<template>
  <Disclosure as="div" class="mt-2">
    <DisclosureButton>
      <div class="flex">
        <InformationCircleIcon class="w-5 h-5 ml-2 text-success" /> History
      </div>
    </DisclosureButton>
    <CollapseTransition>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-500">
        <div
          v-for="purchaseOrder in purchaseOrders"
          :key="purchaseOrder.id"
          class="flow-root bg-slate-200"
        >
          <p>
            Purchase Order:
            {{ purchaseOrder.purchaseOrder.purchaseRequest.title }}
          </p>
          <ul role="list" class="-mb-8">
            <li
              v-for="(status, statusIdx) in purchaseOrder.statuses"
              :key="status.id"
            >
              <div class="relative pb-8">
                <span
                  v-if="statusIdx !== purchaseOrder.statuses.length - 1"
                  class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
                <div class="relative flex items-start space-x-3">
                  <div class="relative">
                    <UserWithAvatar
                      :user="status.createdBy"
                      :show-name="true"
                      :avatar-dimension="6"
                    />
                  </div>
                  <div class="min-w-0 flex-1">
                    <div>
                      set status to
                      <span :class="statusMapping(status.status).class">
                        {{ statusMapping(status.status).text }}
                        <span class="text-gray-500">
                          on {{ status.statusDate }}</span
                        >
                      </span>
                    </div>
                    <div class="mt-2 text-gray-700 italic">
                      <p>{{ status.reason }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li v-if="!purchaseOrder?.statuses?.length">
              No history available
            </li>
          </ul>
        </div>
      </DisclosurePanel>
    </CollapseTransition>
  </Disclosure>
</template>

<script>
import { UserWithAvatar } from "@/components/shared/index";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/outline/esm";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
export default {
  components: {
    InformationCircleIcon,
    UserWithAvatar,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    CollapseTransition,
  },
  props: {
    purchaseOrders: { type: Array, required: true },
  },
  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    statusMapping(status) {
      return {
        N: { class: "text-[#00D1FF]", text: "New" },
        O: { class: "text-hivebuy-yellow", text: "Open" },
        P: { class: "text-hivebuy-plum", text: "Pending" },
        A: { class: "text-hivebuy-green", text: "Approved" },
        RV: { class: "text-hivebuy-red", text: "Asked for Revision" },
      }[status];
    },
  },
};
</script>
