<template>
  <PaymentMethodForm
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-payment-method="savePaymentMethod"
  />
</template>

<script>
import PaymentMethodForm from "@/components/CompanyManagement/Profile/PaymentMethods/PaymentMethodForm";
import { mapActions } from "vuex";
import {
  ADD_PAYMENT_METHOD,
  COMPANY_MANAGEMENT_MODULE,
  GET_PAYMENT_METHODS,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    PaymentMethodForm,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addPaymentMethod: ADD_PAYMENT_METHOD,
      getPaymentMethods: GET_PAYMENT_METHODS,
    }),
    savePaymentMethod(paymentMethod) {
      this.addPaymentMethod(paymentMethod)
        .then(() => {
          this.closeModal();
          this.getPaymentMethods();
          this.showNotification("Payment Method saved successfully");
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>
