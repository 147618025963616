<template>
  <svg viewBox="0 0 54 57" xmlns="http://www.w3.org/2000/svg">
    <g stroke-width="4" fill="none" fill-rule="evenodd">
      <path
        d="M51 40c-1.515-5.198-6.064-9-11.751-9-5.687 0-10.734 3.802-12.249 9M27 46c1.158 5.706 6.202 10 12.249 10C45.296 56 49.842 51.706 51 46"
        stroke="#ffffff"
        stroke-linecap="round"
        class="stroke-000000"
      ></path>
      <path
        stroke="#ffffff"
        d="M52 33v7h-7M26 53v-7h7"
        class="stroke-231e1f"
      ></path>
      <path
        d="M27 13h18M27 27h18M19.929 8l-6.857 8L8.5 12.571M19.929 22l-6.857 8L8.5 26.571M19.929 37l-6.857 7.999L8.5 41.57"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-231f1f"
      ></path>
      <path
        d="M53 27V12.175C53 6.003 47.997 1 41.824 1H12.176C6.004 1 1 6.003 1 12.175v29.649C1 47.996 6.004 53 12.176 53H21"
        stroke="#ffffff"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-231f1f"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DeliverSVG",
};
</script>
