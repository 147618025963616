<template>
  <TransitionRoot as="template" :show="showDialog">
    <Dialog
      as="div"
      static
      class="fixed z-[100] inset-0 overflow-visible"
      :open="showDialog"
      @close="closeDialog"
    >
      <div
        class="flex justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          >
            <div>
              <div
                v-if="success"
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
              >
                <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div
                v-else
                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
              >
                <ExclamationCircleIcon
                  class="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  :class="[
                    'text-lg leading-6 font-medium',
                    { 'text-gray-900': success, 'text-red-600': !success },
                  ]"
                >
                  {{ dialogTitle }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ dialogBodyText }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <Button full danger :on-click-handler="onBtnClick">
                {{ actionButtonText }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import Button from "@/components/shared/Button.vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    ExclamationCircleIcon,
    Button,
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    dialogBodyText: {
      type: String,
      required: true,
    },
    success: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    onClickHandler: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    actionButtonText() {
      return this.buttonText || this.$t("auth.dialogButtonText");
    },
  },
  methods: {
    onBtnClick() {
      if (this.buttonText) {
        this.onClickHandler();
      }
      this.closeDialog();
    },
  },
};
</script>
