<template>
  <div>
    <span
      class="px-2 py-1 rounded text-sm font-semibold"
      :class="getBadge.badgeStyle"
    >
      {{ contractStatusToLabelMapping }}
    </span>
  </div>
</template>

<script>
import { contractMixin } from "@/components/mixins";

export default {
  mixins: [contractMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    getBadge() {
      switch (this.type) {
        case "U":
          return {
            badgeStyle: "bg-hivebuy-lilac text-gray-700",
          };
        case "S":
          return {
            badgeStyle: "bg-primary text-white",
          };
        case "HR":
          return {
            badgeStyle: "bg-hivebuy-yellow text-white",
          };
        default:
          return false;
      }
    },
    contractStatusToLabelMapping() {
      return {
        U: this.$t("shoppingList.types.unit"),
        S: this.$t("shoppingList.types.supplier"),
        HR: this.$t("shoppingList.types.other"),
      }[this.type];
    },
  },
};
</script>
