<template>
  <div class="form-holder">
    <div v-if="featureAllowed" v-auto-animate class="form-header">
      <div
        v-auto-animate
        class="flex items-start justify-between space-x-3 p-4"
      >
        <div class="header-left">
          <p class="font-semibold text-primary">
            {{ $t("invoice.form.header1") }}
          </p>
          <p class="text-xs text-gray-600">
            {{ $t("invoice.form.header2") }}
          </p>
        </div>
        <div v-if="isFeatureAllowed('ocr')">
          <Ocr
            v-if="!ocrData"
            v-model="ocrData"
            :invoice-id="invoice.id"
            :file="invoice.files[invoice.files.length - 1].file"
          />
          <OcrValidation
            v-else-if="ocrData?.ocrValidations"
            :validations="ocrData.ocrValidations"
          />
          <HivebuyTooltip
            v-else-if="!isFeatureAllowed('automateInvoiceProcessing')"
            :open-delay="500"
            :hover="true"
          >
            <div class="relative z-0 inline-flex shadow-sm rounded-md">
              <BarButton
                :on-click-handler="
                  () => {
                    applyValues('green');
                  }
                "
                btn-group="left"
              >
                <div class="h-4 w-4 rounded-full bg-hivebuy-green"></div>
              </BarButton>
              <BarButton
                :on-click-handler="
                  () => {
                    applyValues('yellow');
                  }
                "
                btn-group="middle"
              >
                <div class="h-4 w-4 rounded-full bg-hivebuy-yellow"></div>
              </BarButton>
              <BarButton
                :on-click-handler="
                  () => {
                    applyValues('red');
                  }
                "
                btn-group="middle"
              >
                <div class="h-4 w-4 rounded-full bg-hivebuy-red"></div>
              </BarButton>
              <BarButton
                :on-click-handler="
                  () => {
                    applyValues('all');
                  }
                "
                btn-group="right"
              >
                All
              </BarButton>
            </div>
            <template #content>
              <div class="p-2">Apply OCR values to invoice</div>
            </template>
          </HivebuyTooltip>
        </div>
      </div>
      <SectionBanner v-if="duplicateInvoice" type="warning">
        <template #textSection>
          <div class="flex justify-center text-lg font-semibold">
            {{ $t("invoice.errors.warningDuplicateInvoice") }}
          </div>
          <div class="mx-auto mt-2 grid grid-cols-2">
            <div>
              <span class="dup-title">{{ $t("global.title") }}:</span>
              <span class="dup-value">{{ duplicateInvoice.title }}</span>
            </div>
            <div>
              <span class="dup-title">{{ $t("global.invoiceNumber") }}:</span>
              <span class="dup-value">{{
                duplicateInvoice.invoiceNumber
              }}</span>
            </div>
            <div>
              <span class="dup-title">{{ $t("global.pricing.total") }}:</span>
              <span class="dup-value">
                <i18n-n
                  :value="parseFloat(duplicateInvoice.totalAmount)"
                  format="currency"
                  :locale="
                    currencyToLocale(duplicateInvoice.totalAmountCurrency)
                  "
                />
              </span>
            </div>
            <div>
              <span class="dup-title"
                >{{ $t("invoice.table.createdAt") }}:</span
              >
              <span class="dup-value">
                <TimeDisplay
                  :time="duplicateInvoice.createdAt"
                  :show-time-ago="false"
                  :show-date-with-time="true"
                />
              </span>
            </div>
          </div>
        </template>
      </SectionBanner>
    </div>

    <!-- form content -->
    <div class="form-content">
      <div
        v-if="revisionReasons?.length > 0"
        class="flex border border-hivebuy-red rounded px-4 py-2 mb-4"
      >
        <div
          v-for="object in revisionReasons"
          :key="object"
          class="text-sm font-medium flex w-full items-center justify-between"
        >
          <span>{{ object.reason }}</span>
          <UserWithAvatar
            :user="object.createdBy"
            avatar-dimension="8"
            show-tooltip
          />
        </div>
      </div>
      <div class="mb-3 text-sm">
        <Input
          :error-text="errorMessage('title')"
          :value="invoice.title"
          :name="'title'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="$t('table.title')"
          :label="$t('table.title')"
          is-mandatory
        />
      </div>
      <div class="mb-3 text-sm">
        <InputOCR
          :error-text="errorMessage('invoiceNumber')"
          :value="invoice.invoiceNumber"
          :name="'invoiceNumber'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="$t('invoice.form.invoiceNumber')"
          :label="$t('invoice.form.invoiceNumber')"
          :ocr-details="getOcrField(ocrFields.invoiceNumber)"
          :on-mouse-enter="
            () => drawRectangleForField(getOcrField(ocrFields.invoiceNumber))
          "
          :on-mouse-leave="clearCanvas"
          is-mandatory
        />
      </div>
      <Disclosure
        v-slot="{ open }"
        as="div"
        :class="{ 'border-hivebuy-red': amountError }"
      >
        <DisclosureButton
          :class="['collapse-header', { 'border-hivebuy-red': amountError }]"
        >
          {{ $t("invoice.form.amountSection") }}
          <span class="flex items-center">
            <ExclamationIcon
              v-if="amountError"
              class="h-5 w-5 text-hivebuy-red"
            />

            <ChevronDownIcon
              :class="open ? 'rotate-180 transform ' : ''"
              class="h-5 w-5 text-primary transition-all duration-300"
            />
          </span>
        </DisclosureButton>
        <CollapseTransition>
          <DisclosurePanel class="collapse-content">
            <div v-if="featureAllowed" class="space-y-4 mb-4">
              <div class="grid grid-cols-3 gap-2 mb-2">
                <CurrencyInput
                  id="totalNetAmount"
                  v-model.lazy="invoice.totalNetAmount"
                  :label="$t('table.totalNetAmount')"
                  :error-text="errorMessage('totalNetAmount')"
                  :placeholder="$t('table.totalNetAmount')"
                  :ocr-details="getOcrField(ocrFields.totalNetAmount)"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(
                        getOcrField(ocrFields.totalNetAmount)
                      )
                  "
                  :on-mouse-leave="clearCanvas"
                  :options="{
                    locale: 'de-DE',
                    currency: invoice.totalNetAmountCurrency || 'EUR',
                  }"
                  is-mandatory
                />
                <CurrencyInput
                  id="totalGrossAmount"
                  v-model.lazy="invoice.totalGrossAmount"
                  :error-text="errorMessage('totalGrossAmount')"
                  :placeholder="$t('table.totalGrossAmount')"
                  :label="$t('table.totalGrossAmount')"
                  :ocr-details="getOcrField(ocrFields.totalGrossAmount)"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(
                        getOcrField(ocrFields.totalGrossAmount)
                      )
                  "
                  :on-mouse-leave="clearCanvas"
                  :options="{
                    locale: 'de-DE',
                    currency: invoice.totalNetAmountCurrency || 'EUR',
                  }"
                  is-mandatory
                />
                <CurrencySelect
                  v-model="invoice.totalNetAmountCurrency"
                  class="w-full label"
                  :label="$t('table.currency')"
                  :options="currencyOptions()"
                  :is-in-invoice="true"
                  @change="onCurrencyChange"
                />
              </div>
              <div
                class="bg-gray-50 px-2 rounded border border-gray-200 divide-y divide-gray-200"
              >
                <div
                  v-for="(tax, index) of invoice.taxes"
                  :key="`tax-${index}`"
                  class="relative py-4"
                >
                  <div
                    class="grid gap-x-4 pr-4"
                    :class="
                      isFeatureAllowed('invoicePostings')
                        ? 'grid-cols-3'
                        : 'grid-cols-2'
                    "
                  >
                    <CurrencyInput
                      id="tax"
                      v-model.lazy="invoice.taxes[index].tax"
                      :error-text="taxError(index, 'tax')"
                      :placeholder="$t('table.tax')"
                      :label="$t('table.tax')"
                      is-mandatory
                      :ocr-details="
                        getOcrField(`taxCalculation.${index}.taxAmount`)
                      "
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(`taxCalculation.${index}.taxAmount`)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                      :options="{
                        locale: 'de-DE',
                        currency: invoice.totalNetAmountCurrency || 'EUR',
                      }"
                    />
                    <InputOCR
                      :error-text="taxError(index, 'taxRate')"
                      :value="invoice.taxes[index].taxRate"
                      :name="'vat'"
                      :on-value-change="
                        (event) => {
                          updateTaxRate(index, event);
                        }
                      "
                      type="number"
                      :placeholder="$t('invoice.form.vat')"
                      :label="$t('invoice.form.vat')"
                      :ocr-details="
                        getOcrField(`taxCalculation.${index}.taxRate`)
                      "
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(`taxCalculation.${index}.taxRate`)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                      is-mandatory
                    />
                    <CurrencyInput
                      v-if="isFeatureAllowed('invoicePostings')"
                      id="taxNet"
                      v-model.lazy="invoice.taxes[index].taxNet"
                      :placeholder="$t('global.pricing.taxNet')"
                      :label="$t('global.pricing.taxNet')"
                      :error-text="taxError(index, 'taxNet')"
                      is-mandatory
                      :options="{
                        locale: 'de-DE',
                        currency: invoice.totalNetAmountCurrency || 'EUR',
                      }"
                    />

                    <div
                      v-if="isFeatureAllowed('invoicePostings')"
                      class="grid grid-cols-2 col-span-full gap-x-4 mt-4"
                    >
                      <div>
                        <label
                          class="text-sm text-gray-700 mb-2 capitalize label"
                        >
                          {{ $t("invoice.form.taxCountry") }}
                          <span class="text-red-600">*</span>
                        </label>
                        <DynamicMultiselect
                          v-model="invoice.taxes[index].taxCountry"
                          :is-invalid="taxError(index, 'taxCountry')"
                          :mode="'single'"
                          :options="taxCountryList"
                          placeholder="-"
                          :value-prop="'value'"
                          :label="'name'"
                          :can-clear="false"
                          :error-field-text="taxError(index, 'taxType')"
                        />
                      </div>
                      <div>
                        <label
                          class="text-sm text-gray-700 mb-2 capitalize label"
                        >
                          {{ $t("invoice.form.taxType") }}
                          <span class="text-red-600">*</span>
                        </label>
                        <DynamicMultiselect
                          v-model="invoice.taxes[index].taxType"
                          :mode="'single'"
                          :options="taxTypeList"
                          placeholder="-"
                          :value-prop="'value'"
                          :label="'name'"
                          :can-clear="false"
                          :is-invalid="taxError(index, 'taxType')"
                          :error-field-text="taxError(index, 'taxType')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="absolute -right-1 top-2">
                    <TrashIcon
                      class="h-5 w-5 text-hivebuy-red cursor-pointer"
                      aria-hidden="true"
                      @click="() => manageTaxObject(index)"
                    />
                  </div>
                </div>
                <div class="flex justify-center mt-4 py-2">
                  <Button
                    size-class="sm"
                    :on-click-handler="() => manageTaxObject()"
                    >{{ $t("invoice.form.addTaxLevel") }}</Button
                  >
                </div>
              </div>
              <div
                class="bg-gray-50 px-2 rounded border border-gray-200 relative py-4 grid grid-cols-2 gap-x-4"
              >
                <CurrencyInput
                  id="shippingNet"
                  v-model.lazy="invoice.shippingNet"
                  :placeholder="$t('global.pricing.shippingNet')"
                  :label="$t('global.pricing.shippingNet')"
                  :error-text="errorMessage('shippingNet')"
                  :options="{
                    locale: 'de-DE',
                    currency: invoice.totalNetAmountCurrency || 'EUR',
                  }"
                />
                <CurrencyInput
                  id="shippingGross"
                  v-model.lazy="invoice.shippingGross"
                  :placeholder="$t('global.pricing.shippingGross')"
                  :label="$t('global.pricing.shippingGross')"
                  :error-text="errorMessage('shippingGross')"
                  :options="{
                    locale: 'de-DE',
                    currency: invoice.totalNetAmountCurrency || 'EUR',
                  }"
                />
              </div>
              <div
                class="bg-gray-50 px-2 rounded border border-gray-200 relative py-4"
              >
                <CurrencyInput
                  id="discount"
                  v-model.lazy="invoice.discount"
                  :placeholder="$t('global.pricing.discount')"
                  :label="$t('global.pricing.discount')"
                  :error-text="errorMessage('discount')"
                  :options="{
                    locale: 'de-DE',
                    currency: invoice.totalNetAmountCurrency || 'EUR',
                  }"
                />
              </div>
            </div>
          </DisclosurePanel>
        </CollapseTransition>
      </Disclosure>

      <Disclosure v-slot="{ open }" as="div" class="mt-2">
        <DisclosureButton
          :class="['collapse-header', dateError ? 'border-hivebuy-red' : '']"
        >
          {{ $t("invoice.form.dateSection") }}
          <span class="flex">
            <ExclamationIcon
              v-if="dateError"
              class="h-5 w-5 text-hivebuy-red"
            />
            <ChevronDownIcon
              :class="open ? 'rotate-180 transform ' : ''"
              class="h-5 w-5 text-primary transition-all duration-300"
            />
          </span>
        </DisclosureButton>
        <CollapseTransition>
          <DisclosurePanel class="collapse-content">
            <div class="grid grid-cols-3 gap-x-4 mb-2">
              <div>
                <label class="block text-sm text-gray-600">
                  {{ $t("invoice.form.invoiceDate") }}
                </label>
                <DateCalendar
                  v-model="invoice.invoiceDate"
                  :value="invoice.invoiceDate"
                  :name="'invoiceDate'"
                  :field-name="$t('global.dates.date')"
                  :validation-error="v$.invoice.invoiceDate.$error"
                  :validation-error-text="
                    v$.invoice?.invoiceDate?.$errors[0]?.$message
                  "
                  :ocr-details="getOcrField(ocrFields.invoiceDate)"
                  :on-ocr-update="updateValueFromOCR"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(getOcrField(ocrFields.invoiceDate))
                  "
                  :on-mouse-leave="clearCanvas"
                />
              </div>
              <div>
                <label class="block text-sm text-gray-600">
                  {{ $t("invoice.form.serviceDate") }}
                </label>
                <DateCalendar
                  v-model="invoice.serviceDate"
                  :value="invoice.serviceDate"
                  :field-name="$t('global.dates.date')"
                  :validation-error="v$.invoice.serviceDate.$error"
                  :name="'serviceDate'"
                  :ocr-details="getOcrField(ocrFields.serviceDate)"
                  :on-ocr-update="updateValueFromOCR"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(getOcrField(ocrFields.serviceDate))
                  "
                  :on-mouse-leave="clearCanvas"
                />
              </div>
              <div>
                <label class="block text-sm text-gray-600">
                  {{ $t("invoice.form.dueDate") }}
                </label>
                <DateCalendar
                  v-model="invoice.dueDate"
                  :min-date="invoice.invoiceDate"
                  :value="invoice.dueDate"
                  :field-name="$t('global.dates.date')"
                  :validation-error="v$.invoice?.dueDate?.$error"
                  :validation-error-text="
                    v$.invoice?.dueDate?.$errors[0]?.$message
                  "
                  :name="'dueDate'"
                  :ocr-details="getOcrField(ocrFields.dueDate)"
                  :on-ocr-update="updateValueFromOCR"
                  :on-mouse-enter="
                    () => drawRectangleForField(getOcrField(ocrFields.dueDate))
                  "
                  :on-mouse-leave="clearCanvas"
                />
              </div>
            </div>

            <div class="grid grid-cols-2 gap-x-4 mb-2">
              <div>
                <label class="block text-sm text-gray-600">
                  {{ $t("invoice.form.servicePeriodStart") }}
                </label>
                <DateCalendar
                  v-model="invoice.servicePeriodStart"
                  :value="invoice.servicePeriodStart"
                  :field-name="$t('global.dates.date')"
                  :name="'servicePeriodStart'"
                  :ocr-details="getOcrField(ocrFields.servicePeriodStart)"
                  :on-ocr-update="updateValueFromOCR"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(
                        getOcrField(ocrFields.servicePeriodStart)
                      )
                  "
                  :on-mouse-leave="clearCanvas"
                />
              </div>
              <div>
                <label class="block text-sm text-gray-600">
                  {{ $t("invoice.form.servicePeriodEnd") }}
                </label>
                <DateCalendar
                  v-model="invoice.servicePeriodEnd"
                  :value="invoice.servicePeriodEnd"
                  :field-name="$t('global.dates.date')"
                  :name="'servicePeriodEnd'"
                  :ocr-details="getOcrField(ocrFields.servicePeriodEnd)"
                  :on-ocr-update="updateValueFromOCR"
                  :on-mouse-enter="
                    () =>
                      drawRectangleForField(
                        getOcrField(ocrFields.servicePeriodEnd)
                      )
                  "
                  :on-mouse-leave="clearCanvas"
                />
              </div>
            </div>
          </DisclosurePanel>
        </CollapseTransition>
      </Disclosure>
      <div>
        <div class="w-full my-2">
          <div class="mx-auto w-full rounded-2xl">
            <Disclosure v-slot="{ open }">
              <DisclosureButton class="collapse-header">
                <span>{{ $t("invoice.form.additionalData") }}</span>
                <ChevronDownIcon
                  :class="open ? 'rotate-180 transform' : ''"
                  class="h-5 w-5 text-primary transition-all duration-300"
                />
              </DisclosureButton>
              <CollapseTransition>
                <DisclosurePanel class="collapse-content">
                  <div class="grid grid-cols-2 gap-x-4">
                    <InputOCR
                      :error-text="errorMessage('customerName')"
                      :value="invoice.customerName"
                      :name="'customerName'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.customerName')"
                      :label="$t('invoice.form.customerName')"
                      :ocr-details="getOcrField(ocrFields.customerName)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.customerName)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :error-text="errorMessage('customerNumber')"
                      :value="invoice.customerNumber"
                      :name="'customerNumber'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.customerNumber')"
                      :label="$t('invoice.form.customerNumber')"
                      :ocr-details="getOcrField(ocrFields.customerNumber)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.customerNumber)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                  </div>
                  <div class="grid grid-cols-2 gap-x-4 mt-2">
                    <div class="relative">
                      <TextArea
                        id="customerAddress"
                        name="customerAddress"
                        :placeholder="$t('invoice.form.customerAddress')"
                        :label="$t('invoice.form.customerAddress')"
                        :value="invoice.customerAddress"
                        :rows="2"
                        :on-value-change="onAttributeChange"
                        :ocr-details="getOcrField(ocrFields.customerAddress)"
                        :on-mouse-enter="
                          () =>
                            drawRectangleForField(
                              getOcrField(ocrFields.customerAddress)
                            )
                        "
                        :on-mouse-leave="clearCanvas"
                      />
                    </div>
                    <InputOCR
                      :error-text="errorMessage('customerVatId')"
                      :value="invoice.customerVatId"
                      :name="'customerVatId'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.customerVAT')"
                      :label="$t('invoice.form.customerVAT')"
                      :ocr-details="getOcrField(ocrFields.customerVAT)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.customerVAT)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                  </div>
                  <div class="grid grid-cols-2 gap-x-4 mt-2">
                    <InputOCR
                      :error-text="errorMessage('orderNumber')"
                      :value="invoice.orderNumber"
                      :name="'orderNumber'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.orderNumber')"
                      :label="$t('invoice.form.orderNumber')"
                      :ocr-details="getOcrField(ocrFields.orderNumber)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.orderNumber)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :error-text="errorMessage('deliveryNumber')"
                      :value="invoice.deliveryNoteNumber"
                      :name="'deliveryNoteNumber'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.deliveryNumber')"
                      :label="$t('invoice.form.deliveryNumber')"
                      :ocr-details="getOcrField(ocrFields.deliveryNoteNumber)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.deliveryNoteNumber)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                  </div>
                </DisclosurePanel>
              </CollapseTransition>
            </Disclosure>

            <Disclosure v-slot="{ open }" as="div" class="mt-2">
              <DisclosureButton class="collapse-header">
                <span>{{ $t("invoice.form.supplierData") }}</span>
                <ChevronDownIcon
                  :class="open ? 'rotate-180 transform ' : ''"
                  class="h-5 w-5 text-primary transition-all duration-300"
                />
              </DisclosureButton>
              <CollapseTransition>
                <DisclosurePanel class="collapse-content">
                  <div class="grid grid-cols-3 gap-x-2 gap-y-4">
                    <InputOCR
                      :error-text="errorMessage('supplierName')"
                      :value="invoice.vendorName"
                      :name="'vendorName'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.supplierName')"
                      :label="$t('invoice.form.supplierName')"
                      :ocr-details="getOcrField(ocrFields.vendorName)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.vendorName)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :error-text="errorMessage('vendorVatId')"
                      :value="invoice.vendorVatId"
                      :name="'vendorVatId'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.supplierVAT')"
                      :label="$t('invoice.form.supplierVAT')"
                      :ocr-details="getOcrField(ocrFields.supplierVAT)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.supplierVAT)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :error-text="errorMessage('vendorTaxId')"
                      :value="invoice.vendorTaxId"
                      :name="'vendorTaxId'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.vendorTaxId')"
                      :label="$t('invoice.form.vendorTaxId')"
                      :ocr-details="getOcrField(ocrFields.vendorTaxId)"
                      :on-mouse-enter="
                        () =>
                          drawRectangleForField(
                            getOcrField(ocrFields.vendorTaxId)
                          )
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :value="invoice.iban"
                      :name="'iban'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.iban')"
                      :label="$t('invoice.form.iban')"
                      :ocr-details="getOcrField(ocrFields.iban)"
                      :on-mouse-enter="
                        () => drawRectangleForField(getOcrField(ocrFields.iban))
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                    <InputOCR
                      :value="invoice.bic"
                      :name="'bic'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="$t('invoice.form.bic')"
                      :label="$t('invoice.form.bic')"
                      :ocr-details="getOcrField(ocrFields.bic)"
                      :on-mouse-enter="
                        () => drawRectangleForField(getOcrField(ocrFields.bic))
                      "
                      :on-mouse-leave="clearCanvas"
                    />
                  </div>
                  <div
                    v-if="
                      !invoice.isInvoiceLevelApprover &&
                      !companySetting('BANK_INFO_FROM_INVOICE')?.active
                    "
                    class="mt-4"
                  >
                    <label
                      class="block text-sm text-gray-600 font-semibold mb-1"
                    >
                      {{ $t("invoice.form.companySupplierLabel") }}
                    </label>

                    <SupplierSelect
                      v-model="invoice.companySupplier"
                      :category-to-filter="invoice.category"
                      :placeholder="
                        $t(
                          'catalogue.catalogueItemForm.companySupplierPlaceholder'
                        )
                      "
                      :label="'name'"
                      :clear-on-search="true"
                    />
                  </div>
                </DisclosurePanel>
              </CollapseTransition>
            </Disclosure>
          </div>
        </div>
      </div>
      <div
        v-if="!isProductionEnvironment()"
        class="grid grid-cols-2 gap-y-4 mb-2 px-1 mt-4 h-[40px]"
      >
        <Button :on-click-handler="toggleInvoiceItemsDrawer">
          {{ $t("invoice.form.invoiceItems") }}
        </Button>
      </div>
      <div class="grid grid-cols-2 gap-y-4 mb-2 px-1 mt-4 h-[40px]">
        <SwitchCheckbox
          v-model="invoice.invoicePaid"
          class="mb-2 sm:mb-0"
          :label="$t('invoice.form.paidInvoice')"
        />
        <div v-show="invoice.invoicePaid" class="text-sm">
          <DateCalendar
            v-model="invoice.invoicePaidAt"
            :value="invoice.invoicePaidAt"
            :name="'invoicePaidAt'"
            :field-name="$t('invoice.form.paidInvoiceAt')"
            :validation-error="v$.invoice?.invoicePaidAt?.$error"
          />
        </div>
      </div>
      <div
        v-if="!invoice.invoicePurchaseOrders.length"
        class="grid grid-cols-1 gap-y-4 mb-2 pt-4 px-1"
      >
        <SwitchCheckbox
          v-model="invoice.isInvoiceLevelApprover"
          :read-only="isCollectiveInvoice(invoice)"
          class="mb-2 sm:mb-0"
          :label="$t('invoice.form.nonIpoInvoice')"
        />
        <div
          v-if="invoice.isInvoiceLevelApprover"
          class="text-sm px-4 py-2 border border-gray-100 bg-gray-50 rounded"
        >
          <CategorySupplierSection
            :invoice-form="true"
            :supplier="invoice.supplier"
            :category="invoice.category"
            :save-details-attr="saveDetailsAttr"
          />
          <div
            v-if="errorMessage('category') || errorMessage('companySupplier')"
            class="py-2 flex justify-center text-sm font-medium text-hivebuy-red"
          >
            {{ categorySupplierError() }}
          </div>

          <div class="mt-4"></div>
          <SelectRequestedFor
            v-model="invoice.requestedFor"
            :is-in-modal="true"
            :allow-myself="false"
            :heading="$t('invoice.form.whoOrderedIt')"
            :model-value="invoice.requestedFor"
            :has-errors="errorMessage('requestedFor').length > 0"
          />
        </div>
        <p v-if="isCollectiveInvoice(invoice)" class="text-sm text-gray-400">
          Multiple Purchase orders are already assigned so you can not mark this
          as individual invoice
        </p>
      </div>
      <div class="grid grid-cols-1 gap-y-4 mb-2 pt-4 px-1">
        <SwitchCheckbox
          v-model="invoice.collective"
          :read-only="isCollectiveInvoice(invoice)"
          class="mb-2 sm:mb-0"
          :label="$t('invoice.form.collectiveInvoice')"
        />
        <p
          v-show="invoice.collective"
          class="text-sm px-4 py-2 border border-gray-100 bg-gray-50 rounded"
        >
          {{ $t("invoice.form.collectiveInvoiceText") }}
        </p>
        <p v-if="isCollectiveInvoice(invoice)" class="text-sm text-gray-400">
          Multiple Purchase orders are already assigned so you can not mark this
          as individual invoice
        </p>
      </div>
      <div class="mt-2 md:mt-4 xl:mt-6 px-1">
        <TextArea
          id="notes"
          name="notes"
          :placeholder="$t('invoice.form.commentPlaceholder')"
          :label="$t('table.notes')"
          :value="invoice.notes"
          :rows="3"
          :on-value-change="onAttributeChange"
        />
      </div>
    </div>
    <div class="form-footer">
      <div
        v-if="v$.$error"
        class="py-2 flex justify-center text-sm font-medium text-hivebuy-red"
      >
        {{ $t("global.errorMessages.genericFieldError") }}
      </div>
      <InvoiceFormSaveButtons
        :loading="loading"
        :close-action="closeAction"
        :save-invoice="saveInvoice"
        :invoice="invoiceObject"
        :update-filter="updateFilter"
        :set-visible-state="setVisibleState"
        :validation-object="v$"
      />
    </div>
  </div>
  <Sidebar
    v-model:visible="showInvoiceItemsDrawer"
    :header="$t('invoice.form.invoiceItems')"
    :position="position"
    :class="[
      'w-full md:w-20rem lg:w-30rem',
      sidebarHeightClass,
      'transition-all duration-300',
    ]"
  >
    <template #header>
      <div class="flex items-center gap-2 w-full">
        <div class="font-bold text-primary mr-2">
          {{ $t("invoice.form.invoiceItems") }}
        </div>
        <ArrowsExpandIcon
          v-if="position === 'bottom'"
          class="h-5 w-5 text-primary cursor-pointer"
          @click="() => (position = 'full')"
        />
        <ArrowCircleDownIcon
          v-else
          class="h-5 w-5 text-primary cursor-pointer"
          @click="() => (position = 'bottom')"
        />

        <Button
          class="ml-auto"
          :on-click-handler="toggleHeight"
          :size-class="'sm'"
        >
          Toggle Height
        </Button>
      </div>
    </template>
    <HivebuyInlineEditTable
      :table-data="invoice.lineItems"
      :columns="tableColumns"
      :fields-config="INVOICE_LINE_ITEMS_CONFIG"
      @data-updated="handleDataUpdate"
    >
      <template #row-actions="{ rowData }">
        <button @click="deleteRow(rowData)">Delete Row</button>
      </template>
    </HivebuyInlineEditTable>
  </Sidebar>
</template>
<script>
import {
  INVOICE_MODULE,
  UPDATE_INVOICE,
  VALIDATE_INVOICE,
} from "@/store/Invoice/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

import { mapActions, mapGetters } from "vuex";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
  ChevronDownIcon,
  ExclamationIcon,
  ArrowsExpandIcon,
  ArrowCircleDownIcon,
} from "@heroicons/vue/solid";
import {
  Input,
  TextArea,
  Button,
  CurrencyInput,
  DateCalendar,
  UserWithAvatar,
  SelectRequestedFor,
  SupplierSelect,
  SectionBanner,
} from "@/components/shared/index";
import CategorySupplierSection from "@/components/shared/CategorySupplierSection.vue";
import useValidate from "@vuelidate/core";
import InputOCR from "@/components/shared/InputOCR.vue";
import { currencyToLocale } from "@/utils/utility_methods.js";
import {
  Ocr,
  OcrValidation,
  InvoiceFormSaveButtons,
} from "@/components/InvoiceApproval/index.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { TrashIcon } from "@heroicons/vue/solid";
import { TimeDisplay } from "@/components/shared/index";
import {
  getValueFromHash,
  ocrConfidenceCalc,
  sumInArray,
  arrayToSentence,
} from "@/utils/utility_methods.js";
import BarButton from "@/components/shared/BarButton.vue";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { invoiceValidation } from "@/utils/validations/invoice.js";
import OcrDisplayMixin from "@/components/mixins/ocrDisplayMixin.js";
import DynamicMultiselect from "@/components/shared/DynamicMultiselect";
import {
  CURRENCY_OPTIONS,
  INVOICE_VALIDATE_FIELD_MAPPING,
} from "@/utils/constants.js";
import { DUPLICATE_INVOICE_EXIST } from "@/utils/errorConstants.js";
import Sidebar from "primevue/sidebar";
import { HivebuyInlineEditTable } from "design-buy";
import {
  tableColumns,
  INVOICE_LINE_ITEMS_CONFIG,
} from "@/utils/helpers/InvoiceInlineEditHelper.js";
import CurrencySelect from "@/components/shared/CurrencySelect/index.vue";
export default {
  components: {
    InvoiceFormSaveButtons,
    TimeDisplay,
    BarButton,
    HivebuyTooltip,
    TrashIcon,
    Input,
    TextArea,
    Button,
    CurrencySelect,
    DateCalendar,
    CurrencyInput,
    InputOCR,
    Ocr,
    CollapseTransition,
    ChevronDownIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    CategorySupplierSection,
    OcrValidation,
    ExclamationIcon,
    SwitchCheckbox,
    SelectRequestedFor,
    UserWithAvatar,
    SupplierSelect,
    DynamicMultiselect,
    SectionBanner,
    Sidebar,
    ArrowsExpandIcon,
    ArrowCircleDownIcon,
    HivebuyInlineEditTable,
  },
  mixins: [OcrDisplayMixin],
  inject: {
    onSaveInvoiceModal: {
      default: () => {},
    },
    isInvoiceModal: {
      default: () => {
        return false;
      },
    },
  },
  props: {
    invoiceObject: { type: Object, required: true },
    setVisibleState: { type: Function, default: () => {} },
    updateFilter: { type: Function, required: true },
    closeAction: { type: Function, default: () => {} },
  },
  data() {
    return {
      duplicateInvoice: null,
      invoice: {},
      v$: useValidate(),
      showCustomerData: false,
      ocrData: null,
      loading: false,
      showInvoiceItemsDrawer: false,
      position: "bottom",
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(INVOICE_MODULE, ["invoiceWithId"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    taxCountryList() {
      return [
        { name: "-", value: null },
        { name: "INLAND", value: "INLAND" },
        { name: "EU mit UID", value: "EU mit UID" },
        { name: "EU ohne UID", value: "EU ohne UID" },
        { name: "DRITTLAND", value: "DRITTLAND" },
      ];
    },

    taxTypeList() {
      return [
        { name: "-", value: null },
        { name: "VOLL", value: "VOLL" },
        { name: "ERMÄSSIGT", value: "ERMÄSSIGT" },
        { name: "EUST", value: "EUST" },
        { name: "OHNE", value: "OHNE" },
        { name: "LEIST13B", value: "LEIST13B" },
      ];
    },
    dateError() {
      return (
        this.v$.invoice.invoiceDate.$error || this.v$.invoice.serviceDate.$error
      );
    },
    revisionReasons() {
      const reasonArray = [];
      this.invoice.invoicePurchaseOrders
        ?.filter((ipo) => {
          return ipo.status === "RV";
        })
        ?.forEach((ipo) => {
          ipo.statuses?.forEach((status) => {
            if (status.status === "RV") {
              reasonArray.push(status);
            }
          });
        });
      return reasonArray;
    },
    amountError() {
      let isError = false;
      this.invoice.taxes.forEach((_tax, index) => {
        if (isError) return;
        let taxFields = ["tax", "taxRate"];
        if (this.isFeatureAllowed("invoicePostings")) {
          taxFields = [...taxFields, "taxCountry", "taxType", "taxNet"];
        }

        isError = taxFields.some((field) => {
          return this.taxError(index, field)?.length;
        });
      });

      const amountFields = [
        "totalNetAmount",
        "totalGrossAmount",
        "tax",
        "shippingNet",
        "shippingGross",
        "discount",
      ];
      return isError || amountFields.some((field) => this.errorMessage(field));
    },
    ocrFields() {
      return {
        invoiceNumber: "number",
        currency: "currency",
        vendorName: "vendor.name",
        vendorTaxId: "vendor.tax.id",
        invoiceDate: "date",
        totalNetAmount: "netAmount",
        totalGrossAmount: "grossAmount",
        tax: "taxAmount",
        vat: "taxCalculation.taxRate",
        serviceDate: "servicePeriodEnd",
        servicePeriodStart: "servicePeriodStart",
        servicePeriodEnd: "servicePeriodEnd",
        dueDate: "dueDate",
        customerName: "customer.name",
        customerNumber: "customer.customerNumber",
        customerAddress: "customer.address",
        customerVAT: "customer.vatId",
        supplierVAT: "vendor.vatId",
        orderNumber: "orderConfirmationNumber",
        deliveryNoteNumber: "deliveryNoteNumber",
        iban: "vendor.bankingInformation[0].iban",
        bic: "vendor.bankingInformation[0].bic",
      };
    },
    featureAllowed() {
      return this.isFeatureAllowed("invoiceApproval");
    },
    ocrExtraction() {
      return this.ocrData.ocrExtraction;
    },
    ocrResult() {
      return this.ocrData.ocrResult;
    },
    sidebarHeightClass() {
      return this.isExpanded ? "h-96" : "h-80";
    },
  },
  watch: {
    invoiceObject: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) == JSON.stringify(oldVal)) return;
        this.setInvoiceData();
        this.checkDuplicate();
      },
      deep: true,
      immediate: true,
    },
    "invoice.isInvoiceLevelApprover": {
      handler(value) {
        if (value) this.invoice.collective = false;
      },
    },
    "invoice.supplier": {
      handler(value) {
        if (!value?.type) return;

        if (value.type == "companySupplier") {
          this.invoice.companySupplier = value.details;
          this.invoice.unlistedSupplier = null;
          this.invoice.unlistedSupplierReason = null;
        } else {
          this.invoice.unlistedSupplier = value.details.name;
          this.invoice.unlistedSupplierReason = value.details.reason;
          this.invoice.companySupplier = null;
        }
      },
    },
  },
  mounted() {
    this.INVOICE_LINE_ITEMS_CONFIG = INVOICE_LINE_ITEMS_CONFIG;
    this.tableColumns = tableColumns;
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      updateInvoice: UPDATE_INVOICE,
      validateInvoice: VALIDATE_INVOICE,
    }),
    setInvoiceData() {
      const invoiceObjDetails = this.invoiceWithId(this.invoiceObject.id);
      this.invoice = Object.assign(
        {
          collective: false,
          invoicePaid: false,
          isInvoiceLevelApprover: false,
        },
        this.invoiceWithId(invoiceObjDetails.id)
      );
      this.formatInvoice(invoiceObjDetails);
    },
    formatInvoice(newInvoice) {
      if (this.isCollectiveInvoice(newInvoice)) this.invoice.collective = true;
      if (!newInvoice.title) {
        this.invoice.title = newInvoice.invoiceNumber;
      }
      if (!newInvoice?.taxes?.length) {
        this.invoice.taxes = [];
      }
      if (newInvoice.netAmount) {
        this.invoice.totalNetAmount = newInvoice.netAmount;
      }
      if (newInvoice.grossAmount) {
        this.invoice.totalGrossAmount = newInvoice.grossAmount;
      }
      if (newInvoice.isInvoiceLevelApprover) {
        const type = newInvoice.companySupplier
          ? "companySupplier"
          : "unlisted";
        this.invoice.supplier = {
          type,
          details: newInvoice.companySupplier || {
            name: newInvoice.unlistedSupplier,
            reason: newInvoice.unlistedSupplierReason,
          },
        };
      }
      this.setOCrData(newInvoice);
    },
    categorySupplierError() {
      const errorArray = [];

      this.errorMessage("category") &&
        errorArray.push(this.errorMessage("category"));
      this.errorMessage("companySupplier") &&
        errorArray.push(this.errorMessage("companySupplier"));
      return arrayToSentence(errorArray, ",", this.$t);
    },
    isCollectiveInvoice(invoice) {
      return (
        invoice.invoicePurchaseOrders.filter((ipo) =>
          ["P", "R", "O", "A"].includes(ipo.status)
        ).length > 1
      );
    },
    checkDuplicate() {
      const validateKeys = ["invoiceNumber", "id"];
      const validateObject = {};
      validateKeys.forEach((key) => {
        validateObject[INVOICE_VALIDATE_FIELD_MAPPING(key)] = this.invoice[key];
      });
      this.validateInvoice(validateObject)
        .then((response) => {
          const isDuplicate =
            response?.data?.message == DUPLICATE_INVOICE_EXIST;
          this.duplicateInvoice = isDuplicate ? response.data.invoice : null;
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onAttributeChange(event) {
      const { name, value } = event.target ? event.target : event;
      this.invoice[name] = value;
      this.checkDuplicate();
    },
    saveDetailsAttr(attr, value) {
      this.invoice[attr] = value;
    },
    applyValues(confidence) {
      const range = ocrConfidenceCalc(confidence);
      const fields = Object.keys(this.ocrFields);

      fields.forEach((field) => {
        const data = this.ocrData.ocrExtraction[this.ocrFields[field]];
        if (
          data &&
          data.confidence != null &&
          data.confidence * 100 >= range.start &&
          data.confidence * 100 <= range.end
        ) {
          if (field === "currency") {
            this.onCurrencyChange(data.value);
          }
          const dateFields = ["invoiceDate", "dueDate", "serviceDate"];
          if (dateFields.includes(field)) {
            const parsedDate = new Date(data.value).toISOString();
            this.invoice[field] = parsedDate;
            return;
          }
          this.invoice[field] = data.value;
        }
      });
    },
    setOCrData(invoice) {
      if (!invoice.ocrExtraction) return;
      this.ocrData = Object.assign(
        {},
        {
          ocrExtraction: invoice.ocrExtraction,
          ocrFields: invoice.ocrFields,
          ocrResult: invoice.ocrResult,
          ocrScores: invoice.ocrScores,
          ocrUuid: invoice.ocrUuid,
          ocrValidations: invoice.ocrValidations,
        }
      );
    },
    getOcrField(path) {
      if (this.ocrData) {
        return getValueFromHash(path, this.ocrExtraction);
      }
      return null;
    },

    currencyOptions() {
      return CURRENCY_OPTIONS;
    },
    manageTaxObject(index) {
      if (index >= 0) {
        this.invoice.taxes.splice(index, 1);
      } else {
        const invoiceTaxObject = {
          tax: 0,
          taxRate: 0,
          taxNet: this.invoice.totalNetAmount,
          taxNetCurrency: this.invoice.totalNetAmountCurrency,
        };
        if (this.isFeatureAllowed("invoicePostings")) {
          invoiceTaxObject.taxType = this.taxTypeList[0].value;
          invoiceTaxObject.taxCountry = this.taxCountryList[0].value;
        }
        this.invoice.taxes.push(invoiceTaxObject);
      }
    },
    onCalc() {
      const { totalGrossAmount, totalNetAmount } = this.invoice;
      let tax = parseFloat(totalGrossAmount) - parseFloat(totalNetAmount);
      if (tax < 0) {
        tax = 0;
      }
      this.invoice = { ...this.invoice, tax: tax.toString() };
    },
    onCurrencyChange(data) {
      this.invoice.totalNetAmountCurrency = data;
      this.invoice.totalGrossAmountCurrency = data;
      this.invoice.totalAmountCurrency = data;
      this.invoice.taxCurrency = data;
      this.invoice.shippingCurrency = data;
      this.invoice.totalAmountCurrency = data;
      this.invoice.shippingNetCurrency = data;
      this.invoice.shippingGrossCurrency = data;
      this.invoice.discountCurrency = data;
    },

    taxError(index, attr) {
      return this.v$.invoice.taxes.$each.$response.$errors[index][attr][0]
        ?.$message;
    },
    errorMessage(attribute, index = null) {
      return this.parseError(this.v$, attribute, index);
    },

    parseError(validationObject, attribute) {
      const value = getValueFromHash(attribute, validationObject.invoice);
      if (!value) return "";

      return value.$error
        ? validationObject.$errors.find(
            (error) => error.$property === attribute
          ).$message
        : "";
    },
    formatAmount(amount) {
      return parseFloat(amount).toFixed(this.backendDecimalPoint);
    },
    payload() {
      const invoicePayload = JSON.parse(JSON.stringify(this.invoice));
      if (invoicePayload.isInvoiceLevelApprover && invoicePayload.status == "N")
        invoicePayload.status = "O";
      if (invoicePayload.invoicePurchaseOrders.length) {
        invoicePayload.invoicePurchaseOrders =
          invoicePayload.invoicePurchaseOrders.map((ipo) => {
            return {
              ...ipo,
              purchaseOrder: ipo.purchaseOrder.id || ipo.purchaseOrder,
            };
          });
      }
      return Object.assign({}, invoicePayload, {
        totalNetAmount: this.formatAmount(this.invoice.totalNetAmount),
        totalGrossAmount: this.formatAmount(this.invoice.totalGrossAmount),
        tax: this.formatAmount(this.sumInArray(this.invoice.taxes, "tax")),
        totalAmount: this.formatAmount(this.invoice.totalGrossAmount),
        currency: this.invoice.totalNetAmountCurrency,
        totalAmountCurrency: this.invoice.totalNetAmountCurrency,
      });
    },
    saveInvoice(showPoSection = false, redirection = true) {
      this.v$.$validate();
      if (!this.v$.invoice.$error) {
        this.v$.$reset();
        this.saveInvoiceRecord(showPoSection, redirection);
      }
    },
    currencyToLocale,

    saveInvoiceRecord(showPoSection, redirection) {
      this.loading = true;
      const requestPayload = this.payload();
      if (this.isInvoiceModal) {
        this.onSaveInvoiceModal(requestPayload, this.ocrData).finally(() => {
          this.loading = false;
        });
        return;
      }
      this.updateInvoice({
        invoiceId: requestPayload.id,
        payload: requestPayload,
      })
        .then(() => {
          this.showNotification(this.$t("invoice.saved"), "success");
          this.setInvoiceData();
          if (showPoSection) {
            this.setVisibleState("poList");
          } else if (redirection) {
            this.setVisibleState();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sumInArray,
    updateTaxRate(index, event) {
      const { value } = event.target ? event.target : event;
      this.invoice.taxes[index].taxRate = value;
    },
    drawRectangleForField(field) {
      return field;
    },
    toggleInvoiceItemsDrawer() {
      this.showInvoiceItemsDrawer = !this.showInvoiceItemsDrawer;
    },
    toggleHeight() {
      this.isExpanded = !this.isExpanded;
    },
    handleDataUpdate(updatedData) {
      console.log("Updated Data:", updatedData);
    },
    deleteRow(rowData) {
      console.log("Row Data:", rowData);
    },
  },
  validations() {
    return invoiceValidation(
      this.invoice,
      this.featureAllowed,
      this.$t,
      this.isFeatureAllowed("invoicePostings")
    );
  },
};
</script>

<style scoped>
.form-holder {
  @apply w-full h-full flex flex-col p-4;
}

.form-header {
  @apply pb-2 border-b border-gray-200 -mx-4 px-4 -mt-2 shadow-md bg-white;
}

.form-content {
  @apply flex-1 py-2 -mx-4 px-4 pt-4;
}

.form-footer {
  @apply pb-2;
}

.collapse-header {
  @apply flex w-full justify-between rounded bg-gray-50 p-2 text-left text-sm font-medium text-primary border hover:border-primary hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75;
}

.collapse-content {
  @apply pr-2 py-2 text-sm text-gray-500;
}

.dup-title {
  @apply text-base text-gray-700 mr-2;
}

.dup-value {
  @apply text-base text-gray-800 font-semibold;
}
</style>
