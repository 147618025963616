<template>
  <div class="max-w-screen-2xl mx-auto px-4">
    <div class="flex items-center">
      <p class="text-md font-medium leading-6 text-bayoux-500 py-6 text-2xl">
        {{ $t("companyManagement.connectedSupplier.title") }}
      </p>
    </div>
    <div
      v-if="isApiLoading('companyIntegratedSuppliers')"
      class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 bg-white rounded-lg"
    >
      <div
        class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8"
      >
        <SkeletonItem v-for="index in 3" :key="index" />
      </div>
    </div>
    <div v-else class="bg-white rounded">
      <div
        class="max-w-2xl mx-auto py-10 px-4 sm:py-18 sm:px-6 lg:max-w-7xl lg:px-8"
      >
        <div
          v-if="activeSuppliers.length"
          class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 gap-y-2"
        >
          <div
            v-for="supplier in activeSuppliers"
            :key="supplier.id"
            class="bg-white border border-gray-200 rounded-lg flex flex-col"
          >
            <Supplier
              :supplier="supplier.supplier"
              :company-supplier="supplier"
            />
          </div>
        </div>
        <div v-else class="text-center">
          <EmptyState
            :heading-text="$t('catalogue.suppliers.emptyState.heading')"
            :description-text="$t('catalogue.suppliers.emptyState.description')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonItem from "@/components/Catalogue/CatalogueItems/SkeletonItem";
import EmptyState from "@/components/shared/EmptyState";
import Supplier from "@/components/Catalogue/Supplier/Supplier";
import loadIntegratedSuppliersMixin from "@/components/mixins/loadIntegratedSuppliersMixin.js";

export default {
  components: {
    Supplier,
    SkeletonItem,
    EmptyState,
  },
  mixins: [loadIntegratedSuppliersMixin],
  computed: {
    activeSuppliers() {
      return this.integratedSuppliersList().filter(
        (supplier) => supplier.active
      );
    },
  },
};
</script>
