import { mapState } from "vuex";
import { CURRENCY_LOCALE } from "@/utils/constants.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  methods: {
    valueInCurrency(value, withDecimal = false) {
      const number = parseFloat(value.toFixed(1));
      return withDecimal
        ? this.$n(
            number,
            "currencyWithDecimal",
            CURRENCY_LOCALE[this.company.currency]
          )
        : this.$n(number, "budget", CURRENCY_LOCALE[this.company.currency]);
    },
  },
};
