<template>
  <div class="flex justify-between mb-2">
    <div class="flex items-center">
      <h2 class="page-title">{{ $t("draftList.title") }}</h2>
    </div>
  </div>
  <HTable
    :columns="visibleColumns"
    :data-function="getList"
    :model-data="tableModelData"
    :is-search-enabled="false"
    :show-toolbar="false"
  >
    <template #name="slotProps">
      <div class="flex items-center gap-2 max-w-sm">
        <TruncateText :text="slotProps.data.title" placement="bottom" />
      </div>
    </template>
    <template #requestType="slotProps">
      <ItemSourceTag
        :list="slotProps.data.items.map((item) => item.sourceType)"
        :hide-tooltip="true"
      />
    </template>

    <template #items="slotProps">
      <div class="flex">
        <div
          v-for="(item, index) in slotProps.data.items"
          :key="item.id"
          class="flex -ml-2 overflow-hidden"
        >
          <HivebuyTooltip :open-delay="50">
            <span class="avatar">
              {{ index + 1 }}
            </span>
            <template #content>
              <div class="p-2">{{ item.name }}</div>
            </template>
          </HivebuyTooltip>
        </div>
      </div>
    </template>
    <template #price="slotProps">
      <i18n-n
        :value="parseFloat(slotProps.data.totalNetAmount)"
        format="currency"
        :locale="currencyToLocale(slotProps.data.totalNetAmountCurrency)"
      />
    </template>
    <template #actions="slotProps">
      <ChevronRightIcon
        class="h-6 w-6 text-gray-400 cursor-pointer hover:text-hivebuy-green"
        @click="() => goToRequest(slotProps.data.id)"
      />
    </template>
  </HTable>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  GET_DRAFT_REQUESTS,
  PURCHASE_REQUEST_MODULE,
  SET_DRAFT_LIST_PARAMS,
} from "@/store/PurchaseRequest/types";

import { HTable } from "design-buy";
import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { formatDateTime } from "@/utils/utility_methods.js";
import "@/assets/styles/table.css";
import { currencyToLocale } from "@/utils/utility_methods.js";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import ItemSourceTag from "@/components/RequestCreation/ItemSourceTag.vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
export default {
  name: "DraftList",
  components: {
    HTable,
    HivebuyTooltip,
    ItemSourceTag,
    ChevronRightIcon,
  },
  mixins: [TableFilterMixin],
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, [
      "draftListParams",
      "draftPurchaseRequests",
    ]),
    tableModelData() {
      return {
        data: this.draftPurchaseRequests,
        lazyParams: this.draftListParams,
        mutationCallback: this.setListParams,
      };
    },
    visibleColumns() {
      const columns = [
        {
          field: "name",
          header: this.$t("global.name"),
          slot: "name",
        },
        {
          field: "items",
          header: this.$tc("global.product", 2),
          slot: "items",
        },
        {
          field: "totalNetAmount",
          header: this.$t("global.pricing.amount"),
          slot: "price",
        },
        {
          field: "sourceType",
          header: this.$t("global.requestType"),
          slot: "requestType",
        },
      ];
      if (!this.isUserOnlyAuditor)
        columns.push({
          field: "actions",
          header: "",
          slot: "actions",
        });
      return columns;
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setListParams: SET_DRAFT_LIST_PARAMS,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDraftList: GET_DRAFT_REQUESTS,
    }),
    currencyToLocale,
    formatDateTime,
    goToRequests() {
      this.$router.push({ name: "Requests" });
    },
    goToRequest(id) {
      this.$router.push({
        name: "EditPurchaseRequest",
        params: { id },
      });
    },
    getList(params) {
      return this.getDraftList(
        this.requestQueryParams(params, "draftPR")
      ).catch((error) => this.showErrorMessage(error));
    },
    requestedFor(data) {
      if (!data) return {};
      return {
        id: data.requestedForId,
        type: this.requestedForType(data.requestedForType),
        user: data.user,
        department: data.department,
        project: data.project,
      };
    },
  },
};
</script>
<style scoped>
.avatar {
  @apply inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary cursor-pointer border-white border-2 text-sm text-white font-semibold;
}
</style>
