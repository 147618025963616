<template>
  <HTable
    v-model="selectedFiles"
    :columns="visibleColumns"
    :data-function="getList"
    :show-actions="true"
    :model-data="tableModelData"
  >
    <template #tableActions>
      <DownloadFile :models="['downloadZip']" />
      <Button
        v-if="selectedFiles.length > 1"
        :on-click-handler="fetchFiles"
        :color="'gray'"
        :disabled="!selectedFiles.length"
        :loading="loading"
        size-class="xs"
      >
        {{ $t("buttons.csvExport.downloadZipButton") }}
      </Button>
    </template>
    <template #name="slotProps">
      <div class="flex items-center gap-2">
        {{ slotProps.data.name }}
        <EyeIcon
          :class="[
            'h-5 w-5 text-gray-500',
            { 'text-green-500': slotProps.data.viewed },
          ]"
        />
      </div>
    </template>
    <template #type="slotProps">
      {{ $t(`download.types.${slotProps.data.type}`) }}
    </template>
    <template #createdAt="slotProps">
      <TimeDisplay :time="slotProps.data.createdAt" :show-time-ago="false" />
    </template>

    <template #file="slotProps">
      <ListButton
        :on-click-handler="() => downloadFile(slotProps.data.file)"
        hover-edit
        class="my-0.5"
      >
        <template #icon>
          <CloudDownloadIcon class="h-5 w-5" />
        </template>
      </ListButton>
    </template>
  </HTable>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  DOWNLOAD_MODULE,
  DOWNLOAD_LIST,
  SET_LIST_PARAMS,
  DOWNLOAD_FILES,
} from "@/store/Downloads/types.js";
import { HTable } from "design-buy";
import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { EyeIcon, CloudDownloadIcon } from "@heroicons/vue/outline";
import { formatDateTime } from "@/utils/utility_methods.js";
import "@/assets/styles/table.css";
import { TimeDisplay, ListButton, Button } from "@/components/shared/index";
import DownloadFile from "@/components/PurchaseOrder/PurchaseOrdersList/DownloadFile";
export default {
  name: "DownloadList",
  components: {
    DownloadFile,
    HTable,
    EyeIcon,
    CloudDownloadIcon,
    TimeDisplay,
    ListButton,
    Button,
  },
  mixins: [TableFilterMixin],
  data() {
    return { selectedFiles: [], loading: false };
  },
  computed: {
    ...mapState(DOWNLOAD_MODULE, ["listParams", "downloadList"]),
    tableModelData() {
      return {
        data: this.downloadList,
        lazyParams: this.listParams,
        mutationCallback: this.setListParams,
      };
    },

    language() {
      if (this.$i18n) {
        return this.$i18n.locale;
      } else {
        return "en";
      }
    },

    visibleColumns() {
      return [
        {
          field: "id",
          selectionMode: "multiple",
        },
        {
          field: "name",
          header: this.$t("download.headers.name"),
          slot: "name",
        },
        {
          field: "createdAt",
          header: this.$t("global.dateCreated"),
          slot: "createdAt",
        },
        {
          field: "type",
          header: this.$t("download.headers.type"),
          slot: "type",
          filterConfig: {
            type: "select",
            model: "fileTypes",
            props: { options: this.fileTypes(), object: false },
          },
        },
        {
          field: "file",
          header: this.$t("download.headers.file"),
          slot: "file",
        },
      ];
    },
  },
  methods: {
    ...mapMutations(DOWNLOAD_MODULE, {
      setListParams: SET_LIST_PARAMS,
    }),
    ...mapActions(DOWNLOAD_MODULE, {
      getDownloadList: DOWNLOAD_LIST,
      downloadFiles: DOWNLOAD_FILES,
    }),
    formatDateTime,
    fileTypes() {
      const types = ["I", "A", "PO", "S", "EC", "IFR", "CPE", "IPR", "DN"];
      const list = types.map((type) => {
        return {
          value: type,
          label: this.$t(`download.types.${type}`),
        };
      });
      return list;
    },
    getList(params) {
      params = { ...params, is_file_deleted: false };
      return this.getDownloadList(this.requestQueryParams(params, "dl"));
    },
    fetchFiles() {
      this.loading = true;
      this.downloadFiles(this.selectedFiles.map((file) => file.id))
        .then(() => {
          this.selectedFiles = [];
        })
        .catch((error) => {
          this.selectedFiles = [];
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadFile(link) {
      window.open(link, "_blank").focus();
    },
  },
};
</script>
