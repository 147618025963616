<template>
  <div class="custom-card no-shadow">
    <div class="custom-card-header">
      <div class="card-title">
        {{ $t("contracts.contractForm.costDetails") }}
      </div>
    </div>
    <div class="card-content">
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.contractPrice") }}
            </label>
          </div>
        </div>
        <CurrencyInput
          id="contract_price"
          v-model="costDetails.contractPrice"
          :placeholder="$t('contracts.contractForm.contractPrice')"
        />
      </div>
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.additionalCostNetAmount") }}
            </label>
          </div>

          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>
                {{ $t("contracts.contractForm.additionalCostNetAmount") }}
              </template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{
                      $t(
                        "contracts.contractForm.tooltip.additionalCostNetAmount1"
                      )
                    }}
                  </p>
                  <p class="tooltip-subtext">
                    {{
                      $t(
                        "contracts.contractForm.tooltip.additionalCostNetAmount2"
                      )
                    }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <CurrencyInput
          id="additional_cost_amount"
          v-model="costDetails.additionalCostNetAmount"
          :placeholder="$t('contracts.contractForm.additionalCostNetAmount')"
        />
      </div>
      <div class="cell">
        <RecurrenceInterval v-model="costDetails.recurrence" is-mandatory />
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from "@/components/shared";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { errorMixin } from "@/components/mixins";
import RecurrenceInterval from "@/components/PurchaseRequest/Form/RecurrenceInterval.vue";

export default {
  name: "CostDetails",
  components: {
    RecurrenceInterval,
    CurrencyInput,
  },
  mixins: [errorMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    costDetails: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    errorMessage(validationField) {
      return validationField ? this.error(validationField) : "";
    },
  },
};
</script>

<style scoped>
.page-content .left-content .card-content {
  @apply w-full grid gap-4 grid-cols-2;
}

.tooltip-header {
  @apply text-sm font-medium text-gray-900;
}
.tooltip-subtext {
  @apply mt-1 text-sm text-gray-500 whitespace-normal;
}
</style>
