export const calculateDepartmentsBudgetTotal = (depts, projectBudget) => {
  let departments = depts.slice();
  const totalDepartments = departments.length;

  if (totalDepartments) {
    const budgetInNumber = parseFloat(projectBudget);

    departments = departments.map((department) => {
      const budgetDepartmentTotal =
        budgetInNumber * (department.budgetPercentage / 100);
      return Object.assign({}, department, {
        budgetParticipation: budgetDepartmentTotal,
      });
    });
  }

  return departments;
};

export const calculateDepartmentBudgets = (
  depts,
  projectBudget,
  isBudgetDistributionEvenly
) => {
  let departments = depts.slice();
  const totalDepartments = departments.length;

  if (totalDepartments) {
    if (isBudgetDistributionEvenly) {
      const budgetInNumber = parseFloat(projectBudget);
      let budgetDepartmentTotal = 0;
      let budgetDepartmentPercentage = 0;

      budgetDepartmentTotal = budgetInNumber / totalDepartments;
      budgetDepartmentPercentage =
        (budgetDepartmentTotal / budgetInNumber) * 100;

      departments = departments.map((department) => {
        return Object.assign({}, department, {
          budgetParticipation: budgetDepartmentTotal,
          budgetPercentage: budgetDepartmentPercentage || 0,
        });
      });
    } else {
      departments = calculateDepartmentsBudgetTotal(depts, projectBudget);
    }
  }

  return departments;
};

export const budgetPercentageByNumber = (deptBudget, totalBudget) => {
  return totalBudget ? (deptBudget / totalBudget) * 100 : 0;
};
