<template>
  <div class="custom-card no-shadow">
    <p class="section-title mb-4">{{ $t("global.catalogue") }}</p>
    <div class="-m-5 mt-2">
      <table class="table narrow table-fixed">
        <thead class="table-header">
          <tr>
            <th
              v-for="(columnName, index) in catalogueItemsTableColumns()"
              :key="index"
              scope="col"
            >
              <div class="flex items-center">{{ columnName }}</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr
            v-for="catalogueItem in catalogueItems"
            :key="catalogueItem.id"
            class="group"
          >
            <td>
              <div class="image-holder">
                <img :src="itemImage(catalogueItem)" alt="Catalogue Image" />
              </div>
            </td>
            <td
              :title="catalogueItem.name"
              @click="() => onCatalogueItemClick(catalogueItem)"
            >
              <div
                class="title text-left group-hover:text-primary truncate cursor-pointer"
              >
                {{ catalogueItem.name }}
              </div>
            </td>
            <td>
              <div class="text-sm">
                {{ formattedDate(catalogueItem.createdAt) }}
              </div>
            </td>
            <td>
              <div class="text-left truncate">
                <Category :category="catalogueItem.category" />
                <p
                  v-if="catalogueItem.companySupplier"
                  class="text-gray-500 text-xs 2xl:text-sm"
                >
                  {{ $t("global.prepositions.from") }}:
                  <SupplierName :supplier="catalogueItem.companySupplier" />
                </p>
              </div>
            </td>
            <td>
              <p
                v-if="catalogueItem.itemVariants[0]?.netAmount"
                class="text-primary"
              >
                <i18n-n
                  :value="parseFloat(catalogueItem.itemVariants[0].netAmount)"
                  format="currency"
                  :locale="
                    currencyToLocale(
                      catalogueItem.itemVariants[0].netAmountCurrency
                    )
                  "
                />
              </p>
              <p v-else>-</p>
            </td>
            <td>
              <p
                v-if="catalogueItem.itemVariants[0]?.grossAmount"
                class="text-primary"
              >
                <i18n-n
                  :value="parseFloat(catalogueItem.itemVariants[0].grossAmount)"
                  format="currency"
                  :locale="
                    currencyToLocale(
                      catalogueItem.itemVariants[0].grossAmountCurrency
                    )
                  "
                />
              </p>
              <p v-else>-</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CatalogueItemImage from "@/assets/images/catalogue-item-image-placeholder.png";
import { Category, SupplierName } from "@/components/shared";
import { currencyToLocale } from "@/utils/utility_methods";
import { mapMutations, mapState } from "vuex";
import {
  CATALOGUE_MODULE,
  SET_CATALOGUES_ITEMS_LIST_FILTERS,
} from "@/store/Catalogue/types";

export default {
  name: "CatalogueItems",
  components: {
    SupplierName,
    Category,
  },
  props: {
    catalogueItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, {
      catalogueItemsListFilters: (state) => state.catalogueItemsListFilters,
    }),
  },
  methods: {
    ...mapMutations(CATALOGUE_MODULE, {
      setCatalogueItemsListFilters: SET_CATALOGUES_ITEMS_LIST_FILTERS,
    }),

    catalogueItemsTableColumns() {
      return [
        this.$t("global.image"),
        this.$t("global.name"),
        this.$t("global.dates.date"),
        this.$t("global.category"),
        this.$t("global.pricing.net"),
        this.$t("global.pricing.gross"),
      ];
    },
    itemImage(catalogueItem) {
      if (catalogueItem?.images?.length && catalogueItem.images[0].image)
        return catalogueItem.images[0].image;

      return CatalogueItemImage;
    },
    currencyToLocale,
    onCatalogueItemClick(item) {
      const filters = Object.assign({}, this.catalogueItemsListFilters);
      filters.paginationParams.search = item.name;
      this.setCatalogueItemsListFilters(filters);
      this.$router.push({ name: "Catalogue" });
    },
  },
};
</script>

<style scoped>
.image-holder {
  @apply w-[60px] p-0.5 border border-gray-200 !m-0;
}

.page-content .content-left .table td {
  @apply px-0 text-sm;
}
.page-content .content-left .table td:nth-child(2) {
  @apply font-semibold;
}

.page-content .content-left .table tr th,
.page-content .content-left .table tr td {
  @apply px-2;
}
.page-content .content-left .table tr th:nth-child(1) {
  @apply w-[70px];
}

.page-content .content-left .table tr th:nth-child(3) {
  @apply w-[85px];
}

.page-content .content-left .table tr th:nth-child(5),
.page-content .content-left .table tr th:nth-child(6) {
  width: 90px;
}
</style>
