<template>
  <div
    class="bg-white rounded border border-gray-200 py-4 sm:px-6 sm:py-6 h-full"
  >
    <div class="flex justify-between items-center">
      <h2 class="truncate text-base font-medium text-gray-500">
        {{ heading }}
      </h2>
      <slot name="stats" />
    </div>
    <ChartLoader v-if="loading" />
    <div v-else>
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="series"
        :height="280"
      />
    </div>
  </div>
</template>

<script>
import { ChartLoader } from "@/components/shared";
import { mapState } from "vuex";
import { SHOPPING_LIST_MODULE } from "@/store/ShoppingList/types";

export default {
  name: "RadialBar",
  components: { ChartLoader },
  props: {
    heading: { type: String, required: true },
    loading: { type: Boolean, default: false },
    series: { type: Array, required: true },
    colors: { type: Array, required: true },
    labels: { type: Array, required: true },
    description: { type: String, default: "" },
    totalRecords: { type: Number, default: 0 },
  },
  computed: {
    ...mapState(SHOPPING_LIST_MODULE, ["shoppingListVisible"]),
    chartOptions() {
      return {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: this.shoppingListVisible ? 60 : 40,
            startAngle: -40,
            endAngle: 280,
            hollow: {
              margin: 5,
              size: "35%",
              background: "transparent",
            },
            dataLabels: {
              name: { show: true },
              value: { show: true },
              total: {
                show: true,
                label: this.$t("global.pricing.total"),
                formatter: () => this.totalRecords,
              },
            },
          },
        },
        colors: this.colors,
        labels: this.labels,
        legend: {
          show: true,
          floating: true,
          fontSize: "14px",
          position: "top",
          labels: {
            useSeriesColors: false,
          },
          formatter: (seriesName, opts) => {
            return (
              seriesName +
              ":  " +
              Math.round(
                (opts.w.globals.series[opts.seriesIndex] / 100) *
                  this.totalRecords
              )
            );
          },
          itemMargin: {
            vertical: 1,
            horizontal: 2,
          },
        },
      };
    },
  },
};
</script>
