<template>
  <List />
</template>

<script>
import List from "@/components/Contracts/List";

export default {
  name: "Contracts",
  components: { List },
};
</script>
