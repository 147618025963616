import store from "@/store";
import { FEATURE_MODULE } from "@/store/Feature/types";

export const componentToFeatureFlagMapping = (componentName) => {
  return {
    Contracts: "contracts",
    Proposals: "polls",
    InvoiceDashboard: "invoiceApproval",
    ShoppingList: "shoppingList",
    Projects: "projects",
    ConnectedSuppliers: "integratedSupplier",
    LegalTendencies: "legalEntity",
  }[componentName];
};

export const isPremium = (component) => {
  const featureFlagName = componentToFeatureFlagMapping(component);
  if (!featureFlagName) return false;

  const featureFlagGetter = store.getters[`${FEATURE_MODULE}/featureFlagValue`];

  return (
    !featureFlagGetter(featureFlagName) &&
    featureFlagGetter("isActiveWithoutPackages")
  );
};

export const getRouteTo = (component) => {
  return isPremium(component)
    ? `/premium-feature/${componentToFeatureFlagMapping(component)}/`
    : { name: component };
};
