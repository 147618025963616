<template>
  <Button
    :size-class="'sm'"
    color="transparent"
    :on-click-handler="toggleModal"
  >
    <div
      :class="[
        'flex text-primary font-semibold hover:text-primarydark items-center',
      ]"
    >
      {{ buttonText }}
      <PoSVG class="h-5 w-5 xl:mr-2" aria-hidden="true" />
      <span v-if="showText">
        {{ $tc("global.purchaseOrderShort", 2) }}
      </span>
    </div>
  </Button>
  <Modal :show-modal="showModal" :close-modal="toggleModal">
    <template #header
      >{{ $tc("global.purchaseOrder", 2) }} @
      {{ invoice.invoiceNumber }}</template
    >
    <template #icon>
      <ClipboardListIcon class="text-white h-6 w-6" />
    </template>
    <template #body>
      <PoList
        :invoice="invoice"
        :select-invoice-po-for-edit="selectInvoicePoForEdit"
        :set-visible-state="setVisibleState"
        :toggle-modal="toggleModal"
      />
    </template>
  </Modal>
</template>
<script>
import { Modal } from "@/components/shared";
import PoList from "@/components/InvoiceApproval/poList.vue";
import PoSVG from "@/assets/images/PoSVG";
import { ClipboardListIcon } from "@heroicons/vue/outline";
import { Button } from "@/components/shared";
export default {
  components: { Modal, ClipboardListIcon, PoList, PoSVG, Button },
  props: {
    buttonText: { type: String, required: true },
    poRelations: { type: Array, default: () => [] },
    invoice: { type: Object, default: () => {} },
    selectInvoicePoForEdit: { type: Function, default: () => {} },
    setVisibleState: { type: Function, default: () => {} },
    showText: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
