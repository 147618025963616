<template>
  <div v-auto-animate class="invoice-holder">
    <div :class="['h-full w-[40rem] text-center ', { 'w-1/2': editing }]">
      <InvoicePreview class="mb-1" :invoice="invoice" />
      <span
        v-if="!editing"
        class="flex items-center justify-center gap-x-6"
      >
        <Button
          :loading="loading"
          color="gray"
          :on-click-handler="closeModal"
          >{{ $t("buttons.close") }}</Button
        >
        <Button :loading="loading" :on-click-handler="saveAction">{{
          $t("buttons.save")
        }}</Button>
      </span>
    </div>
    <div v-if="editing" :class="['flex overflow-auto', { 'w-1/2': editing }]">
      <div class="flex w-full" style="min-height: 16rem">
        <Form
          :invoice-object="invoice"
          :close-action="closeForm"
          :show-assign-button="false"
          :save-callback="saveInvoiceObject"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Form, InvoicePreview } from "@/components/InvoiceApproval/index.js";
import { Button } from "@/components/shared";
export default {
  components: { Form, InvoicePreview, Button },
  inject: ["addInvoiceToPo"],
  props: {
    closeModal: { type: Function, default: () => {} },
    invoice: { type: Object, required: true },
  },
  data() {
    return { editing: false, loading: false };
  },
  methods: {
    async saveAction() {
      this.loading = true;
      await this.addInvoiceToPo(this.invoice.files);
      this.loading = false;
    },
    closeForm() {
      this.editing = false;
    },
  },
};
</script>

<style scoped>
.invoice-holder {
  @apply flex content-container justify-center h-[45rem];
}

.content-container {
  max-height: calc(100vh - 335px) !important;
}
</style>
