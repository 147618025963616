import * as TYPES from "./types.js";

export default {
  [TYPES.SAVE_PRODUCT]: (state, payload) => {
    const index = payload?.index >= 0 ? payload.index : state.currentList.length;
    const data = payload?.data;
    state.currentList[index] = Object.assign({}, data);
  },
  [TYPES.REMOVE_PRODUCT]: (state, index) => {
    state.currentList.splice(index, 1);
  },
  [TYPES.RESET_SHOPPING_LIST]: (state) => {
    state.currentList = [];
  },
  [TYPES.SET_SHOPPING_LIST]: (state, payload) => {
    state.shoppingLists[payload.id] = payload;
  },
  [TYPES.SET_SHOPPING_LISTS]: (state, payload) => {
    payload.forEach((list) => {
      state.shoppingLists[list.id] = list;
    });
  },
  [TYPES.TOGGLE_VISIBLE_STATE]: (state) => {
    state.shoppingListVisible = !state.shoppingListVisible;
  },

  [TYPES.SET_SHOPPING_LIST_DATA]: (state, data) => {
    const object = Object.keys(data).includes("storeState")
      ? data.object
      : data;
    state.shoppingLists[object.id] = object;
  },
};
