import { DATEV_STATUS } from "@/utils/constants.js";

export const datevStatus = (datevJob, trans) => {
  const status = datevJob.status;
  switch (status) {
    case DATEV_STATUS.INITIALIZED:
      return {
        color: "bg-hivebuy-yellow",
        textColor: "text-gray-600",
        text: trans("purchaseOrder.datevStatuses.initialized"),
      };
    case DATEV_STATUS.JOB_CREATED:
      return {
        color: "bg-hivebuy-yellow",
        textColor: "text-gray-600",
        text: trans("purchaseOrder.datevStatuses.initialized"),
      };
    case DATEV_STATUS.FILES_UPLOADED:
      return {
        color: "bg-hivebuy-yellow",
        textColor: "text-gray-600",
        text: trans("purchaseOrder.datevStatuses.uploaded"),
      };
    case DATEV_STATUS.JOB_SUCCESSFUL:
      return {
        color: "bg-hivebuy-green",
        textColor: "text-white",
        text: trans("purchaseOrder.datevStatuses.finalized"),
      };
    case DATEV_STATUS.JOB_PARTIALLY_SUCCESSFUL:
      return {
        color: "bg-orange-400",
        textColor: "text-white",
        text: trans("purchaseOrder.datevStatuses.finalized"),
      };
    case DATEV_STATUS.JOB_FAILED:
      return {
        color: "bg-hivebuy-red",
        textColor: "text-white",
        text: trans("purchaseOrder.datevStatuses.failed"),
      };
    case DATEV_STATUS.UNKNOWN_ERROR:
      return {
        color: "bg-hivebuy-red",
        textColor: "text-white",
        text: trans("purchaseOrder.datevStatuses.failed"),
      };
    case DATEV_STATUS.INTERNAL_ERROR:
      return {
        color: "bg-hivebuy-red",
        textColor: "text-white",
        text: trans("purchaseOrder.datevStatuses.failed"),
      };
    default:
      return "Error";
  }
};
