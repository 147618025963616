<template>
  <div v-if="!areNotificationsLoaded" class="card-loading-section">
    <div class="h-24 w-24">
      <HivebuyLoader />
    </div>
  </div>
  <template v-else>
    <div class="header-wrapper">
      <h3 class="card-title">
        {{ $t("profile.notificationSettings.heading") }}
      </h3>
      <p class="card-sub-title">
        {{ $t("profile.notificationSettings.text") }}
      </p>
    </div>
    <section
      id="daily_summary"
      class="py-4 px-4 text-base justify-between flex items-center"
    >
      <div>
        <span class="font-semibold flex items-center gap-x-2"
          >{{ $t(`profile.notificationSettings.dailyEmailSummaryTitle`) }}
          <span
            v-if="!user.dailyEmailSummary"
            class="text-xs border rounded-lg border-gray-300 px-1.5 py-1 text-gray-700 bg-gray-100 uppercase"
            >{{ $t("global.prepositions.inactive") }}</span
          >
          <span
            v-if="user.dailyEmailSummary"
            class="text-xs border rounded-lg border-green-500 px-1.5 py-1 text-white bg-hivebuy-green uppercase"
            >{{ $t("global.prepositions.active") }}</span
          >
        </span>
        <p class="text-sm">
          {{ $t(`profile.notificationSettings.dailyEmailSummaryText`) }}
        </p>
      </div>
      <div class="px-4">
        <SwitchCheckbox
          :checked="user.dailyEmailSummary"
          :on-click-handler="() => updateDailySummary()"
          :loading="emailSummaryLoading"
          state-icons
        />
      </div>
    </section>
    <table class="table">
      <thead class="table-header">
        <tr>
          <th
            v-for="columnName in tableColumnsGeneral"
            :key="columnName"
            scope="col"
          >
            {{ columnName }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr
          v-for="notificationSetting in notificationsGeneral"
          :key="notificationSetting.notificationType"
        >
          <td>
            {{
              $t(
                `profile.notificationSettings.settings.${notificationSetting.notificationType}`
              )
            }}
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'emailNotificationAllowed'
                ) && !user.dailyEmailSummary
              "
              :checked="notificationSetting.notifyThroughEmail"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughEmail', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughEmail',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'slackNotificationAllowed'
                )
              "
              :checked="notificationSetting.notifyThroughSlack"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughSlack', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughSlack',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'msTeamsNotificationAllowed'
                )
              "
              :checked="notificationSetting.notifyThroughTeams"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughTeams', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughTeams',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-if="isFeatureAllowed('invoiceApproval')" class="table">
      <thead class="table-header">
        <tr>
          <th
            v-for="columnName in tableColumnsInvoice"
            :key="columnName"
            scope="col"
          >
            {{ columnName }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr
          v-for="notificationSetting in notificationsInvoices"
          :key="notificationSetting.notificationType"
        >
          <td>
            {{
              $t(
                `profile.notificationSettings.settings.${notificationSetting.notificationType}`
              )
            }}
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'emailNotificationAllowed'
                ) && !user.dailyEmailSummary
              "
              :checked="notificationSetting.notifyThroughEmail"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughEmail', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughEmail',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'slackNotificationAllowed'
                )
              "
              :checked="notificationSetting.notifyThroughSlack"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughSlack', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughSlack',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
          <td>
            <SwitchCheckbox
              v-if="
                isNotificationAllowed(
                  notificationSetting.notificationType,
                  'msTeamsNotificationAllowed'
                )
              "
              :checked="notificationSetting.notifyThroughTeams"
              :on-click-handler="
                () =>
                  updateNotification('notifyThroughTeams', notificationSetting)
              "
              :loading="
                isNotificationBeingUpdated(
                  'notifyThroughTeams',
                  notificationSetting
                )
              "
              state-icons
            />
            <p v-else>-</p>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  AUTH_MODULE,
  GET_USER_NOTIFICATION_SETTINGS,
  UPDATE_USER_NOTIFICATION_SETTING,
  UPDATE_ME,
  SET_USER,
} from "@/store/Auth/types";
import HivebuyLoader from "@/components/shared/HivebuyLoader";
import { SwitchCheckbox } from "@/components/shared";
import { snakeToCamel } from "@/utils/utility_methods";

export default {
  name: "NotificationSettings",
  components: { HivebuyLoader, SwitchCheckbox },
  data() {
    return {
      notificationLoading: {},
      dailyEmailSummary: false,
      emailSummaryLoading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["userNotificationSettings", "user"]),
    areNotificationsLoaded() {
      return !this.isApiLoading() && this.userNotificationSettings.length;
    },

    tableColumnsGeneral() {
      return [
        this.$t("profile.notificationSettings.general"),
        "Email",
        "Slack",
        "MS Teams",
      ];
    },
    tableColumnsInvoice() {
      return [
        this.$t("profile.notificationSettings.invoices"),
        "Email",
        "Slack",
        "MS Teams",
      ];
    },

    notificationsGeneral() {
      return this.notificationSettings.filter((setting) => {
        return setting.type === "general";
      });
    },

    notificationsInvoices() {
      return this.notificationSettings.filter((setting) => {
        return setting.type === "invoices";
      });
    },

    editableSettings() {
      const settings = {
        purchaseOrderReminders: {
          enabled: false,
          slackNotificationAllowed: false,
          emailNotificationAllowed: false,
          msTeamsNotificationAllowed: false,
          type: "general",
        },
        purCreatedRequester: {
          enabled: true,
          slackNotificationAllowed: false,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemCreatedApprover: {
          enabled: this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purCreatedApprover: {
          enabled: true && !this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemRejectedApprover: {
          enabled: !this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purCreatedApproverUpdated: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purComment: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: false,
          type: "general",
        },
        purCommentMention: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purApprovalApprovedNextApprover: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purApprovedRequester: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purRejectedRequester: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purRejectedApprover: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purDelegatedUser: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purFinalApprovalRequester: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purFinalApprovalResultUser: {
          enabled: true,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemDelegatedUser: {
          enabled: false && this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemApprovedRequester: {
          enabled: this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemRejectedRequester: {
          enabled: this.isFeatureAllowed("itemLevelApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purItemCreatedApproverUpdated: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userAcceptedInvite: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: false,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userInviteSent: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: false,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userDepartmentAdded: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userDepartmentRemoved: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userProjectAdded: {
          enabled: false && this.isFeatureAllowed("projects"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        userProjectRemoved: {
          enabled: false && this.isFeatureAllowed("projects"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        personalBudgetUpdate: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        budgetExceeding80Percent: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        pollMemberAdded: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        pollSuggestionAdded: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        pollClosed: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },

        invCreatedApprover: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invRejectedApprover: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invDelegatedUser: {
          enabled: false && this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invApprovedRequester: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invRejectedRequester: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invApprovalApprovedNextApprover: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invAskForRevision: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invOcrFailed: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invOcrValidationsFailed: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invOcrScoresFailed: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invGlobalChecksFailed: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invOrderNotFound: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        invApprovalReminders: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        gmailInvoiceCreated: {
          enabled: this.isFeatureAllowed("invoiceApproval"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        contractCancellationReminder: {
          enabled: false && this.isFeatureAllowed("contracts"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        shoppingListCreated: {
          enabled: false && this.isFeatureAllowed("shoppingList"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        shoppingListCompleted: {
          enabled: false && this.isFeatureAllowed("shoppingList"),
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        purApprovalReminders: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "general",
        },
        ipoCreatedApprover: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoRejectedApprover: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoDelegatedUser: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoApprovedRequester: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoRejectedRequester: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoAskForRevision: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
        ipoCreatedApproverUpdated: {
          enabled: false,
          slackNotificationAllowed: true,
          emailNotificationAllowed: true,
          msTeamsNotificationAllowed: true,
          type: "invoices",
        },
      };
      return settings;
    },
    notificationSettings() {
      return this.userNotificationSettings
        .map((setting) => {
          if (setting.notificationType === "BUDGET_EXCEEDING_80_PERCENT") {
            setting.notificationType = "budgetExceeding80Percent";
          } else {
            setting.notificationType = setting.notificationType.includes("_")
              ? snakeToCamel(setting.notificationType)
              : setting.notificationType;
          }

          if (this.editableSettings[setting.notificationType]) {
            setting.type = this.editableSettings[setting.notificationType].type;
          }
          return setting;
        })
        .filter(
          (setting) => this.editableSettings[setting.notificationType]?.enabled
        );
    },
  },
  mounted() {
    !this.userNotificationSettings.length &&
      this.fetchUserNotificationSettings();
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      getUserNotificationSettings: GET_USER_NOTIFICATION_SETTINGS,
      updateUserNotificationSetting: UPDATE_USER_NOTIFICATION_SETTING,
    }),
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    isNotificationAllowed(notificationType, notification) {
      if (
        notification == "slackNotificationAllowed" &&
        !this.user.isSlackConnected
      )
        return false;
      if (
        notification == "msTeamsNotificationAllowed" &&
        !this.user.isMsTeamsConnected
      )
        return false;

      return this.editableSettings[notificationType][notification];
    },
    isNotificationBeingUpdated(name, notificationSetting) {
      if (!this.notificationLoading[notificationSetting.id]) return false;
      return name === this.notificationLoading[notificationSetting.id];
    },
    fetchUserNotificationSettings() {
      this.setApiLoading({ key: "component", value: true });
      this.getUserNotificationSettings().then(() => {
        this.setApiLoading({ key: "component", value: false });
      });
    },

    updateDailySummary() {
      const payload = {
        dailyEmailSummary: !this.user.dailyEmailSummary,
      };
      this.emailSummaryLoading = true;
      this.updateMe({ payload })
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.emailSummaryLoading = false));
    },

    updateNotification(name, notificationSetting) {
      this.notificationLoading[notificationSetting.id] = name;
      const setting = Object.assign({}, notificationSetting, {
        [name]: !notificationSetting[name],
      });
      const payload = {
        notifyThroughSlack: setting.notifyThroughSlack,
        notifyThroughEmail: setting.notifyThroughEmail,
        notifyThroughTeams: setting.notifyThroughTeams,
      };

      this.updateUserNotificationSetting({
        notificationId: notificationSetting.id,
        payload,
      })
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error))
        .finally(
          () => (this.notificationLoading[notificationSetting.id] = null)
        );
    },
  },
};
</script>

<style scoped>
.card-loading-section {
  @apply flex justify-center items-center;
  height: calc(100vh - 220px);
}

.header-wrapper {
  @apply p-4 border-b border-gray-200;
}

.card-title {
  @apply text-sm text-primary font-semibold mb-1;
}
.card-sub-title {
  @apply text-sm text-gray-500;
}

.table .table-body tr td {
  @apply text-sm;
}

.table .table-body tr td:nth-child(1) {
  @apply w-[250px] max-w-[220px] md:w-auto md:max-w-none whitespace-normal;
}

.table .table-body tr td:nth-child(2) {
  @apply w-[100px] md:w-[150px];
}

.table .table-body tr td:nth-child(3) {
  @apply w-[100px] md:w-[150px];
}

.table .table-body tr td:nth-child(4) {
  @apply w-[100px] md:w-[150px];
}

.table-header {
  @apply !text-primary;
}
</style>
