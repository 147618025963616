<template>
  <div class="custom-card no-shadow flex flex-col justify-between">
    <div class="flex items-center justify-between">
      <h3 class="section-title text-base">
        <div class="flex items-center">
          <div class="flex h-12 w-12 items-center ml-2">
            <WebhookSVG class="h-8 w-8 mr-1" />
          </div>
          <div>
            {{ $t("companyManagement.integrations.webhook") }}
          </div>
        </div>
      </h3>
      <div class="flex ml-4 -mt-3">
        <SwitchCheckbox
          v-model="webhookStatus"
          class="mb-2 sm:mb-0"
          :label="statusText"
          state-icons
          :loading="loading"
        />
      </div>
    </div>
    <div class="flex flex-col justify-between h-full gap-y-3">
      <div class="border border-gray-200 bg-gray-50 p-4 rounded-md h-full">
        <div class="flex flex-col justify-content h-full">
          <div class="text-gray-600 object-bottom">
            {{ $t("companyManagement.integrations.webhookText") }}
          </div>
        </div>
      </div>
      <CollapseTransition>
        <div v-if="isFeatureAllowed('webhooks')">
          <Button
            :on-click-handler="goToWebhooks"
            :loading="loading"
            class="relative flex border bg-hivebuy-red border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-white disabled buttonhelper"
          >
            {{ $t("button.configure") }}
          </Button>
        </div>
      </CollapseTransition>
    </div>
  </div>
</template>

<script>
import WebhookSVG from "@/assets/images/WebhookSVG";
import { Button, SwitchCheckbox } from "@/components/shared";
import { mapActions, mapState } from "vuex";
import { FEATURE_MODULE, UPDATE_FEATURE_FLAG } from "@/store/Feature/types";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "Webhook",
  components: {
    WebhookSVG,
    SwitchCheckbox,
    Button,
    CollapseTransition,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(FEATURE_MODULE, {
      webhooks: (state) => state.featureFlags.webhooks,
    }),
    statusText() {
      return this.webhookStatus ? "Enabled" : "Disabled";
    },
    webhookStatus: {
      get() {
        return this.webhooks.allowed;
      },
      set(value) {
        this.webhookFlagChange(value);
      },
    },
  },
  methods: {
    ...mapActions(FEATURE_MODULE, {
      updateFeatureFlag: UPDATE_FEATURE_FLAG,
    }),
    webhookFlagChange(value) {
      this.loading = true;
      const payload = {
        flagId: this.webhooks?.flagId || this.webhooks?.id,
        flagStatus: { allowed: value },
        flagName: "webhooks",
      };
      this.updateFeatureFlag(payload).then(() => (this.loading = false));
    },
    goToWebhooks() {
      this.$router.push({ name: "Webhooks" });
    },
  },
};
</script>
