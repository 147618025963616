<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column bg-hivebuy-yellow text-white">
        <OrderSVG class="h-4 w-4" />
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex">
          <span class="text-gray-400 mr-1"> {{ $t("timeline.sentBy") }} </span>
          <span class="text-gray-900 font-semibold">
            <UserWithAvatar :user="details.createdBy" :show-name-only="true" />
          </span>
        </div>
        <div class="text-xs text-gray-500 my-1">
          <TimeDisplay :time="details.createdAt || details.statusDate" />
        </div>
        <p v-if="!autoProcessing" class="text-sm md:text-xs text-gray-800 mt-2">
          {{ details?.reason }}
        </p>
        <p v-else class="text-sm md:text-xs text-gray-800 mt-2">
          {{ $t("purchaseRequest.summary.timeline.sentByHivebuyReason") }}
        </p>
      </div>
      <div class="avatar-column flex">
        <UserWithAvatar
          :user="details.createdBy"
          :avatar-dimension="9"
          show-tooltip
          class="cursor-help"
        />
        <div
          v-if="autoProcessing"
          class="bg-white flex items-center rounded-full h-9 w-9 -ml-2 p-1 border"
        >
          <img src="@/assets/images/hivebuy_logo.svg" alt="Hivebuy" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UserWithAvatar, TimeDisplay } from "@/components/shared/index";
import { timeDifference } from "@/utils/timeHelper.js";
import { memberDetailsMixin } from "@/components/mixins/index";
import OrderSVG from "@/assets/images/OrderSVG.vue";
import { fullName } from "@/utils/utility_methods";
export default {
  components: { OrderSVG, UserWithAvatar, TimeDisplay },
  mixins: [memberDetailsMixin],
  props: {
    details: { type: Object, required: true },
    autoProcessing: { type: Boolean, default: false },
  },
  data() {
    return { sentBy: "N/A" };
  },
  mounted() {
    this.setSentByName();
  },
  methods: {
    timeDifference,
    async setSentByName() {
      this.sentBy = fullName(
        await this.getMemberDetails(this.details?.createdBy)
      );
    },
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
