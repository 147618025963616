<template>
  <div class="tabs-holder">
    <div class="border-b border-gray-200">
      <nav class="tabs" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[tab.component === current ? 'tab--active' : '', 'tab group']"
          @click="() => onTabChange(tab)"
        >
          <component
            :is="tab.icon"
            :class="[
              tab.component === current
                ? 'text-primary'
                : 'text-gray-400 group-hover:text-primary',
              '-ml-0.5 mr-2 h-5 w-5',
            ]"
            aria-hidden="true"
          />
          <span>
            {{ tab.name }}
          </span>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    onTabChange: {
      type: Function,
      required: true,
    },

    current: {
      type: String,
      required: true,
    },
  },
};
</script>
