import * as FeatureTypes from "@/store/Feature/types.js";
import { updateFeatureFlag } from "@/utils/services/models/features";

export default {
  [FeatureTypes.UPDATE_FEATURE_FLAG]: ({ commit }, payload) => {
    const { flagId, flagStatus, flagName } = payload;
    return new Promise((resolve, reject) => {
      updateFeatureFlag(flagId, flagStatus, flagName, commit, resolve, reject);
    });
  },
};
