<template>
  <TransitionRoot
    as="template"
    :show="showModal"
    :close-modal="closeModal"
    :confirm="confirmModal"
  >
    <Dialog
      as="div"
      static
      class="fixed z-999 inset-0 overflow-y-auto"
      :open="showModal"
      @close="closeModal()"
    >
      <div
        class="flex justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-8 pr-4">
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                @click="closeModal()"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                :class="classes.bg"
              >
                <component
                  :is="classes.icon"
                  class="h-6 w-6"
                  :class="classes.text"
                />
              </div>
              <div
                class="text-center sm:mt-0 sm:mr-8 sm:text-left md:text-center w-full"
              >
                <DialogTitle
                  as="h3"
                  class="modal-heading text-left"
                  :class="classes.text"
                >
                  {{ title }}
                </DialogTitle>
                <div class="mt-4">
                  <p class="text-center text-sm text-gray-500">{{ text }}</p>
                </div>
                <div class="mt-4 mb-4">
                  <p class="text-md text-gray-500 font-medium text-center">
                    {{ itemToConfirm }}
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex justify-center">
              <Button
                id="cancel-button"
                class="mr-3"
                type="button"
                :on-click-handler="closeModal"
                :color="'gray'"
                :loading="loading"
              >
                {{ $t("buttons.cancel") }}
              </Button>
              <Button
                id="confirm-button"
                type="button"
                :on-click-handler="confirmModal"
                :color="classes.button"
                :loading="loading"
              >
                {{ $t("buttons.confirm") }}
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ExclamationIcon,
  XIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/outline";
import Button from "@/components/shared/Button.vue";
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    XIcon,
    Button,
  },
  props: {
    text: {
      type: String,
      default: null,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    confirmModal: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    itemToConfirm: {
      type: String,
      default: null,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      if (this.danger) {
        return {
          bg: "bg-red-100",
          text: "text-red-500",
          button: "danger",
          icon: ExclamationIcon,
        };
      }
      if (this.success) {
        return {
          bg: "bg-green-100",
          text: "text-hivebuy-green",
          button: "success",
          icon: CheckCircleIcon,
        };
      }
      if (this.info) {
        return {
          bg: "bg-gray-100",
          text: "text-primary",
          button: "primary",
          icon: InformationCircleIcon,
        };
      }
      return {
        bg: "bg-red-100",
        text: "text-red-500",
        button: "danger",
        icon: ExclamationIcon,
      };
    },
  },
};
</script>

<style></style>
