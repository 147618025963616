<template>
  <div v-if="isContractLoaded" id="new-contract" class="flex h-full w-full">
    <div class="flex flex-col h-full w-full">
      <div class="flex items-center justify-between">
        <router-link to="/contracts">
          <h3 class="page-title hover:text-primary flex">
            <ChevronLeftIcon class="w-6 h-6 cursor-pointer translate-y-0.5" />
            {{ isEdit ? contract?.name : $t("contracts.contractForm.new") }}
          </h3>
        </router-link>
      </div>

      <div class="page-content">
        <div class="left-content">
          <GeneralDetails v-model="contract.generalDetails" :v="v$" />
          <RuntimeDetails v-model="contract.runtimeDetails" :v="v$" />
          <CostDetails v-model="contract.costDetails" :v="v$" />
          <LicenseDetails v-model="contract.licenseDetails" :v="v$" />

          <div class="flex justify-center">
            <Button
              :on-click-handler="onCancel"
              :color="'gray'"
              :disabled="loading"
            >
              {{ $t("shared.cancelButton") }}
            </Button>
            <Button :on-click-handler="onSave" class="ml-4" :loading="loading">
              {{ $t("shared.saveButton") }}
            </Button>
          </div>
        </div>
        <div v-if="isEdit" class="right-content">
          <div class="custom-card no-shadow overflow-hidden h-full">
            <div class="-m-5 h-[calc(100%+2.5rem)] overflow-hidden rounded-md">
              <div
                v-if="!contract?.generalDetails?.files?.length"
                class="text-primary flex flex-1 flex-col justify-center items-center place-content-center py-10"
              >
                <PhotographIcon class="h-20 w-20 text-gray-300" />
                <p class="text-gray-400 text-sm">
                  {{ $t("contracts.contractForm.uploadFile") }}
                </p>
              </div>
              <PDFViewer
                v-else-if="isPdfFile(file)"
                :pdf-link="fileUrl(file.file)"
                :show-download="true"
              />
              <img v-else :src="fileUrl(file.file)" class="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loader">
    <HivebuyLoader class="w-36" />
  </div>
</template>

<script>
import { email, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import GeneralDetails from "@/components/Contracts/ContractForm/GeneralDetails";
import RuntimeDetails from "@/components/Contracts/ContractForm/RuntimeDetails";
import LicenseDetails from "@/components/Contracts/ContractForm/LicenseDetails";
import { mapActions } from "vuex";
import {
  CONTRACT_MODULE,
  GET_CONTRACT,
  SAVE_CONTRACT,
} from "@/store/Contract/types";
import {
  isObjectEmpty,
  isValuePresent,
  removeKeysFromObject,
  scrollToError,
} from "@/utils/utility_methods";
import { ChevronLeftIcon } from "@heroicons/vue/solid";
import {
  CONTRACT_STATUS_TYPES,
  CONTRACT_TYPES,
  PRICE_FIELDS,
} from "@/utils/constants";
import { contractMixin } from "@/components/mixins";
import { HivebuyLoader } from "@/components/shared";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import CostDetails from "@/components/Contracts/ContractForm/CostDetails.vue";

export default {
  name: "ContractForm",
  components: {
    GeneralDetails,
    RuntimeDetails,
    LicenseDetails,
    ChevronLeftIcon,
    HivebuyLoader,
    CostDetails,
  },
  mixins: [contractMixin],
  data() {
    return {
      v$: useValidate(),
      contract: {},
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    isContractLoaded() {
      if (!this.isEdit) return true;
      return !this.isApiLoading() && !isObjectEmpty(this.contract);
    },
    isEdit() {
      return !!this.$route.params?.id;
    },
    file() {
      return this.contract.generalDetails.files[0];
    },
  },
  created() {
    this.isEdit ? this.fetchContract() : this.setInitialValues();
  },
  methods: {
    ...mapActions(CONTRACT_MODULE, {
      saveContract: SAVE_CONTRACT,
      getContract: GET_CONTRACT,
    }),
    fetchContract() {
      this.setApiLoading({ key: "component", value: true });
      this.getContract(this.$route.params.id)
        .then((data) => {
          this.contract = data;
          this.setInitialValues();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "component", value: false }));
    },
    async onSave() {
      await this.v$.$validate();
      if (this.v$.$error) {
        this.scrollToError();
      } else {
        this.loading = true;
        this.saveContract(this.contractPayload())
          .then(() => {
            if (this.isEdit) {
              this.$router.push({
                name: "ViewContract",
                params: { id: this.contract.id },
              });
            } else {
              this.$router.push({ name: "Contracts" });
            }
            this.showNotification(this.$t("toasts.genericSuccess"));
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
    //   onCancel() {
    //     if (this.isEdit) {
    //       this.$router.push({
    //         name: "ViewContract",
    //         params: { id: this.contract.id },
    //       });
    //     } else {
    //       this.$router.push({ name: "Contracts" });
    //     }
    //   },
    //   scrollToError,
    // },
    contractPayload() {
      let payload = Object.assign(
        {},
        this.contract.generalDetails,
        this.contract.runtimeDetails,
        this.contract.licenseDetails,
        this.contract.costDetails,
        { id: this.contract?.id }
      );

      if (!isValuePresent(payload.netAmount)) {
        payload = removeKeysFromObject(payload, PRICE_FIELDS);
      }

      if (parseInt(payload.additionalCostNetAmount)) {
        payload.additionalCostNetAmountCurrency = payload.grossAmountCurrency;
      }

      if (payload.recurrence?.interval === "O") {
        payload.recurrence.interval = "";
      }

      if (payload.recurrence?.validTo) {
        payload.recurrence.validTo = new Date(
          payload.recurrence.validTo
        ).toLocaleDateString("fr-CA");
      }

      if (payload.noticePeriodType) {
        const {
          noticePeriodType: type,
          noticePeriodCount: count,
          ...updatedPayload
        } = payload;

        payload = updatedPayload;
        payload.noticePeriod = { type, count: parseInt(count) };
      }

      return payload;
    },
    setInitialValues() {
      this.setGeneralDetails();
      this.setLicenseDetails();
      this.setRuntimeDetails();
      this.setCostDetails();
    },
    setGeneralDetails() {
      this.contract.generalDetails = {
        name: this.contract?.name || null,
        owner: this.contract?.owner || this.user.id,
        partner: this.contract?.partner || null,
        partnerEmail: this.contract?.partnerEmail || null,
        contractNumber: this.contract?.contractNumber || null,
        status: this.contract?.status || "P",
        contractType: this.contract?.contractType || null,
        category: this.contract?.category || null,
        companySupplier: this.contract?.companySupplier || null,
        files: this.contract?.files || [],
        description: this.contract?.description || null,
      };
    },
    setLicenseDetails() {
      this.contract.licenseDetails = {
        licenseAmount: this.contract?.licenseAmount || null,
        freeLicenseAmount: this.contract?.freeLicenseAmount || null,
        approvalNeeded: this.contract?.approvalNeeded || false,
        netAmount: this.contract?.netAmount || null,
        grossAmount: this.contract?.grossAmount || null,
        vat: this.contract?.vat || null,
        replaceResult: this.contract?.replaceResult || false,
      };
    },
    setRuntimeDetails() {
      this.contract.runtimeDetails = {
        startDate: this.contract?.startDate || null,
        endDate: this.contract?.endDate || null,
        noticePeriodType: this.contract?.noticePeriod?.type || null,
        noticePeriodCount: this.contract?.noticePeriod?.count || null,
        reminder: this.contract?.reminder || false,
        reminderTime: this.contract?.reminderTime || null,
      };
    },
    setCostDetails(){
      this.contract.costDetails = {
        contractPrice: this.contract?.contractPrice || 0,
        additionalCostNetAmount: this.contract.additionalCostNetAmount || 0,
        recurrence: this.contract?.recurrence || {},
      }
    },
    onCancel() {
      if (this.isEdit) {
        this.$router.push({
          name: "ViewContract",
          params: { id: this.contract.id },
        });
      } else {
        this.$router.push({ name: "Contracts" });
      }
    },
    scrollToError,
  },
  validations() {
    const validations = {
      contract: {
        generalDetails: {
          name: { required },
          owner: { required },
          contractType: { required },
          status: { required },
          partnerEmail: { email },
        },
      },
      runtimeDetails: {},
    };

    if (this.contract?.generalDetails?.status == CONTRACT_STATUS_TYPES.active) {
      validations.contract.generalDetails.partner = { required };
      validations.contract.runtimeDetails = Object.assign(
        {},
        validations.contract.runtimeDetails,
        {
          startDate: { required },
          endDate: { required },
        }
      );
    }
    if (this.contract?.runtimeDetails?.noticePeriodType) {
      validations.contract.runtimeDetails = Object.assign(
        {},
        validations.contract.runtimeDetails,
        {
          noticePeriodCount: { required },
        }
      );
    }

    if (this.contract?.generalDetails?.contractType == CONTRACT_TYPES.Unit) {
      validations.contract.licenseDetails = {
        licenseAmount: { required },
        grossAmount: {
          minValue: (val) => !!val,
        },
      };
    }
    return validations;
  },
};
</script>

<style scoped>
.page-content {
  @apply flex w-full h-full gap-x-4 overflow-hidden;
}

.page-content .left-content {
  @apply flex-1 flex flex-col gap-y-4 h-full overflow-auto;
}

.page-content .right-content {
  @apply w-1/2 flex flex-col gap-y-4;
}
</style>
