<template>
  <TitleInput
    :suggestion="suggestion"
    :save-method="confirmSave"
    :open="open"
    :close-function="toggleFunction"
  />
</template>

<script>
import TitleInput from "@/components/PurchaseRequest/SummarySections/TitleInput.vue";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_DATA,
} from "@/store/PurchaseRequest/types.js";
import { mapMutations } from "vuex";
export default {
  components: {
    TitleInput,
  },
  props: {
    suggestion: {
      type: String,
      required: false,
      default: "",
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: Function,
      required: true,
    },
  },

  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCurrentRequestData: SET_CURRENT_REQUEST_DATA,
    }),
    confirmSave(value) {
      this.toggleFunction();
      this.setCurrentRequestData({ field: "title", value: value });
      this.saveMethod(false);
    },
  },
};
</script>
