<template>
  <div v-if="!isInPreview">
    <Timeline
      v-if="shouldDisplayTimeline"
      :object="object"
      :is-auto-processing="isAutoProcessing"
    />
    <Budget
      v-if="shouldDisplayBudget"
      :budget-level="object.budgetLevel"
      :budget="object.budget"
      :currency="company.currency"
      :action-required="object.actionRequired"
      :is-in-budget="isInBudget"
      :total-net-amount="object.totalNetAmount"
      :is-purchase-order="object.purchaseOrder != null"
      :on-budget-update="onBudgetUpdate"
      :purchase-request="request"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { Timeline, Budget } from "@/components/PurchaseRequest";
import { TimelineMixin } from "@/components/mixins";
export default defineComponent({
  components: { Timeline, Budget },

  mixins: [TimelineMixin],
  props: {
    object: {
      type: Object,
      required: true,
    },
    onBudgetUpdate: {
      type: Function,
      default: () => {},
    },
    isAutoProcessing: {
      type: Boolean,
      default: false,
    },
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isInPreview() {
      return this.object?.requestStatus === "PV";
    },
    isProduct() {
      return Object.keys(this.object).includes("requestStatus");
    },
    shouldDisplayTimeline() {
      const key = this.isProduct ? "requestStatus" : "status";
      return this.object[key] != "C";
    },

    isInBudget() {
      const { purchaseOrder, isItemLevelApprover } = this.request;
      const budgetObject =
        !isItemLevelApprover && purchaseOrder ? purchaseOrder : this.object;
      return purchaseOrder ? budgetObject.wasInBudget : budgetObject.isInBudget;
    },

    shouldDisplayBudget() {
      if (!this.isFeatureAllowed("budget")) return false;

      const { purchaseOrder } = this.request;
      const { budgetPlanningEnabled } = this.object;
      return (
        budgetPlanningEnabled &&
        (purchaseOrder?.wasInBudget != null || this.object.isInBudget != null)
      );
    },
  },
});
</script>
