<template>
  <ActionablePurchaseRequests />
  <ActionablePurchaseOrders />
  <ActionableInvoices v-if="isFeatureAllowed('invoiceApproval')" />
  <MyPurchaseRequests />
</template>

<script>
import ActionablePurchaseRequests from "@/components/Dashboard/AdvanceUserDashboard/ActionablePurchaseRequests";
import ActionablePurchaseOrders from "@/components/Dashboard/AdvanceUserDashboard/ActionablePurchaseOrders";
import ActionableInvoices from "@/components/Dashboard/AdvanceUserDashboard/ActionableInvoices";
import MyPurchaseRequests from "@/components/Dashboard/MyPurchaseRequests";
export default {
  components: {
    MyPurchaseRequests,
    ActionablePurchaseRequests,
    ActionablePurchaseOrders,
    ActionableInvoices,
  },
};
</script>
