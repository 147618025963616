export const headers = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const zipFileHeaders = () => {
  return {
    "Content-Type": "application/x-zip-compressed",
  };
};
export const multipartHeaders = () => {
  return {
    headers: { "Content-Type": "multipart/form-data" },
  };
};

export const pdfFileHeaders = () => {
  return {
    "Access-Control-Allow-Origin": "http://localhost:8080",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
  };
};

export const RESTRICTED_PAGES = [
  "purchase-request",
  "project-view",
  "proposals",
  "contracts",
  "requests",
];
