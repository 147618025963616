<template>
  <SuggestionForm
    ref="editSuggestionForm"
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-suggestion="onUpdate"
    :is-edit="true"
    :suggestion-to-edit="suggestionToEdit.items && suggestionToEdit.items[0]"
  />
</template>

<script>
import { mapActions } from "vuex";

import SuggestionForm from "@/components/Proposals/ProposalBoard/SuggestionForm";
import {
  PROPOSAL_MODULE,
  UPDATE_SUGGESTION_PURCHASE_REQUEST,
} from "@/store/Proposal/types";

export default {
  components: {
    SuggestionForm,
  },

  props: {
    suggestionToEdit: {
      type: Object,
      default: () => {},
    },
    suggestionId: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    fetchProposal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      updateSuggestionPurchaseRequest: UPDATE_SUGGESTION_PURCHASE_REQUEST,
    }),
    onUpdate(suggestion) {
      const payload = {
        purchaseRequest: {
          id: this.suggestionToEdit.id,
          items: [this.suggestionPayload(suggestion)],
        },
      };
      this.updateSuggestionPurchaseRequest({
        suggestionId: this.suggestionId,
        payload,
      })
        .then(() => {
          this.showNotification("Suggestion has been updated successfully");
          this.fetchProposal();
          this.$refs.editSuggestionForm.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error));
    },
    suggestionPayload(suggestion) {
      const {
        id,
        name,
        description,
        link,
        netAmount,
        grossAmount,
        vat,
        currency,
        images,
        files,
      } = suggestion;

      return {
        id,
        name,
        description,
        link,
        images,
        files,
        itemPrices: [
          {
            netAmount,
            grossAmount,
            vat,
            netAmountCurrency: currency,
            grossAmountCurrency: currency,
            quantity: 1,
          },
        ],
        unit: "pc",
      };
    },
  },
};
</script>
