import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { HTable } from "design-buy";
import {
  PURCHASE_ORDER_MODULE,
  GET_PURCHASE_ORDERS,
  SET_PURCHASE_ORDERS_LIST_PARAMS,
} from "@/store/PurchaseOrder/types";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_FILTERED_COMPANY_SUPPLIERS,
} from "@/store/CompanyManagement/types";

import { UserWithAvatar } from "@/components/shared/index";

import PurchaseRequestQuickView from "@/components/PurchaseRequest/PurchaseRequestList/PurchaseRequestQuickView";

import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import { isObject } from "@/utils/utility_methods";
import "design-buy/dist/hivebuy.css";

import { AUTH_MODULE } from "@/store/Auth/types";

const ORDER_LIST_COLUMNS = [
  {
    field: "title",
    header: "purchaseOrder.table.title",
    slot: "title",
    filterConfig: { type: "", props: {} },
    visible: true,
  },
  {
    field: "requestedFor",
    header: "global.requestedForShort",
    slot: "requestedFor",
    filterConfig: {
      type: "select",
      model: "user",
      props: { mode: "multiple" },
    },
    visible: true,
  },
];

export default {
  mixins: [TableFilterMixin],
  components: {
    HTable,
    PurchaseRequestQuickView,
    UserWithAvatar,
  },
  data() {
    return {
      quickViewOrder: null,
      showQuickView: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "companySuppliersListFilters",
      "legalTendencies",
    ]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryList",
      "usersList",
      "companySuppliersList",
    ]),
    ...mapGetters(AUTH_MODULE, ["getUserPreference"]),
    shouldExecuteSupplierSearchApi() {
      return (
        this.companySuppliersListFilters.pageSize <
        this.companySuppliersListFilters.totalDbRecords
      );
    },
    columns() {
      if (this.$options.name === "OrdersList") {
        return ORDER_LIST_COLUMNS;
      }

      const keyName =
        this.$options.name === "PurchaseOrdersList"
          ? "purchaseOrderAccountingView"
          : "invoiceAccountingView";

      return this.getUserPreference(keyName);
    },
    columnsDetails() {
      return this.columns.map((col) => {
        if (!col.header) return col;

        return Object.assign({}, col, {
          header: this.$t(col.header),
          filterConfig: this.mappedFilterOptions(col.filterConfig),
        });
      });
    },
    visibleColumns() {
      return this.columnsDetails.filter((column) => {
        if (column.field !== "id") return column.visible;

        return this.isFeatureAllowed("sepa") || this.isFeatureAllowed("datev");
      });
    },
    tableModelData() {
      return {
        PurchaseOrdersList: {
          data: this.purchaseOrders,
          lazyParams: this.purchaseOrdersListParams,
          mutationCallback: this.setPurchaseOrderListParams,
        },
        InvoiceList: {
          data: this.invoicesTableList,
          lazyParams: this.invoiceListParams,
          mutationCallback: this.setInvoiceListParams,
        },
        OrdersList: {
          data: this.purchaseOrders,
          lazyParams: this.purchaseOrdersListParams,
          mutationCallback: this.setPurchaseOrderListParams,
        },
      }[this.$options.name];
    },
  },
  methods: {
    isObject,
    ...mapActions(PURCHASE_ORDER_MODULE, {
      getPurchaseOrders: GET_PURCHASE_ORDERS,
    }),
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getFilteredSuppliers: GET_FILTERED_COMPANY_SUPPLIERS,
    }),
    ...mapMutations(PURCHASE_ORDER_MODULE, {
      setPurchaseOrderListParams: SET_PURCHASE_ORDERS_LIST_PARAMS,
    }),
    handleDateLogic(key, value, keyMapper, rangeKeys, newParams) {
      let mappedKey;
      if (Array.isArray(value.value)) {
        value.value.forEach((date, index) => {
          const updatedDate = date || value.value[index - 1];
          mappedKey =
            keyMapper[`${key}_${rangeKeys[Object.keys(rangeKeys)[index]]}`];
          newParams = this.assignFilterValue(
            newParams,
            mappedKey,
            updatedDate.toISOString()
          );
        });
      } else {
        mappedKey = `${keyMapper[key]}_Gte`;
        newParams = this.assignFilterValue(
          newParams,
          mappedKey,
          value.value.toISOString()
        );
      }
      return newParams;
    },
    fetchOrders(params) {
      return this.getPurchaseOrders({
        params: this.requestQueryParams(params),
        formatSameParams: this.$options.name === "PurchaseOrdersList",
      });
    },
    modelList(name) {
      const getter = {
        category: this.categoryList,
        user: this.usersList,
        supplier: this.companySuppliersList,
        invoiceStatus: this.invoiceStatusList,
        legalEntity: this.legalTendencyList,
      }[name];

      return getter().map((obj) => {
        return {
          label: obj.name,
          value: obj.id,
        };
      });
    },
    legalTendencyList() {
      return Object.values(this.legalTendencies);
    },
    mapSearchMethod(model) {
      const searchMethodMapping = {
        supplier: {
          searchMethod: this.onSupplierSearch,
          shouldExecuteApiSearch: this.shouldExecuteSupplierSearchApi,
        },
      };

      return (
        searchMethodMapping[model] || {
          searchMethod: () => ({}),
          shouldExecuteApiSearch: false,
        }
      );
    },
    mappedFilterOptions(config) {
      const configProps = Object.assign({}, config.props);

      if (["advancedBool", "bool"].includes(config.type)) {
        if (config.type === "advancedBool" && configProps.descriptionConfig) {
          const descriptionConfig = { truthText: "", falseText: "" };

          Object.keys(descriptionConfig).forEach((key) => {
            const statusKey = this.$t(configProps.label)
              .replace(/\?/g, "")
              .toLowerCase();
            descriptionConfig[key] = this.$t(`purchaseOrder.table.${key}`, {
              status: statusKey,
            });
          });

          return Object.assign({}, config, {
            props: Object.assign({}, configProps, {
              label: this.$t(configProps.label),
              descriptionConfig,
            }),
          });
        }

        return Object.assign({}, config, {
          props: Object.assign({}, configProps, {
            label: this.$t(configProps.label),
          }),
        });
      }
      if (config.type == "listbox") {
        const componentProps = {
          options: this.modelList(config.model),
          onSearch: this.mapSearchMethod(config.model).searchMethod,
          shouldExecuteApiSearch: this.mapSearchMethod(config.model)
            .shouldExecuteApiSearch,
        };
        if (config.toggleText) {
          componentProps.toggleText = this.$t(config.toggleText);
        }
        return Object.assign({}, config, {
          props: Object.assign({}, config.props, componentProps),
        });
      }
      if (config.type !== "select") return config;
      return Object.assign({}, config, {
        props: Object.assign({}, config.props, {
          options: this.modelList(config.model),
        }),
      });
    },

    setQuickView(purchaseOrder) {
      this.quickViewOrder = purchaseOrder;
      this.showQuickView = true;
    },
    setDetailView(purchaseOrder) {
      this.$router.push({
        name: "RequestDetail",
        params: { id: purchaseOrder.id },
      });
    },

    invoiceStatusList() {
      const statuses = ["O", "A", "R", "P", "RV", "NI", "N"];
      return statuses.map((status) => {
        return {
          id: status,
          name: this.$t(`statuses.invoice.${status}`),
        };
      });
    },
    async onSupplierSearch(search) {
      const suppliers = search
        ? await this.getFilteredSuppliers({ search })
        : this.companySuppliersList();
      const supplierOptions = suppliers.map((supplier) => {
        return {
          label: supplier.name,
          value: supplier.id,
        };
      });
      return Promise.resolve(supplierOptions);
    },
  },
};
