<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-5">
      <div class="col-span-1">
        <label class="block text-base text-gray-700 mb-2"
          >{{ $t("purchaseRequest.detail.whoProduct") }}
          <span v-if="isMandatory" class="text-red-600">*</span></label
        >
        <div>
          <button
            id="requestedForDepartment"
            class="transition duration-500 ease-in-out hover:bg-primary hover:text-white"
            type="button"
            :class="[
              'relative inline-flex items-center px-3 py-2 rounded-l border border-gray-300 focus:outline-none  text-base font-medium focus:z-10 w-1/3 justify-center',
              {
                'bg-primary text-white':
                  requestedForSelectedOption('department'),
                'bg-white text-gray-500':
                  !requestedForSelectedOption('department'),
              },
            ]"
            @click="setRequestedForOption('department')"
          >
            <span class="sr-only">{{
              $t("purchaseRequest.detail.department")
            }}</span>
            {{ $t("purchaseRequest.detail.department") }}
          </button>
          <button
            id="requestedForProject"
            type="button"
            class="transition duration-500 ease-in-out hover:bg-primary hover:text-white"
            :class="[
              '-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 text-base font-medium focus:z-10 focus:outline-none w-1/3 justify-center',
              {
                'bg-primary text-white': requestedForSelectedOption('project'),
                'bg-white text-gray-500':
                  !requestedForSelectedOption('project'),
              },
            ]"
            @click="setRequestedForOption('project')"
          >
            <span class="sr-only">
              {{ $t("purchaseRequest.detail.project") }}
            </span>
            {{ $t("purchaseRequest.detail.project") }}
          </button>
          <button
            id="requestedForUser"
            type="button"
            class="transition duration-500 ease-in-out hover:bg-primary hover:text-white"
            :class="[
              '-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 text-base font-medium focus:z-10 focus:outline-none w-1/3 justify-center',
              {
                'bg-primary text-white': requestedForSelectedOption('user'),
                'bg-white text-gray-500': !requestedForSelectedOption('user'),
              },
            ]"
            @click="setRequestedForOption('user')"
          >
            <span class="sr-only">
              {{ $t("purchaseRequest.detail.user") }}</span
            >
            {{ $t("purchaseRequest.detail.user") }}
          </button>
        </div>
      </div>
      <div class="col-span-1">
        <label class="block text-base text-gray-700 mb-2">{{
          $t("purchaseRequest.detail.requestedFor")
        }}</label>
        <MemberSelector
          v-if="requestedForOptions.options"
          class="w-full"
          :value="requestedFor || ''"
          :on-value-change="(val) => (requestedFor = val)"
          :members-list="requestedForOptions.options"
          :placeholder="requestedForOptions.label"
          :object="true"
        />
        <FieldError v-if="error" :text="error" />
      </div>
    </div>
    <!-- <div v-else class="grid lg:grid-cols-4 grid-cols-2 mt-4 ml-7">
      <h1 class="font-medium text-gray-500">Requested For</h1>
      <div>
        <RequestedForDisplay :product="product" :readOnly="false" />
      </div>
    </div> -->
    <SelectDepartment
      :open="showSelectDepartmentModal"
      :departments="requestedForDepartments"
      :close-select-dept-modal="closeSelectDeptModal"
      :set-department="setDepartment"
    />
  </div>
</template>
<script>
import { FieldError, MemberSelector } from "@/components/shared/index";
import SelectDepartment from "@/components/PurchaseRequest/Form/SelectDepartment.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { PROJECT_MODULE } from "@/store/Project/types";

export default {
  components: {
    FieldError,
    SelectDepartment,
    MemberSelector,
  },
  props: {
    error: { type: String, default: "" },
    showOptions: { type: Boolean, default: true },
    requestedForValue: {
      type: Object,
      default: () => {},
    },
    requestedForOptionValue: { type: String, required: true },
    setDepartment: { type: Function, required: true },
    setProject: { type: Function, required: true },
    changeHandler: { type: Function, required: true },
    product: {
      type: Object,
      default: () => {},
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requestedFor: this.requestedForValue,
      requestedForOption: this.requestedForOptionValue,
      showSelectDepartmentModal: false,
      requestedForDepartments: [],
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments", "users"]),
    ...mapGetters(PROJECT_MODULE, ["projectsList"]),
    requestedForOptions() {
      return {
        department: {
          label: "Choose Department",
          options: this.departments,
          optionLabel: "name",
        },
        user: {
          label: "Choose User",
          options: this.users,
          optionLabel: "name",
        },
        project: {
          label: "Choose project",
          options: this.projectsList(),
          optionLabel: "name",
        },
      }[this.requestedForOption];
    },
  },
  watch: {
    requestedForOption: {
      handler(val) {
        this.saveValue("requestedForOption", val);
        this.defaultRequestedFor();
      },
    },
    requestedFor: {
      handler(val) {
        this.saveValue("requestedFor", val);

        if (this.requestedFor && this.requestedForOption !== "project") {
          this.setProject(null);
        }

        if (this.requestedFor && this.requestedForOption === "project") {
          this.setDepartment(null);
          this.setProject(this.requestedFor);
          return;
        }

        if (!this.requestedFor || this.requestedForOption !== "user") {
          if (this.requestedForOption === "department" && this.requestedFor) {
            this.setDepartment(this.requestedFor);
            this.setProject(null);
          }
          return;
        }

        if (this.requestedFor && this.requestedForOption === "user") {
          if (this.requestedFor.departments.length === 0) {
            // user without department
            this.setDepartment(null);
            return;
          }

          if (this.requestedFor.departments.length === 1) {
            this.setDepartment(this.requestedFor.departments[0]);
            return;
          }
        }

        this.showSelectDepartmentModal =
          this.requestedFor.departments.length > 1;

        if (this.showSelectDepartmentModal) {
          this.requestedForDepartments = this.requestedFor.departments;
        } else {
          // fallback, we can not determine the department of this PuR
          this.setDepartment(null);
        }
      },
    },
  },
  created() {
    this.defaultRequestedFor();
  },
  methods: {
    closeSelectDeptModal() {
      this.showSelectDepartmentModal = false;
    },
    requestedForSelectedOption(option) {
      return this.requestedForOption === option;
    },
    setRequestedForOption(option) {
      this.requestedFor = null;
      this.requestedForOption = option;
      this.defaultRequestedFor();
    },
    saveValue(attr, value) {
      this.changeHandler({
        target: { name: attr, value: value },
      });
    },
    defaultRequestedFor() {
      if (this.requestedFor && this.requestedFor.id) return;
      const requestedForMapping = {
        user: this.user,
        department: this.user.departments[0],
        project: this.projectsList()[0],
      };

      this.requestedFor = requestedForMapping[this.requestedForOption];
    },
  },
};
</script>
