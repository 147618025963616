<template>
  <router-link
    v-for="contract in itemsArray"
    :key="contract.id"
    :to="{
      name: 'ViewContract',
      params: { id: contract.id },
    }"
    class="group item"
    @click="handleResultClick"
  >
    <span class="title">
      {{ contract.name }}
      <span class="number">
        {{ contract.contractNumber }}
      </span>
    </span>
    <span class="type">{{ formattedDate(contract.createdAt) }}</span>
    <span class="badge">{{ $t("global.contract") }}</span>
  </router-link>
</template>
<script>
export default {
  props: {
    contracts: { type: Array, required: true },
    handleResultClick: { type: Function, required: true },
    numberOfRecords: { type: Number, default: 10 },
  },
  computed: {
    itemsArray() {
      return this.contracts.slice(0, this.numberOfRecords);
    },
  },
};
</script>

<style scoped>
.item {
  @apply py-2 hover:bg-gray-100 transition ease-in-out duration-150 flex truncate items-center px-4 justify-between;
}

.title {
  @apply text-sm font-semibold text-gray-700 truncate group-hover:text-primary;
}
.number {
  @apply block text-gray-600 text-xs font-normal;
}
.type {
  @apply text-gray-500 text-xs pl-2;
}
.badge {
  @apply bg-hivebuy-red px-2 py-1 text-xs text-white rounded hidden;
}
</style>
