<template>
  <div class="max-w-xl mx-auto py-16 sm:py-24">
    <div class="text-center">
      <p class="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
        404 error
      </p>
      <h1
        class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
      >
        This page does not exist.
      </h1>
      <p class="mt-2 text-lg text-gray-500">
        The page you are looking for could not be found.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped></style>
