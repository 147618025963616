<template>
  <div
    class="bg-white rounded border border-gray-200 py-4 sm:px-6 sm:py-6 h-full"
  >
    <div class="flex justify-between">
      <h2 class="truncate text-base font-medium text-gray-500">
        {{ heading }}
      </h2>
      <slot name="stats" />
    </div>
    <ChartLoader v-if="loading" />
    <div v-else>
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="series"
        :height="280"
      />
    </div>
  </div>
</template>
<script>
import { ChartLoader } from "@/components/shared/index";

export default {
  components: { ChartLoader },
  props: {
    heading: { type: String, required: true },
    loading: { type: Boolean, default: false },
    series: { type: Array, required: true },
    labels: { type: Array, required: true },
    colors: {
      type: Array,
      required: true,
      default: () => ["#f6d282", "#4dbbaf", "#9e4770"],
    },
    description: { type: String, default: "" },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  fontSize: "14px",
                  color: "#373d3f",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: true,
          position: "top",
          fontSize: "14px",
          horizontalAlign: "center",
          itemMargin: {
            horizontal: 5,
            vertical: 2,
          },
        },
        labels: this.labels,
        colors: this.colors,
      };
    },
  },
};
</script>
