<template>
  <Popover v-slot="{ open }" class="relative z-40" :placement="'left'">
    <PopoverButton
      :class="[
        open ? 'text-gray-900' : 'text-gray-500',
        'group inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none ',
        buttonClass,
      ]"
    >
      <span>{{ buttonText }}</span>
      <ChevronDownIcon
        :class="[
          open ? 'text-gray-600' : 'text-gray-400',
          'ml-1 h-4 w-4 group-hover:text-gray-500',
        ]"
        aria-hidden="true"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <PopoverPanel
        class="absolute z-50 transform -translate-x-2/3 px-2 w-[400px] sm:px-0 rounded-md border border-gray-200 shadow overflow-scroll"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {{ $t("invoice.validations.checked") }}
              </th>
              <th
                scope="col"
                class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {{ $t("invoice.validations.correct") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="[validation, details] in Object.entries(validations)"
              :key="validation"
            >
              <td
                class="py-3 pl-4 pr-3 text-sm font-medium text-gray-700 sm:pl-6 w-[300px]"
              >
                {{ $t(`invoice.validations.${validation}`) }}
                <div
                  v-if="details.note"
                  class="whitespace-pre-wrap mt-0.5 flex font-light text-gray-500"
                >
                  <InformationCircleIcon
                    class="h-4 w-4 flex-shrink-0 text-gray-400"
                  />
                  {{ details.note }}
                </div>
              </td>
              <td class="px-3 py-3 text-sm text-gray-500">
                <component
                  :is="icon(details.required).name"
                  :class="['h-6 w-6', icon(details.required).color]"
                  aria-hidden="true"
                />
              </td>
              <td class="px-3 py-3 text-sm text-gray-500">
                <component
                  :is="icon(details.validated).name"
                  :class="['h-6 w-6', icon(details.validated).color]"
                  aria-hidden="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronDownIcon, InformationCircleIcon } from "@heroicons/vue/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    CheckCircleIcon,
    XCircleIcon,
    InformationCircleIcon,
  },
  props: {
    validations: { type: Object, required: true },
  },
  computed: {
    validationScores() {
      const requiredValidations = Object.values(this.validations).filter(
        (validation) => validation.required
      );
      const validValidations = requiredValidations.filter(
        (validation) => validation.validated
      );
      return { requiredValidations, validValidations };
    },
    buttonText() {
      const { requiredValidations, validValidations } = this.validationScores;
      return `Validation Score: ${validValidations.length}/${requiredValidations.length}`;
    },
    buttonClass() {
      const { requiredValidations, validValidations } = this.validationScores;
      const ratio = validValidations.length / requiredValidations.length;
      switch (true) {
        case ratio > 0.9:
          return "text-hivebuy-green";
        case ratio > 0.6:
          return "text-hivebuy-yellow";
        default:
          return "text-hivebuy-red";
      }
    },
  },
  methods: {
    icon(checkIcon) {
      return checkIcon
        ? { name: "CheckCircleIcon", color: " text-hivebuy-green" }
        : { name: "XCircleIcon", color: " text-hivebuy-red" };
    },
  },
};
</script>
<style scoped>
.max-z-index {
  z-index: 9999;
}
</style>
