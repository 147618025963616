<template>
  <div id="purchase-request-list">
    <PurchaseRequestList />
  </div>
</template>

<script>
import PurchaseRequestList from "@/components/PurchaseRequest/PurchaseRequestList/List";

export default {
  components: { PurchaseRequestList },
};
</script>
