import { defineDirectives } from "./directives";
import { defineGlobalComponents } from "./globalComponents";
import { defineGlobalMixins } from "./globalMixins";
import { defineGlobalProperties } from "./globalProperties";
import { initSentry } from "./sentry";

export class AppVariables {
  constructor(instance, router) {
    initSentry(instance, router);

    this.instance = instance;
    this.router = router;
  }

  initAppVariables() {
    defineGlobalMixins(this.instance);
    defineGlobalProperties(this.instance);
    defineGlobalComponents(this.instance);
    defineDirectives(this.instance);
    return this.instance;
  }
}
