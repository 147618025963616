<template>
  <div class="flex flex-col">
    <div class="custom-card">
      <div class="-m-5">
        <table class="table">
          <thead class="table-header">
            <tr>
              <th
                v-for="(columnName, index) in tableColumns()"
                :key="index"
                scope="col"
              >
                <div class="flex items-center">
                  {{ columnName }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr>
              <td colspan="5" align="center" class="search-holder">
                <div class="relative sticky-search">
                  <SearchInput
                    :value="paginationParams.search"
                    :on-change="onSearchInput"
                    :disabled="isLoading"
                    :placeholder="$t('proposals.search')"
                  />
                </div>
              </td>
            </tr>
            <TableRowsLoader v-if="isLoading" :rows="5" :columns="4" />
            <tr v-else-if="!isLoading && !areProposalsPresent">
              <td colspan="5" align="center">
                <p class="bg-white px-6 py-4 w-full font-medium text-gray-700">
                  {{ $t("general.noRecordsFound") }}
                </p>
              </td>
            </tr>
            <tr
              v-for="proposal in proposals"
              v-else
              :key="proposal.id"
              class="group cursor-pointer"
              @click="() => onViewProposal(proposal.id)"
            >
              <td :title="proposal.title">
                <div
                  class="flex items-center justify-between title group-hover:text-primary"
                >
                  <div class="mr-4 truncate title-wrapper">
                    {{ proposal.title }}
                  </div>
                  <span>
                    <span class="inline-flex col-span-1">
                      <ChevronRightIcon
                        class="w-5 h-5 text-gray-200 cursor-pointer hover:text-primary"
                        @click="() => onViewProposal(proposal.id)"
                      />
                    </span>
                  </span>
                </div>
              </td>
              <td>
                <UserWithAvatar
                  v-if="proposal.createdBy"
                  :user="proposal.createdBy"
                  :avatar-dimension="10"
                  :show-tooltip="true"
                />
              </td>
              <td>
                <StatusTag :status="proposal.status" type="proposal" />
              </td>
              <td>
                <div class="members-list">
                  <div class="members-holder">
                    <div
                      v-for="member in limitedMemberList(proposal)"
                      :key="member.id"
                      class="flex flex-row overflow-hidden -ml-2"
                    >
                      <UserWithAvatar
                        :user="member"
                        :show-tooltip="true"
                        :avatar-dimension="10"
                      />
                    </div>
                    <div
                      v-if="proposal.members.length > maxAmountMembersInList"
                      class="text-sm font-medium text-gray-600 rounded-full inline-flex items-center justify-center bg-gray-300 h-9 w-9 border border-gray-400"
                    >
                      +{{ proposal.members.length - maxAmountMembersInList }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="paginationParams.page < paginationParams.totalPages"
        id="load-more-section"
      >
        <LoadMoreSection
          :load-more="loadMore"
          :load-more-btn-loading="loadMoreBtnLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import {
  GET_PROPOSALS,
  PROPOSAL_MODULE,
  SET_PROPOSALS_LIST_FILTERS,
} from "@/store/Proposal/types";
import { loadMoreFiltersMixin } from "@/components/mixins/index.js";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import {
  UserWithAvatar,
  TableRowsLoader,
  LoadMoreSection,
} from "@/components/shared/index";
import StatusTag from "@/components/shared/StatusTag";

export default {
  components: {
    StatusTag,
    ChevronRightIcon,
    TableRowsLoader,
    SearchInput,
    UserWithAvatar,
    LoadMoreSection,
  },
  mixins: [loadMoreFiltersMixin],
  data() {
    return {
      isLoading: false,
      maxAmountMembersInList: 6,
    };
  },
  computed: {
    ...mapState(PROPOSAL_MODULE, {
      proposals: (state) => state.proposals,
      proposalsListFilters: (state) => state.proposalsListFilters,
      paginationParams: (state) => state.proposalsListFilters.paginationParams,
    }),
    areProposalsPresent() {
      return this.proposals.length;
    },
  },
  created() {
    !this.proposals.length && this.fetchProposals();
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      getProposals: GET_PROPOSALS,
    }),
    ...mapMutations(PROPOSAL_MODULE, {
      setProposalsListFilters: SET_PROPOSALS_LIST_FILTERS,
    }),
    limitedMemberList(proposal) {
      return proposal.members.slice(0, this.maxAmountMembersInList);
    },
    fetchProposals(loadMore = false) {
      if (!loadMore) {
        this.isLoading = true;
      }

      const payload = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getProposals(payload)
        .then(() => {
          this.areFiltersUpdated = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loadMoreBtnLoading = false;
          this.isLoading = false;
        });
    },
    requestQueryParams() {
      const params = Object.assign({}, this.paginationParams);

      if (this.areFiltersUpdated) {
        params.page = 1;
      }

      return params;
    },
    tableColumns() {
      return [
        this.$t("proposals.list.table.title"),
        this.$t("proposals.list.table.createdBy"),
        this.$t("proposals.list.table.status"),
        this.$t("proposals.list.table.members"),
      ];
    },
    updateFilters(key, value, parentKey = null) {
      const payload = this.createFiltersPayload(
        this.proposalsListFilters,
        key,
        value,
        parentKey
      );

      this.setProposalsListFilters(payload);

      /* Pagination Params key is only updated when requests are already fetched,
         so added check to prevent api call again.
       */
      if (key != "paginationParams") {
        if (key === "page") {
          this.fetchProposals(true);
        } else {
          this.areFiltersUpdated = true;
          this.fetchProposals();
        }
      }
    },
    onViewProposal(proposalId) {
      this.$router.push({ name: "ProposalBoard", params: { id: proposalId } });
    },
  },
};
</script>

<style scoped>
.members-list {
  @apply flex pl-2 flex-col overflow-hidden flex-1;
}

.members-holder {
  @apply flex  flex-row items-center;
}
</style>
