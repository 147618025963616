<template>
  <CardSection
    :loading="loading"
    :records="myPurchaseRequests"
    :title="$t('dashboard.headerMyPurchaseRequests')"
    :total-records-count="totalRecords"
  >
    <template #col-tickets-content>
      <ul>
        <li
          v-for="purchaseRequest in myPurchaseRequests"
          :key="purchaseRequest.id"
        >
          <div class="ticket purchase-request">
            <router-link
              :to="{
                name: 'RequestDetail',
                params: { id: purchaseRequest.id },
              }"
            >
              <div
                class="ticket-header group cursor-pointer"
                :style="getStatusColor(purchaseRequest.status)"
              >
                <div class="ticket-header-left">
                  <div class="ticket-title">
                    {{ purchaseRequest.title }}
                  </div>
                  <div class="ticket-meta">
                    {{ formattedDate(purchaseRequest.requestedAt) }}
                  </div>
                </div>
                <div
                  class="ticket-header-right text-primary group-hover:border-primary group-hover:bg-primary group-hover:text-white transition duration-300"
                >
                  <ChevronRightIcon class="w-4 h-4" />
                </div>
              </div>
            </router-link>
            <div class="ticket-content">
              <ul class="content-listing">
                <li>
                  <div class="lab">{{ $t("global.category") }}</div>
                  <div class="val truncate">
                    <Category :purchase-request="purchaseRequest" squashed />
                  </div>
                </li>
                <li>
                  <div class="lab">{{ $t("global.supplier") }}</div>
                  <div class="val truncate">
                    <SupplierName :purchase-request="purchaseRequest" />
                  </div>
                </li>
                <li>
                  <div class="lab">{{ $t("global.requestedFor") }}</div>
                  <div class="val">
                    <RequestedForDisplay
                      :product="purchaseRequest.items[0]"
                      :avatar-dimension="6"
                      :text-classes="['!text-xs !font-semibold !text-gray-600']"
                      show-name
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="ticket-footer">
              <div class="ticket-footer-left">
                <i18n-n
                  :value="parseFloat(purchaseRequest.totalAmount || 0)"
                  format="currency"
                  :locale="
                    currencyToLocale(purchaseRequest.totalAmountCurrency)
                  "
                />
              </div>
              <div class="ticket-footer-right">
                <div class="card-footer-right">
                  <StatusTag
                    :status="getStatusInfo(purchaseRequest).status"
                    :type="getStatusInfo(purchaseRequest).type"
                    :classes="'text-xs'"
                  />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </CardSection>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { mapActions, mapState, mapGetters } from "vuex";
import { Category, SupplierName } from "@/components/shared";
import { currencyToLocale } from "@/utils/utility_methods";
import CardSection from "@/components/Dashboard/AdvanceUserDashboard/CardSection";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import {
  DASHBOARD_MODULE,
  GET_MY_PURCHASE_REQUESTS,
} from "@/store/Dashboard/types";
export default {
  name: "MyPurchaseRequests",
  components: {
    CardSection,
    ChevronRightIcon,
    RequestedForDisplay,
    Category,
    SupplierName,
  },
  data() {
    return {
      loading: false,
      hoverOnCard: false,
      color: {
        "--bgColor": "#4CBAAE",
      },
    };
  },
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    ...mapState(DASHBOARD_MODULE, {
      myRequests: (state) => state.myPurchaseRequests.results,
      totalRecords: (state) => state.myPurchaseRequests.totalRecords,
    }),
    myPurchaseRequests() {
      return this.myRequests.map((request) => {
        return this.requestDetails(request);
      });
    },
  },
  mounted() {
    !this?.myPurchaseRequests?.length && this.fetchMyPurchaseRequests();
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      getMyPurchaseRequests: GET_MY_PURCHASE_REQUESTS,
    }),
    fetchMyPurchaseRequests() {
      this.loading = true;
      this.getMyPurchaseRequests(this.paginationParams())
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    paginationParams() {
      return {
        pageSize: 8,
      };
    },

    getStatusInfo(pur) {
      const result = {
        type: "request",
        status: pur.status,
      };
      if (pur.purchaseOrder) {
        result.type = "order";
        result.status = pur.purchaseOrder.status;
      }
      return result;
    },

    getStatusColor(status) {
      return {
        P: {
          "--bgColor": "#F6D281",
        },
        S: {
          "--bgColor": "#F6D281",
        },
        O: {
          "--bgColor": "#4CBAAE",
        },
        D: {
          "--bgColor": "#4CBAAE",
        },
        R: {
          "--bgColor": "#EB6464",
        },
        CA: {
          "--bgColor": "#e5e7eb",
        },
        CO: {
          "--bgColor": "#4CBAAE",
        },
        A: {
          "--bgColor": "#4CBAAE",
        },
      }[status[0].toUpperCase()];
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.ticket.purchase-request .ticket-header::before {
  background-color: var(--bgColor);
}

.ticket.purchase-request .ticket-footer-right .button {
  @apply justify-center text-primary flex items-center;
}

.ticket.purchase-request .ticket-header-right svg {
  @apply cursor-pointer;
}

.purchase-request .ticket-content .content-listing li {
  @apply border-b border-gray-200 px-4 py-2 flex justify-between items-center text-xs text-gray-600 gap-x-2 xl:gap-x-4;
}

.purchase-request .ticket-content .content-listing li .val {
  @apply font-semibold;
}
</style>
