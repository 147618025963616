import store from "@/store/index.js";
import { DOWNLOAD_MODULE, SET_UPLOAD_STATUS } from "@/store/Downloads/types";

export const importCompletedWithoutErrors = (data) => {
  store.commit(
    `${DOWNLOAD_MODULE}/${SET_UPLOAD_STATUS}`,
    {
      storeState: data.modelName,
      inProgress: false,
      error: false,
      value: null,
    },
    {
      root: true,
    }
  );
};
