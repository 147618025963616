<template>
  <Tip theme="tooltip">
    <template #header>
      {{ contract?.name }}
    </template>
    <template #placeholder>
      {{ contract?.name }}
    </template>
    <template #content>
      <div v-if="contract" class="page-content max-w-xs">
        <div class="content-left">
          <div class="custom-card !p-0 no-shadow h-full border-none">
            <div class="card-table-holder">
              <table class="table narrow table-fixed">
                <tbody class="table-body">
                  <tr>
                    <td>{{ $t("contracts.contractForm.contractNumber") }}</td>
                    <td>{{ contract.contractNumber }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("contracts.contractForm.owner") }}</td>
                    <td>
                      <UserWithAvatar
                        :user="contract.owner"
                        :show-name="true"
                        :avatar-dimension="6"
                        :text-classes="['font-medium']"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("contracts.contractForm.description") }}</td>
                    <td>
                      {{ contract.description }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("contracts.contractForm.contractType") }}</td>
                    <td>
                      <ContractTypeBadge :type="contract.contractType" />
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("contracts.contractForm.status") }}</td>
                    <td>
                      <StatusTag
                        :status="contract.status"
                        type="contract"
                        size="small"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="flex items-center justify-center w-full mt-2 font-normal"
              >
                <span
                  class="cursor-pointer hover:text-primary px-2 py-1 border border-transparent hover:border-gray-200 hover:bg-gray-50 rounded"
                  @click="navigateToContractPage"
                  >{{ $t("contracts.contractForm.contractDetails") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Tip>
</template>

<script>
import { CONTRACT_STATUS_TYPES } from "@/utils/constants";
import { UserWithAvatar } from "@/components/shared";
import { contractMixin } from "@/components/mixins";
import { Tip } from "@/components/shared/index";
import ContractTypeBadge from "@/components/Contracts/ContractTypeBadge";
import StatusTag from "@/components/shared/StatusTag";

export default {
  components: {
    UserWithAvatar,
    Tip,
    ContractTypeBadge,
    StatusTag,
  },
  mixins: [contractMixin],
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.CONTRACT_STATUS_TYPES = CONTRACT_STATUS_TYPES;
  },
  methods: {
    navigateToContractPage() {
      this.$router.push({
        name: "ViewContract",
        params: { id: this.contract.id },
      });
    },
  },
};
</script>

<style scoped>
.page-content {
  @apply flex flex-1 overflow-hidden gap-x-4;
}
.page-content .content-left,
.page-content .content-left {
  @apply flex flex-col overflow-auto gap-y-4;
}
.page-content .content-left .table td {
  @apply px-0 py-2 text-sm;
}
.page-content .content-left .table td:nth-child(2) {
  @apply font-medium;
}
.page-content .content-left .table td:nth-child(1) {
  @apply font-normal;
}
.card-header .card-title {
  @apply text-primary font-semibold;
}
</style>
