<template>
  <svg
    viewBox="0 0 512 512"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
    stroke="currentColor"
  >
    <path
      d="M443.958 493.455H68.042V18.545h275.891l100.025 100.018z"
      fill="none"
      stroke-width="45"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      class="stroke-000000"
    ></path>
    <path
      d="M443.958 118.563H343.933V18.545M137.585 347.323h236.83M137.585 421.678h236.83M272.608 254.344c-11.483 8.758-25.154 13.851-39.825 13.851-40.857 0-73.949-39.396-73.949-87.995s33.092-87.994 73.949-87.994c14.671 0 28.326 5.093 39.825 13.851M137.585 157.123h109.932M137.585 203.276h111.744"
      fill="none"
      stroke-width="30"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      class="stroke-000000"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "InvoiceSVG",
};
</script>
