// Modules
export const CONTRACT_MODULE = "CONTRACT_MODULE";

//Actions
export const GET_CONTRACTS = "GET_CONTRACTS";
export const DELETE_CONTRACT = "DELETE_CONTRACT";
export const SAVE_CONTRACT = "SAVE_CONTRACT";
export const GET_CONTRACT = "GET_CONTRACT";

//Mutations
export const SET_CONTRACTS = "SET_CONTRACTS";
export const SET_CONTRACTS_LIST_FILTERS = "SET_CONTRACTS_LIST_FILTERS";
export const REMOVE_CONTRACT = "REMOVE_CONTRACT";
export const SET_CONTRACT = "SET_CONTRACT";
