import { mapMutations, mapGetters, mapState } from "vuex";
import {
  SAVE_NEW_REQUEST_ATTRIBUTES,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
export default {
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, [
      "newRequestItem",
      "isValidItemInCart",
    ]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["newRequest"]),
    items() {
      return this.newRequestItem();
    },
    requestInProgress() {
      const { name, netAmount } = this.newRequestItem(0) || {};
      return name && netAmount;
    },
  },

  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      saveRequestAttributes: SAVE_NEW_REQUEST_ATTRIBUTES,
    }),
    copyValueToAllItems(key, value) {
      this.items.forEach((item, index) => {
        this.saveRequestAttributes({
          item: { [key]: value },
          index,
        });
      });
    },
  },
};
