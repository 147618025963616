import { post, patch, deleteObject } from "@/utils/services/api.js";
import { PURCHASE_REQUEST_API_URL } from "@/utils/services/apiUrls.js";
import { SET_COMMENT } from "@/store/PurchaseRequest/types.js";
const commentUrl = (prId) => {
  return `${PURCHASE_REQUEST_API_URL}${prId}/comments/`;
};

export const addComment = (prId, payload, commit, resolve, reject) => {
  if (!prId) return;

  post(commentUrl(prId), payload)
    .then((response) => {
      commit(SET_COMMENT, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateComment = (
  prId,
  commentId,
  payload,
  commit,
  resolve,
  reject
) => {
  if (!prId || !commentId) return;

  patch(`${commentUrl(prId)}${commentId}/`, payload)
    .then((response) => {
      commit(SET_COMMENT, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteComment = (prId, commentId, commit, resolve, reject) => {
  if (!prId || !commentId) return;

  deleteObject(`${commentUrl(prId)}${commentId}/`)
    .then((response) => {
      commit(SET_COMMENT, {
        object: { id: commentId, purchaseRequestId: prId },
        activity: "delete",
        storeState: "",
      });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
