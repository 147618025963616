<template>
  <div class="billing">
    <div class="profile-card-content">
      <div class="col-span-6 sm:col-span-4 mt-0">
        {{ $t("companyManagement.profile.billing.header") }}
        <Button :on-click-handler="openPortal">
          {{ $t("companyManagement.profile.billing.changePlanBtn") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/shared/Button";
import chargeBeeMixin from "@/components/mixins/chargeBeeMixin";
export default {
  components: {
    Button,
  },
  mixins: [chargeBeeMixin],
};
</script>
