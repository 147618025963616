<template>
  <div
    :class="[
      'select-none text-sm font-medium flex justify-center items-center gap-x-2 cursor-pointer hover:underline bg-gray-50 py-2 border-gray-200 rounded border',
      showDetails ? 'rounded-b-none' : 'rounded',
    ]"
  >
    <ChevronDownIcon :class="['chevron', { 'chevron-open': showDetails }]" />
    <span @click="showDetails = !showDetails">
      {{
        $t(
          "companyManagement.companySupplier.addModal.legalEntityConfiguration"
        )
      }}
    </span>
    <ChevronDownIcon :class="['chevron', { 'chevron-open': showDetails }]" />
  </div>
  <div v-if="showDetails">
    <div
      class="py-2 bg-gray-50 p-2 rounded rounded-t-none border border-t-0 border-gray-200"
    >
      <div>
        <LegalTendencySelect
          v-model="legalEntity"
          :already-configured-legal-entities="alreadyConfiguredLegalEntities"
          :can-deselect="false"
          class="mb-2"
        />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
          <Input
            id="customerNumber"
            :value="legalEntityConfiguration.customerNumber"
            name="customerNumber"
            :placeholder="
              $t('companyManagement.companySupplier.addModal.orderIdentifier')
            "
            :label="
              $t('companyManagement.companySupplier.addModal.orderIdentifier')
            "
            :on-value-change="onAttributeChange"
            :disabled="areFieldsDisabled"
          />
          <Input
            id="supplierNumber"
            :value="legalEntityConfiguration.supplierNumber"
            name="supplierNumber"
            placeholder="e.g. 70004"
            :label="
              $t('companyManagement.companySupplier.addModal.supplierNumber')
            "
            :on-value-change="onAttributeChange"
            :classes="'rounded-r-md'"
            :disabled="areFieldsDisabled"
          />
          <div class="md:mb-0">
            <label class="block text-sm font-medium text-gray-700 my-1.5">
              {{
                $t("companyManagement.companySupplier.addModal.responsibleUser")
              }}
            </label>
            <div class="mt-1">
              <MemberSelector
                :value="legalEntityConfiguration.responsibleUser"
                :on-value-change="setResponsibleUser"
                :members-list="activeMembers"
                :disabled="areFieldsDisabled"
              />
            </div>
          </div>
          <div class="mb-6 md:mb-0">
            <Input
              id="externalSupplierNumber"
              :value="legalEntityConfiguration.externalSupplierNumber"
              name="externalSupplierNumber"
              :placeholder="
                $t(
                  'companyManagement.companySupplier.addModal.externalSupplierNumber'
                )
              "
              :label="
                $t(
                  'companyManagement.companySupplier.addModal.externalSupplierNumber'
                )
              "
              :on-value-change="onAttributeChange"
              :classes="'rounded-r-md'"
              :disabled="areFieldsDisabled"
            />
          </div>

          <div class="mb-6 md:mb-0">
            <Input
              id="contactName"
              :value="legalEntityConfiguration.contactName"
              name="contactName"
              :placeholder="
                $t(
                  'companyManagement.companySupplier.addModal.placeholderContactName'
                )
              "
              :label="
                $t('companyManagement.companySupplier.addModal.contactName')
              "
              :on-value-change="onAttributeChange"
              :disabled="areFieldsDisabled"
            />
          </div>

          <div class="mb-6 md:mb-0">
            <Input
              id="contactEmail"
              :value="legalEntityConfiguration.contactEmail"
              name="contactEmail"
              placeholder="test@hivebuy.de"
              type="email"
              :label="
                $t('companyManagement.companySupplier.addModal.contactEmail')
              "
              :on-value-change="onAttributeChange"
              :disabled="areFieldsDisabled"
            />
          </div>
          <div
            v-if="changedLegalEntities.length > 0"
            class="mt-2 col-span-full"
          >
            <div
              class="rounded border border-gray-200 flex px-2 py-1 text-sm text-primary"
            >
              {{
                $t(
                  "companyManagement.companySupplier.addModal.legalEntityUpdatetext",
                  {
                    count: changedLegalEntities.length,
                    supplier: $t("global.supplier"),
                    legalEntity: $t("global.legalEntity"),
                  }
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center mt-2">
        <Button
          :on-click-handler="cancelConfiguration"
          :color="'gray'"
          size-class="sm"
        >
          {{ $t("shared.cancelButton") }}
        </Button>
        <Button
          :on-click-handler="saveConfiguration"
          class="ml-2"
          :disabled="areFieldsDisabled"
          size-class="sm"
        >
          {{ $t("buttons.tempSave") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/outline";
import {
  Input,
  MemberSelector,
  LegalTendencySelect,
  Button,
} from "@/components/shared/index";
import { updateOrCreate } from "@/utils/utility_methods";
export default {
  name: "LegalEntityInfo",
  components: {
    Input,
    ChevronDownIcon,
    MemberSelector,
    LegalTendencySelect,
    Button,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    activeMembers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      showDetails: false,
      legalEntityConfiguration: this.initializeLegalEntityConfiguration(),
      changedLegalEntities: [],
    };
  },
  computed: {
    legalEntityConfigurations: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    legalEntity: {
      get() {
        return this.legalEntityConfiguration.legalEntity;
      },
      set(value) {
        this.legalEntityConfiguration = this.findOrInitialize(value);
      },
    },
    areFieldsDisabled() {
      return !this.legalEntityConfiguration.legalEntity;
    },
    alreadyConfiguredLegalEntities() {
      return this.legalEntityConfigurations.map((config) => config.legalEntity);
    },
  },
  methods: {
    initializeLegalEntityConfiguration() {
      return {
        legalEntity: "",
        customerNumber: "",
        supplierNumber: "",
        responsibleUser: "",
        externalSupplierNumber: "",
        contactName: "",
        contactEmail: "",
      };
    },

    pushUniqueValue(value) {
      if (!this.changedLegalEntities.includes(value)) {
        this.changedLegalEntities.push(value);
      }
    },
    saveConfiguration() {
      this.legalEntityConfigurations = updateOrCreate(
        this.legalEntityConfigurations,
        this.legalEntityConfiguration,
        true,
        false,
        "legalEntity"
      );
      this.pushUniqueValue(this.legalEntityConfiguration.legalEntity);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.legalEntityConfiguration[name] = value;
    },
    setResponsibleUser(user) {
      this.legalEntityConfiguration.responsibleUser = user || "";
    },
    cancelConfiguration() {
      this.legalEntityConfiguration = this.findOrInitialize(
        this.legalEntityConfiguration.legalEntity,
        true
      );
      this.showDetails = false;
    },
    findOrInitialize(legalEntity, onCancel = false) {
      const legalEntityObj = this.legalEntityConfigurations.find(
        (config) => config.legalEntity === legalEntity
      );

      return legalEntityObj
        ? Object.assign({}, legalEntityObj)
        : Object.assign(
            {},
            this.initializeLegalEntityConfiguration(),
            onCancel ? {} : { legalEntity }
          );
    },
  },
};
</script>
