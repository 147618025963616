import actions from "@/store/Contract/actions";
import mutations from "@/store/Contract/mutations";
import state from "@/store/Contract/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
