<template>
  <div class="edit-profile">
    <div class="flex items-center justify-between">
      <h3 class="page-title">{{ $t("profile.editProfile") }}</h3>
    </div>
    <div class="custom-card">
      <div class="-m-5">
        <Tabs
          :tabs="tabs"
          :on-tab-change="onTabChange"
          :active-tab="currentComponent"
        />
        <component :is="currentComponent" />
      </div>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import {
  KeyIcon,
  UserIcon,
  FilterIcon,
  BellIcon,
} from "@heroicons/vue/outline";
import ProfileInfo from "@/components/Profile/ProfileInfo";
import ChangePassword from "@/components/Profile/ChangePassword";
import DefaultValues from "@/components/Profile/DefaultValues";
import Tabs from "@/components/Profile/Tabs";
import NotificationSettings from "@/components/Profile/NotificationSettings";

export default {
  components: {
    Tabs,
    ProfileInfo,
    ChangePassword,
    DefaultValues,
    NotificationSettings,
  },
  data() {
    return {
      currentComponent: "ProfileInfo",
    };
  },
  computed: {
    tabs() {
      const options = [
        {
          name: this.$t("profile.profileInfo"),
          component: "ProfileInfo",
          icon: UserIcon,
          current: true,
        },
        {
          name: this.$t("profile.password.title"),
          component: "ChangePassword",
          icon: KeyIcon,
          current: false,
        },
        {
          name: this.$t("profile.defaultValues"),
          component: "DefaultValues",
          icon: FilterIcon,
          current: false,
        },
        {
          name: this.$t("profile.notificationSettings.heading"),
          component: "NotificationSettings",
          icon: BellIcon,
          current: false,
        },
      ];
      return options;
    },
  },
  methods: {
    onTabChange(tab) {
      this.currentComponent = tab.component;
    },
  },
};
</script>

<style scoped>
.edit-profile {
  @apply flex flex-col h-full;
}

.custom-card {
  @apply flex-1 mb-4;
}

.spacer {
  @apply pb-4;
}
</style>
