export const INVOICE_MODULE = "INVOICE_MODULE";
export const ADD_INVOICE = "ADD_INVOICE";
export const ASSIGN_PURCHASE_ORDER = "ASSIGN_PURCHASE_ORDER";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const GET_OCR_DATA = "GET_OCR_DATA";
export const GET_INVOICES_WITHOUT_PAGINATION =
  "GET_INVOICES_WITHOUT_PAGINATION";
export const GET_INVOICES = "GET_INVOICES";
export const UPDATE_INVOICE_RELATION = "UPDATE_INVOICE_RELATION";
export const ACTIONABLE_INVOICES = "ACTIONABLE_INVOICES";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const GET_OCR_PREVIEW_DATA = "GET_OCR_PREVIEW_DATA";

export const SET_INVOICES = "SET_INVOICES";
export const SET_INVOICE_LIST_FILTERS = "SET_INVOICE_LIST_FILTERS";
export const SET_ACTIONABLE_INVOICES_FILTERS =
  "SET_ACTIONABLE_INVOICES_FILTERS";
export const DELETE_INVOICE_PO_RELATION = "DELETE_INVOICE_PO_RELATION";
export const SET_RECENT_INVOICES = "SET_RECENT_INVOICES";
export const SET_OCR_PREVIEW_DATA = "SET_OCR_PREVIEW_DATA";
export const SET_INVOICE_LIVE_UPDATE = "SET_INVOICE_LIVE_UPDATE";
export const SET_PO_STATUS_PENDING = "SET_PO_STATUS_PENDING";
export const UPDATE_PO_STATUS = "UPDATE_PO_STATUS";
export const GET_INVOICE = "GET_INVOICE";
export const SET_NOT_INVOICE = "SET_NOT_INVOICE";
export const SET_ACTIONABLE_INVOICES_FILTERS_LIST =
  "SET_ACTIONABLE_INVOICES_FILTERS_LIST";

export const SET_LIST_FILTERS = "SET_LIST_FILTERS";
export const SET_DEBTORS = "SET_DEBTORS";
export const GET_DEBTORS = "GET_DEBTORS";

export const SET_INVOICE_LIST_PARAMS = "SET_INVOICE_LIST_PARAMS";
export const VALIDATE_INVOICE = "VALIDATE_INVOICE";

export const UNASSIGN_PURCHASE_ORDER = "UNASSIGN_PURCHASE_ORDER";
export const SET_INVOICE_PO_LIVE_UPDATE="SET_INVOICE_PO_LIVE_UPDATE"