<template>
  <div
    class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap"
  >
    <div class="ml-4 mt-4">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <UserImage :user="notification.user" />
        </div>
        <div class="ml-4">
          <p class="text-sm text-gray-500">
            {{ getNotificationText(notification) }}
          </p>
          <div class="text-gray-500 text-xs ml-auto flex items-center mt-1">
            {{ timeAgo(notification.createdAt) }}
            <span>
              <CheckCircleIcon class="ml-2 h-5 w-5 text-teal-500" />
              <XCircleIcon class="hidden ml-2 h-5 w-5 text-red-600" />
              <InformationCircleIcon
                class="hidden ml-2 h-5 w-5 text-blue-500"
              />
              <ExclamationCircleIcon
                class="hidden ml-2 h-5 w-5 text-purple-500"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { notificationMixin } from "@/components/mixins/index.js";
import { UserImage } from "@/components/shared/index";
import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/solid";
export default {
  components: {
    UserImage,
    CheckCircleIcon,
    XCircleIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
  },
  mixins: [notificationMixin],
  methods: {
    getNotificationText(item) {
      return `${item.user.name} ${item.text}`;
    },
  },
};
</script>
