<template>
  <div
    class="max-w-full mx-auto bg-white rounded-lg shadow-sm overflow-hidden md:max-w-2xl border-2"
  >
    <div class="md:flex p-4">
      <div class="md:flex-shrink-0 pr-4">
        <a>
          <img class="h-20 w-full object-contain md:w-20" :src="image" alt="" />
        </a>
      </div>
      <div>
        <a class="block leading-tight font-medium text-sm text-black mb-3">
          {{ title }}</a
        >
        <p class="text-gray-500 text-xs">{{ description }}</p>
        <p
          class="text-gray-400 text-xs mt-3 hover:text-teal-500 cursor-pointer"
        >
          <a :href="link" target="_blank"> {{ link }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false, default: "" },
    description: { type: String, required: false, default: "" },
    image: { type: String, required: false, default: "" },
    link: { type: String, required: false, default: "" },
  },
};
</script>
