import FieldError from "@/components/shared/FieldError";
import { Button } from "@/components/shared";
import useValidate from "@vuelidate/core";

import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_GLOBAL_POLICY,
} from "@/store/CompanyManagement/types";
import Condition from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/Condition";

export default {
  components: {
    FieldError,
    Button,
    Condition,
  },
  props: {
    approvalWorkflow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
    };
  },
  computed: {
    approvalWorkflowTitle() {
      const conditionText = this.conditionTextToCamelCase(
        this.approvalWorkflow.condition
      );
      return this.$t(`approvalWorkflow.globalPolicies.${conditionText}.title`);
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateGlobalPolicy: UPDATE_GLOBAL_POLICY,
    }),
    conditionTextToCamelCase(title) {
      return title
        .toLowerCase()
        .split("_")
        .map((word, index) =>
          index !== 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
        )
        .join("");
    },
  },
};
