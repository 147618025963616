<template>
  <svg
    id="Layer_1"
    enable-background="new 0 0 66 66"
    version="1.1"
    viewBox="0 0 66 66"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="fill-current"
    stroke="currentColor"
  >
    <g>
      <g>
        <path
          d="M40.2,53.2l3.3-17.3c0.2-1.2-0.7-2.4-2-2.4h-31L9.7,28c-0.1-0.5-0.5-0.9-1-0.9H3c-0.6,0-1,0.5-1,1c0,0.6,0.4,1,1,1h4.9    l3.6,24.1c0.1,1,1,1.7,2,1.7H39c0.7,0,1.2,0.6,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H12.6c-0.6,0-1,0.4-1,1c0,0.5,0.4,1,1,1h0.8    c-0.2,0.4-0.3,0.8-0.3,1.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3c0-0.5-0.1-0.9-0.3-1.3h14.4c-0.2,0.4-0.3,0.8-0.3,1.3    c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3c0-0.5-0.1-1-0.3-1.4c1.4-0.4,2.4-1.6,2.4-3.1C42.2,54.8,41.3,53.7,40.2,53.2z M17.6,52.9    h-4.2l0-0.1l-1.1-7.6h5.3V52.9z M17.6,43.2h-5.6l-1.1-7.7l6.7,0V43.2z M25.2,53h-5.6v-7.7h5.6V53z M25.2,43.2h-5.6v-7.7h5.6V43.2z     M32.7,53l-5.6,0v-7.7h5.6V53z M32.7,43.2h-5.6v-7.7h5.6V43.2z M38.2,53h-3.4v-7.7h4.9L38.2,53z M34.7,43.2v-7.7l6.8,0L40,43.2    H34.7z"
        />
      </g>
      <g>
        <path
          d="M62.4,2L32.7,2c-0.9,0-1.6,0.7-1.6,1.6l0,27.9h10.4c2.6,0,4.4,2.4,3.9,4.8l-0.6,3.3h12.8c0.6,0,1,0.5,1,1v3.2    c0,1.7,1.5,3,3.2,2.6c1.3-0.3,2.1-1.5,2.1-2.8V3.6C64,2.7,63.3,2,62.4,2z M57.3,33.8h-9.7c-0.5,0-1-0.5-1-1c0-0.6,0.5-1,1-1h9.7    c0.5,0,1,0.4,1,1C58.3,33.4,57.8,33.8,57.3,33.8z M57.3,25.8H37.9c-0.6,0-1-0.5-1-1c0-0.5,0.4-1,1-1h19.4c0.5,0,1,0.5,1,1    C58.3,25.4,57.8,25.8,57.3,25.8z M57.3,17.8H37.9c-0.6,0-1-0.5-1-1c0-0.6,0.4-1,1-1h19.4c0.5,0,1,0.4,1,1    C58.3,17.4,57.8,17.8,57.3,17.8z M57.3,9.8H37.9c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h19.4c0.5,0,1,0.5,1,1    C58.3,9.3,57.8,9.8,57.3,9.8z"
        />
      </g>
      <g>
        <path d="M56.6,41.6H44.4l-1,4.9h14c-0.5-0.8-0.8-1.7-0.8-2.7V41.6z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ShoppingList",
};
</script>
