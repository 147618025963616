<template>
  <svg
    id="Layer_1"
    enable-background="new 0 0 66 66"
    version="1.1"
    viewBox="0 0 66 66"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="fill-current"
    stroke="currentColor"
  >
    <g>
      <g>
        <path
          d="M63,1H30.1c-1.1,0-2,0.9-2,2v31.5H9.5L8.7,29c-0.1-0.5-0.5-0.9-1-0.9H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.9l3.6,24.1    c0.1,1,1,1.7,2,1.7H38c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H11.6c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h0.8    c-0.2,0.4-0.3,0.8-0.3,1.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3c0-0.5-0.1-0.9-0.3-1.3h14.4c-0.2,0.4-0.3,0.8-0.3,1.3    c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-0.5-0.1-1-0.3-1.4c1.4-0.4,2.4-1.6,2.4-3.1c0-1.4-0.9-2.5-2.1-3l0.9-4.7h20.3    c2.6,0,4.7-2.1,4.7-4.7V3C65,1.9,64.1,1,63,1z M16.7,61.7c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3    C16.1,60.4,16.7,61,16.7,61.7z M35.8,63c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3    C37.1,62.4,36.6,63,35.8,63z M40.5,36.5L39,44.2h-5.3v-7.7L40.5,36.5z M36.4,54l-2.7,0v-7.7h4.9L37.2,54L36.4,54z M12.4,53.9    l-1.1-7.6h5.3v7.7L12.4,53.9L12.4,53.9z M24.2,46.2v7.7l-5.6,0v-7.7H24.2z M18.6,44.2v-7.7l5.6,0v7.7H18.6z M26.2,46.2h5.6V54    l-5.6,0V46.2z M31.7,44.2h-5.6v-7.7l5.6,0V44.2z M16.6,36.5v7.7h-5.6l-1.1-7.7L16.6,36.5z M40.4,47.5l1-4.9h14.3v2.2    c0,1,0.3,1.9,0.8,2.7H40.4z M63,44.8c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7v-3.2c0-0.6-0.4-1-1-1H41.8l0.7-3.7    c0.1-0.6,0-1.2-0.4-1.7c-0.4-0.5-1-0.7-1.6-0.7H30.1l0-31.5L63,3V44.8z"
        />
      </g>
      <g>
        <path
          d="M36.9,10.8h19.4c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H36.9c-0.6,0-1,0.4-1,1C35.9,10.3,36.3,10.8,36.9,10.8z"
        />
      </g>
      <g>
        <path
          d="M36.9,18.8h19.4c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H36.9c-0.6,0-1,0.4-1,1C35.9,18.3,36.3,18.8,36.9,18.8z"
        />
      </g>
      <g>
        <path
          d="M36.9,26.8h19.4c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H36.9c-0.6,0-1,0.4-1,1C35.9,26.4,36.3,26.8,36.9,26.8z"
        />
      </g>
      <g>
        <path
          d="M56.3,32.8h-9.7c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h9.7c0.6,0,1-0.4,1-1C57.3,33.3,56.8,32.8,56.3,32.8z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ShoppingList",
};
</script>
