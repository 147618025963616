import {
  isValuePresent,
  pickKeys,
  removeKeysFromObject,
  isObject,
} from "@/utils/utility_methods.js";
import {
  PR_CREATE_KEYS,
  ITEM_CREATE_KEYS,
  ITEM_BASIC_KEYS,
  PR_BASIC_KEYS,
} from "@/utils/helpers/modelHelpers/purchaseRequest/Constants.js";

import store from "@/store";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export const supplierInfo = (object) => {
  const { companySupplier, unlistedSupplier, reason, unlistedSupplierReason } =
    object;
  let supplier = { type: "skip", details: null };
  if (companySupplier) {
    supplier = { type: "companySupplier", details: companySupplier };
  }
  if (unlistedSupplier) {
    supplier = {
      type: "unlisted",
      details: {
        name: unlistedSupplier,
        reason: unlistedSupplierReason || reason,
      },
    };
  }
  return supplier;
};

const mappedItemData = (item, edit) => {
  let keys = ITEM_CREATE_KEYS;
  if (edit) keys = ITEM_BASIC_KEYS;
  const itemData = pickKeys(item, keys);
  if (!edit) {
    const {
      netAmount,
      grossAmount,
      vat,
      quantity,
      shipping,
      netAmountCurrency,
      grossAmountCurrency,
    } = item;
    itemData.itemPrices = [
      {
        netAmount,
        grossAmount,
        vat,
        netAmountCurrency: netAmountCurrency,
        grossAmountCurrency: grossAmountCurrency,
        quantity,
        shipping,
      },
    ];
  }
  itemData.supplier = supplierInfo(itemData);
  if (itemData.source?.object) {
    const object = itemData.source.object;
    delete itemData.source.object;
    itemData.source = Object.assign({}, itemData.source, object);
  }
  if (itemData?.customFields?.length) {
    const allCustomFields =
      store.state[`${COMPANY_MANAGEMENT_MODULE}`].customFields;
    itemData.customFields = itemData.customFields
      .filter((field) => isValuePresent(field.value))
      .map((field) => {
        const updatedField = Object.assign({}, field);
        if (!updatedField?.customField) {
          updatedField.customField = allCustomFields.find(
            (customField) => customField.name === field.name
          )?.id;
        }
        return removeKeysFromObject(updatedField, ["selectFields"]);
      });
  }
  if (isObject(itemData.catalogueItemVariant))
    itemData.catalogueItemVariant = itemData.catalogueItemVariant.id;
  return itemData;
};
export const mappedPrData = (request, edit = false) => {
  let keys = PR_CREATE_KEYS;
  if (edit) keys = PR_BASIC_KEYS;
  const purchaseRequestData = pickKeys(request, keys);

  const items = request.items.map((item) => mappedItemData(item, edit));
  purchaseRequestData.items = items;
  return purchaseRequestData;
};
