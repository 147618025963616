<template>
  <div id="project">
    <div class="flex flex-col">
      <div class="flex items-center justify-between">
        <h3 class="page-title">
          {{ $t("ApiKeys.title") }}
        </h3>
        <button
          type="button"
          class="flex text-primary font-semibold -mt-2 hover:text-primarydark"
          @click="
            () => {
              showModal = !showModal;
            }
          "
        >
          <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
          {{ $t("ApiKeys.addNew") }}
        </button>
      </div>
      <div class="custom-card no-shadow !min-h-[auto] mb-2">
        <SearchInput
          :value="search"
          :on-change="onSearchInput"
          :placeholder="$t('ApiKeys.inputs.search')"
        />
      </div>
      <div class="custom-card no-shadow !min-h-[auto]">
        <SkeletonCard v-if="isApiLoading('apiKeys')" />
        <ul
          v-else
          role="list"
          class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-2"
        >
          <li
            v-for="apiKey in apiKeyList"
            :key="apiKey.id"
            class="overflow-hidden rounded-xl border border-gray-200"
          >
            <ApiKey :api-key="apiKey" :on-key-disable="onDisable" />
          </li>
        </ul>
      </div>
    </div>
    <ConfirmDialog
      :text="'Are you sure you want to disable the following key?'"
      :show-modal="confirmModal"
      :close-modal="onDeleteModalClose"
      :title="keyToDisable.name"
      :confirm-modal="onConfirmDelete"
    />
  </div>
  <Modal :show-modal="showModal" :on-modal-close="onModalClose" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_API_KEYS,
  DISABLE_API_KEY,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types.js";
import { ConfirmDialog } from "@/components/shared/index";
import SkeletonCard from "@/components/shared/SkeletonLoaders/Card.vue";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import { ApiKey } from "@/components/ApiKeys/index";
import Modal from "@/components/ApiKeys/Modal.vue";

export default {
  components: {
    ApiKey,
    SkeletonCard,
    SearchInput,
    PlusCircleIcon,
    ConfirmDialog,
    Modal,
  },
  data() {
    return {
      search: "",
      confirmModal: false,
      keyToDisable: { name: "" },
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getApiKeys"]),
    apiKeyList() {
      return this.getApiKeys(this.search);
    },
  },

  mounted() {
    !this.apiKeyList.length && this.fetchApiKeys();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getApiKeysAction: GET_API_KEYS,
      disableApiKey: DISABLE_API_KEY,
    }),
    fetchApiKeys() {
      this.setApiLoading({ key: "apiKeys", value: true });
      this.getApiKeysAction()
        .catch((error) => console.log(error))
        .finally(() => this.setApiLoading({ key: "apiKeys", value: false }));
    },

    onModalClose() {
      this.showModal = false;
    },
    onSearchInput(_, value) {
      this.search = value;
    },
    onDisable(key) {
      this.confirmModal = true;
      this.keyToDisable = key;
    },
    onDeleteModalClose() {
      this.confirmModal = false;
    },
    onConfirmDelete() {
      this.confirmModal = false;
      this.disableApiKey(this.keyToDisable.id)
        .then(() => {
          this.showNotification(
            this.$t("ApiKeys.notifications.disabled"),
            "success"
          );
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>

<style scoped>
.table-search {
  @apply flex;
}
</style>
