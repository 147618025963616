<template>
  <div class="relative bg-white pt-16 pb-32 rounded-lg">
    <div class="flex lg:items-center gap-x-4 lg:px-8 flex-col lg:flex-row px-6">
      <span
        class="flex h-12 w-12 items-center justify-center rounded-xl bg-primary"
      >
        <component
          :is="pageContent.icon"
          class="h-8 w-8 text-white"
          aria-hidden="true"
        />
      </span>
      <h2 class="text-3xl font-bold tracking-tight text-gray-900">
        {{ pageContent.title }}
      </h2>
    </div>
    <div
      class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
    >
      <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-8 lg:px-0">
        <div>
          <div class="mt-6">
            <p class="mt-4 text-lg text-gray-600">
              {{ pageContent.text }}
            </p>
            <ul class="list-disc list-outside mt-2 pl-3 text-gray-500">
              <li v-for="li in pageContent.list" :key="li" class="py-1">
                {{ li }}
              </li>
            </ul>
            <div class="mt-6">
              <a
                id="more_info"
                href="#"
                class="inline-flex rounded-lg bg-primary px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm"
              >
                {{ $t("premiumFeature.getMoreButton") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center justify-center mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0 mt-16 lg:mt-0"
      >
        <div>
          <img
            class="w-full rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 lg:w-auto shadow-primary"
            :src="pageContent.screenshot"
            :alt="pageContent.title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ClipboardListIcon,
  DocumentIcon,
  DocumentTextIcon,
  InboxIcon,
  LightningBoltIcon,
  LibraryIcon,
} from "@heroicons/vue/outline";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";

export default {
  name: "PremiumFeature",
  components: {
    InboxIcon,
  },
  data() {
    return {
      featureName: "",
    };
  },
  computed: {
    pageContent() {
      return {
        shoppingList: {
          title: this.$t("premiumFeature.shoppingList.title"),
          text: this.$t("premiumFeature.shoppingList.text"),
          list: this.translationTexts(),
          icon: ClipboardListIcon,
          screenshot:
            "https://staging.hivebuy.com/wp-content/uploads/2022/12/Screenshot-2022-12-01-at-16.29.26.png",
        },
        contracts: {
          title: this.$t("premiumFeature.contracts.title"),
          text: this.$t("premiumFeature.contracts.text"),
          icon: DocumentTextIcon,
          list: this.translationTexts(),
          screenshot:
            "https://staging.hivebuy.com/wp-content/uploads/2022/12/Screenshot-2022-12-01-at-11.40.32.png",
        },
        polls: {
          title: this.$t("premiumFeature.polls.title"),
          text: this.$t("premiumFeature.polls.text"),
          list: this.translationTexts(),
          icon: DocumentIcon,
          screenshot:
            "https://staging.hivebuy.com/wp-content/uploads/2022/12/Screenshot-2022-12-01-at-16.15.48.png",
        },
        invoiceApproval: {
          title: this.$t("premiumFeature.invoiceApproval.title"),
          text: this.$t("premiumFeature.invoiceApproval.text"),
          list: this.translationTexts(),
          icon: ClipboardListIcon,
          screenshot:
            "https://staging.hivebuy.com/wp-content/uploads/2022/12/Screenshot-2022-12-01-at-11.38.03.png",
        },
        projects: {
          title: this.$t("premiumFeature.projects.title"),
          text: this.$t("premiumFeature.projects.text"),
          list: this.translationTexts(),
          icon: ProjectSVG,
          screenshot:
            "https://staging.hivebuy.com/wp-content/uploads/2022/12/Screenshot-2022-12-01-at-16.26.07.png",
        },
        integratedSupplier: {
          title: this.$t("premiumFeature.integratedSupplier.title"),
          text: this.$t("premiumFeature.integratedSupplier.text"),
          list: this.translationTexts(),
          icon: LightningBoltIcon,
        },
        legalEntity: {
          title: this.$t("premiumFeature.legalEntity.title"),
          text: this.$t("premiumFeature.legalEntity.text"),
          list: this.translationTexts(),
          icon: LibraryIcon,
        },
      }[this.featureName];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name !== "PremiumFeature") return;
        this.featureName = val.params.pageName;
      },
    },
  },
  methods: {
    translationTexts() {
      const texts = [];
      let n = 1;

      while (
        this.$te("premiumFeature." + this.featureName + ".list.line" + n)
      ) {
        texts.push(
          this.$t("premiumFeature." + this.featureName + ".list.line" + n)
        );
        n++;
      }
      return texts;
    },
  },
};
</script>
