<template>
  <div id="app">
    <treeselect
      v-model="dropdownValue"
      :placeholder="placeholder || $t('global.category')"
      :options="dropdownOptions"
      :clearable="false"
      :searchable="true"
      :open-on-click="true"
      :close-on-select="true"
      :show-count="showCount"
      :flatten-search-results="flattenSearchResults"
      :required="true"
      :class="{ 'treeselect-invalid': error }"
      :multiple="multiple"
      :flat="flat"
      :value-format="object ? 'object' : 'id'"
      :no-results-text="$t('global.noSearchResults')"
      :default-expand-level="defaultExpandLevel"
      :open-direction="openDirection"
      :limit="limit"
    />
  </div>
  <FieldError v-if="error" :text="$t('global.errorMessages.categoryError')" />
</template>

<script>
import Treeselect from "@bosquig/vue3-treeselect";
import "@bosquig/vue3-treeselect/dist/vue3-treeselect.css";
import { mapGetters, mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_CATEGORIES,
} from "@/store/CompanyManagement/types";
import { FieldError } from "@/components/shared/index";

export default {
  name: "TreeViewSelect",
  components: {
    Treeselect,
    FieldError,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Array, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    flattenSearchResults: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Boolean,
      default: false,
    },
    defaultExpandLevel: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 10,
    },
    openDirection: {
      type: String,
      default: "auto",
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryTreeWithChildren",
      "categoriesList",
    ]),
    dropdownValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    dropdownOptions() {
      return this.options.length
        ? this.options
        : this.categoryTreeWithChildren();
    },
  },
  mounted() {
    if (this.categoriesList.length || this.isApiLoading("categories")) return;

    this.setApiLoading({ key: "categories", value: true });
    this.getCategories()
      .catch(() => {
        this.showErrorMessage();
      })
      .finally(() => {
        this.setApiLoading({ key: "categories", value: false });
      });
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCategories: GET_CATEGORIES,
    }),
  },
};
</script>

<style>
.vue-treeselect__input {
  box-shadow: none !important;
  @apply w-full h-full;
}

.vue-treeselect__control {
  padding-top: 2px;
  padding-bottom: 2px;
}

.vue-treeselect__option--highlight {
  @apply bg-primary/20;
}

.vue-treeselect__option--selected .vue-treeselect__label {
  @apply !bg-primary !text-white;
}

.vue-treeselect__option--selected {
  @apply !bg-primary;
}

.vue-treeselect__label {
  @apply text-sm text-gray-700;
}

.vue-treeselect.treeselect-invalid:not(.vue-treeselect--open)
  .vue-treeselect__control {
  border-color: red;
}

.vue-treeselect.treeselect-invalid .vue-treeselect__single-value {
  color: red;
}
</style>
