import { NotificationSocket } from "@/utils/liveUpdates/notifications.js";
import {
  ALLOWED_DEVIATION,
  BACKEND_PRICE_DECIMAL_POINTS,
} from "@/utils/constants.js";
import { i18n } from "@/i18n";

export const defineGlobalProperties = (instance) => {
  instance.config.globalProperties = {
    socket: new NotificationSocket(),
    allowedDeviation: ALLOWED_DEVIATION,
    backendDecimalPoint: BACKEND_PRICE_DECIMAL_POINTS,
    $t: i18n.global.t,
  };
};
