<template>
  <div class="payment-method">
    <div class="payment-method__header">
      <div class="payment-method__header__left">
        <h3 class="card-title">
          {{ $t("companyManagement.profile.paymentMethods") }}
        </h3>
        <span class="sub-title">
          {{ $t("companyManagement.profile.paymentMethodsSubtext") }}
        </span>
      </div>
      <div class="payment-method__header__right">
        <button class="payment-button" @click="onAddPaymentMethod">
          <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
          {{ $t("companyManagement.profile.addPaymentMethod") }}
        </button>
      </div>
    </div>
    <div class="profile-card-content">
      <List />
    </div>
    <AddPaymentMethod
      :show-modal="showAddPaymentMethodModal"
      :close-modal="onAddPaymentMethodModalClose"
    />
  </div>
</template>

<script>
import List from "@/components/CompanyManagement/Profile/PaymentMethods/List";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import AddPaymentMethod from "@/components/CompanyManagement/Profile/PaymentMethods/AddPaymentMethod";

export default {
  components: {
    List,
    PlusCircleIcon,
    AddPaymentMethod,
  },
  data() {
    return {
      showAddPaymentMethodModal: false,
    };
  },
  methods: {
    onAddPaymentMethod() {
      this.showAddPaymentMethodModal = true;
    },
    onAddPaymentMethodModalClose() {
      this.showAddPaymentMethodModal = false;
    },
  },
};
</script>

<style scoped>
.payment-method__header {
  @apply flex justify-between border-b border-gray-200 pb-4;
}

.payment-method__header__left {
  @apply flex flex-col;
}
.payment-method__header__left .card-title {
  @apply font-bold text-primary mb-1 text-sm;
}
.payment-method__header__left .sub-title {
  @apply text-sm text-gray-500;
}

.payment-method__header__right {
  @apply flex items-center;
}

.payment-button {
  @apply flex items-center text-base font-semibold text-primary focus:outline-none;
}
</style>
