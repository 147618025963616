<template>
  {{ getIntervalText }}

  <span v-if="recurrence.validTo">
    ({{ $t("purchaseRequest.detail.costRecurringSpValidTo") }}:
    {{ formattedDate(recurrence.validTo) }})
  </span>

  <span v-else-if="recurrence.interval == 'SP'">
    ({{ formattedDate(recurrence.servicePeriodStart) }}
    -
    {{ formattedDate(recurrence.servicePeriodEnd) }})
  </span>
</template>

<script>
export default {
  props: {
    recurrence: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getIntervalText() {
      let text = "";
      switch (this.recurrence.interval) {
        case "M":
          text = this.$t("purchaseRequest.detail.recurringM");
          break;
        case "Y":
          text = this.$t("purchaseRequest.detail.recurringY");
          break;
        case "SP":
          text = this.$t("purchaseRequest.detail.recurringSP");
          break;
        case "":
        default:
          text = this.$t("purchaseRequest.detail.oneTimeCost");
      }
      return text;
    },
  },
};
</script>
