import { logSentryMessage } from "@/utils/utility_methods";
export default {
  currentStep: (state) => () => {
    return {
      category: state.currentRequest.currentPage == "category",
      supplier: state.currentRequest.currentPage == "supplier",
      item: state.currentRequest.currentPage == "items",
      summary: state.currentRequest.currentPage == "summary",
      isPreview:
        state.currentRequest.currentPage == "items" &&
        state.currentRequest.isPreview,
    };
  },
  itemDetails: (state) => (position) => {
    return state.currentRequest.items.find((item) => item.position == position);
  },
  requests:
    (state) =>
    (status, user, noOfRecords = null) => {
      if (!state.purchaseRequests) return [];

      const records = state.purchaseRequests.filter(
        (request) => request.status == status && request.requestedBy.id == user
      );
      return noOfRecords ? records.slice(0, noOfRecords) : records;
    },

  requestDetails: (state) => (ids) => {
    const isIdArray = Array.isArray(ids);
    const idList = isIdArray ? ids : [ids];
    const mappedPr = idList.map((id) => {
      let items = state.itemDetails[id];
      const purchaseRequestDetails = Object.assign(
        {},
        state.purchaseRequestDetails[id]
      );
      if (!Object.keys(purchaseRequestDetails).length) return null;
      if (!items) {
        logSentryMessage(
          `Items Missing for Purchase Request ${id}`,
          purchaseRequestDetails
        );
        items = {};
      }
      purchaseRequestDetails.items = Object.values(items);

      return purchaseRequestDetails;
    });

    return isIdArray ? mappedPr : mappedPr[0];
  },
  searchItems: (state) => (search) => {
    const itemsArray = Object.values(state.itemDetails)
      .map((item) => Object.values(item))
      .flat();
    if (!itemsArray.length) return;
    return itemsArray.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
  },
  itemDetailObject: (state) => (itemId, prId) => {
    return state.itemDetails[prId][itemId];
  },
  newRequestItem:
    (state) =>
    (index = null) => {
      if (index === -1) return null;
      if (index != null && index > -1) return state.newRequest.items[index];

      return state.newRequest.items;
    },
  isValidItemInCart: (state) => {
    return state.newRequest.items.some((item) => item.name && item.netAmount);
  },
};
