import { FILE_OPTIONS_CLASS_MAPPING } from "@/utils/constants";
import { snakeToCamel } from "@/utils/utility_methods";

export default {
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    typeOptions() {
      return Object.keys(FILE_OPTIONS_CLASS_MAPPING(this.source)).map((key) => {
        const labelTranslationKey = snakeToCamel(key);
        return {
          label: this.$t(
            `purchaseRequest.summary.RequestFilesection.fileModal.typeOptions.${labelTranslationKey}`
          ),
          value: key,
        };
      });
    },
  },
  methods: {
    typeOptionClass(type) {
      return FILE_OPTIONS_CLASS_MAPPING(this.source)[type];
    },
  },
};
