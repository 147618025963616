export default {
  computed: {
    carrierList() {
      return [
        {
          name: "Deutsche Post Brief",
          url: "https://www.deutschepost.de/de/s/sendungsverfolgung.html?piececode=",
        },
        {
          name: "DPD",
          url: "https://my.dpd.de/redirect.aspx?action=1&parcelno="
        },
        {
          name: "PIN AG",
          url: "https://sendungsverfolgung.pin-ag.de/?sendung="
        },
        {
          name: "DHL",
          url: "https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode="
        }
      ];
    },
    
  },
  methods: {
    isTrackingLinkAvailable(carrierName) {
      return this.carrierList.find(carrier => carrier.name === carrierName);
    },

    generateTrackingLink(carrierName, trackingNumber) {
      const carrier = this.isTrackingLinkAvailable(carrierName)
      if(carrier) {
        return `${carrier.url}${trackingNumber}`
      }
      return null
    }
  },
};
