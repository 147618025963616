<template>
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="fill-current"
  >
    <path
      d="M30.53015,8.01325c-0.95599-1.31143-2.4344-2.06333-4.05636-2.06333H7.76622l-0.0841-0.40573  C7.31398,3.76794,5.73108,2.47896,3.91676,2.47896H1.9999C1.17183,2.47896,0.5,3.1503,0.5,3.97886s0.67183,1.4999,1.4999,1.4999  h1.91686c0.39841,0,0.74702,0.28318,0.82807,0.67378l3.27028,15.78114l-1.07219,1.62879  c-0.77827,1.18302-0.84369,2.6922-0.17186,3.93869c0.67183,1.24601,1.96861,2.01988,3.38453,2.01988h15.04193  c0.82807,0,1.4999-0.67134,1.4999-1.4999s-0.67183-1.4999-1.4999-1.4999H10.15558c-0.45505,0-0.67183-0.31053-0.74409-0.44431  c-0.07226-0.13329-0.21288-0.48532,0.03711-0.86566l0.99395-1.51015h10.98862c3.71752,0,6.98194-2.37825,8.12346-5.91805  l1.70008-5.27747C31.75077,10.96275,31.48712,9.32517,30.53015,8.01325z M28.39944,11.58673l-1.70008,5.276  c-0.74018,2.29623-2.85722,3.8386-5.26819,3.8386H10.8229L8.38782,8.94971h18.08597c0.6523,0,1.24796,0.30271,1.6327,0.83051  C28.49123,10.30899,28.59864,10.96763,28.39944,11.58673z"
    />
  </svg>
</template>

<script>
export default {
  name: "OrderSVG",
};
</script>
