<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <PencilAltIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{ fullName(user) }}
      </h3>
    </template>
    <template #body>
      <div class="mt-5">
        <p class="block text-sm font-medium text-gray-700">
          {{ $t("companyManagement.users.editUserModal.roles") }}
        </p>
        <div v-if="rolesExists">
          <p
            v-for="(role, index) in user.roles"
            :key="index"
            class="mt-1 mb-1 appearance-none inline-flex w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          >
            <span class="text-sm">{{ $t(`global.roles.${role}`) }}</span>

            <span v-if="isUserAdmin" class="ml-auto">
              <TrashIcon
                class="w-5 h-5 cursor-pointer text-gray-400"
                @click="() => deleteRole(role)"
              />
            </span>
          </p>
        </div>
        <div v-else>
          <p class="mt-1">
            {{ $t("companyManagement.users.editUserModal.emptyRoles") }}
          </p>
        </div>
      </div>
      <div v-if="isUserAdmin" class="mt-10">
        <p class="block text-sm font-medium text-gray-700 my-1">
          {{ $t("companyManagement.users.editUserModal.addNewRoles") }}
        </p>
        <Multiselect
          ref="multiselect"
          v-model="newRoles"
          :mode="'tags'"
          :searchable="true"
          :options="rolesList"
          :placeholder="$t('companyManagement.users.editUserModal.chooseRole')"
        />
      </div>
      <div v-if="isFeatureAllowed('legalEntity')" class="mt-10">
        <p class="block text-sm font-medium text-gray-700 my-1">
          {{ $t("companyManagement.users.addUserModal.legalTendency") }}
        </p>
        <div>
          <Multiselect
            v-model="user.legalEntity"
            :searchable="true"
            :options="legalTendencyList"
            :placeholder="
              $t('companyManagement.users.addUserModal.legalTendency')
            "
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            :can-clear="false"
          />
        </div>
      </div>
      <div class="mt-10">
        <Input
          :id="'externalId'"
          :value="user.externalId"
          :name="'externalId'"
          :on-value-change="($event) => (user.externalId = $event.target.value)"
          :type="'text'"
          :placeholder="$t('companyManagement.users.addUserModal.externalId')"
          :label="$t('companyManagement.users.addUserModal.externalId')"
        />
      </div>
    </template>
    <template #footer>
      <Button
        v-focus
        type="button"
        :on-click-handler="onModalClose"
        :color="'gray'"
        :loading="loading"
      >
        {{ $t("buttons.cancel") }}</Button
      >

      <Button :on-click-handler="onUpdate" :loading="loading">
        {{ $t("companyManagement.users.editUserModal.updateBtn") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";
import { userMixin } from "@/components/mixins/index.js";
import { USER_ROLES_MAPPING } from "@/utils/constants";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";
import Multiselect from "@vueform/multiselect";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_USER,
} from "@/store/CompanyManagement/types";
import { Button, Input, Modal } from "@/components/shared/index";
import { AUTH_MODULE, UPDATE_CURRENT_USER } from "@/store/Auth/types";

export default {
  name: "EditUserModal",
  components: {
    Input,
    Modal,
    PencilAltIcon,
    TrashIcon,
    Multiselect,
    Button,
  },
  mixins: [userMixin],
  props: {
    userToEdit: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    fetchUsers: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      newRoles: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["isUserAdmin"]),
    ...mapState(AUTH_MODULE, {
      signedInUser: (state) => state.user,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
    rolesExists() {
      const { roles } = this.user;
      return roles && roles.length;
    },
    isCurrentUserBeingEdited() {
      return this.userToEdit.id === this.signedInUser.id;
    },
    rolesList() {
      const rolesList = [];
      const userRoles = this.user.roles;

      Object.entries(USER_ROLES_MAPPING).forEach(([value]) => {
        if (userRoles && !userRoles.includes(value)) {
          rolesList.push({ label: this.$t(`global.roles.${value}`), value });
        }
      });

      return rolesList;
    },
  },
  watch: {
    userToEdit: {
      handler(newVal, oldVal) {
        if (!isObjectEmpty(newVal) && newVal != oldVal) {
          this.user = deepClone(newVal);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateUser: UPDATE_USER,
    }),
    ...mapMutations(AUTH_MODULE, {
      updateCurrentUser: UPDATE_CURRENT_USER,
    }),
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.user = {};
        this.newRoles = [];
      }, 500);
    },

    userRolesPayload() {
      const newRoles = Object.values(this.newRoles);
      const payload = { roles: [] };

      if (this.rolesExists) {
        payload["roles"] = [...this.user.roles, ...newRoles];
      } else {
        payload["roles"] = newRoles;
      }

      payload["roles"] = payload["roles"].filter((role) => role !== "B");

      return payload;
    },
    onUpdate() {
      const payload = Object.assign({}, this.userRolesPayload(), {
        externalId: this.user.externalId,
      });

      if (this.isFeatureAllowed("legalEntity")) {
        payload.legalEntity = this.user.legalEntity;
      }
      this.loading = true;
      this.updateUser({
        userId: this.user.id,
        payload,
      })
        .then((response) => {
          if (this.isCurrentUserBeingEdited) {
            console.log("current user being updated");

            const { roles, externalId } = response.data;
            this.updateCurrentUser({ roles, externalId });
          }
          this.resetValues();

          this.showNotification(this.$t("toasts.genericSuccess"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.closeModal();
          this.loading = false;
        });
    },
    deleteRole(role) {
      this.user.roles = this.user.roles.filter((r) => r != role);
    },
  },
};
</script>

<style scoped></style>
