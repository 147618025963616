<template>
  <div v-if="showFileOptions" class="flex items-center md:flex-row">
    <RadioOption
      :label="$t('global.image')"
      :name="'mediaType'"
      :input-value="mediaType"
      :value="'image'"
      :on-value-change="() => onMediaChange('image')"
      class="py-2"
    />
    <RadioOption
      :label="$t('global.file')"
      :name="'mediaType'"
      :input-value="mediaType"
      :value="'file'"
      :on-value-change="() => onMediaChange('file')"
      class="py-2"
    />
  </div>
  <div class="file-input">
    <FileInput
      :files="files"
      :type="mediaType"
      :loading="loading"
      :max-files-count="maxFilesCount"
      :show-file-actions="showFileActions"
      :show-paste="showPasteActions"
      @list-update="onFileUploaded"
    />
  </div>
</template>

<script>
import {
  RadioOption,
  UploadArea as FileInput,
} from "@/components/shared/index";
import { MAX_FILES_UPLOADS } from "@/utils/constants";
import { isObjectEmpty } from "@/utils/utility_methods";
export default {
  components: {
    RadioOption,
    FileInput,
  },
  props: {
    onFileUpload: {
      type: Function,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    maximumFileUploadsCount: {
      type: Number,
      default: MAX_FILES_UPLOADS,
    },
    showFileOptions: {
      type: Boolean,
      default: true,
    },
    showFileActions: {
      type: Boolean,
      default: false,
    },
    showPasteActions: {
      type: Boolean,
      default: true,
    },
    objectToUpdate: {
      type: String,
      default: "details",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxFilesCount:
        this.mediaType === "file" ? this.maximumFileUploadsCount : 1,
      mediaType: "image",
    };
  },

  computed: {
    isFileType() {
      return this.mediaType === "file";
    },
    files() {
      if (isObjectEmpty(this.details)) return [];
      return this.details[`${this.mediaType}s`];
    },
  },
  mounted() {
    if (this.type) {
      this.mediaType = this.type;
    } else if (this.details?.files?.length) {
      this.mediaType = "file";
    }
  },
  methods: {
    onMediaChange(type) {
      this.mediaType = type;
      this.maxFilesCount = this.isFileType ? this.maximumFileUploadsCount : 1;
    },
    async onFileUploaded(files) {
      await this.onFileUpload(files, this.mediaType, this.objectToUpdate);
    },
  },
};
</script>
