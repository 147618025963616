<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <ArrowSmDownIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3>{{ $t("purchaseOrder.export.downloadFinishedHeader") }}</h3>
    </template>
    <template #body>{{
      $t("purchaseOrder.export.downloadFinishedText")
    }}</template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button :on-click-handler="getFileAndClose">
        {{ $t(`purchaseOrder.export.downloadFinishedButton.${fileType}`) }}
      </Button>
    </template>
  </Modal>
</template>
<script>
import { Button, Modal } from "@/components/shared";
import { mapState } from "vuex";
import { DOWNLOAD_MODULE } from "@/store/Downloads/types.js";
import { ArrowSmDownIcon } from "@heroicons/vue/solid";
import { downloadMixin } from "@/components/mixins/index.js";
export default {
  components: { Button, Modal, ArrowSmDownIcon },
  mixins: [downloadMixin],
  data() {
    return { showModal: false, fileType: null };
  },
  computed: {
    ...mapState(DOWNLOAD_MODULE, ["invoices", "accrualReport"]),
  },

  watch: {
    invoices: {
      deep: true,
      handler(val) {
        this.fileType = "invoices";
        this.checkAndShowModal(val);
      },
    },
    accrualReport: {
      deep: true,
      handler(val) {
        this.fileType = "accrualReport";
        this.checkAndShowModal(val);
      },
    },
  },

  methods: {
    checkAndShowModal(val) {
      if (val.value !== null && this.$route.name != "PurchaseOrders") {
        this.showModal = true;
      }
    },
    onModalClose() {
      this.showModal = false;
    },
    getFileAndClose() {
      this.fetchFile(this.fileType);
      this.onModalClose();
      this.fileType = null;
    },
  },
};
</script>
