<template>
  <Modal
    :show-modal="showModal"
    :size-classes="['sm:max-w-2xl']"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
  >
    <template #icon>
      <UserAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{ $t("proposals.suggestions.finaliseSuggestionModal.modalTitle") }}
      </h3>
    </template>
    <template #body>
      <div
        v-if="isSuggestionPresent"
        class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6"
      >
        <div class="md:col-span-6 col-span-12">
          <Input
            :id="'title'"
            :error-text="error(v$.purchaseRequest.title)"
            :value="purchaseRequest.title"
            :name="'title'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="
              $t('proposals.suggestions.finaliseSuggestionModal.title')
            "
            :label="$t('proposals.suggestions.finaliseSuggestionModal.title')"
          />
        </div>
        <div class="mt-4 md:col-span-3 col-span-12">
          <RequestAddress
            v-model="purchaseRequest.address"
            is-edit
            object-type
            :is-invalid="v$.purchaseRequest.address.$error"
            :requested-for="purchaseRequest.requestedFor"
          />
        </div>
        <div class="mt-4 md:col-span-3 col-span-12">
          <PaymentMethod
            v-model="purchaseRequest.paymentMethod"
            is-edit
            object-type
            :is-invalid="v$.purchaseRequest.paymentMethod.$error"
            :requested-for="purchaseRequest.requestedFor"
          />
        </div>
        <div class="mt-4 md:col-span-3 col-span-12">
          <label class="block text-sm font-medium text-gray-700">
            {{ $t("proposals.suggestions.finaliseSuggestionModal.category") }}
          </label>
          <div class="mt-1">
            <CategorySelect
              v-model="purchaseRequest.category"
              :error="v$.purchaseRequest.category.$error"
            />
          </div>
        </div>
        <div class="mt-4 md:col-span-3 col-span-12">
          <label class="block text-sm font-medium text-gray-700">
            {{
              $t(
                "proposals.suggestions.finaliseSuggestionModal.companySupplier"
              )
            }}
          </label>
          <div class="mt-1">
            <SupplierSelect
              v-model="purchaseRequest.companySupplier"
              :label="'name'"
              :placeholder="
                $t(
                  'proposals.suggestions.finaliseSuggestionModal.companySupplierPlaceholder'
                )
              "
              :category-to-filter="purchaseRequest.category"
            />
          </div>
        </div>
        <div class="mt-4 md:col-span-6 py-4">
          <SelectRequestedFor
            v-model="purchaseRequest.requestedFor"
            :is-in-modal="true"
            :has-errors="v$.purchaseRequest?.requestedFor?.$error"
            :validation-errors="v$.purchaseRequest?.requestedFor?.$errors"
            is-mandatory
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button v-focus :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("proposals.suggestions.suggestionForm.cancel") }}
      </Button>
      <Button :on-click-handler="onSave" :loading="loading">
        {{ $t("proposals.suggestions.suggestionForm.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { UserAddIcon } from "@heroicons/vue/solid";
import {
  Modal,
  Button,
  Input,
  PaymentMethod,
  CategorySelect,
  SupplierSelect,
} from "@/components/shared/index";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import { mapActions } from "vuex";
import { SelectRequestedFor } from "@/components/shared/";
import {
  isObjectEmpty,
  removeKeysInObjWithNoValue,
} from "@/utils/utility_methods";
import {
  FINALISE_SUGGESTION,
  PROPOSAL_MODULE,
  UPDATE_SUGGESTION_PURCHASE_REQUEST,
} from "@/store/Proposal/types";
import RequestAddress from "@/components/shared/RequestAddress";
import { requestedForValidation } from "@/utils/helpers/purchaseRequestHelper";

export default {
  components: {
    SupplierSelect,
    RequestAddress,
    Modal,
    Button,
    Input,
    UserAddIcon,
    SelectRequestedFor,
    PaymentMethod,
    CategorySelect,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    suggestion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      purchaseRequest: {},
      loading: false,
    };
  },
  computed: {
    isSuggestionPresent() {
      return !isObjectEmpty(this.suggestion);
    },
  },
  watch: {
    suggestion: {
      handler(newVal) {
        if (!isObjectEmpty(newVal)) {
          this.purchaseRequest.items = newVal.purchaseRequest.items;
        }
      },
    },
    "purchaseRequest.requestedFor": function (newVal) {
      if (newVal) {
        if (newVal?.type === "project") {
          this.purchaseRequest.project = newVal.id;
        }
        if (newVal?.type != "project") {
          this.purchaseRequest.department = newVal?.department;
        }
      }
    },
  },

  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      updateSuggestionPurchaseRequest: UPDATE_SUGGESTION_PURCHASE_REQUEST,
      finaliseSuggestion: FINALISE_SUGGESTION,
    }),
    onModalClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.purchaseRequest = {};
        this.v$.$reset();
      }, 500);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.purchaseRequest[name] = value;
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        const purchaseRequestId = this.suggestion.purchaseRequest.id;
        this.updateSuggestionPurchaseRequest({
          suggestionId: this.suggestion.id,
          payload: {
            purchaseRequest: this.updateRequestPayload(purchaseRequestId),
          },
        })
          .then(() => {
            this.finaliseSuggestion(this.suggestion.id)
              .then(() => {
                this.$router.push({
                  name: "RequestDetail",
                  params: { id: purchaseRequestId },
                });
              })
              .catch((error) => {
                this.showErrorMessage(error);
              });
          })
          .catch((error) => {
            this.showErrorMessage(error);
          })
          .finally(() => (this.loading = false));
      }
    },
    updateRequestPayload(purchaseRequestId) {
      const { title, items } = this.purchaseRequest;

      return {
        id: purchaseRequestId,
        title,
        items: this.enrichItems(items),
      };
    },
    enrichItems(items) {
      const {
        netAmount,
        grossAmount,
        vat,
        quantity,
        netAmountCurrency,
        grossAmountCurrency,
      } = items[0];
      items[0].itemPrices = [
        {
          netAmount,
          grossAmount,
          vat,
          netAmountCurrency,
          grossAmountCurrency,
          quantity,
        },
      ];
      items[0].paymentMethod = this.purchaseRequest.paymentMethod.id;
      items[0].companySupplier = this.purchaseRequest.companySupplier;
      items[0].companyAddress = this.purchaseRequest.address.id;
      items[0].category = this.purchaseRequest.category;
      items[0].requestedFor = this.purchaseRequest.requestedFor;
      items = this.itemsPayload(items);
      return items;
    },
    itemsPayload(items) {
      return [removeKeysInObjWithNoValue(items[0])];
    },
  },
  validations() {
    const validations = {
      purchaseRequest: {
        title: { required },
        address: { required },
        paymentMethod: { required },
        category: { required },
        requestedFor: { required },
      },
    };

    if (this.purchaseRequest.requestedFor) {
      validations.purchaseRequest.requestedFor = requestedForValidation(
        validations.purchaseRequest.requestedFor,
        this.purchaseRequest.requestedFor
      );
    }

    return validations;
  },
};
</script>
