import axios from "axios";
import * as Sentry from "@sentry/vue";
import {
  newVersionAvailable,
  setItemInLocalStorage,
} from "@/utils/utility_methods.js";
import { BASE_URL } from "@/utils/services/apiUrls.js";
import { headers } from "@/utils/services/setup/requestConstants.js";
import fusionAuth from "@/utils/services/auth";
import { REDIRECTION_ROUTES } from "@/utils/Constants/RouteConstants";
import { stringToCamelCase, logSentryMessage } from "@/utils/utility_methods";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers = headers();
const uniqueId = () => {
  const randomId = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return `${randomId()}-${randomId()}-${randomId()}-${randomId()}-${randomId()}-${randomId()}-${randomId()}-${randomId()}`;
};

const checkRoute = (errorRequest) => {
  const currentRoute = window.location.pathname.split("/")[1];
  const routes = REDIRECTION_ROUTES[currentRoute];
  if (!routes) return false;

  return routes.some((route) => errorRequest.responseURL.includes(route));
};
axios.interceptors.request.use(
  function (config) {
    const transactionId = uniqueId();
    const spanId = uniqueId();
    Sentry.configureScope((scope) => {
      scope.setTag("transaction_id", transactionId);
      scope.setTag("span_id", spanId);
    });
    config.headers["X-Transaction-ID"] = transactionId;
    // config.headers["X-Span-ID"] = spanId;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    newVersionAvailable(response.headers["x-hivebuy-frontend"]);
    return response;
  },
  async (error) => {
    const errors = error.response.data?.errors;
    if (!errors && error.response.status === 500) {
      const errorObject = {
        details: error.response.data,
        errorMessageCode: "Internal Error",
        errorCode: error.response.status,
      };
      logSentryMessage("INTERNAL SERVER ERROR", errorObject);
      return Promise.reject(errorObject);
    }
    if (
      !error?.response?.config?.url.includes("self-signup") &&
      ["invalidToken", "notAuthenticated"].includes(errors[0].code)
    ) {
      await fusionAuth.redirectToLoginUrl();
    }
    const code = errors[0]?.code;
    const statusCode = error?.response?.status;
    if (statusCode && statusCode > 500) {
      window.location.href = `${window.location.origin}/something-went-wrong`;
      setItemInLocalStorage("refreshUrl", window.location.href);
    } else if (
      code == "notFound" &&
      error.response.headers["content-type"] === "application/json" &&
      error.code == "ERR_BAD_REQUEST" &&
      checkRoute(error.request)
    ) {
      window.location = `${window.location.origin}/not-found`;
    }

    let errorText = "";
    const errorResponse = error?.response?.data?.errors;
    if (errorResponse?.length) {
      errorText = stringToCamelCase(
        errorResponse[0]?.detail.replaceAll(".", "")
      );
    }

    return Promise.reject({
      details: error,
      errorMessageCode: errorText,
      errorCode: code,
    });
  }
);

export default axios;
