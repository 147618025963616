<template>
  <div class="flex flex-col mt-8">
    <div
      class="flex md:flex-row md:gap-x-10 flex-col items-center justify-between mb-4"
    >
      <div class="w-full">
        <Input
          id="search"
          :value="search"
          name="search"
          :placeholder="$t('general.search')"
          show-icon
          type="search"
          :on-key-up="(event) => (search = event.target.value)"
        />
      </div>
      <CSVUpload type="budget" level="D" @success="onCSVUploaded" />
    </div>
    <div class="table-card overflow-visible no-shadow">
      <TableLoader v-if="!areBudgetsFetched" :columns="6" :rows="3" />
      <div
        v-else-if="areBudgetsFetched && departmentsPresent && categoriesPresent"
      >
        <TreeTable
          ref="treeTable"
          :value="categories"
          :columns="columns"
          class="p-treetable-lg"
          scrollable
          scroll-height="flex"
          scroll-direction="both"
          filter-mode="lenient"
          :filters="{ global: search }"
        >
          <Column field="name" header="Category" expander frozen>
            <template #body="slotProps">
              <div
                v-tippy="{
                  content: slotProps.node.data.name,
                  delay: [300, 200],
                  theme: 'time',
                }"
                :class="[
                  'category-name-truncate',
                  {
                    'text-primary font-semibold': !isValuePresent(
                      slotProps.node.data.categoryIndex
                    ),
                  },
                ]"
              >
                {{ slotProps.node.data.name }}
              </div>
            </template>
          </Column>
          <Column
            v-for="(department, index) in departments"
            :key="index"
            :field="department.id"
            :header="department.name"
            class="department-name"
          >
            <template #body="slotProps">
              <EditableText
                v-if="isValuePresent(slotProps.node.data.categoryIndex)"
                v-model="
                  getBudget(slotProps.node.data.categoryIndex, index).amount
                "
                type="currency"
                :on-confirm-handler="
                  (value) =>
                    updateDepartmentBudget(
                      value,
                      getBudget(slotProps.node.data.categoryIndex, index),
                      department.id
                    )
                "
                class="min-w-[6.5rem]"
                :show-edit-icon="false"
                currency-format="budget"
                :dense="true"
                :loading="
                  budgetLoading[
                    getBudget(slotProps.node.data.categoryIndex, index).id
                  ]
                "
                :show-success-animation="false"
                :currency="company.currency"
              />
              <div v-else class="text-primary font-semibold px-2">
                <i18n-n
                  :value="parseInt(department.totalAmount)"
                  format="budget"
                  minimum-fraction-digits="2"
                  :locale="currencyToLocale(department.totalAmountCurrency)"
                />
              </div>
            </template>
          </Column>
          <Column field="totalAmount" header="Total">
            <template #body="slotProps">
              <div class="text-primary font-semibold text-right">
                <i18n-n
                  :value="parseInt(slotProps.node.data.totalAmount)"
                  format="budget"
                  minimum-fraction-digits="2"
                  :locale="currencyToLocale(company.currency)"
                />
              </div>
            </template>
          </Column>
        </TreeTable>
      </div>
      <div v-else class="text-center">
        <EmptyState
          :heading-text="$t('emptyStates.budgetNoDepartmentCategoryHeader')"
          :description-text="$t('emptyStates.budgetNoDepartmentCategoryText')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_BUDGETS,
} from "@/store/CompanyManagement/types";

import {
  currencyToLocale,
  isObjectEmpty,
  isValuePresent,
  sumInArray,
} from "@/utils/utility_methods";
import {
  EmptyState,
  Input,
  TableLoader,
  CSVUpload,
} from "@/components/shared/index";
import EditableText from "@/components/shared/EditableText";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import BudgetCsvMixin from "@/components/mixins/budgetCsvMixin.js";
export default {
  components: {
    Input,
    EditableText,
    EmptyState,
    TableLoader,
    TreeTable,
    Column,
    CSVUpload,
  },
  mixins: [BudgetCsvMixin],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      departmentBudgets: (state) => state.budgets.departmentBudgets,
      budgets: (state) => state.budgets,
      company: (state) => state.company,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoryTreeWithChildren"]),
    categories() {
      const results = this.categoryTreeWithChildren()
        .filter((category) => this.isCategoryExistInDepartment(category))
        .map((category, categoryIndex) =>
          this.mergeCategoryAndChildren(category, categoryIndex)
        );

      results.push({
        key: results.length,
        data: {
          totalAmount: this.overallAmount,
          name: "Total",
        },
      });

      return results;
    },
    departments() {
      return this.departmentBudgets?.departments || [];
    },
    departmentsPresent() {
      return this.departments.length;
    },
    categoriesPresent() {
      return this.categories.length;
    },
    areBudgetsFetched() {
      return !this.isLoading && !isObjectEmpty(this.budgets);
    },
    columns() {
      const departmentNames = this.departments.map(
        (department) => department.name
      );
      return [
        { field: "name", header: "Category Name" },
        ...departmentNames.map((name) => ({ field: name, header: name })),
        { field: "totalAmount", header: "Total" },
      ];
    },
    overallAmount() {
      return this.sumInArray(this.departments, "totalAmount") || 0;
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateBudgets: UPDATE_BUDGETS,
    }),
    departmentCategory(category) {
      return this.departmentBudgets.categories.find(
        (cat) => cat.id === category.id
      );
    },
    onEdit(department) {
      this.departmentToEdit = department;
    },
    updateDepartmentBudget(value, budget) {
      this.budgetLoading[budget.id] = true;
      const budgetObj = { ...budget, amount: value };
      const payload = {
        budgetLevel: "D",
        budgets: [budgetObj],
        legalEntity: this.legalEntity,
      };
      this.updateBudgets(payload)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.budgetLoading[budget.id] = false));
    },
    departmentId(name) {
      return this.departments.find((department) => department.name === name).id;
    },
    getBudget(categoryIndex, departmentIndex) {
      return this.departmentBudgets.departments[departmentIndex].budgets[
        categoryIndex
      ];
    },
    mergeCategoryAndChildren(category) {
      const departmentCategories = this.departmentBudgets.categories;
      const categoryIndex = departmentCategories.findIndex(
        (deptCategory) => deptCategory.id === category.id
      );

      const mergedCategory = {
        key: categoryIndex,
        data: {
          ...category,
          ...this.departmentCategory(category),
          categoryIndex,
        },
      };

      if (category.children) {
        const children = category.children
          .filter((childCategory) =>
            this.isCategoryExistInDepartment(childCategory)
          )
          .map((childCategory) => {
            const childCategoryIndex = departmentCategories.findIndex(
              (deptCategory) => deptCategory.id === childCategory.id
            );
            return {
              key: childCategoryIndex,
              data: {
                ...childCategory,
                ...this.departmentCategory(childCategory),
                ...{ categoryIndex: childCategoryIndex },
              },
            };
          });
        if (children.length) {
          mergedCategory.children = children;
        }
      }

      return mergedCategory;
    },
    isCategoryExistInDepartment(category) {
      const departmentCategories = this.departmentBudgets.categories;
      return departmentCategories.some(
        (deptCategory) => deptCategory.id === category.id
      );
    },
    isValuePresent,
    currencyToLocale,
    sumInArray,
  },
};
</script>

<style scoped>
.category-name-truncate {
  @apply max-w-[150px] overflow-hidden whitespace-nowrap hover:text-primary;
  text-overflow: ellipsis;
}

:deep(.department-name span) {
  @apply max-w-[100px];
}

:deep(.p-treetable .p-frozen-column) {
  @apply w-[220px];
}

:deep(.p-treetable .p-treetable-tbody .p-frozen-column) {
  @apply bg-gray-100;
  z-index: 1;
}

:deep(.p-treetable .p-treetable-thead > tr > th.department-name) {
  @apply w-[150px];
}

:deep(.p-treetable .p-treetable-tbody > tr > td.department-name) {
  @apply w-[150px] h-[50px];
}

:deep(.p-treetable tr:first-child th) {
  @apply line-clamp-2 text-sm;
  overflow-wrap: break-word;
}
</style>
