import memberDetailsMixin from "@/components/mixins/memberDetailsMixin";
export default {
  mixins: [memberDetailsMixin],
  methods: {
    async statusDetails(statuses, statusDetail) {
      const record = statuses.find((status) => status.status == statusDetail);
      const details = { status: record ? true : false };
      if (record) {
        // createdBy should be sent to a function
        // where if it is object (for legacy support) then simply return it
        // otherwise if it is simply id or object with key id
        // search in user if it is there get it from there otherwise fetch from
        // db by members/users api
        if (record.createdBy)
          details["user"] = await this.getMemberDetails(record.createdBy);
        details["time"] = record.statusDate;
        details["note"] = record.reason;
      }
      return details;
    },

    async posSimpleStatus(purchaseOrder) {
      if (!purchaseOrder?.orderStatuses?.length) return {};

      const ordered = await this.statusDetails(
        purchaseOrder.orderStatuses,
        "O"
      );
      const delivered = await this.statusDetails(
        purchaseOrder.orderStatuses,
        "D"
      );
      const canceled = await this.statusDetails(
        purchaseOrder.orderStatuses,
        "CA"
      );
      return {
        ordered: ordered.status,
        orderedAt: ordered.time,
        delivered: delivered.status,
        deliveredAt: delivered.time,
        invoiced: purchaseOrder.invoiced,
        canceled: canceled.status,
        canceledBy: canceled.user,
        canceledAt: canceled.time,
        canceledReason: canceled.note,
      };
    },

    async poStatusDetails(purchaseOrder, trans) {
      const ordered = await this.statusDetails(
        purchaseOrder.orderStatuses,
        "O"
      );
      const delivered = await this.statusDetails(
        purchaseOrder.orderStatuses,
        "D"
      );

      return {
        ordered: {
          "details-added": ordered.status,
          title: trans("purchaseRequest.summary.purchaseOrderStatus.ordered"),
          subtitle: trans(
            "purchaseRequest.summary.purchaseOrderStatus.orderedMark"
          ),
          user: ordered.user,
          time: ordered.time,
          note: ordered.note,
          icon: "OrderSVG",
          status: ordered.status,
        },
        delivered: {
          "details-added": delivered.status,
          title: trans("purchaseRequest.summary.purchaseOrderStatus.delivered"),
          subtitle: trans(
            "purchaseRequest.summary.purchaseOrderStatus.deliveredMark"
          ),
          user: delivered.user,
          time: delivered.time,
          note: delivered.note,
          icon: "DeliverSVG",
          status: delivered.status,
        },
        invoiced: {
          "details-added": purchaseOrder.invoiced,
          title: trans("purchaseRequest.summary.purchaseOrderStatus.invoiced"),
          subtitle: trans(
            "purchaseRequest.summary.purchaseOrderStatus.invoicedMark"
          ),
          user: purchaseOrder.invoiceUploadedBy,
          time: purchaseOrder.invoiceUploadedAt,
          note: purchaseOrder.invoiceUploadedNote,
          icon: "InvoiceSVG",
        },
      };
    },
  },
};
