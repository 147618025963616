<template>
  <AddressForm
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-address-method="saveAddressMethod"
  />
</template>

<script>
import { mapActions } from "vuex";
import {
  ADD_COMPANY_ADDRESS,
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_ADDRESSES,
} from "@/store/CompanyManagement/types";
import AddressForm from "@/components/CompanyManagement/Profile/CompanyAddresses/AddressForm";

export default {
  components: {
    AddressForm,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addCompanyAddress: ADD_COMPANY_ADDRESS,
      getCompanyAddresses: GET_COMPANY_ADDRESSES,
    }),
    saveAddressMethod(data) {
      this.addCompanyAddress(data)
        .then(() => {
          this.closeModal();
          this.getCompanyAddresses();
          this.showNotification("Address saved successfully");
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>
