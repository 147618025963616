<template>
  <span v-if="!category && !purchaseRequest && !categories?.length">-</span>
  <div v-if="loading" class="w-5 h-5">
    <HivebuyLoader />
  </div>
  <div v-else class="truncate-min">
    <div
      v-if="isStructuredOutput && multipleCategoriesToDisplay"
      class="truncate-min"
    >
      <SquashedList
        v-if="squashed && list"
        :list="list"
        :display-attribute="'name'"
        :icon-attribute="'emoji'"
        type="category"
      />
      <div v-else-if="withTooltip">
        <NameWithTooltip
          v-for="categoryObject in list"
          :key="categoryObject.id"
          :name="categoryObject.name"
        />
      </div>
      <span v-else>No item in list </span>
    </div>
    <span v-else>
      <span v-if="multipleCategoriesToDisplay"> {{ list }} </span>
      <span v-else-if="record"> {{ record.name }} </span>
      <span v-else> - </span>
    </span>
  </div>
</template>
<script>
import {
  NameWithTooltip,
  SquashedList,
  HivebuyLoader,
} from "@/components/shared/";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_CATEGORY,
} from "@/store/CompanyManagement/types";
import { mapActions } from "vuex";

export default {
  components: { NameWithTooltip, SquashedList, HivebuyLoader },
  props: {
    squashed: {
      type: Boolean,
      default: false,
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: { type: Object, default: null },
    category: { type: String, default: null },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categoriesDetailList: null,
      list: null,
      record: null,
      loading: false,
    };
  },
  computed: {
    isStructuredOutput() {
      return this.withTooltip || this.squashed;
    },
    categoryIdList() {
      let catArray = this.categories;
      if (!catArray.length && this.purchaseRequest?.items?.length) {
        catArray = this.purchaseRequest.items.map(
          (item) => item.category?.id || item.category
        );
      }
      return catArray;
    },
    multipleCategoriesToDisplay() {
      return this.categoryIdList.length;
    },
  },
  watch: {
    categoryIdList: {
      handler() {
        this.fetchDetails();
      },
      immediate: true,
    },
    isStructuredOutput: {
      handler() {
        this.fetchDetails();
      },
      immediate: true,
    },
    category: {
      handler() {
        this.fetchDetails();
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCategory: GET_CATEGORY,
    }),
    fetchDetails() {
      if (this.categoryIdList.length) {
        this.categoriesDetailObject();
      } else {
        this.fetchIndividual();
      }
    },

    async fetchIndividual(id) {
      let cat = id;
      if (!id) cat = this.category || this.purchaseRequest?.category;
      if (!cat) {
        return;
      }
      this.loading = true;
      const response = await this.getCategory(cat);
      this.loading = false;
      if (id) return response;

      this.record = response;
    },

    async categoriesDetailObject() {
      const categoryIds = [...new Set(this.categoryIdList)];
      const promises = [];
      categoryIds.forEach((id) => {
        promises.push(this.fetchIndividual(id));
      });
      let categoryRecords = await Promise.all(promises);
      categoryRecords = categoryRecords.filter((item) => item);

      if (this.isStructuredOutput) {
        this.list = categoryRecords;
        return;
      }

      const catNames = [
        ...new Set(categoryRecords.map((item) => item?.name || item)),
      ];

      this.list = new Intl.ListFormat().format(catNames);
    },
  },
};
</script>
