<template>
  <div>
    <HivebuyTooltip :open-delay="0" :hover="true">
      <div
        class="flex items-center px-1.5 py-1 rounded-md hover:border-gray-200 border border-gray-200 hover:bg-gray-200 cursor-pointer"
      >
        <UserIcon v-if="checkIfUpdated" class="w-4 h-4 text-hivebuy-grey" />
        <div
          v-else
          class="h-4 w-4 rounded-full"
          :class="`${getConfColorString.bg}`"
        />
      </div>
      <template #content>
        <div class="text-sm w-72">
          <ul role="list">
            <li class="bg-white divide-y divide-gray-200">
              <div
                class="w-full flex items-center justify-between py-2 px-4 space-x-4"
              >
                <div class="flex-1">
                  <h3 class="label !mb-0">{{ label }}</h3>
                  <p class="mt-1 text-gray-500 text-sm truncate">
                    {{ $t("invoice.form.dataFound") }}:
                    {{ ocrDetails?.bboxRefs?.length }}
                  </p>
                  <p class="mt-1 text-gray-500 text-sm truncate">
                    {{ $t("invoice.form.onPages") }}: {{ foundPages }}
                  </p>

                  <div
                    v-if="ocrDetails?.validationProblem"
                    class="rounded my-2 p-2 border border-hivebuy-red border-dotted"
                  >
                    <div class="flex">
                      <div class="flex-1 md:flex md:justify-between">
                        <h3 class="text-xs font-medium text-orange-500">
                          <i18n-t keypath="invoice.validationProblem" tag="p">
                            <template #field>
                              <span class="font-bold text-primary mx-2">
                                {{ label }}
                              </span>
                            </template>
                          </i18n-t>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="ocrDetails?.note"
                    class="flex items-center justify-center text-xs font-medium text-orange-700 cursor-pointer hover:underline"
                    @click="() => (seeDetails = !seeDetails)"
                  >
                    Details
                  </div>
                  <p
                    v-if="ocrDetails?.note && seeDetails"
                    class="text-gray-500 text-xs"
                  >
                    {{ $t("invoice.form.note") }}:
                    <span class="text-hivebuy-red">{{ ocrDetails.note }}</span>
                  </p>
                </div>

                <div
                  v-if="!ocrDetails?.validationProblem"
                  class="w-10 h-10 rounded-full flex-shrink-0 flex items-center justify-center font-semibold text-sm"
                  :class="[getConfColorString.bg, getConfColorString.text]"
                >
                  {{ parseInt(ocrDetails?.confidence * 100) || 0 }}
                </div>
              </div>
              <div
                v-if="checkIfUpdated"
                class="rounded-md bg-blue-50 p-3 mx-2 mb-2"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <UserIcon
                      class="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-xs text-blue-700">
                      {{ $t("invoice.updatedByUser") }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="checkIfUpdated">
                <div class="-mt-px flex">
                  <div class="-ml-px w-0 flex-1 flex">
                    <span
                      class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm text-gray-500 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                    >
                      <div class="flex items-center justify-center">
                        <span>{{ ocrDetails?.value }}</span>
                        <div
                          class="ml-2 relative flex items-center px-1 py-0.5 rounded-md hover:border-gray-300 border border-gray-200 cursor-pointer group"
                          @click="onUpdateValue(ocrDetails?.value)"
                        >
                          <ArrowCircleRightIcon
                            class="h-5 w-5 group-hover:text-hivebuy_turquoise"
                          />
                          <span
                            class="absolute hidden group-hover:block -right-10"
                            >Insert</span
                          >
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </HivebuyTooltip>
  </div>
</template>

<script>
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import { ArrowCircleRightIcon, UserIcon } from "@heroicons/vue/outline";
import { isStringADate } from "@/utils/utility_methods";
export default {
  components: {
    HivebuyTooltip,
    ArrowCircleRightIcon,
    UserIcon,
  },
  props: {
    fieldValue: { type: [null, Number, String, Date], default: "" },
    ocrDetails: {
      type: Object,
      default: null,
    },
    onUpdateValue: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      seeDetails: false,
    };
  },
  computed: {
    isDateField() {
      return this.ocrDetails?.value && isStringADate(this.ocrDetails?.value);
    },
    isNumberOrFloat() {
      const parsedValue = parseFloat(this.ocrDetails?.value);
      return !isNaN(parsedValue) && isFinite(parsedValue);
    },
    checkIfUpdated() {
      if (this.isDateField)
        return Date.parse(this.fieldValue) != Date.parse(this.ocrDetails.value);
      if (this.isNumberOrFloat) {
        const fieldValueNum = parseFloat(this.fieldValue);
        const ocrValueNum = parseFloat(this.ocrDetails.value);
        return (
          fieldValueNum.toFixed(this.backendDecimalPoint) !==
          ocrValueNum.toFixed(this.backendDecimalPoint)
        );
      }
      return this.fieldValue != this.ocrDetails.value;
    },
    foundPages() {
      const newArray = [];
      this.ocrDetails?.bboxRefs?.filter((box) => {
        newArray.push(box.pageNum);
      });

      return newArray.filter((v, i, a) => a.indexOf(v) === i);
    },

    getConfColorString() {
      const { confidence, validationProblem } = this.ocrDetails;
      let returnHash = this.confidenceClass("neutral");
      const conf = confidence * 100;

      if (conf < 51 || validationProblem)
        returnHash = this.confidenceClass("error");
      else if (conf < 91) returnHash = this.confidenceClass("warning");
      else if (conf >= 91) returnHash = this.confidenceClass("success");
      return returnHash;
    },
  },
  methods: {
    confidenceClass(status) {
      return {
        success: {
          bg: "bg-hivebuy-green",
          text: "text-white",
          general: "hivebuy-green",
        },
        warning: {
          bg: "bg-hivebuy-yellow",
          text: "text-gray-600",
          general: "hivebuy-yellow",
        },
        error: {
          bg: "bg-orange-500",
          text: "bg-white",
          general: "hivebuy-red",
        },
        neutral: {
          bg: "bg-gray-300",
          text: "text-gray-700",
          general: "primary",
        },
      }[status];
    },
  },
};
</script>
