<template>
  <div class="flex items-center justify-between">
    <h1 class="page-title">
      {{ $tc("home.news", 2) }}
    </h1>
    <button
      v-if="isUserNewsAdmin"
      type="button"
      class="flex font-semibold items-center mr-4 text-primary hover:text-primarydark"
      @click="toggleNewsModal"
    >
      <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
      {{ $t("news.addNews") }}
    </button>
  </div>
  <List />

  <AddNewsModal :show-modal="showNewsModal" :close-modal="toggleNewsModal" />
</template>

<script>
import List from "@/components/News/List.vue";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import AddNewsModal from "@/components/News/AddNewsModal.vue";
import { mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  name: "News",
  components: { AddNewsModal, PlusCircleIcon, List },
  data() {
    return {
      showNewsModal: false
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    isUserNewsAdmin() {
      return this.user.roles.includes("NA");
    }
  },
  methods: {
    toggleNewsModal() {
      this.showNewsModal = !this.showNewsModal;
    },
  }
};
</script>
