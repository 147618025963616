<template>
  <div class="justify-self-auto animate-pulse h-56">
    <div class="bg-white overflow-hidden flex flex-col justify-between h-full">
      <div class="flex-grow">
        <div class="divide-y divide-gray-200 px-4 divide-dotted">
          <div class="py-1 grid grid-cols-6 gap-y-1">
            <div
              class="col-span-5 my-3 w-full bg-gray-100 rounded-md h-4"
            ></div>
            <div
              class="col-span-6 my-2 w-full bg-gray-100 rounded-md h-4"
            ></div>
            <div
              class="col-span-4 my-2 w-full bg-gray-100 rounded-md h-4"
            ></div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 px-5 py-3 text-center mt-2">
        <div class="h-1 py-4"></div>
      </div>
    </div>
  </div>
</template>
