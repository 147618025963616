export default {
  data() {
    return {
      paginationParams: {
        page: 1,
        pageSize: 10,
        totalPages: "",
        totalRecords: "",
        search: "",
        ordering: "",
      },
    };
  },
};
