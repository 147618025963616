import * as TYPES from "./types.js";

export default {
  [TYPES.SET_LOADING_STATE]: (state, data = {}) => {
    const { key, value } = data;
    let stateValue = true;
    if (typeof value == "boolean") stateValue = value;

    if (!key) {
      Object.keys(state).forEach((stateKey) => (state[stateKey] = stateValue));
    } else {
      state[key] = value;
    }
  },
};
