<template>
  <Modal :show-modal="open" :close-modal="closeFunction">
    <template #icon>
      <CheckCircleIcon class="h-8 w-8 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-xl font-medium text-gray-500">
        {{ $t(`purchaseRequest.newRequest.saveRequest.title`) }}
      </h3>
    </template>
    <template #body>
      <div class="my-6 px-1">
        <p class="text-md text-gray-500 mb-4">
          {{ $t(`purchaseRequest.newRequest.saveRequest.text`) }}
        </p>
        <Input
          :value="suggestion"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :on-enter="saveTitle"
          :label="$t(`purchaseRequest.newRequest.saveRequest.label`)"
        />
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="closeFunction"
        color="cancel-gray"
        :loading="loading"
      >
        <template #icon>
          <XIcon />
        </template>
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        id="save-request"
        :on-click-handler="saveTitle"
        color="success"
        full
        :loading="loading"
      >
        <template #icon>
          <CheckCircleIcon />
        </template>
        {{ $t("buttons.save") }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, Input } from "@/components/shared/index";
import { CheckCircleIcon, XIcon } from "@heroicons/vue/outline";
export default {
  components: {
    Modal,
    Input,
    Button,
    CheckCircleIcon,
    XIcon,
  },
  props: {
    suggestion: {
      type: String,
      required: false,
      default: "",
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    closeFunction: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    suggestion: {
      handler(val) {
        this.title = val;
      },
      immediate: true,
    },
  },
  methods: {
    onAttributeChange(event) {
      this.title = event.target.value;
    },
    saveTitle() {
      this.saveMethod(this.title);
    },
  },
};
</script>
