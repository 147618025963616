import * as PurchaseOrderTypes from "@/store/PurchaseOrder/types.js";
import { formatLiveUpdate } from "@/utils/helpers/purchaseRequestHelper.js";
export default {
  [PurchaseOrderTypes.SET_PURCHASE_ORDERS]: (state, { payload }) => {
    state.purchaseOrders = payload;
  },

  [PurchaseOrderTypes.SET_SUPPLIER_ORDERS]: (state, payload) => {
    state.supplierOrders = payload;
  },

  [PurchaseOrderTypes.SET_SUPPLIER_ORDER]: (state, id) => {
    console.log(state, id);
    // const orderIndex = state.supplierOrders.findIndex(
    //   (order) => order.id == id
    // );
    // if (orderIndex >= 0) {
    //   state.supplierOrders[orderIndex] = {
    //     ...state.supplierOrders[orderIndex],
    //     companySupplier: {
    //       ...state.supplierOrders[orderIndex].companySupplier,
    //       name: "Updated",
    //     },
    //   };
    // }
  },
  [PurchaseOrderTypes.SET_PURCHASE_ORDERS_LIST_FILTERS]: (state, payload) => {
    state.purchaseOrdersListFilters = payload;
  },
  [PurchaseOrderTypes.SET_ACTION_REQUIRED_PURCHASE_ORDERS]: (
    state,
    payload
  ) => {
    state.actionRequiredPurchaseOrders = payload;
  },
  [PurchaseOrderTypes.LIVE_UPDATE_ORDER_REQUEST]: (state, payload) => {
    const prState = {
      purchaseOrders: { pagination: false },
      actionRequiredPurchaseOrders: { pagination: true },
    };

    Object.keys(prState).forEach((key) => {
      if (prState[key].pagination) {
        state[key].results = formatLiveUpdate(payload, state[key].results, key);
      } else {
        state[key] = formatLiveUpdate(payload, state[key], key);
      }
    });
  },
  [PurchaseOrderTypes.SET_PURCHASE_ORDERS_LIST_PARAMS]: (state, payload) => {
    state.purchaseOrdersListParams = payload;
  },

  [PurchaseOrderTypes.SET_SUPPLIER_LIST_PARAMS]: (state, payload) => {
    state.supplierListParams = payload;
  },
};
