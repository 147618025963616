export default {
  data() {
    return {
      debounceTimeout: null,
    };
  },
  methods: {
    executeDebounceSearch(method, options = { key: "search", value: null }, timeout = 1000) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(
        () => method(options.key, options.value),
        timeout
      );
    },
  },
};
