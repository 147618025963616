<template>
  <div v-if="loading" class="loader-holder">
    <div class="h-36 w-36"><HivebuyLoader /></div>
  </div>
  <div v-else class="summary-content">
    <div class="summary-header">
      <SummaryHeader
        :read-only="false"
        :change-form="setCurrentPage"
        :item-level-approval="isItemLevelApprovalAllowed"
        :is-editable="() => {}"
      />
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 lg:col-span-8">
        <div v-if="currentRequest?.items?.length">
          <div>
            <BasicInformation
              :purchase-request="currentRequest"
              :is-purchase-request-editable="true"
              :read-only="false"
            />
            <Products
              :purchase-request-items="currentRequest.items"
              :read-only="false"
              :currency="companyCurrency().value"
              :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
              :is-preview="true"
              :loading="loading"
            />
            <PaymentAndDeliverySection
              v-if="!isItemLevelApprovalAllowed && currentRequest"
              :purchase-request="currentRequest"
              :is-purchase-request-editable="true"
              :on-edit-purchase-request-attribute="() => {}"
              :show-edit-btn="true"
              :product="currentRequest.items[0]"
            />
          </div>
          <PurchaseRequestTitle
            :save-method="createPurchaseRequest"
            :open="showTitleForm"
            :toggle-function="createPurOrToggleTitleModal"
            :suggestion="currentRequest.items[0].name"
          />
        </div>
        <div v-else class="text-center">
          you don't have any product yet. please
          <span
            class="text-primary capitalize"
            @click="() => setCurrentPage('category')"
            >add a product</span
          >
          to initiate the purchase request
        </div>
      </div>
      <div v-if="currentRequest" class="col-span-12 lg:col-span-4">
        <div class="px-0 sticky top-4">
          <TotalPrice
            :show-buttons="true"
            :save-action="createPurOrToggleTitleModal"
            :purchase-request="currentRequest"
            :new-request="!isEditRoute"
            :loading="requestLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PurchaseRequestTitle, TotalPrice } from "@/components/PurchaseRequest";
import Products from "@/components/PurchaseRequest/SummarySections/Products";
import SummaryHeader from "@/components/PurchaseRequest/SummarySections/SummaryHeader";
import BasicInformation from "@/components/PurchaseRequest/SummarySections/BasicInformation";
import PaymentAndDeliverySection from "@/components/PurchaseRequest/SummarySections/PaymentAndDeliverySection";
import {
  PURCHASE_REQUEST_MODULE,
  ADD_PURCHASE_REQUEST,
  RESET_CURRENT_REQUEST,
  SET_CURRENT_PAGE,
} from "@/store/PurchaseRequest/types.js";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { HivebuyLoader } from "@/components/shared";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    PurchaseRequestTitle,
    TotalPrice,
    Products,
    SummaryHeader,
    BasicInformation,
    PaymentAndDeliverySection,
    HivebuyLoader,
  },
  provide() {
    return {
      confirmItemStatus: this.confirmItemStatus,
    };
  },
  data() {
    return {
      showTitleForm: false,
      loading: true,
      requestLoading: false,
    };
  },

  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companyCurrency"]),
    isItemLevelApprovalAllowed() {
      return this.currentRequest?.isItemLevelApprover;
    },
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
  },
  watch: {
    currentRequest: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.items.length && oldVal.items.length) {
          const newItems = newVal.items.map((item) => item.id);
          const oldItems = oldVal.items.map((item) => item.id);
          if (JSON.stringify(newItems) !== JSON.stringify(oldItems)) {
            this.createPurchaseRequest();
          }
        }
      },
    },
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal?.name == "PurchaseRequestEdit") {
          this.resetForm();
        }
      },
    },
  },
  mounted() {
    if (!this.isEditRoute) {
      this.createPurchaseRequest();
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      savePurchaseRequest: ADD_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetForm: RESET_CURRENT_REQUEST,
      setCurrentPage: SET_CURRENT_PAGE,
    }),
    createPurOrToggleTitleModal() {
      if (this.currentRequest.items.length > 1) {
        this.showTitleForm = !this.showTitleForm;
      } else {
        this.createPurchaseRequest(false);
      }
    },
    createPurchaseRequest(preview = true) {
      this.requestLoading = true;
      this.savePurchaseRequest(preview)
        .then((res) => {
          if (!preview) {
            const id = res.data ? res.data.id : res.id;
            this.saveActions(id);
          }
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
          this.requestLoading = false;
        });
    },
    saveActions(id) {
      this.resetForm();
      this.$router.push({
        name: "RequestDetail",
        params: { id },
      });
      this.showNotification(this.$t("toasts.genericCreationSuccess"));
    },
  },
};
</script>

<style scoped>
.btn-disabled {
  @apply opacity-50 cursor-not-allowed hover:text-gray-600 text-white;
}

.summary-content {
  @apply w-full flex flex-col;
}

.loader-holder {
  @apply flex justify-center items-center;
}
</style>
