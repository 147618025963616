<template>
  <ul class="list-outside list-none pl-0 group">
    <li
      v-if="conditions.amount && Object.keys(conditions.amount).length > 0"
      class="condition-box flex"
    >
      <div v-if="conditions.amount.relation === 'BW'" class="flex-grow">
        <span class="font-medium">
          <i18n-n
            :value="parseFloat(conditions.amount.minValue)"
            format="currency"
            :locale="
              currencyToLocale(getCurrencyForCard(conditions.amount.currency))
            "
          />
        </span>
        <span class="workflowItem">{{ `<` }}</span>
        <span class="text-gray-700">{{
          conditionAmountToText(conditions.amount, $t).condition
        }}</span>
        <span class="workflowItem">{{ `<` }}</span>
        <span class="font-medium">
          <i18n-n
            :value="parseFloat(conditions.amount.maxValue)"
            format="currency"
            :locale="
              currencyToLocale(getCurrencyForCard(conditions.amount.currency))
            "
        /></span>
      </div>
      <div v-else>
        <div class="flex space-x-2 items-center">
          <span class="text-gray-700">{{
            conditionAmountToText(conditions.amount, $t).condition
          }}</span
          ><span class="workflowItem">{{
            conditionAmountToText(conditions.amount, $t).relation
          }}</span>
          <span class="font-medium">
            <i18n-n
              :value="parseFloat(conditions.amount.value)"
              format="currency"
              :locale="
                currencyToLocale(getCurrencyForCard(conditions.amount.currency))
              "
          /></span>
        </div>
      </div>
      <div class="">
        <EditorActions
          :allow-actions="allowActions"
          :on-delete="onDelete"
          :on-edit="onEdit"
          :type="'conditions'"
          :category="'amount'"
        />
      </div>
    </li>

    <li v-if="isDeviation" class="condition-box flex">
      <div class="flex items-center space-x-2">
        <span class="text-gray-700"
          >{{
            $t(
              `invoice.deviation.${
                deviationCategory == "deviation" ? "percentage" : "abs"
              }`
            )
          }}
        </span>
        <span class="workflowItem">
          {{ conditionAmountToText(deviationObject, $t).relation }}
        </span>

        <span v-if="deviationCategory == 'deviation'" class="font-medium"
          >{{ deviationObject.value }} %
        </span>
        <span v-else class="font-medium">
          <i18n-n
            :value="parseFloat(deviationObject.value)"
            format="currency"
            :locale="
              currencyToLocale(getCurrencyForCard(deviationObject.value))
            "
        /></span>
      </div>
      <div>
        <EditorActions
          :allow-actions="allowActions"
          :on-delete="onDelete"
          :on-edit="onEdit"
          :type="'conditions'"
          :category="deviationCategory"
        />
      </div>
    </li>

    <li
      v-if="conditions.department && conditions.department.length"
      class="condition-box"
    >
      <div class="flex justify-between">
        <p class="title">{{ $t("global.department") }}</p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'department'"
          />
        </div>
      </div>
      <span
        v-for="(department, index) in conditions.department"
        :key="department.id"
      >
        <template v-if="index > 0">
          <p class="text-center text-xs font-medium">
            {{ $t("global.prepositions.or") }}
          </p>
        </template>
        <p class="workflowItem text-center">
          <Department :key="department.id" :department="department.id" />
        </p>
      </span>
    </li>
    <li
      v-if="conditions.category && conditions.category.length"
      class="condition-box"
    >
      <div class="flex justify-between">
        <p class="title">{{ $t("global.category") }}</p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'category'"
          />
        </div>
      </div>
      <span v-for="(category, index) in conditions.category" :key="category.id">
        <template v-if="index > 0">
          <p class="text-center text-xs font-medium">
            {{ $t("global.prepositions.or") }}
          </p>
        </template>
        <p class="workflowItem text-center">
          {{ category?.name || category.label }}
        </p>
      </span>
    </li>
    <li
      v-if="conditions?.project?.length || conditions.isProject"
      class="condition-box"
    >
      <div class="flex justify-between">
        <p class="title">{{ $t("global.project") }}</p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'project'"
          />
        </div>
      </div>
      <span v-if="conditions.isProject">
        <p class="workflowItem text-center">any project</p>
      </span>
      <span
        v-for="(project, index) in conditions.project"
        v-else
        :key="project.id"
      >
        <template v-if="index > 0">
          <p class="text-center text-xs font-medium">
            {{ $t("global.prepositions.or") }}
          </p>
        </template>
        <p class="workflowItem text-center">
          {{ project.name || projectName(project) }}
        </p>
      </span>
    </li>

    <li
      v-if="conditions?.supplier?.length || conditions.unlistedSupplier"
      class="condition-box"
    >
      <div class="flex justify-between">
        <p class="title">{{ $t("global.supplier") }}</p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'supplier'"
          />
        </div>
      </div>
      <span v-if="conditions.unlistedSupplier">
        <p class="workflowItem text-center">
          {{ $t("global.unlistedSupplier") }}
        </p>
      </span>
      <span
        v-for="(supplier, index) in conditions.supplier"
        v-else
        :key="supplier.id"
      >
        <template v-if="index > 0">
          <p class="text-center text-xs font-medium">
            {{ $t("global.prepositions.or") }}
          </p>
        </template>
        <p class="workflowItem text-center">
          {{ supplier.name }}
        </p>
      </span>
    </li>
    <li
      v-if="conditions.legalEntity && conditions.legalEntity.length"
      class="condition-box"
    >
      <div class="flex justify-between">
        <p class="title">{{ $t("global.legalEntity") }}</p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'legalEntity'"
          />
        </div>
      </div>
      <span
        v-for="(legalEntity, index) in conditions.legalEntity"
        :key="legalEntity.id"
      >
        <template v-if="index > 0">
          <p class="text-center text-xs font-medium">
            {{ $t("global.prepositions.or") }}
          </p>
        </template>
        <p class="workflowItem text-center">
          <LegalTendency
            :key="legalEntity.id"
            :legal-tendency="legalEntity.id"
          />
        </p>
      </span>
    </li>
    <li v-if="conditions.budgetOwnerOfDepartment" class="condition-box">
      <div class="flex justify-between">
        <p class="title">
          {{ $t("workflow.editor.budgetOwnerOfDepartmentConditionLabel") }}
        </p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'budgetOwnerOfDepartment'"
          />
        </div>
      </div>
      <span>
        <p class="workflowItem text-center">
          {{ $t("workflow.editor.budgetOwnerOfDepartmentConditionLabel") }}
        </p>
      </span>
    </li>
    <li v-if="conditions.approverOfDepartment" class="condition-box">
      <div class="flex justify-between">
        <p class="title">
          {{ $t("workflow.editor.approverOfDepartmentConditionLabel") }}
        </p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'approverOfDepartment'"
          />
        </div>
      </div>
      <span>
        <p class="workflowItem text-center">
          {{ $t("workflow.editor.approverOfDepartmentConditionLabel") }}
        </p>
      </span>
    </li>
    <li v-if="conditions.requestType" class="condition-box">
      <div class="flex justify-between">
        <p class="title">
          {{ $t("workflow.editor.requestTypeConditionLabel") }}
        </p>
        <div class="content-center">
          <EditorActions
            :allow-actions="allowActions"
            :on-delete="onDelete"
            :on-edit="onEdit"
            :type="'conditions'"
            :category="'requestType'"
          />
        </div>
      </div>
      <p class="workflowItem text-center">
        {{ requestTypeLabel }}
      </p>
    </li>
  </ul>
</template>
<script>
import {
  conditionAmountToText,
  requestTypeOptions,
} from "@/utils/helpers/workflowHelper.js";
import { currencyToLocale, isValuePresent } from "@/utils/utility_methods.js";
import EditorActions from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/actions.vue";
import { Department } from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState, mapGetters } from "vuex";
import LegalTendency from "@/components/shared/LegalTendency";
import { PROJECT_MODULE } from "@/store/Project/types";

export default {
  components: { LegalTendency, EditorActions, Department },

  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    conditions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(PROJECT_MODULE, ["projectName"]),
    conditionsComputedObject() {
      const { amount, deviation } = this.conditions;
      return Object.keys(amount).length > 0 ? amount : deviation;
    },
    isDeviation() {
      const { deviation, amountDeviation } = this.conditions;
      return (
        (amountDeviation && !!Object.keys(amountDeviation).length) ||
        (deviation && !!Object.keys(deviation).length)
      );
    },
    deviationCategory() {
      const { amountDeviation } = this.conditions;
      return amountDeviation && Object.keys(amountDeviation).length
        ? "amountDeviation"
        : "deviation";
    },
    deviationObject() {
      const { deviation, amountDeviation } = this.conditions;
      return amountDeviation && Object.keys(amountDeviation).length
        ? amountDeviation
        : deviation;
    },
    showAmountSection() {
      const { amount, deviation } = this.conditions;
      return (
        Object.keys(amount).length > 0 || Object.keys(deviation).length > 0
      );
    },
    requestTypeLabel() {
      return requestTypeOptions(this.$t).find(
        (option) => option.value === this.conditions.requestType
      ).label;
    },
  },
  methods: {
    isValuePresent,
    conditionAmountToText,
    currencyToLocale,
    getCurrencyForCard(currency) {
      return currency || this.company.currency;
    },
  },
};
</script>

<style scoped>
.condition-box {
  @apply text-gray-700  mb-4 text-base justify-between;
}
.condition-box .title {
  @apply text-gray-700 text-base mb-2 font-semibold;
}
</style>
