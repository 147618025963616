import { toBase64 } from "@/utils/utility_methods";
export default {
  methods: {
    async formatObjects(files, key = "file") {
      return await Promise.all(
        files.map(async (file) => {
          if (file?.id || file?.title) return file;
          const newFile = await toBase64(file);
          return { title: file.name, [key]: newFile };
        })
      );
    },
    async addFilesToObject(files, key = "file", objectToUpdate = "details") {
      const uploadedFiles = await this.formatObjects(files, key);
      this[objectToUpdate][`${key}s`] = uploadedFiles;
    },
  },
};
