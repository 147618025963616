<template>
  <div class="py-1 px-2">
    <div class="flex justify-center text-base text-primary font-semibold">
      {{ $t("shoppingList.purItems") }}
    </div>
    <ul class="product-list divide-y divide-gray-200" role="list">
      <li
        v-for="prItem in items"
        :key="prItem.position"
        class="product relative py-2"
      >
        <div class="product-description">
          <div class="product-name flex justify-between text-sm w-full">
            <h4 class="!text-sm flex items-center">
              {{ prItem.name }}
            </h4>

            <div
              v-if="prItem?.shoppingItems?.includes(item.id)"
              class="flex justify-end"
              @click="() => unassignItem(prItem.position)"
            >
              <div
                class="h-6 rounded px-2 py-0.5 text-sm font-semibold text-hivebuy-red border border-hivebuy-red hover:text-white hover:bg-hivebuy-red transition-all cursor-pointer group"
              >
                <ScissorsIcon class="h-4 w-4" />
              </div>
            </div>
            <div v-else class="flex justify-end">
              <div
                class="h-6 rounded px-2 py-0.5 text-sm font-semibold text-hivebuy_turquoise border border-hivebuy-green hover:text-white hover:bg-hivebuy-green transition-all cursor-pointer"
                @click="() => assignItem(prItem.position)"
              >
                <LinkIcon class="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {
  PURCHASE_REQUEST_MODULE,
  ASSIGN_SHOPPING_LIST,
  UNASSIGN_SHOPPING_LIST,
  CHANGE_SHOPPING_LIST_RELATION,
} from "@/store/PurchaseRequest/types";
import { mapMutations, mapState, mapActions } from "vuex";
import { LinkIcon, ScissorsIcon } from "@heroicons/vue/solid";
export default {
  components: {
    LinkIcon,
    ScissorsIcon,
  },

  props: { item: { type: Object, required: true } },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, [
      "currentRequest",
      "openCurrentRequest",
    ]),
    isNewRequest() {
      return (
        !this.openCurrentRequest && Object.keys(this.currentRequest).length
      );
    },

    items() {
      const object = this.isNewRequest
        ? this.currentRequest
        : this.openCurrentRequest;
      return object.items;
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      assignShoppingList: ASSIGN_SHOPPING_LIST,
      unassignShoppingList: UNASSIGN_SHOPPING_LIST,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      changeShoppingListRelation: CHANGE_SHOPPING_LIST_RELATION,
    }),
    apiPayload(position) {
      const { id, shoppingList } = this.item;
      const index = this.openCurrentRequest.items.findIndex(
        (item) => item.position == position
      );
      return {
        purchaseRequestItem: this.openCurrentRequest.items[index].id,
        shoppingListItem: id,
        prId: this.openCurrentRequest,
        shoppingList,
      };
    },
    payload(position) {
      const { id, shoppingList } = this.item;
      return { position, shoppingListDetails: { id, shoppingList } };
    },
    assignItem(position) {
      if (this.isNewRequest) this.assignShoppingList(this.payload(position));
      else {
        this.callChangeRelationApi("add", position);
      }
    },
    unassignItem(position) {
      if (this.isNewRequest) this.unassignShoppingList(this.payload(position));
      else {
        this.callChangeRelationApi("remove", position);
      }
    },
    callChangeRelationApi(action, position) {
      this.changeShoppingListRelation({
        method: action,
        payload: this.apiPayload(position),
      })
        .then(() => {
          this.showNotification(this.$t(`shoppingList.toasts.${action}`));
        })
        .catch((error) => this.showErrorMessage(error));
    },
    firstLetterOfProduct(name) {
      if (name) {
        return name.charAt(0);
      } else return "";
    },
  },
};
</script>
