<template>
  <Loading
    v-model:active="display"
    :is-full-page="fullPage"
    :color="LOADER_COLOR"
    :width="width"
    :height="height"
  />
</template>
<script>
import { LOADER_COLOR } from "@/utils/constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 48,
    },
    height: {
      type: Number,
      default: 48,
    },
  },
  data() {
    return {
      display: this.show,
    };
  },
  watch: {
    show: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.display = newVal;
        }
      },
    },
  },
  created() {
    this.LOADER_COLOR = LOADER_COLOR;
  },
};
</script>
