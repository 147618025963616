<template>
  <CardSection
    :loading="loading"
    :records="actionableInvoices"
    :title="$t('dashboard.headerMyInvoices')"
    :total-records-count="paginationParams?.totalRecords"
  >
    <template #col-tickets-content>
      <ul>
        <li v-for="invoice in actionableInvoices" :key="invoice.id">
          <div class="ticket invoice down">
            <router-link
              :to="{
                name: 'InvoiceDecision',
                params: {
                  invoiceId: invoice.id,
                },
              }"
            >
              <div class="ticket-header group cursor-pointer">
                <div class="ticket-header-left">
                  <div class="ticket-title">
                    {{ invoice.title }}
                  </div>
                  <div class="ticket-meta">
                    {{ invoice.invoiceNumber }}
                  </div>
                </div>
                <div
                  class="ticket-header-right group-hover:border-primary group-hover:bg-primary group-hover:text-white transition duration-300"
                >
                  <ChevronRightIcon class="w-4 h-4" />
                </div>
              </div>
            </router-link>
            <div class="ticket-content">
              <ul class="content-listing">
                <li>
                  <div class="lab">
                    {{ $t("global.dates.invoiceDate") }}
                  </div>
                  <div class="val truncate">
                    {{ formattedDate(invoice.invoiceDate) }}
                  </div>
                </li>
                <li>
                  <div class="lab">
                    {{ $t("global.dates.dueDate") }}
                  </div>
                  <div class="val truncate">
                    {{ formattedDate(invoice.dueDate) }}
                  </div>
                </li>
                <li>
                  <div class="lab">
                    {{ $t("global.supplier") }}
                  </div>
                  <div class="val truncate">{{ invoice.vendorName }}</div>
                </li>
                <li>
                  <div class="lab">
                    {{ $t("global.purchaseOrder") }}
                  </div>
                  <div class="val truncate !py-0">
                    <SquashedList
                      v-if="invoice.invoicePurchaseOrders.length"
                      :list="ipoList(invoice)"
                      display-attribute="title"
                      type="po"
                    />
                    <div v-else >-</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="ticket-footer">
              <div class="ticket-footer-left">
                <i18n-n
                  :value="parseFloat(invoice.totalNetAmount)"
                  format="currency"
                  :locale="currencyToLocale(invoice.totalAmountCurrency)"
                />
                <p class="text-gray-500">
                  <small>
                    <i18n-n
                      :value="parseFloat(invoice.totalGrossAmount)"
                      format="currency"
                      :locale="
                        currencyToLocale(invoice.totalGrossAmountCurrency)
                      "
                    />
                  </small>
                </p>
              </div>
              <div class="ticket-footer-right">
                <div
                  v-if="invoice?.invoicePurchaseOrders?.length == 1"
                  class="button respond-to group"
                >
                  <i18n-n
                    v-if="!getPercentIndicator(invoice).over100"
                    :value="getPercentIndicator(invoice).value"
                    format="percent"
                    :locale="currencyToLocale(company.currency)"
                  />
                  <div v-else>> 100 %</div>
                </div>

                <span
                  v-else-if="invoice?.invoicePurchaseOrders?.length > 1"
                  class="inline-flex items-center gap-x-1.5 rounded-md bg-purple-100 px-1.5 py-0.5 text-xs font-medium text-primarydark"
                >
                  {{ $t("global.invoice.multiplePo") }}
                </span>
              </div>
            </div>
          </div>
        </li>

        <li v-if="paginationParams?.page < paginationParams?.totalPages">
          <LoadMoreSection
            :load-more="loadMore"
            :load-more-btn-loading="loadMoreBtnLoading"
          />
        </li>
      </ul>
    </template>
  </CardSection>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { mapActions, mapState, mapMutations } from "vuex";
import CardSection from "@/components/Dashboard/AdvanceUserDashboard/CardSection";
import { currencyToLocale, differenceIndicator } from "@/utils/utility_methods";
import {
  ACTIONABLE_INVOICES,
  INVOICE_MODULE,
  SET_ACTIONABLE_INVOICES_FILTERS,
} from "@/store/Invoice/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { LoadMoreSection } from "@/components/shared";
import loadMoreFilterMixin from "@/components/mixins/loadMoreFiltersMixin";
import { SquashedList } from "@/components/shared";
export default {
  name: "ActionableInvoices",
  components: {
    ChevronRightIcon,
    CardSection,
    LoadMoreSection,
    SquashedList,
  },
  mixins: [loadMoreFilterMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, {
      actionableInvoices: (state) => state.invoices.dashboard,
      paginationParams: (state) => state.actionableInvoices.paginationParams,
    }),

    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  mounted() {
    this?.actionableInvoices.length < 8 && this.fetchActionRequiredInvoices();
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getActionableInvoices: ACTIONABLE_INVOICES,
    }),
    ...mapMutations(INVOICE_MODULE, {
      setActionableInvoiceFilters: SET_ACTIONABLE_INVOICES_FILTERS,
    }),
    ipoList(invoice) {
      return invoice.invoicePurchaseOrders.map(
        (ipo) => ipo.purchaseOrder.purchaseRequest
      );
    },
    getPercentIndicator(invoice) {
      return differenceIndicator(
        invoice.totalNetAmount,
        invoice.invoicePurchaseOrders[0].purchaseOrder.netAmount,
        0.05
      );
    },
    fetchActionRequiredInvoices() {
      if (this.paginationParams.page == 1) this.loading = true;
      const paginationParams = this.paginationParams;
      this.getActionableInvoices(paginationParams)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
          this.loadMoreBtnLoading = false;
        });
    },
    currencyToLocale,

    updateFilters(key, value) {
      const payload = Object.assign({}, this.paginationParams);
      payload[key] = value;
      this.setActionableInvoiceFilters({ paginationParams: payload });

      if (key === "page") {
        this.fetchActionRequiredInvoices();
      }
    },
  },
};
</script>

<style scoped>
.ticket.invoice.down .ticket-header::before {
  @apply bg-hivebuy-green;
}

.ticket.invoice .ticket-footer .status {
  @apply text-white rounded-full font-semibold text-xs px-10 py-1;
}

.ticket.invoice.up .ticket-footer .status {
  @apply bg-hivebuy-green bg-opacity-75;
}

.ticket.invoice.down .ticket-footer .status {
  @apply bg-hivebuy-red bg-opacity-75;
}

.ticket.invoice .ticket-content .content-listing li {
  @apply flex justify-between border-b border-gray-200;
}

.ticket.invoice .ticket-content .content-listing li.heading div {
  @apply text-sm text-gray-500 px-1 py-1  font-semibold;
}

.ticket.invoice .ticket-content .content-listing li div {
  @apply flex  text-xs text-gray-500 px-1 py-2;
}
.ticket.invoice.up .ticket-header::before {
  @apply bg-hivebuy-green;
}
.ticket-footer .ticket-footer-right .button {
  @apply rounded-full py-1 px-4 justify-center text-primary font-semibold text-xs flex items-center cursor-pointer border border-primary/20 hover:border-primary bg-white;
}
</style>
