<template>
  <div id="request_detailed_list" class="custom-card">
    <div class="custom-card-header">
      <h2 class="card-title">{{ $t("charts.requestDetailedList.heading") }}</h2>
      <Description :description="$t('charts.requestDetailedList.desc')" />
    </div>

    <div class="custom-card">
      <div class="-m-5">
        <table class="table overflow-visible">
          <thead class="table-header">
            <tr>
              <th
                v-for="(columnName, index) in tableColumns()"
                :key="index"
                scope="col"
              >
                <div class="flex items-center max-w-xs truncate">
                  <span class="pr-2 truncate">
                    {{ columnName }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              v-for="purchaseRequest in purchaseRequests"
              :key="purchaseRequest.id"
              class="group"
            >
              <td class="relative">
                <div
                  class="absolute inset-y-0 right-2 items-center group-hover:px-3 hidden group-hover:flex"
                >
                  <div
                    class="rounded-full hover:bg-gray-500/20 bg-gray-200/20 backdrop-blur-sm w-8 h-8 flex items-center align-middle justify-center hover:text-primary text-gray-400"
                    @click="() => navigateToDetailPage(purchaseRequest)"
                  >
                    <ChevronRightIcon class="w-5 h-5 cursor-pointer" />
                  </div>
                </div>
                <div
                  class="truncate lg:max-w-[15rem] xl:max-w-[18rem] 2xl:max-w-none"
                >
                  <span class="title group-hover:text-primary truncate">
                    {{ purchaseRequest.title }}
                  </span>
                  <p class="sub-title">
                    {{ purchaseRequest.externalId }}
                  </p>
                </div>
              </td>
              <td>
                <div
                  class="text-left truncate lg:max-w-[10rem] xl:max-w-[14rem] 2xl:max-w-[22rem]"
                >
                  <Category
                    :purchase-request="purchaseRequest"
                    :squashed="true"
                  />
                </div>
                <p class="text-gray-400 text-xs 2xl:text-sm text-left">
                  <SupplierName
                    :purchase-request="purchaseRequest"
                    :size="'small'"
                    :disable-supplier-badge-tooltip="true"
                    :squashed="true"
                  />
                </p>
              </td>
              <td>
                <span class="text-sm">
                  {{ formattedDate(purchaseRequest.requestedAt) }}
                </span>
              </td>
              <td>
                <StatusTag
                  :status="requestStatus(purchaseRequest)"
                  :type="purchaseRequest?.purchaseOrder ? 'order' : 'request'"
                  :size="'small'"
                />
              </td>
              <td>
                <div class="flex">
                  <div
                    v-for="(item, index) in purchaseRequest.items"
                    :key="item.id"
                    class="flex -ml-2 overflow-hidden"
                  >
                    <HivebuyTooltip :open-delay="50">
                      <span class="avatar">
                        {{ index + 1 }}
                      </span>
                      <template #content>
                        <div class="p-2">{{ item.name }}</div>
                      </template>
                    </HivebuyTooltip>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-primary">
                  <i18n-n
                    :value="parseFloat(purchaseRequest.totalNetAmount)"
                    format="currency"
                    :locale="currencyToLocale(company.currency)"
                  />
                </span>
              </td>
              <td>
                <InvoiceBudget
                  v-if="isPurchaseOrderPresent(purchaseRequest)"
                  :purchase-order="purchaseRequest.purchaseOrder"
                  :show-bar-only="true"
                />
                <span v-else>N/A</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  currencyToLocale,
  isObjectEmpty,
  requestStatus,
} from "@/utils/utility_methods";

import { ChevronRightIcon } from "@heroicons/vue/solid";

import { HivebuyTooltip, Category, SupplierName } from "@/components/shared";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import StatusTag from "@/components/shared/StatusTag";
import { Description } from "@/components/Analytics/charts";
import InvoiceBudget from "@/components/PurchaseRequest/SummarySections/InvoiceBudget";

export default {
  name: "RequestDetailedList",
  components: {
    InvoiceBudget,
    Category,
    StatusTag,
    HivebuyTooltip,
    SupplierName,
    ChevronRightIcon,
    Description,
  },
  props: {
    purchaseRequests: { type: Array, required: true },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  mounted() {
    document.getElementById("request_detailed_list").scrollIntoView({
      behavior: "smooth",
    });
  },
  methods: {
    requestStatus,
    currencyToLocale,
    tableColumns() {
      return [
        this.$t("purchaseRequest.list.table.title"),
        this.$t("global.category"),
        this.$t("purchaseRequest.list.table.date"),
        this.$t("purchaseRequest.list.table.status"),
        this.$t("purchaseRequest.list.table.items"),
        this.$t("purchaseRequest.list.table.price"),
        this.$t("purchaseRequest.list.table.invoicedAmount"),
      ];
    },
    navigateToDetailPage(purchaseRequest) {
      this.$router.push({
        name: "RequestDetail",
        params: { id: purchaseRequest.id },
      });
    },
    isPurchaseOrderPresent(purchaseRequest) {
      return !isObjectEmpty(purchaseRequest?.purchaseOrder);
    },
  },
};
</script>

<style scoped>
.custom-table tr th:nth-child(2) {
  width: 160px;
}

.custom-table tr th:nth-child(3),
.custom-table tr th:nth-child(4),
.custom-table tr th:nth-child(5) {
  width: 140px;
}
.custom-table tr th:nth-child(6) {
  width: 160px;
}
.custom-table tr th:nth-child(7) {
  width: 110px;
}

.custom-table tr th:nth-child(7) {
  width: 110px;
}
.avatar {
  @apply inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary cursor-pointer border-white border-2 text-sm text-white font-semibold;
}
</style>
