import { allowedForUser } from "@/utils/helpers/permissionHelper.js";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import ShoppinglistSVG from "@/assets/images/ShoppinglistSVG.vue";
import WebhookIntegrationSVG from "@/assets/images/WebhookIntegrationSVG.vue";
import PurchaseRequestSVG from "@/assets/images/PurchaseRequestSVG.vue";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import OrderListSVG from "@/assets/images/OrderListSVG.vue";
import {
  AdjustmentsIcon,
  ChartPieIcon,
  ClipboardListIcon,
  CurrencyEuroIcon,
  CursorClickIcon,
  DocumentIcon,
  DocumentReportIcon,
  FastForwardIcon,
  GlobeAltIcon,
  HomeIcon,
  LightningBoltIcon,
  OfficeBuildingIcon,
  PlusIcon,
  ScaleIcon,
  SearchIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  StarIcon,
  TableIcon,
  TemplateIcon,
  TrendingUpIcon,
  UserGroupIcon,
  UserIcon,
  ViewBoardsIcon,
  DocumentTextIcon,
  LibraryIcon,
  TagIcon,
  NewspaperIcon,
  DownloadIcon,
} from "@heroicons/vue/outline";
import { mapGetters, mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";
export default {
  components: {
    ShoppingBagIcon,
    DocumentReportIcon,
    HomeIcon,
    ScaleIcon,
    UserGroupIcon,
    ShoppingCartIcon,
    TableIcon,
    AdjustmentsIcon,
    ChartPieIcon,
    CurrencyEuroIcon,
    PlusIcon,
    FastForwardIcon,
    OfficeBuildingIcon,
    UserIcon,
    TemplateIcon,
    TrendingUpIcon,
    ViewBoardsIcon,
    WebhookIntegrationSVG,
    CursorClickIcon,
    GlobeAltIcon,
    SearchIcon,
    DocumentIcon,
    ClipboardListIcon,
    LightningBoltIcon,
    StarIcon,
    ProjectSVG,
    DocumentTextIcon,
    ShoppinglistSVG,
    LibraryIcon,
    PurchaseRequestSVG,
    TagIcon,
    InvoiceSVG,
    NewspaperIcon,
    DownloadIcon,
    OrderListSVG,
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList"]),
    ...mapState(AUTH_MODULE, ["user"]),
  },
  methods: {
    sidebarItems() {
      const items = [
        {
          name: this.$t("home.dashboard"),
          component: "Dashboard",
          userAllowed: allowedForUser("dashboard"),
          include: true,
          icon: "HomeIcon",
        },
        {
          name: this.$t("home.requests"),
          component: "Requests",
          userAllowed: allowedForUser("requests"),
          include: true,
          icon: "PurchaseRequestSVG",
        },
        {
          name: this.$t("home.orders"),
          component: "OrderList",
          userAllowed: allowedForUser("order-list"),
          include: this.isFeatureAllowed("autoOrderPortal"),
          icon: "OrderListSVG",
          beta: true,
        },
        {
          name: this.$t("home.catalogue"),
          component: "Catalogue",
          userAllowed: allowedForUser("catalogue"),
          include: true,
          icon: "ShoppingBagIcon",
          count: this.countItemsInCart,
        },
        {
          name: this.$t("home.news"),
          component: "News",
          userAllowed: allowedForUser("news"),
          include: this.isFeatureAllowed("news"),
          icon: "NewspaperIcon",
        },
        {
          name: this.$t("home.invoices"),
          component: "InvoiceDashboard",
          userAllowed: allowedForUser("invoice-approval"),
          include:
            this.isFeatureAllowed("isActiveWithoutPackages") ||
            this.isFeatureAllowed("invoiceApproval"),
          icon: "InvoiceSVG",
          beta: false,
        },
        {
          name: this.$t("home.purchaseOrder"),
          component: "Accounting",
          userAllowed: allowedForUser("orders"),
          include: true,
          icon: "CurrencyEuroIcon",
        },
        {
          name: this.$t("home.contracts"),
          component: "Contracts",
          userAllowed: allowedForUser("contracts"),
          include:
            this.isFeatureAllowed("isActiveWithoutPackages") ||
            this.isFeatureAllowed("contracts"),
          icon: "DocumentTextIcon",
          beta: false,
        },
        {
          name: this.$t("home.proposals"),
          component: "Proposals",
          userAllowed: allowedForUser("proposals"),
          include:
            this.isFeatureAllowed("isActiveWithoutPackages") ||
            this.isFeatureAllowed("polls"),
          icon: "DocumentIcon",
          beta: false,
        },

        {
          name: this.$t("home.shoppingList"),
          component: "ShoppingList",
          userAllowed: allowedForUser("shopping-list"),
          include:
            this.isFeatureAllowed("isActiveWithoutPackages") ||
            this.isFeatureAllowed("shoppingList"),
          icon: "ShoppinglistSVG",
          beta: false,
        },
        {
          name: this.$t("home.projects"),
          component: "Projects",
          userAllowed: allowedForUser("projects"),
          include:
            this.isFeatureAllowed("isActiveWithoutPackages") ||
            this.isFeatureAllowed("projects"),
          icon: "ProjectSVG",
          beta: false,
        },
        {
          name: this.$t("home.downloads"),
          component: "Downloads",
          userAllowed: allowedForUser("downloads"),
          include: true,
          icon: "DownloadIcon",
          beta: false,
        },
        {
          name: this.$t("home.analytics"),
          userAllowed: allowedForUser("analytics"),
          include: true,
          icon: "ChartPieIcon",
          children: [
            {
              name: this.$t("home.basicAnalytics"),
              component: "DetailedAnalytics",
              userAllowed: allowedForUser("analytics-basic-overview"),
              include: true,
              icon: "SearchIcon",
            },
            {
              name: this.$t("home.departmentBudgets"),
              component: "DepartmentBudgetOverview",
              userAllowed: allowedForUser("analytics-departments"),
              include: this.isFeatureAllowed("budget"),
              icon: "ChartPieIcon",
            },
          ],
        },
        {
          name: this.$t("home.companyManagement"),
          icon: "OfficeBuildingIcon",
          userAllowed: allowedForUser("company-management"),
          include: true,
          children: [
            {
              name: this.$t("home.profile"),
              component: "Settings",
              userAllowed: allowedForUser("company-profile"),
              include: true,
              icon: "UserIcon",
            },
            {
              name: this.$t("home.users"),
              component: "Users",
              userAllowed: allowedForUser("users"),
              include: true,
              icon: "UserGroupIcon",
            },
            {
              name: this.$t("home.departments"),
              component: "Departments",
              userAllowed: allowedForUser("departments"),
              include: true,
              icon: "TemplateIcon",
            },
            {
              name: this.$tc("global.legalEntity", 2),
              component: "LegalTendencies",
              userAllowed: allowedForUser("legal-tendencies"),
              include:
                this.isFeatureAllowed("isActiveWithoutPackages") ||
                this.isFeatureAllowed("legalEntity"),
              icon: "LibraryIcon",
              beta: false,
            },
            {
              name: this.$t("home.budgets"),
              component: "Budgets",
              userAllowed: allowedForUser("budgets"),
              include: this.isFeatureAllowed("budget"),
              icon: "TrendingUpIcon",
            },
            {
              name: this.$t("home.categories"),
              component: "Categories",
              userAllowed: allowedForUser("categories"),
              include: true,
              icon: "TagIcon",
            },
            {
              name: this.$t("home.companySuppliers"),
              component: "CompanySuppliers",
              userAllowed: allowedForUser("company-suppliers"),
              include: true,
              icon: "GlobeAltIcon",
            },
            {
              name: this.$t("home.connectedSuppliers"),
              component: "ConnectedSuppliers",
              userAllowed: allowedForUser("connected-suppliers"),
              include: true,
              icon: "LightningBoltIcon",
            },
            {
              name: this.$t("home.integrations"),
              component: "Integrations",
              userAllowed: allowedForUser("integrations"),
              include: true,
              icon: "WebhookIntegrationSVG",
            },
            {
              name: this.$t("home.approvalWorkflow"),
              component: "Approval Workflow",
              userAllowed: allowedForUser("approval-workflow"),
              include: true,
              icon: "CursorClickIcon",
            },
          ],
        },
      ];
      return this.userAllowedItems(items);
    },
    userAllowedItems(items) {
      const navItems = items.map((item) => {
        if (item.children) {
          item.children = item.children.filter((navItem) =>
            this.isRouteAllowed(navItem)
          );
        }
        return item;
      });

      return navItems.filter((item) => this.isRouteAllowed(item));
    },
    isRouteAllowed(navItem) {
      if (
        navItem.name === this.$t("home.departmentBudgets") &&
        this.departmentsList &&
        this.user.roles.length &&
        this.isFeatureAllowed("budget")
      ) {
        const isUserDepartmentApprover =
          this.user.roles.includes("AP") &&
          this.departmentsList().some((department) =>
            department.approvers.includes(this.user.id)
          );
        return (
          (navItem.userAllowed && navItem.include) || isUserDepartmentApprover
        );
      } else if (navItem.name === this.$t("home.analytics")) {
        return !!navItem.children.length;
      } else {
        return navItem.userAllowed && navItem.include;
      }
    },
  },
};
