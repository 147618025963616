<template>
  <EntityDisplay
    v-if="product?.requestedFor"
    :entity="product.requestedFor"
    :read-only="readOnly"
    :text-classes="textClasses"
    :icon-classes="iconClasses"
    :avatar-dimension="avatarDimension"
    :display-requested-for="displayRequestedFor"
    :show-name="showName"
  />
  <span v-else>-</span>
</template>
<script>
import EntityDisplay from "@/components/shared/EntityDisplay.vue";
export default {
  components: { EntityDisplay },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    product: { type: Object, required: true },
    textClasses: {
      type: Array,
      required: false,
      default: () => ["text-sm font-medium text-gray-600"],
    },
    iconClasses: {
      type: Array,
      required: false,
      default: () => ["text-gray-500 h-6 w-6"],
    },
    avatarDimension: {
      type: Number,
      default: 7,
    },
    displayRequestedFor: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
