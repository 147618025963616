<template>
  <Modal
    :show-icon="false"
    :show-close="showHeader"
    :prevent-click-outside="!showHeader"
    :show-modal="showModal"
    :close-modal="onModalClose"
  >
    <template v-if="showHeader" #header>
      <div class="flex items-center justify-center">
        <span>{{ $t("ApiKeys.form.title") }}</span>
      </div>
    </template>
    <template #body>
      <APIForm :on-modal-close="onModalClose" :toggle-header="toggleHeader" />
    </template>
  </Modal>
</template>

<script>
import { Modal } from "@/components/shared/index";
import APIForm from "@/components/ApiKeys/Form.vue";
export default {
  components: {
    Modal,
    APIForm,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onModalClose: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    showHeader: true,
  }),
  methods: {
    toggleHeader(value) {
      this.showHeader = value;
    },
  },
};
</script>

<style></style>
