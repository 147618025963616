<template>
  <div class="relative" :class="['custom-card h-full z-50', { '': !inPopup }]">
    <div v-if="budget">
      <div class="flex justify-between items-center">
        <span class="text-base font-semibold text-primary">{{
          budget.departmentName
        }}</span>
        <div
          v-if="!totalIsZero"
          class="rounded-xl py-1 px-2"
          :class="statusColor.text"
        >
          <span class="font-bold text-base md:text-md">
            <div v-if="calcPercentage() > 1">> 100%</div>
            <i18n-n
              v-else
              :value="calcPercentage()"
              format="percent0"
              :locale="currencyToLocale(company.currency)"
            />
          </span>
        </div>
      </div>
      <div
        class="text-lg md:text-xl pt-2 text-gray-700 font-semibold select-all"
      >
        <span :class="!totalIsZero ? statusColor.text : 'text-gray-600'">
          <i18n-n
            :value="budget.committedBudget + budget.usedBudget"
            format="budget"
            :locale="currencyToLocale(company.currency)"
        /></span>
        <span class="text-sm mx-1 lowercase"
          >{{ $t("charts.budgets.of") }} {{ $t("charts.budgets.total") }}</span
        >
        <i18n-n
          :value="budget?.totalBudget"
          format="budget"
          :locale="currencyToLocale(company.currency)"
        />
      </div>
      <div id="main-bar">
        <BudgetBar
          :series="formDepartmentData"
          :tooltip="false"
          :title="budget.departmentName"
          :total-is-zero="totalIsZero"
        />
      </div>
      <div
        v-if="withDetails && !totalIsZero"
        v-auto-animate
        class="text-center text-sm font-normal text-hivebuy_darkplum"
      >
        <span
          class="cursor-pointer select-none py-1 px-2 text-sm hover:border border-gray-300 rounded hover:bg-gray-100"
          @click="showDetails = !showDetails"
          >{{
            !showDetails
              ? $t("charts.budgets.showCategories")
              : $t("charts.budgets.hideCategories")
          }}</span
        >

        <div v-if="showDetails" class="mt-2">
          <div v-for="category in budget.categories" :key="category.id">
            <BudgetBar
              :series="formSeriesData(category)"
              :show-legend="true"
              :show-animations="false"
              :title="category.categoryName"
              :show-row-label="true"
              :height="100"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="loading"
      class="flex items-center place-content-center h-24 text-hivebuy_plum"
    >
      {{ $t("charts.budgets.prepareData") }}
      <SpinningLoaderSVG class="ml-2 h-6 w-6" />
    </div>
    <div v-else class="flex items-center place-content-center">
      {{ $t("charts.budgets.noDepartment") }}
    </div>
    <div
      v-if="loading"
      class="absolute inset-0 bg-gray-200/40 rounded-lg place-content-center flex items-center text-hivebuy_plum"
    >
      <SpinningLoaderSVG class="h-10 w-10" />
    </div>
    <HivebuyTooltip :open-delay="600" :disabled="!withDetails">
      <div
        v-if="!loading"
        class="absolute bottom-2 left-2 h-5 w-5 text-gray-400 hover:text-hivebuy_plum"
        :class="loading ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="updateBudget"
      >
        <RefreshIcon :class="loading ? 'animate-spin' : ''" />
      </div>
      <template #content>{{ $t("charts.budgets.refresh") }}</template>
    </HivebuyTooltip>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import {
  DASHBOARD_MODULE,
  GET_DEPARTMENT_BUDGET_ANALYTICS,
  GET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS,
} from "@/store/Dashboard/types";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import { mapActions, mapState } from "vuex";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import BudgetBar from "@/components/Analytics/Departments/BudgetBar.vue";
import { currencyToLocale } from "@/utils/utility_methods.js";
import { RefreshIcon } from "@heroicons/vue/solid";
import { HivebuyTooltip } from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    CollapseTransition,
    BudgetBar,
    RefreshIcon,
    HivebuyTooltip,
    SpinningLoaderSVG,
  },
  props: {
    department: {
      type: Object,
      default: null,
    },
    withDetails: {
      type: Boolean,
      default: true,
    },
    departmentId: {
      type: String,
      default: null,
    },
    inPopup: {
      type: Boolean,
      default: false,
    },
    year: {
      type: String,
      default: "2023",
    },
  },

  data() {
    return {
      showDetails: false,
      indicatorThreshold: 0.25,
      loading: false,
      budget: null,
    };
  },
  computed: {
    ...mapState(DASHBOARD_MODULE, {
      departments: (state) => state.analytics.budgets.departments,
    }),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    totalIsZero() {
      if (this.department) {
        const { totalBudget, usedBudget } = this.department;
        return [totalBudget, usedBudget].every((budget) => budget == 0);
      }
      return true;
    },
    adjustedApprovedData() {
      if (this.budget.usedBudget < 0) return 0;
      return this.budget.usedBudget;
    },
    adjustedCommittedBudget() {
      if (this.budget.committedBudget < 0) return 0;
      return this.budget.committedBudget;
    },
    adjustedAvailableBudget() {
      if (this.budget.availableBudget < 0) return 0;
      return this.budget.availableBudget;
    },
    isBudgetDepleted() {
      if (this.adjustedAvailableBudget == 0) {
        return true;
      }
      return false;
    },
    statusColor() {
      if (this.isBudgetDepleted)
        return {
          text: "text-hivebuy-red",
          bg: "bg-hivebuy-red",
        };
      if (this.adjustedAvailableBudget > 0 && this.budget?.totalBudget > 0) {
        if (
          this.adjustedAvailableBudget / this.budget?.totalBudget <
          this.indicatorThreshold
        ) {
          return {
            text: "text-hivebuy-yellow",
            bg: "bg-hivebuy-yellow",
          };
        }
      }
      return "";
    },
    formDepartmentData() {
      return [
        {
          name: this.$t("charts.budgets.approved"),
          data: [parseInt(this.adjustedApprovedData)],
        },
        {
          name: this.$t("charts.budgets.pending"),
          data: [parseInt(this.adjustedCommittedBudget)],
        },
        {
          name: this.$t("charts.budgets.available"),
          data: [parseInt(this.adjustedAvailableBudget)],
        },
      ];
    },
  },
  mounted() {
    if (this.department) {
      this.budget = this.department;
    } else if (this.departmentId) {
      const departmentToUpdate = this.departments.find(
        (element) => element.departmentId === this.departmentId
      );
      if (departmentToUpdate) {
        this.budget = departmentToUpdate;
      } else {
        this.updateBudget();
      }
    }
  },
  methods: {
    currencyToLocale,
    ...mapActions(DASHBOARD_MODULE, {
      getDepartmentsBudgetAnalytics: GET_DEPARTMENT_BUDGET_ANALYTICS,
      getSingleDepartmentsBudgetAnalytics:
        GET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS,
    }),
    test() {},
    formSeriesData(data) {
      return [
        {
          name: this.$t("charts.budgets.approved"),
          data: [this.checkMinus(parseInt(data.usedBudget))],
        },
        {
          name: this.$t("charts.budgets.pending"),
          data: [this.checkMinus(parseInt(data.committedBudget))],
        },
        {
          name: this.$t("charts.budgets.available"),
          data: [this.checkMinus(parseInt(data.availableBudget))],
        },
      ];
    },
    calcPercentage() {
      if (this.budget?.totalBudget == 0) return 999;
      const response =
        (this.budget.committedBudget + this.budget.usedBudget) /
        this.budget?.totalBudget;
      return response;
    },
    checkMinus(value) {
      if (value < 0) return 0;
      else return value;
    },
    updateBudget() {
      if (!this.loading) {
        this.loading = true;
        let departmentId;
        if (this.departmentId) departmentId = this.departmentId;
        if (this.budget) {
          departmentId = this.budget.departmentId;
        }
        const params = {
          department_id: departmentId,
          year: this.year,
        };
        this.getSingleDepartmentsBudgetAnalytics(params)
          .then(() => {
            this.budget = this.departments.find(
              (element) => element.departmentId === this.departmentId
            );
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style></style>
