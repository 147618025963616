import * as ProposalTypes from "@/store/Proposal/types";
import { updateOrCreate } from "@/utils/utility_methods";

export default {
  [ProposalTypes.SET_PROPOSALS]: (state, { payload, areFiltersUpdated }) => {
    state.proposals = areFiltersUpdated
      ? payload
      : [...state.proposals, ...payload];
  },
  [ProposalTypes.SET_PROPOSALS_LIST_FILTERS]: (state, payload) => {
    state.proposalsListFilters = payload;
  },
  [ProposalTypes.SET_PROPOSAL]: (state, payload) => {
    state.proposals = updateOrCreate(state.proposals, payload);
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProposalTypes.UPDATE_VOTES]: (state, payload) => {
    // TODO: After Payload adjustments, update the likes/dislike of a specific suggestion
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProposalTypes.UPDATE_PROPOSAL_STATUS]: (state, payload) => {
    const { id, status, members } = payload;
    const index = state.proposals.findIndex((proposal) => {
      return proposal.id == id;
    });
    if (index >= 0) {
      if (status) {
        state.proposals[index].status = status;
      }
      if (members) {
        state.proposals[index].members = members;
      }
    }
  },
};
