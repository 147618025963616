export default {
  computed: {
    getPaymentIdFromProduct() {
      if (this.product?.paymentMethod) return this.product.paymentMethod;
      return null;
    },
    getPaymentIdFromPR() {
      const { items } = this.purchaseRequest || {};
      if (items?.length && items[0]?.paymentMethod) {
        return items[0].paymentMethod;
      }
      return null;
    },
    getPaymentMethodId() {
      return this.getPaymentIdFromPR || this.getPaymentIdFromProduct;
    },
  },
};
