<template>
  <div class="product-detail">
    <ChevronDownIcon
      class="arrow"
      :class="showDetails ? 'rotate-180' : ''"
      @click="
        () => {
          showDetails = !showDetails;
        }
      "
    />

    <CollapseTransition>
      <div v-show="showDetails" class="detail-box">
        <div v-if="showPartialDeliveredSection" class="mb-4">
          <SwitchCheckbox
            v-model="isPartialDelivered"
            :label="
              $t('purchaseRequest.summary.purchaseOrderStatus.partialDelivered')
            "
            :on-click-handler="onPartialDeliveredClick"
          />
          <CollapseTransition>
            <div v-show="isPartialDelivered">
              <div class="my-2">
                <Input
                  id="quantity"
                  type="number"
                  :value="deliveryDetails.quantity"
                  name="quantity"
                  :on-key-up="
                    (event) => (deliveryDetails.quantity = event.target.value)
                  "
                  :placeholder="$t('global.pricing.quantity')"
                  :label="
                    $t(
                      'purchaseRequest.summary.purchaseOrderStatus.partialDeliveredQuantity'
                    )
                  "
                />
              </div>
              <div class="my-2">
                <CurrencyInput
                  id="amount"
                  v-model.lazy="deliveryDetails.amount"
                  placeholder="0"
                  :label="
                    $t(
                      'purchaseRequest.summary.purchaseOrderStatus.partialDeliveredAmount'
                    )
                  "
                />
              </div>
              <FieldError
                v-if="partialDeliveredError"
                :text="partialDeliveredError"
                :is-error="false"
              />
            </div>
          </CollapseTransition>
        </div>
        <div
          v-if="action !== 'Deliver'"
          class="flex items-center justify-center py-2 px-4 md:px-8 bg-gray-50 rounded border border-gray-200 mb-2"
        >
          <SwitchCheckbox
            v-model="sameDetails"
            :label="
              $t(
                'purchaseRequest.summary.purchaseOrderStatus.samePriceAsRequest'
              )
            "
          />
        </div>
        <div v-if="sameDetails" class="flex">
          <div class="detail-left w-full truncate">
            <table class="table table--product-detail">
              <tbody class="table-body !bg-transparent">
                <tr v-if="showPrice">
                  <td>{{ $t("purchaseRequest.summary.priceNet") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(product.orderPriceNet)"
                      format="currency"
                      :locale="currencyToLocale(product.totalAmountCurrency)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("purchaseRequest.summary.quantity") }}</td>
                  <td>
                    {{ parseInt(product.quantity) }}
                  </td>
                </tr>
                <tr v-if="showPrice">
                  <td>{{ $t("purchaseRequest.summary.tax") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(product.tax)"
                      format="currency"
                      :locale="currencyToLocale(product.totalAmountCurrency)"
                    />
                  </td>
                </tr>
                <tr v-if="showPrice">
                  <td>{{ $t("purchaseRequest.summary.priceGross") }}</td>
                  <td>
                    <i18n-n
                      :value="parseFloat(product.orderPriceGross)"
                      format="currency"
                      :locale="currencyToLocale(product.totalAmountCurrency)"
                    />
                  </td>
                </tr>

                <tr v-if="showPrice">
                  <td>
                    <p class="font-bold">
                      {{ $t("purchaseRequest.summary.total") }}
                    </p>
                  </td>
                  <td>
                    <i18n-n
                      :value="
                        parseInt(product.quantity) *
                        parseFloat(product.orderPriceGross)
                      "
                      format="currency"
                      :locale="currencyToLocale(product.totalAmountCurrency)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <template v-else>
          <div class="border-red-500">
            <div class="my-2">
              <PriceDetailInput v-if="showPrice" v-model="orderPrice" />
            </div>
            <div class="my-2">
              <Input
                :id="'qty'"
                type="number"
                :value="parseInt(product.quantity)"
                :name="'quantity'"
                :on-key-up="onAttributeChange"
                :placeholder="'Quantity'"
                :label="'Quantity'"
              />
            </div>

            <div v-if="showPrice" class="mt-5">
              <label>Supplier</label>
              <DynamicMultiselect
                v-model="supplier"
                :options="companySuppliersList()"
                :label="'name'"
                :placeholder="
                  $t('catalogue.catalogueItemForm.companySupplierPlaceholder')
                "
                :error-field-text="
                  $t('catalogue.catalogueItemForm.companySupplierFieldError')
                "
                searchable
                clear-on-search
                :can-clear="false"
              />
            </div>

            <FieldError
              v-if="quantityError || quantityWarning"
              :text="quantityError || quantityWarning"
              :severity="quantityError ? 'error' : 'warning'"
              :is-error="false"
            />
          </div>
          <Button
            :on-click-handler="saveValue"
            :disabled="!!quantityError"
            class="mt-1 text-sm"
          >
            Update Item
          </Button>
        </template>
      </div>
    </CollapseTransition>
  </div>
</template>
<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { ChevronDownIcon } from "@heroicons/vue/outline";
import {
  currencyToLocale,
  removeKeysInObjWithNoValue,
  sumInArray,
  isHashEqual,
} from "@/utils/utility_methods.js";
import {
  SwitchCheckbox,
  Button,
  PriceDetailInput,
  Input,
  FieldError,
  DynamicMultiselect,
  CurrencyInput,
} from "@/components/shared/index";
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    CurrencyInput,
    CollapseTransition,
    ChevronDownIcon,
    SwitchCheckbox,
    PriceDetailInput,
    Button,
    Input,
    FieldError,
    DynamicMultiselect,
  },
  props: {
    product: { type: Object, required: true },
    updateProductDetails: { type: Function, default: () => {} },
    action: { type: String, default: "Order" },
    showPartialDeliveredSection: { type: Boolean, default: false },
    updateProductDeliveryDetails: { type: Function, default: () => {} },
  },
  data() {
    return {
      orderPrice: this.defaultValue(),
      showDetails: false,
      sameDetails: true,
      quantityError: null,
      quantityWarning: null,
      supplier: this.product.companySupplier,
      isPartialDelivered: false,
      deliveryDetails: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySuppliersList"]),
    showPrice() {
      return this.action == "Order";
    },
    partialDeliveredError() {
      if (!this.showPartialDeliveredSection || !this.isPartialDelivered)
        return "";

      const { amount, quantity } = this.deliveryDetails || {};
      const { deliveredQuantity, deliveredAmount } =
        this.totalQuantityAndPriceDelivered;

      if (!amount && !quantity) {
        return this.$t(
          "purchaseRequest.summary.purchaseOrderStatus.deliveryDetails.errors.amountOrQuantityMissing"
        );
      }

      if (
        amount &&
        amount >
          parseFloat(this.product.netAmount) *
            parseFloat(this.product.quantity) -
            deliveredAmount
      ) {
        return this.$t(
          "purchaseRequest.summary.purchaseOrderStatus.deliveryDetails.errors.amountError"
        );
      }

      if (
        quantity &&
        quantity > parseFloat(this.product.quantity) - deliveredQuantity
      ) {
        return this.$t(
          "purchaseRequest.summary.purchaseOrderStatus.deliveryDetails.errors.quantityError"
        );
      }

      return "";
    },
    totalQuantityAndPriceDelivered() {
      const partialStatus = this.product.itemStatuses.find(
        (itemStatusObj) => itemStatusObj.status === "PA"
      );

      if (!partialStatus) {
        return { deliveredQuantity: 0, deliveredAmount: 0 };
      }

      const deliveredQuantity = this.sumInArray(
        partialStatus.deliveryDetails,
        "quantity"
      );
      const deliveredAmount = this.sumInArray(
        partialStatus.deliveryDetails,
        "amount"
      );

      return { deliveredQuantity, deliveredAmount };
    },
  },
  watch: {
    deliveryDetails: {
      handler(val) {
        if (val.quantity || val.amount)
          this.updateProductDeliveryDetails(
            this.product.id,
            removeKeysInObjWithNoValue(val)
          );
      },
      deep: true,
    },
    orderPrice: {
      handler(newVal, oldVal) {
        if (parseFloat(newVal.netAmount) && !isHashEqual(newVal, oldVal))
          this.processInput();
      },
      deep: true,
    },
  },
  methods: {
    currencyToLocale,
    sumInArray,
    processInput() {
      if (this.action == "Deliver") this.checkDeliverInput();
      else this.checkOrderInput();
    },
    checkDeliverInput() {
      const orderQuantity = parseInt(this.orderPrice.quantity);
      const productQuantity = parseInt(this.product.quantity);
      if (orderQuantity < productQuantity) {
        this.quantityError = this.$t(
          "purchaseRequest.summary.purchaseOrderStatus.deliveryDetails.errors.quantityErrors.lessThan",
          {
            quantity: this.product.quantity,
          }
        );
      } else if (orderQuantity > productQuantity) {
        this.orderPrice.quantity = this.product.quantity;
        this.quantityError = this.$t(
          "purchaseRequest.summary.purchaseOrderStatus.deliveryDetails.errors.quantityErrors.greaterThan"
        );
      } else {
        this.quantityError = null;
        this.quantityWarning = null;
      }
    },
    checkOrderInput() {
      const orderQuantity = parseInt(this.orderPrice.quantity);
      const productQuantity = parseInt(this.product.quantity);
      const orderPrice = parseFloat(this.orderPrice.netAmount);
      const productPrice = parseFloat(this.product.orderPriceNet);
      if (orderQuantity < productQuantity) {
        this.quantityWarning = this.$t(
          "purchaseRequest.errors.orderQuantityWarning"
        );
      } else if (orderQuantity > productQuantity) {
        this.orderPrice.quantity = this.product.quantity;
        this.quantityError = this.$t(
          "purchaseRequest.errors.orderQuantityError"
        );
      } else if (orderPrice > productPrice) {
        this.quantityError = this.$t("purchaseRequest.errors.orderPriceError");
      } else {
        this.quantityError = null;
        this.quantityWarning = null;
      }
    },
    saveValue() {
      this.updateProductDetails(this.product.id, {
        ...this.orderPrice,
        supplier: this.supplier,
      });
      this.showDetails = false;
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.orderPrice = { ...this.orderPrice, [name]: value };
    },
    defaultValue() {
      return {
        netAmount: this.product.orderPriceNet,
        grossAmount: this.product.orderPriceGross,
        vat: this.product.vat,
        currency: this.product.totalAmountCurrency,
        inputPrice:
          this.product.netGross === "gross"
            ? this.product.orderPriceGross
            : this.product.orderPriceNet,
        quantity: this.product.quantity,
        netGross: "net",
      };
    },
    onPartialDeliveredClick() {
      if (!this.isPartialDelivered) {
        this.updateProductDeliveryDetails(this.product.id, null);
      }
    },
  },
};
</script>

<style scoped>
.detail-box {
  @apply rounded-md p-3 border-l-4 border-primary mt-2;
}

.product-detail {
  @apply -mt-5;
}

.arrow {
  @apply left-full relative -ml-4 h-5 w-5 font-extrabold group-hover:opacity-100 transition -translate-x-0.5 -translate-y-0.5 cursor-pointer hover:text-primary;
}

.table .table-body tr {
  background: transparent !important;
}

.table .table-body tr td:nth-child(2) {
  text-align: right;
}
</style>
