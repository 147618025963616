<template>
  <HivebuyTooltip :open-delay="100">
    <span v-if="loading" class="h-8 w-8 loading-avatar"></span>
    <span
      v-else
      class="inline-flex items-center justify-center h-8 w-8 rounded-full border-white border-2 relative cursor-default"
      :class="deleted ? 'bg-gray-400' : 'bg-primary'"
    >
      <span
        class="text-xs 2xl:text-sm leading-none text-white"
        :class="deleted ? 'line-through' : ''"
      >
        {{ getFirstTwoChars(name) }}
      </span>
    </span>
    <template #content>
      <div class="p-2">
        <div v-if="loading" class="loading-text">Loading...</div>
        <div v-else>
          {{ name }}
        </div>
      </div>
    </template>
  </HivebuyTooltip>
</template>
<script>
import { getFirstTwoChars } from "@/utils/utility_methods";
import { HivebuyTooltip } from "@/components/shared/index";
export default {
  components: {
    HivebuyTooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFirstTwoChars,
  },
};
</script>
