<template>
  <notificationGroup group="hivebuy">
    <div
      class="inset-x-0 bottom-0 fixed flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]"
    >
      <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
        <notification v-slot="{ notifications, close }">
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
            :class="notification.type === 'error' ? 'shadow-hivebuy-red' : ''"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <component
                    :is="notification.icon"
                    class="h-6 w-6"
                    :class="[`text-${getGroupColor(notification.type)}`]"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    id="notification-title"
                    :class="[
                      'text-sm font-medium',
                      `text-${getGroupColor(notification.type)}`,
                    ]"
                  >
                    {{ notification.title }}
                  </p>
                  <div v-if="Array.isArray(notification.text)">
                    <ul>
                      <li v-for="error in notification.text" :key="error">
                        <p class="notification-text mt-1 text-sm text-gray-500">
                          {{ error }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <p class="notification-text mt-1 text-sm text-gray-500">
                      {{ notification.text }}
                    </p>
                  </div>
                </div>
                <div
                  class="ml-4 flex-shrink-0 flex"
                  @click="close(notification.id)"
                >
                  <button
                    type="button"
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup>
  <notificationGroup group="notification">
    <div
      class="fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end z-20"
    >
      <div class="max-w-sm w-full">
        <notification v-slot="{ notifications }">
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="flex w-full bg-white overflow-hidden mt-10 px-2 py-4 shadow-md"
          >
            <component
              :is="getNotificationComponent(notification.type)"
              class="text-gray-400"
              aria-hidden="true"
              :notification="notification.notificationObject"
            />
          </div>
        </notification>
      </div>
    </div>
  </notificationGroup>
</template>

<script>
import { CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/vue/solid";
import { notificationComponent } from "@/utils/helpers/notificationsHelper.js";
export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
  },
  methods: {
    getNotificationComponent(type) {
      return notificationComponent(type);
    },
    getGroupColor(type) {
      if (type === "success") {
        return "hivebuy-green";
      }
      if (type === "error") {
        return "hivebuy-red";
      }
      return "primary";
    },
  },
};
</script>

<style scoped>
.top_80 {
  top: 80%;
}

.list {
  list-style-type: disc;
}
</style>
