const mappingSubKey = (subKey) => {
  return { start: "Gte", end: "Lte" }[subKey];
};
export const getFilterParams = (params) => {
  const hash = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.value) {
      if (typeof params[key].value == "object") {
        Object.keys(params[key].value).forEach((subKey) => {
          const keyName = `${key}_${mappingSubKey(subKey)}`;
          hash[keyName] = params[key].value[subKey];
        });
      } else hash[key] = params[key]?.value;
    }
  });
  return hash;
};
