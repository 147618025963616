<template>
  <section class="custom-card no-shadow">
    <div class="header-row group" @click="toggleDetails">
      <h2 class="title">{{ conditionText }}</h2>
      <div class="arrow-holder">
        <ChevronDownIcon
          :class="[
            showDetails ? '' : 'rotate-180',
            'h-5 w-5 cursor-pointer text-white transition-all group-hover:text-primary ',
          ]"
        />
      </div>
    </div>
    <div
      :class="[showDetails ? 'mt-5' : 'mt-0', 'card-content transition-all']"
    >
      <CollapsibleSection :show-details="showDetails">
        <slot />
      </CollapsibleSection>
    </div>
  </section>
</template>

<script>
import { CollapsibleSection } from "@/components/shared/index";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "GlobalPolicies",
  components: {
    CollapsibleSection,
    ChevronDownIcon,
  },
  props: {
    conditionText: {
      type: String,
      required: true,
    },
    onStatusClickHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showDetails: true,
    };
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>

<style scoped>
.card-content {
  @apply flex flex-col w-full;
}

.header-row {
  @apply border-b border-gray-100 flex justify-between items-center -m-5 py-3 px-4 bg-gray-50 rounded cursor-pointer hover:bg-primary transition-all text-primary hover:text-white;
}

.header-row .title {
  @apply font-semibold transition-all;
}

.arrow-holder {
  @apply w-8 h-8 flex justify-center items-center bg-primary rounded-full group-hover:bg-white group-hover:text-primary transition-all;
}
</style>
