<!-- eslint-disable vue/prop-name-casing -->
<template>
  <div class="bg-white">
    <div class="lg:flex lg:justify-center divide-x divide-gray-200">
      <div class="lg:w-1/3 lg:flex-none">
        <div class="col-holder mt-1">
          <div class="col-inner">
            <p class="col-title">
              {{ $t("workflow.conditionsTitle") }}
            </p>
            <Conditions :conditions="items.conditions" />
          </div>
        </div>
      </div>
      <div class="lg:py-1 lg:w-1/3">
        <div class="col-holder">
          <div class="col-inner">
            <p class="col-title">
              {{ $t("workflow.approvalsTitle") }}
            </p>
            <Approvers :approvals="items.requiredApprovalTypes" />
          </div>
        </div>
      </div>
      <div
        v-if="flowType == 'purchase_request'"
        class="lg:py-1 lg:w-1/3 lg:flex-none"
      >
        <div class="col-holder">
          <div class="col-inner">
            <p class="col-title">
              {{ $t("workflow.resultTitle") }}
            </p>
            <Results :results="items.result" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Conditions, Approvers, Results } from "@/components/PurchaseRequest";

export default {
  components: {
    Conditions,
    Approvers,
    Results,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    flowType: {
      type: String,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    },
    workflowItems: {
      type: Function,
      required: true,
    },
  },
};
</script>
