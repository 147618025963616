<template>
  <svg
    id="Layer_1_1_"
    style="enable-background: new 0 0 30 30"
    version="1.1"
    viewBox="0 0 30 30"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill="currentColor"
    class="fill-current"
  >
    <path
      d="M28.948,16.684l-3-9C25.812,7.275,25.431,7,25,7h-6V2c0-0.552-0.447-1-1-1H2C1.447,1,1,1.448,1,2v23c0,0.552,0.447,1,1,1  h3.142C5.589,27.72,7.141,29,9,29s3.411-1.28,3.858-3h4.284c0.447,1.72,1.999,3,3.858,3s3.411-1.28,3.858-3H28c0.553,0,1-0.448,1-1  v-8C29,16.893,28.982,16.786,28.948,16.684z M3,3h14v12H3V3z M9,27c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S10.103,27,9,27z   M21,27c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S22.103,27,21,27z M27,24h-2.142c-0.447-1.72-1.999-3-3.858-3  s-3.411,1.28-3.858,3h-4.284c-0.447-1.72-1.999-3-3.858-3s-3.411,1.28-3.858,3H3v-7h15c0.553,0,1-0.448,1-1V9h5.279L27,17.162V24z"
    />
  </svg>
</template>

<script>
export default {
  name: "DeliverSVG",
};
</script>
