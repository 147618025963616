<template>
  <div class="mt-1">
    <Multiselect
      v-model="selectorValue"
      :mode="mode"
      :class="[classes, { 'border-red-400': isInvalid }]"
      :value-prop="valueProp"
      :track-by="trackBy"
      :label="label"
      :options="options"
      :create-option="createOption"
      :searchable="searchable"
      :clear-on-search="clearOnSearch"
      :can-deselect="canDeselect"
      :can-clear="canClear"
      :limit="limit"
      :max="max"
      :object="objectType"
      :placeholder="placeholder"
      @change="itemChangeHandler"
    >
      <template #afterlist>
        <div
          v-if="afterListPlaceholder"
          class="p-2 text-sm font-light text-gray-400"
        >
          {{ afterListPlaceholder }}
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label text-sm">
          {{ value[label] }}
        </div>
      </template>
    </Multiselect>
    <FieldError v-if="isInvalid" :text="errorFieldText" />
  </div>
</template>
<script setup>
import Multiselect from "@vueform/multiselect";
import { FieldError } from "@/components/shared/index";
import { computed } from "vue";

const props = defineProps({
  options: {
    type: [Array, Object],
    required: true,
  },
  createOption: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "name",
  },
  trackBy: {
    type: String,
    default: "name",
  },
  valueProp: {
    type: String,
    default: "id",
  },
  canDeselect: {
    type: Boolean,
    default: false,
  },
  clearOnSearch: {
    type: Boolean,
    default: true,
  },
  canClear: {
    type: Boolean,
    default: true,
  },
  searchable: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    optional: true,
    default: "",
  },
  delay: {
    type: Number,
    default: 0,
  },
  mode: {
    type: String,
    default: "single",
  },
  required: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: -1,
  },
  limit: {
    type: Number,
    default: -1,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Object],
    required: true,
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  afterListPlaceholder: {
    type: String,
    optional: true,
    default: "",
  },
  classes: {
    type: String,
    default: "w-full bg-white",
  },
  errorFieldText: {
    type: String,
    optional: true,
    default: "",
  },
  objectType: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "change"]);
const selectorValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});
const itemChangeHandler = (item) => {
  emit("change", item);
};
</script>
