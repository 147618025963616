import actions from "@/store/CompanyManagement/actions";
import mutations from "@/store/CompanyManagement/mutations";
import state from "@/store/CompanyManagement/state";
import getters from "@/store/CompanyManagement/getters.js";

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
