import * as ContractTypes from "@/store/Contract/types";

export default {
  [ContractTypes.SET_CONTRACTS]: (state, { payload, areFiltersUpdated }) => {
    state.contracts = areFiltersUpdated
      ? payload
      : [...state.contracts, ...payload];
  },
  [ContractTypes.SET_CONTRACTS_LIST_FILTERS]: (state, payload) => {
    state.contractsListFilters = payload;
  },
  [ContractTypes.REMOVE_CONTRACT]: (state, contractId) => {
    state.contracts = state.contracts.filter(
      (contract) => contract.id != contractId
    );
  },
  [ContractTypes.SET_CONTRACT]: (state, payload) => {
    const contractToSet = state.contracts.findIndex(
      (contract) => contract.id === payload?.id
    );
    if (contractToSet >= 0) {
      state.contracts[contractToSet] = payload;
    } else {
      state.contracts = [...state.contracts, payload];
    }
  },
};
