import { get, patch, post } from "../api";
import { API_KEYS_URL } from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";

export const getApiKeys = (commit, resolve, reject) => {
  get(API_KEYS_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_API_KEYS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const disableApiKey = (apiKeyId, commit, resolve, reject) => {
  if (!apiKeyId) return;

  patch(`${API_KEYS_URL}${apiKeyId}/`, {
    deactivatedAt: new Date(),
  })
    .then((response) => {
      commit(CompanyManagementTypes.SET_API_KEYS, [response.data]);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createApiKey = (payload, commit, resolve, reject) => {
  if (!payload) return;
  post(API_KEYS_URL, payload, {
    headers: {
      "Client-Id": payload.clientId,
      "Client-Secret": payload.clientSecret,
    },
  })
    .then((response) => {
      commit(CompanyManagementTypes.SET_API_KEYS, [response.data]);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
