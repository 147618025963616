import * as DownloadTypes from "@/store/Downloads/types";
import {
  downloadFile,
  downloadFiles,
  downloadList,
} from "@/utils/services/models/download.js";

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [DownloadTypes.DOWNLOAD_FILE]: ({ commit }, fileId) => {
    return new Promise((resolve, reject) => {
      downloadFile(fileId, resolve, reject);
    });
  },
  [DownloadTypes.DOWNLOAD_FILES]: ({ commit }, fileIds) => {
    return new Promise((resolve, reject) => {
      downloadFiles(fileIds, commit, resolve, reject);
    });
  },

  [DownloadTypes.DOWNLOAD_LIST]: ({ commit }, filters) => {
    return new Promise((resolve, reject) => {
      downloadList(commit, resolve, reject, filters);
    });
  },
};
