<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.601"
    height="21.595"
    viewBox="0 0 22.601 21.595"
    class="fill-current"
  >
    <g id="cart" transform="translate(-3362.875 678.001)">
      <path
        id="Union_5"
        data-name="Union 5"
        d="M0,21.595V4.43H4.519a5.039,5.039,0,0,1,.357-1.319A5.109,5.109,0,0,1,7.587.4a5.088,5.088,0,0,1,4.831.47,5.107,5.107,0,0,1,1.846,2.242,5.039,5.039,0,0,1,.357,1.319h.222l-1.9,1.864H6.53V8.761h-2.1V6.294H2.075V19.731h15.16V14.065l2.076-2.033v9.563ZM6.644,4.43H12.5a3,3,0,0,0-5.852,0Zm3.99,10.189L6.677,10.8,8.722,8.681l3.1,2.992,8.7-8.7L22.6,5.053,11.835,15.819Z"
        transform="translate(3362.875 -678.001)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "OrderSVG",
};
</script>
