<template>
  <NewsForm
    ref="createNewsForm"
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-news="onSave"
  />
</template>

<script>
import NewsForm from "@/components/News/NewsForm.vue";
import { mapActions } from "vuex";
import { DASHBOARD_MODULE, SAVE_NEWS } from "@/store/Dashboard/types";

export default {
  name: "AddNewsModal",
  components: {
    NewsForm,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      createNews: SAVE_NEWS,
    }),
    onSave(payload) {
      this.setApiLoading({ key: "newsCreate", value: true });
      this.createNews(payload)
        .then(() => {
          this.$refs.createNewsForm.onModalClose();
          this.showNotification(this.$t("news.newsModal.createSuccessText"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "newsCreate", value: false }));
    },
  },
};
</script>
