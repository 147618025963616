<template>
  <svg
    :class="[
      open ? 'text-white rotate-180' : 'text-white',
      'ml-3 flex-shrink-0 h-5 w-5 transform transition-all ease-in-out duration-300',
    ]"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>
</template>
<script>
export default {
  name: "DropdownSVG",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
