<template>
  <div v-if="loading">
    <div
      class="h-[100px] flex flex-col w-full border border-gray-300 rounded bg-white items-center justify-center"
    >
      <div class="w-10 h-10"><HivebuyLoader /></div>
    </div>
  </div>
  <div v-else class="quick-links">
    <div
      v-if="supplierLoading"
      class="absolute inset-0 flex items-center justify-center z-20"
    >
      <div
        class="flex-col flex items-center justify-center py-6 px-8 bg-gray-100 rounded-md border-primary border w-1/2"
      >
        <div class="w-16 h-16"><HivebuyLoader /></div>
        <p class="font-medium text-base">
          Opening
          <span class="mx-1 font-medium text-primary">{{
            activatedSupplier
          }}</span>
        </p>
      </div>
    </div>
    <ul class="quick-links__nav">
      <li
        v-for="supplier in companyIntegratedSuppliers"
        :key="supplier.id"
        class="quick-links__nav__item group cursor-pointer"
        @click="
          () => {
            fetchSupplierRedirectUrl(supplier);
          }
        "
      >
        <div
          class="quick-links__nav__item__integrated justify-center items-center px-2"
        >
          <img
            :src="supplier.supplier?.logo"
            class="object-contain"
            :alt="supplier.displayName || supplier?.name"
          />
        </div>
      </li>
      <li
        v-for="catalogue in filteredCatalogues"
        :key="catalogue.id"
        class="quick-links__nav__item group overflow-hidden"
      >
        <router-link
          :to="{ name: 'Catalogue', query: { catalogueId: catalogue.id } }"
        >
          <div class="icon-holder"><CollectionIcon class="icon" /></div>
          <div class="label">{{ catalogue.name }}</div>
        </router-link>
      </li>
      <li class="quick-links__nav__item group">
        <router-link :to="{ name: 'Catalogue', params: { catalogue: '' } }">
          <div class="icon-holder">
            <CollectionIcon class="icon" />
          </div>
          <div class="label">
            {{ $t("purchaseRequest.newRequest.catalogues") }}
          </div>
        </router-link>
      </li>
      <li class="quick-links__nav__item group">
        <router-link :to="{ name: 'CreatePurchaseRequest' }">
          <div class="icon-holder">
            <ClipboardListIcon class="icon-freeform" />
          </div>
          <div class="label">
            {{ $t("purchaseRequest.newRequest.freeFormShort") }}
          </div>
        </router-link>
      </li>
      <li
        v-if="isFeatureAllowed('shoppingList')"
        class="quick-links__nav__item group"
      >
        <router-link :to="{ name: 'ShoppingList' }">
          <div class="icon-holder w-8 icon">
            <ShoppinglistSVG />
          </div>
          <div class="label">
            {{ $t("purchaseRequest.newRequest.shoppingList") }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { CollectionIcon, ClipboardListIcon } from "@heroicons/vue/outline";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_QUICK_LINKS,
  GET_SUPPLIER_URL,
} from "@/store/CompanyManagement/types";
import { HivebuyLoader } from "@/components/shared/index";
import { mapActions, mapState } from "vuex";
import ShoppinglistSVG from "@/assets/images/ShoppinglistSVG";

export default {
  name: "QuickLinks",
  components: {
    CollectionIcon,
    HivebuyLoader,
    ClipboardListIcon,
    ShoppinglistSVG,
  },
  data() {
    return {
      supplierLoading: false,
      activatedSupplier: "",
      loading: false,
      cataloguesLimit: 4,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["quickLinks"]),
    companyIntegratedSuppliers() {
      return this.quickLinks?.integratedSuppliers;
    },
    catalogues() {
      return this.quickLinks?.catalogues;
    },
    filteredCatalogues() {
      return this.catalogues?.slice(0, this.cataloguesLimit);
    },
  },
  mounted() {
    !this.quickLinks && this.fetchQuickLinks();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getQuickLinks: GET_QUICK_LINKS,
      getSupplierUrl: GET_SUPPLIER_URL,
    }),
    fetchQuickLinks() {
      this.loading = true;
      this.getQuickLinks()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    fetchSupplierRedirectUrl(supplier) {
      this.supplierLoading = true;
      this.activatedSupplier = supplier.supplier.name;
      this.getSupplierUrl({ id: supplier.id })
        .then((data) => {
          window.location.href = data.url;
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.supplierLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.quick-links {
  @apply md:flex md:flex-nowrap overflow-scroll md:flex-row w-full space-y-2;
}
.quick-links__nav {
  @apply flex flex-col md:flex-row gap-x-4 gap-y-2;
}
.quick-links__nav__item {
  @apply h-auto lg:h-[100px] w-full md:w-[130px] shadow-none lg:shadow-md hover:shadow-lg flex;
}

.quick-links__nav__item:before {
  @apply absolute w-2 bg-primary top-2 bottom-2 -left-1 rounded-full md:hidden;
}

.quick-links__nav__item a {
  @apply flex md:flex-col w-full h-full border border-gray-300 rounded bg-white items-center md:justify-center hover:border-primary p-4 md:p-0 gap-x-2 md:gap-x-0;
}
.quick-links__nav__item__integrated {
  @apply flex md:flex-col w-full h-full border border-gray-300 rounded bg-white items-center justify-start md:justify-center hover:border-primary p-4 md:px-1 gap-x-2 md:gap-x-0;
}

.quick-links__nav__item__integrated img {
  @apply h-10 w-auto bg-contain;
}

.quick-links__nav__item__link {
  @apply flex flex-col w-full h-full border border-gray-300 rounded bg-white items-center justify-center hover:border-primary;
}
.quick-links__nav__item a .icon-holder {
  @apply md:mt-1 justify-center items-center group-hover:text-primary;
}

.icon {
  @apply w-8 h-8 text-hivebuy_darkplum;
}

.icon-freeform {
  @apply w-8 h-8 text-primary;
}

.quick-links__nav__item a .label {
  @apply text-sm md:text-xs md:mt-[4px] group-hover:text-primary text-center line-clamp-1 sm:ml-4 md:ml-0 mb-0;
}
</style>
