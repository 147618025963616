import {
  notificationUrl,
  storeDetails,
} from "@/utils/helpers/notificationsHelper.js";
import store from "@/store/index.js";
import { snakeToCamel, parseJson } from "@/utils/utility_methods.js";
import { Socket } from "./socket";
import * as socketActions from "./socketActions";

export class NotificationSocket extends Socket {
  constructor() {
    super();
  }
  connect() {
    const url = notificationUrl();
    if (url) {
      super.connect(url);
    }
  }
  commitToStore(object, activity, storeConfig) {
    const { storeState, commitPlaceholder } = storeConfig;
    if (!storeState) return;
    const payload = {
      object,
      activity,
      storeState,
    };

    store.commit(commitPlaceholder, payload);
  }
  getObjectDetails(event) {
    const parts = event.split("_");

    return {
      objectName: parts.slice(0, -1).join("_"),
      action: parts[parts.length - 1],
    };
  }

  newUpdate(data) {
    // TODO: add toast here if not current user
    try {
      if (data.data == "OK") {
        return true;
      }
      const parsedData = this.jsonParsable(data.data)
        ? parseJson(data.data)
        : null;
      if (!parsedData) return;
      const socketEvent = snakeToCamel(parsedData.eventType);
      if (socketActions[socketEvent]) {
        socketActions[socketEvent](parsedData.object);
      } else {
        const { objectName, action } = this.getObjectDetails(
          parsedData.eventType
        );
        const objectKeys = Object.keys(parsedData.object);
        const nestedObject = !objectKeys.includes("id");

        storeDetails(snakeToCamel(objectName)).forEach((storeConfig) => {
          const object = nestedObject
            ? parsedData.object[objectKeys[0]]
            : parsedData.object;
          const key = nestedObject ? objectKeys[0] : "object";
          this.commitToStore({ [key]: object }, action, storeConfig);
        });
      }
    } catch (e) {
      // TODO: ADD sentry event here
      console.error(e.message);
    }
  }
}
