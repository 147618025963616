<template>
  <Modal
    :show-modal="open"
    :show-icon="false"
    :size-classes="['sm:max-w-1xl']"
    :close-modal="onClose"
  >
    <template #header>
      {{ $t("purchaseRequest.otherSupplierModal.heading") }}
      <p class="mb-4 text-sm font-normal text-gray-500">
        {{ $t("purchaseRequest.otherSupplierModal.headingSubtext") }}
      </p>
    </template>
    <template #body>
      <Input
        :value="companySupplier.name"
        :name="'name'"
        :placeholder="$t('purchaseRequest.otherSupplierModal.name')"
        :on-value-change="onAttributeChange"
        :type="'text'"
        :error-text="error(v$.companySupplier.name)"
      />
      <label
        :for="companySupplier.reason"
        class="block text-sm font-medium text-gray-500 mt-6 mb-2"
        >{{ $t("purchaseRequest.otherSupplierModal.reason") }}</label
      >
      <TextArea
        :name="'reason'"
        :value="companySupplier.reason"
        :on-value-change="onAttributeChange"
        :placeholder="$t('purchaseRequest.otherSupplierModal.reasonText')"
        :rows="3"
      />
    </template>
    <template #footer>
      <Button :on-click-handler="onClose" :color="'gray'">
        {{ $t("purchaseRequest.otherSupplierModal.cancel") }}
      </Button>
      <Button :on-click-handler="confirmSave">{{
        $t("purchaseRequest.otherSupplierModal.continue")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, Input, TextArea } from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import errorMixin from "@/components/mixins/errorMixin.js";
import { mapState } from "vuex";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types.js";
export default {
  components: {
    Modal,
    Input,
    Button,
    TextArea,
  },
  mixins: [errorMixin],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: Function,
      required: true,
    },
    setRequestSupplier: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      companySupplier: this.supplierData(),
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
  },
  methods: {
    supplierData() {
      let data = { name: "", reason: "" };
      const supplier = this.currentRequest?.supplier;
      if (supplier && supplier.type == "unlisted") {
        data = supplier.details;
      }
      return data;
    },
    confirmSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.toggleFunction();
        this.setRequestSupplier("unlisted", this.companySupplier);
        this.companySupplier = {};
        this.v$.$reset();
      }
    },

    onClose() {
      this.v$.$reset();
      this.toggleFunction();
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.companySupplier[name] = value;
    },
  },
  validations() {
    return {
      companySupplier: {
        name: { required },
      },
    };
  },
};
</script>
