<template>
  <label class="inline-flex items-center mr-8">
    <input
      v-model="selectedValue"
      :name="name"
      :value="value"
      type="radio"
      class="border-gray-300 focus:outline-none focus:ring-primary focus:border-primary form-radio h-4 w-4 text-primary"
      :checked="selected"
      @change="onValueChange($event)"
    /><span class="ml-2 font-medium text-gray-700">{{ label }}</span>
  </label>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    onValueChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedValue: this.inputValue,
    };
  },
  computed: {
    selected() {
      return this.inputValue == this.value;
    },
  },
  watch: {
    selectedValue: {
      handler(val) {
        this.onValueChange({
          target: { name: this.name, value: val },
        });
      },
    },
  },
};
</script>
