import { mapActions, mapState } from "vuex";
import debounceMixin from "@/components/mixins/debounceMixin";
import { removeKeysFromObject } from "@/utils/utility_methods";
import { GET_PROJECTS, PROJECT_MODULE } from "@/store/Project/types";

export default {
  mixins: [debounceMixin],
  computed: {
    ...mapState(PROJECT_MODULE, ["projectsListFilters"]),
    shouldExecuteProjectPaginationApi() {
      return (
        this.projectsListFilters.pageSize <
        this.projectsListFilters.totalDbRecords
      );
    },
    searchProjectDebounceTimeout() {
      return this.shouldExecuteProjectPaginationApi ? 1000 : 0;
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProjects: GET_PROJECTS,
    }),
    onSearchProject(key, value) {
      if (
        this.shouldExecuteProjectPaginationApi &&
        value !== this.projectsListFilters.search
      ) {
        const paginationParams = removeKeysFromObject(
          this.projectsListFilters,
          ["totalDbRecords"]
        );
        this.setApiLoading({ key: "projectsDropDownList", value: true });
        const updatedParams = Object.assign({}, paginationParams, {
          page: 1,
          [key]: value,
        });
        this.getProjects(updatedParams).then(() =>
          this.setApiLoading({ key: "projectsDropDownList", value: false })
        );
      }
      this.search = value;
    },
  },
};
