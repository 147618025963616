<template>
  <div>
    <p class="text-sm">
      <span
        class="text-blue-500 cursor-pointer"
        @click="showDetails = !showDetails"
      >
        {{
          $t("companyManagement.connectedSupplier.configurationModal.clickHere")
        }}
      </span>
      {{
        $t(
          "companyManagement.connectedSupplier.configurationModal.clickHereText"
        )
      }}
    </p>
    <CollapseTransition>
      <div v-if="showDetails" class="block rounded-xl">
        <div
          class="bg-opacity-50 bg-gray-100 border-gray-200 border rounded-md p-2"
        >
          <slot name="body" />
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  components: {
    CollapseTransition,
  },
  data() {
    return {
      showDetails: false,
    };
  },
};
</script>
