<template>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12 custom-card no-shadow">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 class="section-title">
            {{ $t(`purchaseRequest.summary.resendWebhookSection.title`) }}
          </h3>
          <p class="max-w-xl text-sm text-gray-500">
            {{ $t(`purchaseRequest.summary.resendWebhookSection.desc`) }}
          </p>
        </div>
        <div
          class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center"
        >
          <ConfirmDialog
            :text="
              $t(
                `purchaseRequest.summary.resendWebhookSection.confirmDialog.text`
              )
            "
            :show-modal="showConfirmModal"
            :close-modal="toggleConfirmModal"
            :title="
              $t(
                `purchaseRequest.summary.resendWebhookSection.confirmDialog.title`
              )
            "
            :item-to-confirm="purchaseRequest.title"
            :confirm-modal="onResendWebhook"
          />
          <Button
            v-if="!resent"
            :on-click-handler="toggleConfirmModal"
            :disabled="loading"
          >
            {{ $t(`purchaseRequest.summary.resendWebhookSection.btnText`) }}
          </Button>
          <div
            v-else
            class="bg-gray-100 px-3 py-2 text-hivebuy-green rounded-lg flex items-center border border-hivebuy-green"
          >
            <CheckCircleIcon class="h-5 w-5 mr-2" />
            {{ $t(`purchaseRequest.summary.resendWebhookSection.btnSuccess`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, ConfirmDialog } from "@/components/shared/index";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";
import {
  PURCHASE_ORDER_MODULE,
  RESEND_WEBHOOK,
} from "@/store/PurchaseOrder/types";

export default {
  components: { Button, ConfirmDialog, CheckCircleIcon },
  props: {
    purchaseRequest: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      showConfirmModal: false,
      resent: false,
    };
  },
  methods: {
    ...mapActions(PURCHASE_ORDER_MODULE, {
      resendWebhook: RESEND_WEBHOOK,
    }),
    toggleConfirmModal() {
      this.showConfirmModal = !this.showConfirmModal;
    },
    onResendWebhook() {
      this.loading = true;
      this.resendWebhook(this.purchaseRequest.purchaseOrder.id)
        .then(() => {
          this.showNotification(
            this.$t(
              "purchaseRequest.summary.resendWebhookSection.resendWebhookSuccess"
            )
          );
          this.toggleConfirmModal();
          this.resent = true;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
