import store from "@/store/index.js";
import { AUTH_MODULE } from "@/store/Auth/types";
import purchaseOrderAccountingView from "@/assets/data/preferences/purchaseOrderAccountingView.json";
import invoiceAccountingView from "@/assets/data/preferences/invoiceAccountingView.json";

class PreferenceService {
  getTablePreferenceUpdatePayload(viewName, fields) {
    const userGetter = store.getters[`${AUTH_MODULE}/user`];
    const user = userGetter;

    return {
      preference: {
        ...user.preference,
        preferredViews: user.preference.preferredViews.map((view) => {
          if (view.view.name === viewName) {
            return {
              ...view,
              fields: fields
                .filter((field) => field.visible)
                .map((field) => field.field),
            };
          }
          return view;
        }),
      },
    };
  }
  viewMapping(viewName) {
    return {
      purchaseOrderAccountingView,
      invoiceAccountingView,
    }[viewName];
  }
}

export default new PreferenceService();
