import {
  PURCHASE_REQUEST_STATUSES,
  PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING,
} from "@/utils/constants";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(AUTH_MODULE, ["isUserAdmin", "user"]),
    isOriginalRequester() {
      return this.purchaseRequest.requestedBy === this.user.id;
    },
    isPrPending() {
      return this.purchaseRequest.status == PURCHASE_REQUEST_STATUSES.PENDING;
    },
    isItemRestorable() {
      const { REJECTED, COMPLETE, CANCELLED } =
        PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING;

      if ([REJECTED, COMPLETE].includes(this.item.status)) return false;
      const { APPROVED } = PURCHASE_REQUEST_STATUSES;
      return (
        this.item.requestStatus == APPROVED && this.item.status == CANCELLED
      );
    },

    isPoCancellable() {
      const obj = this.item ? this.item : this.purchaseRequest?.purchaseOrder;
      return this.poCancelConditions(obj);
    },
    isPurchaseRequestCancellable() {
      if (this.item) return this.isPrItemCancelable(this.item);

      if (this.purchaseRequest?.isItemLevelApprover) {
        return this.purchaseRequest.items.every((item) =>
          this.isPrItemCancelable(item)
        );
      }

      return (
        this.isOriginalRequester &&
        this.isPrPending &&
        this.purchaseRequest.status !== PURCHASE_REQUEST_STATUSES.Cancelled
      );
    },
    isPO() {
      return !!this.purchaseRequest?.purchaseOrder;
    },
    poId() {
      if (this.isPO) return this.purchaseRequest.purchaseOrder.id;
      return null;
    },

    isCancelable() {
      if (this.purchaseRequest?.purchaseOrder) return this.isPoCancellable;
      else return this.isPurchaseRequestCancellable;
    },
  },
  created() {
    this.PURCHASE_REQUEST_STATUSES = PURCHASE_REQUEST_STATUSES;
    this.PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING =
      PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING;
  },
  methods: {
    poCancelConditions(obj) {
      const { Cancelled } = PURCHASE_REQUEST_STATUSES;
      const { COMPLETE, CANCELLED, REJECTED } =
        PURCHASE_ORDER_ASSIGNABLE_STATUS_MAPPING;
      const notAllowedStatus = [COMPLETE, CANCELLED, REJECTED];
      return (
        !notAllowedStatus.includes(obj.status) &&
        ![Cancelled, REJECTED].includes(obj.requestStatus) &&
        this.isUserAdmin
      );
    },
    isPrItemCancelable(item) {
      return (
        this.isOriginalRequester &&
        item.requestStatus == PURCHASE_REQUEST_STATUSES.PENDING
      );
    },
  },
};
