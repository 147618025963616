<template>
  <Vue3Lottie :animation-data="loaderJSON" :height="height" :width="width" />
</template>

<script>
import loaderJSON from "@/assets/images/Hivebuy-Loader.json";
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
export default {
  components: {
    Vue3Lottie,
  },
  props: {
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      loaderJSON,
    };
  },
};
</script>
