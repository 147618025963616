<template>
  <div class="text-sm font-medium">
    <div
      class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full content-center"
    >
      <span class="icon-column relative" :class="rowIcon.class">
        <component :is="rowIcon.icon" class="h-5 w-5" />
        <div
          v-if="object.approvalType === 'GP'"
          class="absolute -top-1 -left-1"
        >
          <HivebuyTooltip :open-delay="50">
            <PlusCircleIcon class="w-4 h-4 text-primary" />
            <template #content>
              <div class="p-2 whitespace-nowrap">
                {{ $t("purchaseRequest.detail.addedBecauseGP") }}
              </div>
            </template>
          </HivebuyTooltip>
        </div>
        <div
          v-if="object.approvalType === 'DE'"
          class="absolute -top-1 -left-1"
        >
          <Tip theme="tooltip">
            <template #placeholder>
              <PlusCircleIcon class="w-4 h-4 text-hivebuy-red" />
            </template>
            <template #header>
              {{ $t("purchaseRequest.detail.addedBecauseDEHeader") }}
            </template>
            <template #content>
              <div class="text-sm font-normal w-96">
                {{ $t("purchaseRequest.detail.addedBecauseDE") }}
              </div>
            </template>
          </Tip>
        </div>
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex">
          <span class="text-gray-400 mr-1" :class="rowText.class">
            {{ rowText.leading }}
          </span>

          <span class="text-gray-900 font-semibold" :class="rowText.class">
            <div class="avatar-column">
              <div v-if="isApproved || isRejected">
                <UserWithAvatar
                  :user="object.decidedBy"
                  :show-name-only="true"
                />
              </div>
              <Role
                v-else-if="object.type === 'role'"
                :role="object.object.name"
                :badge="false"
                class="text-gray-900 font-semibold ml-1 !text-sm flex justify-start"
              />

              <div
                v-else-if="
                  object.type === 'approverOfDepartment' ||
                  object.type === 'budgetOwnerOfDepartment'
                "
              >
                <Department :department="object.object" />
              </div>
              <div v-else-if="object.type === 'approverOfLegalEntity'">
                <LegalTendency :legal-tendency="object.object" />
              </div>
              <div v-else-if="object.approvalType === 'DE'">Administrator</div>
              <div v-else>
                <div
                  v-for="(
                    approver, approverIndex
                  ) in object.purchaseRequestApprovers"
                  :key="approverIndex"
                  class="inline-flex"
                  :class="approverIndex > 0 ? '-ml-2' : ''"
                >
                  <UserWithAvatar
                    :user="approver"
                    :avatar-dimension="9"
                    show-tooltip
                    class="cursor-help"
                    :show-name-only="true"
                  />
                </div>
              </div>
            </div>
          </span>
        </div>
        <div v-if="!isPending">
          <p
            v-if="object.decisionNote"
            class="text-sm md:text-xs text-gray-800"
          >
            {{ object.decisionNote }}
          </p>
          <p class="text-xs text-gray-500 my-1">
            <TimeDisplay :time="object.decidedAt" />
          </p>
        </div>
        <div v-if="sentTime" class="text-xs text-gray-500 my-1">
          {{
            $t("purchaseRequest.summary.timeline.sentForApproval", {
              time: sentTime,
            })
          }}
        </div>
      </div>
      <div class="avatar-column">
        <div v-if="isApproved || isRejected">
          <UserWithAvatar
            :user="object.decidedBy"
            :avatar-dimension="9"
            show-tooltip
            class="cursor-help"
          />
        </div>
        <div v-else>
          <div
            v-for="(approver, approverIndex) in object.purchaseRequestApprovers"
            :key="approverIndex"
            class="inline-flex -ml-2 first:ml-0"
          >
            <UserWithAvatar
              :user="approver"
              :avatar-dimension="9"
              show-tooltip
              class="cursor-help"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  UserWithAvatar,
  Department,
  TimeDisplay,
} from "@/components/shared/index";
import { timeDifference } from "@/utils/timeHelper.js";
import {
  ClockIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  XCircleIcon,
  PlusCircleIcon,
} from "@heroicons/vue/solid";
import { APPROVAL_STATUS_MAPPING } from "@/utils/constants";
import { memberDetailsMixin } from "@/components/mixins/index";
import { fullName } from "@/utils/utility_methods";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import LegalTendency from "@/components/shared/LegalTendency";
import Role from "@/components/CompanyManagement/Users/Role.vue";
export default {
  components: {
    ClockIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    XCircleIcon,
    UserWithAvatar,
    PlusCircleIcon,
    HivebuyTooltip,
    Department,
    TimeDisplay,
    LegalTendency,
    Role,
  },
  mixins: [memberDetailsMixin],
  props: {
    overallStatus: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    sentTime: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    isPending() {
      return this.object.status === APPROVAL_STATUS_MAPPING.PENDING;
    },
    isApproved() {
      return this.object.status === APPROVAL_STATUS_MAPPING.APPROVED;
    },
    isRejected() {
      return this.object.status === APPROVAL_STATUS_MAPPING.REJECTED;
    },
    isRevision() {
      return this.object.status === APPROVAL_STATUS_MAPPING.REVISION;
    },
    rowIcon() {
      let details = {};
      switch (true) {
        case this.isThisApprovalVoid(): {
          details = {
            icon: XCircleIcon,
            class: "bg-gray-100 text-gray-400",
          };
          break;
        }
        case this.isApproved: {
          details = {
            icon: ThumbUpIcon,
            class: "bg-gray-100 border border-hivebuy-green text-hivebuy-green",
          };
          break;
        }
        case this.isRejected: {
          details = {
            icon: ThumbDownIcon,
            class: "bg-hivebuy-red text-white",
          };
          break;
        }
        case this.isRevision: {
          details = {
            icon: ClockIcon,
            class: "bg-hivebuy-red bg-opacity-20 text-hivebuy-red",
          };
          break;
        }
        case this.isPending: {
          details = {
            icon: ClockIcon,
            class: "bg-gray-100 text-hivebuy-yellow",
          };
          break;
        }
        default:
          details = {
            icon: ClockIcon,
            class: "bg-gray-100 text-gray-400",
          };
          break;
      }
      return details;
    },
    rowText() {
      if (this.isApproved) {
        return {
          leading: this.$t("purchaseRequest.summary.timeline.approvedBy"),
          class: "",
        };
      }
      if (this.isPending) {
        return {
          leading: this.$t("purchaseRequest.summary.timeline.pendingBy"),
          class: "",
        };
      }
      if (this.isRejected) {
        return {
          leading: this.$t("purchaseRequest.summary.timeline.rejectedBy"),
          class: "",
        };
      }
      if (this.isRevision) {
        return {
          leading: this.$t("purchaseRequest.summary.timeline.revisionBy"),
          class: "",
        };
      }
      return {
        leading: "DEFAULT",
        approver: "DEFAULT",
        class: "",
      };
    },
  },

  methods: {
    timeDifference,
    fullName,
    isThisApprovalVoid() {
      return (
        this.object.status === APPROVAL_STATUS_MAPPING.PENDING &&
        this.overallStatus === "R"
      );
    },
    async memberFullName(memberObject) {
      return this.fullName(await this.getMemberDetails(memberObject));
    },
  },
};
</script>

<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
