<template>
  <Modal
    :show-modal="showModal"
    :show-icon="false"
    :close-modal="closeModal"
    :size-classes="['min-w-[60vw] max-w-[80vw] max-h-[95vh]']"
  >
    <template #header>
      <div class="flex flex-col items-start">
        <h3 class="text-lg text-primary font-semibold">
          {{ invoiceDetails?.invoiceNumber }}
        </h3>
        <p class="text-sm font-normal text-gray-500">
          {{ $t("invoice.uploadedAt") }}
          {{ formatDateTime(invoiceDetails.createdAt, language) }}
        </p>
      </div>
    </template>
    <template #body>
      <InvoiceDetails :invoice-details="invoiceDetails" :loading="loading" />
    </template>

    <template #footer>
      <div class="col-span-2 flex justify-center">
        <Button :on-click-handler="closeModal" :color="'gray'">
          {{ $t("buttons.close") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal } from "@/components/shared/index";
import { invoiceDetailsMixin } from "@/components/mixins";
import InvoiceDetails from "@/components/InvoiceApproval/InvoiceDetails.vue";

export default {
  components: {
    Button,
    Modal,
    InvoiceDetails,
  },
  mixins: [invoiceDetailsMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    invoiceId: {
      type: String,
      required: true,
    },
  },
};
</script>
