<template>
  <section class="approval-workflow">
    <!-- page title -->
    <h3 class="page-title">{{ $t("workflow.title") }}</h3>
    <!-- page-title end -->

    <div class="cards-holder">
      <div class="custom-card">
        <div class="border-b border-gray-200 -mt-5 -mx-5">
          <nav class="tabs" aria-label="Tabs">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              :class="[
                current.name == tab.name ? 'tab tab--active' : 'tab',
                tab.feature && !isFeatureAllowed('invoiceApproval')
                  ? 'cursor-default'
                  : '',
              ]"
              :aria-current="tab.current ? 'page' : undefined"
              @click="() => tabChange(tab)"
            >
              <span>{{ tab.name }}</span>

              <div v-if="tab.feature">
                <HivebuyTooltip>
                  <span
                    v-if="!isFeatureAllowed('invoiceApproval')"
                    class="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal bg-primary text-white"
                    >Pro</span
                  >
                  <template #content>
                    <div
                      class="p-2 text-sm font-medium whitespace-pre-line max-w-xs"
                    >
                      <p class="mb-2 font-medium">
                        This feature is not enabled for your company
                      </p>
                      Contact our
                      <a
                        class="text-primary font-normal"
                        href="mailto:sales@hivebuy.de"
                        >Sales Team</a
                      >
                      to enable this feature
                    </div>
                  </template>
                </HivebuyTooltip>
              </div>
            </div>
          </nav>
        </div>

        <component :is="current.component" />
      </div>
    </div>
  </section>
</template>
<script>
import {
  PurchaseRequestApproval,
  InvoiceApproval,
} from "@/components/CompanyManagement/ApprovalWorkflow/index.js";
import { HivebuyTooltip } from "@/components/shared/index";
import GlobalPolicies from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/GlobalPolicies";

export default {
  components: {
    PurchaseRequestApproval,
    InvoiceApproval,
    HivebuyTooltip,
    GlobalPolicies,
  },
  data() {
    return {
      tabs: this.tabOptions(),
      current: this.tabOptions()[0],
    };
  },
  mounted() {
    if (this.$route.query.flowType == "invoice_purchase_order")
      this.current = this.tabOptions()[1];
  },

  methods: {
    tabOptions() {
      return [
        {
          name: this.$t("workflow.tabTitle"),
          component: "PurchaseRequestApproval",
          feature: false,
        },
        {
          name: this.$t("workflow.invoice.title"),
          component: "InvoiceApproval",
          feature: true,
        },
        {
          name: this.$t("approvalWorkflow.globalPolicies.title"),
          component: "GlobalPolicies",
          feature: false,
        },
      ];
    },
    isTabChangeAllowed(tab) {
      return (
        tab.component !== "InvoiceApproval" ||
        (tab.component === "InvoiceApproval" &&
          this.isFeatureAllowed("invoiceApproval"))
      );
    },
    tabChange(currentTab) {
      if (this.isTabChangeAllowed(currentTab)) {
        this.current = currentTab;
      }
    },
  },
};
</script>
