import { SHOPPING_LIST_STATUSES } from "@/utils/constants.js";

export default {
  productDetails: (state) => (index) => {
    return state.currentList[index];
  },
  shoppingList:
    (state) =>
    (status = "O", userId = null, id = null) => {
      if (id) return state.shoppingList[id];

      const listArray = Object.values(state.shoppingLists);
      let response = listArray.filter((list) => list.status == status);
      if (userId)
        response = response.filter((list) => list.requestedBy == userId);
      return response;
    },
  shoppingListItems: (state) => (shoppingsLists, items) => {
    const details = [];
    shoppingsLists.forEach((list) => {
      const shoppingList = state.shoppingLists[list];
      if (shoppingList)
        details.push({
          title: shoppingList.title,
          id: list,
          requestedBy: shoppingList.requestedBy,
          requestedAt: shoppingList.requestedAt,
          items: shoppingList.items.filter((value) => items.includes(value.id)),
        });
    });
    return details;
  },
  countShoppingLists: (state) => {
    const lists = Object.values(state.shoppingLists);
    return lists.filter((list) => list.status != SHOPPING_LIST_STATUSES.done)
      ?.length;
  },
};
