import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "@/i18n";

import { AppVariables } from "../config";
import "@/assets/styles/main.css";
import "@/assets/styles/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "design-buy/dist/style.css";

const instance = new AppVariables(createApp(App), router).initAppVariables();

instance.use(store).use(router).use(i18n).mount("#app");
export { instance };
