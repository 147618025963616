import {
  cachedGet,
  deleteObject,
  get,
  patch,
  post,
} from "@/utils/services/api";
import { PROJECT_API_URL } from "@/utils/services/apiUrls";
import * as ProjectTypes from "@/store/Project/types";
import { PROJECT_MODULE } from "@/store/Project/types";
import { urlWithParams } from "@/utils/helpers/apis/requiredParams";
import { formattedURL, formattedUrlApi } from "@/utils/utility_methods";
import store from "@/store";

export const getProjects = (commit, resolve, reject, paginationParams = {}) => {
  get(formattedURL(PROJECT_API_URL, paginationParams))
    .then((response) => {
      commit(ProjectTypes.SET_PROJECTS, response.data);
      resolve(response.data.results);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getProjectsList = (commit, resolve, reject, params) => {
  get(formattedUrlApi(PROJECT_API_URL, params))
    .then((response) => {
      commit(ProjectTypes.SET_PROJECTS_LIST, response.data.results);
      resolve(response);
    })
    .catch((err) => reject(err));
};

export const getProject = (resolve, reject, projectId) => {
  if (!projectId) return;

  const projectGetter = store.getters[`${PROJECT_MODULE}/getProject`];
  const response = projectGetter(projectId);

  if (response) {
    return resolve(response);
  }

  cachedGet(`${PROJECT_API_URL}${projectId}/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createProject = (commit, resolve, reject, payload) => {
  post(urlWithParams(PROJECT_API_URL), payload)
    .then((response) => {
      commit(ProjectTypes.SET_PROJECT, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateProject = (
  commit,
  resolve,
  reject,
  { projectId, payload }
) => {
  if (!projectId) return;

  patch(urlWithParams(`${PROJECT_API_URL}${projectId}/`, "project"), payload)
    .then((response) => {
      commit(ProjectTypes.SET_PROJECT, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteProject = (commit, resolve, reject, projectId) => {
  if (!projectId) return;

  deleteObject(`${PROJECT_API_URL}${projectId}/`)
    .then(() => {
      commit(ProjectTypes.REMOVE_PROJECT, projectId);

      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};
