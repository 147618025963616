<template>
  <Button
    v-if="retriesLeft"
    :on-click-handler="initiateResend"
    class="mr-2"
    :disabled="timerCount > 0"
    size-class="xs"
    color-outline
  >
    <span v-if="timerCount > 0">Send again in {{ timerCount }} seconds</span>
    <span v-else>{{ $t("companyManagement.users.resendInvite") }}</span>
  </Button>
</template>
<script>
import Button from "@/components/shared/Button.vue";
import {
  COMPANY_MANAGEMENT_MODULE,
  RESEND_INVITE,
  SET_PENDING_USER,
} from "@/store/CompanyManagement/types";
import { mapActions, mapMutations } from "vuex";
export default {
  components: { Button },
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      timerCount: 0,
      maximumSentTimes: 20,
    };
  },
  computed: {
    retriesLeft() {
      if (this.user.sentTimes >= this.maximumSentTimes) return false;
      return true;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      resendInvite: RESEND_INVITE,
    }),
    ...mapMutations(COMPANY_MANAGEMENT_MODULE, {
      updatePendingUser: SET_PENDING_USER,
    }),
    initiateResend() {
      this.resendInvite(this.user.id).then(() =>
        this.updatePendingUser({
          ...this.user,
          sentTimes: this.user.sentTimes + 1,
        })
      );
      this.timerCount = 30;
    },
  },
};
</script>
