<template>
  <div class="left-content">
    <PDFViewer
      v-if="isPdfFile"
      :pdf-link="fileUrl"
      :component-loading="isApiLoading('invoiceDetails')"
      :show-download="showDownload"
    />
    <img
      v-else
      class="relative w-full object-contain p-4"
      :src="fileUrl"
      alt=""
    />
  </div>
</template>
<script>
import { getUrlExtension, toBase64 } from "@/utils/utility_methods.js";
import { PDFViewer } from "@/components/shared/index";
export default {
  components: { PDFViewer },
  props: {
    invoice: { type: Object, required: true },
    showDownload: { type: Boolean, optional: true, default: false },
  },
  data() {
    return {
      fileUrl: this.invoice.files[0].file,
    };
  },
  computed: {
    isPdfFile() {
      return (
        this.invoice.files.length &&
        ["pdf", "PDF"].includes(getUrlExtension(this.invoice.files[0].file))
      );
    },
  },
  mounted() {
    this.setFileUrl();
  },
  methods: {
    setFileUrl() {
      if (
        this.invoice.files[0].title &&
        typeof this.invoice.files[0].file != "string"
      ) {
        toBase64(this.invoice.files[0].file).then(
          (data) => (this.fileUrl = data)
        );
      }
    },
    getUrlExtension,
  },
};
</script>

<style scoped>
.left-content {
  height: calc(100% + 0px);
  overflow: hidden;
}
</style>
