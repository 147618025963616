<template>
  <div class="flex flex-col gap-2">
    <div class="p-float-label">
      <InputText
        id="title"
        v-model="value"
        :class="{ 'p-invalid': error }"
        :pt="{
          root: { class: 'w-full' },
        }"
      />
      <label for="title">{{ label }}</label>
    </div>
    <small v-if="helper" id="helper-text">{{ helper }}</small>
    <small v-if="error" id="error-text"> <FieldError :text="error" /></small>
  </div>
</template>

<script>
import FieldError from "@/components/shared/FieldError.vue";
import InputText from "primevue/inputtext";
export default {
  components: {
    InputText,
    FieldError,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "<Label>",
    },
    helper: {
      type: String,
      default: null,
    },
    error: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:modelValue"],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
