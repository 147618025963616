<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserGroupIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3>
        {{
          isEditMode
            ? $t("catalogue.catalogueModal.editCatalogue")
            : $t("catalogue.catalogueModal.addCatalogue")
        }}
      </h3>
    </template>
    <template #body>
      <div class="space-y-8">
        <div>
          <Input
            id="name"
            :error-text="nameErrorMessage"
            name="name"
            :value="catalogue.name"
            type="text"
            :placeholder="$t('catalogue.catalogueModal.fields.namePlaceholder')"
            :on-value-change="onAttributeChange"
            :label="$t('catalogue.catalogueModal.fields.name')"
            is-mandatory
          />
        </div>
        <div class="py-4 border-t border-b border-gray-200">
          <label class="block text-sm font-medium text-gray-700 my-2">
            <i18n-t
              keypath="catalogue.catalogueModal.fields.departments"
              tag="p"
            >
              <template #permission>
                <span class="font-bold text-primary">
                  {{ $t(`catalogue.catalogueModal.fields.${permissionType}`) }}
                </span>
              </template>
            </i18n-t>
          </label>

          <DepartmentSelect
            v-model="catalogue.departments"
            :mode="'multiple'"
            :label="'name'"
            :track-by="'name'"
            :hide-selected="false"
          />
          <div class="mt-4">
            <div class="py-2">
              {{
                $t("catalogue.catalogueModal.fields.selectAllDepartmentsText")
              }}
            </div>
            <SwitchCheckbox
              v-model="catalogue.assignedDepartmentsNotAllowed"
              :label="
                $t('catalogue.catalogueModal.fields.selectAllDepartments')
              "
              :on-click-handler="onSelectAll"
            />
          </div>
        </div>
        <div v-if="isFeatureAllowed('legalEntity')" class="mt-6">
          <p class="block text-sm font-medium text-gray-700 my-1">
            {{ $t("global.legalEntity") }}
          </p>
          <div>
            <Multiselect
              v-model="catalogue.legalEntities"
              :mode="'tags'"
              :searchable="true"
              :options="legalTendencyList"
              :placeholder="$t('global.legalEntity')"
              value-prop="id"
              label="name"
              track-by="name"
              :can-clear="true"
              :close-on-select="false"
            />
          </div>
        </div>
        <div class="py-4">
          <div class="md:col-span-2">
            <div class="label">
              {{ $t("catalogue.catalogueModal.fields.description") }}
            </div>
            <HivebuyEditor v-model="catalogue.description" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("shared.cancelButton") }}
      </Button>
      <Button
        type="button"
        :on-click-handler="saveCatalogueObject"
        :loading="loading"
      >
        {{ $t("shared.saveButton") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { mapGetters, mapState } from "vuex";
import { errorMixin } from "@/components/mixins/index.js";
import { UserGroupIcon } from "@heroicons/vue/solid";
import { Button, Modal, Input } from "@/components/shared";
import Multiselect from "@vueform/multiselect";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { isObjectEmpty } from "@/utils/utility_methods";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox.vue";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";
import { PAGINATION_PAGE_SIZE } from "@/utils/constants";
import { HivebuyEditor } from "design-buy";
import { mapActions } from "vuex";
import {
  CATALOGUE_MODULE,
  CREATE_CATALOGUE,
  UPDATE_CATALOGUE,
} from "@/store/Catalogue/types";
export default {
  components: {
    DepartmentSelect,
    SwitchCheckbox,
    Modal,
    UserGroupIcon,
    Button,
    Input,
    Multiselect,
    HivebuyEditor,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    catalogueToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      loading: false,

      catalogue: {
        name: "",
        description: "",
        departments: [],
        assignedDepartmentsNotAllowed: false,
      },
    };
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, ["catalogues"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "departmentsList",
      "legalTendencyList",
    ]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departmentPaginationParams"]),
    isEditMode() {
      return !isObjectEmpty(this.catalogueToEdit);
    },
    nameErrorMessage() {
      if (!this.showModal) return "";

      const { name } = this.v$.catalogue;
      return this.name && name.unique.$invalid
        ? this.$t("catalogue.catalogueModal.fields.uniqueNameError")
        : this.error(name);
    },
    permissionType() {
      return this.assignedDepartmentsNotAllowed
        ? "deparmentsDenyAccess"
        : "deparmentsAllowAccess";
    },
  },
  watch: {
    catalogueToEdit: {
      immediate: true,
      handler(val) {
        if (!isObjectEmpty(val)) {
          this.catalogue = val;
        }
      },
    },
  },
  created() {
    this.PAGINATION_PAGE_SIZE = PAGINATION_PAGE_SIZE;
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      createCatalogue: CREATE_CATALOGUE,
      updateCatalogue: UPDATE_CATALOGUE,
    }),
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.catalogue[name] = value;
    },
    actionAndPayload() {
      const payload = this.catalogue;
      return {
        action: this.isEditMode ? this.updateCatalogue : this.createCatalogue,
        payload: this.isEditMode
          ? {
              catalogueId: this.catalogueToEdit.id,
              payload,
            }
          : payload,
      };
    },
    saveCatalogueObject() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        const { action, payload } = this.actionAndPayload();
        action(payload)
          .then(() => {
            this.onModalClose();
            this.showNotification(this.$t("toasts.genericSuccess"));
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.catalogue = {
          name: "",
          description: "",
          departments: [],
          assignedDepartmentsNotAllowed: false,
        };
        this.v$.$reset();
      }, 500);
    },
  },
  validations() {
    return {
      catalogue: {
        name: {
          required,
          unique: (val) => {
            if (this.isEditMode && this.catalogueToEdit?.name === val) {
              return true;
            }
            return !this.catalogues.find((catalogue) => catalogue.name === val);
          },
        },
      },
    };
  },
};
</script>
