import { get, post } from "@/utils/services/api";
import {
  EXPORT_DATA_URL,
  ANALYTICS_API_URL,
  DELIVERY_NOTE_URL,
  ACTIVITY_HISTORY,
} from "@/utils/services/apiUrls";
import {
  DOWNLOAD_MODULE,
  SET_DOWNLOAD_STATUS,
  SET_UPLOAD_STATUS,
} from "@/store/Downloads/types";
import { multipartHeaders } from "@/utils/services/setup/requestConstants.js";
import {
  SET_ANALYTICS_STATISTICS,
  SET_DETAILED_ANALYTICS,
} from "@/store/Dashboard/types";
import { formattedURL } from "@/utils/utility_methods";

export const uploadCSV = (formData, resolve, reject) => {
  post(`${EXPORT_DATA_URL}validate-file/`, formData, multipartHeaders())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const finalizeCSV = (formData, commit, resolve, reject) => {
  post(`${EXPORT_DATA_URL}upload-file/`, formData, multipartHeaders())
    .then((response) => {
      commit(
        `${DOWNLOAD_MODULE}/${SET_UPLOAD_STATUS}`,
        {
          storeState: formData.get("modelName"),
          inProgress: true,
          error: false,
          value: null,
        },
        {
          root: true,
        }
      );
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const downloadCSV = (payload, type, commit, resolve, reject) => {
  let params = { days: null };
  if (payload) {
    params = {
      modelName: payload.type,
      budgetLevel: payload.level,
      format: payload.format,
    };
  }
  const fileType = type === "template" ? "template" : "download-file";
  post(`${EXPORT_DATA_URL}${fileType}/`, params)
    .then((response) => {
      commit(
        `${DOWNLOAD_MODULE}/${SET_DOWNLOAD_STATUS}`,
        {
          storeState: payload.type,
          downloading: true,
          value: null,
          error: false,
        },
        {
          root: true,
        }
      );
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveSelfSignupCompanyInfo = (commit, resolve, reject, payload) => {
  post("self-company/", payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createDeliveryNoteReport = (resolve, reject, payload) => {
  post(DELIVERY_NOTE_URL, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const openChargeBeePortalSession = (resolve, reject) => {
  get("company/chargebee/generate-session/")
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDetailedAnalytics = (commit, resolve, reject, payload) => {
  get(`${formattedURL(ANALYTICS_API_URL, payload)}`)
    .then((response) => {
      commit(SET_DETAILED_ANALYTICS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getAnalyticsStatistics = (commit, resolve, reject, payload) => {
  get(`${formattedURL("/calculations-analytics", payload)}`)
    .then((response) => {
      commit(SET_ANALYTICS_STATISTICS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getHistory = (resolve, reject, payload) => {
  post(ACTIVITY_HISTORY, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
