// Modules
export const FEATURE_MODULE = "FEATURE_MODULE";

//Mutations
export const SET_FEATURES = "SET_FEATURES";
export const SET_FEATURE = "SET_FEATURE";
export const LIVE_UPDATE_FLAG = "LIVE_UPDATE_FLAG";

// Actions
export const UPDATE_FEATURE_FLAG = "UPDATE_FEATURE_FLAG";
