<template>
  <div v-if="showLoader" class="w-full flex items-center">
    <HivebuyLoader class="w-5 h-5" />
  </div>
  <div v-else-if="entity" class="truncate-min">
    <div v-if="showIcon" class="flex items-center justify-center">
      <component :is="icons.component" v-if="icons" v-bind="icons.props" />
    </div>
    <div v-if="showMainText" :class="'flex items-center truncate-min'">
      <TruncateText :text="mainText" />
    </div>
    <div v-if="showSecondaryText" :class="'flex items-center truncate'">
      {{ secondaryText }}
    </div>
  </div>
</template>
<script>
import HivebuyLoader from "@/components/shared/HivebuyLoader.vue";
import UserWithAvatar from "@/components/shared/UserWithAvatar.vue";
import { OfficeBuildingIcon } from "@heroicons/vue/outline";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { PROJECT_MODULE } from "@/store/Project/types";
export default {
  name: "EntitySections",
  components: {
    HivebuyLoader,
    OfficeBuildingIcon,
    ProjectSVG,
    UserWithAvatar,
  },
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    classes: {
      type: Array,
      default: () => [],
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showMainText: {
      type: Boolean,
      default: false,
    },
    showSecondaryText: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getUser", "departmentDetails"]),
    ...mapGetters(PROJECT_MODULE, ["getProject"]),
    userObject() {
      const id = this.entity.user || this.entity.id;
      return this.getUser(id);
    },
    departmentObject() {
      const id = this.entity.department || this.entity.id;
      return this.departmentDetails(id, true);
    },
    projectObject() {
      const id = this.entity.project || this.entity.id;
      return this.getProject(id);
    },
    isEntityDepartment() {
      return this.entity.department || this.entity.type === "department";
    },
    isEntityProject() {
      return this.entity.project || this.entity.type === "project";
    },
    isEntityUser() {
      return this.entity.user || this.entity.type === "user";
    },
    icons() {
      switch (this.entity.type) {
        case "department":
          return { component: OfficeBuildingIcon, props: { class: "icon" } };
        case "project":
          return { component: ProjectSVG, props: { class: "icon" } };
        case "user":
          return {
            component: UserWithAvatar,
            props: {
              user: this.userObject,
              showDelete: false,
              avatarDimension: "8",
            },
          };
        default:
          return null;
      }
    },
    mainText() {
      switch (this.entity.type) {
        case "department":
          return this.departmentObject.name;
        case "project":
          return this.projectObject.name;
        case "user":
          return this.userObject.name || this.userObject.email.split("@")[0];
        default:
          return "Entity";
      }
    },
    secondaryText() {
      switch (this.entity.type) {
        case "department":
          return this.departmentObject.costCenter;
        case "project":
          return this.projectObject.costCenter;
        case "user":
          return this.departmentDetails(
            this.entity.department || this.userObject.departments[0],
            true
          )?.name;
        default:
          return "Entity";
      }
    },

    showLoader() {
      return false;
    },
  },
};
</script>

<style scoped>
.label-sub-heading {
  @apply text-sm text-gray-400 truncate;
}

.label-heading {
  @apply truncate text-base font-medium text-gray-600;
}

.icon {
  @apply text-white h-5 w-5;
}
</style>
