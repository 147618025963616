<template>
  <div class="custom-card no-shadow">
    <div class="custom-card-header">
      <div class="card-title">
        {{ $t("contracts.contractForm.runtimeDetails") }}
      </div>
    </div>
    <div class="card-content">
      <div class="row">
        <div class="cell">
          <DateRangeCalendar
            v-model="dateRange"
            :validation-error="v.contract?.runtimeDetails?.startDate?.$error"
            :is-mandatory="!!v.contract?.runtimeDetails?.startDate"
          />
        </div>
      </div>
      <div class="row">
        <div class="cell pt-1">
          <label class="label">
            {{ $t("contracts.contractForm.noticePeriodType") }}
          </label>
          <Multiselect
            v-model="runtimeDetails.noticePeriodType"
            :searchable="true"
            :options="noticePeriodTypeOptions"
            :can-clear="false"
            :mode="'single'"
            :placeholder="
              $t('contracts.contractForm.noticePeriodTypePlaceholder')
            "
          />
        </div>
        <div class="cell">
          <Input
            :value="runtimeDetails.noticePeriodCount"
            :on-value-change="
              (event) => (runtimeDetails.noticePeriodCount = event.target.value)
            "
            type="number"
            :placeholder="$t('contracts.contractForm.noticePeriodCount')"
            :label="$t('contracts.contractForm.noticePeriodCount')"
            :name="'noticePeriodCount'"
            :error-text="
              errorMessage(v.contract.runtimeDetails?.noticePeriodCount)
            "
          />
        </div>
      </div>

      <div class="row">
        <div class="cell">
          <div class="flex items-center">
            <div>
              <label class="label">
                {{ $t("contracts.contractForm.reminder") }}
              </label>
            </div>

            <div class="left-content ml-2 flex items-center">
              <Tip help theme="tooltip">
                <template #header>{{
                  $t("contracts.contractForm.reminder")
                }}</template>
                <template #content>
                  <div class="max-w-md">
                    <p class="tooltip-subtext">
                      {{ $t("contracts.contractForm.tooltip.reminder1") }}
                    </p>
                    <p class="tooltip-subtext">
                      {{ $t("contracts.contractForm.tooltip.reminder2") }}
                    </p>
                    <p class="tooltip-subtext">
                      {{ $t("contracts.contractForm.tooltip.reminder3") }}
                    </p>
                  </div>
                </template>
              </Tip>
            </div>
          </div>
          <SwitchCheckbox
            v-model="runtimeDetails.reminder"
            :class="'flex-col !items-start '"
          />
        </div>
        <div v-if="runtimeDetails.reminder" class="cell">
          <Input
            :value="runtimeDetails.reminderTime"
            :on-value-change="
              (event) => (runtimeDetails.reminderTime = event.target.value)
            "
            type="number"
            :placeholder="$t('contracts.contractForm.reminderTime')"
            :label="$t('contracts.contractForm.reminderTime')"
            :name="'reminderTime'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateRangeCalendar, Input, SwitchCheckbox } from "@/components/shared";
import Multiselect from "@vueform/multiselect";
import { contractMixin, errorMixin } from "@/components/mixins";

export default {
  name: "RuntimeDetails",
  components: {
    DateRangeCalendar,
    Input,
    SwitchCheckbox,
    Multiselect,
  },
  mixins: [errorMixin, contractMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {};
  },
  computed: {
    dateRange: {
      get() {
        return {
          start: this.modelValue.startDate,
          end: this.modelValue.endDate,
        };
      },
      set(value) {
        this.runtimeDetails.startDate = value.start;
        this.runtimeDetails.endDate = value.end;
      },
    },
    runtimeDetails: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    noticePeriodTypeOptions() {
      return Object.entries(this.noticePeriodTypes()).map(([value, label]) => {
        return { value, label };
      });
    },
  },
  methods: {
    errorMessage(validationField) {
      return validationField ? this.error(validationField) : "";
    },
  },
};
</script>

<style scoped>
.page-content .left-content .card-content .row {
  @apply flex w-full gap-x-4 mb-4 last:mb-0;
}

.page-content .left-content .card-content .row .cell {
  @apply flex flex-1 flex-col;
}
.tooltip-header {
  @apply text-sm font-medium text-gray-900;
}
.tooltip-subtext {
  @apply mt-1 text-sm text-gray-500 whitespace-normal;
}
</style>
