import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { isString } from "@/utils/utility_methods";

export default {
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    defaultDateMask() {
      return {
        input: this.company.defaultDateFormat.toUpperCase(),
      };
    },
  },
  methods: {
    formattedDate(date) {
      if (!date) return "-";
      const parsedDate = isString(date) ? parseISO(date) : date;
      return format(parsedDate, this.company.defaultDateFormat);
    },
  },
};
