<template>
  <RadialBar
    :loading="loading"
    :series="series"
    :labels="labels"
    :colors="colors()"
    :heading="$t('charts.ordersDonutChart.heading')"
    :description="$t('charts.ordersDonutChart.desc')"
    :total-records="purchaseOrders.length"
  >
    <template v-if="!loading" #stats>
      <div class="rounded border border-gray-200 bg-gray-50 text-sm px-4">
        {{ totalNetAmount }}
      </div>
    </template>
  </RadialBar>
</template>

<script>
import { currencyLocaleMixin } from "@/components/mixins";
import RadialBar from "@/components/Analytics/charts/radialBar";

export default {
  components: {
    RadialBar,
  },
  mixins: [currencyLocaleMixin],
  props: {
    loading: { type: Boolean, default: false },
    purchaseOrders: { type: Array, required: true },
    purchaseOrderTotalNetAmount: { type: Number, required: true },
    purchaseOrderMedian: { type: Number, required: true },
  },
  computed: {
    totalNetAmount() {
      return this.valueInCurrency(this.purchaseOrderTotalNetAmount, true);
    },
    medianAmount() {
      return this.valueInCurrency(this.purchaseOrderMedian, true);
    },
    series() {
      const booleanStatuses = {
        O: "ordered",
        D: "delivered",
        I: "invoiced",
      };
      const statusesCount = {};
      this.statuses().forEach((status) => (statusesCount[status] = 0));

      this.purchaseOrders.forEach((purchaseOrder) => {
        if (!Object.keys(booleanStatuses).includes(purchaseOrder.status)) {
          statusesCount[purchaseOrder.status] =
            statusesCount[purchaseOrder.status] + 1;
        }

        Object.entries(booleanStatuses).forEach(([key, value]) => {
          if (purchaseOrder[value]) {
            statusesCount[key] = statusesCount[key] + 1;
          }
        });
      });

      return this.getRecordsBasedOnOrdering(statusesCount, this.statuses());
    },
    labels() {
      return this.statuses().map((status) =>
        this.$t(`statuses.order.${status}`)
      );
    },
  },
  methods: {
    statuses() {
      return ["P", "O", "D", "I", "CO"];
    },
    colors() {
      return ["#F6D181", "#dfd3ec", "#5c164e", "#4DBAAE", "#479E75"];
    },
    getRecordsBasedOnOrdering(data, orderingArray) {
      return Object.entries(data)
        .sort(
          (a, b) =>
            orderingArray.indexOf(data[a]) - orderingArray.indexOf(data[b])
        )
        .filter((record) => this.statuses().includes(record[0]))
        .map((record) => {
          if (record[1] > 0) {
            return Math.round((record[1] / this.purchaseOrders?.length) * 100);
          }
          return record[1];
        });
    },
  },
};
</script>
