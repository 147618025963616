<template>
  <div class="text-sm font-medium mt-5">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column text-hivebuy-green bg-gray-100">
        <FastForwardIcon class="h-5 w-5" />
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex">
          <span class="text-gray-400 mr-1">
            {{ $t("timeline.delegatedBy") }}
          </span>
          <UserWithAvatar :user="details.decidedBy" :show-name-only="true" />
        </div>
        <p
          v-if="details.decisionNote"
          class="text-sm md:text-xs text-gray-800 mt-1"
        >
          {{ details.decisionNote }}
        </p>
        <div class="text-xs text-gray-500 my-1">
          <TimeDisplay :time="details.decidedAt" />
        </div>
      </div>
      <div class="avatar-column flex">
        <UserWithAvatar
          :user="details.decidedBy"
          :avatar-dimension="9"
          show-tooltip
          class="cursor-help"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { UserWithAvatar, TimeDisplay } from "@/components/shared/index";
import { FastForwardIcon } from "@heroicons/vue/outline";

export default {
  components: { FastForwardIcon, UserWithAvatar, TimeDisplay },
  props: {
    details: { type: Object, required: true },
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
