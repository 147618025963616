<template>
  <div class="flex flex-row-reverse">
    <div class="flex space-x-2 top-6 z-10">
      <DownloadFile :models="models" />
      <Menu as="div" class="relative inline-block text-left z-50 mx-2">
        <MenuButton
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          :disabled="showFileDownload"
        >
          {{ $t("global.actions") }}
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="origin-top-right absolute right-0 mt-2 z-50 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"
          >
            <div class="py-1">
              <MenuItem
                v-if="orderModal"
                v-slot="{ active }"
                :disabled="downloadIsBeingPrepared"
              >
                <div
                  class="cursor-pointer flex"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  :onClick="() => downloadPurchaseOrders('xlsx')"
                >
                  <TableIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  {{ $t("purchaseOrder.export.download", { format: "Excel" }) }}
                </div>
              </MenuItem>
              <MenuItem
                v-if="orderModal"
                v-slot="{ active }"
                :disabled="downloadIsBeingPrepared"
              >
                <div
                  class="cursor-pointer flex"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  :onClick="() => downloadPurchaseOrders('csv')"
                >
                  <TableIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  {{ $t("purchaseOrder.export.download", { format: "CSV" }) }}
                </div>
              </MenuItem>
              <MenuItem
                v-if="invoiceModal"
                v-slot="{ active }"
                :disabled="downloadIsBeingPrepared"
              >
                <a
                  class="cursor-pointer flex"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  :onClick="downloadInvoices"
                  ><DocumentDownloadIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />{{ $t("purchaseOrder.export.invoices") }}</a
                >
              </MenuItem>

              <MenuItem
                v-if="orderModal"
                v-slot="{ active }"
                :disabled="downloadIsBeingPrepared"
              >
                <a
                  class="cursor-pointer flex"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                  :onClick="fetchAccrualReport"
                  ><DocumentDownloadIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />{{ $t("purchaseOrder.export.accrualReport") }}</a
                >
              </MenuItem>
            </div>
            <div
              v-for="action in customActions"
              :key="action.name"
              class="py-1"
            >
              <MenuItem v-slot="{ active }" :disabled="action.disabled">
                <a
                  class="cursor-pointer flex"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                    action.disabled ? 'opacity-50' : '',
                    action.labelStyle,
                  ]"
                  :onClick="() => !action.disabled && action.action()"
                  ><component
                    :is="action.icon"
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  />
                  {{ action.label }}
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
  <FileDownloadLoader
    :show-modal="showFileDownload"
    :show-file-downloaded="showFileDownloaded"
    :close-loading="
      () => {
        showFileDownload = false;
      }
    "
  />
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import DownloadFile from "@/components/PurchaseOrder/PurchaseOrdersList/DownloadFile";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { DocumentDownloadIcon, TableIcon } from "@heroicons/vue/outline";
import { DOWNLOAD_MODULE, SET_DOWNLOAD_STATUS } from "@/store/Downloads/types";
import { mapMutations, mapState, mapActions } from "vuex";
import { mapAdvanceFilter } from "@/utils/Constants/filters/mapFilterKeys.js";
import tableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import {
  DOWNLOAD_ACCRUAL_REPORT,
  DOWNLOAD_PURCHASE_REQUESTS_INVOICES,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import {
  PURCHASE_ORDER_MODULE,
  DOWNLOAD_PURCHASE_ORDERS,
} from "@/store/PurchaseOrder/types";
import { downloadMixin } from "@/components/mixins/index.js";
import { FileDownloadLoader } from "@/components/shared/index";
import { INVOICE_MODULE } from "@/store/Invoice/types.js";
import { getFilterParams } from "@/utils/helpers/filters/filterFormatHelper.js";

export default {
  components: {
    FileDownloadLoader,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    DocumentDownloadIcon,
    TableIcon,
    DownloadFile,
  },
  mixins: [downloadMixin, tableFilterMixin],
  props: {
    model: { type: String, default: "orders" },
    customActions: { type: Array, default: () => [] },
    models: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFileDownload: false,
      showFileDownloaded: false,
      downloadIsBeingPrepared: false,
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, {
      invoiceListParams: (state) => state.invoiceListParams,
    }),
    ...mapState(PURCHASE_ORDER_MODULE, {
      purchaseOrdersListParams: (state) => state.purchaseOrdersListParams,
      advancedFilters: (state) =>
        state.purchaseOrdersListFilters.advancedFilters,
    }),
    orderModal() {
      return this.model === "orders";
    },
    invoiceModal() {
      return this.model === "invoice";
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      downloadOrders: DOWNLOAD_PURCHASE_ORDERS,
      downloadInvoicesApi: DOWNLOAD_PURCHASE_REQUESTS_INVOICES,
      downloadAccrualReport: DOWNLOAD_ACCRUAL_REPORT,
    }),
    ...mapActions(PURCHASE_ORDER_MODULE, {
      downloadOrders: DOWNLOAD_PURCHASE_ORDERS,
    }),
    ...mapMutations(DOWNLOAD_MODULE, {
      setDownloadStatus: SET_DOWNLOAD_STATUS,
    }),
    downloadPurchaseOrders(format = "csv") {
      this.downloadIsBeingPrepared = true;
      const params = this.requestQueryParams({
        filters: this.purchaseOrdersListParams.filters,
      });
      params.fileFormat = format;

      this.downloadOrders(params)
        .then(() => {
          this.setDownloadStatus({
            storeState: "csvOrders",
            downloading: true,
            value: null,
          });
        })
        .finally(() => {
          this.downloadIsBeingPrepared = false;
        });
    },

    downloadInvoices() {
      const params = getFilterParams(this.invoiceListParams.filters);
      const filters = mapAdvanceFilter(params, INVOICE_MODULE);
      filters.search = [this.invoiceListParams.search, filters.search];
      this.downloadIsBeingPrepared = true;
      this.downloadInvoicesApi(filters)
        .then(() => {
          this.setDownloadStatus({
            storeState: "invoices",
            downloading: true,
            value: null,
          });
        })
        .finally(() => {
          this.downloadIsBeingPrepared = false;
        });
    },
    fetchAccrualReport() {
      this.downloadAccrualReport().then(() => {
        this.setDownloadStatus({
          storeState: "accrualReport",
          downloading: true,
          value: null,
        });
      });
    },
  },
};
</script>
