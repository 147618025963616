import { mapActions, mapGetters } from "vuex";
import { DOWNLOAD_MODULE, DOWNLOAD_FILE } from "@/store/Downloads/types.js";
export default {
  computed: {
    ...mapGetters(DOWNLOAD_MODULE, [
      "downloadStatus",
      "downloadedFiles",
      "fileDownloading",
      "downloadStatusModel",
      "exportStatusesForArray",
      "fileUploading",
    ]),
  },
  methods: {
    ...mapActions(DOWNLOAD_MODULE, {
      downloadFile: DOWNLOAD_FILE,
    }),
    fetchFile(fileType) {
      this.downloadFile(this.downloadStatus(fileType).value).then(
        (response) => {
          const url = response.data.file;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.setAttribute("download", this.fileName(fileType));
          document.body.appendChild(link);
          link.click();
        }
      );
    },
    fileName(fileType) {
      return { invoices: "invoices.zip" }[fileType];
    },
  },
};
