<template>
  <div
    class="w-full relative h-4 max-h-6 flex max-w-full min-h-1 shadow-sm"
    :class="[{ rounded: rounded }, { indeterminate: indeterminate }]"
  >
    <!-- APPROVED BAR -->
    <div
      v-if="
        inBarLabels &&
        approved > 0 &&
        getApprovedPercentage <= 100 &&
        getBalance < 0
      "
      :style="{ width: `${getApprovedPercentage}%` }"
      class="shadow-none flex items-center justify-center whitespace-nowrap progress-bar-approved"
      :class="colorApproved"
    >
      <span
        v-if="formatValue(getApprovedPercentage / 100) > threshold"
        class="text-xs text-white font-medium text-size-10 overflow-x-hidden"
        ><i18n-n
          :value="formatValue(getApprovedPercentage / 100)"
          format="percent0"
          locale="de"
      /></span>
      <span
        v-if="labels"
        class="flex items-center h-full text-xs font-light progress-bar-pending"
      >
        <slot>{{ approved }}</slot>
      </span>
    </div>
    <div
      v-if="getPendingPercentage > 0 && getBalance < 0"
      :style="{ width: `${getPendingPercentage}%` }"
      class="shadow-none flex justify-center whitespace-nowrap items-center"
      :class="colorPending"
    >
      <span
        v-if="
          inBarLabels &&
          pending > 0 &&
          formatValue(getPendingPercentage / 100) > threshold
        "
        class="text-xs text-black font-medium text-size-10 overflow-x-hidden"
      >
        <i18n-n
          :value="formatValue(getPendingPercentage / 100)"
          format="percent0"
          locale="de"
        />
      </span>
      <span v-if="labels" class="flex items-center h-full text-xs font-light">
        <slot>{{ pending }}</slot>
      </span>
    </div>
    <div
      v-if="getPendingRemaining > 0 && getBalance < 0"
      :style="{ width: `${getPendingRemaining}%` }"
      class="shadow-none flex justify-center whitespace-nowrap items-center progress-bar-remaining"
      :class="color"
    >
      <span
        v-if="inBarLabels && formatValue(getPendingRemaining / 100) > threshold"
        class="text-xs text-black font-medium text-size-10 overflow-x-hidden"
        ><i18n-n
          :value="formatValue(getPendingRemaining / 100)"
          format="percent0"
          locale="de"
      /></span>
      <span v-if="labels" class="flex items-center h-full text-xs">
        <slot></slot>
      </span>
    </div>
    <div
      v-if="getBalance > 0"
      :style="{ width: `${getNegBalance}%` }"
      class="shadow-none flex justify-center whitespace-nowrap items-center progress-bar-warning"
      :class="colorWarning"
    >
      <span
        v-if="inBarLabels"
        class="text-xs text-black font-medium text-size-10 overflow-x-hidden"
        ><i18n-n
          :value="formatValue(getNegBalance / 100)"
          format="percent0"
          locale="de"
      /></span>
      <span v-if="labels" class="flex items-center h-full text-xs">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      default: function (val) {
        if (val > 3) return 2;
        return val;
      },
    },
    color: {
      type: String,
      default: "bg-gray-300",
    },
    colorApproved: {
      type: String,
      default: "bg-teal-500",
    },
    colorPending: {
      type: String,
      default: "bg-pending",
    },
    colorWarning: {
      type: String,
      default: "bg-warning",
    },
    total: {
      type: Number,
      required: true,
    },
    approved: {
      type: Number,
      required: true,
    },
    pending: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Boolean,
      default: true,
    },

    inBarLabels: {
      type: Boolean,
      default: false,
    },

    indeterminate: Boolean,
  },
  data() {
    return {
      threshold: 0.15,
    };
  },

  computed: {
    getApprovedPercentage: function () {
      const approvedBudget = Number(this.approved) / Number(this.total);
      return approvedBudget * 100;
    },
    getPendingPercentage: function () {
      return (parseFloat(this.pending) / parseFloat(this.total)) * 100;
    },
    getPendingRemaining: function () {
      const remaining =
        (parseFloat(this.pending) + parseFloat(this.approved)) /
        parseFloat(this.total);
      return 100 - remaining * 100;
    },
    getBalance: function () {
      const balance =
        parseFloat(this.pending) +
        parseFloat(this.approved) -
        parseFloat(this.total);

      return balance;
    },
    getNegBalance: function () {
      const balance =
        (parseFloat(this.pending) + parseFloat(this.approved)) /
        parseFloat(this.total);

      return balance * 100;
    },
  },
  methods: {
    formatValue(value) {
      return Math.round(value * 100 + Number.EPSILON) / 100;
    },
  },
};
</script>

<style scoped>
.text-size-10 {
  font-size: 10px;
}
.progress-bar-approved {
  background-color: #17b8a6;
}
.progress-bar-pending {
  @apply bg-yellow-400;
}
.progress-bar-remaining {
  background-color: #efefefba;
}
.progress-bar-warning {
  background-color: #eb6363;
}
</style>
>
