import { currencyToLocale } from "@/utils/utility_methods";
import DeliverModal from "@/components/Dashboard/PurchaseOrders/DeliverModal";
import OrderModal from "@/components/Dashboard/PurchaseOrders/OrderModal";
import InvoiceModal from "@/components/Dashboard/PurchaseOrders/InvoiceModal";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { DownloadIcon, UploadIcon } from "@heroicons/vue/outline";

export default {
  data() {
    return {
      showOrderModal: false,
      showDeliverModal: false,
      showInvoiceModal: false,
    };
  },
  components: {
    DeliverModal,
    OrderModal,
    SwitchCheckbox,
    DownloadIcon,
    UploadIcon,
    InvoiceModal,
  },
  methods: {
    currencyToLocale,
    onOrderModalClose() {
      this.showOrderModal = false;
    },
    onDeliverModalClose() {
      this.showDeliverModal = false;
    },
    onInvoiceModalClose() {
      this.showDeliverModal = false;
    },
    invoiceFileName(file) {
      const name = file.split("invoice_");
      return name[1].split("?")[0];
    },
    onMarkAsOrdered() {
      this.showOrderModal = true;
    },
    onMarkAsDelivered() {
      this.showDeliverModal = true;
    },
  },
};
