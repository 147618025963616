import { deleteObject, get, patch, post } from "../api";
import { NEWS_URL, USER_NEWS_URL } from "@/utils/services/apiUrls.js";
import * as DashboardTypes from "@/store/Dashboard/types";

export const getNews = (commit, resolve, reject) => {
  get(NEWS_URL)
    .then((response) => {
      commit(DashboardTypes.SET_NEWS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveNews = (commit, resolve, reject, payload) => {
  if (payload?.id) updateNewsItem(commit, resolve, reject, payload.id, payload);
  else addNewsItem(commit, resolve, reject, payload);
};

const addNewsItem = (commit, resolve, reject, payload) => {
  post(NEWS_URL, payload)
    .then((response) => {
      commit(DashboardTypes.SAVE_NEWS_DATA, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

const updateNewsItem = (commit, resolve, reject, newsId, payload) => {
  patch(`${NEWS_URL}${newsId}/`, payload)
    .then((response) => {
      commit(DashboardTypes.SAVE_NEWS_DATA, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

export const deleteNews = (commit, resolve, reject, newsId) => {
  deleteObject(`${NEWS_URL}${newsId}/`)
    .then(() => {
      commit(DashboardTypes.REMOVE_NEWS, newsId);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUserRelevantNews = (commit, resolve, reject) => {
  get(USER_NEWS_URL)
    .then((response) => {
      commit(DashboardTypes.SET_USER_RELEVANT_NEWS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const markNewsAsRead = (commit, resolve, reject, id, payload) => {
  patch(`${USER_NEWS_URL}${id}/`, payload)
    .then((response) => {
      commit(DashboardTypes.SET_USER_RELEVANT_NEWS_DATA, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};
