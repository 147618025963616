<template>
  <Modal
    :size-classes="['sm:w-[36rem] md:w-[48rem] lg:w-[56rem]']"
    :show-modal="showModal"
    :show-icon="false"
    :close-modal="closeModal"
  >
    <template #header>
      <div class="text-xl flex items-center justify-center gap-x-2">
        {{ catalogueItem.name }}
        <div
          class="rounded-full border border-transparent hover:border-gray-200 hover:bg-gray-50 p-2 hover:text-primary text-gray-400 cursor-pointer"
        >
          <ClipboardCopyIcon
            v-tippy="{
              content: $t('button.clickToCopy', { link: 'link' }),
              delay: [200, 50],
              theme: 'time',
            }"
            class="h-5 w-5"
            @click="copyToClipboard"
          />
        </div></div
    ></template>
    <template #body>
      <div
        v-if="isApiLoading('catalogueItemLoading')"
        class="flex items-center"
      >
        <HivebuyLoader class="w-20 h-20" />
      </div>
      <div v-else class="modal-window">
        <div
          class="image-holder aspect-w-3 aspect-h-2 flex-1 flex items-center justify-center"
        >
          <HivebuyCarousal
            :key="itemToDisplay.id"
            :list="itemImages(itemToDisplay)"
            :num-visible="1"
            :show-indicators="false"
            :show-navigators="itemImages(itemToDisplay).length > 1"
          >
            <template #carouselItem="{ item }">
              <img
                class="object-cover p-2"
                :src="item.image"
                alt="catalogue item image"
              />
            </template>
          </HivebuyCarousal>
        </div>

        <div class="content-holder flex-1">
          <div class="page-title">
            <h3>{{ itemToDisplay.name }}</h3>
            <p class="text-sm">
              ({{ $t("catalogue.catalogueItems.itemQuickView.updated") }} @
              {{ formattedDate(detailedItem.updatedAt) }})
            </p>

            <section aria-labelledby="information-heading" class="mt-4">
              <h2 id="information-heading" class="sr-only">
                {{
                  $t(
                    "catalogue.catalogueItems.itemQuickView.productInformation"
                  )
                }}
              </h2>

              <div
                v-if="
                  !isItemVariantAlreadyAdded(itemToDisplay.id) &&
                  !isProductionEnvironment()
                "
                class="mb-4 border-t border-gray-200"
              >
                <div class="w-full">
                  <RecurrenceInterval
                    v-model="detailedItem.recurrence"
                    :full-width="true"
                  />
                </div>
              </div>

              <hr />

              <div class="grid grid-cols-2 gap-x-4 mt-2">
                <div class="price">
                  <i18n-n
                    :value="parseFloat(itemToDisplay.grossAmount)"
                    format="currency"
                    :locale="
                      currencyToLocale(itemToDisplay.grossAmountCurrency)
                    "
                  />
                  <span class="price-title">
                    {{
                      $t("catalogue.catalogueItems.itemQuickView.gross")
                    }}</span
                  >
                </div>
                <div class="price">
                  <i18n-n
                    :value="parseFloat(itemToDisplay.netAmount)"
                    format="currency"
                    :locale="currencyToLocale(itemToDisplay.netAmountCurrency)"
                  />
                  <span class="price-title">{{
                    $t("catalogue.catalogueItems.itemQuickView.net")
                  }}</span>
                </div>
              </div>

              <div v-if="itemToDisplay.minimalQuantity > 1">
                <span class="text-xs text-gray-500 font-light">
                  {{ $t("catalogue.catalogueItemForm.minimalOrderQuantity") }}:
                  <i18n-n
                    :value="parseFloat(itemToDisplay.minimalQuantity)"
                    format="decimal"
                    :locale="currencyToLocale(itemToDisplay.netAmountCurrency)"
                  />
                </span>
              </div>

              <!-- eslint-disable vue/no-v-html -->
              <p
                id="text-editor"
                class="description"
                v-html="itemToDisplay.description"
              />
              <hr />

              <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-y-4">
                <div class="flex">
                  <CollectionIcon
                    class="flex-shrink-0 w-5 h-5 text-primary"
                    aria-hidden="true"
                  />
                  <p class="ml-2 text-sm text-gray-500">
                    <Category :category="detailedItem.category" />
                  </p>
                </div>
                <div class="flex">
                  <GlobeAltIcon
                    class="flex-shrink-0 w-5 h-5 text-primary"
                    aria-hidden="true"
                  />
                  <p class="ml-2 text-sm text-gray-500">
                    <SupplierName
                      v-if="detailedItem.companySupplier"
                      :supplier="detailedItem.companySupplier"
                    />
                    <UnlistedSupplierBadge
                      v-else-if="detailedItem.unlistedSupplier"
                      :unlisted-supplier="detailedItem.unlistedSupplier"
                    />
                  </p>
                </div>
                <div
                  v-if="itemToDisplay.url"
                  class="text-primary col-span-full flex items-center"
                >
                  <ExternalLinkIcon
                    class="flex-shrink-0 w-4 h-4"
                    aria-hidden="true"
                  />
                  <a
                    class="focus:outline-none text-primary cursor-pointer text-sm mx-0 md:mx-3 truncate hover:underline"
                    :href="itemToDisplay.url"
                    target="_blank"
                  >
                    {{ itemToDisplay.url }}
                  </a>
                </div>
                <div
                  v-if="itemToDisplay.estimatedDelivery"
                  class="col-span-full flex items-center text-sm font-normal"
                >
                  {{ $t("catalogue.catalogueItemForm.estimatedDelivery") }}:
                  <span class="font-semibold ml-2">{{
                    itemToDisplay.estimatedDelivery
                  }}</span>
                </div>
              </div>
              <div
                v-if="itemToDisplay.files"
                class="flex flex-col gap-y-1 mt-4 group"
              >
                <a
                  v-for="file in itemToDisplay.files"
                  :key="file"
                  class="rounded text-sm hover:text-primary flex items-center gap-x-1"
                  target="_blank"
                  :href="file.file"
                >
                  <DocumentIcon class="h-5 w-5" />
                  {{ file.title }}
                  <ExternalLinkIcon
                    class="h-4 w-4 group-hover:flex text-gray-400 hidden"
                  />
                </a>
              </div>

              <CustomFields
                :custom-fields="itemToDisplay.customFields"
                :classes="'flex items-center text-sm font-normal mt-2 gap-x-2'"
              />
              <div
                v-if="catalogueItem.itemVariants?.length > 1"
                class="w-full mt-6"
              >
                <div
                  class="content-center flex items-center gap-x-2 justify-center flex-wrap gap-y-2"
                >
                  <div
                    v-for="(variant, index) in detailedItem.itemVariants"
                    :key="variant.id"
                    :class="[
                      'px-2 min-w-[3.5rem] h-8 border border-gray-300 flex rounded text-center align-middle !items-center justify-center',

                      variant.id === itemToDisplay.id
                        ? 'bg-primary !text-white'
                        : 'hover:border-primary hover:text-primary hover:bg-primary/10 cursor-pointer',
                    ]"
                    @click="() => handleDotClick(index)"
                  >
                    <span class="text-sm">{{
                      variant.shortName || index + 1
                    }}</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <template v-if="!isApiLoading('catalogueItemLoading')">
        <div class="flex justify-center">
          <Button
            id="cancel"
            full
            :on-click-handler="closeModal"
            :color="'gray'"
          >
            <template #icon><XIcon /></template>
            {{ $t("buttons.closeWindow") }}
          </Button>
        </div>
        <span v-if="!isUserOnlyAuditor">
          <div v-if="isItemValid" class="flex items-stretch">
            <div class="w-16">
              <Multiselect
                v-model="detailedItem.quantity"
                :searchable="false"
                :options="quantityOptions"
                :can-clear="false"
                class="w-full h-full quantity-quickview"
                :open-direction="'top'"
                :caret="false"
              />
            </div>
            <div class="flex-grow flex-shrink w-7/10">
              <Button
                id="add"
                full
                :on-click-handler="onAddToCart"
                :disabled="
                  !itemToDisplay.active ||
                  isItemVariantAlreadyAdded(itemToDisplay.id) ||
                  !allowSameCategoryAndSupplier(itemToDisplay)
                "
                class="w-full h-full border border-primary"
              >
                <template #icon><ShoppingCartIcon /></template>
                {{ $t("buttons.addToCart") }}
              </Button>
            </div>
          </div>
          <div v-else>
            <div
              class="text-gray-400 border border-gray-300 bg-gray-50 font-normal py-2.5 px-4 rounded flex justify-center items-center"
            >
              <XIcon class="mr-1 w-4 h-4 text-gray-400" />
              {{ $t("catalogue.catalogueItems.invalidItem") }}
            </div>
          </div>
        </span>
      </template>
    </template>
  </Modal>
</template>

<script>
import {
  catalogueMixin,
  catalogueItemMixin,
} from "@/components/mixins/index.js";
import Button from "@/components/shared/Button.vue";
import {
  Category,
  Modal,
  SupplierName,
  UnlistedSupplierBadge,
  HivebuyLoader,
} from "@/components/shared/index";
import {
  XIcon,
  CollectionIcon,
  GlobeAltIcon,
  ExternalLinkIcon,
  ShoppingCartIcon,
} from "@heroicons/vue/solid";
import { ClipboardCopyIcon, DocumentIcon } from "@heroicons/vue/outline";
import { currencyToLocale, isObjectEmpty } from "@/utils/utility_methods.js";
import { mapActions } from "vuex";
import CustomFields from "@/components/CustomFields/CustomFields.vue";
import { HivebuyCarousal } from "design-buy";
import { CATALOGUE_MODULE, GET_CATALOGUE_ITEM } from "@/store/Catalogue/types";
import Multiselect from "@vueform/multiselect";
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
export default {
  components: {
    Modal,
    XIcon,
    CollectionIcon,
    GlobeAltIcon,
    ExternalLinkIcon,
    ShoppingCartIcon,
    Button,
    Category,
    SupplierName,
    UnlistedSupplierBadge,
    CustomFields,
    HivebuyCarousal,
    HivebuyLoader,
    Multiselect,
    RecurrenceInterval,
    ClipboardCopyIcon,
    DocumentIcon,
  },
  mixins: [catalogueMixin, catalogueItemMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    catalogueItem: {
      type: Object,
      default: () => {},
    },
    addToCart: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      itemToDisplay: {},
      detailedItem: {},
    };
  },
  computed: {
    isItemValid() {
      return this.isValidCatalogueItem(this.detailedItem);
    },
    quantityOptions() {
      return Array.from({ length: 100 }, (_, index) => {
        return (
          parseFloat(this.itemToDisplay.minimalQuantity) +
          this.itemToDisplay.quantityStep * index
        );
      });
    },
  },
  watch: {
    catalogueItem: {
      async handler(val) {
        if (!isObjectEmpty(val)) {
          this.setApiLoading({ key: "catalogueItemLoading", value: true });
          this.detailedItem = await this.fetchCatalogueItem(val.id);

          if (!this.detailedItem.recurrence) this.detailedItem.recurrence = {};

          if (!this.detailedItem.quantity)
            this.detailedItem.quantity = parseFloat(
              this.detailedItem.itemVariants[0].minimalQuantity
            );
          this.setApiLoading({ key: "catalogueItemLoading", value: false });
          this.itemToDisplay = this.variantDetails(0);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(CATALOGUE_MODULE, {
      fetchCatalogueItem: GET_CATALOGUE_ITEM,
    }),
    currencyToLocale,
    copyToClipboard() {
      navigator.clipboard.writeText(window.location.href).then(
        () => {
          this.showNotification(this.$t("toasts.copySuccess"));
        },
        (error) => {
          this.showErrorMessage(error);
        }
      );
    },
    handleDotClick(index) {
      this.itemToDisplay = this.variantDetails(index);
      if (!this.detailedItem.quantity) this.detailedItem.quantity = 1;
    },
    onAddToCart() {
      const variantIndex = this.detailedItem.itemVariants.findIndex(
        (variant) => variant.id === this.itemToDisplay.id
      );
      this.addToCart(this.detailedItem, variantIndex);
    },
    isItemVariantAlreadyAdded(variantId) {
      if (!this.newRequest?.items?.length) return false;

      const item = this.newRequest.items.find((item) =>
        [item.catalogueItemVariant, item.catalogueItemVariant?.id].includes(
          variantId
        )
      );
      return Boolean(item);
    },
    variantDetails(variantIndex) {
      return {
        ...this.detailedItem.itemVariants[variantIndex],
        category: this.detailedItem.category,
        companySupplier: this.detailedItem.companySupplier,
        unlistedSupplier: this.detailedItem.unlistedSupplier,
      };
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/editor.css";

.modal-window {
  @apply relative flex;
}

.close-button {
  @apply cursor-pointer w-5 text-gray-500 absolute -right-2 -top-4;
}

.image-holder {
  @apply rounded-lg border;
}

.content-holder {
  @apply flex flex-col ml-4;
}
.content-holder .page-title h1 {
  @apply text-2xl font-bold text-primary;
}

.content-holder .price {
  @apply text-lg text-gray-900 font-bold;
}
.content-holder .price-title {
  @apply text-sm text-gray-500 ml-2;
}
.content-holder .saperator {
  @apply border-l border-gray-200 mx-4;
}

.content-holder .description {
  @apply text-base text-gray-500 mt-2 mb-4 font-normal;
}

.carousel-dots {
  @apply gap-x-2;
  display: flex;
  justify-content: center;
}

.quantity-quickview {
  @apply border-r-0 -mr-1 rounded-r-none border-primary;
}

:deep(.multiselect-single-label) {
  @apply px-2 flex w-full justify-center;
}
</style>
