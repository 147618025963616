import { APPROVAL_STATUS_MAPPING } from "@/utils/constants";

export default {
  methods: {
    checkApprovals(object) {
      return (
        object.approvals &&
        object.approvals.length &&
        !object.approvals.some((approval) =>
          [APPROVAL_STATUS_MAPPING.REJECTED].includes(approval.status)
        )
      );
    },
  },
};
