<template>
  <div class="flex items-center place-content-center text-hivebuy_plum m-1">
    <div
      v-tippy="{
        content: $t('invoice.ocr.loading'),
        placement: 'bottom',
      }"
      class="button-action flex items-center"
    >
      <SpinningLoaderSVG class="h-5 w-5 mr-1" />
      {{ $t("general.processing") }}
    </div>
  </div>
</template>
<script>
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: {
    SpinningLoaderSVG,
  },
});
</script>
