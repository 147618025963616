<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4"
    :class="!isInModal ? 'lg:grid-cols-3 xl:grid-cols-4' : ''"
  >
    <div v-for="field in displayFields" :key="field.id">
      <template v-if="field.type === 'TEXT'">
        <Input
          :id="field.name"
          :value="field.value"
          :name="field.name"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="field.placeHolder"
          :label="field.label"
          :is-mandatory="field.mandatoryField"
        />
      </template>
      <template v-else-if="field.type === 'BOOLEAN'">
        <SwitchCheckbox
          v-model="field.value"
          class="mt-4"
          :label="field.label"
          :is-mandatory="field.mandatoryField"
          @update:model-value="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <template v-else-if="field.type === 'PRICE'">
        <CurrencyInput
          v-model="field.value"
          :label="field.label"
          :placeholder="field.placeHolder"
          :currency-value="field.currency"
          :is-mandatory="field.mandatoryField"
          @update:model-value="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <template v-else-if="field.type === 'SELECT'">
        <div class="label">
          {{ field.label
          }}<span v-if="field.mandatoryField" class="text-hivebuy-red ml-0.5"
            >*</span
          >
        </div>
        <Multiselect
          v-model="field.value"
          :options="field.selectFields"
          :placeholder="field.placeHolder"
          :searchable="true"
          :can-clear="false"
          :track-by="'value'"
          @change="
            (value) =>
              onAttributeChange({ target: { name: field.name, value } })
          "
        />
      </template>
      <FieldError v-if="errorMessage(field)" :text="errorMessage(field)" />
    </div>
  </div>
</template>

<script>
import { Input, SwitchCheckbox } from "@/components/shared";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import Multiselect from "@vueform/multiselect";
import FieldError from "@/components/shared/FieldError.vue";
import { isObjectEmpty } from "@/utils/utility_methods";
export default {
  name: "CustomFieldForm",
  components: { CurrencyInput, Input, SwitchCheckbox, Multiselect, FieldError },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
    validationObject: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue"],
  computed: {
    fields: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    displayFields() {
      return this.fields?.filter((field) => !field.readOnly);
    },
  },

  methods: {
    errorMessage(field) {
      if (isObjectEmpty(this.validationObject)) return;

      const index = this.fields.findIndex((item) => item.name === field.name);
      return this.validationObject.$each.$response.$errors?.[index]?.value[0]
        ?.$message;
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      const updatedFields = this.fields.map((field) => {
        if (field.name === name) {
          return { ...field, value };
        }
        return field;
      });
      this.fields = [...updatedFields];
    },
  },
};
</script>
