<template>
  <div class="flex flex-col sm:flex-row justify-start items-center">
    <div
      v-for="(datePicker, index) in datePickers"
      :key="index"
      class="relative flex-grow mr-4 cursor-pointer"
    >
      <DatePicker
        :model-value="modelValue[datePicker.field]"
        :masks="defaultDateMask"
        :popover="{ visibility: 'click' }"
        :min-date="datePicker.minDate"
        :max-date="datePicker.maxDate"
        @update:model-value="(val) => updateDate(datePicker.field, val)"
      >
        <template #default="{ inputValue, inputEvents }">
          <div class="relative flex-grow cursor-pointer">
            <label class="inline-flex text-sm font-medium text-gray-700 my-1">
              {{ datePicker.label }}
              <span v-if="datePicker.isMandatory" class="text-red-600 ml-0.5">
                *
              </span>
              <span v-if="datePicker.tooltipText" class="ml-1">
                <Tip help theme="tooltip" placement="right">
                  <template #header>
                    {{ datePicker.label }}
                  </template>
                  <template #content>
                    <div class="max-w-md">
                      <p class="tooltip-subtext">
                        {{ datePicker.tooltipText }}
                      </p>
                    </div>
                  </template>
                </Tip>
              </span>
            </label>
            <svg
              :class="[
                'text-gray-600 w-4 mx-2 top-8 absolute pointer-events-none',
                {
                  'mt-1.5': !datePicker.tooltipText,
                  'mt-3': datePicker.tooltipText,
                },
              ]"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              ></path>
            </svg>
            <input
              :value="inputValue"
              :class="[
                'block w-full pl-8 pr-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm disabled:bg-gray-100',
                {
                  'border-red-400': datePicker.validationError,
                },
              ]"
              :placeholder="datePicker.placeholder"
              v-on="inputEvents"
            />
            <FieldError
              v-if="datePicker.validationError"
              :text="'Please enter value for this field'"
            />
          </div>
        </template>
      </DatePicker>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import FieldError from "@/components/shared/FieldError";
import {
  defaultDateAttributes,
  getYesterdayDate,
} from "@/utils/utility_methods";
import "v-calendar/dist/style.css";

export default {
  components: {
    DatePicker,
    FieldError,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    validationError: {
      type: Boolean,
      default: false,
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    startDateTooltipText: {
      type: String,
      default: "",
    },
    endDateTooltipText: {
      type: String,
      default: "",
    },
    startDateBeforeToday: {
      type: Boolean,
      default: false,
    },
    fieldConfig: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  computed: {
    defaultDatePicker() {
      return [
        {
          field: "start",
          minDate: null,
          label: this.$t("global.dates.startDate"),
          isMandatory: this.isMandatory,
          tooltipText: this.startDateTooltipText,
          placeholder: this.$t("global.dates.startDate"),
          validationError: this.validationError,
          maxDate: this.startDateBeforeToday
            ? getYesterdayDate()
            : this.maxDate,
        },
        {
          field: "end",
          minDate: this.minDate,
          label: this.$t("global.dates.endDate"),
          isMandatory: this.isMandatory,
          tooltipText: this.endDateTooltipText,
          placeholder: this.$t("global.dates.endDate"),
          validationError: this.validationError,
          maxDate: null,
        },
      ];
    },
    datePickers() {
      if (!this.fieldConfig.length) return this.defaultDatePicker;
      return this.defaultDatePicker.map((datePicker, index) => {
        return Object.assign({}, datePicker, this.fieldConfig[index]);
      });
    },
    minDate() {
      return this.modelValue?.[this.minDateField]
        ? new Date(this.modelValue[this.minDateField])
        : null;
    },
    maxDate() {
      return this.modelValue?.[this.maxDateField]
        ? new Date(this.modelValue[this.maxDateField])
        : null;
    },
    minDateField() {
      if (!this.datePickers) return "start";

      return this.datePickers[0].field;
    },
    maxDateField() {
      if (!this.datePickers) return "end";

      return this.datePickers[this.datePickers?.length - 1].field;
    },
  },
  methods: {
    defaultDateAttributes,
    updateDate(key, value) {
      const updateObject = { ...this.modelValue, [key]: value };
      if (JSON.stringify(this.modelValue) == JSON.stringify(updateObject))
        return;
      this.$emit("update:modelValue", updateObject);
    },
  },
};
</script>
