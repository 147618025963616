import {
  COMPANY_MANAGEMENT_MODULE,
  GET_DEPARTMENTS,
} from "@/store/CompanyManagement/types";
import { mapActions, mapState } from "vuex";
import debounceMixin from "@/components/mixins/debounceMixin";
import { removeKeysFromObject } from "@/utils/utility_methods";
import { mapGetters } from "vuex";

export default {
  mixins: [debounceMixin],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departmentPaginationParams"]),
    shouldExecutePaginationApi() {
      return (
        this.departmentPaginationParams.pageSize <
        this.departmentPaginationParams.totalDbRecords
      );
    },
    searchDebounceTimeout() {
      return this.shouldExecutePaginationApi ? 1000 : 0;
    },
  },
  mounted() {
    const loadingState =
      this.$options.name === "DepartmentsList"
        ? "departments"
        : "departmentsDropDownList";
    if (this.departmentsList().length || this.isApiLoading(loadingState))
      return;

    this.setApiLoading({ key: loadingState, value: true });
    this.getDepartments(this.departmentPaginationParams)
      .catch(() => this.showErrorMessage())
      .finally(() => {
        this.setApiLoading({ key: loadingState, value: false });
      });
  },

  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getDepartments: GET_DEPARTMENTS,
    }),
    onSearchUpdate(key, value) {
      const loadingState =
        this.$options.name === "DepartmentsList"
          ? "departments"
          : "departmentsDropDownList";
      if (
        this.shouldExecutePaginationApi &&
        value !== this.departmentPaginationParams.search
      ) {
        const paginationParams = removeKeysFromObject(
          this.departmentPaginationParams,
          ["totalDbRecords"]
        );
        this.setApiLoading({ key: loadingState, value: true });
        const updatedParams = Object.assign({}, paginationParams, {
          page: 1,
          [key]: value,
        });
        this.getDepartments(updatedParams).then(() =>
          this.setApiLoading({ key: loadingState, value: false })
        );
      }
      this.search = value;
    },
  },
};
