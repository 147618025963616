<template>
  <div class="custom-card no-shadow hover:shadow">
    <Transition>
      <div class="-m-5 relative" :class="collapsed ? '' : 'group'">
        <div
          class="absolute sm:inset-y-0 right-0 top-0 sm:bottom-0 bg-white/20 backdrop-blur-md rounded-lg px-2 py-2"
          :class="
            collapsed
              ? 'hidden'
              : 'sm:hidden sm:flex-col sm:justify-between sm:group-hover:flex flex gap-x-1'
          "
        >
          <div
            class="arrow-holder flex"
            :class="collapsed ? 'rotate-180' : ''"
            @click.stop="toggleCollapse"
          >
            <ChevronDownIcon class="toggle-arrow" />
          </div>
          <div class="arrow-holder flex" @click="() => deleteProduct(index)">
            <TrashIcon class="text-hivebuy-red w-5 h-5 transition-all" />
          </div>
        </div>

        <div
          :class="collapsed ? 'hide-product' : 'show-product'"
          class="product-collapse grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 lg:gap-x-4 gap-x-2 gap-y-4 lg:gap-y-0 text-gray-600"
        >
          <div class="col-span-2 sm:col-span-1 md:col-span-2">
            <h3 class="truncate font-semibold text-primary">
              {{ details.name }}
            </h3>
            <p class="description line-clamp-2 text-sm">
              {{ details.description }}
            </p>
          </div>
          <div class="flex items-center">
            <QuantitySVG
              v-tippy="{
                content: 'Quantity',
                delay: [100, 200],
                theme: 'time',
              }"
              class="h-5 w-5 mr-2 outline-none focus:outline-none"
            />
            <span class="font-medium">{{ details.quantity }}</span>
          </div>
          <div class="flex items-center mt-1">
            <div
              v-if="details.needByDate"
              v-tippy="{
                content: 'Need by date',
                delay: [100, 200],
                theme: 'time',
              }"
              class="text-sm px-2 py-0.5 border border-gray-200 bg-gray-100 rounded-lg max-w-[100px] flex items-center justify-between"
            >
              <TruckIcon class="w-4 h-4 text-hivebuy-red mr-1" />
              {{ formattedDate(details.needByDate) }}
            </div>
          </div>
          <div>
            <RequestedForDisplay
              v-if="details.requestedFor"
              :product="details"
              :read-only="readOnly"
              :avatar-dimension="8"
              show-name
            />
          </div>
          <div class="text-sm">
            <RequestAddress
              :address-id="details?.deliveryAddress?.id"
              :show-title="false"
            />
          </div>
        </div>
        <div
          :class="collapsed ? 'show-form' : 'hide-form'"
          class="shopping-form"
        >
          <Header
            :close-form="toggleCollapse"
            :details="details"
            :change-step="changeStep"
            :current-step="currentStep"
            :error-fields="errorsList"
          />

          <Form
            :product="productDetails(index)"
            :index="index"
            :set-value="setValue"
            :current-step="currentStep"
            :error-object="v$"
          />
          <div class="form-nav">
            <div
              v-if="!steps.basic"
              class="prev flex items-center justify-start px-2.5 py-1 border sm:border-transparent md:hover:border-hivebuy-grey rounded transition-all text-hivebuy-grey hover:text-gray-600 border-primary"
              @click="() => changeStep(false, true)"
            >
              <ArrowCircleLeftIcon class="mr-1 h-5 w-5" />
              <span class="hidden sm:block">{{
                $t("shoppingList.buttons.back")
              }}</span>
            </div>
            <div
              class="next col-start-2 flex items-center justify-center px-2.5 py-1 border sm:border-transparent md:hover:border-primary rounded md:hover:bg-primary transition-all border-primary"
              @click="() => finished()"
            >
              <span class="hidden sm:block">
                {{ $t("shoppingList.buttons.finished") }}
              </span>
              <CheckCircleIcon class="ml-1 h-5 w-5" />
            </div>
            <div
              v-if="currentStep != 'address'"
              class="next flex items-center justify-end px-2.5 py-1 border sm:border-transparent md:hover:border-primary rounded md:hover:bg-primary transition-all border-primary"
              @click="() => changeStep()"
            >
              <span class="hidden sm:block">{{
                $t("shoppingList.buttons.next")
              }}</span>
              <ArrowCircleRightIcon class="ml-1 h-5 w-5" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script>
import { required } from "@vuelidate/validators";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import useValidate from "@vuelidate/core";
import {
  ChevronDownIcon,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  TruckIcon,
  TrashIcon,
  CheckCircleIcon,
} from "@heroicons/vue/outline";
import QuantitySVG from "@/assets/images/svg/QuantitySVG.vue";
import { Header, Form } from "@/components/ShoppingList/index.js";
import {
  SHOPPING_LIST_MODULE,
  SAVE_PRODUCT,
  REMOVE_PRODUCT,
} from "@/store/ShoppingList/types.js";
import { mapMutations, mapGetters } from "vuex";
import RequestAddress from "@/components/shared/RequestAddress.vue";

export default {
  name: "Product",
  components: {
    Header,
    Form,
    ChevronDownIcon,
    ArrowCircleRightIcon,
    ArrowCircleLeftIcon,
    RequestedForDisplay,
    QuantitySVG,
    TruckIcon,
    TrashIcon,
    CheckCircleIcon,
    RequestAddress,
  },
  props: {
    index: { type: Number, required: true },
    open: { type: Boolean, default: false },
    manageValidation: { type: Function, required: true },
  },

  data() {
    return {
      v$: useValidate(),
      steps: {
        basic: true,
        requestedFor: false,
        quantity: false,
        date: false,
        address: false,
      },
      collapsed: this.open,
      details: {},
      readOnly: false,
    };
  },
  computed: {
    ...mapGetters(SHOPPING_LIST_MODULE, ["productDetails"]),
    errorsList() {
      return this.v$.$errors.map((errorObject) => errorObject.$property);
    },
    currentStep() {
      let step = null;
      let stepString, stepValue;
      Object.entries(this.steps).findIndex((entry) => {
        stepString = entry[0];
        stepValue = entry[1];
        if (stepValue) step = stepString;
      });
      return step;
    },
  },
  watch: {
    index: {
      immediate: true,
      handler(val) {
        this.details = this.productDetails(val);
      },
    },
  },
  methods: {
    ...mapMutations(SHOPPING_LIST_MODULE, {
      saveProduct: SAVE_PRODUCT,
      deleteProduct: REMOVE_PRODUCT,
    }),
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    finished() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.toggleCollapse();
      }
    },
    async validateProduct() {
      await this.v$.$validate();
      this.manageValidation(this.index, !this.v$.$error);
    },
    setValue(event) {
      const { name, value } = event.target;
      this.details[name] = value;
      this.saveProduct({ index: this.index, data: this.details });
    },
    changeStep(next = true, back = false, step = null) {
      const stepKeys = Object.keys(this.steps);
      let stepString = step;
      if (!step) {
        let index = Object.values(this.steps).findIndex((value) => value);
        if (next && index + 1 < stepKeys.length) index += 1;
        if (back && index > 0) index -= 1;
        stepString = stepKeys[index];
      }
      stepKeys.forEach((key) => {
        this.steps[key] = key == stepString;
      });
    },
  },
  validations() {
    return {
      details: {
        name: { required },
        requestedFor: { required },
        quantity: { required },
        deliveryAddress: { required },
        invoiceAddress: { required },
      },
    };
  },
};
</script>
<style scoped>
.form-nav {
  @apply w-full max-w-[80%] m-auto mb-6 mt-4 grid grid-cols-3 gap-x-4 md:gap-x-8;
}
.form-nav .prev,
.form-nav .next {
  @apply text-sm font-semibold cursor-pointer;
}
.form-nav .next {
  @apply text-primary hover:text-white;
}

.product-collapse {
  @apply w-full items-center p-5 overflow-hidden transition-all duration-300;
}

.product-collapse .product-collapse-left {
  @apply flex;
}

.product-collapse .product-collapse-left .product-content {
  @apply flex gap-x-4 items-center;
}

.product-collapse .product-collapse-left .product-content h3 {
  @apply text-base font-semibold text-primary mb-0 w-72 truncate;
}

.product-collapse .product-collapse-left .product-content .description {
  @apply text-xs text-gray-500;
}

.product-collapse .product-collapse-right {
  @apply flex gap-x-4 items-center mr-12;
}

.product-collapse .product-collapse-right .price {
  @apply font-semibold text-primary;
}
.product-collapse .product-collapse-right .quantity {
  @apply font-semibold text-primary;
}
.product-collapse .product-collapse-right .quantity span {
  @apply text-sm text-gray-500 mr-0.5;
}

.shopping-form {
  @apply overflow-hidden transition-[max-height] duration-300;
}

.arrow-holder {
  @apply bg-white border border-gray-200 w-8 h-8 rounded-full justify-center items-center  hover:border-primary z-50 transition-all duration-300 cursor-pointer;
}

.toggle-arrow {
  @apply w-5 h-5 transition-all group-hover:text-primary;
}

.hide-product {
  @apply py-0;
  max-height: 0;
}

.show-product {
  @apply py-5;
  max-height: 200;
}

.show-form {
  max-height: 800px;
  overflow: visible;
}

.hide-form {
  max-height: 0;
}
</style>
