import store from "@/store/index.js";

import { CATALOGUE_MODULE } from "@/store/Catalogue/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { CONTRACT_MODULE } from "@/store/Contract/types";
import { INVOICE_MODULE } from "@/store/Invoice/types";
import { PROJECT_MODULE } from "@/store/Project/types";
import { PROPOSAL_MODULE } from "@/store/Proposal/types";
import { PURCHASE_ORDER_MODULE } from "@/store/PurchaseOrder/types";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";

const ALL_MODULES = [
  CATALOGUE_MODULE,
  COMPANY_MANAGEMENT_MODULE,
  CONTRACT_MODULE,
  INVOICE_MODULE,
  PROJECT_MODULE,
  PROPOSAL_MODULE,
  PURCHASE_ORDER_MODULE,
  PURCHASE_REQUEST_MODULE,
];

/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
export default {
  methods: {
    getStoreValue(moduleName, key, valueToFind = null, valueToFindBy = "id") {
      this.evaluateModule(moduleName);
      const target = store.state[moduleName][key];
      if (target && !valueToFind) return target;
      else if (target) {
        return this.methodToCall(target)(target, valueToFind, valueToFindBy);
      }
      return null;
    },
    evaluateModule(moduleName) {
      if (ALL_MODULES.indexOf(moduleName) < 0) throw "Module not found";
    },
    methodToCall(target) {
      if (this.isString(target)) return this.simpleString;
      else if (this.isArray(target)) return this.searchArray;
      else return this.searchObject;
    },
    isArray(value) {
      return Array.isArray(value);
    },
    isString(value) {
      return typeof value == "string";
    },
    simpleString(target) {
      return target;
    },
    searchArray(array, valueToFind, valueToFindBy) {
      return array.find((elem) => elem[valueToFindBy] == valueToFind);
    },
    searchObject(object, valueToFind) {
      return object[valueToFind];
    },
  },
};
