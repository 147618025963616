<template>
  <ProjectForm
    v-if="showProjectData"
    ref="projectForm"
    :on-save="onSave"
    :project-to-edit="project"
  />
  <div v-else class="loader-container">
    <HivebuyLoader class="h-36" />
  </div>
</template>

<script>
import ProjectForm from "@/components/Projects/ProjectForm";
import { mapActions } from "vuex";
import {
  GET_PROJECT,
  PROJECT_MODULE,
  UPDATE_PROJECT,
} from "@/store/Project/types";
import { isObjectEmpty } from "@/utils/utility_methods";
import { HivebuyLoader } from "@/components/shared";
import { budgetPercentageByNumber } from "@/utils/helpers/projectHelper";

export default {
  components: {
    ProjectForm,
    HivebuyLoader,
  },
  data() {
    return {
      loading: false,
      project: {},
    };
  },
  computed: {
    showProjectData() {
      return !(this.loading && isObjectEmpty(this.project));
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchProject();
    }
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProject: GET_PROJECT,
      updateProject: UPDATE_PROJECT,
    }),
    fetchProject() {
      this.loading = true;
      this.getProject(this.$route.params.id)
        .then((data) => this.setProjectDetails(data))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    onSave(project) {
      this.$refs.projectForm.v$.$validate();
      if (!this.$refs.projectForm.v$.$error) {
        project = {
          ...project,
          approvers: [project.approvers],
          budgetOwners: [project.budgetOwners],
        };
        this.$refs.projectForm.toggleLoading();
        this.updateProject({ projectId: project.id, payload: project })
          .then(() => {
            this.$router.push({ name: "Projects" });
            this.showNotification(
              "Project has been updated successfully",
              "success"
            );
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => this.$refs.projectForm.toggleLoading());
      }
    },
    setProjectDetails(data) {
      const {
        id,
        name,
        description,
        projectNumber,
        approvers,
        budgetOwners,
        participatingDepartments,
        totalProjectBudget,
        totalProjectBudgetCurrency,
        costCenter,
        projectApprovalFlowType,
        budget,
        legalEntity,
      } = data;

      const departments = participatingDepartments.map((d) => {
        return {
          department: d.department?.id || d.department,
          budgetParticipation: parseFloat(d.budgetParticipation),
          budgetPercentage: budgetPercentageByNumber(
            parseFloat(d.budgetParticipation),
            parseFloat(totalProjectBudget)
          ),
        };
      });

      this.project = {
        id,
        name,
        description,
        projectNumber,
        approvers: approvers[0],
        budgetOwners: budgetOwners[0],
        includeDepartmentsHead: false,
        participatingDepartments: departments,
        totalProjectBudget: parseFloat(totalProjectBudget),
        totalProjectBudgetCurrency,
        costCenter,
        projectApprovalFlowType,
        budget,
        legalEntity,
      };
    },
  },
};
</script>

<style scoped>
.loader-container {
  @apply flex items-center justify-between;
  height: calc(100vh - 160px);
}
</style>
