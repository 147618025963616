<template>
  <div id="budget">
    <h3 class="page-title">Budget Management</h3>

    <div class="grid grid-cols-3 sm:grid-cols-3 radio-holder gap-4">
      <div class="col-start-1 sm:col-start-1">
        <RadioGroup v-model="budgetYear">
          <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
            <RadioGroupOption
              v-for="year in years"
              :key="year"
              v-slot="{ checked, active }"
              as="template"
              :value="year.value"
              class="cursor-pointer"
            >
              <div
                :class="[
                  checked ? 'radio-option-active' : 'border-gray-300',
                  active ? 'radio-option-active' : '',
                  'radio-option',
                ]"
              >
                <div class="radio-title">
                  <RadioGroupLabel as="span" class="text-base font-semibold">
                    {{ year.value }}
                  </RadioGroupLabel>
                  <RadioGroupDescription
                    as="span"
                    class="mt-1 flex items-center text-sm text-gray-500"
                  >
                    {{ displayBudgetDates(year.value) }}
                  </RadioGroupDescription>
                </div>

                <CheckCircleIcon
                  v-if="checked"
                  class="h-5 w-5 text-primary checked"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>

      <div class="col-start-3 sm:col-start-3">
        <div class="rounded-md">
          <div class="label !text-primary">
            {{ $t("legalTendency.title") }}
          </div>
          <Multiselect
            v-model="legalEntity"
            :searchable="true"
            :options="legalTendencyList"
            :value-prop="'id'"
            :label="'name'"
            :track-by="'id'"
            :can-clear="false"
            :placeholder="$t('legalTendency.title')"
          />
        </div>
      </div>
    </div>

    <div class="overflow-hidden">
      <div class="tabs-holder">
        <div class="border-b border-gray-200 flex justify-between">
          <nav class="tabs" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              :key="tab.name"
              :class="[
                { 'tab--active': tab.component === currentTab },
                'tab group',
              ]"
              @click="() => onTabChange(tab.component)"
            >
              <span>{{ tab.name }}</span>
            </a>
          </nav>
        </div>
      </div>
      <div class="content-holder">
        <component
          :is="currentTab"
          :year="budgetYear"
          :is-loading="isLoading"
          :type="currentTab"
          :get-budgets="fetchBudgets"
          :select-tab="onTabChange"
          :legal-entity="legalEntity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Department from "@/components/CompanyManagement/Budgets/Department";
import Company from "@/components/CompanyManagement/Budgets/Company";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_BUDGETS,
  GET_USERS,
} from "@/store/CompanyManagement/types";
import User from "@/components/CompanyManagement/Budgets/User";

import { CheckCircleIcon } from "@heroicons/vue/solid";
import Multiselect from "@vueform/multiselect";
import {
  formatDateInHyphenFormat,
  getCurrentYear,
  isObjectEmpty,
} from "@/utils/utility_methods";

export default {
  components: {
    Department,
    Company,
    User,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
    Multiselect,
  },
  data() {
    return {
      isLoading: false,
      budgetYear: getCurrentYear(),
      years: [
        {
          title: getCurrentYear(),
          value: getCurrentYear(),
        },
        {
          title: getCurrentYear() + 1,
          value: getCurrentYear() + 1,
        },
      ],
      budgetDates: {},
      currentTab: "Department",
      legalEntity: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["budgets"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
    tabs() {
      return [
        {
          component: "Department",
          name: this.$tc("global.department", 2),
        },
        {
          component: "Company",
          name: this.$t("global.company"),
        },
        {
          component: "User",
          name: this.$tc("global.user", 2),
        },
      ];
    },
  },
  watch: {
    budgetYear: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchBudgets();
        }
      },
    },
    legalEntity: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchBudgets();
        }
      },
    },
    company: {
      handler(val) {
        if (!isObjectEmpty(val)) {
          this.fetchBudgets();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUsers: GET_USERS,
      getBudgets: GET_BUDGETS,
    }),
    fetchUsers() {
      this.isLoading = true;
      this.getUsers()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    fetchBudgets() {
      this.isLoading = true;
      this.budgetDates = this.budgetDatesByYear(this.budgetYear);
      const filteredParams = this.filterParams();

      this.getBudgets(filteredParams)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
    filterParams() {
      const params = {
        valid_from: formatDateInHyphenFormat(this.budgetDates.validFrom),
        valid_to: formatDateInHyphenFormat(this.budgetDates.validTo),
      };

      if (!this.isProductionEnvironment()) {
        params.legal_entity = this.legalEntity || "";
      }
      return params;
    },
    onTabChange(tab = "department") {
      this.currentTab = tab;
    },
    budgetDatesByYear(year) {
      if (year === getCurrentYear()) {
        return {
          validFrom: this.company.budgetValidFrom,
          validTo: this.company.budgetValidTo,
        };
      }

      const validFrom = new Date(this.company.budgetValidTo);
      validFrom.setDate(validFrom.getDate() + 1);

      const validTo = new Date(this.company.budgetValidTo);
      validTo.setFullYear(validTo.getFullYear() + 1);

      return { validFrom, validTo };
    },
    displayBudgetDates(year) {
      const { validFrom, validTo } = this.budgetDatesByYear(year);
      return `${this.formattedDate(validFrom)} - ${this.formattedDate(
        validTo
      )}`;
    },
  },
};
</script>

<style scoped>
.radio-holder {
  @apply mb-4;
}
.radio-option {
  @apply bg-white rounded-md border border-gray-200 px-4 py-2 relative box-border;
}

.radio-option-active {
  @apply bg-white rounded border-2 border-primary;
}

.radio-option-active .radio-title {
  @apply text-primary;
}

.checked {
  @apply absolute right-2 top-2;
}
</style>
