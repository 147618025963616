<template>
  <CardSection
    :loading="loading"
    :records="actionRequiredPurchaseOrders"
    :title="$t('dashboard.headerMyPurchaseOrders')"
    :total-records-count="totalRecords"
  >
    <template #col-tickets-content>
      <ul>
        <li v-for="order in actionRequiredPurchaseOrders" :key="order.id">
          <div class="ticket purchase-order">
            <router-link
              :to="{
                name: 'RequestDetail',
                params: { id: order.purchaseRequest.id },
              }"
            >
              <div class="ticket-header group cursor-pointer">
                <div class="ticket-header-left">
                  <div class="ticket-title">
                    {{ order.purchaseRequest.title }}
                  </div>
                  <div class="ticket-meta">
                    <span>
                      {{ order.purchaseRequest.externalId }}
                    </span>
                    <span>-</span>
                    <span>
                      {{ formattedDate(order.purchaseRequest.requestedAt) }}
                    </span>
                  </div>
                </div>
                <div
                  class="ticket-header-right text-primary transition duration-300 group-hover:border-primary group-hover:bg-primary group-hover:text-white"
                >
                  <ChevronRightIcon class="w-4 h-4" />
                </div>
              </div>
            </router-link>
            <div class="ticket-footer">
              <div class="status">
                <div class="status-text">
                  {{ $t("ordersDashboard.ordered") }}
                </div>
                <CheckCircleIcon
                  v-if="isStatusCompleted(order, 'O')"
                  class="w-4 h-4 text-hivebuy-green"
                />
                <ExclamationCircleIcon
                  v-else
                  class="w-4 h-4 text-hivebuy-yellow opacity-70"
                />
              </div>
              <div class="status">
                <div class="status-text">
                  {{ $t("ordersDashboard.delivered") }}
                </div>
                <CheckCircleIcon
                  v-if="isStatusCompleted(order, 'D')"
                  class="w-4 h-4 text-hivebuy-green"
                />
                <ExclamationCircleIcon
                  v-else
                  class="w-4 h-4 text-hivebuy-yellow opacity-70"
                />
              </div>
              <div class="status">
                <div class="status-text">
                  {{ $t("ordersDashboard.invoiced") }}
                </div>
                <CheckCircleIcon
                  v-if="order.purchaseOrderInvoices.length"
                  class="w-4 h-4 text-hivebuy-green"
                />
                <ExclamationCircleIcon
                  v-else
                  class="w-4 h-4 text-hivebuy-yellow opacity-70"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </CardSection>
</template>

<script>
import {
  ChevronRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/outline";
import { mapActions, mapState } from "vuex";
import {
  GET_ACTION_REQUIRED_PURCHASE_ORDERS,
  PURCHASE_ORDER_MODULE,
} from "@/store/PurchaseOrder/types";
import CardSection from "@/components/Dashboard/AdvanceUserDashboard/CardSection";
import { isObjectEmpty } from "@/utils/utility_methods";

export default {
  name: "ActionablePurchaseOrders",
  components: {
    ChevronRightIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    CardSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_ORDER_MODULE, {
      actionRequiredPurchaseOrders: (state) =>
        state.actionRequiredPurchaseOrders.results,
      totalRecords: (state) => state.actionRequiredPurchaseOrders.totalRecords,
    }),
  },
  mounted() {
    isObjectEmpty(this?.actionRequiredPurchaseOrders) &&
      this.fetchPurchaseOrders();
  },
  methods: {
    ...mapActions(PURCHASE_ORDER_MODULE, {
      getActionRequiredPurchaseOrders: GET_ACTION_REQUIRED_PURCHASE_ORDERS,
    }),
    fetchPurchaseOrders() {
      this.loading = true;
      this.getActionRequiredPurchaseOrders(this.paginationParams())
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    paginationParams() {
      return {
        pageSize: 8,
        hideClosed: true,
      };
    },
    isStatusCompleted(order, status) {
      return !!order.orderStatuses.find((s) => s.status == status);
    },
  },
};
</script>

<style scoped>
.ticket.purchase-order .ticket-header::before {
  @apply bg-primary;
}

.ticket.purchase-order .ticket-title {
  @apply text-primary;
}

.ticket.purchase-order .ticket-footer {
  @apply bg-white items-center;
}

.ticket.purchase-order .ticket-footer .status {
  @apply flex flex-1 text-xs gap-x-1 text-gray-600 items-center;
}

.ticket.purchase-order .ticket-footer .status .status-text {
  @apply line-clamp-1;
}
</style>
