<template>
  <div>
    <div class="block mt-2">
      <span>Min:</span>
      <CurrencyInput
        id="min-currency-input"
        v-model.lazy="amount.minValue"
        :label="$t('workflow.editor.amount')"
        placeholder="0"
      />
    </div>
    <div class="block mt-2">
      <span>Max:</span>
      <CurrencyInput
        id="max-currency-input"
        v-model.lazy="amount.maxValue"
        :label="$t('workflow.editor.amount')"
        placeholder="0"
      />
    </div>
  </div>
</template>

<script>
import CurrencyInput from "@/components/shared/CurrencyInput";
import workflowMixin from "@/components/mixins/workflowMixin";
export default {
  components: {
    CurrencyInput,
  },
  mixins: [workflowMixin],
  data() {
    const { amount } = this.workflow.conditions;
    return {
      amount: (amount && Object.assign({}, amount)) || {},
    };
  },
};
</script>
