<template>
  <div v-if="!isInvoiceLoading">
    <div class="flex flex-col items-start py-2 px-4">
      <h3 class="text-lg text-primary font-semibold">
        {{ invoiceDetails?.invoiceNumber }}
      </h3>
      <p class="text-sm font-normal text-gray-500">
        {{ $t("invoice.uploadedAt") }}
        <TimeDisplay
          :time="invoiceDetails.createdAt"
          :show-time-ago="false"
          show-date-with-time
        />
      </p>
    </div>
    <InvoiceDetails
      :invoice-details="invoiceDetails"
      :loading="loading"
      :is-detailed-view="true"
    />
  </div>
</template>

<script>
import { invoiceDetailsMixin } from "@/components/mixins";
import InvoiceDetails from "@/components/InvoiceApproval/InvoiceDetails.vue";
import { TimeDisplay } from "@/components/shared";

export default {
  name: "InvoiceDetailedView",
  components: {
    InvoiceDetails,
    TimeDisplay,
  },
  mixins: [invoiceDetailsMixin],
};
</script>
