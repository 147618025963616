<template>
  <!-- NEW START -->
  <div class="custom-card flex-1 flex justify-between mb-6">
    <div class="card-content flex-1">
      <div class="logo-holder">
        <img :src="supplierImage" :alt="supplier.name" />
      </div>

      <div id="text" class="ml-4 flex w-full">
        <div class="flex-row w-full">
          <div class="flex-row">
            <h3 class="font-semibold text-primary text-lg">
              {{ supplier.name }}
            </h3>
            <p class="mr-4 max-w-screen-lg">
              {{ supplierDescription }}
            </p>
          </div>
          <CollapseTransition>
            <div v-if="showDetails" class="mt-3 w-full">
              <div v-if="isSupplierConfigured" class="supplier md: mr-12">
                <div class="flex flex-col sm:flex-row">
                  <div class="flex w-full justify-between">
                    <div class="flex">
                      <div>
                        <CheckCircleIcon
                          class="h-5 w-5 text-hivebuy-green"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <h3 class="">
                          {{
                            $t(
                              "companyManagement.connectedSupplier.supplier.supplierActive"
                            )
                          }}
                        </h3>
                        <div
                          class="mt-2 text-sm text-gray-700 hidden sm:block w-2/3"
                        >
                          <p>
                            {{
                              $t(
                                "companyManagement.connectedSupplier.supplier.supplierActiveText"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!isUserOnlyAuditor"
                    class="md:pr-6 sm:flex-shrink-0 mt-4 sm:mt-0"
                  >
                    <div class="-mx-2 flex">
                      <Button
                        :on-click-handler="onConfigureSupplier"
                        color="gray"
                        :disabled="loading"
                        full
                      >
                        <template #icon> <PencilAltIcon /></template>
                        {{
                          $t(
                            "companyManagement.connectedSupplier.supplier.updateCredentialsBtn"
                          )
                        }}
                      </Button>
                    </div>
                    <div class="-mx-2 mt-2 flex">
                      <Button
                        :on-click-handler="onTestConnection"
                        color-outline
                        full
                        :loading="loading"
                      >
                        <template #icon>
                          <ChartBarIcon />
                        </template>
                        {{
                          $t(
                            "companyManagement.connectedSupplier.supplier.testC"
                          )
                        }}
                      </Button>
                    </div>
                    <div
                      v-if="
                        isFeatureAllowed('invoiceReconciliation') &&
                        supplier.oauthUrl &&
                        !getCompanySupplierForThisSupplier.isAuthenticated
                      "
                      class="-mx-2 mt-2 flex"
                    >
                      <Button
                        :on-click-handler="() => onAuthorise(supplier.oauthUrl)"
                        color-outline
                        full
                        :loading="isApiLoading('authoriseSupplier')"
                      >
                        <template #icon>
                          <LockClosedIcon />
                        </template>
                        {{
                          $t(
                            "companyManagement.connectedSupplier.supplier.authoriseConnection"
                          )
                        }}
                      </Button>
                    </div>
                    <div
                      v-else-if="
                        isFeatureAllowed('invoiceReconciliation') &&
                        getCompanySupplierForThisSupplier.isAuthenticated
                      "
                      class="-mx-2 mt-2 flex"
                    >
                      <Button
                        :on-click-handler="() => onRevokeAccess(supplier)"
                        color-outline
                        full
                        :loading="isApiLoading('revokeSupplierAccess')"
                      >
                        <template #icon>
                          <LockClosedIcon />
                        </template>
                        {{
                          $t(
                            "companyManagement.connectedSupplier.supplier.revokeConnection"
                          )
                        }}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="rounded-md bg-red-50 p-4 md: mr-12">
                <div class="flex flex-col sm:flex-row">
                  <div class="flex w-full justify-between">
                    <!-- Red box icon & text -->
                    <div class="flex">
                      <div>
                        <XCircleIcon
                          class="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <div class="sm:block">
                          <h3 class="text-sm font-medium text-red-800">
                            {{
                              $t(
                                "companyManagement.connectedSupplier.supplier.supplierNotConfigured"
                              )
                            }}
                          </h3>
                        </div>
                        <div
                          v-if="!isUserOnlyAuditor"
                          class="mt-2 text-sm text-red-700 hidden sm:block"
                        >
                          <p>
                            {{
                              $t(
                                "companyManagement.connectedSupplier.supplier.supplierNotConfiguredText"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Red box icon & text END -->
                    <div class="md:pr-6 sm:self-center mt-4 sm:mt-0">
                      <div class="-mx-2 flex">
                        <Button
                          v-if="!isUserOnlyAuditor"
                          :on-click-handler="onConfigureSupplier"
                          color="gray"
                          :disabled="loading"
                          full
                        >
                          <template #icon> <PencilAltIcon /></template>
                          {{
                            $t(
                              "companyManagement.connectedSupplier.supplier.addCredentialsBtn"
                            )
                          }}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CollapseTransition>
        </div>
      </div>
    </div>
    <div
      v-if="!isUserOnlyAuditor"
      id="switch"
      class="sm:col-end-13 flex sm:justify-end justify-center mt-4 sm:mt-0"
    >
      <SwitchCheckbox
        v-model="isCompanySupplierActive"
        class="checkbox self-center"
        :state-icons="true"
        :on-click-handler="onCompanySupplierToggle"
        :loading="loading"
      />
    </div>
  </div>
  <SupplierCredentialsModal
    ref="supplierCredentialsModal"
    :show-modal="showSupplierConfigurationModal"
    :close-modal-handler="onModalClose"
    :supplier-name="supplier.name"
    :company-supplier="getCompanySupplierForThisSupplier"
    :update-company-supplier="saveSupplier"
  />
  <ConfirmDialog
    :text="
      $t('companyManagement.connectedSupplier.supplier.revokeConfirmModal.text')
    "
    :show-modal="showConfirmRevokeModal"
    :close-modal="toggleRevokeAccessModal"
    :title="
      $t(
        'companyManagement.connectedSupplier.supplier.revokeConfirmModal.title'
      )
    "
    :item-to-confirm="supplierToRevoke.name"
    :confirm-modal="onConfirmRevokeAccess"
  />
</template>

<script>
import SupplierPlaceholderImage from "@/assets/images/catalogue-item-image-placeholder.png";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { Button, ConfirmDialog } from "@/components/shared";
import { mapActions, mapState, mapGetters } from "vuex";
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  LockClosedIcon,
} from "@heroicons/vue/outline";
import { AUTH_MODULE } from "@/store/Auth/types";
import { ChartBarIcon } from "@heroicons/vue/solid";
import SupplierCredentialsModal from "@/components/CompanyManagement/ConnectedSuppliers/SupplierCredentialsModal";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import {
  AUTHORISE_SUPPLIER_CREDENTIALS,
  COMPANY_MANAGEMENT_MODULE,
  SAVE_INTEGRATED_SUPPLIER,
  VERIFY_SUPPLIER_CREDENTIALS,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    SwitchCheckbox,
    CollapseTransition,
    CheckCircleIcon,
    XCircleIcon,
    SupplierCredentialsModal,
    PencilAltIcon,
    Button,
    ChartBarIcon,
    LockClosedIcon,
    ConfirmDialog,
  },
  props: {
    supplier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSupplierConfigurationModal: false,
      loading: false,
      showConfirmRevokeModal: false,
      supplierToRevoke: {},
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["integratedSuppliersList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),

    isThisSupplierACompanySupplier() {
      return this.integratedSuppliersList().some((companySupplier) => {
        const supplierId =
          companySupplier.supplier?.id || companySupplier.supplier;
        return supplierId === this.supplier.id;
      });
    },

    userLanguage() {
      if (this.user.language) {
        return this.user.language.toLowerCase();
      }
      return "en";
    },

    supplierDescription() {
      return this.descriptionString[this.userLanguage];
    },

    descriptionString() {
      const defaultDescription = {
        en: "[DESCRIPTION MISSING]",
        de: "[BESCHREIBUNG FEHLT]",
      };

      if (!this.supplier.description) {
        return defaultDescription;
      }

      try {
        return JSON.parse(this.supplier.description);
      } catch (error) {
        return defaultDescription;
      }
    },

    getCompanySupplierForThisSupplier() {
      return this.integratedSuppliersList().find(
        (companySupplier) =>
          (companySupplier.supplier?.id || companySupplier.supplier) ===
          this.supplier.id
      );
    },

    isSupplierConfigured() {
      return this.getCompanySupplierForThisSupplier?.loginId;
    },
    supplierImage() {
      return this.supplier.logo || SupplierPlaceholderImage;
    },
    isCompanySupplierActive() {
      return this.getCompanySupplierForThisSupplier?.active;
    },
    showDetails() {
      return this.isCompanySupplierActive;
    },
  },
  mounted() {
    const { slug, code } = this.$route.query;
    if (slug && code && slug === this.supplier.slug) {
      this.authoriseSupplier(code);
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      saveIntegratedSupplier: SAVE_INTEGRATED_SUPPLIER,
      verifySupplierCredentials: VERIFY_SUPPLIER_CREDENTIALS,
      authoriseSupplierCredentials: AUTHORISE_SUPPLIER_CREDENTIALS,
    }),
    onCompanySupplierToggle() {
      let payload = null;
      if (this.isThisSupplierACompanySupplier) {
        payload = {
          active: !this.isCompanySupplierActive,
          id: this.getCompanySupplierForThisSupplier.id,
        };
      }
      this.saveSupplier(payload);
    },
    saveSupplier(payload = null) {
      if (this.isThisSupplierACompanySupplier) {
        payload = this.existingCompanySupplierPayload(payload);
        const readOnlyKeys = ["confirmationUrl", "shipmentUrl"];
        readOnlyKeys.forEach((key) => {
          if (payload[key]) delete payload[key];
        });
      } else {
        payload = this.newCompanySupplierPayload();
      }
      this.loading = true;
      this.saveIntegratedSupplier(payload)
        .then(() => {
          this.showNotification(
            this.$t(
              "companyManagement.connectedSupplier.supplier.supplierSaved"
            )
          );
          this.showSupplierConfigurationModal &&
            this.$refs.supplierCredentialsModal.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    existingCompanySupplierPayload(payload = null) {
      const { id, name } = this.getCompanySupplierForThisSupplier;

      payload = { ...payload, ...{ id, name } };

      return payload;
    },

    newCompanySupplierPayload() {
      const payload = {
        active: true,
        name: this.supplier.name,
        supplier: this.supplier.id,
      };
      return payload;
    },

    onConfigureSupplier() {
      this.showSupplierConfigurationModal = true;
    },
    onModalClose() {
      this.showSupplierConfigurationModal = false;
    },
    onTestConnection() {
      this.loading = true;
      this.verifySupplierCredentials({
        companySupplierId: this.getCompanySupplierForThisSupplier?.id,
      })
        .then((res) => {
          if (res.success) {
            this.showNotification(`${this.supplier.name} Configured Correctly`);
          } else {
            this.showNotification(
              `${this.supplier.name} Credentials are invalid`,
              "error"
            );
          }
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    authoriseSupplier(code) {
      this.setApiLoading({ key: "authoriseSupplier", value: true });
      this.authoriseSupplierCredentials({
        companySupplierId: this.getCompanySupplierForThisSupplier?.id,
        payload: { code },
      })
        .then(() =>
          this.showNotification(
            `${this.supplier.name} ${this.$t(
              "companyManagement.connectedSupplier.supplier.authoriseSuccessText"
            )}`
          )
        )
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({ key: "authoriseSupplier", value: false })
        );
    },
    onRevokeAccess(supplier) {
      this.toggleRevokeAccessModal();
      this.supplierToRevoke = Object.assign({}, supplier);
    },
    onAuthorise(oauthUrl) {
      window.location.href = oauthUrl;
    },
    toggleRevokeAccessModal() {
      this.showConfirmRevokeModal = !this.showConfirmRevokeModal;
    },
    onConfirmRevokeAccess() {
      this.toggleRevokeAccessModal();
      console.log("Revoke Access Confirmed");
    },
  },
};
</script>

<style scoped>
.card-content {
  @apply flex items-center;
}

.logo-holder {
  @apply h-24 w-24 border border-gray-200 p-2 rounded;
  align-self: flex-start;
}

.logo-holder img {
  @apply object-center object-contain sm:w-full sm:h-full;
}

.supplier {
  @apply rounded-md p-4 bg-hivebuy-green/10 border border-hivebuy-green/20;
}

.supplier h3 {
  @apply text-sm font-semibold text-gray-700;
}

.checkbox {
  align-items: start !important;
}
</style>
