<template>
  <TabView
    v-model:activeIndex="activeIndex"
    :lazy="true"
    class="tabview-custom"
    @tab-change="changeUrl"
  >
    <TabPanel>
      <template #header>
        <PurchaseRequestSVG class="h-5 w-5 mr-1" />
        <span>
          {{ $tc("global.purchaseOrder", 2) }}
        </span>
      </template>
      <PurchaseOrdersList />
    </TabPanel>
    <TabPanel>
      <template #header>
        <InvoiceSVG class="h-5 w-5 mr-1" />
        <span>
          {{ $tc("global.invoice.title", 2) }}
        </span>
      </template>
      <InvoiceList />
    </TabPanel>
  </TabView>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PurchaseOrdersList from "@/components/PurchaseOrder/PurchaseOrdersList/List";
import PurchaseRequestSVG from "@/assets/images/PurchaseRequestSVG.vue";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import InvoiceList from "@/components/PurchaseOrder/PurchaseOrdersList/InvoiceList.vue";
import { mapState, mapActions } from "vuex";
import { INVOICE_MODULE, GET_DEBTORS } from "@/store/Invoice/types";
export default {
  components: {
    PurchaseOrdersList,
    TabView,
    TabPanel,
    InvoiceList,
    PurchaseRequestSVG,
    InvoiceSVG,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, ["debtors"]),
  },
  mounted() {
    this.$route.params.type === "invoice" && (this.activeIndex = 1);
    if (!this.debtors.length) this.getDebtors();
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getDebtors: GET_DEBTORS,
    }),
    changeUrl(event) {
      const params = {
        type: event.index === 1 ? "invoice" : "",
      };
      this.$router.replace({ params });
    },
  },
};
</script>
<style></style>
