import * as ProjectTypes from "@/store/Project/types";
import { updateOrCreate } from "@/utils/utility_methods";

export default {
  [ProjectTypes.SET_PROJECTS]: (state, payload) => {
    if (!payload) return;

    const { results, ...paginationObj } = payload;
    const { currentPage, ...paginationParams } = paginationObj;

    state.projectsListFilters = Object.assign(
      {},
      state.projectsListFilters,
      paginationParams,
      { page: currentPage }
    );

    results.forEach(
      (project) =>
        (state.projects = {
          ...state.projects,
          [project.id]: Object.assign({}, project),
        })
    );
  },

  [ProjectTypes.SET_PROJECTS_LIST]: (state, payload) => {
    state.projectsList = payload;
  },
  [ProjectTypes.SET_PROJECTS_LIST_FILTERS]: (state, payload) => {
    state.projectsListFilters = payload;
  },
  [ProjectTypes.SET_PROJECT_LIST_PARAMS]: (state, payload) => {
    state.projectsListParams = payload;
  },
  [ProjectTypes.SET_PROJECT]: (state, payload) => {
    state.projectsList = updateOrCreate(state.projectsList, payload);
    state.projects[payload.id] = payload;
  },
  [ProjectTypes.REMOVE_PROJECT]: (state, projectId) => {
    state.projectsList = state.projectsList.filter(
      (project) => project.id !== projectId
    );
    delete state.projects[projectId];
  },
};
