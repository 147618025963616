import actions from "@/store/Downloads/actions";
import mutations from "@/store/Downloads/mutations";
import getters from "@/store/Downloads/getters.js";
import state from "@/store/Downloads/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
