<template>
  <section class="page">
    <header class="header">
      <div class="container">
        <img
          src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/page-logo.png"
        />
      </div>
      <Button class="sign-out" @click="onUserLogout">
        <LogoutIcon
          class="mr-3 h-5 w-5 text-gray-400 group-hover:text-red-500"
          aria-hidden="true"
        />
        {{ $t("home.signOut") }}
      </Button>
    </header>
    <div class="container">
      <div class="main-content">
        <h2>{{ $t("trialPeriodExpired.pageTitle") }}</h2>
        <p>
          {{ $t("trialPeriodExpired.pageDescription") }}
        </p>
        <p>
          {{ $t("trialPeriodExpired.featuresSection.heading") }}
        </p>

        <ul>
          <li v-for="feature in featuresTexts" :key="feature">{{ feature }}</li>
        </ul>
      </div>
    </div>

    <section class="pink">
      <div class="container">
        <div class="content-holder">
          <h1>
            {{ $t("trialPeriodExpired.benefitsSection.heading") }}
          </h1>
          <h3>{{ $t("trialPeriodExpired.benefitsSection.text") }}</h3>
          <div class="row">
            <div class="left-content">
              <ul>
                <li v-for="benefit in benefitsTexts" :key="benefit">
                  {{ benefit }}
                </li>
              </ul>
            </div>
            <div class="right-content">
              <Button :on-click-handler="redirectToCalendly" class="button">
                {{ $t("trialPeriodExpired.benefitsSection.purchaseNowBtn") }}
              </Button>
            </div>
          </div>
        </div>
        <div class="image-holder">
          <img
            src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/illusration.png"
          />
        </div>
      </div>
    </section>

    <div class="container footer">
      <p>{{ $t("trialPeriodExpired.anyQuestionsText") }}</p>
      <div class="content">
        <img
          src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/robert.png"
        />
        <p>
          Robert Eickmeyer
          <br />
          <a
            href="https://calendly.com/robert-1072/hivebuy-30-min-talk-mit-robert-yyb?month=2023-01"
            class="text-primary underline"
          >
            {{ $t("trialPeriodExpired.bookCall") }}
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Button from "@/components/shared/Button";
import { mapActions } from "vuex";
import { AUTH_MODULE, LOGOUT_USER } from "@/store/Auth/types";
import { LogoutIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Button,
    LogoutIcon,
  },
  computed: {
    featuresTexts() {
      const texts = [];
      Array.from({ length: 6 }, (_, i) => i + 1).forEach((element) => {
        texts.push(
          this.$t(`trialPeriodExpired.featuresSection.list.feature${element}`)
        );
      });
      return texts;
    },
    benefitsTexts() {
      const texts = [];
      Array.from({ length: 3 }, (_, i) => i + 1).forEach((element) => {
        texts.push(
          this.$t(`trialPeriodExpired.benefitsSection.list.benefit${element}`)
        );
      });
      return texts;
    },
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      onUserLogout: LOGOUT_USER,
    }),
    redirectToCalendly() {
      window.location.href =
        "https://calendly.com/robert-1072/hivebuy-30-min-talk-mit-robert-yyb?month=2023-01";
    },
  },
};
</script>

<style scoped>
.sign-out {
  @apply mx-auto px-4;
}

.page {
  @apply font-serif bg-white;
}
.page .header {
  @apply py-4 mb-[20px] flex;
}
.page .container {
  @apply max-w-[960px] mx-auto px-4;
}
.page .container .main-content {
  @apply text-center;
}
.page .container .main-content h2 {
  @apply text-2xl font-bold mb-4 font-sans;
}
.page .container .main-content p {
  @apply mb-2;
}
.page .container .main-content ul {
  @apply inline-block gap-x-3 gap-y-0.5 mt-10;
  column-count: 2;
}
.page .container .main-content ul li {
  @apply flex-grow flex-shrink-0 text-left list-disc list-inside;
}
.page .pink {
  @apply bg-[#eee7f2] py-8 my-8 w-full;
}
.page .pink .container {
  @apply flex justify-between;
}
.page .pink .container .content-holder {
  @apply flex-1 pr-8;
}
.page .pink .content-holder h1 {
  @apply text-xl font-bold font-sans;
}
.page .pink .content-holder h3 {
  @apply text-lg font-semibold mb-6 font-sans;
}
.page .pink .content-holder .row {
  @apply w-full justify-between flex;
}
.page .pink .content-holder .row .left-content {
  @apply flex-1;
}
.page .pink .content-holder .row .left-content ul li {
  @apply list-disc list-inside;
}
.page .pink .content-holder .row .right-content {
  @apply flex items-end;
}
.page .pink .content-holder .row .right-content .button {
  @apply bg-primarydark text-white rounded px-8 py-2 self-end font-sans;
}
.page .pink .image-holder img {
  @apply h-[200px] w-auto -my-4;
}
.footer p {
  @apply font-semibold;
}
.footer .content {
  @apply flex items-center gap-x-4 mt-4;
}
.footer .content p {
  @apply font-normal text-sm;
}
.footer .content img {
  @apply w-[100px] h-auto rounded-3xl;
}
</style>
