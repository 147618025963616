<template>
  <div v-if="isValidItemInCart">
    <div
      class="p-2 rounded border border-transparent hover:border-gray-300 cursor-pointer relative hover:bg-gray-100 flex items-center gap-x-2"
      @click="togglePopover"
    >
      <div
        class="rounded-full bg-primary px-2 py-0.5 text-xs text-center absolute text-white -top-1 -left-1"
      >
        {{ newRequestItem().length }}
      </div>

      <PurchaseRequestPlusSVG class="h-7 w-7 text-gray-500" />
    </div>
    <OverlayPanel ref="cartPopover">
      <div class="cart-popover-wrapper">
        <ul role="list" class="divide-y divide-gray-100 w-full">
          <li
            v-for="item in newRequestItem()"
            :key="item"
            class="flex justify-between gap-x-6 py-5"
          >
            <div class="flex min-w-0 gap-x-4">
              <img
                v-if="item.images?.length"
                class="h-12 w-12 flex-none rounded-full bg-gray-50"
                :src="item.images[0].image"
                alt=""
              />
              <div
                v-else
                class="h-12 w-12 flex items-center justify-center rounded bg-gray-100 border border-gray-200 uppercase"
              >
                {{ item.name.charAt(0) || "-" }}
              </div>
              <div class="min-w-0 flex-auto">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ item.name }}
                </p>
                <p class="truncate text-xs leading-5 text-gray-500">
                  <Category :category="item.category" />
                </p>
              </div>
            </div>
            <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p class="text-sm leading-6 text-gray-900">
                <i18n-n
                  :value="parseFloat(item.netAmount) * item.quantity"
                  format="currency"
                  :locale="currencyToLocale(item.netAmountCurrency)"
                />
                <span class="flex text-2xs">
                  {{ item.quantity }} x
                  <i18n-n
                    :value="parseFloat(item.netAmount)"
                    format="currency"
                    :locale="currencyToLocale(item.netAmountCurrency)"
                /></span>
              </p>
            </div>
          </li>
        </ul>
        <div v-if="!ifCheckoutRoute" class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center"></div>
        </div>
        <div v-if="!ifCheckoutRoute" class="py-1 flex justify-center gap-x-4">
          <div>
            <Button
              :loading="loading"
              color="success"
              size-class="sm"
              :on-click-handler="goToCheckout"
            >
              <template #icon>
                <ShoppingCartIcon />
              </template>
              {{ $t("buttons.goToCart") }}
            </Button>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>
<script>
import PurchaseRequestPlusSVG from "@/assets/images/PurchaseRequestPlusSVG.vue";
import { Button } from "@/components/shared/index";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { ShoppingCartIcon } from "@heroicons/vue/outline";
import { currencyToLocale } from "@/utils/utility_methods.js";
import OverlayPanel from "primevue/overlaypanel";
import { mapGetters } from "vuex";
import { Category } from "@/components/shared";
export default {
  components: {
    PurchaseRequestPlusSVG,
    OverlayPanel,
    Button,
    ShoppingCartIcon,
    Category,
  },
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, [
      "newRequestItem",
      "isValidItemInCart",
    ]),

    ifCheckoutRoute() {
      return this.$route.name === "CreatePurchaseRequest";
    },
  },
  methods: {
    currencyToLocale,

    goToCheckout(event) {
      this.togglePopover(event);
      this.$router.push({
        name: "CreatePurchaseRequest",
      });
    },
    togglePopover(event) {
      this.$refs.cartPopover.toggle(event);
    },
  },
};
</script>
<style scoped>
.cart-popover-wrapper {
  @apply w-[25rem] flex flex-col gap-3 px-4 py-1;
}
</style>
