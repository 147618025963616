import { createRouter, createWebHistory } from "vue-router";
import {
  getItemFromLocalStorage,
  stringToBoolean,
} from "@/utils/utility_methods";
import { skipValidation } from "@/utils/helpers/permissionHelper.js";
import fusionAuth from "@/utils/services/auth";
import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export const isAppUnderMaintenance = () => {
  return stringToBoolean(process.env.VUE_APP_UNDER_MAINTENANCE);
};

const handleAuth = async (to, next) => {
  const { query } = to;
  if (query && query.code && !query.slug) {
    try {
      await fusionAuth.retrieveAndSetTokens(query);
      if (query?.locale) {
        window.localStorage.setItem(
          "fusion_auth_locale",
          query.locale.split("_")[0]
        );
      }
      next("/");
    } catch (error) {
      fusionAuth.handleAuthError(error);
    }
  } else {
    !to.path.includes("self-signup") && fusionAuth.saveRouteToRedirect();
    if (!isAppUnderMaintenance()) {
      await fusionAuth.redirectToLoginUrl();
    }
  }
};
export const isSignupPath = (route) => {
  return !!route.query?.state;
};
export const isSelfSignup = (route) => {
  return (
    route.includes("self-signup") &&
    process.env.VUE_APP_SELF_SIGNUP === "enabled"
  );
};

router.beforeEach((to, from, next) => {
  if (
    fusionAuth.isUserLoggedIn() ||
    isSelfSignup(to.fullPath) ||
    isSignupPath(to)
  ) {
    if (to.name == from.name && to.params == from.params) return next(false);
    validateRoute(to) ? next() : next("/not-allowed");
  } else {
    handleAuth(to, next);
  }

  document.title = to.meta.title || "Hivebuy";
});

const validateRole = (allowedRoles = []) => {
  const user = getItemFromLocalStorage("user");
  if (!user || !user.roles.length) return false;

  return user.roles.some((role) => allowedRoles.includes(role));
};

const validateRoute = (route) => {
  return (
    skipValidation(route.fullPath.replaceAll("/", "")) ||
    skipValidation(route.fullPath.split("/")[1]) ||
    validateRole(route.meta.roles) ||
    isSignupPath(route)
  );
};

export default router;
