import { deleteObject, get, patch, post } from "@/utils/services/api";
import { LEGAL_TENDENCY_API_URL } from "@/utils/services/apiUrls";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types";

export const getLegalTendencies = (commit, resolve, reject) => {
  get(LEGAL_TENDENCY_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_LEGAL_TENDENCIES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveLegalTendency = (commit, resolve, reject, payload) => {
  if (payload?.id)
    updateLegalTendency(commit, resolve, reject, payload.id, payload);
  else addLegalTendency(commit, resolve, reject, payload);
};

const addLegalTendency = (commit, resolve, reject, payload) => {
  post(LEGAL_TENDENCY_API_URL, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_LEGAL_TENDENCY, response.data);
      resolve();
    })
    .catch((err) => reject(err));
};

const updateLegalTendency = (
  commit,
  resolve,
  reject,
  legalTendencyId,
  payload
) => {
  if (!legalTendencyId) return;

  patch(`${LEGAL_TENDENCY_API_URL}${legalTendencyId}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_LEGAL_TENDENCY, response.data);
      resolve();
    })
    .catch((err) => reject(err));
};

export const deleteLegalTendency = (
  commit,
  resolve,
  reject,
  legalTendencyId
) => {
  if (!legalTendencyId) return;

  deleteObject(`${LEGAL_TENDENCY_API_URL}${legalTendencyId}/`)
    .then(() => {
      commit(CompanyManagementTypes.REMOVE_LEGAL_TENDENCY, legalTendencyId);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};
