<template>
  <div class="p-10 text-center">
    <div class="flex flex-col items-center text-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-12 h-12 text-primary mx-auto mb-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>

      <p class="font-medium mb-1">
        {{ headingText }}
      </p>
      <p class="text-gray-500 text-sm mb-5">
        {{ descriptionText }}
      </p>
      <button
        v-if="buttonText"
        type="button"
        class="flex items-center text-primary font-semibold justify-center"
        @click="buttonFunction"
      >
        <PlusIcon class="-ml-1.5 mr-1 h-5 w-5" />
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/outline";

export default {
  components: {
    PlusIcon,
  },
  props: {
    headingText: {
      type: String,
      required: true,
    },
    descriptionText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonFunction: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
