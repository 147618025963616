import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import de from "javascript-time-ago/locale/de.json";
import es from "javascript-time-ago/locale/es.json";
import { i18n } from "@/i18n";

const getLocale = (locale) => {
  return {
    en: { locale: en, extendedLocale: "en-US" },
    de: { locale: de, extendedLocale: "de-DE" },
    es: { locale: es, extendedLocale: "es-ES" },
  }[locale];
};
export const timeDifference = (dateTime) => {
  const localeDetail = getLocale(i18n.global.locale);
  TimeAgo.addLocale(localeDetail.locale);
  const timeAgo = new TimeAgo(localeDetail.extendedLocale);
  const date = Date.parse(dateTime);
  if (isNaN(date)) return "-";

  return timeAgo.format(date);
};
