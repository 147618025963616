<template>
  <div class="flex min-h-screen justify-center items-center bg-white">
    <div class="text-center">
      <div class="rounded-full h-20 w-20 mx-auto">
        <img src="@/assets/images/hivebuy_logo.svg" alt="Hivebuy" />
      </div>
      <div class="py-2">
        <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Site Under Maintenance.</h1>
        <p class="mt-2 text-base text-gray-500">Hivebuy is down for planned maintenance. We'll be back with the latest updates shortly.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppUnderMaintenance"
}
</script>
