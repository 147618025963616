<template>
  <Modal :show-modal="open" :close-modal="toggleFunction">
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      {{ $t("purchaseRequest.summary.titleModalHeader") }}
    </template>
    <template #body>
      <div class="mt-2">
        <p class="text-sm text-gray-500 mb-2">
          {{ $t("purchaseRequest.summary.titleModalText") }}
        </p>
        <div class="mb-2">
          <Input
            :value="listTitle"
            :name="'title'"
            :on-key-up="onAttributeChange"
            :type="'text'"
          />
        </div>
        <TextArea
          :name="'description'"
          :placeholder="
            $t('catalogue.catalogueModal.fields.descriptionPlaceholder')
          "
          :value="description"
          :rows="3"
          :on-key-up="onAttributeChange"
          :label="$t('catalogue.catalogueModal.fields.description')"
        />
      </div>
    </template>
    <template #footer>
      <Button type="button" :on-click-handler="toggleFunction" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        type="button"
        :disabled="!saveAllowed"
        :on-click-handler="confirmSave"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import { Button, Modal, Input, TextArea } from "@/components/shared/index";
export default {
  components: {
    Modal,
    CheckIcon,
    Input,
    Button,
    TextArea,
  },
  props: {
    suggestion: {
      type: String,
      required: false,
      default: "",
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      description: "",
    };
  },
  computed: {
    saveAllowed() {
      return this.listTitle && this.description;
    },
    listTitle() {
      return this.title || this.suggestion;
    },
  },
  methods: {
    confirmSave() {
      this.toggleFunction();

      this.saveMethod(this.listTitle, this.description);
    },

    onAttributeChange(event) {
      const { name, value } = event.target;
      this[name] = value;
    },
  },
};
</script>
