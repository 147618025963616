<template>
  <div v-click-outside="() => (showPopover = false)" class="ml-auto relative">
    <SearchInput
      :value="search"
      :on-change="updateSearchFilters"
      :search-classes="['text-sm rounded-full p-0']"
      :loading="isLoading"
      :hide="hide"
      :placeholder="$t('global.searchPlaceholder')"
      @focus="show"
    />

    <div v-if="showPopover" v-auto-animate class="search-dropdown">
      <fieldset class="flex m-4 cursor-pointer gap-x-4">
        <div class="checkbox-container">
          <input
            type="checkbox"
            :checked="showItems.purchaseRequests"
            name="purchaseRequests"
            @input="showItems.purchaseRequests = $event.target.checked"
          />
          <label for="purchase-request">
            {{ $tc("global.purchaseRequestShort", 2) }}
          </label>
        </div>

        <div v-if="allowedContracts" class="checkbox-container">
          <input
            type="checkbox"
            :checked="showItems.contracts"
            name="contracts"
            @input="showItems.contracts = $event.target.checked"
          />
          <label for="contracts">{{ $tc("global.contract", 2) }}</label>
        </div>

        <div class="checkbox-container">
          <input
            type="checkbox"
            :checked="showItems.catalogueItems"
            name="catalogueItems"
            @input="showItems.catalogueItems = $event.target.checked"
          />
          <label for="catalogueItems">{{ $t("global.catalogue") }}</label>
        </div>
        <div class="checkbox-container">
          <input
            type="checkbox"
            :checked="showItems.invoices"
            name="invoices"
            @input="showItems.invoices = $event.target.checked"
          />
          <label for="invoices">{{ $tc("global.invoice.title", 2) }}</label>
        </div>
      </fieldset>
      <div v-if="showAll" class="tabs-container">
        <ul
          :class="[
            { all: isTabActive('all') },
            { request: isTabActive('purchaseRequests') },
            { contracts: isTabActive('contracts') },
            { catalogue: isTabActive('catalogueItems') },
            { invoices: isTabActive('invoices') },
          ]"
        >
          <li
            v-if="showAll"
            :class="[{ active: isTabActive('all') }]"
            @click="() => setActiveTab('all')"
          >
            {{ $t("global.all") }}
          </li>
          <li
            v-if="
              showItems.purchaseRequests && records?.purchaseRequests?.length
            "
            :class="[{ active: isTabActive('purchaseRequests') }]"
            @click="() => setActiveTab('purchaseRequests')"
          >
            {{ $tc("global.purchaseRequestShort", 2) }}
          </li>
          <li
            v-if="
              allowedContracts &&
              showItems.contracts &&
              records?.contracts?.length
            "
            :class="[{ active: isTabActive('contracts') }]"
            @click="() => setActiveTab('contracts')"
          >
            {{ $tc("global.contract", 2) }}
          </li>
          <li
            v-if="showItems.catalogueItems && records?.catalogueItems?.length"
            :class="[{ active: isTabActive('catalogueItems') }]"
            @click="() => setActiveTab('catalogueItems')"
          >
            {{ $t("global.catalogue") }}
          </li>
          <li
            v-if="showItems.invoices && records?.invoices?.length"
            :class="[{ active: isTabActive('invoices') }]"
            @click="() => setActiveTab('invoices')"
          >
            {{ $tc("global.invoice.title", 2) }}
          </li>
        </ul>
      </div>
      <div
        v-if="showAll && !isLoading"
        class="list"
        :class="[{ all: isTabActive('all') }]"
      >
        <div
          v-if="showPR && records.purchaseRequests?.length"
          class="list-item"
        >
          <PurchaseRequestItem
            :purchase-requests="records.purchaseRequests"
            :handle-result-click="hide"
            :number-of-records="numberOfRecords"
          />
        </div>
        <div
          v-if="showContracts && records.contracts?.length"
          class="list-item"
        >
          <Contract
            :contracts="records.contracts"
            :handle-result-click="hide"
            :number-of-records="numberOfRecords"
          />
        </div>
        <div
          v-if="showCatalogueItems && records.catalogueItems?.length"
          class="list-item"
        >
          <CatalogueItem
            :items="records.catalogueItems"
            :handle-result-click="hide"
            :number-of-records="numberOfRecords"
          />
        </div>
        <div
          v-if="showInvoiceItems && records.invoices?.length"
          class="list-item"
        >
          <InvoiceItems
            :items="records.invoices"
            :handle-result-click="hide"
            :number-of-records="numberOfRecords"
          />
        </div>
      </div>
      <div
        v-else-if="isLoading"
        class="p-5 inline-block transition ease-in-out duration-150 text-xs text-center"
      >
        <svg
          class="animate-spin h-5 w-5 mr-3 fill-current text-primary inline"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {{ $t("global.searching") }}
      </div>
      <div
        v-if="searchWasExecuted && !showAll && !isLoading"
        class="block rounded-md transition ease-in-out duration-150 shadow-md text-xs text-center pb-2"
      >
        {{ $t("global.noSearchResults") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { GET_SEARCH_DATA, DASHBOARD_MODULE } from "@/store/Dashboard/types.js";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInputTopBar";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  PurchaseRequestItem,
  Contract,
  CatalogueItem,
  InvoiceItems,
} from "@/components/shared/searchBar/index.js";
export default {
  components: {
    PurchaseRequestItem,
    SearchInput,
    Contract,
    CatalogueItem,
    InvoiceItems,
  },
  data() {
    return {
      isLoading: false,
      showPopover: false,
      activeTab: "all",
      showItems: {
        purchaseRequests: true,
        contracts: true,
        catalogueItems: true,
        invoices: true,
      },
      records: {},
      search: "",
      searchWasExecuted: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    numberOfRecords() {
      return this.isTabActive("all") ? 3 : 10;
    },
    allowedContracts() {
      const allowedRoles = ["O", "MD", "CO"].filter((value) =>
        this.user.roles.includes(value)
      );
      return allowedRoles.length;
    },
    showAll() {
      const { purchaseRequests, contracts, catalogueItems, invoices } =
        this.records;
      return [purchaseRequests, contracts, catalogueItems, invoices].some(
        (record) => record?.length
      );
    },
    showPR() {
      return (
        (this.isTabActive("all") || this.isTabActive("purchaseRequests")) &&
        this.showItems.purchaseRequests
      );
    },
    showContracts() {
      return (
        (this.isTabActive("all") || this.isTabActive("contracts")) &&
        this.showItems.contracts &&
        this.allowedContracts
      );
    },
    showCatalogueItems() {
      return (
        (this.isTabActive("all") || this.isTabActive("catalogueItems")) &&
        this.showItems.catalogueItems
      );
    },
    showInvoiceItems() {
      return (
        (this.isTabActive("all") || this.isTabActive("invoices")) &&
        this.showItems.invoices
      );
    },
  },
  watch: {
    showItems: {
      deep: true,
      handler(val) {
        const filteredKeys = Object.keys(val).filter(function (key) {
          return val[key];
        });
        if (
          JSON.stringify(filteredKeys) ==
          JSON.stringify(Object.keys(this.records))
        )
          this.fetchRecords();
      },
    },
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      getRecords: GET_SEARCH_DATA,
    }),
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isTabActive(tab) {
      return this.activeTab == tab;
    },

    updateSearchFilters(key, value) {
      this.showPopover = value !== "";
      this.search = value;
      if (this.search.length) {
        this.fetchRecords();
      } else {
        this.records = {};
        this.searchWasExecuted = false;
      }
    },
    fetchRecords() {
      const modules = [];
      Object.keys(this.showItems).forEach((module) => {
        if (this.showItems[module]) modules.push(module);
      });
      if (!modules.length) return;
      const params = { query: this.search, modules: modules.join(",") };
      this.isLoading = true;

      this.getRecords(params)
        .then((response) => {
          this.records = response.records;
          this.isLoading = false;
          this.searchWasExecuted = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    show() {
      this.showPopover = true;
    },
    hide() {
      this.showPopover = false;
      this.search = "";
      this.searchWasExecuted = false;
    },
  },
};
</script>

<style scoped>
.search-dropdown {
  @apply absolute left-0 z-[-1] w-[441px] bg-white border border-gray-200 pt-[24px] top-[27px] shadow-lg rounded-md;
}

.tabs-container {
  @apply border-t border-gray-200;
}
.tabs-container ul {
  @apply px-[8px] py-[12px] gap-2 border-b border-gray-200 flex;
}

.tabs-container ul li {
  @apply px-[8px] py-[4px] rounded text-sm font-semibold text-gray-600 cursor-pointer hover:ring-1 hover:ring-hivebuy-grey;
}
.tabs-container ul.all li.active {
  @apply bg-primary text-white;
}

.tabs-container ul.request li.active {
  @apply bg-hivebuy-yellow text-white;
}

.tabs-container ul.contracts li.active {
  @apply bg-hivebuy-red text-white;
}

.tabs-container ul.catalogue li.active {
  @apply bg-hivebuy-green text-white;
}

.tabs-container ul.invoices li.active {
  @apply bg-hivebuy_darkplum text-white;
}

.list {
  @apply max-h-[60vh] overflow-auto;
}
.list .list-item {
  @apply divide-y divide-gray-200 border-t border-t-gray-200 first:border-t-0;
}

:deep(.list.all .list-item .item .badge) {
  @apply inline-block;
}

:deep(.list.all .list-item .item .type) {
  @apply hidden;
}

.checkbox-container {
  @apply flex h-5 items-center flex-1;
}

.checkbox-container input {
  @apply mr-1 h-4 w-4 rounded border-gray-300 text-hivebuy-green ring-0;
}

.checkbox-container label {
  @apply text-sm font-semibold text-gray-700;
}
</style>
