<template>
  <div>
    <div class="relative rounded">
      <CurrencyInput
        :key="currency.name"
        v-model.number="inputPrice"
        :label="inputLabel"
        :placeholder="placeholderText"
        name="priceInput"
        :is-mandatory="isMandatory"
        :error-text="errorText"
        :disabled="disabled"
        :options="currencyOption"
      />
      <div
        class="absolute right-3 flex items-center"
        :class="[pricing.netAmount > 0 ? 'bottom-8' : 'bottom-2']"
      >
        <Popper class="z-50">
          <div
            class="flex items-center focus:outline-none outline-none cursor-pointer"
          >
            <CogIcon
              class="h-6 flex items-center text-gray-400 hover:text-primary outline-none self-center focus:outline-none"
            />
          </div>
          <template #content>
            <div v-if="disabled">{{ $t("priceInput.disabled") }}</div>
            <div v-else class="z-50 sm:px-0 bg-white w-64 py-4">
              <div class="mx-auto px-4">
                <ButtonAsRadio
                  :label="$t('global.pricing.vat')"
                  :options="appliedVATRates"
                  :value="pricing.vat"
                  :name="'vat'"
                  :on-change-handler="changeEvent"
                />

                <ButtonAsRadio
                  :label="
                    $t('global.pricing.net') +
                    ' / ' +
                    $t('global.pricing.gross')
                  "
                  :options="NET_GROSS($t)"
                  :value="pricing.netGross"
                  :name="'netGross'"
                  :on-change-handler="changeEvent"
                />

                <ButtonAsRadio
                  :label="$t('global.pricing.currency')"
                  :options="currencies"
                  :value="pricing.currency"
                  :name="'currency'"
                  :on-change-handler="changeEvent"
                />
              </div>
            </div>
          </template>
        </Popper>
      </div>
      <CollapseTransition>
        <div
          v-show="pricing.inputPrice > 0"
          class="text-xs -mt-0.5 border border-t-0 rounded-b-md px-6 pb-1 pt-1.5 border-gray-200 flex gap-x-2 justify-between bg-primary text-white font-medium"
        >
          <span v-if="pricing.netAmount" class="grow">
            <i18n-n
              :value="parseFloat(pricing.netAmount)"
              format="currency"
              :locale="currencyToLocale(pricing.currency)"
            />
          </span>
          <span class="flex-none">+</span>
          <span class="flex-none px-4">{{ pricing.vat }}%</span>
          <span class="flex-none">=</span>
          <span v-if="pricing.grossAmount" class="grow text-right"
            ><i18n-n
              :value="parseFloat(pricing.grossAmount)"
              format="currency"
              :locale="currencyToLocale(pricing.currency)"
            />
          </span>
        </div>
      </CollapseTransition>
    </div>
  </div>
</template>

<script>
import ButtonAsRadio from "@/components/shared/ButtonAsRadio.vue";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import {
  CURRENCY,
  NET_GROSS,
  VAT_RATES,
  CURRENCY_TO_LOCALE,
} from "@/utils/constants.js";
import { currencyToLocale, isValuePresent } from "@/utils/utility_methods.js";
import { CogIcon } from "@heroicons/vue/solid";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    CogIcon,
    ButtonAsRadio,
    CollapseTransition,
    CurrencyInput,
  },
  props: {
    name: {
      type: String,
      default: "price",
    },
    errorText: {
      type: String,
      required: false,
      default: "",
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
    modelValue: {
      type: [Object, null],
      required: true,
    },
    precision: {
      type: Number,
      default: 4,
    },
    label: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      currencies: CURRENCY,
      inputPrice: null,
      defaultNetGross: "net",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    pricing: {
      get() {
        return this.returnValue(this.modelValue);
      },
      set: (value) => {
        this.$emit("update:modelValue", value);
      },
    },
    currency() {
      return this.getCurrencyValue(this.modelValue?.currency) || CURRENCY[0];
    },
    currencyOption() {
      const { name } = this.currency;
      return {
        locale: this.CURRENCY_TO_LOCALE[name],
        currency: name,
        precision: this.precision,
      };
    },
    placeholderText() {
      let vatText = "";
      if (this.pricing.netGross === "net") {
        // vatText = "Price (excl. " + this.pricing.vat + "% VAT)";
        vatText = this.$t("global.pricing.priceExclVAT", {
          rate: this.pricing.vat,
        });
      } else {
        // vatText = "Price (incl. " + this.pricing.vat + "% VAT)";
        vatText = this.$t("global.pricing.priceInclVAT", {
          rate: this.pricing.vat,
        });
      }
      return vatText;
    },
    appliedVATRates() {
      let vatRates;
      this.currencies.filter((currency) => {
        if (currency.value === this.pricing.currency) {
          vatRates = currency.vatRates;
        }
      });
      return vatRates;
    },
    firstAppliedVATRate() {
      return this.appliedVATRates[0]?.value;
    },
    inputLabel() {
      return this.label || this.$t("purchaseRequest.detail.price");
    },
  },
  watch: {
    inputPrice(val, oldVal) {
      if (val != oldVal) {
        this.pricing.inputPrice = isValuePresent(val) ? val : null;
        this.calcPrice(val);
      }
    },
    modelValue: {
      handler(value) {
        this.inputPrice = isValuePresent(value?.inputPrice)
          ? value?.inputPrice
          : null;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.VAT_RATES = VAT_RATES;
    this.NET_GROSS = NET_GROSS;
    this.CURRENCY = CURRENCY;
    this.handleModelValue();
    this.CURRENCY_TO_LOCALE = CURRENCY_TO_LOCALE;
  },
  methods: {
    currencyToLocale,
    handleModelValue() {
      if (this.modelValue == null) {
        this.$emit("update:modelValue", this.returnValue(this.modelValue));
      }
    },
    returnValue(modelValue) {
      if (modelValue == null && typeof modelValue != "number") {
        return {
          netAmount: 0,
          grossAmount: 0,
          currency: this.company?.currency,
          vat: this.firstAppliedVATRate,
          netGross: this.defaultNetGross,
          inputPrice: null,
        };
      } else {
        return this.modelValue;
      }
    },
    getCurrencyValue(currency) {
      return CURRENCY.find((element) => element.value == currency);
    },
    changeEvent(event) {
      const { name, value } = event.target;
      if (name === "priceInput") {
        this.pricing.inputPrice = value;
        this.calcPrice(value);
      }
      if (name === "vat") {
        this.pricing.vat = value;
        this.calcPrice(this.pricing.inputPrice);
      }
      if (name === "netGross") {
        this.pricing.netGross = value;
        this.calcPrice(this.pricing.inputPrice);
      }
      if (name === "currency") {
        this.pricing.currency = this.handleCurrencyValue(value);
        this.pricing.vat = this.appliedVATRates[0].value;
        this.calcPrice(this.pricing.inputPrice);
      }
    },
    calcPrice(value) {
      const adjustedVAT = this.pricing.vat / 100;
      if (this.pricing.netGross === "net") {
        this.pricing.netAmount = value;
        this.pricing.grossAmount = parseFloat(
          value * (1 + adjustedVAT)
        ).toFixed(this.backendDecimalPoint);
      } else {
        this.pricing.grossAmount = value;
        this.pricing.netAmount = parseFloat(value / (1 + adjustedVAT)).toFixed(
          this.backendDecimalPoint
        );
      }
    },
    handleCurrencyValue(val) {
      const currency = CURRENCY.find((element) => element.value == val);
      return currency.value;
    },
  },
};
</script>
