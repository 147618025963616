<template>
  <div class="custom-card no-shadow">
    <div>
      <div class="w-full flex items-center justify-between">
        <div class="flex-1">
          <div class="flex items-center space-x-2">
            <h3 class="section-title">Status</h3>
          </div>
          <p
            v-if="isSupplierRequest"
            class="text-gray-600 text-sm truncate hover:text-primary cursor-pointer"
            @click="showDetails = !showDetails"
          >
            Show Details
          </p>
        </div>
        <StatusTag :status="purchaseOrder.status" :type="'order'" />
      </div>
      <div v-if="isSupplierRequest">
        <CollapseTransition>
          <div v-if="showDetails" class="mt-4">
            <div class="bg-gray-50 border border-gray-200 rounded p-4">
              <ul class="text-sm">
                <li class="py-2 flex justify-between border-b border-gray-200">
                  <div class="text-gray-900 font-medium text-sm">
                    {{
                      $t(
                        `purchaseRequest.summary.purchaseOrderStatus.itemDetails.name`
                      )
                    }}
                  </div>
                  <div class="text-gray-900 font-medium text-sm mr-3">
                    {{
                      $t(
                        `purchaseRequest.summary.purchaseOrderStatus.itemDetails.status`
                      )
                    }}
                  </div>
                </li>
                <li
                  v-for="item in purchaseRequest.items"
                  :key="item.id"
                  class="border-b border-gray-200 py-4 last:border-0 last:pb-1 flex flex-col gap-y-1"
                >
                  <div class="flex justify-between items-center">
                    <span
                      class="truncate mr-4 text-gray-600"
                      :title="item.name"
                    >
                      <TruncateText :text="item.name" />
                    </span>
                    <StatusTag
                      :status="item.status"
                      :type="'order'"
                      size="small"
                    />
                  </div>
                  <div
                    v-if="item.shippingDetail?.plannedShipmentDate"
                    class="flex justify-between items-center"
                  >
                    <p class="text-xs text-gray-500">
                      {{
                        $t(
                          `purchaseRequest.summary.purchaseOrderStatus.itemDetails.plannedShipmentDate`
                        )
                      }}
                    </p>
                    <p class="text-xs text-gray-500 font-medium">
                      {{
                        formattedDate(item.shippingDetail.plannedShipmentDate)
                      }}
                    </p>
                  </div>
                  <div
                    v-if="item.shippingDetail?.plannedDeliveryDate"
                    class="flex justify-between items-center"
                  >
                    <p class="text-xs text-gray-500">
                      {{
                        $t(
                          `purchaseRequest.summary.purchaseOrderStatus.itemDetails.plannedDeliveryDate`
                        )
                      }}
                    </p>
                    <p class="text-xs text-gray-500 font-medium">
                      {{
                        formattedDate(item.shippingDetail.plannedDeliveryDate)
                      }}
                    </p>
                  </div>

                  <div
                    v-if="item.shippingDetail?.carrier"
                    class="flex justify-between items-center"
                  >
                    <p class="text-xs text-gray-500">
                      {{
                        $t(
                          `purchaseRequest.summary.purchaseOrderStatus.itemDetails.carrier`
                        )
                      }}
                    </p>
                    <p class="text-xs text-gray-500 font-medium">
                      {{ item.shippingDetail.carrier }}
                    </p>
                  </div>
                  <div
                    v-if="item.shippingDetail?.trackingNumber"
                    class="flex justify-between items-center"
                  >
                    <p class="text-xs text-gray-500">
                      {{
                        $t(
                          `purchaseRequest.summary.purchaseOrderStatus.itemDetails.trackingNumber`
                        )
                      }}
                    </p>
                    <p class="text-xs text-gray-500 font-medium">
                      {{ item.shippingDetail?.trackingNumber }}
                    </p>
                  </div>
                  <div
                    v-if="
                      generateTrackingLink(
                        item.shippingDetail?.carrier,
                        item.shippingDetail?.trackingNumber
                      )
                    "
                    class="flex justify-center"
                  >
                    <p class="text-sm text-primary font-medium hover:underline">
                      <a
                        :href="
                          generateTrackingLink(
                            item.shippingDetail?.carrier,
                            item.shippingDetail?.trackingNumber
                          )
                        "
                        target="_"
                      >
                        {{
                          $t(
                            `purchaseRequest.summary.purchaseOrderStatus.itemDetails.trackingLink`
                          )
                        }}</a
                      >
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </CollapseTransition>
      </div>
    </div>
    <!-- END NEW -->
  </div>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import trackingMixin from "@/components/mixins/trackingMixin.js";
import {
  PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDER_STATUSES_STYLES,
  PURCHASE_REQUEST_ITEM_STATUSES,
} from "@/utils/constants";
import StatusTag from "@/components/shared/StatusTag";

export default {
  components: {
    CollapseTransition,
    StatusTag,
  },
  mixins: [trackingMixin],
  props: {
    purchaseOrder: {
      type: Object,
      required: true,
    },
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
    isSupplierRequest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDetails: true,
    };
  },
  created() {
    this.PURCHASE_ORDER_STATUSES = PURCHASE_ORDER_STATUSES;
    this.PURCHASE_ORDER_STATUSES_STYLES = PURCHASE_ORDER_STATUSES_STYLES;
    this.PURCHASE_REQUEST_ITEM_STATUSES = PURCHASE_REQUEST_ITEM_STATUSES;
  },
};
</script>
