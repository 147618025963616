<template>
  <ProjectForm ref="projectForm" :on-save="onSave" />
</template>

<script>
import ProjectForm from "@/components/Projects/ProjectForm";
import { mapActions } from "vuex";
import { PROJECT_MODULE, CREATE_PROJECT } from "@/store/Project/types";

export default {
  name: "AddProject",
  components: {
    ProjectForm,
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      createProject: CREATE_PROJECT,
    }),
    onSave(project) {
      this.$refs.projectForm.v$.$validate();
      if (!this.$refs.projectForm.v$.$error) {
        project = {
          ...project,
          approvers: [project.approvers],
          budgetOwners: [project.budgetOwners],
        };
        this.$refs.projectForm.toggleLoading();
        this.createProject(project)
          .then(() => {
            this.$router.push({ name: "Projects" });
            this.showNotification(
              "Project has been added successfully",
              "success"
            );
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => this.$refs.projectForm.toggleLoading());
      }
    },
  },
};
</script>
