import axiosInstance from "./setup/axiosSetup";
import * as cachios from "cachios";

const cachedInstance = cachios.create(axiosInstance);

export const patch = (url, body = {}) => {
  return axiosInstance.patch(url, body);
};

export const post = (url, body, options = {}) => {
  return axiosInstance.post(url, body, options);
};

export const put = (url, body) => {
  return axiosInstance.put(url, body);
};

export const get = (url, params = {}, options = {}) => {
  return axiosInstance.get(url, params, options);
};

export const deleteObject = (url) => {
  return axiosInstance.delete(url);
};

export const cachedGet = (url, params = {}, options = {}) => {
  return cachedInstance.get(url, params, options);
};
