import * as DownloadTypes from "./types.js";

export default {
  [DownloadTypes.SET_LIST_PARAMS]: (state, payload) => {
    state.listParams = payload;
  },
  [DownloadTypes.SET_LIST]: (state, payload) => {
    state.downloadList = payload.filter((item) => item.file);
  },
  [DownloadTypes.SET_DOWNLOAD_STATUS]: (
    state,
    { storeState, downloading, value, error }
  ) => {
    const fieldName = storeState.replace("_", "");
    if (!state[fieldName]) state[fieldName] = {};

    state[fieldName].downloading = downloading;
    state[fieldName].value = value;
    state[fieldName].error = error;
  },
  [DownloadTypes.SET_UPLOAD_STATUS]: (
    state,
    { storeState, inProgress, error, value }
  ) => {
    if (!state.uploads[storeState]) state.uploads[storeState] = {};

    state.uploads[storeState].inProgress = inProgress;
    state.uploads[storeState].error = error;
    state.uploads[storeState].value = value;
  },
};
