import { get } from "../api";
import { DEPT_ANALYTICS_BUDGET_URL } from "@/utils/services/apiUrls.js";
import {
  SET_DEPARTMENT_BUDGET_ANALYTICS,
  SET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS,
} from "@/store/Dashboard/types.js";
// import { filterZeroValues } from "@/utils/utility_methods";

export const getDepartmentBudgetAnalytics = (
  commit,
  resolve,
  reject,
  params
) => {
  get(DEPT_ANALYTICS_BUDGET_URL, { params })
    .then((response) => {
      commit(SET_DEPARTMENT_BUDGET_ANALYTICS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getSingleDepartmentBudgetAnalytics = (
  commit,
  resolve,
  reject,
  params
) => {
  get(DEPT_ANALYTICS_BUDGET_URL, { params })
    .then((response) => {
      commit(SET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
