// Modules
export const PROJECT_MODULE = "PROJECT_MODULE";

//Actions

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const GET_PROJECTS_LIST = "GET_PROJECTS_LIST";

//Mutations
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECTS_LIST_FILTERS = "SET_PROJECTS_LIST_FILTERS";
export const SET_PROJECT_LIST_PARAMS = "SET_PROJECT_LIST_PARAMS";
export const SET_PROJECT = "SET_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";

export const SET_PROJECTS_LIST = "SET_PROJECTS_LIST";
