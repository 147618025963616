<template>
  <div class="flex flex-col">
    <div class="flex flex-row gap-3">
      <div class="flex flex-col w-24">
        <Input
          :id="'min-currency-input'"
          name="start"
          :value="amountRange?.start || null"
          type="number"
          class="filter-input group"
          label="Min amount"
          placeholder="0"
          @input="handleRangeUpdate"
        />
      </div>
      <div class="flex flex-col w-24">
        <Input
          :id="'max-currency-input'"
          name="end"
          :value="amountRange?.end || null"
          type="number"
          :min="amountRange?.start || 0"
          class="filter-input group"
          label="Max amount"
          placeholder="0"
          @input="handleRangeUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/shared/Input";

export default {
  components: { Input },
  props: {
    handleAmountRange: {
      type: Function,
      default: () => {},
    },
    amountFilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      amountRange: {
        start: this.amountFilter?.start || 0,
        end: this.amountFilter?.end || 0,
      },
    };
  },
  methods: {
    handleRangeUpdate({ target: { name, value } }) {
      this.amountRange[name] = value;
      this.handleAmountRange(this.amountRange);
    },
  },
};
</script>
