<template>
  <ul role="list" class="list-holder">
    <li v-for="request in item.purchaseRequests" :key="request">
      <h2 class="item-title">
        {{ request.name }}
      </h2>
      <ul class="item-list">
        <li v-for="prItem in request.purchaseRequestItems" :key="prItem.id">
          <p class="tag-label">{{ prItem.name }}</p>
          <div
            class="tag-badge hover: cursor-pointer"
            @click="() => unassignItem(prItem)"
          >
            <ScissorsIcon class="h-4 w-4" />
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import {
  PURCHASE_REQUEST_MODULE,
  CHANGE_SHOPPING_LIST_RELATION,
} from "@/store/PurchaseRequest/types";
import { mapActions } from "vuex";
import { ScissorsIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ScissorsIcon,
  },

  props: {
    item: { type: Object, required: true },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      changeShoppingListRelation: CHANGE_SHOPPING_LIST_RELATION,
    }),
    apiPayload(shoppingListitem) {
      return {
        purchaseRequestItem: shoppingListitem.id,
        shoppingListItem: this.item.id,
        shoppingList: shoppingListitem.shoppingList,
      };
    },
    unassignItem(item) {
      this.changeShoppingListRelation({
        method: "remove",
        payload: this.apiPayload(item),
      })
        .then(() =>
          this.showNotification(this.$t("shoppingList.toasts.remove"))
        )
        .catch((error) => this.showErrorMessage(error));
    },
    firstLetterOfProduct(name) {
      if (name) {
        return name.charAt(0);
      } else return "";
    },
  },
};
</script>
<style scoped>
.list-holder {
  @apply divide-y divide-gray-200;
}
.list-holder > li {
  @apply pt-4 pb-4 first:pt-0 last:pb-0;
}
.item-title {
  @apply text-sm text-primary font-semibold mb-2;
}
.tag-label {
  @apply text-sm text-gray-600;
}
.tag-badge {
  @apply px-4 py-1 inline-flex text-xs font-semibold rounded-full text-white capitalize bg-hivebuy-red ml-4;
}

.item-list {
}

.item-list > li {
  @apply flex items-center justify-between mb-2 pl-3 last:mb-0 relative;
}

.item-list > li:before {
  content: "";
  @apply w-[5px] h-[5px] rounded-full bg-hivebuy-red mr-2 absolute left-0;
}
</style>
