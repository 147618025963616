<template>
  <div class="flex">
    <h3 class="page-title">
      {{ $t("companyManagement.integrations.title") }}
    </h3>
  </div>
  <div v-if="isIntegrationsLoading" class="h-36">
    <HivebuyLoader />
  </div>
  <div v-else class="custom-card">
    <div
      class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"
    >
      <template
        v-for="integration in allowedIntegrations"
        :key="integration.id"
      >
        <Slack v-if="integration.name == 'Slack'" :integration="integration" />
        <Webhook v-else-if="integration.name == 'Webhooks'" />
        <Zapier
          v-else-if="integration.name == 'Zapier'"
          :integration="integration"
        />
        <Datev
          v-else-if="integration.name == 'Datev'"
          :integration="integration"
        />
        <BuisnessCentral
          v-else-if="integration.name == 'Business Central' && false"
          :integration="integration"
        />
        <GenericIntegration
          v-else
          :integration="integration"
          :parent="integration.parent"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { HivebuyLoader } from "@/components/shared";
import { mapActions, mapState, mapGetters } from "vuex";
import BuisnessCentral from "@/components/CompanyManagement/Integrations/BuisnessCentral";
import Zapier from "@/components/CompanyManagement/Integrations/Zapier";
import Webhook from "@/components/CompanyManagement/Integrations/Webhook";
import Slack from "@/components/CompanyManagement/Integrations/Slack";
import Datev from "@/components/CompanyManagement/Integrations/Datev/";
import GenericIntegration from "@/components/CompanyManagement/Integrations/GenericIntegration";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATIONS,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    Zapier,
    Webhook,
    Slack,
    Datev,
    HivebuyLoader,
    BuisnessCentral,
    GenericIntegration,
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["integrations"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "integrationName",
      "integrationParent",
    ]),
    isIntegrationsLoading() {
      return !this.integrations.length;
    },
    allowedIntegrations() {
      const featureFlagBasedIntegrations = {
        Zapier: this.isFeatureAllowed("zapierIntegration"),
        Datev: this.isFeatureAllowed("datev"),
        History: false,
      };

      return this.integrations.filter((integration) => {
        integration.parent = this.integrationParent(
          integration.integrationSlug
        );
        integration.name = this.integrationName(integration.integrationSlug);
        return (
          !Object.keys(featureFlagBasedIntegrations).includes(
            integration.name
          ) || featureFlagBasedIntegrations[integration.name]
        );
      });
    },
  },

  created() {
    if (!this.integrations.length) {
      this.getIntegrations();
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getIntegrations: GET_INTEGRATIONS,
    }),
  },
};
</script>
