<template>
  <div>
    <h2 class="sr-only">Notifications</h2>

    <div class="">
      <List :notifications="notifications" />
    </div>
  </div>
</template>
<script>
import List from "@/components/Notifications/List.vue";

export default {
  components: {
    List,
  },
};
</script>
