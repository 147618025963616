import * as Types from "./types.js";
import {
  getPurchaseRequestsAnalytics,
  getDepartmentAnalytics,
  getDepartmentBudgetAnalytics,
  getSingleDepartmentBudgetAnalytics,
  getSearchData,
  saveSelfSignupCompanyInfo,
  getDetailedAnalytics,
  getAnalyticsStatistics,
  getMyPurchaseRequests,
  getUserActionRequiredPurchaseRequests,
} from "@/utils/services";
import {
  deleteNews,
  getNews,
  getUserRelevantNews,
  markNewsAsRead,
  saveNews,
} from "@/utils/services/models/news";

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Types.GET_SEARCH_DATA]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getSearchData(payload, resolve, reject);
    });
  },
  [Types.GET_PURCHASE_REQUESTS_ANALYTICS]: ({ commit }, noOfRecords) => {
    return new Promise((resolve, reject) => {
      getPurchaseRequestsAnalytics(commit, resolve, reject, noOfRecords);
    });
  },
  [Types.GET_DEPARTMENT_ANALYTICS]: ({ commit }, noOfRecords) => {
    return new Promise((resolve, reject) => {
      getDepartmentAnalytics(commit, resolve, reject, noOfRecords);
    });
  },

  [Types.GET_DEPARTMENT_BUDGET_ANALYTICS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getDepartmentBudgetAnalytics(commit, resolve, reject, params);
    });
  },
  [Types.GET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getSingleDepartmentBudgetAnalytics(commit, resolve, reject, params);
    });
  },

  [Types.SAVE_SELF_SIGNUP_COMPANY_INFO]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveSelfSignupCompanyInfo(commit, resolve, reject, payload);
    });
  },

  [Types.GET_DETAILED_ANALYTICS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getDetailedAnalytics(commit, resolve, reject, payload);
    });
  },
  [Types.GET_ANALYTICS_STATISTICS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getAnalyticsStatistics(commit, resolve, reject, payload);
    });
  },
  [Types.GET_MY_PURCHASE_REQUESTS]: ({ commit }, paginationParams = {}) => {
    return new Promise((resolve, reject) => {
      getMyPurchaseRequests(commit, resolve, reject, paginationParams);
    });
  },
  [Types.GET_ACTION_REQUIRED_PURCHASE_REQUESTS]: (
    { commit },
    paginationParams
  ) => {
    return new Promise((resolve, reject) => {
      getUserActionRequiredPurchaseRequests(
        commit,
        resolve,
        reject,
        paginationParams
      );
    });
  },

  [Types.GET_NEWS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getNews(commit, resolve, reject);
    });
  },

  [Types.SAVE_NEWS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveNews(commit, resolve, reject, payload);
    });
  },

  [Types.DELETE_NEWS]: ({ commit }, newsId) => {
    return new Promise((resolve, reject) => {
      deleteNews(commit, resolve, reject, newsId);
    });
  },

  [Types.GET_USER_RELEVANT_NEWS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getUserRelevantNews(commit, resolve, reject);
    });
  },

  [Types.MARK_NEWS_AS_READ]: ({ commit }, { id, payload }) => {
    return new Promise((resolve, reject) => {
      markNewsAsRead(commit, resolve, reject, id, payload);
    });
  },
};
