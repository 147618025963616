import { createI18n } from "vue-i18n";

import { defaultLocale, languages } from "./locales/index.js";

const messages = Object.assign(languages);

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    },
    currencyActual: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  "en-EN": {
    currency: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
    },
    currencyActual: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  "sv-SE": {
    currency: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
    },
    currencyActual: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    budget: {
      style: "currency",
      currency: "SEK",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "SEK",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  "de-DE": {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
    },
    currencyActual: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
    },
    currencyActual: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  "pl-PL": {
    currency: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
    },
    currencyActual: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "PLN",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "PLN",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
  "de-CH": {
    currency: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
    },
    currencyActual: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      maximumFractionDigits: 4,
    },
    percent: {
      style: "percent",
      minimumFractionDigits: 1,
    },
    percent0: {
      style: "percent",
      minimumFractionDigits: 0,
    },
    budget: {
      style: "currency",
      currency: "CHF",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    currencyWithDecimal: {
      style: "currency",
      currency: "CHF",
      currencyDisplay: "symbol",
      minimumFractionDigits: 2,
    },
  },
};

export const i18n = createI18n({
  locale: defaultLocale(),
  fallbackLocale: defaultLocale(),
  numberFormats,
  messages,
});
