<template>
  <div class="custom-card no-shadow mt-4 mb-4 bg-white border border-gray-100">
    <dl class="flex p-2 gap-2 md:gap-4 px-4 py-4">
      <RequestAddress
        v-model="companyAddressValue"
        :requested-for-id="product.requestedForId"
        :is-edit="editable"
        :show-save-buttons="true"
        :loading="isLoading('companyAddress')"
      />
      <RequestAddress
        v-if="invoiceAddressId"
        v-model="invoiceAddressValue"
        :label="$t('purchaseRequest.detail.invoiceAddress')"
        is-invoice-address
        :requested-for-id="product.requestedForId"
        :is-edit="editable"
        :show-save-buttons="true"
        :loading="isLoading('invoiceAddress')"
      />

      <PaymentMethod
        v-model="paymentMethodValue"
        :list="[getPaymentMethodId]"
        :requested-for-id="product.requestedForId"
        :show-save-buttons="true"
        :loading="isLoading('paymentMethod')"
      />
    </dl>
  </div>
</template>
<script>
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";
import { PaymentMethod, RequestAddress } from "@/components/shared/index";
import {
  extractPaymentDetailsMixin,
  addressIdExtractorMixin,
} from "@/components/mixins/index";
import { AUTH_MODULE } from "@/store/Auth/types";
import { ADMIN_ROLES } from "@/utils/constants";
import { intersection } from "@/utils/utility_methods";
export default {
  components: {
    PaymentMethod,
    RequestAddress,
  },
  mixins: [extractPaymentDetailsMixin, addressIdExtractorMixin],
  props: {
    updateCompleteRequest: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: {
      type: Object,
      required: false,
      default: null,
    },
    product: {
      type: Object,
      required: false,
      default: null,
    },
    updateAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["paymentMethods", "company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getPaymentMethod"]),
    editable() {
      const superRoles = intersection(this.user.roles, ADMIN_ROLES);
      return !!superRoles.length;
    },
    companyAddressValue: {
      get() {
        return this.companyAddressId;
      },
      set(value) {
        this.updateAttribute("companyAddress", value);
      },
    },
    invoiceAddressValue: {
      get() {
        return this.invoiceAddressId;
      },
      set(value) {
        this.updateAttribute("invoiceAddress", value);
      },
    },
    paymentMethodValue: {
      get() {
        return this.getPaymentMethodId;
      },
      set(value) {
        this.updateAttribute("paymentMethod", value);
      },
    },
  },
  methods: {
    isLoading(key) {
      return this.loading === key;
    },
    updateAttribute(key, value) {
      this.loading = key;
      this.updateAction(this.payload(key, value))
        .then(() => {
          this.showNotification(
            this.$t("toasts.uploadSuccess", {
              name: this.$t(`global.${key}`),
            })
          );
        })
        .catch(() => {
          this.showErrorMessage();
        })
        .finally(() => {
          this.loading = null;
        });
    },
    payload(key, value) {
      let items = [];
      let payload = {};
      items = this.purchaseRequest.items.map((item) => {
        payload = {
          id: item.id,
        };
        if (this.updateCompleteRequest) {
          payload[key] = value;
        } else if (item.id === this.product.id) {
          payload[key] = value;
        }
        return payload;
      });
      payload = {
        items: items,
        id: this.purchaseRequest.id,
      };
      return { payload, purchaseRequestId: this.purchaseRequest.id };
    },
  },
};
</script>

<style scoped>
.section-title {
  @apply text-xs mb-1;
}

.custom-card {
  @apply p-0;
}

.section {
  @apply px-3 flex-1 last:border-r-0;
}
</style>
