import useValidate from "@vuelidate/core";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_COMPANY,
} from "@/store/CompanyManagement/types";

import errorMixin from "@/components/mixins/errorMixin";

export default {
  mixins: [errorMixin],
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      v$: useValidate(),
      loading: false,
    };
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateCompany: UPDATE_COMPANY,
    }),
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    updateCompanyData(payload, successMessage) {
      this.loading = true;
      this.updateCompany(payload)
        .then(() => {
          this.showNotification(successMessage);
          this.toggleEditMode();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
