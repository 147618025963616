<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column bg-primary text-white">
        <DeliverSVG class="h-5 w-5" />
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex gap-x-1">
          <span class="text-gray-400">
            {{ $t("timeline.deliveredBy") }}
          </span>
          <span class="text-gray-900 font-semibold">
            <UserWithAvatar :user="details.createdBy" :show-name-only="true" />
          </span>
          <span v-if="details.statusDate" class="text-gray-500 font-normal"
            >@
            <TimeDisplay
              :time="details.statusDate"
              :show-time-ago="false"
              :hide-tooltip="true"
          /></span>
        </div>
        <p class="text-sm md:text-xs text-gray-800 mt-2">
          {{ details?.reason }}
        </p>
        <div class="text-xs text-gray-500 my-1">
          <TimeDisplay :time="details.createdAt || details.statusDate" />
        </div>
      </div>
      <div class="avatar-column">
        <div>
          <UserWithAvatar
            :user="details.createdBy"
            :avatar-dimension="9"
            show-tooltip
            class="cursor-help"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeliverSVG from "@/assets/images/DeliverSVG.vue";
import { UserWithAvatar, TimeDisplay } from "@/components/shared/index";
import { timeDifference } from "@/utils/timeHelper.js";
import { memberDetailsMixin } from "@/components/mixins/index";
import { fullName } from "@/utils/utility_methods";
export default {
  components: { DeliverSVG, UserWithAvatar, TimeDisplay },
  mixins: [memberDetailsMixin],
  props: {
    details: { type: Object, required: true },
  },
  data() {
    return { orderedBy: "N/A" };
  },
  mounted() {
    this.setOrderedByName();
  },
  methods: {
    timeDifference,
    async setOrderedByName() {
      this.orderedBy = fullName(
        await this.getMemberDetails(this.details?.createdBy)
      );
    },
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
