<template>
  <svg
    id="Icons"
    viewBox="0 0 74 74"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
    stroke="currentColor"
  >
    <path
      d="M54,38H20a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1H54a1,1,0,0,1,1,1V37A1,1,0,0,1,54,38ZM21,36H53V4H21Z"
    />
    <path
      d="M41,15a1,1,0,0,1-.707-.293L37,11.414l-3.293,3.293A1,1,0,0,1,32,14V3a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V14a1,1,0,0,1-1,1ZM37,9a1,1,0,0,1,.707.293L40,11.586V4H34v7.586l2.293-2.293A1,1,0,0,1,37,9Z"
    />
    <path
      d="M71,72H37a1,1,0,0,1-1-1V37a1,1,0,0,1,1-1H71a1,1,0,0,1,1,1V71A1,1,0,0,1,71,72ZM38,70H70V38H38Z"
    />
    <path
      d="M58,49a1,1,0,0,1-.707-.293L54,45.414l-3.293,3.293A1,1,0,0,1,49,48V37a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V48a1,1,0,0,1-1,1Zm-4-6a1,1,0,0,1,.707.293L57,45.586V38H51v7.586l2.293-2.293A1,1,0,0,1,54,43Z"
    />
    <path
      d="M37,72H3a1,1,0,0,1-1-1V37a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1V71A1,1,0,0,1,37,72ZM4,70H36V38H4Z"
    />
    <path
      d="M24,49a1,1,0,0,1-.707-.293L20,45.414l-3.293,3.293A1,1,0,0,1,15,48V37a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V48a1,1,0,0,1-1,1Zm-4-6a1,1,0,0,1,.707.293L23,45.586V38H17v7.586l2.293-2.293A1,1,0,0,1,20,43Z"
    />
  </svg>
</template>

<script>
export default {
  name: "QuantitySVG",
};
</script>
