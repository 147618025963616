<template>
  <div
    v-if="currentRequest.category"
    class="flex justify-end items-center text-gray-700"
  >
    <div class="flex group">
      <div
        class="text-sm font-medium cursor-pointer group-hover:text-red-700"
        @click="showResetModal = true"
      >
        <!-- Reset all data -->
        {{ $t("button.resetAll") }}
      </div>
      <RefreshIcon
        class="h-4 w-4 ml-1 cursor-pointer group-hover:text-red-700"
        @click="showResetModal = true"
      />
    </div>
  </div>
  <ConfirmDialog
    text="Start over with a fresh Purchase Request?"
    :show-modal="showResetModal"
    :close-modal="onResetClose"
    title="Reset Purchase Request"
    :confirm-modal="onReset"
  />
</template>

<script>
import { RefreshIcon } from "@heroicons/vue/solid";
import { ConfirmDialog } from "@/components/shared/index";
import {
  PURCHASE_REQUEST_MODULE,
  RESET_CURRENT_REQUEST,
} from "@/store/PurchaseRequest/types.js";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    RefreshIcon,
    ConfirmDialog,
  },
  data() {
    return {
      showResetModal: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetState: RESET_CURRENT_REQUEST,
    }),
    onResetClose() {
      this.showResetModal = false;
    },

    onReset() {
      this.showResetModal = false;
      this.resetState();
    },
  },
};
</script>

<style></style>
