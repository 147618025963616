<template>
  <div
    v-if="!isObjectEmpty(statusMapping)"
    :class="[
      statusMapping?.colorClass,
      classes,
      onlyIcon ? sizeClasses.icon[size] : sizeClasses.withoutIcon[size],
      {
        'inline-block px-2 font-regular rounded-full whitespace-nowrap':
          !onlyIcon,
        'flex items-center rounded-full boxItem ': onlyIcon,
      },
    ]"
  >
    <Tip v-if="onlyIcon && showToolTip">
      <template #placeholder>
        <component :is="statusMapping?.icon" class="pr-1" />
      </template>
      <template #content>
        <div class="text-sm font-normal">
          {{ text }}
        </div>
      </template>
    </Tip>
    <component :is="statusMapping?.icon" v-else-if="onlyIcon && !showToolTip" />
    <p v-else>{{ text }}</p>
  </div>
</template>
<script>
import {
  CheckCircleIcon,
  ClockIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  BanIcon,
} from "@heroicons/vue/solid";
import OrderSVG from "@/assets/images/OrderSVG";
import DeliverSVG from "@/assets/images/DeliverSVG";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import { isObjectEmpty } from "@/utils/utility_methods";

const REQUEST_STATUS_MAPPING = {
  L: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  P: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  R: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
  A: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  C: {
    icon: BanIcon,
    colorClass: "bg-hivebuy-grey text-white",
  },
  D: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-grey text-white",
  },
  O: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  ID: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-darkgreen text-white",
  },
};
const SUPPLIER_ORDERS_STATUS_MAPPING = {
  SENT: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  RESENT: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-darkgreen text-white",
  },
};
const ORDER_STATUS_MAPPING = {
  P: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  S: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },

  O: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  D: {
    icon: DeliverSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  R: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
  CA: {
    icon: BanIcon,
    colorClass: "bg-gray-300 text-gray-600",
  },
  CO: {
    icon: CheckCircleIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  A: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  I: {
    icon: InvoiceSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  PA: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  RS: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-green text-white",
  },
  RT: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  ID: {
    icon: OrderSVG,
    colorClass: "bg-hivebuy-darkgreen text-white",
  },
};
const CONTRACT_STATUS_MAPPING = {
  P: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  A: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  R: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
  AC: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  IA: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-grey text-white",
  },
};
export const INVOICE_STATUS_MAPPING = {
  O: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  A: {
    icon: ThumbUpIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  R: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
  P: {
    icon: ClockIcon,
    colorClass: "bg-primary text-white",
  },
  RV: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
  NI: {
    icon: BanIcon,
    colorClass: "bg-gray-300 text-gray-600",
  },
  N: {
    icon: BanIcon,
    colorClass: "bg-[#00D1FF] text-gray-100",
  },
};
const PROPOSAL_STATUS_MAPPING = {
  DR: {
    icon: ClockIcon,
    colorClass: "bg-gray-300 text-gray-600",
  },
  OP: {
    icon: ClockIcon,
    colorClass: "bg-hivebuy-yellow text-white",
  },
  CO: {
    icon: CheckCircleIcon,
    colorClass: "bg-hivebuy-green text-white",
  },
  CL: {
    icon: ThumbDownIcon,
    colorClass: "bg-hivebuy-red text-white",
  },
};

const STATUS_MAPPING = {
  request: REQUEST_STATUS_MAPPING,
  order: ORDER_STATUS_MAPPING,
  contract: CONTRACT_STATUS_MAPPING,
  invoice: INVOICE_STATUS_MAPPING,
  proposal: PROPOSAL_STATUS_MAPPING,
  supplierOrders: SUPPLIER_ORDERS_STATUS_MAPPING,
};

export default {
  components: {
    OrderSVG,
  },
  props: {
    showToolTip: { type: Boolean, default: true },
    status: { type: String, required: true },
    type: {
      type: String,
      default: "request",
      validator: (prop) => Object.keys(STATUS_MAPPING).includes(prop),
    },
    size: {
      type: String,
      default: "normal",
      validator: (prop) => ["small", "normal"].includes(prop),
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
    classes: { type: String, default: "" },
    customText: { type: String, default: "" },
  },
  computed: {
    sizeClasses() {
      return {
        icon: { normal: "w-9 h-9 p-2", small: "w-6 h-6 p-1" },
        withoutIcon: { normal: "py-1 text-sm", small: "py-0.5 text-xs" },
      };
    },
    text() {
      return this.customText || this.$t(`statuses.${this.type}.${this.status}`);
    },
    statusMapping() {
      return STATUS_MAPPING[this.type][this.status?.toUpperCase()];
    },
  },
  methods: {
    isObjectEmpty,
  },
};
</script>
