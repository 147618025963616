<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
  >
    <template #icon>
      <UserAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{ $t("proposals.addProposalModal.heading") }}
      </h3>
    </template>
    <template #body>
      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <div class="sm:col-span-2">
          <Input
            :id="'title'"
            :error-text="error(v$.proposal.title)"
            :value="proposal.title"
            :name="'title'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="$t('proposals.addProposalModal.fields.title')"
            :label="$t('proposals.addProposalModal.fields.title')"
          />
        </div>

        <div class="sm:col-span-2">
          <TextArea
            :name="'description'"
            :placeholder="$t('proposals.addProposalModal.fields.description')"
            :label="$t('proposals.addProposalModal.fields.description')"
            :value="proposal.description"
            :rows="5"
            :on-value-change="onAttributeChange"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>

      <Button :on-click-handler="onSave">
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import useValidate from "@vuelidate/core";
import { UserAddIcon } from "@heroicons/vue/solid";
import { Button, Modal, Input, TextArea } from "@/components/shared/index";
import { errorMixin } from "@/components/mixins/index.js";
import { required } from "@vuelidate/validators";
import { mapActions, mapMutations, mapState } from "vuex";

import {
  CREATE_PROPOSAL,
  PROPOSAL_MODULE,
  SET_PROPOSALS_LIST_FILTERS,
} from "@/store/Proposal/types";
import { PROPOSAL_STATUSES_MAPPING } from "@/utils/constants";

export default {
  components: {
    UserAddIcon,
    Modal,
    Button,
    Input,
    TextArea,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      proposal: {},
    };
  },
  computed: {
    ...mapState(PROPOSAL_MODULE, {
      proposalsListFilters: (state) => state.proposalsListFilters,
      paginationParams: (state) => state.proposalsListFilters.paginationParams,
    }),
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      createProposal: CREATE_PROPOSAL,
    }),
    ...mapMutations(PROPOSAL_MODULE, {
      setProposalsListFilters: SET_PROPOSALS_LIST_FILTERS,
    }),
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveProposal();
      }
    },
    saveProposal() {
      const { title, description } = this.proposal;
      const payload = {
        title,
        description,
        status: PROPOSAL_STATUSES_MAPPING.draft,
      };
      this.createProposal(payload)
        .then((response) => {
          this.showNotification("Proposal board has been successfully created");
          this.closeModal();
          this.$router.push({
            name: "ProposalBoard",
            params: { id: response.id },
          });
          this.resetValues();
        })
        .catch((error) => this.showErrorMessage(error));
    },

    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.proposal = {};
        this.v$.$reset();
      }, 1000);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.proposal[name] = value;
    },
  },
  validations() {
    return {
      proposal: {
        title: { required },
      },
    };
  },
};
</script>

<style scoped></style>
