<template>
  <DatePicker
    :attributes="defaultDesign"
    color="teal"
    :locale="user.language"
    show-weeknumbers
    :masks="defaultDateMask"
    :max-date="maxDate"
    :min-date="minDate"
    :popover="{ visibility: visibility }"
    class="relative"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <template #default="{ inputValue, inputEvents }">
      <input
        :value="inputValue"
        :placeholder="fieldName"
        :class="[
          'appearance-none block w-full px-3 min-h-[42px] border border-gray-200 rounded-md placeholder-gray-400 focus:outline-none  focus:ring-primary focus:ring-1 focus:border-primary sm:text-sm',
          { 'border-red-400': validationError },
        ]"
        v-on="inputEvents"
      />
      <div v-if="ocrDetails" class="absolute top-1.5 right-2">
        <OCRButtonInput
          :field-value="value"
          :ocr-details="ocrDetails"
          :on-update-value="updateValue"
          :label="fieldName"
        />
      </div>
      <FieldError
        v-if="validationError"
        :text="validationErrorText || $t('errors.genericError')"
      />
    </template>
  </DatePicker>
</template>

<script>
import { DatePicker } from "v-calendar";
import FieldError from "@/components/shared/FieldError.vue";
import OCRButtonInput from "@/components/shared/OCRButtonInput.vue";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import { defaultDateAttributes } from "@/utils/utility_methods";

export default {
  components: {
    DatePicker,
    FieldError,
    OCRButtonInput,
  },
  props: {
    maxDate: {
      type: [String, Date],
      default: null,
    },
    minDate: {
      type: [String, Date],
      default: null,
    },
    value: {
      type: [String, Date],
      default: null,
    },

    validationError: {
      type: Boolean,
      required: false,
    },
    validationErrorText: {
      type: [String, null],
      default: null,
    },
    fieldName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    onMouseEnter: {
      type: Function,
      default: () => {},
    },
    onMouseLeave: {
      type: Function,
      default: () => {},
    },
    ocrDetails: {
      type: Object,
      default: null,
    },
    onOcrUpdate: {
      type: Function,
      default: () => {},
    },
    visibility: {
      type: String,
      default: "click",
    },
  },
  emits: ["input"],
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    defaultDesign() {
      const attrs = [
        {
          key: "today",
          dates: new Date(),
          dot: "red",
        },
      ];
      return attrs;
    },
  },
  methods: {
    defaultDateAttributes,
    updateValue(value) {
      this.onOcrUpdate(this.name, value);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.vc-popover-content-wrapper) {
  z-index: 9999;
}
</style>
