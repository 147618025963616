import { post, patch, get, deleteObject, cachedGet } from "../api";
import { PAYMENT_METHODS_API_URL } from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";

export const addPaymentMethod = (payload, resolve, reject) => {
  post(PAYMENT_METHODS_API_URL, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePaymentMethod = (
  paymentMethodId,
  payload,
  resolve,
  reject
) => {
  if (!paymentMethodId) return;

  patch(`${PAYMENT_METHODS_API_URL}${paymentMethodId}/`, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPaymentMethods = (commit, resolve, reject) => {
  get(PAYMENT_METHODS_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_PAYMENT_METHODS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deletePaymentMethod = (paymentMethodId, resolve, reject) => {
  if (!paymentMethodId) return;

  deleteObject(`${PAYMENT_METHODS_API_URL}${paymentMethodId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPaymentMethod = (paymentMethodId, commit, resolve, reject) => {
  if (!paymentMethodId) return;

  cachedGet(`${PAYMENT_METHODS_API_URL}${paymentMethodId}/`)
    .then((response) => {
      commit(CompanyManagementTypes.SET_PAYMENT_METHOD, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
