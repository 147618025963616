<template>
  <div
    v-if="item?.shoppingItems?.length"
    class="px-2 py-0.5 hover:bg-gray-100 border border-transparent hover:border-gray-200 text-hivebuy-grey hover:text-primary rounded"
  >
    <ShoppinglistSVG
      class="w-6 h-6"
      :alt="$t('shoppingList.buttons.assigned')"
      @click.stop="toggleModal"
    />
  </div>
  <Modal
    :show-modal="showModal"
    :close-modal="toggleModal"
    :size-classes="['sm:max-w-3xl']"
  >
    <template #header>
      {{ $t("shoppingList.title") }}
    </template>
    <template #icon>
      <ShoppinglistSVG class="text-white h-6 w-6" />
    </template>
    <template #body>
      <p class="text-gray-400 text-sm mb-4">
        The following Shopping List items have been linked to this Purchase
        Request. You can unlink them if necessary.
      </p>
      <ul
        v-if="
          shoppingListItems(request.shoppingLists, item?.shoppingItems).length
        "
        role="list"
        class="divide-y divide-gray-200"
      >
        <li
          v-for="list in shoppingListItems(
            request.shoppingLists,
            item?.shoppingItems
          )"
          :key="list?.id"
        >
          <div v-if="list.items" class="py-4">
            <h2 class="popup-title">{{ list.title }}</h2>
            <ul class="item-list">
              <li
                v-for="shoppingListItem in list.items"
                :key="shoppingListItem.id"
              >
                <div class="grid grid-cols-4 gap-4 p-4">
                  <div>
                    <p class="text-primary font-semibold mb-2">
                      {{ $t("purchaseRequest.summary.title") }}
                    </p>
                    <p>{{ shoppingListItem.name }}</p>
                  </div>

                  <div class="pl-4">
                    <p class="text-primary font-semibold mb-2">
                      {{ $t("purchaseRequest.summary.requestedFor") }}
                    </p>
                    <RequestedForDisplay
                      v-if="shoppingListItem.requestedFor"
                      :product="shoppingListItem"
                      :read-only="true"
                      :avatar-dimension="8"
                      show-name
                    />
                  </div>

                  <div class="pl-4">
                    <p class="text-primary font-semibold mb-2">
                      {{ $t("purchaseRequest.summary.requestedBy") }}
                    </p>
                    <div class="flex items-center">
                      <UserWithAvatar
                        :user="list?.requestedBy"
                        :avatar-dimension="8"
                        show-name
                      />
                    </div>
                  </div>

                  <div class="pl-4">
                    <p class="text-primary font-semibold mb-2">
                      {{ $t("purchaseRequest.summary.requestedAt") }}
                    </p>
                    <p>{{ formattedDate(list.requestedAt) }}</p>
                  </div>
                </div>

                <div
                  class="tag-badge cursor-pointer"
                  @click="() => unassignItem(list, shoppingListItem)"
                >
                  <ScissorsIcon class="w-4 h-4" />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div v-else>no shopping list item is assigned</div>
    </template>
    <template #footer>
      <Button
        type="button"
        :on-click-handler="toggleModal"
        :color="'gray translate-x-[50%]'"
      >
        {{ $t("buttons.close") }}
      </Button>
    </template>
  </Modal>
</template>
<script>
import {
  PURCHASE_REQUEST_MODULE,
  CHANGE_SHOPPING_LIST_RELATION,
  UNASSIGN_SHOPPING_LIST,
} from "@/store/PurchaseRequest/types";
import { SHOPPING_LIST_MODULE } from "@/store/ShoppingList/types.js";
import { ScissorsIcon } from "@heroicons/vue/solid";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ShoppinglistSVG from "@/assets/images/ShoppinglistSVG.vue";
import { Button, Modal, UserWithAvatar } from "@/components/shared";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";

export default {
  components: {
    Button,
    Modal,
    ShoppinglistSVG,
    ScissorsIcon,
    RequestedForDisplay,
    UserWithAvatar,
  },
  props: { position: { type: Number, required: true } },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, [
      "currentRequest",
      "openCurrentRequest",
    ]),
    ...mapGetters(SHOPPING_LIST_MODULE, ["shoppingListItems"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    request() {
      return this.openCurrentRequest
        ? this.requestDetails(this.openCurrentRequest)
        : this.currentRequest;
    },
    item() {
      if (!this.request) return {};
      return this.request.items.find((item) => item.position == this.position);
    },
    isNewRequest() {
      return (
        !this.openCurrentRequest && Object.keys(this.currentRequest).length
      );
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      unassignShoppingList: UNASSIGN_SHOPPING_LIST,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      changeShoppingListRelation: CHANGE_SHOPPING_LIST_RELATION,
    }),
    apiPayload(list, shoppingItem) {
      return {
        purchaseRequestItem: this.item.id,
        shoppingListItem: shoppingItem.id,
        shoppingList: list.id,
      };
    },
    unassignItem(list, item) {
      if (this.isNewRequest) this.unassignShoppingList(this.position);
      else {
        this.changeShoppingListRelation({
          method: "remove",
          payload: this.apiPayload(list, item),
        })
          .then(() => {
            this.showNotification(this.$t("shoppingList.toasts.remove"));
          })
          .catch((error) => this.showErrorMessage(error));
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped>
.shopping-badge {
  @apply bg-hivebuy-green px-3 py-1 inline-flex text-xs font-semibold rounded-full text-white capitalize hover:bg-opacity-80 transition-all;
}

.popup-title {
  @apply text-sm text-gray-700 font-semibold;
}

.item-list {
  @apply mt-3;
}

.item-list > li {
  @apply flex items-center justify-between mb-2 pb-2 pl-3 last:mb-0 relative border-b last:border-b-0;
}

.item-list > li:before {
  content: "";
  @apply w-[5px] h-[5px] rounded-full bg-hivebuy-red mr-2 absolute left-0;
}

.tag-badge {
  @apply px-4 py-1 inline-flex text-xs font-semibold rounded-full hover:text-white capitalize hover:bg-hivebuy-red ml-4 text-hivebuy-red bg-white;
}
</style>
