<template>
  <div>
    <AddProject v-if="!$route.params.id" />
    <EditProject v-else />
  </div>
</template>

<script>
import AddProject from "@/components/Projects/AddProject";
import EditProject from "@/components/Projects/EditProject";

export default {
  name: "ProjectView",
  components: {
    AddProject,
    EditProject,
  },
};
</script>
