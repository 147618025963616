<template>
  <apexchart
    type="bar"
    height="400"
    :options="chartOptions"
    :series="series"
    @data-point-selection="onRequestDataPointSelection"
  />
</template>
<script>
import { currencyLocaleMixin } from "@/components/mixins/index.js";
export default {
  mixins: [currencyLocaleMixin],
  inject: {
    onDataPointSelection: {
      default: () => {},
    },
  },
  props: {
    colors: { type: Array, required: true },
    categories: { type: Array, required: true },
    series: { type: Array, required: true },
    onRequestDataPointSelection: {
      type: Function,
      required: true,
    },
    customTooltip: {
      type: Object,
      default: () => {},
    },
    showCurrencySymbol: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    chartOptions() {
      return {
        series: this.series,
        chart: {
          height: 350,
          type: "bar",
          redrawOnParentResize: true,
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        xaxis: {
          type: "numeric",
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: (val) => {
              return this.showCurrencySymbol ? this.valueInCurrency(val) : val;
            },
          },
        },
        tooltip: this.customTooltip,
      };
    },
  },
  methods: {},
};
</script>
