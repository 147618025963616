<template>
  <div>
    <Popper
      :hover="true"
      :placement="'top'"
      :disabled="!showTooltip"
      :arrow="true"
    >
      <div v-if="currentUser" class="flex items-center">
        <div v-if="showNameOnly">
          <div v-if="isApiLoading('users')" class="loading-text">
            Loading...
          </div>
          <div v-else>
            {{ fullName(currentUser) }}
          </div>
        </div>
        <div v-else-if="showEntityListDetails" class="flex items-center">
          <UserTile :user="currentUser" />
          <span
            v-if="isDeleteAllowed"
            class="inline-flex items-center justify-center cursor-pointer text-gray"
            :class="`h-${avatarDimension} w-${avatarDimension}`"
            @click="showDeleteConfirmModal = true"
          >
            <TrashIcon class="w-4 h-4 text-gray cursor-pointer" />
          </span>
        </div>

        <div v-else-if="showAvatar">
          <div v-if="!isApiLoading('users')">
            <span
              :class="[
                'inline-flex items-center justify-center',
                { showBounded: 'rounded-full bg-primary border border-white' },
              ]"
              @mouseover="() => (showDeleteIcon = isDeleteAllowed)"
              @mouseleave="() => (showDeleteIcon = !isDeleteAllowed)"
            >
              <span
                v-if="showDeleteIcon"
                class="inline-flex items-center justify-center rounded-full bg-red-600 cursor-pointer border-white border absolute opacity-0 transition duration-300 ease-in-out hover:opacity-100"
                :class="`h-${avatarDimension} w-${avatarDimension}`"
                @click="showDeleteConfirmModal = true"
              >
                <TrashIcon class="w-4 h-4 text-white cursor-pointer" />
              </span>
              <UserImage
                :user="currentUser"
                :image-dimension="avatarDimension"
              />
            </span>
          </div>
          <div v-else>
            <span
              class="loading-avatar"
              :class="`h-${avatarDimension} w-${avatarDimension}`"
              ><UserIcon />
            </span>
          </div>
        </div>
        <div v-if="showEmail || showName || showRole" class="ml-2">
          <div v-if="showName" :class="['', ...textClasses]">
            <span
              v-if="!isApiLoading('users')"
              class=""
              :class="[{ 'line-through': !isActive }, ...textClasses]"
            >
              {{ fullName(currentUser) }}
            </span>
            <div v-else class="loading-text">Loading...</div>
          </div>
          <div
            v-if="showEmail"
            class="text-sm text-gray-700"
            :class="[{ 'line-through': !isActive }]"
          >
            <div v-if="!isApiLoading('users')">
              {{ currentUser.email }}
            </div>
            <div v-else class="loading-text">Loading...</div>
          </div>
          <div v-if="showRole"><Roles :roles="currentUser.roles" /></div>
        </div>
      </div>
      <template #content>
        <UserTile v-if="currentUser" :user="currentUser" />
      </template>
    </Popper>
    <ConfirmDialog
      :text="
        $t('global.confirmation.removeUserText', { department: departmentName })
      "
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      :title="$t('global.confirmation.removeUserTitle')"
      :item-to-confirm="fullName(currentUser)"
      :confirm-modal="onConfirmDelete"
    />
  </div>
</template>

<script>
import userMixin from "@/components/mixins/userMixin";
import { TrashIcon, UserIcon } from "@heroicons/vue/solid/esm";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import Roles from "@/components/shared/Roles.vue";
import UserTile from "@/components/shared/UserTile.vue";
import UserImage from "@/components/shared/UserImage.vue";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    TrashIcon,
    ConfirmDialog,
    Roles,
    UserTile,
    UserImage,
    UserIcon,
  },
  mixins: [userMixin],
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Function,
      required: false,
      default: () => {},
    },
    showName: {
      type: Boolean,
      default: false,
    },
    showNameOnly: {
      type: Boolean,
      default: false,
    },
    showEmail: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showRole: {
      type: Boolean,
      default: false,
    },
    showBounded: {
      type: Boolean,
      default: false,
    },
    user: {
      type: [Object, String],
      default: null,
      required: true,
    },

    avatarDimension: {
      type: Number,
      default: 10,
      required: false,
    },
    departmentName: {
      type: String,
      required: false,
      default: null,
    },
    isApprover: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    showEntityListDetails: {
      type: Boolean,
      default: false,
    },
    textClasses: {
      type: Array,
      required: false,
      default: () => ["text-sm font-semibold text-gray-700"],
    },
  },
  data() {
    return {
      showDeleteIcon: false,
      showDeleteConfirmModal: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["users"]),
    toolTipDisable() {
      return this.showTooltip ? true : null;
    },

    isDeleteAllowed() {
      return this.showDelete && !this.isUserOnlyAuditor;
    },
    currentUser() {
      const userId = this.user.id || this.user;
      return this.users[userId] || {};
    },
  },
  watch: {
    user: {
      async handler(value) {
        await this.getMemberDetails(value);
      },
      immediate: true,
      deep: true,
    },
    users: {
      async handler() {
        if (!this.userDetails && this.user) {
          await this.getMemberDetails(this.user);
        }
      },
      deep: true,
    },
  },
  methods: {
    deleteModalText() {
      const userName = this.fullName(this.currentUser);
      return `Are you sure you want to remove ${userName} from ${this.departmentName}?`;
    },

    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },

    onConfirmDelete() {
      if (this.isApprover) {
        this.onDeleteModalClose();
        return this.onDelete();
      } else {
        this.onDeleteModalClose();
        this.onDelete(this.currentUser);
      }
    },
  },
};
</script>

<style scoped>
.showBounded {
  vertical-align: middle;
}
</style>
