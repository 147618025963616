<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <PencilAltIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{
          $t("companyManagement.integrations.datev.configurationModal.title")
        }}
      </h3>
    </template>
    <template #body>
      <p class="my-2">
        {{
          $t("companyManagement.integrations.datev.configurationModal.helpText")
        }}
      </p>
      <div class="mt-6">
        <Input
          id="client_id"
          :error-text="error(v$.clientId)"
          name="clientId"
          :value="clientId"
          :placeholder="
            $t(
              'companyManagement.integrations.datev.configurationModal.clientId'
            )
          "
          :label="
            $t(
              'companyManagement.integrations.datev.configurationModal.clientId'
            )
          "
          :on-value-change="onAttributeChange"
        />
      </div>
      <div class="mt-6">
        <Input
          id="consultant_id"
          :error-text="error(v$.consultantId)"
          name="consultantId"
          :value="consultantId"
          :placeholder="
            $t(
              'companyManagement.integrations.datev.configurationModal.consultantId'
            )
          "
          :label="
            $t(
              'companyManagement.integrations.datev.configurationModal.consultantId'
            )
          "
          :on-value-change="onAttributeChange"
        />
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("shared.cancelButton") }}
      </Button>
      <Button
        :on-click-handler="saveCredentials"
        :disabled="v$.$error"
        :loading="loading"
      >
        {{ $t("shared.saveButton") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { mapActions } from "vuex";
import {
  ADD_DATEV_CREDENTIALS,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types";
import { errorMixin } from "@/components/mixins/index.js";
import { Button, Modal, Input } from "@/components/shared/index";
import { PencilAltIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Modal,
    Button,
    Input,
    PencilAltIcon,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      clientId: "",
      consultantId: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addDatevCredentials: ADD_DATEV_CREDENTIALS,
    }),
    onAttributeChange(event) {
      const { name, value } = event.target;
      this[name] = value;
    },
    saveCredentials() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.addDatevCredentials({
          clientId: this.clientId,
          consultantId: this.consultantId,
        })
          .then((data) => {
            this.showNotification(
              this.$t(
                "companyManagement.integrations.datev.configurationModal.successText"
              )
            );
            window.location.href = data.completeUrl;

            this.onModalClose();
          })
          .catch(() =>
            this.showNotification(
              this.$t(
                "companyManagement.integrations.datev.configurationModal.errorText"
              ),
              "error"
            )
          )
          .finally(() => (this.loading = false));
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.clientId = "";
        this.consultantId = "";
        this.v$.$reset();
      }, 500);
    },
  },
  validations() {
    return {
      clientId: { required },
      consultantId: { required },
    };
  },
};
</script>
