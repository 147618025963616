<template>
  <div id="invoice-approval">
    <div class="upper-part">
      <div class="text-lg font-semibold text-gray-700 mb-2">
        <div
          v-if="selectedInvoice"
          class="flex justify-between flex-col sm:flex-row gap-y-2 sm:gap-y-0"
        >
          <div class="inline-flex items-center gap-x-6 pb-3.5">
            <span
              :class="[
                { 'cursor-pointer hover:text-primary': selectedInvoice },
                'flex items-center gap-x-2',
              ]"
              @click="
                () => {
                  goBackButton();
                }
              "
            >
              <ChevronLeftIcon
                class="w-6 h-6 cursor-pointer text-hivebuy-plum flex-shrink-0"
              />
              <span>{{
                originalInvoice.title
                  ? originalInvoice.title
                  : originalInvoice.invoiceNumber
              }}</span>
              <StatusTag
                type="invoice"
                :status="originalInvoice.status"
                class="-mt-0.5"
              />
            </span>
          </div>

          <div class="text-base font-normal flex items-center gap-x-2">
            <PoPreview
              v-if="
                selectedInvoice.invoicePurchaseOrders &&
                selectedInvoice.invoicePurchaseOrders.length
              "
              :button-text="`${selectedInvoice.invoicePurchaseOrders.length}`"
              :invoice="selectedInvoice"
              :select-invoice-po-for-edit="selectInvoicePoForEdit"
              :set-visible-state="setVisibleState"
              :show-text="true"
            />
            <StatusChange
              v-if="['RV', 'O'].includes(selectedInvoice.status)"
              :invoice="selectedInvoice"
              new-status="R"
              :call-back="() => updateFilterAction('R')"
              :buttons-stacked="true"
            >
              <template #icon>
                <XIcon
                  v-tippy="{
                    content: $t('invoice.markAsNotInvoice'),
                    theme: 'time',
                    delay: [500, 50],
                  }"
                  class="h-5 w-5 focus:outline-none"
                />
                {{ $t("global.decisions.reject") }}
              </template>
            </StatusChange>
            <Button
              v-if="showSection('poList')"
              :size-class="'sm'"
              color="transparent"
              :on-click-handler="() => setVisibleState('preview')"
            >
              <div
                :class="[
                  'flex text-primary font-semibold hover:text-primarydark items-center',
                ]"
              >
                <XIcon class="h-5 w-5 xl:mr-2" aria-hidden="true" />
                <span class="hidden xl:flex">Cancel</span>
              </div>
            </Button>
          </div>
        </div>
        <span v-else> {{ $t("invoice.dashboard.title") }} </span>
      </div>
      <CollapseTransition>
        <div v-if="showSection('uploadSection')" class="invoice-upload">
          <UploadMedia
            :on-file-upload="attachAndSelectFile"
            :details="{}"
            :loading="fileUploadLoading"
            :maximum-file-uploads-count="20"
            :show-file-options="false"
            :show-file-actions="true"
            :show-paste-actions="false"
            :object-to-update="'invoice'"
            :type="'file'"
          />
        </div>
      </CollapseTransition>
    </div>
    <div class="lower-part">
      <div class="custom-card">
        <div v-if="showSection('list')" class="search-holder">
          <div class="search-wrapper min-w-10 shrink">
            <Input
              name="search"
              :placeholder="$t('invoice.dashboard.searchBy')"
              :value="advancedFilters.search"
              :on-value-change="onAttributeChange"
            />
          </div>
          <div class="filters flex justify-end flex-1 grow shrink-0 text-base">
            <div class="flex items-center gap-2 w-[240px] mr-2">
              <p>{{ $t("invoice.table.status") }}</p>
              <HivebuySelect
                v-model="filter"
                :filter="false"
                :options="statusOptions"
                :option-label="'name'"
                :clearable="false"
              >
                <template #option="{ option }">
                  <span
                    class="px-3 py-0.5 rounded-full border border-white"
                    :class="option.colorClass"
                    >{{ option.name }}</span
                  >
                </template>
              </HivebuySelect>
            </div>
            <Filters />
          </div>
        </div>
        <div v-if="isApiLoading('invoiceAction')" class="content-center">
          <HivebuyLoader class="w-36 h-36" />
        </div>
        <section v-else class="content-wrapper">
          <section v-if="showSection('list')" class="listing-holder">
            <section class="invoice-list">
              <div class="list-wrapper">
                <InvoiceListing
                  ref="invoiceListing"
                  :select-invoice="selectInvoice"
                  :selected="selectedInvoice ? selectedInvoice.id : null"
                  :show-details="showState.preview"
                  :set-visible-state="setVisibleState"
                  :select-invoice-po-for-edit="selectInvoicePoForEdit"
                />
              </div>
            </section>
          </section>
          <section
            v-if="showSection('preview') && selectedInvoice"
            class="invoice-preview"
          >
            <PreviewSection
              :show-adjust="
                !showSection('form') && isAllowedToEdit(selectedInvoice)
              "
              :show-assign="
                !showSection('poList') && isAllowedToAssign(selectedInvoice)
              "
              :invoice-object="originalInvoice"
              :select-invoice-po-for-edit="selectInvoicePoForEdit"
              :set-visible-state="setVisibleState"
            />
          </section>
          <div
            v-else
            class="text-primary flex flex-1 flex-col justify-center items-center place-content-center py-10"
          >
            <PhotographIcon class="h-20 w-20 text-gray-300" />
            <p class="text-gray-400 text-sm">
              {{ $t("invoice.invoicePreviewDefault") }}
            </p>
          </div>

          <section
            v-if="showSection('form') || showSection('poList')"
            class="detail-section"
          >
            <DetailSection
              :invoice-object="originalInvoice"
              :update-invoice="saveInvoicePoDetails"
              :select-invoice-po-for-edit="selectInvoicePoForEdit"
              :show-state="showState"
              :set-visible-state="setVisibleState"
              :update-filter="updateFilterAction"
              :show-assign-button="isAllowedToAssign(selectedInvoice)"
            />
          </section>
        </section>
        <AssignPoSection
          v-if="selectedInvoice"
          :loading="poSaveLoading"
          :open="showInvoicePoEditModal"
          :invoice-id="selectedInvoice.id"
          :invoice-po="selectedInvoicePo"
          :save-action="saveInvoicePoDetails"
          :toggle-action="
            () => (showInvoicePoEditModal = !showInvoicePoEditModal)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  InvoiceListing,
  PreviewSection,
} from "@/components/InvoiceApproval/index.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import UploadMedia from "@/components/PurchaseRequest/UploadMedia";
import DetailSection from "@/components/InvoiceApproval/details.vue";
import { AUTH_MODULE } from "@/store/Auth/types";
import { invoiceMixin, invoiceFilterMixin } from "@/components/mixins/index.js";
import { Input, Button, HivebuyLoader } from "@/components/shared";
import { ChevronLeftIcon, PhotographIcon, XIcon } from "@heroicons/vue/solid";
import AssignPoSection from "@/components/InvoiceApproval/assignPOSection.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { INVOICE_MODULE, GET_INVOICE } from "@/store/Invoice/types";
import { HivebuySelect } from "design-buy";
import { invoiceDashboardState } from "@/utils/helpers/invoiceHelper.js";
import StatusTag from "@/components/shared/StatusTag";
import PoPreview from "@/components/InvoiceApproval/invoicePoDetails.vue";
import { sumInArray } from "@/utils/utility_methods.js";
import markPoPendingMixin from "@/components/mixins/markPoPendingMixin.js";
import StatusChange from "@/components/InvoiceApproval/StatusChange.vue";
import Filters from "@/components/InvoiceApproval/Filters.vue";
export default {
  components: {
    XIcon,
    InvoiceListing,
    PreviewSection,
    UploadMedia,
    DetailSection,
    HivebuySelect,
    ChevronLeftIcon,
    AssignPoSection,
    Input,
    Button,
    CollapseTransition,
    PhotographIcon,
    StatusTag,
    PoPreview,
    StatusChange,
    HivebuyLoader,
    Filters,
  },
  mixins: [invoiceMixin, markPoPendingMixin, invoiceFilterMixin],
  provide() {
    return {
      reloadInvoicesPage: this.reloadInvoicesPage,
      showSection: this.showSection,
    };
  },
  data() {
    return {
      selectedInvoice: null,
      showInvoicePoEditModal: false,
      poSaveLoading: false,
      selectedInvoicePo: {},
      isLoadingInvoices: false,
      fileUploadLoading: false,
      showState: {
        uploadSection: true,
        list: true,
        preview: false,
        form: false,
        poList: false,
      },
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(INVOICE_MODULE, ["invoices"]),
    ...mapGetters(INVOICE_MODULE, ["invoiceWithId"]),
    ...mapState(INVOICE_MODULE, {
      advancedFilters: (state) => state.invoiceListFilters.advancedFilters,
      paginationParams: (state) => state.invoiceListFilters.paginationParams,
    }),
    filter: {
      get() {
        return this.advancedFilters.filter;
      },
      set(value) {
        return this.handleListChange(value || "all");
      },
    },
    originalInvoice() {
      return this.invoiceWithId(this.selectedInvoice.id);
    },
    statusOptions() {
      return [
        {
          name: this.$t("invoice.status.all"),
          value: "all",
          colorClass: "bg-white text-gray-800",
        },

        {
          name: this.$t("statuses.invoice.N"),
          value: "new",
          colorClass: "bg-[#00D1FF] text-gray-100",
        },
        {
          name: this.$t("invoice.status.P"),
          value: "open",
          colorClass: "bg-hivebuy-yellow text-white",
        },
        {
          name: this.$t("invoice.status.Pe"),
          value: "pendingApproval",
          colorClass: "bg-primary text-white",
        },
        {
          name: this.$t("invoice.status.A"),
          value: "approved",
          colorClass: "bg-hivebuy-green text-white",
        },
        {
          name: this.$t("invoice.status.RV"),
          value: "askedForRevision",
          colorClass: "bg-hivebuy-red text-white",
        },
        {
          name: this.$t("invoice.status.R"),
          value: "rejected",
          colorClass: "bg-hivebuy-red text-white",
        },
        {
          name: this.$t("invoice.status.Pa"),
          value: "paid",
          colorClass: "bg-gray-300 text-gray-600",
        },
        {
          name: this.$t("statuses.invoice.NI"),
          value: "notAnInvoice",
          colorClass: "bg-gray-300 text-gray-600",
        },
      ];
    },
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getInvoice: GET_INVOICE,
    }),
    handleListChange(value) {
      this.updateFilterAction(value);
      this.selectedInvoice = null;
      this.setVisibleState();
    },
    goBackButton() {
      if (this.showSection("list")) {
        this.setVisibleState();
      } else {
        this.setVisibleState("preview");
      }
    },
    updateFilterAction(value, key = "filter") {
      this.updateFilters(key, value, "advancedFilters");
    },
    setVisibleState(name = "default") {
      this.showState = invoiceDashboardState(name);
      if (name === "default") this.selectedInvoice = null;
    },
    showSection(section) {
      return this.showState[section];
    },
    showAmountAdjustForm() {
      this.setVisibleState("form");
    },
    onAttributeChange(event) {
      const { value } = event.target;
      this.updateFilterAction(value, "search");
    },
    async selectInvoice(invoice) {
      this.setApiLoading({ key: "invoiceDetails", value: true });
      this.selectedInvoice = await this.getInvoice(invoice.id);
      this.setApiLoading({ key: "invoiceDetails", value: false });
      this.setVisibleState("preview");
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async attachAndSelectFile(data, objectToUpdate) {
      this.fileUploadLoading = true;
      await this.attachFile(data);
      this.fileUploadLoading = false;
      this.updateFilterAction("new");
    },

    reloadInvoicesPage(params = {}) {
      if (params.assigned) this.updateFilterAction("open");
      this.setVisibleState();
    },
    selectInvoicePoForEdit(record, isNewRecord = false) {
      const { ipoId, ...purchaseOrder } = record;
      if (isNewRecord) {
        const updatedInvoice = this.invoiceWithId(this.selectedInvoice.id);
        this.selectedInvoicePo = {
          id: ipoId,
          invoice: this.selectedInvoice,
          purchaseOrder,
          tax: sumInArray(updatedInvoice.taxes, "tax"),
          totalAmount: updatedInvoice.totalAmount,
          totalGrossAmount: updatedInvoice.totalGrossAmount,
          totalNetAmount: updatedInvoice.totalNetAmount,
          totalNetAmountCurrency: updatedInvoice.totalNetAmountCurrency,
        };
      } else {
        this.selectedInvoicePo = { ...this.selectedInvoice, ...record };
      }

      this.showInvoicePoEditModal = true;
    },
    sendForApproval(invoicePurchaseOrders) {
      const invoiceLevelApprover = this.selectedInvoice?.isInvoiceLevelApprover;
      const ids = invoiceLevelApprover
        ? this.selectedInvoice.id
        : invoicePurchaseOrders;
      const payload = {
        ids,
        isInvoiceLevelApprover: invoiceLevelApprover,
      };
      this.updateFilterAction("pendingApproval");
      this.markPending(payload);
    },
    updateInvoicePoDetails(index, payload, finish, callback = () => {}) {
      const invoicePurchaseOrders = this.invoiceWithId(
        this.selectedInvoice.id
      ).invoicePurchaseOrders;
      const actionPayload = {
        invoiceId: this.selectedInvoice.id,
        payload: {
          invoicePurchaseOrders: invoicePurchaseOrders.map((ipo, ipoIndex) => {
            if (index === ipoIndex) {
              return Object.assign({}, ipo, payload);
            }
            return { id: ipo.id };
          }),
        },
      };
      this.updateInvoice(actionPayload)
        .then((response) => {
          this.selectInvoice = response.data;
          this.showNotification("Purchase Orders Assigned Successfully");
          this.showInvoicePoEditModal = false;
          if (finish)
            this.sendForApproval(
              response.data.invoicePurchaseOrders.map((ipo) => ipo.id)
            );
        })
        .finally(() => {
          callback();
          this.poSaveLoading = false;
        });
    },
    createInvoicePoDetails(payload, finish, callback = () => {}) {
      this.assignPO(payload)
        .then((response) => {
          if (!response.invoice.collective) {
            this.updateFilterAction("open");
            this.setVisibleState();
          }
          this.showNotification("Purchase Orders Assigned Successfully");
          this.showInvoicePoEditModal = false;
          if (finish) {
            this.sendForApproval(response.invoice.invoicePurchaseOrders);
          }
        })
        .finally(() => {
          callback();
          this.poSaveLoading = false;
        });
    },
    isExistingIPOIndex(payload) {
      const { invoice: invoiceId, purchaseOrder } = payload;
      const invoice = this.invoiceWithId(this.selectedInvoice.id);
      return invoice.invoicePurchaseOrders.findIndex(
        (ipo) =>
          ipo.purchaseOrder.id === purchaseOrder && ipo.invoice === invoiceId
      );
    },
    saveInvoicePoDetails(payload, finish = false, callback = () => {}) {
      this.poSaveLoading = true;
      const index = this.isExistingIPOIndex(payload);
      if (index > -1) {
        this.updateInvoicePoDetails(index, payload, finish, callback);
      } else {
        this.createInvoicePoDetails(payload, finish, callback);
      }
    },
  },
};
</script>

<style scoped>
#invoice-approval {
  @apply flex flex-col h-full;
}

.invoice-upload {
  @apply overflow-y-auto w-full mb-2;
}

.lower-part {
  @apply flex-1 h-1/2;
}

.custom-card {
  @apply h-full p-0 flex flex-col;
}

.search-holder {
  @apply relative border-b border-gray-200 p-3 w-full h-auto gap-x-4 flex justify-center;
}

.search-wrapper {
  @apply w-full;
  max-width: 50%;
}

.filters {
  @apply flex items-center;
}

.filter-label {
  @apply ml-2 text-sm font-semibold;
}

.content-wrapper {
  @apply flex flex-1 h-[80%];
}

.listing-holder,
.invoice-preview {
  @apply flex flex-col flex-1 flex-shrink-0;
}

.listing-holder {
  @apply border-r border-gray-200 h-full;
}
.invoice-preview {
  @apply overflow-x-hidden h-full;
}

.detail-section {
  @apply h-full overflow-x-hidden overflow-y-auto w-1/2 border-l border-gray-200;
}

.invoice-list {
  @apply w-full h-full overflow-auto;
}

.list-wrapper {
  @apply h-full overflow-auto box-border relative;
}
</style>

<style lang="scss" scoped>
.arrow-pointer {
  width: 80px;
  height: 30px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }

  &:before {
    content: "";
    position: absolute;
    right: -15px;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
}
</style>
