<template>
  <BarButton
    show-icon
    :on-click-handler="invoiceId ? getOcrDetails : getOcrPreviewDetails"
    :loading="loading"
  >
    <template #icon>
      <DocumentSearchIcon />
    </template>
    {{ processing ? "Processing..." : "OCR" }}
  </BarButton>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  GET_OCR_DATA,
  GET_OCR_PREVIEW_DATA,
  INVOICE_MODULE,
} from "@/store/Invoice/types";
import BarButton from "@/components/shared/BarButton.vue";
import { DocumentSearchIcon } from "@heroicons/vue/outline";
export default {
  components: { BarButton, DocumentSearchIcon },
  props: {
    invoiceId: { type: [Number, null, String], default: null },
    file: { type: [Object, null], default: null },
    modelValue: {
      type: [Object, null],
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      loading: false,
      processing: false,
      ocrUuid: null,
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, ["invoicesOcr", "ocrPreview"]),
    ocrData: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    invoicesOcr: {
      deep: true,
      handler(newVal) {
        if (newVal[this.invoiceId]) {
          this.loading = false;
          this.ocrData = newVal[this.invoiceId];
        }
      },
    },
    ocrPreview: {
      deep: true,
      handler(newVal) {
        if (newVal[this.ocrUuid]) {
          this.loading = false;
          this.ocrData = newVal[this.ocrUuid];
        }
      },
    },
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getOcrData: GET_OCR_DATA,
      getOcrPreview: GET_OCR_PREVIEW_DATA,
    }),
    getOcrDetails() {
      if (!this.invoiceId) return;

      if (this.invoicesOcr[this.invoiceId] != null) {
        this.ocrData = this.invoicesOcr[this.invoiceId];
        return;
      }
      this.loading = true;
      this.getOcrData(this.invoiceId)
        .then(() => {
          this.processing = true;
        })
        .catch(() => {
          this.showNotification(this.$t("ocr.error"), "error");
          this.loading = false;
        });
    },
    getOcrPreviewDetails() {
      if (!this.file) return;
      this.loading = true;
      this.getOcrPreview(this.file)
        .then((response) => {
          this.processing = true;
          this.ocrUuid = response.data.ocrUuid;
        })
        .catch(() => {
          this.showNotification(this.$t("ocr.error"), "error");
          this.loading = false;
        });
    },
  },
};
</script>
