<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column bg-hivebuy-red bg-opacity-20 text-hivebuy-red">
        <BanIcon class="h-6 w-6" />
      </span>
      <div class="text-column ml-4 mt-0">
        <span class="text-gray-400 mr-1">
          {{ $t("timeline.cancelledBy") }}
        </span>
        <span class="text-gray-900 font-semibold"> {{ cancelledBy }} </span>
        <div class="text-xs text-gray-500 my-1">
          {{ timeDifference(details.statusDate) }}
        </div>
      </div>
      <div class="avatar-column">
        <div>
          <UserWithAvatar
            :user="details.createdBy"
            :avatar-dimension="9"
            show-tooltip
            class="cursor-help"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BanIcon } from "@heroicons/vue/solid";
import { UserWithAvatar } from "@/components/shared/index";
import { timeDifference } from "@/utils/timeHelper.js";
import { memberDetailsMixin } from "@/components/mixins/index";
import { fullName } from "@/utils/utility_methods";
export default {
  components: { BanIcon, UserWithAvatar },
  mixins: [memberDetailsMixin],
  props: {
    details: { type: Object, required: true },
  },
  data() {
    return { cancelledBy: "N/A" };
  },
  mounted() {
    this.setCancelledByName();
  },
  methods: {
    timeDifference,
    async setCancelledByName() {
      this.cancelledBy = fullName(
        await this.getMemberDetails(this.details?.createdBy)
      );
    },
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
