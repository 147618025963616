import actions from "@/store/PurchaseOrder/actions";
import mutations from "@/store/PurchaseOrder/mutations";
import state from "@/store/PurchaseOrder/state";
import getters from "@/store/PurchaseOrder/getters.js";
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
