import {
  downloadKeys,
  purchaseOrderKeys,
  invoiceKeys,
  dateKeys,
  draftPRKeys,
  projectKeys,
  supplierOrderListKeys,
} from "@/utils/Constants/filters/mapFilterKeys.js";
import { isValuePresent, stringToBoolean } from "@/utils/utility_methods";

export default {
  methods: {
    modelKeys(model) {
      return {
        po: purchaseOrderKeys,
        in: invoiceKeys,
        dl: downloadKeys,
        draftPR: draftPRKeys,
        so: supplierOrderListKeys,
        projects: projectKeys,
      }[model];
    },
    assignFilterValue(filters, key, value) {
      if (!value || !key) return filters;
      filters[key] = filters[key] ? `${filters[key]},${value}` : value;
      return filters;
    },
    assignAdvancedBooleanFilter(filters, key, value) {
      if (!isValuePresent(value)) return filters;
      const filterKey = key[value];
      filters[filterKey] = filters[filterKey]
        ? [...filters[filterKey], key.statusKey]
        : key.statusKey;
      return filters;
    },
    requestQueryParams(params, model = "po") {
      const keyMapper = this.modelKeys(model);
      const rangeKeys = { start: "Gte", end: "Lte" };
      let newParams = Object.assign({}, params);
      delete newParams.filters;
      let mappedKey = "";
      Object.entries(params.filters).forEach(([key, value]) => {
        mappedKey = keyMapper[key];
        if (this.isObject(value.value) && !Array.isArray(value.value)) {
          Object.entries(value.value).forEach(([valueKey, filterValue]) => {
            const updatedValueKey = rangeKeys[valueKey] || valueKey;
            mappedKey = keyMapper[`${key}_${updatedValueKey}`];
            newParams = this.assignFilterValue(
              newParams,
              mappedKey,
              filterValue
            );
          });
        } else if (dateKeys.includes(key) && value.value) {
          newParams = this.handleDateLogic(
            key,
            value,
            keyMapper,
            rangeKeys,
            newParams
          );
        } else if (
          model === "po" &&
          key === "supplier" &&
          value.value &&
          !Array.isArray(value.value)
        ) {
          newParams = {
            ...newParams,
            [keyMapper.unlistedSupplier]: stringToBoolean(value.value),
          };
        } else if (this.isObject(mappedKey)) {
          newParams = this.assignAdvancedBooleanFilter(
            newParams,
            mappedKey,
            value.value
          );
        } else {
          newParams = this.assignFilterValue(newParams, mappedKey, value.value);
        }
      });
      return newParams;
    },
  },
};
