<template>
  <HivebuyLoader v-if="isApiLoading('companySuppliers')" class="w-5 h-5" />
  <span v-else>
    <SquashedList
      v-if="squashed"
      :list="suppliersList(purchaseRequest)"
      :display-attribute="'name'"
      type="supplier"
    />

    <span v-else class="flex items-center">
      <HivebuyTooltip
        v-if="isIntegratedSupplier"
        :disabled="disableSupplierBadgeTooltip"
      >
        <div
          :class="`rounded-full
        bg-gray-200
        flex
        items-center
        ${supplierBadgeClasses}`"
        >
          <LightningBoltIcon
            class="text-hivebuy_plum group-hover:text-hivebuy_darkplum"
          />
        </div>
        <template #content>
          <div
            class="text-gray-500 max-w-xs p-2 font-light ml-0.5 flex items-center whitespace-normal"
          >
            {{
              $t(
                "companyManagement.companySupplier.integratedSupplierTooltipText"
              )
            }}
          </div>
        </template>
      </HivebuyTooltip>
      <NameWithTooltip v-if="withTooltip" :name="supplierNames" />
      <span v-else :class="{ 'text-hivebuy-red': isSupplierDeleted }">
        <HivebuyTooltip v-if="isSupplierDeleted">
          {{ formattedSupplierName }}
          <template #content>
            <div
              class="text-gray-500 max-w-xs p-2 font-light ml-0.5 flex items-center whitespace-normal"
            >
              {{ toolTipText }}
            </div>
          </template>
        </HivebuyTooltip>
        <span v-else>
          {{ showSupplierNumber ? supplierNumber : formattedSupplierName }}
        </span>
      </span>
    </span>
  </span>
</template>
<script>
import {
  NameWithTooltip,
  HivebuyTooltip,
  SquashedList,
  HivebuyLoader,
} from "@/components/shared";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_SUPPLIERS,
} from "@/store/CompanyManagement/types";
import supplierHelperMixin from "@/components/mixins/supplierHelperMixin";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { mapState, mapActions, mapGetters } from "vuex";
import { LightningBoltIcon } from "@heroicons/vue/solid";
import { ALLOWED_API_ON_ROUTE } from "@/utils/constants.js";
export default {
  components: {
    NameWithTooltip,
    HivebuyTooltip,
    LightningBoltIcon,
    SquashedList,
    HivebuyLoader,
  },
  mixins: [supplierHelperMixin],
  props: {
    showSupplierNumber: {
      type: Boolean,
      default: false,
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: [String, Object],
      default: null,
    },
    purchaseRequest: { type: Object, default: null },
    size: {
      type: String,
      default: "normal",
    },
    disableSupplierBadgeTooltip: {
      type: Boolean,
      default: false,
    },
    squashed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      hasDeleted: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "companySuppliersList",
      "supplierName",
    ]),
    supplierNumber() {
      const supplierDetails = this.getCompanySupplier()(this.supplier);
      return supplierDetails?.supplierNumber;
    },
    companySuppliersPresent() {
      return !!this.companySuppliersList()?.length;
    },
    supplierBadgeClasses() {
      return {
        small: "h-4 w-4 p-0.5 mr-0.5",
        normal: "h-6 w-6 p-1 mr-2 cursor-pointer group",
      }[this.size];
    },
    toolTipText() {
      return this.$t(
        "companyManagement.companySupplier.companySupplierIsDeleted"
      );
    },
    formattedSupplierName() {
      if (this.isSupplierDeleted) {
        return `* ${this.supplierNames}`;
      }
      return this.supplierNames;
    },
    isSupplierDeleted() {
      return this.activeSupplier?.deleted || this.hasDeleted;
    },
    activeSupplier() {
      if (this.isIntegratedSupplier) {
        this.getSupplierDetails(this.integratedSupplier);
        return this.integratedSupplier;
      } else if (this.supplier && this.supplier?.type !== "unlisted") {
        return this.getSupplierDetails(this.supplier, "CompanySupplier");
      }
      return null;
    },
    isNewRequest() {
      return (
        this.purchaseRequest &&
        !Object.keys(this.purchaseRequest).includes("id")
      );
    },
    supplierNames() {
      if (this.supplier?.type == "unlisted")
        return this.supplier?.details?.name;
      if (this.companySuppliersList()) {
        if (this.isIntegratedSupplier) return this.activeSupplier?.name;
        else if (this.supplier)
          return this.getSupplierPreferredName(this.activeSupplier);
        else if (this.isNewRequest) return this.newRequestFlow();
        else if (this.purchaseRequest)
          return this.prSupplierNames(this.purchaseRequest);
      }
      return "";
    },
  },
  mounted() {
    const routeApis = ALLOWED_API_ON_ROUTE(this.$route.name);
    if (!routeApis.includes("companySuppliers")) return;

    if (
      !this.companySuppliersPresent &&
      !this.isApiLoading("companySuppliers")
    ) {
      this.setApiLoading({ key: "companySuppliers", value: true });
      this.getCompanySuppliers().finally(() => {
        this.setApiLoading({ key: "companySuppliers", value: false });
      });
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompanySuppliers: GET_COMPANY_SUPPLIERS,
    }),

    newRequestFlow() {
      const { items, supplier } = this.purchaseRequest;
      if (supplier == null && !items.length)
        return this.$t("purchaseRequest.selectCompanySupplier");

      if (!items.length) {
        let name = this.$t("purchaseRequest.noCompanySupplier");
        if (supplier.type == "companySupplier")
          name = this.getCompanySupplierName(supplier.details);
        if (supplier.type == "unlisted") name = supplier.details.name;
        return name;
      }
      return this.prSupplierNames(this.purchaseRequest);
    },
    suppliersList(pr) {
      return [
        ...new Set(
          pr.items.map((item) => {
            const extractedSupplier = this.extractSupplierFromPR(item);
            if (extractedSupplier) {
              if (extractedSupplier.deleted) this.hasDeleted = true;
              return this.getSupplierPreferredName(extractedSupplier);
            } else if (item.unlistedSupplier)
              return `${item.unlistedSupplier} (${this.$t("global.unlisted")})`;
            else {
              return this.$t("global.noSupplier");
            }
          })
        ),
      ];
    },
    extractSupplierFromPR({ companySupplier, supplier }) {
      this.deletedSuppliersListInPR = {};
      let extractedSupplier = null;
      if (supplier && supplier.type == "companySupplier") {
        extractedSupplier = this.getSupplierDetails(
          supplier.details,
          "CompanySupplier"
        );
      } else if (companySupplier) {
        extractedSupplier = this.getSupplierDetails(
          companySupplier,
          "CompanySupplier"
        );
      }
      return extractedSupplier;
    },
    prSupplierNames(pr) {
      const suppliers = this.suppliersList(pr);
      return new Intl.ListFormat().format([
        ...new Set(suppliers.filter((item) => item)),
      ]);
    },
  },
};
</script>
