import * as FeatureTypes from "./types.js";
import { snakeToCamel } from "@/utils/utility_methods";

export default {
  [FeatureTypes.SET_FEATURES]: (state, payload) => {
    const { featureFlags } = payload;
    featureFlags.forEach((f) => {
      const featureName = snakeToCamel(f.featureName.toLowerCase());
      state.featureFlags[featureName] = f;
    });
  },
  [FeatureTypes.SET_FEATURE]: (state, payload) => {
    const { updatedData, flagName } = payload;
    state.featureFlags[flagName].updatedAt = updatedData.updatedAt;
  },
  [FeatureTypes.LIVE_UPDATE_FLAG]: (state, payload) => {
    const featureName = snakeToCamel(
      payload.object.featureFlag.featureName.toLowerCase()
    );
    state.featureFlags[featureName] = payload.object;
  },
};
