import actions from "@/store/Project/actions";
import mutations from "@/store/Project/mutations";
import getters from "@/store/Project/getters";
import state from "@/store/Project/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
