<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <PencilAltIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{ $t("companyManagement.integrations.datev.fallbackModal.title") }}
      </h3>
    </template>
    <template #body>
      <div class="mt-2 pb-2">
        <ul role="list" class="divide-y divide-gray-100">
          <li
            v-for="client in datev.clients"
            :key="client"
            class="flex items-center justify-between gap-x-6 py-5"
          >
            <div class="min-w-0">
              <div class="flex items-start gap-x-3">
                <p class="text-base font-semibold leading-6 text-gray-900">
                  {{ client.name || client.clientNumber }}
                </p>
              </div>
              <div
                class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500"
              >
                <p class="whitespace-nowrap">
                  {{
                    $t(
                      "companyManagement.integrations.datev.configurationModal.clientId"
                    )
                  }}: {{ client.clientNumber }}
                </p>
                <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                  <circle cx="1" cy="1" r="1" />
                </svg>
                <p class="truncate">
                  {{
                    $t(
                      "companyManagement.integrations.datev.configurationModal.consultantId"
                    )
                  }}: {{ client.consultantNumber }}
                </p>
              </div>
            </div>
            <div class="flex flex-none items-center gap-x-4">
              <Button
                v-if="!client.connected"
                :on-click-handler="() => connectClient(client)"
                size-class="xs"
                color="success"
                :loading="loading"
              >
                {{ $t("button.connect") }}
              </Button>
              <Button
                v-else
                :on-click-handler="() => disconnectClient(client)"
                size-class="xs"
                color="danger"
                :loading="loading"
              >
                {{ $t("button.disconnect") }}
              </Button>
            </div>
          </li>
        </ul>
      </div>
      <div class="pt-2">
        {{ $t("companyManagement.integrations.datev.fallbackModal.subtext") }}
        <p class="mb-2 font-medium">
          {{ $t("companyManagement.integrations.datev.fallbackModal.expl") }}
        </p>
        <Input
          :id="'collectiveCreditor'"
          :value="collCred"
          :name="'collectiveCreditor'"
          :on-key-up="onAttributeChange"
          :type="'text'"
          :label="
            $t(
              'companyManagement.integrations.datev.fallbackModal.collectiveCreditor'
            )
          "
        />
      </div>
      <FieldError v-if="apiError" :text="apiError" />
    </template>
    <template #footer>
      <Button
        v-focus
        :on-click-handler="onModalClose"
        :color="'gray'"
        :loading="loading"
      >
        {{ $t("button.close") }}
      </Button>
      <Button
        :on-click-handler="onCollectiveCreditorSave"
        :loading="loading"
        :disabled="!collectiveCreditor"
      >
        {{ $t("button.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Button, HivebuyLoader, Modal, Input } from "@/components/shared";
import { PencilAltIcon } from "@heroicons/vue/solid";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import FieldError from "@/components/shared/FieldError";
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  SET_DATEV_COLLECTIVE_CREDITOR,
  CONNECT_SINLE_DATEV_CLIENT,
  DISCONNECT_DATEV_SINGLE_CLIENT,
} from "@/store/CompanyManagement/types";

export default {
  name: "ConsultantModal",
  components: {
    FieldError,
    Modal,
    Button,
    PencilAltIcon,
    // eslint-disable-next-line vue/no-unused-components
    HivebuyLoader,
    Input,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    datev: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      consultantList: [],
      selectedConsultant: null,
      noteCheckbox: null,
      v$: useValidate(),
      loading: false,
      apiError: null,
      collectiveCreditor: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      datev: (state) => state.company.datevintegration,
    }),
    areConsultantsLoaded() {
      return this.consultantList.length;
    },
    collCred: {
      get() {
        return this.datev?.collectiveCreditor;
      },
      set(value) {
        this.collectiveCreditor = value;
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      setCollectiveCreditor: SET_DATEV_COLLECTIVE_CREDITOR,
      connectSingleDatevClient: CONNECT_SINLE_DATEV_CLIENT,
      disconnectDatevSingleClient: DISCONNECT_DATEV_SINGLE_CLIENT,
    }),
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.apiError = null;
        this.collectiveCreditor = this.datev.collectiveCreditor || "";
        this.v$.$reset();
      }, 500);
    },
    onAttributeChange(event) {
      const value = event.target?.value;
      this.collectiveCreditor = value;
    },

    connectClient(client) {
      this.connectSingleDatevClient(client.id).then((client) => {
        window.location.href = client.completeUrl;
      });
    },

    disconnectClient(client) {
      this.loading = true;
      this.disconnectDatevSingleClient(client.id).finally(
        () => (this.loading = false)
      );
    },

    onCollectiveCreditorSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.setCollectiveCreditor({
          collectiveCreditor: this.collectiveCreditor,
        })
          .then(() => {
            this.showNotification(
              this.$t(
                "companyManagement.integrations.datev.configurationModal.tokenStored"
              )
            );
            this.onModalClose();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  validations() {
    return {
      collectiveCreditor: { required },
    };
  },
};
</script>
