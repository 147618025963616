<template>
  <div class="text-center">
    <div class="flex justify-center mt-10 font-extralight">
      <PresentationChartLineIcon
        :class="['w-40 text-primary', { 'animate-pulse': loading }]"
      />
    </div>
    <div v-if="loading">
      <p>{{ $t("charts.emptyState.loading") }}</p>
    </div>
    <div v-else>
      <h3 class="mt-2 text-base font-semibold text-primary">
        {{ $t("charts.emptyState.notFound.heading") }}
      </h3>
      <p class="mt-2 text-sm text-gray-500">
        {{ $t("charts.emptyState.notFound.desc") }}
      </p>
      <div :onClick="navigateToHome" class="cursor-pointer text-primary mt-4">
        {{ $t("charts.emptyState.notFound.button") }}
      </div>
    </div>
  </div>
</template>

<script>
import { PresentationChartLineIcon } from "@heroicons/vue/outline";

export default {
  components: {
    PresentationChartLineIcon,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  methods: {
    navigateToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
