<template>
  <InformationPopover
    class="ml-2 translate-y-0.5 z-50"
    :icon-class="'text-white'"
  >
    <template #header>
      {{ $t("charts.details") }}
    </template>
    <template #body>
      <div class="whitespace-pre-line">
        {{ description }}
      </div>
    </template>
  </InformationPopover>
</template>
<script>
import { InformationPopover } from "@/components/shared/index";
export default {
  components: { InformationPopover },
  props: { description: { type: String, required: true } },
};
</script>
