import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";

export default {
  methods: {
    isSupplierRequest(request) {
      if (request?.items?.length) {
        const { source } = request.items[0];
        return source && source.type === CATALOGUE_ITEM_SOURCE.SUPPLIER;
      }
      return false;
    },
  },
};
