<template>
  <div
    v-if="isApiLoading('news')"
    class="w-full min-h-[600px] flex items-center justify-center"
  >
    <HivebuyLoader class="w-36 h-36" />
  </div>
  <div v-if="!isApiLoading('news')" class="mt-4">
    <div v-if="news.length">
      <div class="space-y-6">
        <article
          v-for="newsObj in news"
          :key="newsObj.id"
          class="w-full flex flex-col items-start justify-between p-4 bg-white rounded shadow"
        >
          <div class="flex items-center justify-between w-full gap-x-4 text-xs">
            <div class="flex items-center gap-x-2">
              <div class="text-gray-500">
                {{ formattedDate(newsObj.createdAt) }}
              </div>
              <div
                :class="`px-2 py-0.5 rounded-xl text-xs font-medium text-white ${severityOptionClass(
                  newsObj.severity
                )}`"
              >
                {{
                  titleize(
                    $t(`news.newsModal.severityOptions.${newsObj.severity}`)
                  )
                }}
              </div>
            </div>
            <div v-if="isUserNewsAdmin" class="flex justify-end">
              <div>
                <PencilAltIcon
                  class="w-5 h-5 text-gray-300 mr-2 cursor-pointer hover:text-primary"
                  aria-hidden="true"
                  @click="() => onEdit(newsObj)"
                />
              </div>
              <div class="ml-4">
                <TrashIcon
                  class="w-5 h-5 text-gray-300 mr-2 cursor-pointer hover:text-hivebuy-red"
                  aria-hidden="true"
                  @click="() => onDelete(newsObj)"
                />
              </div>
            </div>
          </div>
          <div class="group relative">
            <h3
              class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600"
            >
              {{ newsObj.title }}
            </h3>
            <!-- eslint-disable vue/no-v-html -->
            <p
              id="news-description"
              class="mt-5 text-sm leading-6 text-gray-600"
              v-html="newsObj.description"
            />
          </div>
          <div class="mt-2">
            <span v-for="(file, i) in newsObj.files" :key="i">
              <a
                :href="file.file"
                class="inline-flex items-center text-hivebuy-green mr-4"
                download="download"
                target="_blank"
              >
                <LinkIcon class="w-5 h-5 text-gray-300 mr-1" />
                {{ file?.title || "Download" }}
              </a>
            </span>
          </div>
          <div class="relative mt-4 flex items-center gap-x-4">
            <UserWithAvatar
              :user="newsObj.createdBy"
              show-name
              :avatar-dimension="8"
            />
          </div>
        </article>
      </div>
    </div>
    <div v-else>
      <EmptyState
        :heading-text="$t('emptyStates.newsHeading')"
        :description-text="$t('emptyStates.newsText')"
      />
    </div>
  </div>

  <NewsForm
    ref="editNewsForm"
    :show-modal="showEditModal"
    :close-modal="onCloseEditModal"
    :save-news="onUpdate"
    :is-edit="true"
    :news-to-edit="newsToEdit"
  />

  <ConfirmDialog
    :text="$t('news.newsModal.deleteConfirmDialog.text')"
    :show-modal="showDeleteModal"
    :close-modal="onDeleteModalClose"
    :title="$t('news.newsModal.deleteConfirmDialog.title')"
    :item-to-confirm="newsToDelete?.title"
    :confirm-modal="onConfirmDelete"
    :loading="isApiLoading('newsDelete')"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  DASHBOARD_MODULE,
  DELETE_NEWS,
  GET_NEWS,
  SAVE_NEWS
} from "@/store/Dashboard/types";
import {
  ConfirmDialog,
  EmptyState,
  HivebuyLoader,
  UserWithAvatar
} from "@/components/shared";
import { AUTH_MODULE } from "@/store/Auth/types";
import { NEWS_SEVERITY_OPTIONS_CLASS_MAPPING } from "@/utils/constants";
import { titleize } from "@/utils/utility_methods";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";
import NewsForm from "@/components/News/NewsForm.vue";
import { LinkIcon } from "@heroicons/vue/outline";

export default {
  name: "List",
  components: {
    EmptyState,
    LinkIcon,
    NewsForm,
    PencilAltIcon,
    TrashIcon,
    UserWithAvatar,
    HivebuyLoader,
    ConfirmDialog
  },
  data() {
    return {
      showDeleteModal: false,
      showEditModal: false,
      newsToEdit: {},
      newsToDelete: {}
    };
  },
  computed: {
    ...mapState(DASHBOARD_MODULE, ["news"]),
    ...mapState(AUTH_MODULE, ["user"]),
    isUserNewsAdmin() {
      return this.user.roles.includes("NA");
    }
  },
  created() {
    !this.news.length && this.fetchNews();
  },
  methods: {
    titleize,
    ...mapActions(DASHBOARD_MODULE, {
      getNews: GET_NEWS,
      deleteNews: DELETE_NEWS,
      updateNews: SAVE_NEWS
    }),
    fetchNews() {
      this.setApiLoading({ key: "news", value: true });
      this.getNews()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "news", value: false }));
    },
    severityOptionClass(type) {
      return NEWS_SEVERITY_OPTIONS_CLASS_MAPPING[type];
    },
    onEdit(news) {
      this.newsToEdit = { ...news };
      this.showEditModal = true;
    },
    onDelete(news) {
      this.newsToDelete = { ...news };
      this.showDeleteModal = true;
    },
    onDeleteModalClose() {
      this.showDeleteModal = false;
      this.newsToDelete = {};
    },
    onConfirmDelete() {
      this.setApiLoading({ key: "newsDelete", value: true });
      this.deleteNews(this.newsToDelete.id)
        .then(() => {
          this.showNotification(
            this.$t("news.newsModal.deleteConfirmDialog.successText")
          );
          this.onDeleteModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "newsDelete", value: false }));
    },
    onCloseEditModal() {
      this.showEditModal = false;
      this.newsToEdit = {};
    },
    onUpdate(payload) {
      this.setApiLoading({ key: "newsUpdate", value: true });
      this.updateNews(payload)
        .then(() => {
          this.showNotification(this.$t("news.newsModal.editSuccessText"));
          this.$refs.editNewsForm.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => this.setApiLoading({ key: "newsUpdate", value: false }));
    }
  }
};
</script>
