import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  OPEN_CHARGE_BEE_PORTAL_SESSION,
} from "@/store/CompanyManagement/types";

export default {
  mounted() {
    if (!window.Chargebee) {
      const chargeBeeScript = document.createElement("script");
      chargeBeeScript.onload = this.onChargeBeeScriptLoaded;
      chargeBeeScript.type = "text/javascript";
      chargeBeeScript.src = "https://js.chargebee.com/v2/chargebee.js";
      document.head.appendChild(chargeBeeScript);
    } else {
      this.onChargeBeeScriptLoaded();
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      openChargeBeePortalSession: OPEN_CHARGE_BEE_PORTAL_SESSION,
    }),
    onChargeBeeScriptLoaded() {
      this.cbInstance = window.Chargebee.init({ site: "hivebuy-test" });
      this.cbInstance.setPortalSession(() => {
        return this.openChargeBeePortalSession().then(
          (response) => response.data
        );
      });
    },
    openPortal() {
      this.cbInstance.createChargebeePortal().open({
        subscriptionChanged: () => {},
      });
    },
  },
};
