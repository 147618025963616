<template>
  <List />
</template>

<script>
import List from "@/components/Projects/List";
export default {
  name: "Projects",
  components: { List },
};
</script>
