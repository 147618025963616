<template>
  <div class="flex ml-2 mr-2">
    <div
      v-for="object in baseList"
      :key="object"
      class="flex flex-row overflow-hidden -ml-2"
    >
      <Department
        v-if="entityType === 'department'"
        :department="object"
        with-tooltip
      />

      <UserWithAvatar
        v-else
        :user="object"
        :show-delete="showDelete"
        :show-tooltip="true"
        :on-delete="onDeleteMember"
        :avatar-dimension="avatarDimension"
        :department-name="object.name"
      />
    </div>
    <div v-if="slicedList.length > 0">
      <Popper :hover="true" :placement="'right'" :arrow="false">
        <span
          :class="[
            'text-xs -ml-2 inline-flex items-center justify-center bg-hivebuy_darkplum rounded-full border-white border relative cursor-default text-white',
            classes,
          ]"
        >
          +{{ slicedList.length }}
        </span>
        <template #content>
          <nav
            class="max-h-[80vh] overflow-y-auto overflow-x-hidden"
            aria-label="Directory"
          >
            <ul role="list" class="relative z-0 divide-y divide-gray-200">
              <li
                v-for="object in slicedList"
                :key="object"
                class="bg-white relative flex items-center px-2 py-2 text-sm"
              >
                <Department
                  v-if="entityType === 'department'"
                  :department="object"
                />
                <UserWithAvatar
                  v-else
                  :user="object"
                  :show-delete="showDelete"
                  :show-tooltip="showTooltip"
                  :on-delete="onDeleteMember"
                  :avatar-dimension="avatarDimension"
                  :department-name="object.name"
                  :show-entity-list-details="true"
                />
              </li>
            </ul>
          </nav>
        </template>
      </Popper>
    </div>
  </div>
</template>

<script>
import { Department, UserWithAvatar } from "@/components/shared";

export default {
  components: {
    Department,
    UserWithAvatar,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    showDisplayCount: {
      type: Number,
      default: 10,
    },
    entityType: {
      type: String,
      default: "department",
    },
    onDelete: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: Array,
      default: () => ["w-8 h-8"],
    },
    avatarDimension: {
      type: Number,
      default: 10,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slicedList() {
      return this.list.slice(this.showDisplayCount);
    },
    baseList() {
      return this.list.slice(0, this.showDisplayCount);
    },
  },
  methods: {
    onDeleteMember(member) {
      this.showDelete ? this.onDelete(member) : null;
    },
  },
};
</script>
