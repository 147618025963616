<template>
  <div
    v-if="itemLevelApproval && actionableProducts?.length"
    class="custom-card no-shadow mb-4"
  >
    <div class="flex justify-between items-center">
      <div class="content text-base font-semibold">
        <p class="text-primary">
          {{ $t("purchaseRequest.actionPanel.approveText") }}
        </p>
        <p class="text-sm text-gray-500 font-normal">
          {{ $t("purchaseRequest.actionPanel.approveSubText") }}
        </p>
      </div>
      <div v-if="purchaseRequest.id" class="buttons-holder">
        <div
          class="button-reject text-hivebuy-red hover:text-white"
          @click="() => confirmItemStatus('Rejected', actionableProducts)"
        >
          <DashboardLoader v-if="approveLoading" class="mr-2" />
          <ThumbDownIcon v-else class="h-5 w-5 mr-2" />

          <span class="hidden xl:block font-medium">{{
            $t("purchaseRequest.actionPanel.buttonReject")
          }}</span>
        </div>
        <div
          class="button-approve"
          @click="() => confirmItemStatus('Approved', actionableProducts)"
        >
          <DashboardLoader v-if="approveLoading" class="xl:mr-2 mr-0" />
          <ThumbUpIcon v-else class="h-5 w-5 xl:mr-2 mr-0" />
          <span class="hidden xl:block font-medium">{{
            $t("purchaseRequest.actionPanel.buttonApprove")
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!itemLevelApproval && canCurrentUserApprove">
    <div
      class="items-center flex flex-col lg:flex-row space-y-2 xl:space-y-0 space-x-4 bg-white border border-hivebuy_plum p-5 rounded-md w-full text-base mb-4 no-shadow"
    >
      <span class="p-1 rounded-lg bg-hivebuy_plum">
        <ExclamationCircleIcon
          class="h-10 w-10 text-white"
          aria-hidden="true"
        />
      </span>
      <div class="text-base font-normal p-2">
        {{ $t("purchaseRequest.summary.totalPrice.infoDecision") }}
        <p
          v-if="!purchaseRequest.actionRequired"
          class="text-xs font-light mt-2"
        >
          {{ $t("purchaseRequest.summary.totalPrice.adminInfoDecision") }}
        </p>
      </div>
      <div v-if="showButtons" class="flex flex-1 xl:justify-end justify-around">
        <div
          class="space-x-2 lg:space-x-0 lg:space-y-2 flex flex-row lg:flex-col justify-between xl:justify-around items-center"
        >
          <Button
            id="approve-button"
            class="btn-success"
            :loading="loading"
            full
            :on-click-handler="
              () =>
                setRequestedPurchaseRequestAttributes(
                  PURCHASE_REQUEST_STATUS_MAPPING['A']
                )
            "
          >
            <template #icon><ThumbUpIcon /></template>
            {{ $t("purchaseRequest.actionPanel.buttonApprove") }}
          </Button>

          <Button
            id="reject-button"
            color="danger"
            full
            :loading="loading"
            :on-click-handler="
              () =>
                setRequestedPurchaseRequestAttributes(
                  PURCHASE_REQUEST_STATUS_MAPPING['R']
                )
            "
          >
            <template #icon><ThumbDownIcon /></template>
            {{ $t("purchaseRequest.actionPanel.buttonReject") }}
          </Button>

          <Button
            v-if="showForwardButton"
            id="forward-button"
            class="btn-green"
            full
            :on-click-handler="
              () =>
                setRequestedPurchaseRequestAttributes(
                  PURCHASE_REQUEST_STATUS_MAPPING['D']
                )
            "
          >
            <template #icon><FastForwardIcon /></template>
            {{ $t("purchaseRequest.actionPanel.buttonForward") }}
          </Button>

          <StatusUpdateModal
            :show-modal="showRequestedPurchaseRequestStatusModal"
            :close-modal="onPurchaseRequestActionClose"
            :requested-purchase-request-status="requestedPurchaseRequestStatus"
            :purchase-request="purchaseRequest"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PURCHASE_REQUEST_STATUS_MAPPING,
  TIMELINE_STATUS,
} from "@/utils/constants";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ExclamationCircleIcon,
  FastForwardIcon,
} from "@heroicons/vue/outline";
import Button from "@/components/shared/Button.vue";
import StatusUpdateModal from "@/components/PurchaseRequest/SummarySections/StatusUpdateModal";
import {
  PURCHASE_REQUEST_MODULE,
  UPDATE_PURCHASE_REQUEST_STATUS,
} from "@/store/PurchaseRequest/types";
import { mapActions } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import { DashboardLoader } from "@/components/shared";
export default {
  components: {
    ThumbDownIcon,
    ThumbUpIcon,
    Button,
    ExclamationCircleIcon,
    StatusUpdateModal,
    DashboardLoader,
    FastForwardIcon,
  },
  inject: ["confirmItemStatus"],
  props: {
    approveLoading: {
      type: Boolean,
      default: false,
    },
    itemLevelApproval: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      requestedPurchaseRequestStatus: "",
      showRequestedPurchaseRequestStatusModal: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    canCurrentUserApprove() {
      const allowedRoles =
        this.user.roles.includes("O") || this.user.roles.includes("MD");
      return this.purchaseRequest.actionRequired || allowedRoles;
    },
    actionableProducts() {
      return this.purchaseRequest.items.filter((item) => item.actionRequired);
    },
    showForwardButton() {
      const allowedRoles = this.user.roles.includes("O", "MD");
      return (
        (this.purchaseRequest.actionRequired || allowedRoles) &&
        !this.purchaseRequest?.approvals.find((approval) => {
          return (
            approval.decidedBy === this.user.id &&
            approval.status == TIMELINE_STATUS.delegated
          );
        })
      );
    },
  },
  created() {
    this.PURCHASE_REQUEST_STATUS_MAPPING = PURCHASE_REQUEST_STATUS_MAPPING;
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updatePurchaseRequestStatus: UPDATE_PURCHASE_REQUEST_STATUS,
    }),

    setRequestedPurchaseRequestAttributes(status) {
      this.requestedPurchaseRequestStatus = status;
      this.showRequestedPurchaseRequestStatusModal = true;
    },
    onPurchaseRequestActionClose() {
      this.showRequestedPurchaseRequestStatusModal = false;
      setTimeout(() => {
        this.requestedPurchaseRequestStatus = "";
      }, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
.buttons-holder {
  @apply flex items-center justify-center;
}

.buttons-holder .button-approve,
.buttons-holder .button-reject {
  @apply flex justify-center items-center;
}

.buttons-holder .button-approve {
  @apply px-4 py-2 rounded-full bg-hivebuy-green text-white text-sm ml-4 cursor-pointer hover:ring-2 hover:ring-offset-2 ring-hivebuy-green;
}
.buttons-holder .button-reject {
  @apply px-4 py-2 rounded-full text-hivebuy-red text-sm cursor-pointer hover:bg-hivebuy-red hover:text-white transition-all duration-500 hover:ring-2 hover:ring-offset-2 ring-hivebuy-red;
}
</style>
>
