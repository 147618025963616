<template>
  <section class="tabs-holder cursor-pointer">
    <div class="close-button group" @click="closeForm">
      <XIcon class="w-4 h-4 text-gray-600 group-hover:text-primary" />
    </div>
    <div
      v-for="step in Object.entries(stepDetails)"
      :key="step[0]"
      class="group"
      :class="['tab', { active: step[0] == currentStep }]"
      @click="() => changeStep(false, false, step[0])"
    >
      <div :class="['tab-icon']">
        <component :is="step[1].icon" class="icon group-hover:text-primary" />
      </div>
      <div class="tab-content text-gray-400 group-hover:text-primary">
        <div class="title flex">
          {{ step[1].title }}
          <FieldError
            v-if="step[1].error"
            text=""
            class="animate-bounce -mt-6"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  CheckIcon,
  XIcon,
  DocumentTextIcon,
  UserIcon,
  CalendarIcon,
  LocationMarkerIcon,
} from "@heroicons/vue/outline";
import { FieldError } from "@/components/shared/index";
import { intersection } from "@/utils/utility_methods.js";
import QuantitySVG from "@/assets/images/svg/QuantitySVG.vue";
export default {
  components: {
    CheckIcon,
    XIcon,
    DocumentTextIcon,
    UserIcon,
    QuantitySVG,
    CalendarIcon,
    LocationMarkerIcon,
    FieldError,
  },
  props: {
    closeForm: { type: Function, required: true },
    changeStep: { type: Function, required: true },
    currentStep: { type: String, required: true },
    errorFields: { type: Array, default: () => [] },
  },
  computed: {
    stepDetails() {
      return {
        basic: {
          error: intersection(this.errorFields, ["name"]).length,
          title: this.$t("shoppingList.form.stepOneTitle"),
          icon: "DocumentTextIcon",
        },
        requestedFor: {
          error: intersection(this.errorFields, ["requestedFor"]).length,
          title: this.$t("shoppingList.form.stepTwoTitle"),
          icon: "UserIcon",
        },
        quantity: {
          error: intersection(this.errorFields, ["quantity"]).length,
          title: this.$t("shoppingList.form.stepThreeTitle"),
          icon: "QuantitySVG",
        },
        date: {
          title: this.$t("shoppingList.form.stepFourTitle"),
          icon: "CalendarIcon",
        },
        address: {
          error: intersection(this.errorFields, [
            "deliveryAddress",
            "invoiceAddress",
          ]).length,
          title: this.$t("shoppingList.form.addresses"),
          icon: "LocationMarkerIcon",
        },
      };
    },
  },
};
</script>
<style scoped>
.tabs-holder {
  @apply flex bg-gray-50 rounded-t-md relative pb-6 pr-10 lg:pr-0;
}

.close-button {
  @apply absolute right-4 bg-white border border-gray-200 w-8 h-8 rounded-full flex justify-center items-center top-4 hover:border-primary z-50 transition-all duration-300 cursor-pointer;
}

.tabs-holder .tab {
  @apply flex flex-1 p-4 flex-col justify-center items-center relative;
}

.tabs-holder .tab:before {
  @apply absolute left-[50%] w-full h-[2px] bg-gray-200 top-[30px];
  content: "";
}

.tabs-holder .tab:last-child::before {
  display: none;
}

.tabs-holder .tab .tab-icon {
  @apply items-center justify-center flex  mb-2 bg-gray-50 text-gray-400;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 32px;
  height: 28px;
  position: relative;
}

.tabs-holder .tab .tab-icon .icon {
  @apply w-5 h-5;
}

/* Tab Content */

.tabs-holder .tab .tab-content {
  @apply flex flex-col hidden xl:flex;
}
.tabs-holder .tab .tab-content .title {
  @apply text-sm font-semibold;
}
/* Active Tab */

.tabs-holder .tab.active .tab-icon {
  @apply bg-primary border-0;
}
.tabs-holder .tab.active .tab-icon:after {
  content: "";
  @apply bg-gray-50;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 30px;
  height: 26px;
  position: absolute;
  left: 1px;
  top: 1px;
}

.tabs-holder .tab.active .tab-icon .icon {
  @apply text-primary relative z-30;
}
.tabs-holder .tab.active .tab-content .title {
  @apply text-primary;
}
.tabs-content {
  @apply p-8 justify-center;
}

/* Completed */

.tabs-holder .tab.completed:before {
  @apply bg-primary;
}

.tabs-holder .tab.completed .tab-icon {
  @apply bg-primary border-0;
}
.tabs-holder .tab.completed .tab-icon .icon {
  @apply text-white;
}

.tabs-holder .tab.completed .tab-content .title {
  @apply text-primary;
}
</style>
