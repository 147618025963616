<template>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select
      id="tabs"
      name="tabs"
      class="block w-full focus:ring-teal-500 focus:border-primary border-gray-300 rounded-md"
    >
      <option v-for="tab in tabs" :key="tab.name" :selected="activeTab">
        {{ tab.name }}
      </option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="tabs" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[
            activeTab == tab.component ? 'tab--active' : '',
            'tab group',
          ]"
          @click="() => onTabChange(tab)"
        >
          <component
            :is="tab.icon"
            :class="[
              activeTab == tab.component
                ? 'text-primary'
                : 'text-gray-400 group-hover:text-primary',
              '-ml-0.5 mr-2 h-5 w-5',
            ]"
            aria-hidden="true"
          />
          <span>
            {{ tab.name }}
          </span>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    onTabChange: {
      type: Function,
      required: true,
    },
    activeTab: {
      type: String,
      default: "ProfileInfo",
    },
  },
};
</script>
