import { Button, PDFViewer } from "@/components/shared";
import { PhotographIcon } from "@heroicons/vue/solid";
import {
  getKeyByValue,
  getUrlExtension,
  titleize,
} from "@/utils/utility_methods";

export default {
  components: {
    PhotographIcon,
    PDFViewer,
    Button,
  },
  methods: {
    fileUrl(file) {
      return file && `${file}#toolbar=0`;
    },
    isPdfFile(file) {
      return (
        file &&
        (file.title.includes("pdf") || getUrlExtension(file.file) == "pdf")
      );
    },
    descriptiveValue(obj, value) {
      return titleize(getKeyByValue(obj, value));
    },
    contractTypes(status) {
      return {
        U: this.$t("shoppingList.types.unit"),
        S: this.$t("shoppingList.types.supplier"),
        HR: this.$t("shoppingList.types.other"),
      }[status];
    },
    noticePeriodTypes() {
      return {
        days: this.$t("contracts.contractForm.days"),
        weeks: this.$t("contracts.contractForm.weeks"),
        months: this.$t("contracts.contractForm.months"),
        years: this.$t("contracts.contractForm.years"),
      };
    },
    contractStatusTypes(status) {
      return this.$t(`statuses.contract.${status}`);
    },
  },
};
