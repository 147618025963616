<template>
  <div>
    <label for="price" class="block text-sm font-medium text-gray-700"
      >Cost {{ label }}</label
    >
    <div class="relative mt-1 rounded-md shadow-sm">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <span class="text-gray-500 sm:text-sm">{{ selector }}</span>
      </div>
      <CurrencyInput v-model.lazy="value" :currency-display="currencyDisplay" />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label for="currency" class="sr-only">Currency</label>
        <select
          v-model="selector"
          class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-8 text-gray-500 focus:border-primary focus:ring-primary sm:text-sm"
        >
          <option>123</option>
          <option>%</option>
        </select>
      </div>
    </div>
    <p v-if="v$.$error" class="text-xs font-light text-hivebuy-red mt-0.5">
      Cannot be higher than 100%
    </p>
  </div>
</template>
<script>
import { CurrencyInput } from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { maxValue } from "@vuelidate/validators";
export default {
  components: {
    CurrencyInput,
  },
  props: {
    label: { type: String, required: true },
    modelValue: { type: String, required: true },
    onAttrChange: { type: Function, required: true },
    purchaseRequest: { type: Object, required: true },
  },
  data() {
    return { selector: "123", value: 0, amount: 0, v$: useValidate() };
  },
  computed: {
    currencyDisplay() {
      return "hidden";
    },
    isNumber() {
      return this.selector === "123";
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        if (val) {
          this.amount = val;
        } else {
          this.amount = 0;
        }
      },
    },
    value: {
      handler(val) {
        if (val) {
          this.v$.$validate();
          if (this.selector == "123") {
            this.amount = val;
          } else {
            this.calculatePercentage(val);
          }
        } else {
          this.amount = 0;
        }
        this.onAttrChange({ name: this.label, value: this.amount });
      },
    },
    selector: {
      handler(val) {
        this.v$.$validate();
        if (val == "123") {
          this.amount = this.value;
        } else {
          this.calculatePercentage(this.value);
        }
        this.onAttrChange({ name: this.label, value: this.amount });
      },
    },
  },
  mounted() {
    this.value = this.modelValue;
    this.amount = this.modelValue;
  },

  methods: {
    calculatePercentage(number) {
      if (number) {
        let percentage = 100 - number;
        if (percentage > 100) percentage = 100;
        if (percentage > 0) {
          this.amount = (
            this.purchaseRequest.totalNetAmount / (percentage / 100) -
            this.purchaseRequest.totalNetAmount
          ).toFixed(2);
        } else this.amount = 0;
      }
    },
    useValidate,
  },
  validations() {
    let returnValue = {};
    if (!this.isNumber) {
      returnValue = {
        value: {
          maxValue: maxValue(100),
        },
      };
    }
    return returnValue;
  },
};
</script>
