<template>
  <div
    class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap"
  >
    <div class="ml-4 mt-4">
      <div class="flex items-center">
        <div class="ml-4">
          <p class="text-sm text-gray-500">
            {{ notification.text }}
          </p>
          <div class="text-gray-500 text-xs ml-auto">
            {{ timeAgo(notification.createdAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { notificationMixin } from "@/components/mixins/index.js";
export default {
  mixins: [notificationMixin],
};
</script>
