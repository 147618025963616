<template>
  <CollapseTransition>
    <div v-if="showDetails" class="block">
      <slot />
    </div>
  </CollapseTransition>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "CollapsibleSection",
  components: {
    CollapseTransition,
  },
  props: {
    showDetails: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
