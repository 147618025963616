<template>
  <div class="timeline">
    <div class="flex justify-start">
      <div class="section-title">
        {{ $t("purchaseRequest.summary.timeline.header") }}
        <Status v-if="approvalsLoading" status="L" class="m-2" />
      </div>
      <div
        v-if="object?.needByDate"
        class="flex text-sm font-medium items-top content-top pt-l ml-4"
      >
        <CalendarIcon class="h-4 w-4 mr-2 text-hivebuy_plum" />
        {{ $t("purchaseRequest.detail.needBy") }}:
        {{ formattedDate(object.needByDate) }}
      </div>
    </div>

    <div v-if="!approvalsLoading">
      <ul v-if="timeLineObjectsWithDetails?.length">
        <li
          v-for="(timeLineObject, index) in timeLineObjectsWithDetails"
          :key="timeLineObject.id"
          :class="[
            {
              'timeline-divider': index + 1 < timeLineObjectsWithDetails.length,
            },
            'relative text-center sm:text-left sm:mb-8 mb-0',
          ]"
        >
          <InstantApprovedRow
            v-if="timeLineObject.type == TIMELINE_STATUS.instantApproved"
          />
          <ApprovalRow
            v-if="
              timeLineObject.type == TIMELINE_STATUS.approval &&
              showApproval(timeLineObject.details)
            "
            :object="timeLineObject.details"
            :overall-status="overallStatus"
            :sent-time="showPendingDate(index)"
          />
          <FullyApprovedRow
            v-if="
              timeLineObject.type == TIMELINE_STATUS.fullyApproved && !isInvoice
            "
          />
          <OrderRow
            v-if="timeLineObject.type == TIMELINE_STATUS.ordered"
            :details="timeLineObject.details"
            :auto-processing="isAutoProcessing"
          />
          <SentRow
            v-if="timeLineObject.type == TIMELINE_STATUS.sent"
            :details="timeLineObject.details"
            :auto-processing="isAutoProcessing"
          />
          <ResentRow
            v-if="timeLineObject.type == TIMELINE_STATUS.resent"
            :details="timeLineObject.details"
            :auto-processing="isAutoProcessing"
          />
          <InDeliveryRow
            v-if="timeLineObject.type == TIMELINE_STATUS.inDelivery"
            :details="timeLineObject.details"
            :auto-processing="isAutoProcessing"
          />
          <PartialDeliveredRow
            v-if="timeLineObject.type === TIMELINE_STATUS.partialDelivered"
            :details="timeLineObject.details"
          />
          <DeliverRow
            v-if="timeLineObject.type == TIMELINE_STATUS.delivered"
            :details="timeLineObject.details"
          />
          <InvoiceRow
            v-if="timeLineObject.type == TIMELINE_STATUS.invoiced"
            :details="timeLineObject.details"
          />
          <CompleteRow
            v-if="timeLineObject.type == TIMELINE_STATUS.complete"
            :details="timeLineObject.details"
          />
          <RejectRow
            v-if="timeLineObject.type == TIMELINE_STATUS.rejected"
            :details="timeLineObject.details"
          />
          <RevisionRow
            v-if="timeLineObject.type == TIMELINE_STATUS.revision"
            :details="timeLineObject.details"
          />
          <CancelRow
            v-if="timeLineObject.type == TIMELINE_STATUS.cancelled"
            :details="timeLineObject.details"
          />
          <PendingRow
            v-if="timeLineObject.type == TIMELINE_STATUS.pending"
            :details="timeLineObject.details"
          />
          <OpenRow
            v-if="timeLineObject.type == TIMELINE_STATUS.open"
            :details="timeLineObject.details"
          />
          <DelegateRow
            v-if="timeLineObject.type == TIMELINE_STATUS.delegated"
            :details="timeLineObject.details"
          />
          <div
            v-if="showResultSection() && object.purchaseOrder?.status != 'R'"
            class="timeline-divider"
          />
        </li>
      </ul>
      <ul v-else>
        <li
          class="timeline-divider relative text-center sm:text-left sm:mb-8 mb-0"
        >
          <div class="items-center text-sm font-medium grid grid-cols-12 gap-2">
            <span
              class="inline-flex items-center justify-center h-9 w-9 rounded-full bg-green-100 text-sm sm:col-span-1 md:col-span-2 col-span-12 lg:col-span-1 mx-auto sm:mx-0 my-4 sm:my-0"
            >
              <ThumbUpIcon class="w-5 h-5 text-teal-500" />
            </span>
            <div
              class="col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2"
            >
              <span class="text-gray-400 mr-1">{{
                $t("purchaseRequest.summary.timeline.instantApproval")
              }}</span>
            </div>
          </div>
          <div class="timeline-divider" />
        </li>
      </ul>
      <ul
        v-if="
          showResultSection() &&
          !isInvoice &&
          object.purchaseOrder?.status != 'R'
        "
      >
        <li class="relative text-center sm:text-left sm:mb-8 mb-0">
          <ResultRow
            :supplier="object.companySupplier"
            :result="object.result"
            :is-auto-processing="isAutoProcessing"
            :overall-status="overallStatus"
          />
        </li>
      </ul>
      <ul v-if="isInvoice">
        <li class="relative text-center sm:text-left sm:mb-8 mb-0">
          <div class="items-center text-sm font-medium grid grid-cols-12 gap-2">
            <span
              :class="[
                'inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-2 col-span-12 lg:col-span-1 mx-auto sm:mx-0 my-4 sm:my-0',
                ,
                { 'bg-green-100': invoiceApproved },
                { 'bg-red-100': invoiceRejected },
              ]"
            >
              <CheckCircleIcon
                v-if="invoiceApproved"
                class="w-5 h-5 text-teal-500"
              />
              <ThumbDownIcon
                v-else-if="invoiceRejected"
                class="w-5 h-5 text-red-500"
              />
              <ClockIcon v-else class="w-5 h-5 text-gray-500" />
            </span>
            <div
              class="col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2"
            >
              <span v-if="invoiceApproved" class="text-gray-400 mr-1">{{
                $t("purchaseRequest.detail.fullyApproved")
              }}</span>
              <span v-else-if="invoiceRejected" class="text-gray-400 mr-1">
                {{ $t("purchaseRequest.detail.rejected") }}
              </span>
              <span v-else class="text-gray-400 mr-1">{{
                $t("purchaseRequest.detail.notApproved")
              }}</span>
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="showResultAsComplete">
        <li class="relative text-center sm:text-left sm:mb-8 mb-0">
          <div class="items-center text-sm font-medium grid grid-cols-12 gap-2">
            <span
              class="inline-flex bg-green-100 items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-2 col-span-12 lg:col-span-1 mx-auto sm:mx-0 my-4 sm:my-0"
            >
              <CheckCircleIcon class="w-5 h-5 text-teal-500" />
            </span>
            <div
              class="col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2"
            >
              <span class="text-gray-400 mr-1">{{
                $t("purchaseRequest.detail.approved")
              }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  ThumbUpIcon,
  CheckCircleIcon,
  ClockIcon,
  ThumbDownIcon,
} from "@heroicons/vue/solid";
import { TIMELINE_STATUS } from "@/utils/constants.js";

import {
  ApprovalRow,
  ResultRow,
  OrderRow,
  FullyApprovedRow,
  DeliverRow,
  RejectRow,
  SentRow,
  ResentRow,
  CancelRow,
  InstantApprovedRow,
  OpenRow,
  RevisionRow,
  PendingRow,
  DelegateRow,
} from "@/components/PurchaseRequest/SummarySections/Timeline/index.js";
import { CalendarIcon } from "@heroicons/vue/outline";
import { requestTypeMixin, TimelineMixin } from "@/components/mixins/index.js";
import InDeliveryRow from "@/components/PurchaseRequest/SummarySections/Timeline/InDeliveryRow.vue";
import InvoiceRow from "@/components/PurchaseRequest/SummarySections/Timeline/InvoiceRow.vue";
import CompleteRow from "@/components/PurchaseRequest/SummarySections/Timeline/CompleteRow.vue";
import PartialDeliveredRow from "@/components/PurchaseRequest/SummarySections/Timeline/PartialDeliveredRow.vue";
import Status from "@/components/PurchaseRequest/SummarySections/Status.vue";
export default {
  components: {
    PartialDeliveredRow,
    ThumbUpIcon,
    ApprovalRow,
    FullyApprovedRow,
    InstantApprovedRow,
    ResultRow,
    CheckCircleIcon,
    ClockIcon,
    OrderRow,
    DeliverRow,
    RejectRow,
    SentRow,
    CancelRow,
    CalendarIcon,
    InDeliveryRow,
    OpenRow,
    RevisionRow,
    PendingRow,
    ThumbDownIcon,
    InvoiceRow,
    CompleteRow,
    DelegateRow,
    ResentRow,
    Status,
  },
  mixins: [requestTypeMixin, TimelineMixin],
  props: {
    object: {
      type: Object,
      required: true,
    },
    isInvoice: {
      type: Boolean,
      required: false,
      default: false,
    },
    showResultAsComplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAutoProcessing: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    invoiceApproved() {
      return this.object.status == "A";
    },
    invoiceRejected() {
      return this.object.status == "R";
    },
    approvalsLoading() {
      if (this.isInvoice) return false;

      if (!this.object.instantApproval) return !this.object.approvals.length;
      return false;
    },
  },
  created() {
    this.TIMELINE_STATUS = TIMELINE_STATUS;
  },
  methods: {
    showApproval(object) {
      return this.isInvoice
        ? ["Approved", "Pending"].includes(object.status)
        : true;
    },
  },
};
</script>

<style scoped>
.timeline-divider::before {
  content: "";
  position: absolute;
  top: 36px;
  right: 0;
  left: 0;
  bottom: 0;
  width: 18px;
  height: 100%;
  @apply border-r-2 border-gray-300;
  @apply sm:block !important;
  @apply hidden;
}
.pt-l {
  padding-top: 2px;
}
</style>
