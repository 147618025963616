export default {
  proposals: [],
  proposalsListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
      search: "",
    },
  },
};
