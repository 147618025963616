<template>
  <div
    v-if="badge"
    class="px-2 py-0.5 rounded-lg text-xs 2xl:text-sm font-medium"
    :class="className"
  >
    <TruncateText :text="$t(`global.roles.${role}`)" />
  </div>
  <template v-else-if="doesTranslationExists">
    {{ $t(`global.roles.${role}`) }}
  </template>
  <template v-else>-</template>
</template>

<script>
export default {
  props: {
    roleName: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      required: true,
    },
    badge: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    doesTranslationExists() {
      return this.$te(`global.roles.${this.role}`);
    },
  },
};
</script>

<style scoped></style>
