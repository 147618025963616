// Module
export const PROPOSAL_MODULE = "PROPOSAL_MODULE";

// Actions
export const GET_PROPOSALS = "GET_PROPOSALS";
export const CREATE_PROPOSAL = "CREATE_PROPOSAL";
export const GET_PROPOSAL = "GET_PROPOSAL";
export const UPDATE_PROPOSAL_MEMBERS = "UPDATE_PROPOSAL_MEMBERS";
export const CREATE_SUGGESTION = "CREATE_SUGGESTION";
export const LIKE_SUGGESTION = "LIKE_SUGGESTION";
export const DISLIKE_SUGGESTION = "DISLIKE_SUGGESTION";
export const DELETE_SUGGESTION = "DELETE_SUGGESTION";
export const UPDATE_SUGGESTION_PURCHASE_REQUEST =
  "UPDATE_SUGGESTION_PURCHASE_REQUEST";
export const FINALISE_SUGGESTION = "FINALISE_SUGGESTION";
export const UPDATE_PROPOSAL = "UPDATE_PROPOSAL";

//Mutations
export const SET_PROPOSALS = "SET_PROPOSALS";
export const SET_PROPOSALS_LIST_FILTERS = "SET_PROPOSALS_LIST_FILTERS";
export const UPDATE_VOTES = "UPDATE_VOTES";
export const UPDATE_PROPOSAL_STATUS = "UPDATE_PROPOSAL_STATUS";
export const SET_PROPOSAL = "SET_PROPOSAL";
