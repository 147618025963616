import { get, deleteObject, post, patch, cachedGet } from "../api";
import { CATEGORIES_API_URL } from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";
import store from "@/store";
export const getCategories = (commit, resolve, reject) => {
  get(CATEGORIES_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_CATEGORIES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteCategory = (categoryId, commit, resolve, reject) => {
  if (!categoryId) return;

  deleteObject(`${CATEGORIES_API_URL}${categoryId}/`)
    .then((response) => {
      commit(CompanyManagementTypes.DELETE_CATEGORY, categoryId);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getCategory = (categoryId, commit, resolve, reject) => {
  if (!categoryId) return;

  const categoryGetter =
    store.getters[
      `${CompanyManagementTypes.COMPANY_MANAGEMENT_MODULE}/categoryDetails`
    ];
  const response = categoryGetter(categoryId, true);
  if (response) {
    return resolve(response);
  }

  cachedGet(`${CATEGORIES_API_URL}${categoryId}/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const addCategory = (payload, commit, resolve, reject) => {
  post(CATEGORIES_API_URL, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_CATEGORY, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveCategory = (payload, commit, resolve, reject) => {
  if (payload?.id) updateCategory(payload.id, payload, commit, resolve, reject);
  else addCategory(payload, commit, resolve, reject);
};

export const updateCategory = (
  categoryId,
  payload,
  commit,
  resolve,
  reject
) => {
  if (!categoryId) return;

  patch(`${CATEGORIES_API_URL}${categoryId}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_CATEGORY, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
