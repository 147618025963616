<template>
  <button
    :id="id"
    class="transition-colors"
    :class="[btnClasses, colorClasses]"
    :disabled="disabled"
    type="button"
    v-on="{ click: !loading && !active ? onClickHandler : null }"
  >
    <span
      v-if="showIcon && !loading"
      :class="[
        'h-4 w-4 flex items-center justify-center',
        btnGroup != 'solo' ? '' : 'mr-1',
        showIcon ? 'mr-1.5' : '',
      ]"
    >
      <slot name="icon" />
    </span>
    <span v-if="loading" class="mr-1 text-primary">
      <svg
        class="animate-spin h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sizeClass: {
      type: String,
      default: "sm",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClickHandler: {
      type: Function,
      required: true,
    },
    btnGroup: {
      type: String,
      default: "solo",
    },
    colorClasses: {
      type: String,
      default: "btn-default",
    },
    bgColor: {
      type: String,
      default: "btn-default",
    },
    hoverColor: {
      type: String,
      default: "bg-white",
    },
    hoverTextColor: {
      type: String,
      default: "text-primary",
    },
    textColor: {
      type: String,
      default: "text-gray-500",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClasses() {
      const classes = {
        "bar-btn": true,
        "btn-solo": this.btnGroup === "solo",
        "btn-active": this.active,
        "btn-right": this.btnGroup === "right",
        "btn-left": this.btnGroup === "left",
        "btn-middle": this.btnGroup === "middle",
        "btn-loading": this.loading,
        "btn-disabled": this.disabled,
      };

      return classes;
    },
  },
};
</script>

<style scoped>
.btn-default {
  @apply bg-white;
}
.bar-btn {
  @apply relative inline-flex shrink-0 items-center px-2 py-1.5 border border-gray-300 text-sm font-medium hover:shadow focus:z-10 focus:outline-none;
}

.btn-active {
  @apply bg-primary text-white cursor-default;
}
.btn-solo {
  @apply rounded-md;
}
.btn-right {
  @apply rounded-r-md px-2.5 border-l-0;
}
.btn-left {
  @apply rounded-l-md px-2.5;
}
.btn-middle {
  @apply rounded-none px-2.5 border-l-0;
}
.btn-loading {
  @apply cursor-not-allowed hover:shadow-none focus:ring-0;
}
.btn-disabled {
  @apply text-gray-300 hover:text-gray-300 hover:shadow-none cursor-not-allowed hover:bg-current;
}
</style>
