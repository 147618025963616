<template>
  <div
    class="flex items-center justify-between title group-hover:text-primary py-1 group relative cursor-pointer"
    @click="() => setQuickView(purchaseOrder)"
  >
    <div class="mr-4 truncate title-wrapper max-w-[22rem]">
      {{ request.title }}
      <p class="text-gray-400 text-xs flex items-center gap-x-2">
        {{ request.externalId }}
        <StatusTag :status="purchaseOrder.status" type="order" size="small" />
        <Tip v-if="purchaseOrder.datevJob" theme="time" :text="'DATEV'">
          <template #placeholder>
            <div
              class="rounded text-xs px-1 py-0.5 border border-gray-100"
              :class="[
                datevStatus(purchaseOrder.datevJob, $t).color,
                datevStatus(purchaseOrder.datevJob, $t).textColor,
              ]"
            >
              <span>DATEV</span>
            </div>
          </template>
          <template #content>
            {{ datevStatus(purchaseOrder.datevJob, $t).text }}
          </template>
        </Tip>
      </p>
    </div>
    <div class="action-buttons">
      <div class="mr-1">
        <ListButton :on-click-handler="() => setQuickView(purchaseOrder)">
          <template #icon>
            <EyeIcon class="h-5 w-5 group-hover:text-primary" />
          </template>
        </ListButton>
      </div>
      <div>
        <ListButton :on-click-handler="() => setDetailView(request)">
          <template #icon>
            <ChevronRightIcon class="h-5 w-5" />
          </template>
        </ListButton>
      </div>
    </div>
  </div>
</template>
<script>
import { EyeIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import StatusTag from "@/components/shared/StatusTag";
import { ListButton } from "@/components/shared/index";
import { datevStatus } from "@/utils/helpers/datevHelper.js";
export default {
  components: {
    ListButton,
    EyeIcon,
    ChevronRightIcon,
    StatusTag,
  },
  props: {
    setQuickView: { type: Function, default: () => {} },
    setDetailView: { type: Function, default: () => {} },
    purchaseOrder: { type: Object, required: true },
  },
  computed: {
    request() {
      return this.purchaseOrder.purchaseRequest || {};
    },
  },
  methods: {
    datevStatus,
  },
};
</script>
<style>
.action-buttons {
  @apply absolute hidden group-hover:flex right-0;
}
</style>
