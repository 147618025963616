<template>
  <button
    type="button"
    :class="[
      buttonsStacked ? 'action' : 'round-button',
      `hover:text-hivebuy-${hoverColor}`,
    ]"
    class="rounded-md button text-black"
    @click.stop="toggle"
  >
    <slot name="icon"> Change Status </slot>
  </button>
  <ConfirmDialog
    :text="subtext"
    :show-modal="show"
    :close-modal="toggle"
    :title="$t('buttons.confirm')"
    :item-to-confirm="invoice.invoiceNumber"
    :confirm-modal="onConfirmHandler"
    :loading="loading"
  />
</template>

<script>
import invoiceMixin from "@/components/mixins/invoiceMixin.js";
import { ConfirmDialog } from "@/components/shared";
export default {
  components: {
    ConfirmDialog,
  },
  mixins: [invoiceMixin],
  props: {
    buttonsStacked: { type: Boolean, default: false },
    invoice: {
      type: Object,
      default: () => {},
    },
    newStatus: {
      type: String,
      default: "NI",
    },
    hoverColor: {
      type: String,
      default: "red",
    },
    callBack: { type: Function, default: () => {} },
  },
  data() {
    return {
      show: false,
      loading: false,
    };
  },

  computed: {
    subtext() {
      return {
        P: this.$t("invoice.confirmation.reApproval"),
        N: this.$t("invoice.confirmation.reInvoice"),
        NI: this.$t("invoice.confirmation.noInvoice"),
        R: this.$t("invoice.confirmation.reject"),
      }[this.newStatus];
    },
  },

  methods: {
    toggle() {
      this.show = !this.show;
    },

    onConfirmHandler() {
      this.loading = true;
      let promise = null;
      if (this.newStatus == "R") {
        promise = this.updateRelation({
          invoiceRelationId: this.invoice.id,
          isInvoiceLevelApprover: true,
          payload: { status: "Rejected" },
        });
      } else if (this.newStatus == "NI") {
        promise = this.markNotInvoice(this.invoice.id);
      } else {
        promise = this.markStatusInvoice(this.invoice, this.newStatus);
      }
      promise
        .then(() => {
          this.callBack();
          this.toggle();
          this.showNotification("Updated Successfully");
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.action {
  @apply relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-primary hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary transition-all duration-300;
}

.round-button {
  @apply rounded-full w-8 h-8 flex items-center align-middle justify-center cursor-pointer text-gray-300;
}
</style>
