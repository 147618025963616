import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_INTEGRATED_SUPPLIERS,
} from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["integratedSuppliersList"]),
  },

  mounted() {
    if (this.integratedSuppliersList().length < 5) this.getList();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getIntegratedSuppliers: GET_COMPANY_INTEGRATED_SUPPLIERS,
    }),
    getList() {
      this.setApiLoading({ key: "companyIntegratedSuppliers", value: true });
      this.getIntegratedSuppliers()
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({
            key: "companyIntegratedSuppliers",
            value: false,
          })
        );
    },
  },
};
