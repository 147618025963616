import store from "@/store/index.js";
import {
  PURCHASE_ORDER_MODULE,
  LIVE_UPDATE_ORDER_REQUEST,
} from "@/store/PurchaseOrder/types";
import {
  PURCHASE_REQUEST_MODULE,
  SET_REQUEST_DETAILS,
} from "@/store/PurchaseRequest/types";
export const purchaseOrderUpdated = (data) => {
  const poObject = data.purchaseOrder;
  store.commit(
    `${PURCHASE_ORDER_MODULE}/${LIVE_UPDATE_ORDER_REQUEST}`,
    poObject
  );
  const { purchaseRequest } = poObject;
  delete poObject.purchaseRequest;
  purchaseRequest.purchaseOrder = poObject;
  store.commit(
    `${PURCHASE_REQUEST_MODULE}/${SET_REQUEST_DETAILS}`,
    purchaseRequest,
    { root: true }
  );
};
