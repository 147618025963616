<!-- eslint-disable vue/prop-name-casing -->
<template>
  <div class="approval-workflow-listing">
    <h3 class="section-heading">
      {{ heading }}
    </h3>
    <p v-if="!isUserOnlyAuditor" class="text-sm mb-4">
      <i18n-t keypath="workflow.rearrangeHelptext" tag="p">
        <DotsVerticalIcon class="h-4 w-4 text-primary inline" />
      </i18n-t>
    </p>
    <div v-if="loading" class="custom-card">
      <ListLoader :columns="7" :rows="5" />
    </div>

    <div v-else class="border-t border-gray-100">
      <div v-if="items.length">
        <Sortable
          :disabled="isUserOnlyAuditor"
          :list="items"
          item-key="id"
          tag="div"
          :options="dragOptions"
          @end="updateOrder"
        >
          <template #item="{ element }">
            <div class="custom-card no-shadow mb-4 group">
              <div class="custom-card-header">
                <h3
                  class="text-base font-semibold flex items-center grabbable handle"
                >
                  <DotsVerticalIcon
                    v-if="!isUserOnlyAuditor"
                    class="h-4 w-4 text-white mr-1 -translate-y-0.5"
                  />
                  {{ element.title }}
                </h3>
                <div v-if="!isUserOnlyAuditor" class="text-sm inline-flex mr-4">
                  <PencilAltIcon
                    class="w-5 h-5 mx-2 text-white cursor-pointer sm:opacity-0 group-hover:opacity-100"
                    @click="() => setUpEdit(element.id)"
                  />
                  <TrashIcon
                    class="h-5 w-5 text-white cursor-pointer sm:opacity-0 group-hover:opacity-100"
                    @click="
                      () => {
                        setWorkflowToDelete(element);
                      }
                    "
                  />
                </div>
              </div>
              <div class="cards-holder">
                <WorkflowCard
                  :items="element"
                  :workflow-items="workflowItems"
                  :flow-type="flowType"
                />
              </div>
            </div>
          </template>
        </Sortable>
      </div>
      <div v-else class="text-center">
        <p class="text-gray-400 mt-10 mb-5">Nothing here yet</p>
      </div>
    </div>
    <ConfirmDialog
      v-if="workflowToDelete"
      :text="`Are you sure you want to delete workflow ( ${workflowToDelete.title} )?`"
      title="Delete Workflow"
      :show-modal="showDeleteConfirmModal"
      :close-modal="
        () => {
          showDeleteConfirmModal = false;
        }
      "
      :item-to-confirm="workflowToDelete.name"
      :confirm-modal="onWorkflowDeleteConfirm"
    />
  </div>
</template>

<script>
import { workflowListingText } from "@/utils/helpers/workflowHelper.js";
import { ConfirmDialog, ListLoader } from "@/components/shared/index";
import { Sortable } from "sortablejs-vue3";
import {
  DotsVerticalIcon,
  TrashIcon,
  PencilAltIcon,
} from "@heroicons/vue/solid";
import WorkflowCard from "@/components/CompanyManagement/ApprovalWorkflow/WorkflowCard.vue";

export default {
  components: {
    ListLoader,
    DotsVerticalIcon,
    TrashIcon,
    PencilAltIcon,
    ConfirmDialog,
    WorkflowCard,
    Sortable,
  },
  props: {
    flowType: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    deleteWorkflow: {
      type: Function,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    updateWorkflowsOrdering: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      workflowToDelete: null,
      showDeleteConfirmModal: false,
      drag: false,
      items: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        handle: ".handle",
      };
    },
  },

  watch: {
    list: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.items = newVal;
        }
      },
    },
  },
  methods: {
    moveItem(array, from, to) {
      const item = array[from];
      array.splice(from, 1);
      array.splice(to, 0, item);
      return array;
    },
    updateOrder(event) {
      if (this.isUserOnlyAuditor) {
        this.showNotification(this.$t("global.valuesNotRetained"), "info");
        return;
      }
      const { oldIndex, newIndex } = event;
      this.items = this.moveItem(this.items, oldIndex, newIndex);
      this.updateWorkflowsOrdering(this.items);
    },
    setWorkflowToDelete(workflow) {
      this.workflowToDelete = workflow;
      this.showDeleteConfirmModal = true;
    },
    onWorkflowDeleteConfirm() {
      this.deleteWorkflow(this.workflowToDelete.id);
      this.showDeleteConfirmModal = false;
    },

    sort() {
      this.items = this.items.sort((a, b) => a.order - b.order);
    },
    workflowItems(object) {
      return workflowListingText(object, this.$i18n.t);
    },
    setUpEdit(id) {
      this.$router.push({
        path: `approval-workflow-editor/${id}`,
        query: { flowType: this.flowType },
      });
    },
  },
};
</script>
<style scope>
.col-holder {
  @apply px-2 flex items-center;
}

.col-inner {
  @apply flex-auto flex flex-col p-4;
}

.col-title {
  @apply text-center text-gray-700 font-semibold mb-4 underline;
}

.custom-card-header {
  @apply border !border-primary !py-3;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
