import { ocrConfidenceCalc } from "@/utils/utility_methods.js";
import { isStringADate } from "@/utils/utility_methods";
export default {
  data() {
    return {
      canvas: null,
      ctx: null,
      drawCanvas: null,
      canvasArray: [],
      ctxArray: [],
      drawCanvasArray: [],
    };
  },
  watch: {
    ocrData: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.drawCanvas) {
          this.prepareCanvasArray();
        }
      },
    },
  },
  methods: {
    drawRectangle(
      sourceX1,
      sourceX2,
      sourceY1,
      sourceY2,
      sourceW,
      sourceH,
      page
    ) {
      // calculating the result in dependency with the current height and width
      const resultX = (sourceX1 / sourceW) * this.drawCanvasArray[page].width;
      const resultY = (sourceY1 / sourceH) * this.drawCanvasArray[page].height;
      const resultW =
        ((sourceX2 - sourceX1) / sourceW) * this.drawCanvasArray[page].width;
      const resultH =
        ((sourceY2 - sourceY1) / sourceH) * this.drawCanvasArray[page].height;

      // Adding a little padding around to make it more visible
      this.ctxArray[page].strokeRect(
        resultX - 4,
        resultY - 4,
        resultW + 8,
        resultH + 8
      );
      this.ctxArray[page].fillRect(
        resultX - 3,
        resultY - 3,
        resultW + 6,
        resultH + 6
      );
    },
    clearCanvas() {
      if (this.ctxArray) {
        this.ctxArray.forEach((ctx, index) => {
          if (this.canvasArray[index]) {
            ctx.clearRect(
              0,
              0,
              this.canvasArray[index].width,
              this.canvasArray[index].height
            );
          }
        });
      }
    },

    drawRectangleForField(ocrDetails) {
      if (!ocrDetails) return;

      if (ocrDetails.bboxRefs) {
        this.prepareCanvasArray();
        this.drawField(ocrDetails.bboxRefs);
      }
    },
    updateValueFromOCR(field, value) {
      const fieldValue = isStringADate(value)
        ? new Date(value).toISOString()
        : value;
      this.invoice[field] = fieldValue;
    },
    drawField(boxes) {
      let pageNum = null;
      boxes.forEach((box) => {
        const coordinates = this.compileCoordinatesForBox(box);
        const { x1, x2, y1, y2, width, height } = coordinates;
        pageNum = box.pageNum - 1;
        if (this.drawCanvasArray[pageNum] && this.ctxArray[pageNum]) {
          this.drawRectangle(x1, x2, y1, y2, width, height, pageNum);
        }
      });
    },
    getBoxPage(box) {
      return box.pageNum - 1;
    },
    getBoxId(box) {
      return box.bboxId;
    },
    getOcrHeight(page) {
      return this.ocrResult.pages[page].height;
    },
    getOcrWidth(page) {
      return this.ocrResult.pages[page].width;
    },
    compileCoordinatesForBox(box) {
      const boxPage = this.getBoxPage(box);
      const boxId = this.getBoxId(box);
      const height = this.getOcrHeight(boxPage);
      const width = this.getOcrWidth(boxPage);
      const { x1, x2, y1, y2 } =
        this.ocrResult.pages[boxPage].bboxes[boxId - 1];
      return {
        x1,
        x2,
        y1,
        y2,
        height,
        width,
      };
    },
    prepareCanvasArray() {
      const testIfCanvasIsStillThere = document.getElementById("tempDiv0");
      if (!testIfCanvasIsStillThere) {
        this.implementCanvas();
      }

      this.drawCanvasArray.forEach((drawCanvas, index) => {
        if (drawCanvas && this.canvasArray[index]) {
          drawCanvas.height = this.canvasArray[index].style.height.slice(0, -2);
          drawCanvas.width = this.canvasArray[index].style.width.slice(0, -2);

          this.ctxArray[index] = drawCanvas.getContext("2d");
          this.ctxArray[index].lineJoin = "round";
          this.ctxArray[index].fillStyle = "rgba(158 , 71, 112, 0.3)";
          this.ctxArray[index].lineWidth = 2;
          this.ctxArray[index].strokeStyle = "#9e4770";
        }
      });
    },
    implementCanvas() {
      this.canvasArray = document.getElementsByTagName("canvas");
      this.canvasArray = Array.prototype.slice.call(this.canvasArray);
      this.canvasArray.forEach((canvas, index) => {
        canvas.setAttribute("id", "origCanvas" + index);
        const tempDiv = document.createElement("div");

        tempDiv.setAttribute("id", "tempDiv" + index);
        canvas.insertAdjacentElement("afterend", tempDiv);

        const newCanvas = document.createElement("canvas");
        newCanvas.setAttribute("id", "drawCanvas" + index);
        newCanvas.style.position = "absolute";
        newCanvas.style.top = "0px";
        newCanvas.style.bottom = "0px";
        newCanvas.style.right = "0px";
        newCanvas.style.left = "0px";
        canvas.insertAdjacentElement("afterend", newCanvas);
      });

      this.canvasArray.forEach((canvas, index) => {
        this.drawCanvasArray[index] = document.getElementById(
          "drawCanvas" + index
        );
      });
    },
    applyValues(confidence) {
      const range = ocrConfidenceCalc(confidence);
      const fields = Object.keys(this.ocrFields);
      fields.forEach((field) => {
        const data = this.ocrFields[field].data;
        if (
          data &&
          data.confidence != null &&
          data.confidence * 100 >= range.start &&
          data.confidence * 100 <= range.end
        ) {
          if (field === "currency") {
            this.onCurrencyChange(data.value);
          }
          const dateFields = ["invoiceDate", "dueDate", "serviceDate"];
          if (dateFields.includes(field)) {
            const parsedDate = new Date(data.value).toISOString();
            this.invoice[field] = parsedDate;
            return;
          }
          this.invoice[field] = data.value;
        }
      });
    },
  },
};
