<template>
  <div
    class="list-button cursor-pointer text-gray-300 flex-shrink-0"
    :class="[
      `hover:${hoverColor}`,
      `w-${size} h-${size}`,
      border
        ? 'group-hover:border group-hover:border-gray-200 hover:bg-gray-200 bg-gray-50'
        : 'border-none'
    ]"
    @click="onClickHandler"
  >
    <slot name="icon">
      <PencilAltIcon class="h-5 w-5 text-gray-300" />
    </slot>
  </div>
</template>

<script>
import { PencilAltIcon } from "@heroicons/vue/solid";
export default {
  components: {
    PencilAltIcon
  },
  props: {
    onClickHandler: {
      type: Function,
      default: () => {}
    },
    hoverDanger: {
      type: Boolean,
      default: false
    },
    hoverEdit: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "8"
    }
  },
  computed: {
    hoverColor() {
      if (this.hoverDanger) {
        return "text-hivebuy-red";
      }
      if (this.hoverEdit) {
        return "text-hivebuy-green";
      }
      return "text-primary";
    }
  }
};
</script>

<style>
.list-button {
  @apply rounded-full flex items-center align-middle justify-center;
}
</style>
