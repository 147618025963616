import * as ProjectTypes from "@/store/Project/types";
import {
  createProject,
  deleteProject,
  getProject,
  getProjects,
  updateProject,
  getProjectsList,
} from "@/utils/services";
import { isObjectEmpty } from "@/utils/utility_methods";

export default {
  [ProjectTypes.GET_PROJECTS]: ({ commit, state }, paginationParams) => {
    return new Promise((resolve, reject) => {
      const params = isObjectEmpty(paginationParams)
        ? state.projectsListFilters
        : paginationParams;
      getProjects(commit, resolve, reject, params);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProjectTypes.GET_PROJECT]: ({ commit }, projectId) => {
    return new Promise((resolve, reject) => {
      getProject(resolve, reject, projectId);
    });
  },

  [ProjectTypes.GET_PROJECTS_LIST]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getProjectsList(commit, resolve, reject, params);
    });
  },

  [ProjectTypes.CREATE_PROJECT]: ({ commit }, projectId) => {
    return new Promise((resolve, reject) => {
      createProject(commit, resolve, reject, projectId);
    });
  },

  [ProjectTypes.UPDATE_PROJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updateProject(commit, resolve, reject, payload);
    });
  },

  [ProjectTypes.DELETE_PROJECT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      deleteProject(commit, resolve, reject, payload);
    });
  },
};
