import { patch, get } from "../api";
import {
  COMPANY_API_URL,
  ADDRESSES_API_URL,
  QUICK_LINKS,
  COMPANY_COST_SAVING_URL,
  SEARCH_URL,
  TAX_CODES_API_URL,
} from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";

export const getCompany = (commit, resolve, reject) => {
  get(COMPANY_API_URL)
    .then((response) => {
      const company = response.data[0];
      commit(CompanyManagementTypes.SET_COMPANY, company);
      resolve(company);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateCompany = (commit, payload, resolve, reject) => {
  patch(`${COMPANY_API_URL}`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getQuickLinks = (commit, resolve, reject) => {
  get(QUICK_LINKS)
    .then((response) => {
      commit(CompanyManagementTypes.SET_QUICK_LINKS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getCompanyAddress = (commit, resolve, reject) => {
  return get(ADDRESSES_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY_ADDRESSES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getCompanyCostSaving = (commit, resolve, reject) => {
  return get(COMPANY_COST_SAVING_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COST_SAVING, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const setCompanyCostSaving = (payload, commit, resolve, reject) => {
  const id = payload.id;
  delete payload.id;
  return patch(`${COMPANY_COST_SAVING_URL}${id}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COST_SAVING_GOALS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getSearchData = (params, resolve, reject) => {
  const url = `${SEARCH_URL}?modules=${params.modules}&query=${params.query}`;
  return get(url)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getTaxCodes = (commit, resolve, reject) => {
  return get(TAX_CODES_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_TAX_CODES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
