<template>
  <button
    :id="id"
    :class="btnClasses"
    :disabled="disabled"
    type="button"
    v-on="{ click: !loading ? onClickHandler : null }"
  >
    <span
      v-if="hasIconSlot && !loading"
      :class="[
        'h-5 w-5 flex items-center',
        { 'mr-0': circle, 'mr-2': !circle },
      ]"
    >
      <slot name="icon" />
    </span>
    <span v-if="loading" class="mr-2">
      <svg
        class="animate-spin h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    colorOutline: {
      type: Boolean,
      default: false,
    },
    sizeClass: {
      type: String,
      default: "md",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClickHandler: {
      type: Function,
      required: true,
    },
    applyStylingViaClass: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    btnClasses() {
      if (!this.applyStylingViaClass) return {};

      const classes = {
        btn: true,
        "btn-rounded": this.rounded,
        "btn-circle": this.circle,
        "btn-full": this.full,
        "btn-disabled": this.disabled,
        "btn-loading": this.loading,
      };

      classes[`btn-${this.color}`] = true;
      classes[`btn-${this.color}-outline`] = this.colorOutline;
      classes[`btn-${this.sizeClass}`] = true;

      return classes;
    },
    hasIconSlot() {
      return Boolean(this.$slots.icon && this.$slots.icon().length);
    },
  },
};
</script>

<style scoped>
.btn {
  @apply bg-white text-gray-700  hover:bg-gray-50 font-medium py-2 px-4 rounded focus:outline-none border border-gray-200  inline-flex items-center justify-center content-center transition duration-500 ease-in-out tracking-wide;
}
.btn-xs {
  @apply text-xs;
}
.btn-sm {
  @apply text-sm py-1.5;
}
.btn-lg {
  @apply text-lg;
}
.btn-rounded {
  @apply rounded-full;
}
.btn-full {
  @apply w-full;
}
.btn-circle {
  @apply rounded-full p-0 h-7 w-7;
}
.btn-primary {
  @apply bg-primary hover:bg-primarydark text-white;
}
.btn-transparent {
  @apply bg-transparent  text-black border-none hover:bg-slate-200;
}
.btn-transparent-danger {
  @apply bg-transparent  text-black border-none hover:border-hivebuy-red;
}
.btn-danger {
  @apply bg-hivebuy-red hover:bg-hivebuy-red hover:bg-opacity-80 text-white border-hivebuy-red;
}
.btn-gray {
  @apply bg-white hover:bg-gray-50 text-gray-700;
}
.btn-primary-gray {
  @apply bg-white hover:bg-primary hover:text-white border border-gray-300;
}
.btn-cancel-gray {
  @apply bg-white hover:text-hivebuy-red border hover:border-hivebuy-red hover:bg-white;
}
.btn-light-gray {
  @apply bg-gray-100 hover:bg-gray-200 text-gray-900;
}
.btn-blue {
  @apply bg-blue-500 hover:bg-blue-600 text-white border-gray-100;
}

.btn-success {
  @apply bg-hivebuy-green hover:bg-[#40A398] active:bg-[#32847B] text-white border-hivebuy-green;
}

.btn-primary-outline {
  @apply bg-white border-primary hover:bg-primary text-primary hover:text-white;
}
.btn-danger-outline {
  @apply bg-white border-hivebuy-red hover:bg-hivebuy-red text-hivebuy-red hover:text-white;
}
.btn-disabled {
  @apply opacity-50 cursor-not-allowed bg-gray-500 hover:bg-gray-500 text-white;
}
.btn-loading {
  @apply cursor-not-allowed;
}
.btn-dark-gray {
  @apply bg-gray-100 hover:bg-gray-200 text-gray-900;
}
</style>
