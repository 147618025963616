<template>
  <ul role="list">
    <li class="">
      <div v-if="purchaseOrder" class="flex items-center">
        <div class="flex items-center mr-10">
          <Tooltip :arrow="true" open-delay="100" :hover="true">
            <StatusTag
              status="A"
              type="order"
              only-icon
              :show-tool-tip="false"
            />
            <template #content>
              <div class="p-2">
                <p class="text-gray-700 font-semibold text-center">
                  {{ $t("global.purchaseRequest") }}
                  <span class="lowercase">
                    {{ $t("purchaseRequest.summary.statusApproved") }}</span
                  >
                </p>
                <p class="text-xs text-gray-500 font-normal">
                  @ {{ formattedDate(decidedAt) }} -
                  {{ formattedTime(decidedAt) }}
                </p>
              </div></template
            >
          </Tooltip>
        </div>
        <div
          class="rounded-md col-span-1 flex boxItem bg-white rounded-lg shadow"
        >
          <div
            :class="[
              'flex-shrink-0 flex items-center justify-center px-3 text-white text-sm font-medium rounded-l-md border-gray-300 border-t border-b border-l',
              `${PURCHASE_ORDER_STATUSES_STYLES[purchaseOrder.status]}`,
            ]"
          >
            <component
              :is="orderDetail.icon"
              class="text-white h-5 w-5"
              aria-hidden="true"
            />
          </div>
          <div
            class="flex-1 flex items-center justify-between rounded-r-md border-gray-300 border-t border-r border-b"
          >
            <div class="flex-1 px-3 py-2 text-sm truncate">
              <a class="text-gray-900 font-medium">{{ orderDetail.text }}</a>
              <p class="text-gray-500 text-xs">
                {{ orderDetail.date ? formattedDate(orderDetail.date) : "" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="col-span-1 flex rounded-lg shadow">
        <div
          :class="[
            'flex-shrink-0 flex items-center justify-center px-3 text-white text-sm font-medium rounded-l-md border-gray-300 border-t border-l border-b',
            `${PURCHASE_REQUEST_STATUSES_STYLES[status]}`,
          ]"
        >
          <component
            :is="requestDetails.icon"
            class="text-white h-6 w-6"
            aria-hidden="true"
          />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 rounded-r-md bg-white"
        >
          <div class="flex-1 px-3 py-2 text-sm truncate">
            <a class="text-gray-900 font-medium">{{ requestDetails.text }}</a>
            <p v-if="requestDetails.date" class="text-gray-500 text-xs">
              {{ formattedDate(requestDetails.date) }}
            </p>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { formattedTime } from "@/utils/utility_methods";
import {
  PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDER_STATUSES_STYLES,
  PURCHASE_REQUEST_ITEM_STATUSES,
  PURCHASE_REQUEST_STATUSES_STYLES,
  PURCHASE_ORDER_STATUS_MAPPING,
} from "@/utils/constants";
import { HivebuyTooltip as Tooltip } from "@/components/shared/index";
import OrderSVG from "@/assets/images/OrderSVG.vue";
import DeliverSVG from "@/assets/images/DeliverSVG.vue";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import {
  ThumbUpIcon,
  ClockIcon,
  ThumbDownIcon,
  CheckCircleIcon,
  XCircleIcon,
  BanIcon,
  CollectionIcon,
} from "@heroicons/vue/solid";
import StatusTag from "@/components/shared/StatusTag";
import { HivebuyLoader } from "@/components/shared";
export default {
  components: {
    ThumbUpIcon,
    ClockIcon,
    ThumbDownIcon,
    CheckCircleIcon,
    OrderSVG,
    DeliverSVG,
    Tooltip,
    InvoiceSVG,
    StatusTag,
    HivebuyLoader,
    CollectionIcon,
  },
  props: {
    status: {
      type: String,
      default: null,
    },
    purchaseOrder: {
      type: Object,
      default: null,
    },
    decidedAt: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    requestDetails() {
      switch (this.status) {
        case "L":
          return {
            icon: HivebuyLoader,
            text: this.$t("purchaseRequest.summary.calculatingApprovals"),
          };
        case "A":
          return {
            icon: ThumbUpIcon,
            text: this.$t("purchaseRequest.summary.statusApproved"),
            date: this.decidedAt,
          };
        case "P":
          return {
            icon: ClockIcon,
            text: this.$t("purchaseRequest.summary.statusPending"),
          };
        case "R":
          return {
            icon: ThumbDownIcon,
            text: this.$t("purchaseRequest.summary.statusRejected"),
            date: this.decidedAt,
          };
        case "C":
          return {
            icon: BanIcon,
            text: this.$t("purchaseRequest.summary.statusCancelled"),
            date: this.decidedAt,
          };
        default:
          return false;
      }
    },
    orderDetail() {
      switch (this.purchaseOrder.status) {
        case "A":
          return {
            icon: OrderSVG,
            text: this.$t("purchaseRequest.summary.statusOrdered"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.ORDERED:
          return {
            icon: OrderSVG,
            text: this.$t("purchaseRequest.summary.statusOrdered"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.COMPLETE:
          return {
            icon: CheckCircleIcon,
            text: this.$t("purchaseRequest.summary.statusComplete"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.DELIVERED:
          return {
            icon: DeliverSVG,
            text: this.$t("purchaseRequest.summary.statusDelivered"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.INVOICED:
          return {
            icon: InvoiceSVG,
            text: this.$t("purchaseRequest.summary.statusInvoiced"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.PENDING:
          return {
            icon: ClockIcon,
            text: this.$t("purchaseRequest.summary.statusPending"),
            date: this.decidedAt,
          };
        case PURCHASE_ORDER_STATUS_MAPPING.SENT:
          return {
            icon: ClockIcon,
            text: this.$t("purchaseRequest.summary.orderSent"),
          };
        case PURCHASE_ORDER_STATUS_MAPPING.REJECTED:
          return {
            icon: XCircleIcon,
            text: this.$t("purchaseRequest.summary.orderRejected"),
            date: this.decidedAt,
          };
        case PURCHASE_ORDER_STATUS_MAPPING.CANCELED:
          return {
            icon: BanIcon,
            text: this.$t("purchaseRequest.summary.orderCanceled"),
            date: this.purchaseOrder.statusChangedAt
              ? this.purchaseOrder.statusChangedAt
              : null,
          };
        case PURCHASE_ORDER_STATUS_MAPPING.DELIVERY:
          return {
            icon: DeliverSVG,
            text: this.$t("purchaseRequest.summary.inDelivery"),
            date: this.purchaseOrder.statusChangedAt
              ? this.purchaseOrder.statusChangedAt
              : null,
          };
        case PURCHASE_ORDER_STATUS_MAPPING["PARTIAL DELIVERED"]:
          return {
            icon: DeliverSVG,
            text: this.$t(
              "purchaseRequest.summary.purchaseOrderStatus.partialDelivered"
            ),
            date: this.purchaseOrder.statusChangedAt || null,
          };
        case PURCHASE_ORDER_STATUS_MAPPING.ON_HOLD:
          return {
            icon: CollectionIcon,
            text: this.$t("purchaseRequest.summary.onHold"),
            date: this.decidedAt,
          };
        default:
          return false;
      }
    },
  },
  created() {
    this.PURCHASE_ORDER_STATUSES = PURCHASE_ORDER_STATUSES;
    this.PURCHASE_ORDER_STATUSES_STYLES = PURCHASE_ORDER_STATUSES_STYLES;
    this.PURCHASE_REQUEST_ITEM_STATUSES = PURCHASE_REQUEST_ITEM_STATUSES;
    this.PURCHASE_REQUEST_STATUSES_STYLES = PURCHASE_REQUEST_STATUSES_STYLES;
  },
  methods: {
    isRequestComplete() {
      const { ordered, received, invoiceFile } = this.purchaseOrder;
      if (ordered && received && invoiceFile) {
        return true;
      } else return false;
    },
    formattedTime,
  },
};
</script>

<style scoped>
div.boxItem {
  position: relative;
}
.boxItem:before,
.boxItem:after {
  content: "";
  width: 2.5em; /* size of your margin */
  border-bottom: 1px dashed;
  @apply border-gray-400;
  position: absolute;
  top: 50%;
}
:after {
  left: 100%;
}
:before {
  right: 100%;
}
.boxItem:first-of-type:before,
.boxItem:last-of-type:after {
  display: none;
}
</style>
