<template>
  <div>
    <h3 class="text-sm font-medium leading-6 text-primary text-center">
      {{ $t("global.requestedFor") }}
    </h3>
    <HivebuyOverlay
      ref="inlineRequestedFor"
      :title="$t('global.supplier')"
      :subtitle="$t('companyManagement.companySupplier.heading')"
      :error-text="error"
      :editable="editAllowed"
    >
      <template #header>
        <EntitySections :entity="requestedFor" :show-main-text="true" />
      </template>
      <template #subtitle>
        <EntitySections :entity="requestedFor" :show-secondary-text="true" />
      </template>

      <template #icon>
        <EntitySections :entity="requestedFor" :show-icon="true" />
      </template>
      <template #panelHeader>
        <div class="flex items-center justify-between w-full">
          <p>
            {{ $t("purchaseRequest.requestedFor.whoWillUseItHeader") }}
          </p>
          <Button
            :on-click-handler="() => $refs.inlineRequestedFor.toggle()"
            :loading="loading"
            :color="'primary'"
            :size-class="'sm'"
            >{{ $t("buttons.close") }}</Button
          >
        </div>
      </template>
      <template #panelContent>
        <SelectRequestedFor
          v-model="requestedFor"
          is-mandatory
          :show-header="false"
          :show-info-text="true"
        />
      </template>
      <template #panelFooter>
        {{ $t("purchaseRequest.detail.requestedFor") }}
      </template>
    </HivebuyOverlay>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { SelectRequestedFor, Button } from "@/components/shared";
import { HivebuyOverlay } from "design-buy";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import EntitySections from "@/components/shared/EntitySections.vue";
export default {
  name: "RequestedFor",
  components: {
    SelectRequestedFor,
    HivebuyOverlay,
    EntitySections,
    Button,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    editAllowed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["newRequestItem"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    requestedFor: {
      get() {
        return {
          id: this.modelValue.requestedForId,
          type: this.modelValue.requestedForType,
          user: this.modelValue.user,
          department: this.modelValue.department,
          project: this.modelValue.project,
        };
      },
      set(val) {
        const payload = {
          requestedForId: val.id,
          requestedForType: val.type,
          user: val.user,
          department: val.department,
          project: val.project,
        };
        this.$emit("update:modelValue", payload);
      },
    },
    isValuePresent() {
      const { type, id } = this.requestedFor;
      return !!(type && id);
    },
    shouldSelectDepartmentAsRequestedFor() {
      const { roles, departments } = this.user;
      return (
        this.companySetting("ONLY_DEPARTMENTS_IN_REQUESTED_FOR")?.active &&
        (!roles.length || (roles.length === 1 && roles.includes("AP"))) &&
        departments.length
      );
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal) {
        if (!newVal?.requestedForId) {
          const { id, departments } = this.user;
          this.requestedFor = Object.assign(
            {},
            {
              id: this.shouldSelectDepartmentAsRequestedFor
                ? departments[0].id
                : id,
              department: departments[0]?.id,
              type: this.shouldSelectDepartmentAsRequestedFor
                ? "department"
                : "user",
            }
          );
        }
      },
    },
  },
};
</script>
<style scoped>
.requested-for :global(.content-wrapper) {
  overflow: visible !important;
}
.requested-for :global(.p-overlaypanel) {
  overflow: visible !important;
}
</style>
