<template>
  <div class="project-header">
    <div class="flex items-center place-content-center content-center">
      <router-link to="/projects">
        <h1 class="page-title flex hover:text-primary">
          <ChevronLeftIcon class="w-6 h-6 cursor-pointer translate-y-0.5" />
          {{ project?.name || $t("project.details") }}
        </h1>
      </router-link>
    </div>
  </div>
  <section class="custom-card mb-4 overflow-visible no-shadow">
    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
      <div class="flex-1">
        <p class="label">{{ $t("project.projectOwner") }}</p>

        <div>
          <MemberSelector
            :value="project.approvers"
            :on-value-change="(value) => (project.approvers = value)"
            :members-list="usersList()"
            :placeholder="
              $t('global.prepositions.select', {
                object: $t('project.projectOwner'),
              })
            "
            :can-deselect="false"
          />
          <FieldError
            v-if="v$.project.approvers.$error"
            :text="
              $t('global.prepositions.select', {
                object: $t('project.projectOwner'),
              })
            "
          />
        </div>
      </div>
      <div class="flex-1">
        <div class="relative">
          <span class="label pr-6">
            {{ $t("project.budgetOwner") }}
          </span>
        </div>

        <div>
          <MemberSelector
            :value="project.budgetOwners"
            :on-value-change="(value) => (project.budgetOwners = value)"
            :members-list="usersList()"
            :placeholder="
              $t('global.prepositions.select', {
                object: $t('project.budgetOwner'),
              })
            "
            :can-deselect="false"
          >
          </MemberSelector>
          <FieldError
            v-if="v$.project.budgetOwners.$error"
            :text="
              $t('global.prepositions.select', {
                object: $t('project.budgetOwner'),
              })
            "
          />
        </div>
      </div>

      <div class="flex-1">
        <div class="relative">
          <div class="label pr-6">
            <span class="mr-1">
              {{ $t("project.approvalFlowType") }}
            </span>
            <Tip class="absolute" theme="tooltip" help>
              <template #header>{{
                $t("project.approvalFlowTypesTooltip.header")
              }}</template>
              <template #content>
                <div
                  v-for="type in approvalFlowTypes"
                  :key="type"
                  class="grid grid-cols-2 border-b border-gray-100 items-center align-middle pb-2 normal-case"
                >
                  <p class="text-sm font-medium text-white text-left">
                    <span
                      :class="`px-2 py-0.5 rounded-xl text-xs 2xl:text-sm font-medium mr-2 ${approvalFlowTypeClass(
                        type.id
                      )}`"
                    >
                      {{ type.name }}
                    </span>
                  </p>
                  <p class="text-sm text-gray-500 inline-flex py-0.5">
                    {{
                      $t(
                        `project.approvalFlowTypesTooltip.${snakeToCamel(
                          type.id.toLowerCase()
                        )}`
                      )
                    }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <div>
          <Multiselect
            v-model="project.projectApprovalFlowType"
            :mode="'single'"
            :options="approvalFlowTypes"
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            placeholder="Please select a type"
            :can-deselect="false"
          />
          <FieldError
            v-if="v$.project.projectApprovalFlowType.$error"
            :text="'Please select a approval workflow type'"
          />
        </div>
      </div>
    </div>
  </section>

  <div class="input-holder">
    <div class="custom-card flex flex-col gap-x-4 mb-4 no-shadow">
      <div class="row flex flex-col">
        <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <div class="text-input mb-4">
            <Input
              id="name"
              :error-text="error(v$.project.name)"
              name="name"
              :value="project.name"
              type="text"
              :placeholder="'Name'"
              :on-value-change="onAttributeChange"
              :label="$t('project.name')"
              is-mandatory
            />
          </div>
          <div class="text-input mb-4">
            <Input
              id="project_number"
              name="projectNumber"
              :value="project.projectNumber"
              type="text"
              :placeholder="$t('project.number')"
              :on-value-change="onAttributeChange"
              :label="$t('project.number')"
            />
          </div>
          <div class="text-input mb-4">
            <Input
              id="cost_center"
              name="costCenter"
              :value="project.costCenter"
              type="text"
              :placeholder="$t('project.costCenter')"
              :on-value-change="onAttributeChange"
              :label="$t('project.costCenter')"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-1 gap-4">
          <div class="text-area mb-4 col-span-3">
            <TextArea
              :name="'description'"
              :placeholder="$t('global.description')"
              :value="project.description"
              :rows="3"
              :on-value-change="onAttributeChange"
              :label="$t('global.description')"
            />
          </div>
        </div>

        <div
          v-if="isFeatureAllowed('legalEntity')"
          class="grid grid-cols-1 lg:grid-cols-1 gap-4"
        >
          <div class="text-input mb-4">
            <p class="block text-sm font-medium text-gray-700">
              {{ $t("companyManagement.users.addUserModal.legalTendency") }}
            </p>
            <div class="mt-2">
              <Multiselect
                v-model="project.legalEntity"
                :searchable="true"
                :options="legalTendencyList"
                :placeholder="
                  $t('companyManagement.users.addUserModal.legalTendency')
                "
                :value-prop="'id'"
                :label="'name'"
                :track-by="'name'"
                :can-clear="false"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Budget Distribution Start -->
      <div v-if="projectToEdit">
        <div class="font-medium text-md my-6">
          {{ $t("purchaseRequest.summary.budget.details") }}
        </div>
        <div class="block xl:flex items-center justify-between mb-4">
          <div class="flex items-center xl:mb-0 mb-1">
            <span><CashIcon class="w-6 h-6 text-hivebuy-green mr-3" /> </span>
            <span class="text-sm font-medium">
              {{ $t("purchaseRequest.summary.budget.total") }}
              <i18n-n
                :value="parseFloat(projectToEdit?.budget.amount)"
                format="currency"
                :locale="currencyToLocale(projectToEdit?.budget.amountCurrency)"
              />
            </span>
          </div>
          <div class="block xl:flex items-center">
            <div class="font-medium text-sm flex items-center xl:mb-0 mb-1">
              <span class="text-hivebuy-red mx-1">
                {{ $t("purchaseRequest.summary.budget.used") }}
              </span>
              <span
                ><i18n-n
                  :value="parseFloat(projectToEdit?.budget.usedBudget)"
                  format="currency"
                  :locale="
                    currencyToLocale(projectToEdit?.budget.amountCurrency)
                  "
              /></span>
            </div>
            <div
              class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
            >
              <span class="text-yellow-500 mx-1">
                {{ $t("purchaseRequest.summary.budget.pending") }}</span
              >
              <span
                ><i18n-n
                  :value="parseFloat(projectToEdit?.budget.pendingBudget)"
                  format="currency"
                  :locale="
                    currencyToLocale(projectToEdit?.budget.amountCurrency)
                  "
              /></span>
            </div>
            <div
              class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
            >
              <span class="text-hivebuy-green mx-1">
                {{ $t("purchaseRequest.summary.budget.remaining") }}
              </span>
              <span
                ><i18n-n
                  :value="parseFloat(projectToEdit?.budget.remainingBudget)"
                  format="currency"
                  :locale="
                    currencyToLocale(projectToEdit?.budget.amountCurrency)
                  "
              /></span>
            </div>
          </div>
        </div>
        <div v-if="showBar && projectToEdit">
          <div
            class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
          >
            <div
              class="progress-bar progress-bar-approved"
              :style="{ width: `${calcBar().used}%` }"
            >
              <i18n-n
                :value="calcBar().used / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              class="progress-bar progress-bar-pending"
              :style="{ width: `${calcBar().pending}%` }"
            >
              <i18n-n
                :value="calcBar().pending / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              class="flex-grow text-center text-gray-600 text-xs font-medium"
            >
              <i18n-n
                :value="calcBar().remaining / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Budget Distribution End -->
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div class="currency">
          <CurrencyInput
            id="total_project_budget"
            v-model.lazy="project.totalProjectBudget"
            :error-text="budgetErrorText"
            is-mandatory
            placeholder="Project Budget"
            :label="'Budget'"
          />
        </div>
        <div>
          <p class="label mb-1">{{ $t("project.budgetDistribution") }}</p>
          <Multiselect
            v-model="budgetDistribution"
            :searchable="true"
            :options="budgetDistributionOptions()"
            :can-deselect="false"
          />
        </div>
        <div class="currency -m-2">
          <CurrencySelect
            v-model="project.totalProjectBudgetCurrency"
            :disabled="true"
            :label="$t('global.pricing.currency')"
          />
        </div>
      </div>
      <div
        v-if="project.participatingDepartments.length"
        class="department-holder"
      >
        <div
          v-for="(dept, index) in project.participatingDepartments"
          :key="index"
          class="new-department"
        >
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:grid-cols-3">
            <div class="department flex-1">
              <p class="label">{{ $t("global.department") }}</p>
              <DepartmentSelect
                v-model="dept.department"
                :label="'name'"
                :track-by="'name'"
                :can-clear="false"
                :department-list="departmentsList(index)"
              />
              <FieldError
                v-if="
                  v$.project.participatingDepartments?.$errors[0]?.$message[
                    index
                  ]?.length
                "
                :text="'Please select a department'"
              />
            </div>
            <div class="currency flex-1">
              <p class="label">Budget</p>
              <CurrencyInput
                id="budget_participation"
                v-model.lazy="dept.budgetParticipation"
                :disabled="true"
                placeholder="Department Budget"
              />
            </div>
            <div class="flex">
              <div class="budget-distribution flex-1">
                <p class="label">
                  {{
                    $t("project.budgetDistributionOptions.percentage (evenly)")
                  }}
                </p>
                <Input
                  id="budget_percentage"
                  name="budgetPercentage"
                  :value="dept.budgetPercentage"
                  type="text"
                  :placeholder="'Budget Percentage'"
                  :on-value-change="
                    (event) => onDepartmentAttributeChange(event, index)
                  "
                  :disabled="isBudgetDistributionEvenly"
                />
              </div>

              <div
                class="remove-department flex items-center self-start ml-2.5"
              >
                <TrashIcon
                  class="w-6 h-6 text-hivebuy-red cursor-pointer mt-10"
                  @click="() => removeDepartment(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        v-if="project.participatingDepartments?.length < departments?.length"
        type="button"
        class="flex w-56 text-primary font-semibold mt-6 mb-2"
        @click="addNewDepartment"
      >
        <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
        {{ $t("companyManagement.departments.addDepartment") }}
      </button>
    </div>

    <div class="flex justify-end">
      <Button
        :on-click-handler="() => onSave(project)"
        class="w-[300px]"
        :loading="loading"
      >
        {{ isEditMode ? $t("buttons.save") : $t("buttons.update") }}
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Input,
  MemberSelector,
  TextArea,
  Button,
  FieldError,
  CurrencyInput,
  DepartmentSelect,
} from "@/components/shared";
import { mapState, mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { decimal, helpers, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins";
import {
  PlusCircleIcon,
  TrashIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/solid";
import Multiselect from "@vueform/multiselect";
import { BUDGET_DISTRIBUTION_OPTIONS } from "@/utils/constants";
import {
  isObjectEmpty,
  currencyToLocale,
  snakeToCamel,
} from "@/utils/utility_methods";
import {
  calculateDepartmentBudgets,
  calculateDepartmentsBudgetTotal,
} from "@/utils/helpers/projectHelper";
import { CashIcon } from "@heroicons/vue/outline";
import CurrencySelect from "@/components/shared/CurrencySelect/index.vue";
export default {
  name: "ProjectView",
  components: {
    CashIcon,
    MemberSelector,
    Input,
    TextArea,
    PlusCircleIcon,
    Multiselect,
    TrashIcon,
    Button,
    FieldError,
    ChevronLeftIcon,
    CurrencyInput,
    DepartmentSelect,
    CurrencySelect,
  },
  mixins: [errorMixin],
  props: {
    projectToEdit: {
      type: Object,
      default: () => {},
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      project: {
        name: "",
        description: "",
        projectNumber: "",
        costCenter: "",
        projectApprovalFlowType: "EXTENDED",
        approvers: "",
        budgetOwners: "",
        includeDepartmentsHead: false,
        participatingDepartments: [],
        totalProjectBudget: 0,
        totalProjectBudgetCurrency: null,
      },
      budgetDistribution: "",
      loading: false,
      isEditable: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      departments: (state) => Object.values(state.departments),
      company: (state) => state.company,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "usersList",
      "legalTendencyList",
    ]),
    showEditMemberIcon() {
      return !this.isEditable;
    },
    isEditMode() {
      return isObjectEmpty(this.projectToEdit);
    },
    isBudgetDistributionCustom() {
      return this.budgetDistribution === "Custom";
    },
    isBudgetDistributionEvenly() {
      return this.budgetDistribution.includes("Percentage");
    },
    allDepartmentsBudgetTotal() {
      return this.project.participatingDepartments
        .map((item) => parseFloat(item.budgetParticipation))
        .reduce((prev, curr) => prev + curr, 0);
    },
    budgetErrorText() {
      const { totalProjectBudget } = this.v$.project;
      return totalProjectBudget.amountValid?.$invalid
        ? "The Budget of all departments exceeds the total budget for this project."
        : this.error(totalProjectBudget);
    },
    approvalFlowTypes() {
      return [
        {
          id: "SIMPLE",
          name: this.$t("project.approvalFlowTypeSimple"),
        },
        {
          id: "MEDIUM",
          name: this.$t("project.approvalFlowTypeMedium"),
        },
        {
          id: "EXTENDED",
          name: this.$t("project.approvalFlowTypeExtended"),
        },
        {
          id: "USE_PURCHASE_REQUEST_WORKFLOWS",
          name: this.$t("project.usePurchaseRequestWorkflows"),
        },
      ];
    },
    showBar() {
      return (
        parseInt(this.projectToEdit?.budget.remainingBudget) > 0 &&
        parseInt(this.projectToEdit?.budget.amount) > 0
      );
    },
  },
  watch: {
    projectToEdit: {
      immediate: true,
      handler(val) {
        if (!isObjectEmpty(val)) {
          this.project = Object.assign({}, val);
        }
      },
    },
  },
  created() {
    this.BUDGET_DISTRIBUTION_OPTIONS = BUDGET_DISTRIBUTION_OPTIONS;
    this.budgetDistribution = this.BUDGET_DISTRIBUTION_OPTIONS[0];
  },
  mounted() {
    this.$watch(
      (vm) => [vm.project.totalProjectBudget, vm.budgetDistribution],
      () => {
        const { participatingDepartments, totalProjectBudget } = this.project;
        this.project.participatingDepartments = calculateDepartmentBudgets(
          participatingDepartments,
          totalProjectBudget,
          this.isBudgetDistributionEvenly
        );
      },
      {
        deep: true,
      }
    );
    if (!this.project.totalProjectBudgetCurrency)
      this.project.totalProjectBudgetCurrency = this.company.currency;
  },
  methods: {
    currencyToLocale,

    calcBar() {
      const total = parseFloat(this.projectToEdit?.budget.amount);
      const used = parseFloat(this.projectToEdit?.budget.usedBudget);
      const pending = parseFloat(this.projectToEdit?.budget.pendingBudget);
      const remaining = parseFloat(this.projectToEdit?.budget.remainingBudget);
      const bar = {};
      bar.pending = (pending / total) * 100;
      bar.used = (used / total) * 100;
      bar.remaining = (remaining / total) * 100;
      return bar;
    },

    approvalFlowTypeClass(type) {
      return {
        SIMPLE: "bg-hivebuy-green",
        MEDIUM: "bg-hivebuy-yellow",
        EXTENDED: "bg-primary",
        USE_PURCHASE_REQUEST_WORKFLOWS: "bg-gray-400",
      }[type];
    },
    toggleLoading() {
      this.loading = !this.loading;
    },

    filteredDepartments(index) {
      const { participatingDepartments } = this.project;
      const selectedDepartments = participatingDepartments
        .filter((dept, ind) => ind !== index)
        .map((d) => d.department);

      return this.departments.filter(
        (dept) => !selectedDepartments.includes(dept.id)
      );
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.project[name] = value;
    },
    onDepartmentAttributeChange(event, index) {
      const { name, value } = event.target;
      this.project.participatingDepartments[index][name] = value;

      if (this.isBudgetDistributionCustom && name === "budgetPercentage") {
        const { participatingDepartments, totalProjectBudget } = this.project;
        this.project.participatingDepartments = calculateDepartmentsBudgetTotal(
          participatingDepartments,
          totalProjectBudget
        );
      }
    },
    addNewDepartment() {
      const department = {
        department: "",
        budgetParticipation: 0,
        budgetPercentage: 0,
      };
      this.project.participatingDepartments = [
        ...this.project.participatingDepartments,
        department,
      ];
      this.reCalculateDepartmentBudgets();
    },
    removeDepartment(index) {
      this.project.participatingDepartments =
        this.project.participatingDepartments.filter((dept, i) => i !== index);
      this.reCalculateDepartmentBudgets();
    },
    reCalculateDepartmentBudgets() {
      if (this.isBudgetDistributionEvenly) {
        const { participatingDepartments, totalProjectBudget } = this.project;
        this.project.participatingDepartments = calculateDepartmentBudgets(
          participatingDepartments,
          totalProjectBudget,
          true
        );
      }
    },
    budgetDistributionOptions() {
      return BUDGET_DISTRIBUTION_OPTIONS.map((option) => {
        return {
          label: this.$t(
            `project.budgetDistributionOptions.${option.toLowerCase()}`
          ),
          value: option,
        };
      });
    },
    departmentsList(index) {
      return this.filteredDepartments(index).map((dept) => dept.id);
    },
    snakeToCamel,
  },
  validations() {
    return {
      project: {
        approvers: { required },
        budgetOwners: { required },
        name: { required },
        projectApprovalFlowType: { required },
        totalProjectBudget: {
          decimal,
          amountValid: (value) => {
            return (
              !this.allDepartmentsBudgetTotal ||
              parseInt(this.allDepartmentsBudgetTotal) <= parseInt(value)
            );
          },
        },
        participatingDepartments: {
          $each: helpers.forEach({
            department: {
              required,
            },
          }),
        },
      },
    };
  },
};
</script>

<style scoped>
.project-header {
  @apply flex justify-between;
}

.new-department {
  @apply border-t border-gray-200 last:border-b px-5 py-8 -mx-5;
}

.department-holder {
  @apply mt-4;
}

.progress {
  height: 22px;
  overflow: hidden;
  @apply bg-hivebuy-green bg-opacity-10;
  @apply rounded-md ring-gray-400;

  -webkit-box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
}
.progress-bar {
  @apply font-normal;
  float: left;
  width: 0%;
  height: 100%;
  line-height: 16px;
  color: #fff;
  @apply text-xs;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar-pending {
  @apply bg-hivebuy-yellow;
}
.progress-bar-approved {
  @apply bg-hivebuy-red;
}
.progress-bar-remaining {
  @apply bg-gray-400;
}
</style>
