<template>
  <span v-if="isLoading" colspan="4" class="mt-6">
    <div class="loading flex justify-center items-center">
      <div class="h-14 w-14"><HivebuyLoader /></div>
    </div>
  </span>
  <div v-if="poInvoices && poInvoices.length" class="mt-6">
    <div class="flex items-center justify-center">
      <div
        class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12 relative"
      >
        <span
          class="absolute top-1 right-1 block h-4 w-4 rounded-full ring-1 ring-white bg-hivebuy-green p-0.5"
          ><CheckIcon class="text-white" />
        </span>
        <InvoiceSVG class="h-6 w-6 text-gray-500"></InvoiceSVG>
      </div>
    </div>

    <div v-if="allowInvoiceView">
      <InvoiceQuickView
        v-for="poInvoice in poInvoices"
        :key="poInvoice.id"
        :purchase-order-id="purchaseOrderId"
        :invoice="poInvoice"
      />
    </div>
    <div v-else class="text-center text-gray-400">
      {{ $t("invoice.validations.notAllowed") }}
    </div>
  </div>
</template>
<script>
import {
  INVOICE_MODULE,
  GET_INVOICES_WITHOUT_PAGINATION,
  GET_INVOICE,
} from "@/store/Invoice/types";
import { mapActions, mapState } from "vuex";
import InvoiceQuickView from "@/components/PurchaseRequest/SummarySections/invoiceQuickView.vue";
import { HivebuyLoader } from "@/components/shared/index";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import { CheckIcon } from "@heroicons/vue/solid";
import { AUTH_MODULE } from "@/store/Auth/types";
import { intersection } from "@/utils/utility_methods.js";
export default {
  components: { InvoiceQuickView, HivebuyLoader, InvoiceSVG, CheckIcon },
  props: {
    invoices: { type: Array, required: true },
    purchaseOrderId: { type: String, required: true },
    purchaseRequest: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
      poInvoices: [],
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    allowInvoiceView() {
      return (
        intersection(this.user?.roles, ["AC", "O"]).length ||
        this.purchaseRequest.requestedBy === this.user.id
      );
    },
  },
  watch: {
    invoices: {
      handler(newVal) {
        this.fetchInvoices(newVal);
      },
    },
  },
  mounted() {
    this.invoices.length && this.fetchInvoices(this.invoices);
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getInvoices: GET_INVOICES_WITHOUT_PAGINATION,
      getInvoice: GET_INVOICE,
    }),
    fetchInvoices(invoices) {
      this.isLoading = true;
      const promises = [];
      invoices.forEach((invoice) => {
        promises.push(this.getInvoice(invoice));
      });
      Promise.all(promises)
        .then((response) => {
          this.poInvoices = response;
        })
        .finally(() => (this.isLoading = false));

      // this.getInvoices({ id: invoices.join(","), expand: "files" })
      //   .then((response) => {
      //     this.poInvoices = response;
      //   })
      //   .finally(() => (this.isLoading = false));
    },
  },
};
</script>
