<template>
  <div
    class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6"
  >
    <UserWithAvatar
      :user="apiKey.user"
      show-tooltip
      :avatar-dimension="8"
      class="h-12 w-12 pt-2 pl-2 rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
    />
    <div class="text-sm font-medium leading-6 text-gray-900">
      {{ apiKey.name }}
      <p class="text-slate-600">
        {{ apiKey.description }}
      </p>
    </div>
    <Menu v-if="!apiKey.deactivatedAt" as="div" class="relative ml-auto">
      <MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
        <span class="sr-only">Open options</span>
        <DotsVerticalIcon class="h-5 w-5 text-gray-900" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
        >
          <MenuItem as="div" @click="() => onKeyDisable(apiKey)">
            <div class="flex content-center hover:text-red-400">
              <TrashIcon class="h-5 w-5" /> Disable
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
    <div class="flex justify-between gap-x-4 py-3">
      Status:
      <dd class="text-gray-70">
        <div class="mt-1 flex items-center gap-x-1.5">
          <div
            :class="[
              'flex-none rounded-full p-1',
              `${keyStatusDetails.class}-100`,
            ]"
          >
            <div
              :class="[
                'h-1.5 w-1.5 rounded-full',
                `${keyStatusDetails.class}-500`,
              ]"
            />
          </div>
          <p class="text-xs leading-5 text-gray-500">
            {{ keyStatusDetails.text }}
            <TimeDisplay
              v-if="apiKey.deactivatedAt"
              :time="apiKey.deactivatedAt"
            />
          </p>
        </div>
      </dd>
    </div>
    <div class="flex justify-between gap-x-4 py-3">
      <dd class="flex items-start gap-x-2">
        <div
          class="font-medium text-gray-500 overflow-x-hidden break-all text-sm"
        >
          Key Ends with:
          <span class="bg-slate-100 p-1 rounded-md text-gray-800">{{
            apiKey.maskedKey?.slice(-7)
          }}</span>
        </div>
      </dd>
    </div>
  </dl>
</template>

<script>
import { UserWithAvatar, TimeDisplay } from "@/components/shared";
import { TrashIcon, DotsVerticalIcon } from "@heroicons/vue/solid";
import { formatDateTime } from "@/utils/utility_methods.js";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

export default {
  components: {
    UserWithAvatar,
    TrashIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    TimeDisplay,
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
    onKeyDisable: {
      type: Function,
      required: true,
    },
  },
  computed: {
    keyStatusDetails() {
      return this.apiKey.deactivatedAt
        ? { class: "bg-red", text: this.$t("ApiKeys.deactivated") }
        : { class: "bg-green", text: this.$t("ApiKeys.activeStatus") };
    },
  },
  methods: {
    formatDateTime,
  },
};
</script>

<style scoped>
.title-wrapper {
  width: 90%;
}
.members-holder {
  @apply flex  flex-row items-center;
}
.members-list {
  @apply flex pl-2 flex-col overflow-hidden flex-1;
}
</style>
