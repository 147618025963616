import { mapGetters } from "vuex";
import { FEATURE_MODULE } from "@/store/Feature/types";
import { isProductionEnvironment } from "@/utils/utility_methods";
export default {
  methods: {
    ...mapGetters(FEATURE_MODULE, ["featureFlagValue", "featureFlagAmount"]),
    isProductionEnvironment,
    isFeatureAllowed(feature) {
      return this.featureFlagValue.call()(feature);
    },
    getfeatFlagAmount(feature) {
      return this.featureFlagAmount.call()(feature);
    },
  },
};
