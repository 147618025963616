import { sentenceCase } from "@/utils/utility_methods.js";
export default {
  methods: {
    error(validationObject, property = null) {
      return validationObject.$error
        ? this.$t(`errors.${validationObject.$errors[0].$validator}`, {
            entity: sentenceCase(
              property || validationObject.$errors[0].$property
            ),
          })
        : "";
    },

    genericError(validationObject = null) {
      return validationObject.$error ? this.$t("errors.genericError") : "";
    },
    fieldErrorMessage(validationObject, property) {
      const errorObj = validationObject.$errors.find((error) =>
        error.$propertyPath.includes(property)
      );
      return errorObj
        ? this.$t(`errors.${errorObj.$validator}`, {
            entity: sentenceCase(property || errorObj.$property),
          })
        : "";
    },
  },
};
