<template>
  <Panel
    ref="panel"
    unstyled
    toggleable
    :collapsed="false"
    :pt="{
      header: 'hb-panel-header',
      root: ['hb-panel-root', basicFieldErrors ? 'error' : ''],
      content: 'hb-panel-content',
      toggler: 'hb-panel-toggler',
    }"
  >
    <template #togglericon="{ collapsed }">
      <ChevronDownIcon
        class="text-primary h-6 w-6 transition-transform"
        :class="collapsed ? '' : 'rotate-180'"
      />
    </template>
    <template #header>
      <div
        className="self-stretch py-1 justify-start items-center gap-2.5 inline-flex cursor-pointer w-full"
        @click="toggle"
      >
        <div
          className="grow shrink basis-0 text-[#9e4770] text-[12.80px] font-semibold"
        >
          {{ $t("purchaseRequest.newRequest.basicInformation") }}
        </div>
      </div>
      <ExclamationIcon
        v-if="basicFieldErrors"
        class="h-5 w-5 text-hivebuy-red"
      />
    </template>
    <div class="w-full py-4">
      <div
        class="flex flex-col md:flex-row md:gap-x-4 gap-y-4 md:gap-y-0 w-full"
      >
        <div class="w-full md:max-w-44 h-24 md:h-32 xl:h-[153px]">
          <HivebuyImage
            :images="item?.images"
            :allow-add="!shouldDisableOptions"
            :add-action="addImage"
            :product-name="item?.name"
          />
        </div>

        <div
          class="md:grid md:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-4 flex flex-col w-full"
        >
          <div class="col-span-full">
            <ItemAutoComplete
              v-model="item"
              :label="$t('purchaseRequest.detail.productTitle')"
              :placeholder="$t('purchaseRequest.detail.productTitle')"
              :error="errorMessage('name')"
              :disabled="shouldDisableOptions"
              :allow-suggestions="false"
            />
          </div>
          <div class="col-span-3">
            <HivebuyPriceInput
              v-model="price"
              :label="$t('purchaseRequest.detail.price')"
              :error-text="errorMessage('netAmount')"
              :help-text="''"
              :max-fraction-digits="4"
              :disabled="shouldDisableOptions"
            />
          </div>
          <QuantitySelect
            :item="item"
            :index="index"
            :show-buttons="false"
            :error-text="errorMessage"
            :disabled="item.isIntegratedSupplierItem"
            class="box-border"
          />
        </div>
      </div>
      <div class="pt-4 md:pt-6 grid grid-cols-1 gap-4 md:gap-y-6">
        <HText
          v-model="item.link"
          label="Link"
          icon="link"
          :disabled="shouldDisableOptions"
        />
        <div class="flex-col flex">
          <label class="label" for="area">{{ $t("global.description") }}</label>
          <Textarea id="area" v-model="item.description" rows="4" />
        </div>
      </div>
      <div class="pt-4 md:pt-6 grid grid-cols-1 gap-4 md:gap-y-6">
        <div class="grid md:grid-cols-2 md:gap-x-4 gap-y-4 md:gap-y-0">
          <Addresses v-model="item" :v="v" />
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import { ItemAutoComplete } from "@/components/RequestCreation";
import Textarea from "primevue/textarea";
import { HivebuyPriceInput, HText, HivebuyImage } from "design-buy";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import Addresses from "@/components/PurchaseRequest/Form/addresses.vue";
import QuantitySelect from "@/components/shared/quantitySelect.vue";
import addFilesImagesToObjectMixin from "@/components/mixins/addFilesImagesToObjectMixin.js";
import Panel from "primevue/panel";
import { ChevronDownIcon, ExclamationIcon } from "@heroicons/vue/solid";
export default {
  name: "BasicInfo",
  components: {
    ItemAutoComplete,
    HivebuyPriceInput,
    HText,
    Textarea,
    Addresses,
    QuantitySelect,
    HivebuyImage,
    Panel,
    ChevronDownIcon,
    ExclamationIcon,
  },
  mixins: [newRequestErrorMixin, addFilesImagesToObjectMixin],
  inject: ["index"],
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    shouldDisableOptions() {
      return this.item?.isCatalogueItem || this.item.isIntegratedSupplierItem;
    },

    price: {
      get() {
        const {
          netAmount,
          netAmountCurrency,
          netGross,
          vat,
          tax,
          grossAmount,
        } = this.modelValue;
        return {
          currency: netAmountCurrency || "EUR",
          netGross: netGross || "net",
          value:
            netGross == "net" ? parseFloat(netAmount) : parseFloat(grossAmount),
          grossAmount: parseFloat(grossAmount) || 0,
          netAmount: parseFloat(netAmount) || 0,
          vat: vat > -1 ? vat : 19,
          tax: tax || 0,
        };
      },
      set(priceValue) {
        const { currency, netGross, vat, grossAmount, netAmount, tax } =
          priceValue;
        const priceInfo = {
          grossAmount,
          netAmount,
          tax,
          vat,
          netGross: netGross,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
          currency: currency,
          taxCurrency: currency,
        };

        this.item = Object.assign({}, this.item, priceInfo);
      },
    },
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    async addImage(files) {
      this.item.images = await this.formatObjects(files, "image");
    },
    togglePanel(value) {
      this.$refs.panel.d_collapsed = value;
    },

    toggle() {
      this.$refs.panel.toggle();
    },
  },
};
</script>
<style scoped>
:deep(.p-autocomplete-input) {
  width: 100%;
}

:deep(.p-autocomplete) {
  width: 100%;
}

:deep(.p-inputwrapper) {
  width: 100%;
}
:deep(.p-inputtext) {
  @apply text-base;
}

:deep(.p-input-icon-left > .p-inputtext) {
  padding-left: 2rem;
}

:deep(.label) {
  display: flex !important;
}
</style>
