import { paramsForKey } from "@/utils/helpers/apis/urlParamMapping.js";
import { keyFromUrl } from "@/utils/helpers/apis/urlKeyMapping.js";
import { isObjectEmpty } from "@/utils/utility_methods";

const getURLKey = (url, urlKey = null) => {
  if (urlKey) return urlKey;

  return keyFromUrl(url);
};

export const urlWithParams = (url, urlKey = null, paginationParams = {}) => {
  let urlParams = paramsForKey(getURLKey(url, urlKey));
  let queryParams = "";

  if (!isObjectEmpty(paginationParams)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { totalPages, totalRecords, ...params } = paginationParams;
    urlParams = Object.assign({}, params, urlParams);
  }

  Object.keys(urlParams).forEach((key, index) => {
    queryParams += `${!index ? "?" : "&"}${key}=${urlParams[key]}`;
  });

  return url + queryParams;
};
