export const SHOPPING_LIST_MODULE = "SHOPPING_LIST_MODULE";

export const SAVE_PRODUCT = "SAVE_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SET_SHOPPING_LIST = "SET_SHOPPING_LIST";
export const SET_SHOPPING_LISTS = "SET_SHOPPING_LISTS";
export const RESET_SHOPPING_LIST = "RESET_SHOPPING_LIST";
export const UPDATE_SHOPPING_LIST = "UPDATE_SHOPPING_LIST";

export const GET_SHOPPING_LISTS = "GET_SHOPPING_LISTS";
export const SAVE_SHOPPING_LIST = "SAVE_SHOPPING_LIST";
export const TOGGLE_VISIBLE_STATE = "TOGGLE_VISIBLE_STATE";
export const UPDATE_LIST_RELATIONS = "UPDATE_LIST_RELATIONS";
export const SET_SHOPPING_LIST_DATA = "SET_SHOPPING_LIST_DATA";
