<template>
  <div class="flex items-center space-x-2 justify-between">
    <div class="flex items-center gap-x-1">
      <span>{{
        $t("catalogue.catalogueItems.requestedForConfiguration")
      }}</span>

      <Tip help theme="tooltip" :hide-on-click="false">
        <template #header>
          {{ $t("catalogue.catalogueItems.requestedForConfiguration") }}
        </template>
        <template #content>
          <p class="tooltip-subtext">
            {{ $t("catalogue.catalogueItems.requestedForConfigurationDesc") }}
          </p>
        </template>
      </Tip>
    </div>
    <SwitchCheckbox
      v-model="showRequestedForMultipleTimes"
      state-icons
      :read-only="hasIntegratedSupplierItem"
    />
    <ConfirmDialog
      :text="$t('catalogue.catalogueItems.sameInfoConfirmation')"
      :show-modal="showConfirmation"
      :close-modal="toggleConfirmation"
      :title="$t('catalogue.catalogueItems.requestedForConfiguration')"
      :confirm-modal="copyValue"
    />
  </div>
</template>

<script>
import { SwitchCheckbox } from "@/components/shared";
import newRequestMixin from "@/components/mixins/newRequestMixin.js";
import { ConfirmDialog } from "@/components/shared";
export default {
  components: {
    ConfirmDialog,
    SwitchCheckbox,
  },
  mixins: [newRequestMixin],
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    hasIntegratedSupplierItem() {
      return this.newRequest.items.some(
        (item) => item.isIntegratedSupplierItem
      );
    },
    showRequestedForMultipleTimes: {
      get() {
        return this.newRequest.sameRecipient;
      },
      set(value) {
        if (value && !this.isRecipientSame) {
          this.toggleConfirmation();
          return;
        }
        this.saveRequestAttributes({ sameRecipient: value });
      },
    },
    isRecipientSame() {
      if (this.newRequest.items.length == 0) return true;

      const recipients = [];
      const address = [];
      const invoiceAddress = [];
      this.newRequest.items.forEach((item) => {
        recipients.push(item.requestedForId);
        address.push(item.companyAddress);
        invoiceAddress.push(item.invoiceAddress);
      });
      return (
        Array.from(new Set(recipients)).length == 1 &&
        Array.from(new Set(address)).length == 1 &&
        Array.from(new Set(invoiceAddress)).length == 1
      );
    },
  },
  mounted() {
    if (this.hasIntegratedSupplierItem) {
      this.copyValue();
    }
  },
  methods: {
    toggleConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },
    copyValue() {
      this.saveRequestAttributes({ sameRecipient: true });
      const copyAttributes = [
        "department",
        "project",
        "user",
        "requestedForId",
        "requestedForType",
        "user",
        "companyAddress",
        "invoiceAddress",
      ];
      const item = this.newRequestItem(0);
      const payload = {};
      copyAttributes.forEach((attribute) => {
        payload[attribute] = item[attribute];
      });
      Object.keys(payload).forEach((key) => {
        this.copyValueToAllItems(key, payload[key]);
      });
      if (this.showConfirmation) this.toggleConfirmation();
    },
  },
};
</script>
