<template>
  <div :class="['input-holder block', { 'px-4 py-2': !isInInvoice }]">
    <label for="prefix" class="label">{{ label }} </label>
    <div class="relative">
      <div class="flex">
        <Multiselect
          id="currency-select"
          v-model="currency"
          mode="single"
          :options="currencyOptions"
          :can-clear="false"
          :track-by="'value'"
          :value-prop="'value'"
          :disabled="disabled"
        >
          <template #singlelabel="{ value: option }">
            <CurrencyDisplay :option="option" class="p-2" />
          </template>
          <template #option="{ option }">
            <CurrencyDisplay :option="option" />
          </template>
        </Multiselect>
      </div>
    </div>
  </div>
</template>
<script>
import { CURRENCY_OPTIONS } from "@/utils/constants.js";
import Multiselect from "@vueform/multiselect";
import CurrencyDisplay from "@/components/shared/CurrencySelect/currencyDisplay.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
export default {
  name: "CurrencySelect",
  components: {
    Multiselect,
    CurrencyDisplay,
  },
  props: {
    modelValue: {
      type: [String, null],
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: "Currency",
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isInInvoice: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    currencyOptions() {
      return CURRENCY_OPTIONS;
    },
    currency: {
      get() {
        return this.modelValue || this.company.currency;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.$emit("change", val);
      },
    },
  },
};
</script>
<style scoped>
:deep(.multiselect-wrapper) {
  justify-content: space-between !important;
}
</style>
