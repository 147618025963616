import { get, patch, post } from "../api";
import {
  DATEV_INTEGRATION_API_URL,
  SEPA_INTEGRATION_API_URL,
  INTEGRATIONS_API_URL,
  INTEGRATIONS_SLUGS_API_URL,
} from "@/utils/services/apiUrls.js";
import {
  SET_DATEV_INTEGRATION,
  SET_INTEGRATION,
  SET_INTEGRATIONS,
  SET_INTEGRATIONS_SLUGS,
} from "@/store/CompanyManagement/types";
import { DOWNLOAD_MODULE, SET_DOWNLOAD_STATUS } from "@/store/Downloads/types";
export const updateIntegration = (
  commit,
  resolve,
  reject,
  integrationId,
  payload
) => {
  patch(`${INTEGRATIONS_API_URL}${integrationId}/`, payload)
    .then((response) => {
      commit(SET_INTEGRATION, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const addDatevCredentials = (commit, resolve, reject) => {
  get(`${DATEV_INTEGRATION_API_URL}connect`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const connectSingleClient = (commit, resolve, reject, id) => {
  get(`${DATEV_INTEGRATION_API_URL}${id}/connect`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const confirmDatevConnection = (commit, resolve, reject, payload) => {
  post(`${DATEV_INTEGRATION_API_URL}confirm`, payload)
    .then((response) => {
      if(response.data.datevIntegration) {
        commit(SET_DATEV_INTEGRATION, response.data.datevIntegration);
      } else {
        commit(SET_DATEV_INTEGRATION, response.data);
      }
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const setCollectiveCreditor = (commit, resolve, reject, payload) => {
  patch(`${DATEV_INTEGRATION_API_URL}collective-creditor`, payload)
    .then((response) => {
      commit(SET_DATEV_INTEGRATION, response.data.datevIntegration);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const disconnectDatev = (commit, resolve, reject) => {
  post(`${DATEV_INTEGRATION_API_URL}disconnect`)
    .then((response) => {
      commit(SET_DATEV_INTEGRATION, response.data.datevIntegration);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const disconnectDatevSingleClient = (commit, resolve, reject, id) => {
  post(`${DATEV_INTEGRATION_API_URL}${id}/disconnect`)
    .then((response) => {
      commit(SET_DATEV_INTEGRATION, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDatevAccountConsultants = (resolve, reject) => {
  get(`${DATEV_INTEGRATION_API_URL}get-clients`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDatevStatus = (commit, resolve, reject) => {
  get(`${DATEV_INTEGRATION_API_URL}status`)
    .then((response) => {
      commit(SET_DATEV_INTEGRATION, response.data.datevIntegration);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getIntegrationsSlugs = (commit, resolve, reject) => {
  get(`${INTEGRATIONS_SLUGS_API_URL}`)
    .then((response) => {
      commit(SET_INTEGRATIONS_SLUGS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const setDatevConsultantId = (commit, resolve, reject, payload) => {
  post(`${DATEV_INTEGRATION_API_URL}set-consultant-id`, payload)
    .then((response) => {
      commit(SET_DATEV_INTEGRATION, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const downloadSepa = (commit, resolve, reject, payload) => {
  post(SEPA_INTEGRATION_API_URL, payload)
    .then((response) => {
      commit(
        `${DOWNLOAD_MODULE}/${SET_DOWNLOAD_STATUS}`,
        {
          storeState: "sepa",
          downloading: true,
          value: null,
        },
        { root: true }
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const uploadPurchaseOrderInvoicesToDatev = (
  resolve,
  reject,
  payload
) => {
  post(`${DATEV_INTEGRATION_API_URL}upload-purchase-order-invoices`, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getIntegrations = (commit, resolve, reject) => {
  get(INTEGRATIONS_API_URL)
    .then((response) => {
      commit(SET_INTEGRATIONS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getIntegrationUrl = (commit, resolve, reject, integrationId) => {
  post(`${INTEGRATIONS_API_URL}${integrationId}/url/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
