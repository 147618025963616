<template>
  <div class="card flex justify-center">
    <Button label="Login" icon="pi pi-user" @click="visible = true" />
    <Dialog
      v-model:visible="visible"
      :closable="false"
      :modal="true"
      :show-header="true"
      :style="{ width: '30vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <div class="pb-8 px-4">
        <div
          class="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-green-100"
        >
          <SuccessCheckmark />
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <p class="text-lg text-gray-700 font-semibold uppercase">
              {{ $t("requestCreation.success.text") }}
            </p>
          </div>
        </div>
        <p
          v-if="selfTimeOut"
          class="text-sm text-gray-500 leading-relaxed mt-4"
        >
          {{ $t("requestCreation.success.subText", { seconds: timer }) }}
        </p>
      </div>
      <button
        type="button"
        class="px-5 py-2.5 w-full !bg-primary rounded-lg text-white text-sm border-none outline-none hover:!bg-primarydark transition-colors"
        @click="() => manualRedirect()"
      >
        {{ $t("global.prepositions.ok") }}
      </button>
    </Dialog>
  </div>
</template>

<script>
import SuccessCheckmark from "@/assets/images/SuccessCheckmark.vue";
import Dialog from "primevue/dialog";

export default {
  components: {
    SuccessCheckmark,
    Dialog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selfTimeOut: {
      type: Boolean,
      default: false,
    },
    timeoutAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      timer: null,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.visible = val;
          if (this.selfTimeOut) {
            this.timer = 5;
            this.startTimer();
          }
        }
      },
    },
  },
  methods: {
    startTimer() {
      const interval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(interval);
          this.visible = false;
          this.timeoutAction();
        }
      }, 1000);
    },
    manualRedirect() {
      this.timer = null;
      this.timeoutAction();
    },
  },
};
</script>
