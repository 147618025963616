import { get, patch } from "@/utils/services/api";
import { GLOBAL_POLICY_API_URL } from "@/utils/services/apiUrls";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types";

export const updateGlobalPolicy = (
  commit,
  resolve,
  reject,
  conditionId,
  payload
) => {
  if (!conditionId) return;

  patch(`${GLOBAL_POLICY_API_URL}${conditionId}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_GLOBAL_POLICY, response.data);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const getGlobalPolicies = (commit, resolve, reject) => {
  get(GLOBAL_POLICY_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_GLOBAL_POLICIES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
