<template>
  <div>
    <div class="relative rounded">
      <Input
        :currency-symbol="currency.symbol"
        :is-currency="true"
        :error-text="errorText"
        :value="value"
        :on-value-change="onValueChange"
        type="number"
        :placeholder="placeholderText"
        :label="$t('purchaseRequest.detail.price')"
        :on-key-up="onKeyUp"
        :name="name"
        :is-mandatory="isMandatory"
        :classes="'block w-full px-6 py-2 border border-gray-200 rounded placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-base'"
      />
      <div class="absolute top-9 right-3 flex items-center">
        <Popper class="z-50">
          <button class="flex items-center focus:outline-none outline-none">
            <CogIcon
              class="h-6 flex items-center text-gray-400 hover:text-primary outline-none self-center focus:outline-none"
            />
          </button>
          <template #content>
            <div class="z-50 sm:px-0 bg-white w-56 py-4">
              <div class="mx-auto px-6">
                <ButtonAsRadio
                  :label="'VAT Rate'"
                  :options="VAT_RATES"
                  :value="vat"
                  :name="'vat'"
                  :on-change-handler="changeHandler"
                />

                <ButtonAsRadio
                  :label="'Net or Gross'"
                  :options="NET_GROSS($t)"
                  :value="netGross"
                  :name="'netGross'"
                  :on-change-handler="changeHandler"
                />

                <ButtonAsRadio
                  :label="'Currency'"
                  :options="CURRENCY"
                  :value="currency.value"
                  :name="'currency'"
                  :on-change-handler="changeHandler"
                />
              </div>
            </div>
          </template>
        </Popper>
      </div>
    </div>
  </div>
</template>

<script>
import { CogIcon } from "@heroicons/vue/solid";
import { VAT_RATES, NET_GROSS, CURRENCY } from "@/utils/constants.js";
import Input from "@/components/shared/Input.vue";
import ButtonAsRadio from "@/components/shared/ButtonAsRadio.vue";

export default {
  components: {
    Input,
    CogIcon,
    ButtonAsRadio,
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {
        return { vat: 19, netGross: "gross" };
      },
    },
    systemCurrency: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: false,
      default: null,
    },
    errorText: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    onValueChange: {
      type: Function,
      default: () => {},
    },
    isMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return this.defaultValues();
  },
  computed: {
    placeholderText() {
      let vatText = "";
      if (this.netGross === "net") {
        vatText = "Price (excl. " + this.vat + "% VAT)";
      } else {
        vatText = "Price (incl. " + this.vat + "% VAT)";
      }
      return vatText;
    },
  },
  watch: {
    systemCurrency: {
      handler(newVal) {
        this.currency = this.getCurrencyValue(newVal);
      },
    },
  },
  created() {
    this.VAT_RATES = VAT_RATES;
    this.NET_GROSS = NET_GROSS;
    this.CURRENCY = CURRENCY;
  },
  methods: {
    getCurrencyValue(currency) {
      return CURRENCY.find((element) => element.value == currency);
    },
    defaultValues() {
      const { vat, netGross } = this.options;
      return {
        vat: vat >= 0 ? vat.toString() : "19",
        netGross: netGross || "gross",
        currency: this.getCurrencyValue(this.systemCurrency) || CURRENCY[0],
      };
    },
    changeHandler(event) {
      let { value } = event.target;
      const { name } = event.target;
      value = name == "currency" ? this.handleCurrencyValue(value) : value;
      this.$data[name] = value;

      this.onValueChange({ target: { name, value } });
    },
    handleCurrencyValue(val) {
      const currency = CURRENCY.find((element) => element.value == val);
      return currency.value;
    },
  },
};
</script>
