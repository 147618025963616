<template>
  <div id="indicators" class="custom-card analytic-card">
    <ChartLoader v-if="loading" />
    <div v-else class="card-data">
      <dt class="custom-card-header">
        <h2 class="card-title">{{ heading }}</h2>
        <Description v-if="description" :description="description" />
      </dt>
      <dd class="card-content flex-col">
        <div class="content">
          <span class="text-base xl:text-2xl font-bold text-primary">{{
            averageTime.avgDays.toFixed(1)
          }}</span>
          <span class="text-base xl:text-xl ml-1 font-bold text-primary">{{
            $t("charts.indicator.days")
          }}</span>
          <span
            v-if="averageTime.avgPastDays != null"
            class="days-from text-gray-700"
          >
            from {{ averageTime.avgPastDays.toFixed(1) }}
          </span>
        </div>

        <div
          :class="[
            'lower-text',
            {
              'text-hivebuy-green':
                !averageTime.avgPastDays ||
                averageTime.avgDays < averageTime.avgPastDays,
            },
            {
              'text-hivebuy-red':
                averageTime.avgPastDays &&
                averageTime.avgDays > averageTime.avgPastDays,
            },
          ]"
        >
          <div
            v-if="averageTime.avgPastDays"
            :class="[
              averageTime.avgDays > averageTime.avgPastDays
                ? 'bg-hivebuy-red text-hivebuy-red  bg-opacity-20'
                : 'bg-hivebuy-green text-hivebuy-green bg-opacity-20',
              'badge',
            ]"
          >
            <ArrowSmDownIcon
              v-if="averageTime.avgDays > averageTime.avgPastDays"
              class="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <ArrowSmUpIcon
              v-else
              class="h-5 w-5 text-hivebuy-green"
              aria-hidden="true"
            />

            <span class="sr-only">
              {{
                averageTime.avgDays > averageTime.avgPastDays
                  ? $t("charts.indicator.decreased")
                  : $t("charts.indicator.increased")
              }}
              by
            </span>
            {{ averagePercentage }}%
          </div>
          <div
            class="base-content text-gray-600 flex align-center place-content-center font-light text-sm"
          >
            <span class="ml-1 mr-1 font-bold">{{ averageTime.avgHours }}</span>
            {{ $t("charts.indicator.hours") }}
            <div v-if="averageTime.avgPastHours != null" class="ml-1">
              {{ "<-"
              }}<span class="ml-1 mr-1 font-bold">{{
                averageTime.avgPastHours
              }}</span
              >{{ $t("charts.indicator.hours") }}
            </div>
          </div>
        </div>
      </dd>
    </div>
  </div>
</template>
<script>
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";
import { ChartLoader } from "@/components/shared/index";
import Description from "@/components/Analytics/charts/description.vue";
export default {
  components: {
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    ChartLoader,
    Description,
  },
  props: {
    description: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    heading: { type: String, required: true },
    averageTime: { type: Object, required: true },
  },
  computed: {
    averagePercentage() {
      const value = this.averageTime.avgDays / this.averageTime.avgPastDays;
      return ((1 - value) * 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
$bayoux300: #a6d2d7;
$bayoux500: #4d717c;
$bayoux600: #395a6a;

$teal500: #14b8a6;

$gray300: #d1d5db;

#indicators {
  .content {
    flex-direction: column;
    margin-bottom: 0.5rem;
    .days-large,
    .days-small {
      font-weight: bold;
      margin-left: 0.25rem;
    }
    .days-large {
      line-height: 1;
      @media only screen and (max-width: 1200px) and (min-width: 1025px) {
      }
    }
    .days-small {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .days-from {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
}

.lower-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.25rem;

  font-weight: 500;

  .badge {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-right: 0.25rem;
  }

  .base-content {
  }

  @media only screen and (max-width: 1200px) and (min-width: 1025px) {
    flex-direction: column;
    .badge {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}

.box .map {
  min-height: 404px;
  background-size: cover;
}
</style>
