import {
  featuresMixin,
  LoadingMixin,
  toastMixin,
  memberDetailsMixin,
  dateMixin,
} from "@/components/mixins";
export const defineGlobalMixins = (instance) => {
  instance.mixin(toastMixin);
  instance.mixin(featuresMixin);
  instance.mixin(memberDetailsMixin);
  instance.mixin(LoadingMixin);
  instance.mixin(dateMixin);
};
