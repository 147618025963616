export default {
  contracts: [],
  contractsListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
      search: "",
      ordering: "name",
    },
    advancedFilters: {},
  },
};
