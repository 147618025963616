<template>
  <div class="shipping-address">
    <div class="shipping-address__header">
      <div class="shipping-address__header__left">
        <h3 class="card-title">
          {{ $t("companyManagement.profile.address.title") }}
        </h3>
        <span class="sub-title">
          {{ $t("companyManagement.profile.addressToolTip") }}
        </span>
      </div>
      <div class="shipping-address__header__right">
        <button class="address-button" @click="onAddAddress">
          <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
          {{ $t("companyManagement.profile.address.addAddress") }}
        </button>
      </div>
    </div>
    <div class="profile-card-content">
      <List />
    </div>
    <AddAddressModal
      :show-modal="showAddAddressModal"
      :close-modal="onAddAddressModalClose"
    />
  </div>
</template>

<script>
import List from "@/components/CompanyManagement/Profile/CompanyAddresses/List";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import AddAddressModal from "@/components/CompanyManagement/Profile/CompanyAddresses/AddAddressModal";

export default {
  name: "CompanyAddresses",
  components: {
    List,
    PlusCircleIcon,
    AddAddressModal,
  },
  data() {
    return {
      showAddAddressModal: false,
    };
  },
  methods: {
    onAddAddress() {
      this.showAddAddressModal = true;
    },
    onAddAddressModalClose() {
      this.showAddAddressModal = false;
    },
  },
};
</script>

<style scoped>
.shipping-address {
}

.shipping-address__header {
  @apply flex justify-between border-b border-gray-200 pb-4;
}

.shipping-address__header__left {
  @apply flex flex-col w-9/12;
}
.shipping-address__header__left .card-title {
  @apply font-bold text-primary mb-1 text-sm;
}
.shipping-address__header__left .sub-title {
  @apply text-sm text-gray-500;
}

.shipping-address__header__right {
  @apply flex items-center;
}

.address-button {
  @apply flex items-center text-base font-semibold text-primary focus:outline-none;
}
</style>
