import * as TYPES from "./types.js";
import {
  addInvoice,
  assignPurchaseOrder,
  updateInvoice,
  updateInvoiceStatus,
  getActionableInvoices,
  getInvoices,
  getInvoicesWithoutPagination,
  deleteInvoice,
  getOcr,
  ocrPreview,
  markPoPending,
  getInvoice,
  markNotInvoice,
  getDebtors,
  validateInvoice,
  unAssignPurchaseOrder,
} from "@/utils/services";

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [TYPES.ADD_INVOICE]: ({ _commit }, payload) => {
    return new Promise((resolve, reject) => {
      addInvoice(payload, resolve, reject);
    });
  },
  [TYPES.GET_INVOICE]: ({ rootGetters, commit }, id) => {
    const object = rootGetters["INVOICE_MODULE/invoiceWithId"](id);
    return new Promise((resolve, reject) => {
      if (object && object.files) resolve(object);
      else getInvoice(id, commit, resolve, reject);
    });
  },
  [TYPES.ASSIGN_PURCHASE_ORDER]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      assignPurchaseOrder(payload, resolve, reject);
    });
  },

  [TYPES.VALIDATE_INVOICE]: (_store, payload) => {
    return new Promise((resolve, reject) => {
      validateInvoice(payload, resolve, reject);
    });
  },
  [TYPES.UPDATE_INVOICE]: ({ commit }, { invoiceId, payload }) => {
    return new Promise((resolve, reject) => {
      updateInvoice(invoiceId, payload, resolve, reject, commit);
    });
  },
  [TYPES.GET_OCR_DATA]: ({ commit }, invoiceId) => {
    return new Promise((resolve, reject) => {
      getOcr(invoiceId, commit, resolve, reject);
    });
  },
  [TYPES.GET_OCR_PREVIEW_DATA]: ({ commit }, file) => {
    return new Promise((resolve, reject) => {
      ocrPreview(file, commit, resolve, reject);
    });
  },

  [TYPES.GET_INVOICES_WITHOUT_PAGINATION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      getInvoicesWithoutPagination(payload, resolve, reject);
    });
  },
  [TYPES.GET_INVOICES]: ({ commit }, { queryParams, areFiltersUpdated }) => {
    return new Promise((resolve, reject) => {
      getInvoices(queryParams, areFiltersUpdated, commit, resolve, reject);
    });
  },

  [TYPES.UPDATE_INVOICE_RELATION]: (
    { commit },
    { invoiceRelationId, isInvoiceLevelApprover, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateInvoiceStatus(
        invoiceRelationId,
        isInvoiceLevelApprover,
        payload,
        commit,
        resolve,
        reject
      );
    });
  },

  [TYPES.ACTIONABLE_INVOICES]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getActionableInvoices(params, commit, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [TYPES.DELETE_INVOICE]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      deleteInvoice(payload, resolve, reject);
    });
  },
  [TYPES.SET_PO_STATUS_PENDING]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      markPoPending(payload, commit, resolve, reject);
    });
  },
  [TYPES.SET_NOT_INVOICE]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      markNotInvoice(id, commit, resolve, reject);
    });
  },
  [TYPES.GET_DEBTORS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getDebtors(commit, resolve, reject);
    });
  },

  [TYPES.UNASSIGN_PURCHASE_ORDER]: ({ commit }, ipoId) => {
    return new Promise((resolve, reject) => {
      unAssignPurchaseOrder(commit, resolve, reject, ipoId);
    });
  },
};
