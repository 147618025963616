import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { Vue as VueIntegration } from "@sentry/integrations/dist/vue";
import SentryRRWeb from "@sentry/rrweb";

export const initSentry = (instance, router) => {
  if (process.env.VUE_APP_ENV !== "production") return;

  Sentry.init({
    environment: process.env.VUE_APP_ENV,
    dsn: `https://${process.env.VUE_APP_SENTRY_SECRET}@${process.env.VUE_APP_SENTRY_TOKEN}.ingest.sentry.io/${process.env.VUE_APP_SENTRY_KEY}`,
    integrations: [
      new VueIntegration({ Vue: instance }),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.hivebuy.de", /^\//],
      }),
      new SentryRRWeb({}),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    tracesSampleRate: 0.5,
  });
};
