import AddApprover from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/AddApprover";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import globalPolicyMixin from "@/components/mixins/globalPolicyMixin";

export default {
  components: {
    AddApprover,
    SwitchCheckbox,
  },
  mixins: [globalPolicyMixin],
  data() {
    return {
      isActive: this.approvalWorkflow.isActive,
    };
  },
  methods: {
    workflowDefaultValues() {
      return {
        roleForResult: "",
        departmentForResult: "",
        userForResult: "",
        departmentApprover: false,
        isActive: this.isActive,
      };
    },
  },
};
