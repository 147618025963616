import { errorMixin } from "@/components/mixins";

export default {
  mixins: [errorMixin],
  props: {
    v: {
      type: Object,
      required: true,
    },
  },
  methods: {
    errorMessage(validationField) {
      if (!this.v.$error || !validationField) return "";

      return this.fieldErrorMessage(this.v, validationField);
    },
  },
};
