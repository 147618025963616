<template>
  <div
    class="border border-gray-300 rounded bg-white px-4 relative w-[250px] h-[100px]"
  >
    <div class="px-4 py-3 sm:p-3">
      <dt class="text-base font-medium text-gray-800">Cost Savings</dt>
      <dd class="flex items-baseline justify-between md:block lg:flex">
        <div class="items-baseline text-xl font-semibold text-primary">
          <i18n-n
            v-if="costSavings.valueTotal"
            :value="parseFloat(costSavings.valueTotal) || 0"
            format="budget"
            :locale="currencyToLocale(currency)"
          />
          <p class="text-sm text-gray-500">
            of
            <i18n-n
              v-if="costSavings.goal"
              :value="parseFloat(costSavings.goal) || 0"
              format="budget"
              :locale="currencyToLocale(currency)"
            />
          </p>
        </div>
      </dd>
    </div>
    <div class="absolute top-0 right-0">
      <CostSavingsGauge
        :goal="costSavings.goal"
        :value-total="costSavings.valueTotal"
        :value-this-month="costSavings.valueThisMonth"
      />
    </div>
  </div>
  <div
    class="ml-2 border border-gray-300 rounded bg-white px-4 relative w-[250px] h-[100px]"
  >
    <div class="px-4 py-3 sm:p-3">
      <dt class="text-base font-medium text-gray-800">Cost Avoidance</dt>
      <dd class="flex items-baseline justify-between md:block lg:flex">
        <div class="items-baseline text-xl font-semibold text-primary">
          <i18n-n
            v-if="costAvoidance.valueTotal"
            :value="parseFloat(costAvoidance.valueTotal) || 0"
            format="budget"
            :locale="currencyToLocale(currency)"
          />
          <p class="text-sm text-gray-500">
            of
            <i18n-n
              v-if="costAvoidance.goal"
              :value="parseFloat(costAvoidance.goal) || 0"
              format="budget"
              :locale="currencyToLocale(currency)"
            />
          </p>
        </div>
      </dd>
    </div>
    <div class="absolute top-0 right-0">
      <CostSavingsGauge
        :goal="costAvoidance.goal"
        :value-total="costAvoidance.valueTotal"
        :value-this-month="costAvoidance.valueThisMonth"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { HProgressBar } from "@/components/shared/index";
import CostSavingsGauge from "@/components/Analytics/charts/CostSavingsGauge.vue";
import {
  GET_COST_SAVING,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types.js";
import { currencyToLocale } from "@/utils/utility_methods.js";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HProgressBar,
    CostSavingsGauge,
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["costSaving"]),
    costSavings() {
      return {
        goal: this.costSaving.costSavingGoal?.toString(),
        valueTotal: this.costSaving.costSavingAll?.toString(),
        valueThisMonth: this.costSaving.costSavingTm?.toString(),
      };
    },
    costAvoidance() {
      return {
        goal: this.costSaving.costAvoidanceGoal?.toString(),
        valueTotal: this.costSaving.costAvoidanceAll?.toString(),
        valueThisMonth: this.costSaving.costAvoidanceTm?.toString(),
      };
    },
    currency() {
      return this.costSaving.costSavingGoalCurrency;
    },
  },
  mounted() {
    this.getCostSaving();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCostSaving: GET_COST_SAVING,
    }),
    currencyToLocale,
  },
};
</script>
