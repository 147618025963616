<template>
  <div class="buttons-holder">
    <Button class="button" color="light-gray" :on-click-handler="cancelFilters"
      >Cancel</Button
    >
    <Button
      class="button"
      color="success"
      :disabled="disabled"
      :on-click-handler="applyFilters"
      >Apply</Button
    >
  </div>
</template>
<script>
import Button from "@/components/shared/Button.vue";
export default {
  components: { Button },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel-filters", "apply-filters"],
  methods: {
    cancelFilters() {
      this.$emit("cancel-filters");
    },
    applyFilters() {
      this.$emit("apply-filters");
      this.$emit("cancel-filters");
    },
  },
};
</script>
<style scoped>
.buttons-holder {
  @apply flex gap-x-3 mt-4;
}

.buttons-holder .button {
  @apply flex-1 justify-center;
}
</style>
