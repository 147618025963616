import actions from "@/store/Invoice/actions";
import mutations from "@/store/Invoice/mutations";
import state from "@/store/Invoice/state";
import getters from "@/store/Invoice/getters.js";
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
