import mutations from "@/store/ShoppingList/mutations";
import state from "@/store/ShoppingList/state";
import getters from "@/store/ShoppingList/getters.js";
import actions from "@/store/ShoppingList/actions.js";
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
