<template>
  <AppUnderMaintenance v-if="isAppUnderMaintenance()" />
  <SelfSignup v-else-if="isSelfSignupFlow()" />
  <SignUp v-else-if="isSignedUp()" />
  <Home v-else-if="isUserLoggedIn()" />
</template>

<script>
import Home from "@/views/Home";
import fusionAuth from "@/utils/services/auth";
import SelfSignup from "@/views/SelfSignup";
import SignUp from "@/views/SignUp.vue";
import { isAppUnderMaintenance, isSelfSignup } from "@/router";
import AppUnderMaintenance from "@/views/AppUnderMaintenance.vue";

export default {
  components: { Home, SelfSignup, SignUp, AppUnderMaintenance },
  methods: {
    isUserLoggedIn() {
      return fusionAuth.isUserLoggedIn();
    },
    isSignedUp() {
      const redirectionIdentifiers = ["integration", "slug"];
      const { state } = this.$route.query;
      const externalRedirection = redirectionIdentifiers.some((identifier) => {
        return this.$route.query[identifier];
      });
      return !!state && !externalRedirection;
    },
    isSelfSignupFlow() {
      return isSelfSignup(window.location.pathname);
    },
    isAppUnderMaintenance,
  },
};
</script>
