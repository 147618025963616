<template>
  <tr
    v-for="(field, index) in sortedCustomFields"
    :key="index"
    :class="classes"
  >
    <td>{{ field.label }}:</td>
    <td>
      <span v-if="field.type == 'TEXT'" class="font-semibold">
        {{ field.value }}
      </span>
      <span v-if="field.type == 'BOOLEAN'">
        <CheckCircleIcon
          v-if="field.value"
          class="h-4 w-4 text-hivebuy-green"
        />
        <XCircleIcon v-else class="h-4 w-4 text-hivebuy-red" />
      </span>
      <span v-if="field.type == 'PRICE'" class="font-semibold">
        <i18n-n
          :value="parseFloat(field.value)"
          format="currency"
          :locale="currencyToLocale(field.currency)"
        />
      </span>
      <span v-if="field.type == 'SELECT'">
        {{ customFieldSelectLabel(field) }}
      </span>
    </td>
  </tr>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";
import { currencyToLocale } from "@/utils/utility_methods";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";

export default {
  name: "CustomFields",
  components: {
    CheckCircleIcon,
    XCircleIcon,
  },
  props: {
    customFields: {
      type: Array,
      required: true,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["customFieldSelectLabel"]),
    sortedCustomFields() {
      return this.customFields
        .slice()
        .sort((a, b) => a.order - b.order)
        .filter((field) => {
          if (field.type === "BOOLEAN") {
            return true;
          } else {
            const value =
              field.type === "PRICE" ? parseFloat(field.value) : field.value;
            return Boolean(value);
          }
        });
    },
  },
  methods: {
    currencyToLocale,
  },
};
</script>
import { mapGetters, mapState } from "vuex";
