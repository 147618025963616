export const downloadKeys = {
  type: "type",
};
export const projectKeys = {};
export const draftPRKeys = {
  createdAt_Gte: "created_at__gte",
  createdAt_Lte: "created_at__lte",
};

export const supplierOrderListKeys = {};

export const PURCHASE_ORDER_STATUSES_MAPPING_KEYS = {
  include_status: "order_statuses__status",
  exclude_status: "order_statuses__status__not",
};

export const purchaseOrderKeys = {
  requestedBy: "purchase_request__requested_by__in",
  requestedFor: "order_items__requested_for_id__in",
  category: "purchase_request__category__in",
  supplier: "purchase_request__company_supplier__in",
  unlistedSupplier: "unlisted_supplier",
  createdAt_Gte: "created_at__gte",
  createdAt_Lte: "created_at__lte",
  statusDate_Gte: "statuses__status_date__gte",
  statusDate_Lte: "statuses__status_date__lte",
  inBudget: "was_in_budget",
  status: "status",
  invoiced: {
    statusKey: "I",
    true: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.include_status,
    false: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.exclude_status,
  },
  ordered: {
    statusKey: "O",
    true: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.include_status,
    false: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.exclude_status,
  },
  delivered: {
    statusKey: "D",
    true: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.include_status,
    false: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.exclude_status,
  },
  completed: {
    statusKey: "CO",
    true: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.include_status,
    false: PURCHASE_ORDER_STATUSES_MAPPING_KEYS.exclude_status,
  },
  orderStatuses: "order_statuses__status__in",
  uploadedToDatev: "datev",
  title: "search",
  totalGross_start: "total_gross__gte",
  totalGross_end: "total_gross__lte",
  totalNet_start: "net_amount__gte",
  totalNet_end: "net_amount__lte",
  purchaseOrderRequestedFor: "order_items__requested_for_id__in",
  legalEntities: "legal_entities__id__in",
};
export const invoiceKeys = {
  title: "search",
  invoiceNumber: "search",
  requestedBy: "invoice__requested_by__in",
  requestedFor: "invoice__requested_for__in",
  category: "invoice__category__in",
  companySupplier: "invoice__company_supplier__in",
  supplier: "vendor_name__icontains",
  createdAt_Gte: "created_at__gte",
  createdAt_Lte: "created_at__lte",
  status: "status",
  uploadedToDatev: "uploaded_to_datev",
  totalGrossAmount_Gte: "total_gross_amount__gte",
  totalGrossAmount_Lte: "total_gross_amount__lte",
  totalNetAmount_Gte: "total_net_amount__gte",
  totalNetAmount_Lte: "total_net_amount__lte",
  invoiceRequestedFor: "invoice__requested_for_id__in",
  actionRequired: "action_required",
  collective: "is_collective",
  paidAt_Gte: "invoice_paid_at__gte",
  paidAt_Lte: "invoice_paid_at__lte",
  invoicePaid: "invoice_paid",
  invoiceDate_Gte: "invoice_date__gte",
  invoiceDate_Lte: "invoice_date__lte",
  invoiceDate: "invoice_date",
  dueDate_Gte: "due_date__gte",
  dueDate_Lte: "due_date__lte",
  serviceDate_Gte: "service_date__gte",
  serviceDate_Lte: "service_date__lte",
  legalEntity: "legal_entity",
};

const mappedModule = (module) => {
  return {
    PURCHASE_ORDER_MODULE: purchaseOrderKeys,
    INVOICE_MODULE: invoiceKeys,
  }[module];
};

export const mapAdvanceFilter = (advancedFilters, module) => {
  const returnHash = {};
  const keyHash = mappedModule(module);
  Object.keys(advancedFilters).map((key) => {
    returnHash[keyHash[key]] = advancedFilters[key];
  });
  return returnHash;
};

export const dateKeys = [
  "createdAt",
  "statusDate",
  "paidAt",
  "invoiceDate",
  "dueDate",
  "serviceDate",
];
