import { mapMutations, mapState } from "vuex";
import { createFiltersPayload } from "@/utils/utility_methods";
import {
  INVOICE_MODULE,
  SET_INVOICE_LIST_FILTERS,
} from "@/store/Invoice/types";
export default {
  computed: {
    ...mapState(INVOICE_MODULE, {
      invoiceListFilters: (state) => state.invoiceListFilters,
    }),
  },
  methods: {
    ...mapMutations(INVOICE_MODULE, {
      setInvoiceListFilters: SET_INVOICE_LIST_FILTERS,
    }),
    updateFilters(key, value, parentKey = null) {
      const payload = createFiltersPayload(
        this.invoiceListFilters,
        key,
        value,
        parentKey
      );
      this.setInvoiceListFilters(payload);
    },
  },
};
