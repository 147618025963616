<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column text-white bg-hivebuy-darkgreen">
        <DeliverSVG class="h-4 w-4" />
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex">
          <span class="text-gray-400 mr-1">
            {{ $t("purchaseRequest.summary.timeline.inDelivery") }}
          </span>
        </div>
        <div class="text-xs text-gray-500 my-1">
          <TimeDisplay :time="details.createdAt || details.statusDate" />
        </div>
      </div>
      <div class="avatar-column flex">
        <div
          v-if="autoProcessing"
          class="bg-white flex items-center rounded-full h-9 w-9 p-1 border"
        >
          <img src="@/assets/images/hivebuy_logo.svg" alt="Hivebuy" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeDisplay } from "@/components/shared/index";
import { memberDetailsMixin } from "@/components/mixins/index";
import { fullName } from "@/utils/utility_methods";
import DeliverSVG from "@/assets/images/DeliverSVG.vue";
export default {
  components: { DeliverSVG, TimeDisplay },
  mixins: [memberDetailsMixin],
  props: {
    details: { type: Object, required: true },
    autoProcessing: { type: Boolean, default: false },
  },
  data() {
    return { orderedBy: "N/A" };
  },
  mounted() {
    this.setOrderedByName();
  },
  methods: {
    async setOrderedByName() {
      this.orderedBy = fullName(
        await this.getMemberDetails(this.details?.createdBy)
      );
    },
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
