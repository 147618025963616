<template>
  <AddressForm
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-address-method="onUpdate"
    :is-edit="true"
    :address-to-edit="addressToEdit"
    :loading="loading"
    :api-error="error"
  />
</template>

<script>
import { mapActions } from "vuex";

import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_ADDRESSES,
  UPDATE_COMPANY_ADDRESS,
} from "@/store/CompanyManagement/types";
import AddressForm from "@/components/CompanyManagement/Profile/CompanyAddresses/AddressForm";

export default {
  components: {
    AddressForm,
  },

  props: {
    addressToEdit: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateCompanyAddress: UPDATE_COMPANY_ADDRESS,
      getCompanyAddresses: GET_COMPANY_ADDRESSES,
    }),
    onUpdate(paymentMethod) {
      this.loading = true;
      this.updateCompanyAddress(paymentMethod)
        .then(() => {
          this.closeModal();
          this.getCompanyAddresses();
          this.showNotification(this.$t("toasts.genericSuccess"));
          this.error = null
        })
        .catch((error) => { this.showErrorMessage(error); this.error = error }).finally(() => this.loading = false);
    },
  },
};
</script>

<style scoped></style>
