import actions from "@/store/Auth/actions";
import mutations from "@/store/Auth/mutations";
import getters from "@/store/Auth/getters";
import state from "@/store/Auth/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
