<template>
  <div class="text-sm font-medium grid grid-cols-12 gap-2">
    <span class="icon-column" :class="rowIcon.class">
      <component :is="rowIcon.icon" class="h-5 w-5" />
    </span>
    <div class="text-column">
      <div class="text-gray-400">
        {{ $t("purchaseRequest.summary.timeline.orderedBy") }}
        <span class="text-gray-900 font-semibold ml-1">{{ TEXT }}</span>
        <!-- <div
          v-if="isSupplierRequest"
          class="text-sm md:text-xs text-gray-400 my-2 pr-6"
        >
          {{ $t("purchaseRequest.summary.timeline.autoOrder") }}
        </div> -->
      </div>
    </div>
    <!-- <div class="avatar-column">
      <div v-if="isSupplierRequest">
        <div class="flex-shrink-0 h-8 w-8">
          <img src="@/assets/images/hivebuy_logo.svg" alt="Hivebuy" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(resultUser, index) in result.users"
          :key="index"
          class="inline-flex -m-1"
        >
          <UserWithAvatar
            :user="resultUser"
            avatar-dimension="9"
            show-tooltip
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ShoppingCartIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ShoppingCartIcon,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  computed: {
    rowIcon() {
      //   if (1 === 1) {
      //     return {
      //       icon: ShoppingCartIcon,
      //       class: "bg-gray-100 text-gray-400",
      //     };
      //   }
      // default return
      return {
        icon: ShoppingCartIcon,
        class: "bg-gray-100 text-blue-400",
      };
    },
  },
};
</script>

<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
