<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2">
      <span
        class="icon-column text-hivebuy-green bg-white border border-gray-200 overflow-hidden"
      >
        <CheckCircleIcon class="h-7 w-7" />
      </span>
      <div class="text-column ml-4 mt-0 col-span-10">
        <span class="text-gray-400 mr-1">
          <div class="relative mt-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-hivebuy-green" />
            </div>
            <div class="relative flex justify-center">
              <span
                class="px-2 bg-white text-sm text-gray-400 rounded-lg border border-gray-200"
              >
                {{ $t("timeline.fullyApproved") }}
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { timeDifference } from "@/utils/timeHelper.js";
export default {
  components: { CheckCircleIcon },
  methods: {
    timeDifference,
  },
};
</script>
<style>
.icon-column {
  @apply inline-flex items-center justify-center rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5;
}
</style>
