<template>
  <div class="company-management">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY,
} from "@/store/CompanyManagement/types";

export default {
  name: "CompanyManagement",
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  mounted() {
    !this.company?.defaultUserBudgetCurrency && this.fetchCompany();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompany: GET_COMPANY,
    }),
    fetchCompany() {
      this.getCompany().catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>
