export default {
  computed: {
    isCompanyAddress() {
      return (
        (this.prItemsPresent && this.firstPrItemAddress) ||
        this.purchaseRequest?.address ||
        this.product?.companyAddress
      );
    },
    isInvoiceAddress() {
      return (
        (this.prItemsPresent && this.firstPrItem?.invoiceAddress) ||
        this.product?.invoiceAddress
      );
    },
    firstPrItem() {
      return this.purchaseRequest?.items[0];
    },
    prItemsPresent() {
      return !!this.firstPrItem;
    },
    firstPrItemAddress() {
      return this.purchaseRequest?.items[0]?.companyAddress;
    },
    productCompanyAddress() {
      return this.product?.companyAddress;
    },
    prAddress() {
      return (
        this.purchaseRequest?.companyAddress || this.purchaseRequest?.address
      );
    },
    invoiceAddressId() {
      return this.firstPrItem?.invoiceAddress || this.product?.invoiceAddress;
    },
    companyAddressId() {
      return (
        this.firstPrItemAddress || this.productCompanyAddress || this.prAddress
      );
    },
  },
};
