<template>
  <div v-if="supplier" class="custom-card no-shadow">
    <p class="section-title mb-4">Supplier</p>
    <div class="-m-5 mt-2">
      <table class="table narrow">
        <thead class="table-header">
          <tr>
            <th
              v-for="(columnName, index) in companySuppliersTableColumns()"
              :key="index"
              scope="col"
            >
              <div class="flex items-center">{{ columnName }}</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="group">
            <td>
              <div class="flex items-center">
                <div
                  class="title group-hover:text-primary cursor-pointer"
                  @click="onSupplierClick"
                >
                  <SupplierName :supplier="supplier.id" />
                </div>
              </div>
            </td>
            <td>
              <p class="text-left">
                {{ supplier.supplierNumber || "-" }}
              </p>
            </td>
            <td>
              <div class="text-left truncate text-sm">
                <Category :categories="supplierCategories" :squashed="true" />
              </div>
            </td>
            <td>
              <div class="text-left">
                <UserWithAvatar
                  :user="supplier.responsibleUser"
                  :show-name="true"
                  :avatar-dimension="6"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Category, SupplierName, UserWithAvatar } from "@/components/shared";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  SET_COMPANY_SUPPLIERS_LIST_FILTERS,
} from "@/store/CompanyManagement/types";

export default {
  name: "CompanySupplier",
  components: {
    Category,
    UserWithAvatar,
    SupplierName,
  },
  props: {
    companySupplier: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companySuppliersListFilters"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCompanySupplier"]),
    supplier() {
      return this.getCompanySupplier(this.companySupplier);
    },
    supplierCategories() {
      return this.supplier.categories.map((category) => category.id);
    },
  },
  methods: {
    ...mapMutations(COMPANY_MANAGEMENT_MODULE, {
      setCompanySuppliersListFilters: SET_COMPANY_SUPPLIERS_LIST_FILTERS,
    }),
    companySuppliersTableColumns() {
      return [
        this.$t(
          "companyManagement.companySupplier.tableHeadingCompanySupplier"
        ),
        this.$t("companyManagement.companySupplier.tableHeadingSupplierNumber"),
        this.$t("companyManagement.companySupplier.tableHeadingCategories"),
        this.$t("companyManagement.companySupplier.addModal.responsibleUser"),
      ];
    },
    onSupplierClick() {
      const filtersPayload = Object.assign(
        {},
        this.companySuppliersListFilters,
        { search: this.supplier.name }
      );
      this.setCompanySuppliersListFilters(filtersPayload);
      this.$router.push({ name: "CompanySuppliers" });
    },
  },
};
</script>

<style scoped>
.page-content .content-left .table td {
  @apply px-0 text-sm;
}
.page-content .content-left .table td:nth-child(2) {
  @apply font-semibold;
}

.page-content .content-left .table tr th,
.page-content .content-left .table tr td {
  @apply px-2;
}
.page-content .content-left .table tr th:nth-child(1) {
  @apply w-[70px];
}

.page-content .content-left .table tr th:nth-child(3) {
  @apply w-[85px];
}

.page-content .content-left .table tr th:nth-child(5),
.page-content .content-left .table tr th:nth-child(6) {
  width: 90px;
}
</style>
