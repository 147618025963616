<template>
  <div class="relative grid bg-white mt-1">
    <template v-if="invoice.invoicePurchaseOrders.length">
      <div class="flex pb-1 items-center justify-center">
        <span class="text-sm font-medium text-gray-600">{{
          $t("invoice.deviation.base")
        }}</span>
        <DifferenceIndicator
          :expected-amount="invoicePoAmount()"
          :actual-amount="invoiceAmount.amount"
          :show-total="true"
        />
      </div>
      <section
        v-for="invoicePurchaseOrder in invoice.invoicePurchaseOrders"
        :key="invoicePurchaseOrder.id"
        class="relative group flex gap-x-4 hover:bg-gray-50 transition ease-in-out duration-150 items-center mb-4"
      >
        <div class="w-2/3">
          <p class="text-base font-medium text-gray-900 line-clamp-2">
            {{ attrValue(invoicePurchaseOrder) }}
          </p>
          <span
            >{{
              invoicePurchaseOrder?.purchaseOrder?.purchaseRequest?.externalId
            }}
            /
          </span>
          <span class="text-sm font-normal text-gray-600 mt-0.5">
            <i18n-n
              :value="parseFloat(invoicePurchaseOrder.purchaseOrder.netAmount)"
              format="currency"
              :locale="
                currencyToLocale(
                  invoicePurchaseOrder.purchaseOrder.netAmountCurrency
                )
              "
            />
          </span>
        </div>
        <div class="flex-none">
          <StatusTag
            type="invoice"
            :status="invoicePurchaseOrder.status"
            size="small"
          />
        </div>
        <div class="flex items-center justify-self-end">
          <template
            v-if="
              !isInvoiceComplete &&
              ['O', 'N', 'NI', 'RV'].includes(invoicePurchaseOrder.status)
            "
          >
            <div
              class="rounded-full hover:bg-gray-200 flex place-items-center p-2 cursor-pointer"
              @click="
                () => {
                  editPoRelation(invoicePurchaseOrder);
                }
              "
            >
              <PencilAltIcon
                class="w-4 h-4 group-hover:text-hivebuy-green text-gray-400"
              />
            </div>
            <div
              class="rounded-full hover:bg-gray-200 flex place-items-center p-2 cursor-pointer"
            >
              <ConfirmTip
                :text="$t('invoice.confirmation.unAssignPurchaseOrder')"
                :confirm-modal="
                  () => onUnAssignPurchaseOrder(invoicePurchaseOrder.id)
                "
              >
                <template #placeholder>
                  <XCircleIcon
                    class="w-4 h-4 group-hover:text-red-500 text-gray-400"
                  />
                </template>
              </ConfirmTip>
            </div>
          </template>

          <div
            class="rounded-full hover:bg-gray-200 flex place-items-center p-2 cursor-pointer"
            @click="
              () => {
                goToInvoice(invoicePurchaseOrder);
              }
            "
          >
            <ChevronRightIcon
              class="w-5 h-5 group-hover:text-primary text-gray-400"
            />
          </div>
          <div
            class="hidden rounded-full hover:bg-gray-200 place-items-center p-2 cursor-pointer group"
            @click="
              () => {
                deletePoRelation(
                  invoicePurchaseOrder.invoice.id,
                  invoicePurchaseOrder.purchaseOrder.id,
                  invoicePurchaseOrder.id
                );
              }
            "
          >
            <TrashIcon class="w-5 h-5 cursor-pointer text-hivebuy-red" />
          </div>
        </div>
      </section>
    </template>
    <p v-else>No purchase orders assigned to this invoice</p>
    <section class="list-item text-sm bg-gray-100">
      {{ $t("invoice.purchaseOrderTotal") }}:
      <i18n-n
        :value="parseFloat(invoicePoAmount())"
        format="currency"
        :locale="
          currencyToLocale(
            invoice.invoicePurchaseOrders[0].purchaseOrder.netAmountCurrency
          )
        "
      />
      <span v-if="invoiceAmount && invoiceAmount.amount">
        {{ $t("invoice.invoiceTotal") }}:
        <i18n-n
          :value="parseFloat(invoiceAmount.amount)"
          format="currency"
          :locale="currencyToLocale(invoiceAmount.currency)"
        />
      </span>
    </section>
  </div>
</template>

<script>
import {
  TrashIcon,
  PencilAltIcon,
  ChevronRightIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";
import { currencyToLocale } from "@/utils/utility_methods.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import {
  INVOICE_MODULE,
  DELETE_INVOICE,
  DELETE_INVOICE_PO_RELATION,
  UNASSIGN_PURCHASE_ORDER,
} from "@/store/Invoice/types";

import { PURCHASE_ORDER_MODULE } from "@/store/PurchaseOrder/types";
import { DifferenceIndicator, ConfirmTip } from "@/components/shared";
import StatusTag from "@/components/shared/StatusTag";

export default {
  components: {
    StatusTag,
    TrashIcon,
    PencilAltIcon,
    DifferenceIndicator,
    ChevronRightIcon,
    XCircleIcon,
    ConfirmTip,
  },

  props: {
    invoice: { type: Object, required: true },
    selectInvoicePoForEdit: { type: Function, default: () => {} },
    toggleModal: { type: Function, default: () => {} },
    setVisibleState: { type: Function, default: () => {} },
  },
  data() {
    return { loading: {} };
  },
  computed: {
    invoiceAmount() {
      return {
        amount: this.invoice.totalNetAmount,
        currency: this.invoice.totalNetAmountCurrency,
      };
    },
    isInvoiceComplete() {
      return this.invoice.status == "A";
    },
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      deletePO: DELETE_INVOICE,
      unAssignPurchaseOrder: UNASSIGN_PURCHASE_ORDER,
    }),
    ...mapMutations(INVOICE_MODULE, {
      deleteInvoicePoRelation: DELETE_INVOICE_PO_RELATION,
    }),
    ...mapGetters(PURCHASE_ORDER_MODULE, ["getPurchaseOrder"]),
    attrValue(invoicePurchaseOrder, attr = "title") {
      if (invoicePurchaseOrder?.purchaseOrder?.purchaseRequest) {
        return invoicePurchaseOrder.purchaseOrder.purchaseRequest[attr];
      } else if (invoicePurchaseOrder?.purchaseOrder?.length) {
        return this.getPOObjectDetails(invoicePurchaseOrder.purchaseOrder)
          .purchaseRequest[attr];
      } else {
        return "-";
      }
    },
    externalId(invoicePurchaseOrder) {
      return invoicePurchaseOrder.purchaseOrder.purchaseRequest.externalId;
    },
    currencyToLocale,
    editPoRelation(invoicePurchaseOrder) {
      if (this.invoice.status == "A" || this.invoice.complete) {
        this.showNotification(
          "This invoice is complete so it can not be deleted",
          "error"
        );
        return;
      }
      this.selectInvoicePoForEdit(invoicePurchaseOrder);
      this.toggleModal();
    },
    deletePoRelation(invoiceId, poId, id) {
      if (this.invoice.status == "A" || this.invoice.complete) {
        this.showNotification(
          "This invoice is complete so it can not be deleted",
          "error"
        );
        return;
      }
      this.deletePO({ invoiceId, poId })
        .then(() => {
          this.showNotification("Relation deleted successfully");
          this.deleteInvoicePoRelation({ id, invoiceId });
        })
        .catch((error) => {
          this.showErrorMessage(error);
        });
    },
    getPOObjectDetails(purchaseOrder) {
      const purchaseOrderObject = purchaseOrder.length
        ? this.getPurchaseOrder.call()(purchaseOrder)
        : purchaseOrder;
      return purchaseOrderObject;
    },
    invoicePoAmount() {
      if (!this.invoice.invoicePurchaseOrders.length) return 0;
      return this.invoice.invoicePurchaseOrders
        .map((invoicePO) => {
          const purchaseOrderObject = this.getPOObjectDetails(
            invoicePO.purchaseOrder
          );
          return purchaseOrderObject.netAmount;
        })
        .reduce(
          (accumulatedValue, current) =>
            parseFloat(accumulatedValue) + parseFloat(current)
        );
    },
    goToInvoice(invoicePurchaseOrder) {
      const poObject = this.getPOObjectDetails(
        invoicePurchaseOrder.purchaseOrder
      );
      const invoiceId = invoicePurchaseOrder.invoice?.id
        ? invoicePurchaseOrder.invoice.id
        : invoicePurchaseOrder.invoice;
      this.$router.push({
        name: "InvoiceDecision",
        params: {
          invoiceId: invoiceId,
        },
        query: { poId: poObject.id },
      });
    },
    onUnAssignPurchaseOrder(invoicePurchaseOrderId) {
      this.loading[invoicePurchaseOrderId] = true;
      this.unAssignPurchaseOrder(invoicePurchaseOrderId)
        .then(() => this.toggleModal())
        .finally(() => (this.loading[invoicePurchaseOrderId] = false));
    },
  },
};
</script>

<style scoped>
.list-item {
  @apply p-4 hover:bg-gray-50 transition ease-in-out duration-150 flex justify-between mb-4 last:mb-0;
}
</style>
