<template>
  <div v-if="isBuyer" class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12 custom-card no-shadow">
      <div class="section-title">Cost Saving</div>

      <div class="mt-2 space-y-4 md:space-y-8 mb-4">
        <div class="flex gap-x-4">
          <SavingField
            :label="'saving'"
            :model-value="payload.saving"
            :on-attr-change="onAttrChange"
            :purchase-request="purchaseRequest"
          />
          <ArrowRightIcon class="h-6 w-6 self-center" />
          <div
            class="flex items-center border content-center px-6 gap-x-2 rounded-lg min-w-[130px] font-semibold text-gray-700"
            :class="
              costSaving > 0
                ? 'bg-hivebuy-green/10 border-hivebuy-green'
                : 'border-gray-200 bg-gray-50'
            "
          >
            <i18n-n
              :value="costSaving"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </div>
        </div>
        <div class="flex mt-2 gap-x-4">
          <SavingField
            :label="'avoidance'"
            :model-value="payload.avoidance"
            :on-attr-change="onAttrChange"
            :purchase-request="purchaseRequest"
          />
          <ArrowRightIcon class="h-6 w-6 self-center" />
          <div
            class="flex items-center border content-center px-6 gap-x-2 rounded-lg min-w-[130px] font-semibold text-gray-700"
            :class="
              costAvoidance > 0
                ? 'bg-hivebuy-green/10 border-hivebuy-green'
                : 'border-gray-200 bg-gray-50'
            "
          >
            <i18n-n
              :value="costAvoidance"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </div>
        </div>
      </div>
      <Button :on-click-handler="save" :loading="loading">Save</Button>
    </div>
  </div>
</template>
<script>
import { ArrowRightIcon } from "@heroicons/vue/solid";
import SavingField from "@/components/PurchaseRequest/savingField.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  PURCHASE_REQUEST_MODULE,
  SET_COST_SAVING,
} from "@/store/PurchaseRequest/types.js";
import { mapState, mapActions } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods";
import { Button } from "@/components/shared/index.js";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  name: "CostSaving",
  components: { SavingField, Button, ArrowRightIcon },

  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      payload: {
        saving: "0",
        avoidance: "0",
      },
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapState(AUTH_MODULE, ["user"]),
    isBuyer() {
      return this.user.roles.includes("BU");
    },
    costAvoidance() {
      if (this.payload.avoidance) {
        return parseFloat(this.payload.avoidance);
      } else return 0;
    },
    costSaving() {
      if (this.payload.saving) {
        return parseFloat(this.payload.saving);
      } else return 0;
    },
  },
  watch: {
    purchaseRequest: {
      immediate: true,
      deep: true,
      handler(pr) {
        const costSavingData = pr?.costSavings[pr.costSavings?.length - 1];
        if (!costSavingData) return;
        this.payload.saving = costSavingData?.costSaving;
        this.payload.avoidance = costSavingData?.costAvoidance;
      },
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      setCostSaving: SET_COST_SAVING,
    }),
    currencyToLocale,
    onAttrChange(payload) {
      const { name, value } = payload;
      this.payload[name] = value.toString();
    },

    save() {
      const payload = {
        purchase_request: this.purchaseRequest.id,
        setBy: this.user.id,
        costSaving: parseFloat(this.payload.saving).toFixed(
          this.backendDecimalPoint
        ),
        costAvoidance: parseFloat(this.payload.avoidance).toFixed(
          this.backendDecimalPoint
        ),
      };
      this.loading = true;
      this.setCostSaving({
        payload,
        costSaving:
          this.purchaseRequest.costSavings[
            this.purchaseRequest.costSavings.length - 1
          ],
      })
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
