<template>
  <HivebuyLoader v-if="isApiLoading('projects')" class="h-8 w-8" />
  <div v-else>
    <MultiselectWithLazyLoading
      v-if="optionsList.length"
      ref="project-multiselect"
      v-model="project"
      :can-clear="canClear"
      :create-option="createOption"
      :filter-results="!shouldExecuteProjectPaginationApi"
      :hide-selected="hideSelected"
      :label="label"
      :loading="isLoading"
      :mode="mode"
      :object="object"
      :on-create="onCreateProject"
      :options="filteredProjects"
      :placeholder="placeholder"
      :searchable="true"
      :show-options="!isLoading"
      :track-by="object ? undefined : trackBy"
      :value-prop="'id'"
      class="w-full bg-white"
      name="project"
      @change="onProjectChange"
      @search-change="setSearch"
      @open="() => handleOpen('project-multiselect')"
      @close="handleClose()"
    >
      <template v-if="createOption" #createOption>
        <div class="p-2 text-sm font-light text-gray-400">
          {{ $t("projects.newProjectCreateText") }}
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label">
          <ProjectSVG class="h-6 w-6 mr-2 text-primary" />
          {{ value.name }}
        </div>
      </template>
      <template #nooptions>
        <div class="px-2 py-1 text-base">
          {{
            $t("emptyStates.multiSelectEmpty", {
              type: $tc("global.project", 2),
            })
          }}
        </div>
      </template>
    </MultiselectWithLazyLoading>
  </div>
</template>

<script>
import {
  HivebuyLoader,
  MultiselectWithLazyLoading,
} from "@/components/shared/index";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  GET_PROJECT,
  GET_PROJECTS,
  PROJECT_MODULE,
} from "@/store/Project/types";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import projectsMixin from "@/components/mixins/projectsMixin";
import multiselectLazyLoadingMixin from "@/components/mixins/multiselectLazyLoadingMixin";
import { isObjectEmpty, removeKeysFromObject } from "@/utils/utility_methods";

export default {
  name: "ProjectSelect",
  components: { ProjectSVG, HivebuyLoader, MultiselectWithLazyLoading },
  mixins: [projectsMixin, multiselectLazyLoadingMixin],
  props: {
    modelValue: {
      type: [String, Array, Object],
      required: true,
    },
    mode: {
      type: String,
      default: "single",
    },
    label: {
      type: String,
      default: "name",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    object: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select Project",
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    newProjectLoading: {
      type: Boolean,
      default: false,
    },

    createOption: {
      type: Boolean,
      default: false,
    },
    onCreateProject: {
      type: Function,
      default: () => {},
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      loading: false,
      optionsList: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters(PROJECT_MODULE, ["projectsList"]),
    ...mapState(PROJECT_MODULE, ["projects"]),
    project: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    filteredProjects() {
      return this.optionsList.filter((project) =>
        project.name.toLowerCase().includes(this.search)
      );
    },
    isLoading() {
      return (
        this.newProjectLoading ||
        this.isApiLoading("projectsDropDownList") ||
        this.loading
      );
    },
  },
  watch: {
    projects: {
      async handler() {
        if (!this.projectsList()?.length) {
          this.getProjects();
        } else {
          await this.updateOptionsList();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(PROJECT_MODULE, {
      getProject: GET_PROJECT,
      getProjects: GET_PROJECTS,
    }),
    onProjectChange(value, { name }) {
      this.$emit("change", value, { name });
    },
    setSearch(query) {
      this.executeDebounceSearch(this.onSearchProject, {
        key: "search",
        value: query.toLowerCase(),
      });
    },
    async updateOptionsList() {
      if (this.loading) return;

      try {
        const optionsSet = new Set(this.projectsList());

        if (!isObjectEmpty(this.modelValue)) {
          this.loading = true;
          const projectIds = Array.isArray(this.modelValue)
            ? this.modelValue.map((v) => v?.id || v)
            : [this.modelValue?.id || this.modelValue].filter((v) => v);

          const modelValueProjects = await Promise.all(
            projectIds.map((projectId) => this.getProject(projectId))
          );

          modelValueProjects.forEach((project) => optionsSet.add(project));
        }

        this.optionsList = Array.from(optionsSet);
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      const { totalDbRecords, page, pageSize } = this.projectsListFilters;

      if (
        this.optionsList.length >= totalDbRecords ||
        page * pageSize >= totalDbRecords
      ) {
        return;
      }

      const paginationParams = {
        ...removeKeysFromObject(this.projectsListFilters, ["totalDbRecords"]),
        page: page + 1,
      };

      await this.getProjects(paginationParams);
    },
  },
};
</script>
