// Module
export const PURCHASE_ORDER_MODULE = "PURCHASE_ORDER_MODULE";

// Actions
export const GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS";
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER";
export const GET_ACTION_REQUIRED_PURCHASE_ORDERS =
  "GET_ACTION_REQUIRED_PURCHASE_ORDERS";
export const UPDATE_PURCHASE_ORDER = "UPDATE_PURCHASE_ORDER";
export const RESEND_WEBHOOK = "RESEND_WEBHOOK";
export const GET_SUPPLIER_ORDERS = "GET_SUPPLIER_ORDERS";
export const GET_SUPPLIER_ORDER = "GET_SUPPLIER_ORDER";
export const RESEND_SUPPLIER_ORDER = "RESEND_SUPPLIER_ORDER";
export const DOWNLOAD_PURCHASE_ORDERS = "DOWNLOAD_PURCHASE_ORDERS";

//Mutations
export const SET_PURCHASE_ORDERS = "SET_PURCHASE_ORDERS";
export const SET_PURCHASE_ORDERS_LIST_FILTERS =
  "SET_PURCHASE_ORDERS_LIST_FILTERS";
export const SET_ACTION_REQUIRED_PURCHASE_ORDERS =
  "SET_ACTION_REQUIRED_PURCHASE_ORDERS";
export const LIVE_UPDATE_ORDER_REQUEST = "LIVE_UPDATE_ORDER_REQUEST";
export const SET_PURCHASE_ORDERS_LIST_PARAMS =
  "SET_PURCHASE_ORDERS_LIST_PARAMS";

export const SET_SUPPLIER_ORDERS = "SET_SUPPLIER_ORDERS";
export const SET_SUPPLIER_LIST_PARAMS = "SET_SUPPLIER_LIST_PARAMS";
export const SET_SUPPLIER_ORDER = "SET_SUPPLIER_ORDER";
