import store from "@/store";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

import { i18n } from "@/i18n";
const $t = i18n.global.t;

const LINE_ITEM_TYPES = [
  {
    label: $t("invoice.form.invoiceItemType.item"),
    value: "item",
  },
  {
    label: $t("invoice.form.invoiceItemType.shipping"),
    value: "shipping",
  },
  {
    label: $t("invoice.form.invoiceItemType.discount"),
    value: "discount",
  },
];

const inputProps = (type, options = []) => {
  switch (type) {
    case "select":
      return {
        inputType: "select",
        props: {
          filter: false,
          options: options,
        },
      };
    case "number":
      return { inputType: "number" };
    default:
      return { inputType: "text" };
  }
};
export const tableColumns = [
  { field: "position", header: "position" },
  {
    field: "type",
    header: "type",
    inputConfig: inputProps("select", LINE_ITEM_TYPES),
  },
  { field: "article", header: "article", inputConfig: inputProps("text") },
  {
    field: "title",
    header: "title",
    inputConfig: inputProps("text"),
  },
  {
    field: "quantity",
    header: "quantity",
    inputConfig: inputProps("number"),
  },
  {
    field: "netAmount",
    header: "netAmount",
    inputConfig: inputProps("number"),
  },
  {
    field: "taxCode",
    header: "taxCode",
    inputConfig: inputProps(
      "select",
      store.getters[`${COMPANY_MANAGEMENT_MODULE}/getTaxCodesOptions`]()
    ),
  },
  { field: "vat", header: "vat", inputConfig: inputProps("number") },
  { field: "tax", header: "tax", inputConfig: inputProps("number") },
  {
    field: "grossAmount",
    header: "grossAmount",
    inputConfig: inputProps("number"),
  },
  {
    field: "shipping",
    header: "shipping",
    inputConfig: inputProps("number"),
  },
  {
    field: "discount",
    header: "discount",
    inputConfig: inputProps("number"),
  },
  {
    field: "totalAmount",
    header: "totalAmount",
    inputConfig: inputProps("number"),
  },
  {
    field: "exchangeRate",
    header: "exchangeRate",
    inputConfig: inputProps("number"),
  },
  {
    field: "costCenter",
    header: "costCenter",
    inputConfig: inputProps("text"),
  },
  {
    field: "glAccount",
    header: "glAccount",
    inputConfig: inputProps("text"),
  },
];

export const INVOICE_LINE_ITEMS_CONFIG = {
  type: {
    discount: {
      disabled: [
        "discount",
        "discountCurrency",
        "shipping",
        "shippingCurrency",
        "shippingGross",
        "shippingGrossCurrency",
        "shippingNet",
        "shippingNetCurrency",
      ],
      required: [],
    },
    shipping: {
      disabled: [
        "discount",
        "discountCurrency",
        "shipping",
        "shippingCurrency",
        "shippingGross",
        "shippingGrossCurrency",
        "shippingNet",
        "shippingNetCurrency",
      ],
      required: [],
    },
  },
};
