<template>
  <div class="col-span-1 flex shadow-sm rounded-md mb-10">
    <div
      class="flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-sm font-medium rounded-l-md"
      :class="[isInBudget ? 'bg-hivebuy-green' : 'bg-hivebuy-red']"
    >
      <ThumbUpIcon v-if="isInBudget" class="w-8 h-8 text-white" />
      <ThumbDownIcon v-else class="w-8 h-8 text-white" />
    </div>
    <div
      class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 bg-white rounded-r-md"
    >
      <div class="flex-1 px-4 py-2 text-sm font-medium">
        <p v-if="isInBudget" class="text-gray-700 py-3 block">
          {{ $t("charts.budgets.inBudget") }}
        </p>
        <p v-else class="text-gray-700 py-3 block">
          {{ $t("charts.budgets.outOfBudget") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ThumbDownIcon, ThumbUpIcon } from "@heroicons/vue/solid";
export default {
  name: "BudgetDisplay",
  components: {
    ThumbUpIcon,
    ThumbDownIcon,
  },
  props: {
    isInBudget: {
      type: Boolean,
      required: true,
    },
    purchaseOrder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
