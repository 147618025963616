<template>
  <tippy
    :theme="theme"
    :placement="placement"
    :trigger="triggerOrHide"
    :animation="animation"
    :max-width="500"
    :delay="delay"
    :arrow="arrow"
    :interactive="interactive"
    :hide-on-click="hideOnClick"
    @state="onStateChange"
  >
    <slot name="placeholder">
      <div v-if="help" class="inline-flex">
        <QuestionMarkCircleIcon
          class="h-5 w-5 text-gray-400 hover:cursor-help hover:text-primary"
        />
      </div>
      <template v-else>{{ text }}</template>
    </slot>
    <template #content="{ hide }">
      <div :class="theme">
        <template v-if="showHeader">
          <div :class="`${theme}-header`">
            <slot name="header">-HeaderSlot-</slot>
          </div>
        </template>
        <div :class="`${theme}-content`">
          <slot name="content">-ContentSlot-</slot>
        </div>
      </div>
      <div v-if="showFooter" class="mt-5 sm:mt-4 sm:flex justify-center">
        <Button
          class="mr-3"
          type="button"
          :color="'gray'"
          :on-click-handler="() => hide()"
          full
        >
          {{ $t("buttons.cancel") }}
        </Button>
        <Button
          type="button"
          full
          :on-click-handler="
            () => {
              saveAction();
              hide();
            }
          "
        >
          {{ $t("buttons.confirm") }}
        </Button>
      </div>
    </template>
  </tippy>
</template>
<script>
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import Button from "@/components/shared/Button.vue";
export default {
  components: {
    QuestionMarkCircleIcon,
    Button,
  },
  props: {
    theme: { type: String, default: "time" },
    placement: { type: String, default: "auto" },
    trigger: { type: String, default: "mouseenter focus" },
    text: { type: String, default: "-PlaceholderSlot-" },
    help: { type: Boolean, default: false },
    arrow: { type: Boolean, default: true },
    visible: { type: Boolean, default: true },
    interactive: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideOnClick: { type: Boolean, default: true },
    onStateChange: { type: Function, default: () => {} },
    saveAction: { type: Function, default: () => {} },
    showFooter: { type: Boolean, default: false },
  },
  computed: {
    animation() {
      if (this.theme === "tooltip") {
        return "shift-toward";
      }
      return "scale";
    },

    showHeader() {
      if (this.theme === "tooltip") {
        return true;
      }
      return false;
    },

    delay() {
      if (this.theme === "tooltip") {
        return [200, 100];
      }
      return [50, 50];
    },
    triggerOrHide() {
      return this.disabled ? "manual" : this.trigger;
    },
  },
};
</script>
<style>
[data-v-tippy] {
  @apply inline-flex items-center;
}

/* Time theme */
.tippy-box[data-theme~="time"] {
  @apply bg-white text-gray-700 p-1 border-solid border-gray-300 text-sm font-semibold;
  box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tippy-box[data-theme~="time"][data-placement^="top"] > .tippy-arrow::before {
  @apply border-t-white;
}
.tippy-box[data-theme~="time"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-b-white;
}
.tippy-box[data-theme~="time"][data-placement^="left"] > .tippy-arrow::before {
  @apply border-l-white;
}
.tippy-box[data-theme~="time"][data-placement^="right"] > .tippy-arrow::before {
  @apply border-r-white;
}

/* Tooltip theme */
.tooltip {
  @apply p-1;
}

.tooltip-header {
  @apply flex justify-center p-2 border-b border-b-gray-300 text-base font-medium text-primary;
}

.tooltip-content {
  @apply p-2;
}

.tippy-box[data-theme~="tooltip"] {
  @apply text-sm bg-white text-gray-700 border-solid border-gray-300;
  box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tippy-box[data-theme~="tooltip"] .tippy-content {
  @apply p-0;
}

.tippy-box[data-theme~="tooltip"][data-placement^="top"]
  > .tippy-arrow::before {
  @apply border-t-white;
}
.tippy-box[data-theme~="tooltip"][data-placement^="bottom"]
  > .tippy-arrow::before {
  @apply border-b-white;
}
.tippy-box[data-theme~="tooltip"][data-placement^="left"]
  > .tippy-arrow::before {
  @apply border-l-white;
}
.tippy-box[data-theme~="tooltip"][data-placement^="right"]
  > .tippy-arrow::before {
  @apply border-r-white;
}
</style>
