<template>
  <Modal
    :show-modal="open"
    :close-modal="toggleFunction"
    :prevent-click-outside="true"
  >
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #body>
      <label class="text-gray-500 font-medium text-sm mb-3"
        >Update Comment</label
      >
      <div class="mt-2">
        <Mentionable :keys="['@']" :items="membersToTag" offset="6">
          <textarea
            v-model="value"
            :name="'name'"
            :type="'text'"
            placeholder="Leave Your Comment Here"
            class="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary text-sm mb-6 h-40"
          />
        </Mentionable>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="toggleFunction" :color="'gray'">
        Cancel
      </Button>
      <Button :on-click-handler="confirmSave"> Save </Button>
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import { Button, Modal } from "@/components/shared/index";
import { Mentionable } from "vue-mention";

export default {
  components: {
    Modal,
    CheckIcon,
    Button,
    Mentionable,
  },
  props: {
    comment: {
      type: String,
      required: false,
      default: "",
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: Function,
      required: true,
    },
    membersToTag: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: this.comment,
    };
  },
  methods: {
    confirmSave() {
      this.toggleFunction();
      this.saveMethod(this.value);
    },
  },
};
</script>
