<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserGroupIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-2xl font-medium text-gray-500">
        {{ $t("companyManagement.departments.addDepartmentModal.header") }}
      </h3>
    </template>
    <template #body>
      <div class="mt-6">
        <Input
          id="department_name"
          :error-text="error(v$.departmentName, 'department name')"
          name="departmentName"
          :value="departmentName"
          type="text"
          :placeholder="
            $t(
              'companyManagement.departments.addDepartmentModal.namePlaceholder'
            )
          "
          :label="$t('companyManagement.departments.addDepartmentModal.name')"
          :on-value-change="onAttributeChange"
        />
      </div>
      <div class="mt-6">
        <Input
          id="cost_center"
          name="costCenter"
          :value="costCenter"
          type="text"
          :placeholder="
            $t(
              'companyManagement.departments.addDepartmentModal.costCenterPlaceholder'
            )
          "
          :label="
            $t('companyManagement.departments.addDepartmentModal.costCenter')
          "
          :on-value-change="onAttributeChange"
        />
      </div>
      <div v-if="isFeatureAllowed('legalEntity')" class="mt-10">
        <p class="label">
          {{
            $t("companyManagement.departments.addDepartmentModal.legalTendency")
          }}
        </p>
        <div>
          <Multiselect
            v-model="legalEntity"
            :searchable="true"
            :options="legalTendencyList"
            :placeholder="
              $t(
                'companyManagement.departments.addDepartmentModal.legalTendency'
              )
            "
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            :can-clear="false"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{
          $t("companyManagement.departments.addDepartmentModal.cancelButton")
        }}
      </Button>
      <Button :on-click-handler="saveDepartment">
        {{ $t("companyManagement.departments.addDepartmentModal.saveButton") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  ADD_DEPARTMENT,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import { errorMixin } from "@/components/mixins/index.js";
import { UserGroupIcon } from "@heroicons/vue/solid";
import { Button, Modal, Input } from "@/components/shared/index";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    Modal,
    UserGroupIcon,
    Button,
    Input,
    Multiselect,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      departmentName: "",
      costCenter: "",
      legalEntity: "",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addDepartment: ADD_DEPARTMENT,
    }),
    onAttributeChange(event) {
      const { name, value } = event.target;
      this[name] = value;
    },
    saveDepartment() {
      this.v$.$validate();

      if (!this.v$.$error) {
        const payload = {
          name: this.departmentName,
          costCenter: this.costCenter,
        };

        if (this.isFeatureAllowed("legalEntity")) {
          payload.legalEntity = this.legalEntity;
        }

        this.addDepartment(payload)
          .then(() =>
            this.showNotification(this.$t("toasts.genericCreationSuccess"))
          )
          .catch((error) => this.showErrorMessage(error))
          .finally(() => {
            this.onModalClose();
          });
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.departmentName = "";
        this.legalEntity = "";
        this.costCenter = "";
        this.v$.$reset();
      }, 500);
    },
    showErrorMessage(error) {
      error.errorMessageCode === "departmentsLimitExceeds"
        ? this.showNotification(
            this.$t("errors.companyDepartmentMissing", {
              count: this.getfeatFlagAmount("numberOfDepartments"),
            }),
            "error"
          )
        : this.showErrorMessage(error);
    },
  },

  validations() {
    return {
      departmentName: { required },
    };
  },
};
</script>
