<template>
  <div class="flex">
    <h3 class="page-title">Webhooks</h3>
  </div>
  <div class="custom-card h-screen">
    <div v-if="svixUrl" class="h-full">
      <iframe :src="svixUrl" frameborder="0" class="w-full h-full"></iframe>
    </div>
    <div v-else class="text-gray-600 text-sm">
      <HivebuyLoader class="h-20" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { HivebuyLoader } from "@/components/shared/index";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATION_URL,
} from "@/store/CompanyManagement/types";
export default {
  components: {
    HivebuyLoader,
  },
  data() {
    return {
      svixUrl: null,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["integrations"]),
  },
  watch: {
    integrations: {
      immediate: true,
      deep: true,
      handler(val) {
        const integration = val.find(
          (integration) => integration.name === "Webhooks"
        );
        if (integration) {
          this.getSvixUrl(integration.id).then(
            (response) => (this.svixUrl = response.data.url)
          );
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getSvixUrl: GET_INTEGRATION_URL,
    }),
  },
};
</script>
