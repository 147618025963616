<template>
  <div
    class="py-2 rounded-md w-full shadow-none text-sm flex justify-end gap-x-2"
  >
    <BarButton
      v-if="showOrderButton && !itemsAutoProcessing"
      show-icon
      :on-click-handler="markAsOrdered"
      :disabled="status?.canceled"
    >
      <template #icon>
        <OrderSVG />
      </template>
      {{ $t("buttons.purchaseOrderButtons.markOrdered") }}
    </BarButton>
    <BarButton
      v-if="showDeliverButton"
      :disabled="!isDeliverButtonEnabled"
      show-icon
      :on-click-handler="markAsDelivered"
    >
      <template #icon>
        <DeliverSVG />
      </template>
      {{ $t("buttons.purchaseOrderButtons.markDelivered") }}
    </BarButton>
    <BarButton
      show-icon
      :disabled="!isInvoiceButtonEnabled"
      :on-click-handler="uploadInvoice"
    >
      <template #icon>
        <InvoiceSVG />
      </template>
      {{ $t("buttons.purchaseOrderButtons.uploadInvoice") }}
    </BarButton>
  </div>
</template>

<script>
import BarButton from "@/components/shared/BarButton.vue";
import OrderSVG from "@/assets/images/OrderSVG.vue";
import DeliverSVG from "@/assets/images/DeliverSVG.vue";
import InvoiceSVG from "@/assets/images/InvoiceSVG.vue";
import poStatusMixin from "@/components/mixins/poStatusMixin.js";
export default {
  components: {
    BarButton,
    OrderSVG,
    DeliverSVG,
    InvoiceSVG,
  },
  mixins: [poStatusMixin],
  props: {
    purchaseOrder: {
      type: Object,
      required: true,
    },
    items: {
      type: Object,
      default: null,
    },
  },
  emits: ["order", "deliver", "invoice"],
  data() {
    return {
      status: null,
    };
  },
  computed: {
    itemsAutoProcessing() {
      let found = null;
      if (this.items) {
        found = this.items.filter((item) => {
          return item.autoProcessing && item.status != "O";
        });
      }
      return found?.length;
    },
    showDeliverButton() {
      return (
        this.items?.filter(
          (item) =>
            item.requestStatus == "A" && ["O", "PA"].includes(item.status)
        )?.length && !this.status?.delivered
      );
    },
    showOrderButton() {
      return (
        this.items?.filter(
          (item) => item.requestStatus == "A" && ["P"].includes(item.status)
        )?.length && !this.status?.ordered
      );
    },
    isDeliverButtonEnabled() {
      return this.areItemsOrdered && !this.status?.canceled;
    },
    isInvoiceButtonEnabled() {
      return this.areItemsOrdered;
    },
    areItemsOrdered() {
      let found = null;
      if (this.items) {
        found = this.items.filter((item) => {
          if (item.itemStatuses) {
            return item.itemStatuses.find(
              (itemStatus) => itemStatus.status === "O"
            );
          }
        });
      }
      return found?.length || this.status?.ordered;
    },
  },
  watch: {
    purchaseOrder: {
      deep: true,
      handler() {
        this.getStatus();
      },
    },
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    markAsOrdered() {
      this.$emit("order");
    },
    markAsDelivered() {
      this.$emit("deliver");
    },
    uploadInvoice() {
      this.$emit("invoice");
    },
    async getStatus() {
      if (this.purchaseOrder) {
        const status = await this.posSimpleStatus(this.purchaseOrder);
        this.status = status;
      }
    },
  },
};
</script>
