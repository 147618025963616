<template>
  <Transition>
    <div class="custom-card no-shadow relative" :class="{ active: collapsed }">
      <div
        class="arrow-holder group"
        :class="collapsed ? 'rotate-180 !border-primary' : ''"
        @click="toggleCollapsed"
      >
        <ChevronDownIcon
          class="toggle-arrow"
          :class="collapsed ? 'text-primary' : ''"
        />
      </div>
      <div class="product-collapse">
        <div class="product-content">
          <h3>{{ list.title }}</h3>
          <h2 class="font-normal text-sm leading-6 text-gray-600 line-clamp-1">
            {{ list.description }}
          </h2>
        </div>
      </div>
      <div class="flex items-center mt-3 justify-between">
        <div class="flex gap-x-2 items-center">
          <UserWithAvatar
            :user="list.requestedBy"
            :avatar-dimension="6"
            show-tooltip
          />
          <span>
            <template v-if="needByDate">
              <div class="by-date">
                <TruckIcon class="w-4 h-4 text-primary mr-1" />
                {{ formattedDate(needByDate) }}
              </div>
            </template>
          </span>
        </div>

        <div class="btn group">
          <Button
            v-if="list.status != 'C'"
            class="mark-done"
            size-class="sm"
            :loading="loading"
            :on-click-handler="() => selectEditableList(list)"
          >
            <CheckCircleIcon
              class="w-4 h-4 text-primary group-hover:text-primarydark"
            />{{ $t("shoppingList.markAsDone") }}</Button
          >
        </div>
      </div>
      <div class="shopping-form" :class="collapsed ? 'show-form' : 'hide-form'">
        <ul role="list" class="divide-y divide-gray-200 mt-2">
          <li v-for="item in list.items" :key="item.id" class="relative">
            <ListItem :item="item" :list="list" />
          </li>
        </ul>
      </div>
    </div>
  </Transition>
  <ConfirmDialog
    :text="$t('shoppingList.markAsDoneText')"
    :show-modal="showConfirmModal"
    :close-modal="
      () => {
        showConfirmModal = false;
      }
    "
    :title="$t('shoppingList.markAsDone')"
    :item-to-confirm="editableItem?.name"
    :confirm-modal="markAsDone"
  />
</template>
<script>
import { ChevronDownIcon, CheckCircleIcon } from "@heroicons/vue/outline";
import { TruckIcon } from "@heroicons/vue/solid";
import {
  SHOPPING_LIST_MODULE,
  UPDATE_SHOPPING_LIST,
} from "@/store/ShoppingList/types.js";
import { mapActions } from "vuex";
import { ListItem } from "@/components/ShoppingList/index.js";
import {
  Button,
  ConfirmDialog,
  UserWithAvatar,
} from "@/components/shared/index.js";

export default {
  components: {
    ChevronDownIcon,
    CheckCircleIcon,
    UserWithAvatar,
    ListItem,
    TruckIcon,
    Button,
    ConfirmDialog,
  },

  props: { list: { type: Object, required: true } },
  data() {
    return {
      showConfirmModal: false,
      editableItem: null,
      loading: false,
      collapsed: true,
    };
  },
  computed: {
    needByDate() {
      const dates = this.list.items.map((item) => {
        return item.needByDate;
      });
      const maxDate = dates.reduce((first, second) =>
        first > second ? first : second
      );
      return maxDate;
    },
  },

  methods: {
    ...mapActions(SHOPPING_LIST_MODULE, {
      updateShoppingList: UPDATE_SHOPPING_LIST,
    }),
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    selectEditableList(item) {
      this.showConfirmModal = true;
      this.editableItem = item;
    },
    cancelEditableList() {
      this.showConfirmModal = false;
      this.editableItem = null;
    },
    markAsDone() {
      this.loading = true;
      const payload = {
        id: this.editableItem.id,
        data: {
          status: "C",
        },
      };
      this.updateShoppingList(payload)
        .then(() =>
          this.showNotification(
            this.$t("shoppingList.markAsDoneSuccess"),
            "success"
          )
        )
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.custom-card {
  @apply p-3 mb-3;
}

.custom-card.active {
  @apply border-primary shadow-lg;
}

.product-collapse {
  @apply flex w-full justify-between items-center  overflow-hidden transition-all duration-300;
}

.product-collapse .product-content {
  @apply flex gap-x-4  flex-col;
}

.product-collapse .product-content h3 {
  @apply text-base font-semibold text-primary mb-0 w-72 truncate;
}

.product-collapse .product-content .description {
  @apply text-sm text-gray-600 line-clamp-1 flex-1 pr-3;
}

.shopping-form {
  @apply overflow-hidden transition-[max-height] duration-500;
}

.arrow-holder {
  @apply absolute top-3 right-3 bg-white border border-gray-200 w-6 h-6 rounded-full flex justify-center items-center  hover:border-primary z-50 transition-all duration-300 cursor-pointer;
}

.toggle-arrow {
  @apply w-3 h-3 group-hover:text-primary;
}

.hide-product {
  @apply py-0;
  max-height: 0;
}

.show-product {
  @apply py-5;
  max-height: 200px;
}

.show-form {
  max-height: 1500px;
  overflow: visible;
}

.hide-form {
  max-height: 0;
}

.btn {
  @apply flex gap-x-1 items-center;
}

.mark-done {
  @apply bg-transparent p-0 text-primary border-0 group-hover:bg-transparent group-hover:text-primarydark;
}

.by-date {
  @apply text-xs px-2 py-0.5 border border-gray-200 bg-gray-100 rounded-lg max-w-[100px] flex items-center justify-between;
}
</style>
