<template>
  <div class="input-holder">
    <label v-if="label" :for="name" class="label"
      >{{ label }}
      <span v-if="isMandatory" class="text-hivebuy-red ml-0.5">*</span>
    </label>
    <div :class="`relative`">
      <div class="flex" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <input
          :id="fieldId"
          :type="type"
          :name="name"
          autocomplete="off"
          :class="inputClasses()"
          class="focus:outline-none focus:ring-primary focus:border-primary"
          :placeholder="placeholder"
          :value="fieldValue"
          :aria-invalid="errorText"
          :aria-describedby="errorText && `${type}-error`"
          :disabled="disabled"
          @keyup="onKeyUp($event)"
          @change="onValueChange($event)"
          @focusin="onFocusIn"
          @focusout="onFocusOut"
        />

        <div v-if="ocrDetails" class="absolute top-2 right-3">
          <OCRButtonInput
            :ocr-details="ocrDetails"
            :field-value="fieldValue"
            :label="label"
            :on-update-value="updateValue"
          />
        </div>
      </div>
    </div>
    <FieldError v-if="errorText" :text="errorText" />
  </div>
</template>

<script>
import FieldError from "@/components/shared/FieldError.vue";
import { toKebabCase } from "@/utils/utility_methods.js";
import OCRButtonInput from "@/components/shared/OCRButtonInput.vue";
export default {
  components: { FieldError, OCRButtonInput },
  props: {
    errorText: { type: String, required: false, default: null },
    ocrClasses: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: [Number, String],
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    placeholder: {
      type: [Number, String],
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
    onValueChange: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    onFocusIn: {
      type: Function,
      default: () => {},
    },
    onFocusOut: {
      type: Function,
      default: () => {},
    },
    onMouseEnter: {
      type: Function,
      default: () => {},
    },
    onMouseLeave: {
      type: Function,
      default: () => {},
    },
    ocrDetails: {
      type: Object,
      default: null,
    },
  },
  computed: {
    fieldValue() {
      return this.value;
    },
    fieldId() {
      return this.id ? this.id : toKebabCase(this.label);
    },
  },
  methods: {
    inputClasses() {
      let styleClasses = `block w-full border border-gray-200 rounded-md placeholder-gray-400  sm:text-base disabled:bg-gray-100 ${this.ocrClasses}`;
      if (this.errorText) {
        styleClasses = `${styleClasses} focus:border-red-400 border-red-400`;
      }
      return styleClasses;
    },
    updateValue(value) {
      this.onValueChange({
        name: this.name,
        value: value,
      });
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
