<template>
  <div class="select-supplier">
    <div class="supplier-holder">
      <div
        v-if="isApiLoading('companySuppliersSearch')"
        class="flex items-center mt-4"
      >
        <HivebuyLoader class="h-20 w-20" />
      </div>
      <div v-else v-auto-animate class="supplier-top">
        <div class="flex justify-between mb-2">
          <div class="supplier-button flex items-center gap-x-1">
            <div
              id="addNewCompanySupplier"
              class="inline-flex items-center cursor-pointer justify-center"
              @click="toggleAddCompanySupplierModal"
            >
              <PlusCircleIcon class="h-6 text-primary" />

              <div class="mx-1 font-semibold text-sm text-primary">
                {{ $t("purchaseRequest.otherSupplier") }}
                <span v-if="currentRequest?.supplier?.type == 'unlisted'"
                  >(Current: {{ currentRequest.supplier.details.name }})</span
                >
              </div>
            </div>

            <InformationPopover class="self-center relative z-50">
              <template #header>
                {{ $t("purchaseRequest.whatIsThis") }}
              </template>
              <template #body>
                {{ $t("purchaseRequest.otherSupplierTooltip") }}
              </template>
            </InformationPopover>
          </div>
          <div v-if="showSkip" class="supplier-button">
            <div class="flex items-center">
              <div
                id="skipCompanySupplierSelection"
                class="flex items-center cursor-pointer"
                @click="
                  () => {
                    setRequestSupplier('skip');
                  }
                "
              >
                <p class="mr-2 text-sm text-primary font-semibold">
                  {{ $t("purchaseRequest.skipSupplier") }}
                </p>
              </div>
              <InformationPopover class="">
                <template #header>
                  {{ $t("purchaseRequest.whatIsThis") }}
                </template>
                <template #body>
                  {{ $t("purchaseRequest.skipSupplierTooltip") }}
                </template>
              </InformationPopover>
            </div>
          </div>
        </div>
        <div
          v-if="!showAddUnlistedSupplier"
          id="search"
          v-auto-animate
          class="mb-4 flex justify-start"
        >
          <div class="flex-1 shadow-sm">
            <Input
              id="search"
              :value="search"
              name="search"
              :placeholder="$t('general.search')"
              show-icon
              type="search"
              :disabled="isApiLoading('companySuppliersSearch')"
              :focus="true"
              :on-key-up="
                (event) =>
                  executeDebounceSearch(
                    onSearchUpdate,
                    { key: 'search', value: event.target.value },
                    searchDebounceTimeout
                  )
              "
              @keyup.enter="
                (event) =>
                  executeDebounceSearch(
                    onSearchUpdate,
                    { key: 'search', value: event.target.value },
                    0
                  )
              "
            />
          </div>
        </div>

        <div v-if="showAddUnlistedSupplier">
          <label class="label">{{
            $t("purchaseRequest.otherSupplierModal.heading")
          }}</label>
          <div
            class="grid grid-cols-1 gap-y-6 p-4 rounded border border-gray-200 bg-gray-50"
          >
            <HText
              v-model="unlistedSupplier.name"
              label="Unlisted Supplier Name"
              :placeholder="$t('purchaseRequest.otherSupplierModal.name')"
            />
            <div>
              <label for="reason" class="label">{{
                $t("purchaseRequest.otherSupplierModal.reason")
              }}</label>
              <TextArea
                id="reason"
                :name="'reason'"
                :value="unlistedSupplier.reason"
                :placeholder="
                  $t('purchaseRequest.otherSupplierModal.reasonText')
                "
                :rows="2"
              />
            </div>
          </div>
          <div class="flex justify-between mt-2">
            <Button
              :on-click-handler="() => (showAddUnlistedSupplier = false)"
              :color="'gray'"
              size-class="sm"
            >
              {{ $t("purchaseRequest.goBackToSupplier") }}
            </Button>
            <Button
              :on-click-handler="() => saveSupplier(unlistedSupplier, true)"
              size-class="sm"
            >
              {{ $t("purchaseRequest.otherSupplierModal.continue") }}
            </Button>
          </div>
        </div>
        <div v-else-if="sortedLIst.length" class="card-list overflow-y-scroll">
          <div v-if="isApiLoading('companySuppliersSearch')">
            <div v-for="i in 3" :key="i">
              <div class="supplier-card h-[82px] animate-pulse">
                <div class="icon-holder">
                  <SpinningLoaderSVG class="text-gray-400" />
                </div>
                <div class="supplier-name w-full">
                  <div class="flex items-center h-5 bg-gray-200 rounded"></div>
                  <div
                    class="flex items-center h-5 bg-gray-200 rounded mt-1 w-28"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="filteredCompanySupplier in sortedLIst"
            v-else
            :id="filteredCompanySupplier.id"
            :key="filteredCompanySupplier.id"
            :class="[
              'supplier-card',
              selectedSupplier === filteredCompanySupplier.id ? 'selected' : '',
            ]"
            class="cursor-pointer relative"
            @click="
              () => {
                setRequestSupplier(
                  'companySupplier',
                  filteredCompanySupplier.id
                );
              }
            "
          >
            <div
              class="icon-holder"
              :class="[{ selected: isSelected(filteredCompanySupplier.name) }]"
            >
              <SupplierNewSVG />
            </div>
            <div class="supplier-name">
              <div class="flex items-center">
                {{
                  filteredCompanySupplier.displayName ||
                  filteredCompanySupplier.name
                }}
              </div>
              <span>
                {{ filteredCompanySupplier.website }}
              </span>
            </div>

            <div
              v-if="filteredCompanySupplier.supplierNumber"
              class="supplier-number"
            >
              <div v-if="!compact" class="supplier-number-icon">
                <ListIcon />
              </div>
              <p>
                <span>{{
                  $t(
                    "companyManagement.companySupplier.tableHeadingSupplierNumber"
                  )
                }}</span>
                {{ filteredCompanySupplier.supplierNumber }}
              </p>
            </div>

            <div class="supplier-notes">
              <template v-if="filteredCompanySupplier.notes && !compact">
                <div class="notes-icon"></div>
                <p class="notes hidden xl:line-clamp-3">
                  <span>{{
                    $t("companyManagement.companySupplier.addModal.notes")
                  }}</span>
                  {{ filteredCompanySupplier.notes }}
                </p>
                <div class="xl:hidden">
                  <ListButton
                    v-tippy="{
                      content: filteredCompanySupplier.notes,
                      delay: [50, 50],
                      theme: 'time',
                    }"
                    @click.stop
                  >
                    <template #icon>
                      <DocumentTextIcon class="w-5 h-5" />
                    </template>
                  </ListButton>
                </div>
              </template>
            </div>
            <div class="actions">
              <PreferredIcon
                v-if="filteredCompanySupplier.isPreferred"
                v-tippy="{
                  content: $t(
                    'companyManagement.companySupplier.addModal.preferredSupplier'
                  ),
                  delay: [50, 50],
                  theme: 'time',
                }"
                class="h-5 w-5 text-hivebuy-green ml-3 focus:outline-none"
                @click.stop
              />
              <OrderSVG
                v-if="filteredCompanySupplier.orderViaEmail && !compact"
                v-tippy="{
                  content: $t(
                    'companyManagement.companySupplier.addModal.enableEmailInfoText'
                  ),
                  delay: [50, 50],
                  theme: 'time',
                }"
                class="h-5 w-5 text-gray-400 hover:text-primary focus:outline-none"
                @click.stop
              />
            </div>
          </div>
        </div>

        <div
          v-else
          class="bg-white px-2 py-6 sm:px-3 md:grid md:place-items-center lg:px-4 rounded-md shadow font-normal sm:col-span-2"
        >
          <div>
            <div class="sm:flex">
              <div>
                <div>
                  <h1 class="text-lg text-gray-600 sm:text-base font-medium">
                    {{ $t("purchaseRequest.intro1") }}
                    <span class="font-bold">{{
                      categoryDetails(category)
                    }}</span>
                  </h1>
                  <p v-if="category" class="text-gray-400 text-sm mt-2">
                    {{ $t("purchaseRequest.selectCategorySubtext1") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="supplier-footer"
      :class="{ '-m-4': saveToStore || currentRequest?.supplier }"
    >
      <!-- Back to Category -->
      <div v-if="saveToStore" class="back-to-category">
        <Button
          id="backButtonCategory"
          :on-click-handler="() => setCurrentPage('category')"
          :color="'gray'"
        >
          {{ $t("purchaseRequest.goBack") }}
        </Button>
      </div>

      <!-- Skip -->
    </div>
  </div>
  <NewCompanySupplier
    :open="showAddCompanySupplierModal"
    :toggle-function="toggleAddCompanySupplierModal"
    :set-request-supplier="setRequestSupplier"
    class="z-50"
  />
</template>

<script>
import ListIcon from "@/assets/images/list-icon.vue";
import {
  Button,
  HivebuyLoader,
  InformationPopover,
  Input,
  ListButton,
  TextArea,
} from "@/components/shared/index";
import { PlusCircleIcon, DocumentTextIcon } from "@heroicons/vue/outline";
import { mapState, mapMutations, mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_DATA,
  SET_CURRENT_PAGE,
} from "@/store/PurchaseRequest/types.js";
import NewCompanySupplier from "@/components/PurchaseRequest/NewCompanySupplier.vue";
import OrderSVG from "@/assets/images/OrderSVG.vue";
import suppliersMixin from "@/components/mixins/suppliersMixin";
import PreferredIcon from "@/assets/images/preferred.vue";
import SupplierNewSVG from "@/assets/images/SupplierNewSVG.vue";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import { HText } from "design-buy";
export default {
  name: "SupplierSelection",
  components: {
    HivebuyLoader,
    ListIcon,
    Button,
    InformationPopover,
    Input,
    ListButton,
    NewCompanySupplier,
    PlusCircleIcon,
    DocumentTextIcon,
    OrderSVG,
    PreferredIcon,
    HText,
    TextArea,
    SupplierNewSVG,
    SpinningLoaderSVG,
  },
  mixins: [suppliersMixin],
  props: {
    selectedSupplier: { type: [String, Object], default: null },
    saveSupplier: {
      type: Function,
      default: () => {},
    },
    saveToStore: {
      type: Boolean,
      default: true,
    },
    showSkip: {
      type: Boolean,
      default: true,
    },
    showInlineUnlistedSupplier: {
      type: Boolean,
      default: false,
    },
    unlistedSupplierObject: {
      type: Object,
      default: () => ({}),
    },
    sortByPreferred: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showAddCompanySupplierModal: false,
      showAddUnlistedSupplier: false,
      suppliersList: [],
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryDetails",
      "supplierName",
      "companySetting",
      "getSupplierBySearch",
    ]),
    filteredList() {
      return this.getSupplierBySearch(
        this.suppliersList,
        this.search.toLowerCase(),
        !!this.category
      );
    },
    sortedLIst() {
      const list = this.filteredList;
      if (!this.sortByPreferred) {
        return list;
      } else {
        return list.sort((a, b) => b.isPreferred - a.isPreferred);
      }
    },
  },
  watch: {
    unlistedSupplierObject: {
      immediate: true,
      handler(value) {
        this.unlistedSupplier = value;
        this.showAddUnlistedSupplier = !!value.name;
      },
    },
  },
  async mounted() {
    const category = this.currentRequest?.category || this.category;
    if (!this.companySetting("NO_SUPPLIER_CATEGORY")?.active && category) {
      this.setApiLoading({ key: "companySuppliersSearch", value: true });
      this.suppliersList = await this.getSuppliersByCategory(category);
      this.setApiLoading({ key: "companySuppliersSearch", value: false });
    }
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCurrentRequestData: SET_CURRENT_REQUEST_DATA,
      setCurrentPage: SET_CURRENT_PAGE,
    }),
    setRequestSupplier(type, details) {
      const payload = { type, details };
      if (this.saveToStore)
        this.setCurrentRequestData({
          field: "supplier",
          value: payload,
          redirectPage: "items",
        });
      else {
        this.saveSupplier(payload);
      }
    },
    toggleAddCompanySupplierModal() {
      if (this.showInlineUnlistedSupplier) {
        this.showAddUnlistedSupplier = !this.showAddUnlistedSupplier;
      } else {
        this.showAddCompanySupplierModal = !this.showAddCompanySupplierModal;
      }
    },
    isSelected(companySupplierName) {
      const supplier = this.currentRequest.supplier;
      if (!supplier || supplier.type != "companySupplier") return false;

      return this.supplierName(supplier.details) == companySupplierName;
    },
  },
};
</script>

<style scoped>
.select-supplier {
  @apply flex flex-col h-full w-full;
}
.supplier-card {
  @apply relative border border-gray-200 text-left px-3 py-4 flex items-center rounded-md shadow hover:border-gray-300 hover:bg-gray-50 transition mb-2;
}

.supplier-card .icon-holder {
  @apply border border-gray-200 rounded-full w-[48px] h-[48px] p-3 shrink-0;
}

.supplier-card .supplier-name {
  @apply w-[250px] ml-3 text-primary text-sm font-semibold;
}
.supplier-card .supplier-name span {
  @apply text-xs block font-normal text-gray-500;
}

.supplier-card .supplier-number {
  @apply flex gap-x-2 justify-center items-center mr-10;
}

.supplier-card .supplier-number p {
  @apply text-gray-600 text-sm;
}
.supplier-card .supplier-number p span {
  @apply text-gray-500 block text-xs;
}

.supplier-card .supplier-notes {
  @apply flex items-center flex-1 mr-4;
}

.supplier-card .supplier-notes .notes span {
  @apply block text-xs text-gray-500;
}

.supplier-card .supplier-notes .notes {
  @apply ml-2 text-sm text-gray-600;
}

.supplier-card .actions {
  @apply flex items-center gap-x-2 md:gap-x-4;
}

.supplier-card.selected {
  @apply bg-white text-primary border-primary;
}
.supplier-card.selected .icon-holder svg {
  @apply text-primary;
}

.custom-card.no-shadow {
  @apply justify-start;
}

.supplier-holder {
  @apply mt-2 flex flex-col flex-1;
}
.supplier-top {
  @apply flex-1 mb-8 flex flex-col;
}

.supplier-top #search {
}

.supplier-top .card-list {
  @apply flex-1 flex flex-col;
}

.supplier-footer {
  @apply flex justify-between items-center px-8 py-3 border-t border-gray-200 bg-white;
}

.supplier-footer .back-to-category .btn {
  @apply border-0 p-0 text-primary hover:text-primarydark bg-transparent text-sm font-semibold;
  letter-spacing: 0;
}

.supplier-button {
  @apply py-1 px-2 border border-transparent hover:border-gray-300 hover:bg-gray-50 rounded cursor-pointer;
}
</style>
