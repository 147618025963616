<template>
  <tr v-for="row in rows" :key="row">
    <td v-for="col in columns" :key="col">
      <p class="w-full h-3 bg-gray-200 rounded-md mb-2"></p>
      <p class="w-3/4 h-3 bg-gray-200 rounded-md mb-2"></p>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 5,
      required: false,
    },
    columns: {
      type: Number,
      default: 5,
      required: false,
    },
  },

  computed: {
    columnWidth() {
      return 1 / parseInt(this.columns);
    },
  },
};
</script>
