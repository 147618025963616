<template>
  <div v-if="!isAllZero" class="-ml-4 -mr-2 mt-2">
    <div v-if="showRowLabel" class="flex w-full px-3 -mb-1">{{ title }}</div>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="series"
      :height="height"
    />
  </div>
  <div
    v-else-if="totalIsZero"
    class="h-[50px] flex content-center items-center text-gray-800 text-sm"
  >
    {{ $t("general.noBudgetPlanned") }}
    <span class="ml-1 font-semibold text-hivebuy_plum">{{ title }}</span>
  </div>
</template>

<script>
import { currencyLocaleMixin } from "@/components/mixins/index.js";
export default {
  mixins: [currencyLocaleMixin],
  props: {
    series: {
      type: Array,
      default: null,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    showAnimations: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    height: {
      type: Number,
      default: 110,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    showRowLabel: {
      type: Boolean,
      default: false,
    },
    totalIsZero: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: [
          {
            breakpoint: 640,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
          offsetY: -15,
          animations: {
            enabled: this.showAnimations,
            animateGradually: {
              enabled: this.showAnimations,
            },
            dynamicAnimation: {
              enabled: this.showAnimations,
            },
          },
        },
        colors: ["#5c164e", "#9e4770", "#d1d5db"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
          lineCap: "round",
        },
        xaxis: {
          categories: [this.title],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            minWidth: this.rowLabelWidth(),
            maxWidth: this.rowLabelWidth(),
            align: "right",
            style: {
              fontSize: "13px",
              fontFamily: "system-ui",
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: false,
          },
        },
        tooltip: {
          enabled: this.tooltip,
          followCursor: true,
          fixed: {
            enabled: false,
            offsetX: -50,
            offsetY: -40,
          },
          y: {
            formatter: (y) => {
              return this.valueInCurrency(y);
            },
          },
          x: {
            show: false,
          },
        },
        grid: {
          show: false,
        },

        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: this.showLegend,
          showForZeroSeries: false,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "13px",
          fontFamily: "system-ui",
          onItemHover: {
            highlightDataSeries: false,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          itemMargin: {
            horizontal: 15,
            vertical: 0,
          },
          formatter: (seriesName, opts) => {
            return [
              "<strong>",
              seriesName,
              "</strong>",
              "<BR/>",
              this.valueInCurrency(
                parseInt(opts.w.globals.series[opts.seriesIndex])
              ),
            ];
          },
          markers: {
            offsetX: -7,
          },
        },
      },
    };
  },
  computed: {
    dataExist() {
      return this.series;
    },
    isAllZero() {
      let result = 0;
      this.series.forEach((element) => {
        result = result + parseInt(element.data);
      });
      return !result > 0;
    },
  },
  methods: {
    rowLabelWidth() {
      // return this.showRowLabel ? 70 : 0;
      return 0;
    },
  },
};
</script>
