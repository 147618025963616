import { post, get, patch } from "@/utils/services/api";
import { SHOPPING_LIST_API_URL } from "@/utils/services/apiUrls";
import * as ShoppingListTypes from "@/store/ShoppingList/types";

export const getShoppingLists = (commit, resolve, reject) => {
  get(`${SHOPPING_LIST_API_URL}?all=true`)
    .then((response) => {
      commit(ShoppingListTypes.SET_SHOPPING_LISTS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveShoppingList = (payload, commit, resolve, reject) => {
  post(SHOPPING_LIST_API_URL, payload)
    .then((response) => {
      commit(ShoppingListTypes.SET_SHOPPING_LIST, response.data);

      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const updateList = (id, payload, commit, resolve, reject) => {
  if (!id) return;

  patch(`${SHOPPING_LIST_API_URL}${id}/`, payload)
    .then((response) => {
      commit(ShoppingListTypes.SET_SHOPPING_LIST_DATA, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
