import * as ProposalTypes from "@/store/Proposal/types";
import {
  createProposal,
  createSuggestion,
  deleteSuggestion,
  dislikeSuggestion,
  finaliseSuggestion,
  getProposal,
  getProposals,
  likeSuggestion,
  updateProposal,
  updateProposalMembers,
  updateSuggestionPurchaseRequest,
} from "@/utils/services/models/proposals";

export default {
  [ProposalTypes.GET_PROPOSALS]: (
    { commit },
    { queryParams, areFiltersUpdated }
  ) => {
    return new Promise((resolve, reject) => {
      getProposals(commit, resolve, reject, queryParams, areFiltersUpdated);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProposalTypes.CREATE_PROPOSAL]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      createProposal(commit, resolve, reject, payload);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProposalTypes.GET_PROPOSAL]: ({ commit }, proposalId) => {
    return new Promise((resolve, reject) => {
      getProposal(resolve, reject, proposalId);
    });
  },

  [ProposalTypes.UPDATE_PROPOSAL_MEMBERS]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { proposalId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateProposalMembers(resolve, reject, proposalId, payload);
    });
  },

  [ProposalTypes.CREATE_SUGGESTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      createSuggestion(resolve, reject, payload);
    });
  },

  [ProposalTypes.LIKE_SUGGESTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    suggestionId
  ) => {
    return new Promise((resolve, reject) => {
      likeSuggestion(resolve, reject, suggestionId);
    });
  },

  [ProposalTypes.DISLIKE_SUGGESTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    suggestionId
  ) => {
    return new Promise((resolve, reject) => {
      dislikeSuggestion(resolve, reject, suggestionId);
    });
  },

  [ProposalTypes.DELETE_SUGGESTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    suggestionId
  ) => {
    return new Promise((resolve, reject) => {
      deleteSuggestion(resolve, reject, suggestionId);
    });
  },

  [ProposalTypes.UPDATE_SUGGESTION_PURCHASE_REQUEST]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { suggestionId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updateSuggestionPurchaseRequest(resolve, reject, suggestionId, payload);
    });
  },

  [ProposalTypes.FINALISE_SUGGESTION]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    suggestionId
  ) => {
    return new Promise((resolve, reject) => {
      finaliseSuggestion(resolve, reject, suggestionId);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ProposalTypes.UPDATE_PROPOSAL]: ({ commit }, { proposalId, payload }) => {
    return new Promise((resolve, reject) => {
      updateProposal(commit, resolve, reject, proposalId, payload);
    });
  },
};
