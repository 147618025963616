<template>
  <div class="flex items-center w-full">
    <div
      v-if="isApiLoading('categories')"
      class="flex items-center justify-center w-full"
    >
      <HivebuyLoader class="h-5 w-5" />
    </div>
    <div v-else class="w-full">
      <HivebuyOverlay
        ref="inlineSupplierSelect"
        :title="$t('global.category')"
        :subtitle="$t('companyManagement.companySupplier.heading')"
        :disabled-message="disabledMessage"
        :error-text="error"
        narrow
      >
        <template #header>
          <div v-if="value" class="text-sm">
            <TruncateText :text="value.name" />
          </div>
          <p v-else>
            {{
              $t("global.prepositions.select", {
                object: $t("global.category"),
              })
            }}
          </p>
        </template>
        <template #subtitle>
          <div v-if="value" class="text-sm">
            {{ value?.glAccount }}
          </div>
          <p v-else></p>
        </template>

        <template #icon>
          <TagIcon class="h-[32px] w-[32px]" />
        </template>
        <template #panelHeader>
          {{
            $t("global.prepositions.select", {
              object: $t("global.category"),
            })
          }}
        </template>

        <template #panelContent>
          <Input
            id="filterTextInput"
            :value="filterText"
            name="filterTextInput"
            :placeholder="$t('general.search')"
            show-icon
            type="search"
            :focus="true"
            :on-key-up="onSearchChange"
          />
          <Tree
            v-model:expandedKeys="expandedKeys"
            :value="filteredNodes"
            selection-mode="single"
            :pt="{
              root: { class: ' hivebuy-border-x-0' },
              container: () => ({
                class: 'hivebuy-overflow-y-scroll hivebuy-max-h-[50vh]',
              }),
            }"
            @node-select="nodeClick"
          >
            <template #default="slotProps">
              <div class="text-base flex items-center gap-x-2">
                <span v-if="slotProps.node.emoji" class="text-base">
                  {{ slotProps.node.emoji }}
                </span>
                <div>
                  {{ slotProps.node.label }}
                  <p class="text-xs font-light">
                    {{ slotProps.node.glAccount }}
                  </p>
                </div>
              </div>
            </template>
          </Tree>
        </template>
        <template #panelFooter>
          {{ $t("purchaseRequest.selectCategorySubtext") }}
        </template>
      </HivebuyOverlay>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { HivebuyOverlay } from "design-buy";
import { TagIcon } from "@heroicons/vue/outline";

import {
  COMPANY_MANAGEMENT_MODULE,
  GET_CATEGORIES,
} from "@/store/CompanyManagement/types";
import { HivebuyLoader, Input } from "@/components/shared/index";
import Tree from "primevue/tree";
export default {
  name: "InlineCategorySelect",
  components: {
    HivebuyOverlay,
    HivebuyLoader,
    TagIcon,
    Tree,
    Input,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabledMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      expandedKeys: {},
      filterText: "",
    };
  },

  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryTreeWithChildren",
      "categoriesList",
      "categoryDetails",
    ]),
    value: {
      get() {
        return this.categoryDetails(this.modelValue, true);
      },
      set(value) {
        this.editMode = false;
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },

    treeOptions() {
      return this.categoryTreeWithChildren().map((opt) => {
        return { ...opt, key: opt.id };
      });
    },

    filteredNodes() {
      if (!this.filterText.trim()) return this.treeOptions;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.expandedKeys = {};
      return this.filterTreeNodes(this.treeOptions, this.filterText);
    },
  },
  mounted() {
    if (this.categoriesList.length || this.isApiLoading("categories")) return;

    this.setApiLoading({ key: "categories", value: true });
    this.getCategories()
      .catch(() => {
        this.showErrorMessage();
      })
      .finally(() => {
        this.setApiLoading({ key: "categories", value: false });
      });
  },

  methods: {
    filterTreeNodes(nodes, filterText) {
      return nodes
        .map((node) => {
          const glAccount = node.glAccount || "";
          const matches =
            node.label.toLowerCase().includes(filterText.toLowerCase()) ||
            glAccount.toLowerCase().includes(filterText.toLowerCase());

          let filteredChildren = [];
          if (node.children) {
            filteredChildren = this.filterTreeNodes(node.children, filterText);
          }

          if (matches || filteredChildren.length) {
            if (matches) {
              this.expandParents(node.key);
            }
            return {
              ...node,
              children: filteredChildren.length ? filteredChildren : [],
            };
          }

          return null;
        })
        .filter((node) => node);
    },
    expandParents(key) {
      let parentNode = this.findParentNode(this.treeOptions, key);
      while (parentNode) {
        this.expandedKeys[parentNode.key] = true;
        parentNode = this.findParentNode(this.treeOptions, parentNode.key);
      }
    },
    findParentNode(nodes, key) {
      for (const node of nodes) {
        if (node.children && node.children.some((child) => child.key === key)) {
          return node;
        }
        if (node.children) {
          const parent = this.findParentNode(node.children, key);
          if (parent) {
            return parent;
          }
        }
      }
      return null;
    },

    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCategories: GET_CATEGORIES,
    }),
    nodeClick(node) {
      if (node.isDisabled) this.toggleNode(node);
      else {
        this.value = node.id;
        this.$refs.inlineSupplierSelect.toggle();
      }
    },

    toggleNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = !this.expandedKeys[node.key];

        for (const child of node.children) {
          this.toggleNode(child);
        }
      }
    },

    onSearchChange(event) {
      const { value } = event.target;
      this.filterText = value;
      this.filteredNodes;
    },

    onFilter() {
      this.expandedKeys = {};
      if (this.filterText.trim() === "") {
        this.expandedKeys = {};
      } else if (Array.isArray(this.treeOptions)) {
        this.expandMatchingNodes(this.treeOptions, this.filterText);
      }
    },
  },
};
</script>
<style>
.p-tree {
  @apply border-none !important;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  @apply shadow-none transition-all !important;
}
</style>
