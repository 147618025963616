export default {
  users: false,
  members: false,
  categories: false,
  companySuppliers: false,
  companyAddresses: false,
  departments: false,
  paymentMethods: false,
  component: false,
};
