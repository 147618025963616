import DropDown from "@/components/shared/DropDown";
import { UPDATE_ME } from "@/store/Auth/types";
import { AUTH_MODULE, SET_USER } from "@/store/Auth/types";
import { mapState, mapActions, mapMutations } from "vuex";
import { LANGUAGES } from "@/utils/constants";

export default {
  components: {
    DropDown,
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    localeOptions() {
      return LANGUAGES(this.$t)
    },
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    localValue(val = this.$i18n.locale) {
      const locale = this.localeOptions.find(
        (l) => l.value === val.toUpperCase()
      );
      return locale.label;
    },

    localeFromLabel(value) {
      return this.localeOptions.find((l) => l.label === value);
    },
    
    onLocaleChange(val) {
      const payload = {
        language: val,
      };

      this.updateMe({ payload }).then((response) => {
        this.$i18n.locale = val.toLowerCase();
        this.showNotification(this.$t("toasts.genericSuccess"), "success");
        this.setUser(response.data);
      });
    },
  },
};
