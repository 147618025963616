<template>
  <div class="h-full">
    <div class="profile__header">
      <div class="profile__header__left">
        <h3 class="card-title">
          {{ $t("companyManagement.profile.costSaving.costSaving") }} /
          {{ $t("companyManagement.profile.costSaving.costAvoidance") }}
        </h3>
        <InformationPopover class="ml-2" :icon-class="''">
          <template #header>
            {{ $t("companyManagement.profile.costSaving.costSaving") }} /
            {{ $t("companyManagement.profile.costSaving.costAvoidance") }}
          </template>
          <template #body>
            <p>
              {{ $t("companyManagement.profile.costSaving.tooltip") }}
            </p>
          </template>
        </InformationPopover>
      </div>
      <div class="profile__header__right">
        <div v-if="editMode">
          <Button
            class="button-small"
            :on-click-handler="resetValues"
            :color="'gray'"
          >
            {{ $t("companyManagement.profile.buttons.cancel") }}
          </Button>
          <Button :on-click-handler="onSave" class="button-small">
            {{ $t("companyManagement.profile.buttons.save") }}
          </Button>
        </div>
        <div v-else>
          <Button :on-click-handler="toggleEditMode" class="button-small">
            {{ $t("companyManagement.profile.buttons.edit") }}
          </Button>
        </div>
      </div>
    </div>
    <div class="profile-card-content">
      <div class="col-span-6 sm:col-span-4 mt-0 pt-2 pb-2">
        <div class="grid md:grid-cols-2 gap-x-6">
          <div class="text-sm mb-0.5 font-light flex justify-center">
            {{ $t("companyManagement.profile.costSaving.costSaving") }}
          </div>
          <div class="text-sm mb-0.5 font-light flex justify-center">
            {{ $t("companyManagement.profile.costSaving.costAvoidance") }}
          </div>
        </div>
        <div v-if="editMode" class="grid md:grid-cols-2 gap-x-6">
          <CurrencyInput v-model.lazy="goals.costSavingGoal" />
          <CurrencyInput v-model.lazy="goals.costAvoidanceGoal" />
        </div>
        <div v-else class="grid md:grid-cols-2 gap-x-6 text-base font-medium">
          <div class="flex justify-center">
            <i18n-n
              v-if="costSavingGoals.costSavingGoal"
              :value="parseFloat(costSavingGoals.costSavingGoal)"
              format="currency"
              :locale="currencyToLocale(costSavingGoals.currency)"
            />
          </div>
          <div class="flex justify-center">
            <i18n-n
              v-if="costSavingGoals.costAvoidanceGoal"
              :value="parseFloat(costSavingGoals.costAvoidanceGoal)"
              format="currency"
              :locale="currencyToLocale(costSavingGoals.currency)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InformationPopover,
  CurrencyInput,
} from "@/components/shared/index";
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods";
import {
  GET_COST_SAVING,
  SET_COST_SAVINGS,
  COMPANY_MANAGEMENT_MODULE,
} from "@/store/CompanyManagement/types.js";
export default {
  components: {
    Button,
    InformationPopover,
    CurrencyInput,
  },
  data() {
    return {
      editMode: false,
      v$: useValidate(),
      goals: {
        costSavingGoal: 0,
        costAvoidanceGoal: 0,
      },
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["costSavingGoals"]),
  },
  mounted() {
    if (!this.costSavingGoal) this.getCostSaving();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCostSaving: GET_COST_SAVING,
      setCostSaving: SET_COST_SAVINGS,
    }),
    resetValues() {
      this.toggleEditMode();
    },
    toggleEditMode() {
      this.goals = Object.assign({}, this.costSavingGoals);
      this.editMode = !this.editMode;
    },
    onSave() {
      this.v$.$validate();

      if (!this.v$.$error) {
        this.setCostSaving(this.goals).then(() => {
          this.toggleEditMode();
          this.showNotification("Updated", "success");
        });
      }
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.button-small {
  @apply border-0 py-1 px-2 bg-transparent text-sm text-gray-800 hover:text-white  hover:bg-primary ml-3;
}

.profile {
  @apply mb-12;
}

.profile__header {
  @apply flex justify-between border-b border-gray-200 pb-4;
}

.profile__header__left {
  @apply flex;
}
.profile__header__left .card-title {
  @apply font-bold text-primary mb-1 text-sm;
}
.profile__header__left .sub-title {
  @apply text-sm text-gray-500;
}
</style>
