import * as ShoppingListTypes from "@/store/ShoppingList/types.js";
import {
  saveShoppingList,
  getShoppingLists,
  updateList,
} from "@/utils/services/models/shoppingList.js";

export default {
  [ShoppingListTypes.SAVE_SHOPPING_LIST]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveShoppingList(payload, commit, resolve, reject);
    });
  },
  [ShoppingListTypes.GET_SHOPPING_LISTS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      getShoppingLists(commit, resolve, reject);
    });
  },
  [ShoppingListTypes.UPDATE_SHOPPING_LIST]: ({ commit }, payload) => {
    const { id, data } = payload;
    return new Promise((resolve, reject) => {
      updateList(id, data, commit, resolve, reject);
    });
  },
};
