<template>
  <div class="mt-1 grid grid-cols-1">
    <div
      class="relative rounded-lg bg-white px-3 py-2 flex items-center space-x-3"
    >
      <div class="flex-shrink-0">
        <UserImage :user="user" />
      </div>
      <div class="flex-1 min-w-0">
        <div class="focus:outline-none text-left">
          <span class="absolute inset-0" aria-hidden="true"></span>
          <p class="text-sm font-medium text-gray-900">
            {{ user ? user.name : "" }}
          </p>
          <p class="text-xs font-extralight text-gray-900">
            {{ user ? user.email : "" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserImage from "@/components/shared/UserImage";
import userMixin from "@/components/mixins/userMixin";
export default {
  components: {
    UserImage,
  },
  mixins: [userMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
