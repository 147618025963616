<template>
  <svg
    baseProfile="tiny"
    version="1.2"
    viewBox="0 0 100 100"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill="currentColor"
    class="fill-current"
  >
    <g id="Слой_2" />
    <g id="Слой_1">
      <path
        d="M82.5,5h-45C30.607,5,25,10.607,25,17.5V75H7.5C6.119,75,5,76.119,5,77.5v5C5,89.393,10.607,95,17.5,95h45   C69.393,95,75,89.393,75,82.5V30h17.5c1.381,0,2.5-1.119,2.5-2.5v-10C95,10.607,89.393,5,82.5,5z M17.5,90   c-4.136,0-7.5-3.364-7.5-7.5V80h40v2.5c0,2.816,0.947,5.408,2.524,7.5H17.5z M70,17.5v65c0,4.136-3.364,7.5-7.5,7.5   S55,86.636,55,82.5v-5c0-1.381-1.119-2.5-2.5-2.5H30V17.5c0-4.136,3.364-7.5,7.5-7.5h35.024C70.947,12.092,70,14.684,70,17.5z    M90,25H75v-7.5c0-4.136,3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5V25z"
      />
      <path
        d="M35,22.5c0,1.381,1.119,2.5,2.5,2.5h25c1.381,0,2.5-1.119,2.5-2.5S63.881,20,62.5,20h-25C36.119,20,35,21.119,35,22.5z"
      />
      <path
        d="M62.5,30h-25c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h25c1.381,0,2.5-1.119,2.5-2.5S63.881,30,62.5,30z"
      />
      <path
        d="M62.5,40h-25c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h25c1.381,0,2.5-1.119,2.5-2.5S63.881,40,62.5,40z"
      />
      <path
        d="M62.5,50h-25c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h25c1.381,0,2.5-1.119,2.5-2.5S63.881,50,62.5,50z"
      />
      <path
        d="M62.5,60h-25c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5h25c1.381,0,2.5-1.119,2.5-2.5S63.881,60,62.5,60z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "ShoppinglistSVG",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
