<template>
  <div class="relative">
    <div class="text-right">
      <Button
        style="background: #ffffff; border: none; box-shadow: none"
        class="bg-white"
        :on-click-handler="onFilterClick"
        :color="showFilters ? 'danger' : 'primary'"
        :disabled="disabled"
      >
        <AdjustmentsIcon class="text-primary hover:text-primarydark w-6 h-6" />

        <div v-if="appliedFiltersCount" class="counter-badge">
          {{ appliedFiltersCount }}
        </div>
      </Button>
    </div>
    <CollapseTransition class="absolute right-0">
      <div v-if="showFilters" class="filter-holder z-50 w-[800px]">
        <div class="flex-grow z-50">
          <div class="grid lg:grid-cols-6 gap-4">
            <div class="col-span-12 lg:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("contracts.table.fields.contractType") }}
              </label>
              <div class="mt-1">
                <Multiselect
                  v-model="filters.contractType"
                  :mode="'single'"
                  :options="contractTypeOptions"
                  :placeholder="
                    $t('contracts.table.fields.contractTypePlaceholder')
                  "
                  :can-clear="false"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-4">
              <DateRangeInput v-model="filters.dateRange" />
            </div>
          </div>

          <div class="flex justify-end ml-2 mt-2">
            <Button :on-click-handler="onApplyFilters" class="mr-2">
              {{ $t("button.apply") }}
            </Button>
            <Button :on-click-handler="onResetAllFilters" :color="'gray'">
              {{ $t("button.resetAll") }}
            </Button>
          </div>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { removeKeysInObjWithNoValue } from "@/utils/utility_methods";
import { AdjustmentsIcon } from "@heroicons/vue/outline";
import { Button, DateRangeInput } from "@/components/shared";
import { CONTRACT_TYPES } from "@/utils/constants";
import Multiselect from "@vueform/multiselect";

export default {
  components: {
    CollapseTransition,
    AdjustmentsIcon,
    DateRangeInput,
    Button,
    Multiselect,
  },
  props: {
    setAdvancedFilters: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFilters: false,
      filters: {
        contractType: "",
        dateRange: { start: "", end: "" },
      },
    };
  },
  computed: {
    appliedFiltersCount() {
      return Object.keys(this.advanceFilters).length;
    },
    advanceFilters() {
      const { dateRange, ...formattedFilters } = this.filters;

      formattedFilters.start_date__gte = dateRange?.start
        ? dateRange.start.toISOString()
        : "";
      formattedFilters.end_date__lte = dateRange?.end
        ? dateRange.end.toISOString()
        : "";

      return removeKeysInObjWithNoValue(formattedFilters);
    },
    contractTypeOptions() {
      return Object.entries(CONTRACT_TYPES).map(([key, value]) => {
        return { label: key, value };
      });
    },
  },
  created() {
    this.CONTRACT_TYPES = CONTRACT_TYPES;
  },
  methods: {
    onApplyFilters() {
      this.setAdvancedFilters(this.advanceFilters);
      this.showFilters = false;
    },
    onResetAllFilters() {
      this.filters = {};
      this.setAdvancedFilters(this.advanceFilters);
      this.showFilters = false;
    },
    onFilterClick() {
      this.showFilters = !this.showFilters;
    },
  },
};
</script>

<style scoped>
.filter-holder {
  @apply flex items-end justify-end bg-white p-6 rounded border border-gray-200 shadow-2xl;
}
</style>
