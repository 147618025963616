<template>
  <div class="content-holder">
    <UpdateAndPreview :invoice="selectedInvoice" :close-modal="closeModal" />
  </div>
</template>

<script>
import { INVOICE_MODULE, UPDATE_INVOICE } from "@/store/Invoice/types";
import UpdateAndPreview from "@/components/InvoiceApproval/updateAndPreview.vue";
import { mapActions } from "vuex";

export default {
  components: { UpdateAndPreview },

  provide() {
    return {
      saveInvoiceObject: this.saveInvoiceObject,
    };
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    invoices: { type: Array, required: true },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedInvoice: this.invoices[0],
    };
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      updateInvoice: UPDATE_INVOICE,
    }),
    selectInvoice(invoice) {
      this.selectedInvoice.current = false;
      this.selectedInvoice = invoice;
      this.selectedInvoice.current = true;
    },
    saveInvoiceObject(object) {
      this.updateInvoice({
        invoiceId: object.id,
        payload: this.payload(object),
      })
        .then(() => {
          this.showNotification(this.$t("toasts.genericSuccess"));
          this.closeModal();
        })
        .catch((error) => {
          this.showErrorMessage(error);
        });
    },
    payload(object) {
      const invoicePurchaseOrder = {
        invoice: object.id,
        purchaseOrder: this.purchaseOrder.id,
        shipping: object.shipping,
        shippingCurrency: object.shippingCurrency,
        tax: object.tax,
        taxCurrency: object.taxCurrency,
        totalAmount: object.totalAmount,
        totalAmountCurrency: object.totalAmountCurrency,
        totalGrossAmount: object.totalGrossAmount,
        totalGrossAmountCurrency: object.totalGrossAmountCurrency,
        totalNetAmount: object.totalNetAmount,
        totalNetAmountCurrency: object.totalNetAmountCurrency,
      };
      return { invoicePurchaseOrders: [invoicePurchaseOrder] };
    },
  },
};
</script>

<style scoped>
.content-holder {
  @apply h-full;
}
</style>
