<template>
  <div :class="['max-lg:col-span-2 mb-6 pb-3', classes]">
    <label class="block text-sm font-medium text-gray-500"> {{ label }}</label>
    <div class="flex items-center text-white w-max mt-3.5">
      <div class="grid grid-cols-3 gap-1">
        <div
          v-for="item in list"
          :key="item"
          class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-primary ring-1 ring-inset ring-primary"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
