import * as PurchaseOrderTypes from "@/store/PurchaseOrder/types";
import {
  getUserActionRequiredPurchaseOrders,
  updatePurchaseOrder,
  getPurchaseOrders,
  getPurchaseOrder,
  resendWebhook,
  getSupplierOrders,
  getSupplierOrder,
  resendSupplierOrder,
  downloadOrders,
} from "@/utils/services";

export default {
  [PurchaseOrderTypes.DOWNLOAD_PURCHASE_ORDERS]: (_store, params) => {
    return new Promise((resolve, reject) => {
      downloadOrders(resolve, reject, params);
    });
  },
  [PurchaseOrderTypes.GET_PURCHASE_ORDERS]: (
    { commit },
    { params, formatSameParams = false }
  ) => {
    return new Promise((resolve, reject) => {
      getPurchaseOrders(commit, resolve, reject, params, formatSameParams);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [PurchaseOrderTypes.GET_PURCHASE_ORDER]: ({ commit }, purchaseOrderId) => {
    return new Promise((resolve, reject) => {
      getPurchaseOrder(resolve, reject, purchaseOrderId);
    });
  },

  [PurchaseOrderTypes.GET_SUPPLIER_ORDERS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getSupplierOrders(commit, resolve, reject, params);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [PurchaseOrderTypes.GET_SUPPLIER_ORDER]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      getSupplierOrder(resolve, reject, id);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [PurchaseOrderTypes.RESEND_SUPPLIER_ORDER]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      resendSupplierOrder(commit, resolve, reject, id);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [PurchaseOrderTypes.GET_SUPPLIER_ORDER]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      getSupplierOrder(resolve, reject, id);
    });
  },

  [PurchaseOrderTypes.GET_ACTION_REQUIRED_PURCHASE_ORDERS]: (
    { commit },
    paginationParams
  ) => {
    return new Promise((resolve, reject) => {
      getUserActionRequiredPurchaseOrders(
        commit,
        resolve,
        reject,
        paginationParams
      );
    });
  },

  [PurchaseOrderTypes.UPDATE_PURCHASE_ORDER]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { purchaseOrderId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updatePurchaseOrder(resolve, reject, purchaseOrderId, payload);
    });
  },

  [PurchaseOrderTypes.RESEND_WEBHOOK]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    purchaseOrderId
  ) => {
    return new Promise((resolve, reject) => {
      resendWebhook(resolve, reject, purchaseOrderId);
    });
  },
};
