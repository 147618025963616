import en from "./en.json";
import de from "./de.json";
import es from "./es.json";

export const languages = {
  en: en,
  de: de,
  es: es,
};

const userLanguage = () => {
  const user = localStorage.getItem("user");
  if (!user) return "EN";

  return JSON.parse(user).language;
};

export const defaultLocale = () => {
  return userLanguage()?.toLowerCase() || "en";
};
