<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <PencilAltIcon
        v-if="isEdit"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <PlusIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      {{
        isEdit
          ? $t("companyManagement.profile.address.editAddress")
          : $t("companyManagement.profile.address.addAddress")
      }}
    </template>
    <template #body>
      <div class="mt-4">
        <div class="grid grid-cols-6 space-y-2 gap-2">
          <Input
            :value="address.name"
            :name="'name'"
            :error-text="error(v$.address.name)"
            :placeholder="$t('companyManagement.profile.address.name')"
            :label="$t('companyManagement.profile.address.name')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            is-mandatory
            class="col-span-6"
          />
          <Input
            id="address_street"
            :value="address.street"
            :error-text="error(v$.address.street)"
            :name="'street'"
            :placeholder="$t('companyManagement.profile.address.street')"
            :label="$t('companyManagement.profile.address.street')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            is-mandatory
            class="col-span-4"
          />
          <Input
            :value="address.houseNumber"
            :error-text="error(v$.address.houseNumber)"
            :name="'houseNumber'"
            :placeholder="$t('companyManagement.profile.address.number')"
            :label="$t('companyManagement.profile.address.number')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            is-mandatory
            class="col-span-2"
          />
          <Input
            :value="address.addressSupplement"
            :name="'addressSupplement'"
            :placeholder="$t('companyManagement.profile.address.supplement')"
            :label="$t('companyManagement.profile.address.supplement')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            class="col-span-6"
          />
          <Input
            :value="address.postalCode"
            :error-text="error(v$.address.postalCode)"
            :name="'postalCode'"
            :placeholder="$t('companyManagement.profile.address.postal')"
            :label="$t('companyManagement.profile.address.postal')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            is-mandatory
            class="col-span-2"
          />
          <Input
            :value="address.city"
            :error-text="error(v$.address.city)"
            :name="'city'"
            :placeholder="$t('companyManagement.profile.address.city')"
            :label="$t('companyManagement.profile.address.city')"
            :on-value-change="onAttributeChange"
            :type="'text'"
            is-mandatory
            class="col-span-4"
          />
          <div class="col-span-full">
            <label class="block text-sm font-medium text-gray-700 mb-2"
              >{{ $t("companyManagement.profile.address.country") }}
              <span class="text-red-600">*</span>
            </label>
            <Multiselect
              v-model="address.country"
              :options="sortedCountries"
              :searchable="true"
              :track-by="'name_en'"
              :mode="'single'"
              :placeholder="$t('companyManagement.profile.address.country')"
              :value-prop="'abbr'"
              :label="'name_en'"
              :can-deselect="false"
            >
              <template #option="{ option, selected }">
                <div class="flex items-center">
                  <img
                    :src="require('@/assets/images/' + option.flag)"
                    alt=""
                    class="flex-shrink-0 h-6 w-6 rounded-full border border-gray-200"
                  />
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'ml-3 block truncate'
                    ]"
                  >
                    {{ option.name_en }}
                  </span>
                </div>
              </template>
            </Multiselect>
          </div>
        </div>
        <div class="flex justify-between pt-6">
          <SwitchCheckbox
            v-model="address.defaultDelivery"
            :label="$t('global.default') + ' ' + $t('global.deliveryAddress')"
          />
          <SwitchCheckbox
            v-model="address.defaultInvoice"
            :label="$t('global.default') + ' ' + $t('global.invoiceAddress')"
          />
        </div>
        <div v-if="isAddressError" class="border p-2 border-hivebuy-red rounded text-hivebuy-red mt-2">
          {{ $t("profile.defaultAddressError")}}
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button :on-click-handler="onSave">
        {{ isEdit ? $t("buttons.edit") : $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { PlusIcon, PencilAltIcon } from "@heroicons/vue/outline";
import Multiselect from "@vueform/multiselect";
import Input from "@/components/shared/Input.vue";
import Modal from "@/components/shared/Modal";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { errorMixin } from "@/components/mixins/index.js";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Button } from "@/components/shared";
import { isObjectEmpty } from "@/utils/utility_methods";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

import countries_json from "@/assets/data/countries.json";

export default {
  components: {
    Modal,
    PlusIcon,
    Input,
    SwitchCheckbox,
    Button,
    PencilAltIcon,
    Multiselect
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    saveAddressMethod: {
      type: Function,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    addressToEdit: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    apiError: {
      type: String,
      default: null}
  },
  data() {
    return {
      address: this.addressInitialState(),
      v$: useValidate()
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    countries() {
      return countries_json;
    },
    sortedCountries() {
      return this.countries
        .slice(0)
        .sort((a, b) => a.abbr.localeCompare(b.abbr));
    },
    isAddressError() {
      return this.apiError?.errorMessageCode == "weMustHaveAtLeastOneDefaultDeliveryAddressAndDefaultInvoiceAddress" 
    }
  },
  watch: {
    addressToEdit: {
      handler(newVal, oldVal) {
        if (this.isEdit && !isObjectEmpty(newVal) && newVal != oldVal) {
          this.address = Object.assign({}, newVal);
        }
      }
    }
  },
  methods: {
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveAddressMethod(this.address);
        this.resetValues();
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        // this.address = this.addressInitialState();
        this.v$.$reset();
      }, 200);
    },
    addressInitialState() {
      return { defaultDelivery: false, defaultInvoice: false };
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.address[name] = value;
    }
  },
  validations() {
    return {
      address: {
        street: { required },
        name: { required },
        houseNumber: { required },
        postalCode: { required },
        country: { required },
        city: { required }
      }
    };
  }
};
</script>
