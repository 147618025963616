<template>
  <div class="custom-card no-shadow flex flex-col justify-between">
    <div class="flex items-center justify-between">
      <h3 class="section-title text-base">
        <div class="flex items-center">
          <div class="h-12 w-12">
            <SlackSVG class="h-12 w-12 mr-1" />
          </div>
          <div>
            {{ $t("companyManagement.integrations.slack") }}
          </div>
        </div>
      </h3>
      <div v-if="integration?.connected" class="flex ml-4 -mt-3">
        <span class="text-sm text-hivebuy-green">
          {{ $t("companyManagement.integrations.connected") }}
        </span>
        <CheckCircleIcon class="h-5 w-5 text-hivebuy-green" />
      </div>
    </div>
    <div class="flex flex-col justify-between h-full gap-y-3">
      <div class="flex flex-col justify-between h-full">
        <div
          class="relative border border-gray-200 bg-gray-50 p-4 rounded-md h-full"
        >
          <div>
            <div class="text-gray-600">
              {{ $t("companyManagement.integrations.slackText") }}
            </div>
          </div>
        </div>
        <div class="mt-4">
          <a
            v-if="!integration?.connected"
            :href="slackUrl"
            class="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
          >
            {{ $t("button.connect") }}
          </a>
          <Button
            v-else
            :on-click-handler="onDisconnect"
            color="danger"
            :size-class="'sm'"
          >
            {{ $t("button.disconnect") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlackSVG from "@/assets/images/SlackSVG";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import { SLACK_URL } from "@/utils/services/apiUrls";
import { mapActions, mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { Button } from "@/components/shared";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_INTEGRATION,
} from "@/store/CompanyManagement/types";

export default {
  name: "Slack",
  components: {
    SlackSVG,
    CheckCircleIcon,
    Button,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    slackUrl() {
      const id = this.user.id;
      return SLACK_URL(id);
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateIntegration: UPDATE_INTEGRATION,
    }),
    onDisconnect() {
      const payload = { clientSecret: "", appIdentifier: "" };
      this.updateIntegration({ integrationId: this.integration.id, payload });
    },
  },
};
</script>
