export default {
  projects: [],
  projectsListFilters: {
    page: 1,
    pageSize: 10,
    totalPages: "",
    totalRecords: "",
    search: "",
  },
  projectsList: [],
  projectsListParams: {},
};
