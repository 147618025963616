import jwt_decode from "jwt-decode";
import { CURRENCY_LOCALE } from "@/utils/constants.js";
import store from "../store";
import {
  SHOW_NEW_VERSION_ALERT,
  DISABLE_NEW_VERSION_ALERT,
  DASHBOARD_MODULE,
} from "@/store/Dashboard/types";
import * as Sentry from "@sentry/vue";
import { PURCHASE_ORDER_STATUSES_MAPPING_KEYS } from "@/utils/Constants/filters/mapFilterKeys.js";
export const titleize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getItemFromLocalStorage = (key, default_value = null) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : default_value;
};

export const setItemInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const sumInArray = (array, fieldName, initialValue = 0) => {
  return array.reduce((a, b) => {
    return a + parseFloat(b[fieldName]);
  }, initialValue);
};

export const removeItemInLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const decodeAccessToken = (token) => {
  return jwt_decode(token);
};

export const isEmptyString = (str) => {
  return !str || str.length == 0;
};

export const checkEmail = (email) => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (reg.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const isObjectEmpty = (obj) => {
  return !obj || Object.keys(obj).length === 0;
};

export const ocrConfidenceCalc = (value) => {
  if (value === "all") {
    return {
      start: 0,
      end: 100,
    };
  }
  if (value === "red") {
    return {
      start: 0,
      end: 50,
    };
  }
  if (value === "yellow") {
    return {
      start: 50,
      end: 90,
    };
  }
  if (value === "green") {
    return {
      start: 90,
      end: 100,
    };
  }
};

//TODO Costly Method need an optimised alternative
export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getInitialsOfFullName = (fullName) => {
  if (!fullName) return "n/a";

  return (
    fullName
      .trim()
      .split(" ")
      .filter((name) => name.length)
      .map((name) => name[0].toLocaleUpperCase())
      .slice(0, 2)
      .join("") || "n/a"
  );
};

export const getInitialsOfName = (firstName, lastName) => {
  return (
    firstName.trim().charAt(0).toLocaleUpperCase() +
    lastName.trim().charAt(0).toLocaleUpperCase()
  );
};

export const validatePasswordRegex = (password, loginForm = false) => {
  if (loginForm) return true;

  return (
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /[0-9]/.test(password)
  );
};

export const formattedDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const formattedTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatDateInHyphenFormat = (dateString) => {
  return new Date(dateString).toISOString().slice(0, 10);
};

export const formatDatetimeInHyphenFormat = (dateString = new Date()) => {
  return new Date(dateString).toISOString();
};

export const formatDate = (date, showDayAsName, language) => {
  if (!date) return "-";

  const dateObject = new Date(date);
  let locale;
  if (!language) {
    locale = "de";
  } else {
    locale = language + "-DE";
  }
  let options;
  if (showDayAsName) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
  } else {
    options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
  }
  return new Intl.DateTimeFormat(locale, options).format(dateObject);
};

export const formatDateTime = (date, language) => {
  if (!date) return "";

  const dateObject = new Date(date);
  let locale;
  if (!language) {
    locale = "de";
  } else {
    locale = language + "-DE";
  }
  const options = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };

  return new Intl.DateTimeFormat(locale, options).format(dateObject);
};
export const calcNetPrice = (product) => {
  let netPrice;

  if (product.netGross === "gross") {
    netPrice = parseInt(product.price) / (1 + product.vat / 100);
  } else {
    netPrice = parseInt(product.price);
  }

  return netPrice || 0;
};

export const calcGrossPrice = (product) => {
  let grossPrice;

  if (product.netGross === "net") {
    grossPrice = parseInt(product.price) * (1 + product.vat / 100);
  } else {
    grossPrice = parseInt(product.price);
  }

  return grossPrice || 0;
};

export const formattedURL = (apiUrl, paginationParams) => {
  let url = "";

  if (isObjectEmpty(paginationParams)) {
    url = apiUrl;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { totalPages, totalRecords, ...params } = paginationParams;
    url = `${apiUrl}?${objectToQueryString(params)}`;
  }

  return url;
};

export const getFirstTwoChars = (val) => {
  if (val) {
    return val.substring(0, 2).toUpperCase();
  }
  return "-";
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
export const googleAuthMessages = (trans) => {
  return {
    genericAuthError: {
      data: { message: trans("auth.genericAuthError") },
    },
    noPendingInviteFound: {
      data: { message: trans("auth.noPendingInviteFound") },
    },
    pendingInviteInMails: {
      data: { message: trans("auth.pendingInviteInMails") },
    },
    signupOnlyWithInvite: {
      data: { message: trans("auth.signupOnlyWithInvite") },
    },
    authProviderEmailAndInviteEmailMismatch: {
      data: {
        message: trans("auth.authProviderEmailAndInviteEmailMismatch"),
      },
    },
  };
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const objectToQueryString = (params, formatSameParams = false) => {
  const statusKeys = Object.values(PURCHASE_ORDER_STATUSES_MAPPING_KEYS);

  return Object.keys(params)
    .map((key) => {
      if (
        statusKeys.includes(key) &&
        Array.isArray(params[key]) &&
        formatSameParams
      ) {
        return params[key].map((value) => `${key}=${value}`).join("&");
      } else {
        return `${key}=${params[key]}`;
      }
    })
    .join("&");
};

export const removeKeysFromObject = (obj, keys) => {
  const updatedObject = Object.assign({}, obj);

  keys.forEach((key) => {
    if (keys.includes(key)) {
      delete updatedObject[key];
    }
  });

  return updatedObject;
};

export const currencyToLocale = (currency) => {
  return CURRENCY_LOCALE[currency];
};

export const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const stringToBoolean = (str) => {
  return JSON.parse(str.toLowerCase());
};

export const isValueUndefined = (val) => {
  return typeof val === "undefined";
};

export const isValuePresent = (val) => {
  return typeof val !== "undefined" && val !== null && val !== "";
};

export const defaultDateAttributes = () => {
  return [
    {
      key: "today",
      highlight: true,
      dates: new Date(),
    },
  ];
};

export const isProductionEnvironment = () => {
  return process.env.VUE_APP_ENV === "production";
};
export const newVersionAvailable = (newVersion) => {
  const commitPlaceholder = `${DASHBOARD_MODULE}/${SHOW_NEW_VERSION_ALERT}`;
  store.commit(commitPlaceholder, newVersion);
};
export const newVersionApplied = () => {
  const commitPlaceholder = `${DASHBOARD_MODULE}/${DISABLE_NEW_VERSION_ALERT}`;
  store.commit(commitPlaceholder);
};

export const groupBy = (array, key) => {
  return array.reduce((r, a) => {
    r[a[key]] = [...(r[a[key]] || []), a];
    return r;
  }, {});
};
export const filterZeroValues = (array, key = "amount") => {
  return array.filter((element) => element[key] > 0);
};

export const toKebabCase = (str) => {
  if (str) return str.toLowerCase().replace(/\s+/g, "-");

  return "";
};

export const formatPrice = (value) => {
  return parseFloat(value.toString().replace(",", "."));
};

export const getNthPreviousDateFromNow = (days) => {
  return new Date(new Date().setDate(new Date().getDate() - days));
};

export const parseJson = (str) => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
};

export const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const formatCookieObjValue = (str) => {
  const inputStr = eval(str).replace(/'/g, "\"");
  return JSON.parse(inputStr);
};
export const previewFile = (file) => {
  const reader = new FileReader();

  if (file) return reader.readAsDataURL(file);
  return null;
};

export const findAndReplace = (data, obj) => {
  const records = [...data];

  return records.map((record) =>
    obj.id === record.id ? Object.assign({}, obj) : record
  );
};
export const findAndUpdate = (data, obj) => {
  const records = [...data];

  return records.map((record) =>
    obj.id === record.id ? Object.assign({}, record, obj) : record
  );
};
export const updateOrCreate = (
  data,
  record,
  create = true,
  pushAtStart = true,
  attribute = "id"
) => {
  let records = data && data.length ? [...data] : [];
  const index = records.findIndex((object) => {
    return attribute
      ? object[attribute] == record[attribute]
      : object == record;
  });
  if (index >= 0) {
    records[index] = attribute ? Object.assign({}, record) : record;
  } else if (create)
    pushAtStart ? (records = [record, ...records]) : records.push(record);

  return records;
};

export const deleteObjectFromArray = (array, record, attr = "id") => {
  if (!array || !record) return array;

  return array.filter((object) => object[attr] != record[attr]);
};
export const deleteIdFromArray = (array, id) => {
  if (!array || !id) return array;

  return array.filter((arrId) => arrId != id);
};

export const removeKeysInObjWithNoValue = (obj) => {
  const keysToRemove = [];

  Object.entries(obj).forEach(
    ([key, value]) => !value && keysToRemove.push(key)
  );

  return removeKeysFromObject(obj, keysToRemove);
};

export const differenceBetweenArraysOfObjects = (
  array1,
  array2,
  key = "id"
) => {
  return array1.filter(
    (obj1) => !array2.some((obj2) => obj1[key] == obj2[key])
  );
};

export const differenceIndicator = (
  actualAmount,
  expectedAmount,
  threshhold = 0.5
) => {
  const indicator = {};
  indicator.value = Math.abs(actualAmount - expectedAmount) / expectedAmount;
  indicator.value = isNaN(indicator.value) ? 0 : indicator.value;

  if (indicator.value > 1) {
    indicator.over100 = true;
  } else indicator.over100 = false;
  if (indicator.value < threshhold) {
    indicator.textColor = "text-green-800";
    indicator.bgColor = "bg-green-100";
  } else {
    indicator.textColor = "text-hivebuy-red";
    indicator.bgColor = "bg-red-100";
  }
  return indicator;
};

export const toTitleCase = (phrase, joiningChar = "") => {
  return phrase
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(joiningChar);
};

export const isObject = (val) => {
  if (val === null || val === undefined) return false;
  return (
    typeof val == "object" ||
    (val.constructor === Object && Object.keys(val).length)
  );
};

export const scrollToError = (
  selector = ".input-error-field",
  block = "start"
) => {
  const element = document.querySelector(selector);
  element?.parentElement.scrollIntoView({ behavior: "smooth", block: block });
};
export const fullName = (user) => {
  if (user != undefined && user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user != undefined && user.name) {
    return user.name;
  } else if (user != undefined && user.username) {
    return user.username;
  } else return "Name not found";
};

export const objectKeyCount = (object) => Object.keys(object).length;

export const requestStatus = (object, type = "request") => {
  let status;

  if (type === "request") {
    status = object.purchaseOrder ? object.purchaseOrder.status : object.status;
  } else if (type === "order") {
    status = object.status;
  }

  return status;
};

export const pickKeys = (obj, keys) =>
  Object.keys(obj)
    .filter((i) => keys.includes(i))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});

export const getUrlExtension = (file) => {
  const fileName = file.name ? file.name : file;
  return fileName ? fileName.split(/[#?]/)[0].split(".").pop().trim() : null;
};

export const differenceBetweenDatesInSecs = (start, end) => {
  const differenceInMs = Math.abs(new Date(end) - new Date(start));
  return Math.round(differenceInMs / 1000);
};
export const intersection = (array1, array2) => {
  return array1.filter((value) => array2.includes(value));
};
export const differenceArray = (array1, array2) => {
  return array1.filter((item) => !array2.includes(item));
};
export const sentenceCase = (str) => {
  return str
    .split(/(?=[A-Z])/)
    .join(" ")
    .toLowerCase();
};

export const addToArray = (array, attr) => {
  const responseArray = array?.length ? array : [];
  if (!responseArray.includes(attr)) responseArray.push(attr);
  return responseArray;
};

export const filterDeletedItems = (items) => {
  return items.filter(({ deleted }) => !deleted);
};
export const getValueFromHash = (path, obj, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
};
export const isHashEqual = (baseHash, comparisonHash) => {
  return JSON.stringify(baseHash) == JSON.stringify(comparisonHash);
};

export const uniqArray = (objects, key = "id") => {
  return [...new Map(objects.map((item) => [item[key], item])).values()];
};

export const isString = (val) => {
  return typeof val === "string" || val instanceof String;
};

export const arrayToSentence = (array, separator = ", ", trans) => {
  return (
    array.slice(0, -1).join(separator) +
    (array.length > 1 ? ` ${trans("global.prepositions.and")} ` : "") +
    array.slice(-1)
  );
};
export const isStringADate = (value) => {
  return value && !isNaN(Date.parse(value));
};
export const compareDates = (date1, date2) => {
  const date1Obj = date1 instanceof Date ? date1 : new Date(date1);
  const date2Obj = date2 instanceof Date ? date2 : new Date(date2);
  if (date1Obj < date2Obj) {
    return -1;
  } else if (date1Obj > date2Obj) {
    return 1;
  } else {
    return 0;
  }
};

export const getYesterdayDate = () => {
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() - 1);
  return maxDate;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const logSentryMessage = (message, data = {}) => {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Error);
    scope.setExtras({
      customData: data,
    });

    Sentry.captureMessage(message);
  });
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const camelToSnake = (inputString) =>
  inputString.replace(/[A-Z]/g, "_$&").toLowerCase();

export const formattedUrlApi = (url, params, formatSameParams = false) => {
  if (isObjectEmpty(params)) return url;
  let newParam = Object.assign({}, params);
  newParam = buildPaginationParams(newParam);
  newParam = buildSortingParams(newParam);
  return `${url}?${objectToQueryString(newParam, formatSameParams)}`;
};

const buildPaginationParams = (params) => {
  const { page, rows } = params;
  params.page = page + 1;
  params.pageSize = rows;
  delete params.rows;
  return params;
};

const buildSortingParams = (params) => {
  const { sortField, sortOrder } = params;
  if (!sortField) return params;
  const sortOrderString = sortOrder === -1 ? "-" : "";
  params.ordering = sortOrderString + camelToSnake(sortField);
  delete params.sortOrderString;
  delete params.sortField;
  return params;
};

export const stringToCamelCase = (inputString) => {
  const words = inputString.replace(/[^a-zA-Z0-9]/g, " ").split(" ");
  const camelCaseArray = words.map((word, index) => {
    return !index
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseArray.join("");
};

export const isBoolean = (val) => "boolean" === typeof val;

export const createFiltersPayload = (filters, key, value, parentKey) => {
  let payload = {};

  if (parentKey) {
    payload = {
      ...filters,
      [parentKey]: {
        ...filters[parentKey],
        [key]: value,
      },
    };
  } else {
    payload = { ...filters, [key]: value };
  }

  return payload;
};
export const checkAttributesPresence = (obj, attributeList) => {
  return attributeList.every(
    (attribute) =>
      Object.prototype.hasOwnProperty.call(obj, attribute) && obj[attribute]
  );
};
export const copyObjectKeys = (obj, keys) => {
  const targetObj = {};

  keys.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      targetObj[key] = obj[key];
    }
  });
  return targetObj;
};
export const downloadFileWithName = async (file, fileName = null) => {
  if (!fileName) {
    window.open(file, "_blank").focus();
    return;
  }

  const response = await fetch(file);
  const blob = await response.blob();
  const filename = fileName;
  const blobWithFilename = new Blob([blob], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blobWithFilename);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
export const objectHasKeys = (obj, keys) => {
  return keys.every((key) => {
    if (typeof key == "string") {
      return Object.prototype.hasOwnProperty.call(obj, key) && obj[key];
    } else {
      const value = objectHasKeys(
        obj[Object.keys(key)[0]],
        Object.values(key)[0]
      );
      return value;
    }
  });
};

const sortObjectKeys = (obj) =>
  Object.keys(obj)
    .sort()
    .reduce(
      (result, key) => ({
        ...result,
        [key]:
          obj[key] !== null && typeof obj[key] === "object"
            ? sortObjectKeys(obj[key])
            : obj[key],
      }),
      {}
    );

export const deepEqual = (obj1, obj2) =>
  JSON.stringify(sortObjectKeys(obj1)) === JSON.stringify(sortObjectKeys(obj2));

export const addScriptInHeader = (
  src,
  async = true,
  defer = true,
  type = "text/javascript"
) => {
  const script = document.createElement("script");
  script.src = src;
  script.type = type;
  script.async = async;
  script.defer = defer;

  document.head.appendChild(script);
};

export const addHubspotScript = (token, user) => {
  window.hsConversationsSettings = {
    identificationEmail: user.email,
    identificationToken: token,
  };
  addScriptInHeader(process.env.VUE_APP_HUBSPOT_URL);
};
