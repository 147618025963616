<template>
  <div class="mt-2 justify-self-auto shadow-lg animate-pulse">
    <div class="bg-white overflow-hidden shadow rounded-lg flex flex-col">
      <div class="relative">
        <div
          class="relative w-full h-72 rounded-lg overflow-hidden bg-gray-200"
        />
        <div class="relative mt-4 items-center justify-center px-4 py-2">
          <div class="w-full h-3 bg-gray-200 rounded-md mb-2"></div>
          <div class="w-full h-3 bg-gray-200 rounded-md mb-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
