<template>
  <Modal
    :size-classes="['sm:max-w-xl']"
    :show-modal="showModal"
    :close-modal="onModalClose"
  >
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-1xl text-gray-500">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.header") }}
      </h3>
      <p class="text-sm mt-2 font-normal text-gray-500">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.headerSubtext") }}
      </p>
    </template>
    <template #body>
      <div class="mb-4 mt-8">
        <label class="block text-sm font-medium text-gray-700 my-2">
          {{ $t("dashboard.myPurchaseOrderCard.orderedModal.date") }}
        </label>
        <DateCalendar
          v-model="order.orderedAt"
          :field-name="$t('dashboard.myPurchaseOrderCard.orderedModal.date')"
          :validation-error="v$.order.orderedAt.$error"
          :max-date="new Date()"
        />
      </div>
      <div class="grid grid-cols-12 gap-6 mt-6">
        <div class="col-span-12">
          <p class="text-sm font-light text-gray-500 mb-2">
            {{ $t("dashboard.myPurchaseOrderCard.orderedModal.confirmText") }}
          </p>
          <div class="flex place-content-center mt-4">
            <SwitchCheckbox
              v-model="samePriceRates"
              :label="
                $t('dashboard.myPurchaseOrderCard.orderedModal.samePriceSwitch')
              "
            />
          </div>
        </div>

        <OrderPrice
          ref="orderPrice"
          :purchase-order="purchaseOrder"
          :same-price-rates="samePriceRates"
        />

        <div class="col-span-12 mr-6">
          <TextArea
            name="comment"
            placeholder="Leave a note to explain your decision"
            :label="$t('dashboard.myPurchaseOrderCard.orderedModal.comments')"
            :value="order.comment"
            :rows="3"
            :on-value-change="($event) => (order.comment = $event.target.value)"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        v-focus
        type="button"
        :on-click-handler="() => onModalClose()"
        :color="'gray'"
        >{{
          $t("dashboard.myPurchaseOrderCard.orderedModal.cancelButton")
        }}</Button
      >
      <Button
        v-focus
        type="button"
        :on-click-handler="onSave"
        :loading="loading"
        >{{
          $t("dashboard.myPurchaseOrderCard.orderedModal.saveButton")
        }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/solid";
import {
  Button,
  TextArea,
  Modal,
  DateCalendar,
} from "@/components/shared/index";
import {
  formatDateInHyphenFormat,
  formatDatetimeInHyphenFormat,
  formatPrice,
} from "@/utils/utility_methods.js";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

import errorMixin from "@/components/mixins/errorMixin";
import { mapActions, mapState } from "vuex";
import { DASHBOARD_MODULE } from "@/store/Dashboard/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import OrderPrice from "@/components/Dashboard/PurchaseOrders/OrderPrice";
import {
  UPDATE_PURCHASE_ORDER_STATUS,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { UPDATE_PURCHASE_ORDER } from "@/store/PurchaseOrder/types";
export default {
  components: {
    OrderPrice,
    Modal,
    CheckIcon,
    Button,
    TextArea,
    SwitchCheckbox,
    DateCalendar,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
    fetchRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      order: this.initializeOrder(),
      samePriceRates: true,
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      updatePurchaseOrder: UPDATE_PURCHASE_ORDER,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updatePurchaseOrderStatus: UPDATE_PURCHASE_ORDER_STATUS,
    }),
    formatDateInHyphenFormat,
    formatDatetimeInHyphenFormat,
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.order = this.initializeOrder();
        this.v$.$reset();
      }, 1000);
    },
    initializeOrder() {
      return {
        orderedAt: "",
        comment: "",
      };
    },
    updateStatus(payload) {
      this.loading = true;
      this.updatePurchaseOrderStatus(payload)
        .then(() => {
          this.fetchRequest();
          this.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
    onSave() {
      this.v$.$validate() && this.$refs.orderPrice.v$.$validate();
      if (!this.v$.$error && !this.$refs.orderPrice.v$.$error) {
        const { orderedAt, comment } = this.order;
        const {
          netAmount,
          grossAmount,
          netAmountCurrency,
          grossAmountAmountCurrency,
          shipping,
          tax,
          totalAmount,
        } = this.$refs.orderPrice.order;

        let payload = {
          status: "O",
          reason: comment,
          statusDate: this.formatDatetimeInHyphenFormat(orderedAt),
          purchaseOrder: this.purchaseOrder.id,
        };

        if (!this.samePriceRates) {
          payload = {
            ...payload,
            orderPrices: [
              {
                netAmount: formatPrice(netAmount),
                grossAmount: formatPrice(grossAmount),
                netAmountCurrency: netAmountCurrency,
                grossAmountCurrency: grossAmountAmountCurrency,
                shipping: formatPrice(shipping),
                tax: formatPrice(tax),
                totalAmount: formatPrice(totalAmount),
              },
            ],
          };
        }
        this.updateStatus(payload);
      }
    },
  },
  validations() {
    return { order: { orderedAt: { required } } };
  },
};
</script>
