<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserAddIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{ $t("purchaseRequest.summary.RequestFilesection.fileModal.heading") }}
      </h3>
    </template>
    <template #body>
      <RequestFileFields ref="requestFileFields" :source="source" />
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="isApiLoading('orderDocumentUpload')"
      >
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        :on-click-handler="onSave"
        :loading="isApiLoading('orderDocumentUpload')"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Modal, Button } from "@/components/shared";
import { UserAddIcon } from "@heroicons/vue/solid";
import { mapMutations } from "vuex";

import {
  PURCHASE_REQUEST_MODULE,
  SET_REQUEST_DETAILS,
} from "@/store/PurchaseRequest/types";

export default {
  name: "RequestFileModal",
  components: {
    Modal,
    UserAddIcon,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    saveFiles: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setRequestDetails: SET_REQUEST_DETAILS,
    }),
    onSave() {
      this.$refs.requestFileFields.v$.$validate();
      if (!this.$refs.requestFileFields.v$.$error) {
        this.saveFiles(
          this.$refs.requestFileFields.document,
          this.onModalClose
        );
      }
    },
    onModalClose() {
      this.$refs.requestFileFields.resetValues();
      this.closeModal();
    },
  },
};
</script>
