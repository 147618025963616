<template>
  <DatePicker
    v-model="datePickerValue"
    is-range
    :attributes="defaultDateAttributes()"
    :masks="defaultDateMask"
    :popover="{ visibility: 'focus' }"
  >
    <template #default="{ inputValue, inputEvents }">
      <div class="flex gap-3">
        <div :class="widthClass">
          <label class="block text-sm font-medium text-gray-700 my-1">
            {{ $t("general.validFrom") }}
          </label>
          <input
            :value="inputValue.start"
            :class="[
              'block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm disabled:bg-gray-100',
              { 'border-red-400': validationError },
            ]"
            v-on="inputEvents.start"
          />
          <FieldError
            v-if="validationError"
            :text="$t('general.missingValue')"
          />
        </div>
        <div :class="widthClass">
          <label class="block text-sm font-medium text-gray-700 my-1">
            {{ $t("general.validTo") }}
          </label>
          <input
            :value="inputValue.end"
            :class="[
              'block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm disabled:bg-gray-100',
              { 'border-red-400': validationError },
            ]"
            v-on="inputEvents.end"
          />
          <FieldError
            v-if="validationError"
            :text="$t('general.missingValue')"
          />
        </div>
      </div>
    </template>
  </DatePicker>
</template>

<script>
import { DatePicker } from "v-calendar";
import FieldError from "@/components/shared/FieldError";
import { defaultDateAttributes } from "@/utils/utility_methods";

export default {
  components: {
    DatePicker,
    FieldError,
  },
  props: {
    value: {
      type: [String, Object],
      default: "",
    },
    validationError: {
      type: Boolean,
      default: false,
    },
    widthClass: {
      type: String,
      default: "w-32",
    },
  },
  emits: ["input"],
  computed: {
    datePickerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    defaultDateAttributes,
  },
};
</script>
