import { FusionAuthClient } from "@fusionauth/typescript-client";
import getPkce from "oauth-pkce";
import {
  getCookie,
  getItemFromLocalStorage,
  isObjectEmpty,
  removeItemInLocalStorage,
  setItemInLocalStorage,
  logSentryMessage,
} from "@/utils/utility_methods";
import store from "@/store";
import Swal from "sweetalert2";
import { i18n } from "@/i18n";
const $t = i18n.global.t;
const AUTH_ID = process.env.VUE_APP_AUTH_ID;
const AUTH_DOMAIN = process.env.VUE_APP_AUTH_DOMAIN;
const AUTH_REDIRECT_URI = process.env.VUE_APP_AUTH_REDIRECT_URI;
const AUTH_SECRET_ID = process.env.VUE_APP_AUTH_SECRET_ID;

import {
  AUTH_MODULE,
  GET_CODE_VERIFIER,
  LOGOUT_USER,
  SET_AUTH_TOKENS,
  SET_SELF_SIGNUP_AUTH_TOKENS,
} from "@/store/Auth/types";
import { AUTH_LOGOUT_URL } from "@/utils/constants";
import sha256 from "crypto-js/sha256";

export const AUTH_ERROR_CODES = {
  noPendingInviteFound: "noPendingInviteFound",
  authCodeNotFound: "auth_code_not_found",
  invalidIdentityProvider: "invalidIdentityProvider",
  invalidRedirectUri: "invalid_redirect_uri",
};

export default {
  client: null,
  createClient() {
    this.client = new FusionAuthClient(AUTH_ID, AUTH_DOMAIN);
  },
  saveRouteToRedirect() {
    const path = window.location.pathname;
    if (path.length > 1) {
      const routeToRedirect = {
        path,
        time: new Date().toUTCString(),
      };
      setItemInLocalStorage("routeToRedirect", routeToRedirect);
    }
  },
  async redirectToLoginUrl() {
    const { verifier, challenge } = await this.generatePkceChallenge();
    this.setCodeVerifier(verifier);
    removeItemInLocalStorage("user");
    window.location.href = `${AUTH_DOMAIN}/oauth2/authorize?client_id=${AUTH_ID}&response_type=code&redirect_uri=${AUTH_REDIRECT_URI}&code_challenge=${challenge}&code_challenge_method=S256&scope=offline_access`;
  },
  async redirectToSignupUrl() {
    removeItemInLocalStorage("user");
    const { verifier, challenge } = await this.generatePkceChallenge();
    this.setCodeVerifier(verifier);
    const state = sha256("self-signup").toString();
    window.location.href = `${AUTH_DOMAIN}/oauth2/register?client_id=${AUTH_ID}&response_type=code&redirect_uri=${AUTH_REDIRECT_URI}&code_challenge=${challenge}&code_challenge_method=S256&scope=offline_access&state=${state}`;
  },
  isSignInViaSelfServiceFlow(state) {
    return state === sha256("self-signup").toString();
  },
  generatePkceChallenge() {
    return new Promise((resolve) => {
      getPkce(43, (error, { verifier, challenge }) => {
        if (error) throw error;
        resolve({ verifier, challenge });
      });
    });
  },
  async getOAuthCodeForAccessToken(query) {
    const { code, state } = query;
    const verifier = await this.getCodeVerifier(state);
    this.createClient();
    return this.client.exchangeOAuthCodeForAccessTokenUsingPKCE(
      code,
      AUTH_ID,
      AUTH_SECRET_ID,
      AUTH_REDIRECT_URI,
      verifier
    );
  },
  setCodeVerifier(verifier) {
    window.localStorage.setItem("code_verifier", verifier);
  },
  async getCodeVerifier(state) {
    if (!state) return window.localStorage.getItem("code_verifier");
    return await this.getVerifier(state);
  },
  isUserLoggedIn() {
    return !!(
      !isObjectEmpty(store.getters[`${AUTH_MODULE}/user`]) ||
      (getItemFromLocalStorage("user") && getCookie("user"))
    );
  },
  async getVerifier(state) {
    return (await store.dispatch(`${AUTH_MODULE}/${GET_CODE_VERIFIER}`, state))
      .codeVerifier;
  },

  async retrieveAndSetTokens(query) {
    const { state } = query;
    const tokenResponse = await this.getOAuthCodeForAccessToken(query);
    const { userId, refresh_token, access_token } = tokenResponse.response;
    const payload = {
      user: { id: userId },
      access_token,
      refresh_token,
    };

    if (this.isSignInViaSelfServiceFlow(state)) {
      await store.dispatch(
        `${AUTH_MODULE}/${SET_SELF_SIGNUP_AUTH_TOKENS}`,
        payload
      );
    } else {
      if (state) payload.invite_id = state;
      await store.dispatch(`${AUTH_MODULE}/${SET_AUTH_TOKENS}`, payload);
    }
  },

  handleAuthError(error) {
    logSentryMessage("Auth error", error);
    const errors = error.details?.response?.data?.errors;
    const code = errors?.length
      ? errors[0]?.code
      : error?.exception?.error_reason;

    switch (code) {
      case AUTH_ERROR_CODES.noPendingInviteFound:
        this.toastAndLogout("noPendingInviteFound");
        break;
      case AUTH_ERROR_CODES.invalidIdentityProvider:
      case AUTH_ERROR_CODES.authCodeNotFound:
      case AUTH_ERROR_CODES.invalidRedirectUri:
        window.localStorage.removeItem("code_verifier");
        window.location.href = `${AUTH_LOGOUT_URL}?client_id=${process.env.VUE_APP_AUTH_ID}`;
        break;
      default:
        this.toastAndLogout();
        break;
    }
  },
  toastAndLogout(code = "default") {
    this.swalAuthError(
      $t(`auth.signup.error.${code}.header`),
      $t(`auth.signup.error.${code}.text`)
    ).then(() => store.dispatch(`${AUTH_MODULE}/${LOGOUT_USER}`));
  },

  swalAuthError(title, text) {
    return Swal.fire({
      title,
      text,
      icon: "error",
      confirmButtonText: "Go back to login",
      confirmButtonColor: "#9e4770",
    });
  },
};
