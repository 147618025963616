<template>
  <svg
    id="AO"
    enable-background="new 0 0 100 100"
    version="1.1"
    viewBox="0 0 100 100"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="fill-current"
    stroke="currentColor"
  >
    <path
      d="M94.308,62.418c-0.403-1.444-1.006-2.839-1.862-4.196l-3.661,0.514c-0.659-0.804-1.408-1.512-2.226-2.116l0.33-3.683  c-1.34-0.755-2.784-1.311-4.284-1.65l-2.226,2.953c-1.012-0.101-2.043-0.079-3.071,0.076l-2.37-2.837  c-1.444,0.403-2.839,1.006-4.196,1.862l0.514,3.661c-0.804,0.659-1.512,1.408-2.116,2.226l-3.683-0.33  c-0.755,1.339-1.311,2.784-1.65,4.284l2.953,2.226c-0.101,1.012-0.079,2.043,0.076,3.071l-2.837,2.37  c0.403,1.444,1.006,2.839,1.862,4.196l3.661-0.514c0.659,0.804,1.408,1.512,2.226,2.116l-0.33,3.683  c1.339,0.755,2.784,1.311,4.284,1.65l2.226-2.953c1.012,0.101,2.043,0.079,3.071-0.076l2.37,2.837  c1.444-0.403,2.839-1.006,4.196-1.862l-0.514-3.661c0.804-0.659,1.512-1.408,2.116-2.226l3.683,0.33  c0.755-1.339,1.311-2.784,1.65-4.284l-2.953-2.226c0.101-1.012,0.079-2.043-0.076-3.071L94.308,62.418z M81.888,72.795  c-3.403,1.51-7.386-0.024-8.897-3.427c-1.51-3.403,0.024-7.386,3.427-8.897s7.386,0.024,8.897,3.427  C86.826,67.301,85.291,71.284,81.888,72.795z"
    />
    <path
      d="M68.082,54.071c0.434-2.966,0.464-6.006,0-9.181l-7.108-2.031c-0.552-2.004-1.347-3.907-2.353-5.675l3.592-6.465  c-1.836-2.467-4.025-4.656-6.492-6.492l-6.465,3.592c-1.768-1.006-3.67-1.801-5.675-2.353l-2.031-7.108  c-2.966-0.434-6.006-0.464-9.181,0l-2.031,7.108c-2.004,0.552-3.907,1.347-5.675,2.353l-6.465-3.592  c-2.467,1.836-4.656,4.025-6.492,6.492l3.592,6.465c-1.006,1.768-1.801,3.67-2.353,5.675l-7.108,2.031  c-0.434,2.966-0.464,6.006,0,9.181l7.108,2.031c0.552,2.004,1.347,3.907,2.353,5.675l-3.592,6.465  c1.836,2.467,4.025,4.656,6.492,6.492l6.465-3.592c1.768,1.006,3.67,1.801,5.675,2.353l2.031,7.108c2.966,0.434,6.006,0.464,9.181,0  l2.031-7.108c2.005-0.552,3.907-1.347,5.675-2.353l6.465,3.592c2.467-1.836,4.656-4.025,6.492-6.492l-3.592-6.465  c1.006-1.768,1.801-3.67,2.353-5.675L68.082,54.071z M36.96,62.963c-7.446,0-13.483-6.036-13.483-13.483  c0-7.446,6.036-13.483,13.483-13.483s13.483,6.036,13.483,13.483C50.442,56.926,44.406,62.963,36.96,62.963z"
    />
    <path
      d="M45.948,49.48c0,4.964-4.024,8.989-8.989,8.989s-8.989-4.024-8.989-8.989s4.024-8.988,8.989-8.988  S45.948,44.516,45.948,49.48z"
    />
  </svg>
</template>

<script>
export default {
  name: "DeliverSVG",
};
</script>
