import { post, patch, get, deleteObject, cachedGet } from "../api";
import { ADDRESSES_API_URL } from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";

export const addCompanyAddress = (payload, resolve, reject) => {
  post(ADDRESSES_API_URL, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateCompanyAddress = (
  companyAddressId,
  payload,
  resolve,
  reject
) => {
  if (!companyAddressId) return;

  patch(`${ADDRESSES_API_URL}${companyAddressId}/`, payload)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getCompanyAddresses = (commit, resolve, reject) => {
  get(ADDRESSES_API_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY_ADDRESSES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const fetchCompanyAddress = (
  companyAddressId,
  commit,
  resolve,
  reject
) => {
  if (!companyAddressId) return;

  cachedGet(`${ADDRESSES_API_URL}${companyAddressId}/`)
    .then((response) => {
      commit(CompanyManagementTypes.SET_COMPANY_ADDRESS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteCompanyAddress = (companyAddressId, resolve, reject) => {
  if (!companyAddressId) return;

  deleteObject(`${ADDRESSES_API_URL}${companyAddressId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
