<template>
  <div v-if="showDeleteSection" class="lg:mt-0 mb-4 grid grid-cols-12">
    <div class="mt-4 custom-card no-shadow col-span-12">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 id="cancel-zone" class="section-title">
            {{ $t("purchaseRequest.summary.dangerZone.heading") }}
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              {{ $t("purchaseRequest.summary.dangerZone.helperText") }}
            </p>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center"
        >
          <Button
            :on-click-handler="toggleDeleteModal"
            color="danger"
            :loading="loading"
          >
            {{ $t("purchaseRequest.summary.dangerZone.heading") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog
    :text="$t('purchaseRequest.summary.dangerZone.deleteConfirmation')"
    :show-modal="showDeleteModal"
    :close-modal="toggleDeleteModal"
    :title="$t('purchaseRequest.summary.dangerZone.deleteConfirmationTitle')"
    :item-to-confirm="purchaseRequest.title"
    :confirm-modal="deleteRequest"
  />
</template>
<script>
import { Button, ConfirmDialog } from "@/components/shared/index";
import { mapActions } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  GET_PURCHASE_REQUESTS,
  DELETE_PURCHASE_REQUEST,
  UPDATE_PURCHASE_ORDER_STATUS,
} from "@/store/PurchaseRequest/types";

export default {
  components: { Button, ConfirmDialog },

  props: {
    purchaseRequest: { type: Object, required: true },
    showDeleteSection: { type: Boolean, default: false },
    fetchPurchaseRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showDeleteModal: false,
    };
  },
  computed: {
    isCanceled() {
      return this.purchaseRequest?.purchaseOrder?.status === "CA";
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getPurchaseRequests: GET_PURCHASE_REQUESTS,
      deletePurchaseRequest: DELETE_PURCHASE_REQUEST,
      updatePurchaseOrderStatus: UPDATE_PURCHASE_ORDER_STATUS,
    }),
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },

    cancelString(text) {
      if (this.isCanceled) {
        return text + "Restore";
      } else return text;
    },
    deleteRequest() {
      this.loading = true;
      this.deletePurchaseRequest(this.purchaseRequest.id)
        .then(() => {
          this.showNotification(
            this.$t("purchaseRequest.summary.dangerZone.deleted")
          );
          this.getPurchaseRequests();
          this.$router.push({ name: "Requests" });
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
