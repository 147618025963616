<template>
  <div class="px-2">
    <Popover v-slot="{ open }" :placement="placement" class="relative">
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="focus:outline-none"
      >
        <QuestionMarkCircleIcon
          :class="`w-5 h-5 ${tooltipClass} cursor-pointer `"
          class="inline"
        />
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute z-50 max-w-sm w-screen transform -translate-x-1/2 left-1/2"
        >
          <div
            class="shadow-lg border border-gray-200 rounded-md overflow-hidden"
          >
            <div class="bg-white">
              <div
                class="text-base font-medium p-3 border-b border-gray-100 text-center"
              >
                <slot name="header" />
              </div>
              <div class="max-h-screen overflow-y-auto text-sm p-4">
                <slot name="body" />
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    QuestionMarkCircleIcon,
  },
  props: {
    tooltipClass: {
      type: String,
      default: "text-white",
    },
    placement: {
      type: String,
      default: "bottom",
    },
  },
};
</script>
