<template>
  <div v-if="showLoader" class="w-full flex items-center">
    <HivebuyLoader class="w-5 h-5" />
  </div>
  <div v-else-if="entity" class="text-teal-500 truncate-min">
    <div
      v-if="isEntityDepartment && departmentsExists"
      class="flex items-center truncate-min"
    >
      <div v-if="showDepartmentAsSubheader" class="truncate">
        <div class="pl-9 font-light text-gray-500 truncate -mt-1">
          <TruncateText :text="entityValue?.name" />
        </div>
      </div>
      <div v-else-if="showCostCenter">
        <div class="pl-9 font-light text-gray-500 truncate">
          <TruncateText :text="entityValue?.costCenter" />
        </div>
      </div>
      <div v-else class="truncate-min">
        <div class="flex items-center truncate-min">
          <div
            :class="`h-${avatarDimension} w-${avatarDimension}`"
            class="icon-holder"
          >
            <OfficeBuildingIcon
              :class="`h-${avatarDimension - 1} w-${avatarDimension - 1}`"
              class="icon"
            />
          </div>

          <div class="flex flex-col truncate-min">
            <span :class="textClasses" class="label-heading truncate-min">
              <TruncateText :text="requestedForName"
            /></span>
          </div>
        </div>
        <slot name="departmentDetails" />
      </div>
    </div>
    <div v-else-if="isEntityProject" class="truncate-min">
      <div class="flex items-center truncate-min">
        <div
          :class="`h-${avatarDimension} w-${avatarDimension}`"
          class="justify-center items-center flex mr-2 overflow-hidden text-primary"
        >
          <ProjectSVG
            :class="`h-${avatarDimension - 1} w-${avatarDimension - 1}`"
          />
        </div>

        <div class="flex flex-col truncate-min">
          <div class="text-gray-600 truncate-min">
            <TruncateText :text="requestedForName" />
          </div>
        </div>
      </div>
      <div v-if="showCostCenter">
        <div class="pl-9 font-light text-gray-500 truncate text-sm">
          <TruncateText :text="entityValue?.costCenter" />
        </div>
      </div>
    </div>
    <div v-else-if="entityValue" class="">
      <div class="rounded-full items-center flex">
        <UserWithAvatar
          :avatar-dimension="avatarDimension"
          :show-name="showName"
          :text-classes="['', ...textClasses]"
          :user="entityValue"
        />
      </div>
      <slot name="userDetails" />
    </div>
  </div>
  <div v-else>-</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_DEPARTMENT,
} from "@/store/CompanyManagement/types";
import { UserWithAvatar } from "@/components/shared/index";
import { OfficeBuildingIcon } from "@heroicons/vue/outline";
import ProjectSVG from "@/assets/images/ProjectSVG.vue";
import { GET_PROJECT, PROJECT_MODULE } from "@/store/Project/types";
import { isObjectEmpty } from "@/utils/utility_methods";
import HivebuyLoader from "@/components/shared/HivebuyLoader.vue";

export default {
  components: {
    HivebuyLoader,
    UserWithAvatar,
    OfficeBuildingIcon,
    ProjectSVG,
  },
  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    textClasses: {
      type: Array,
      required: false,
      default: () => ["text-sm font-medium text-gray-600"],
    },
    iconClasses: {
      type: Array,
      required: false,
      default: () => ["text-gray-500 h-6 w-6"],
    },
    avatarDimension: {
      type: Number,
      default: 7,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    isBudget: {
      type: Boolean,
      default: false,
    },
    showDepartmentAsSubheader: {
      type: Boolean,
      default: false,
    },
    showCostCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entityValue: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList", "usersList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
    usersPresent() {
      return this.usersList().length;
    },
    departmentsExists() {
      return Boolean(this.departmentsList() && this.departmentsList().length);
    },
    isEntityDepartment() {
      return (
        (this.entity.department && this.isBudget) ||
        this.entity.type === "department"
      );
    },
    isEntityProject() {
      return (
        (this.entity.project && this.isBudget) || this.entity.type === "project"
      );
    },
    isEntityUser() {
      return (this.entity.user && this.isBudget) || this.entity.type === "user";
    },
    requestedForName() {
      let name = "-";
      if (this.readOnly && this.entity.object?.name) {
        name = this.entity.object?.name;
      } else {
        name = this.entityValue?.name;
      }
      return name;
    },

    showLoader() {
      const loading = ["users", "departments", "projects"];
      return (
        this.entityValue === null &&
        (loading.some((item) => this.isApiLoading(item)) || this.isLoading)
      );
    },
    executeDepartmentApiCall() {
      if (this.isFeatureAllowed("legalEntityFiltering"))
        return this.isEntityDepartment;
      return this.isEntityDepartment && this.departmentsExists;
    },
  },
  watch: {
    departments: {
      async handler() {
        this.entityValue = await this.getEntityValue();
      },
      immediate: true,
      deep: true,
    },
    entity: {
      async handler() {
        this.entityValue = await this.getEntityValue();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getDepartment: GET_DEPARTMENT,
    }),
    ...mapActions(PROJECT_MODULE, {
      getProject: GET_PROJECT,
    }),

    async getEntityValue() {
      let entityValue = null;

      if (isObjectEmpty(this.entity)) return;

      let objectId = this.entity.id || this.entity.object;
      if (this.isBudget) {
        objectId =
          this.entity.department || this.entity.project || this.entity.user;
      }

      try {
        this.isLoading = true;
        if (this.executeDepartmentApiCall) {
          entityValue = await this.getDepartment(objectId);
        } else if (this.isEntityUser) {
          entityValue = objectId;
        } else if (this.isEntityProject) {
          entityValue = await this.getProject(objectId);
        } else {
          entityValue = undefined;
        }
      } finally {
        this.isLoading = false;
      }

      return entityValue;
    },
  },
};
</script>

<style scoped>
.label-sub-heading {
  @apply text-sm text-gray-400 truncate;
}

.label-heading {
  @apply truncate text-base font-medium text-gray-600;
}

.icon-holder {
  @apply rounded-full justify-center items-center flex mr-2 overflow-hidden;
}

.icon {
  @apply text-primary;
}
</style>
