<template>
  <Modal
    :show-modal="showModal"
    :close-modal="closeModal"
    :footer-classes="['flex items-center w-full justify-between px-4 ']"
    :size-classes="['!max-w-[40rem] w-full']"
  >
    <template #icon>
      <CloudUploadIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <div class="flex items-center justify-center">
        <span class="mr-2">{{ $t("purchaseOrder.sepaModal.title") }}</span>
      </div>
    </template>
    <template #body>
      <div>
        <span class="text-base font-medium">
          {{ $t("purchaseOrder.sepaModal.header") }}
        </span>
        <p v-if="notAllowedList?.length" class="py-2 text-hivebuy-red">
          {{ $t("purchaseOrder.sepaModal.ignoredOrders") }}
        </p>

        <dl
          class="divide-y divide-gray-200 border border-gray-200 rounded-lg mt-4"
        >
          <div
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3 text-primary"
          >
            <dt v-for="col in columns" :key="col.field">
              {{ col.header }}
            </dt>
          </div>
          <div
            v-for="(object, index) in allowedList"
            :key="object.id"
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3 text-gray-500"
          >
            <dd v-for="col in columns" :key="col.field">
              <span v-if="col.type == 'text'">
                {{ allowedList[index][col.field] }}
              </span>
              <BooleanDisplay
                v-if="col.type == 'bool'"
                :value="Boolean(allowedList[index][col.field])"
              />
            </dd>
          </div>
          <div
            v-for="object in notAllowedList"
            :key="object.id"
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3 text-gray-500 bg-red-100"
          >
            <dd v-for="(col, index) in columns" :key="col.field">
              <span v-if="col.type == 'text'">
                {{ notAllowedList[index][col.field] }}
              </span>
              <BooleanDisplay
                v-if="col.type == 'bool'"
                :value="Boolean(false)"
              />
            </dd>
          </div>
        </dl>

        <div class="mt-4">
          <label class="block text-sm font-medium text-gray-700">
            {{ $t("purchaseOrder.sepaModal.debtor") }}
          </label>

          <Multiselect
            v-model="debtor"
            class="select-dropdown"
            :searchable="true"
            :options="debtorOptions"
            :can-clear="false"
            mode="single"
            :required="true"
            :can-deselect="false"
          />
        </div>
      </div>
      <div
        v-if="v$.$error"
        class="p-1 border border-hivebuy-red bg-hivebuy-red/20 mt-4 text-hivebuy-red rounded"
      >
        {{ $t("purchaseOrder.sepaModal.debtorError") }}
      </div>
      <div class="mt-4 flex items-center">
        <CloudUploadIcon class="h-6 w-6 text-hivebuy-green mr-4" />
        {{
          $t("purchaseOrder.sepaModal.toBeSent", {
            count: allowedList?.length || 0,
          })
        }}
      </div>
    </template>
    <template #footer>
      <Button v-focus :on-click-handler="closeModal">
        {{ $t("buttons.close") }}
      </Button>
      <Button
        :on-click-handler="initDownload"
        :loading="loading"
        :disabled="!allowedList?.length"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Modal, Button } from "@/components/shared/index";
import { CloudUploadIcon } from "@heroicons/vue/solid";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  DOWNLOAD_SEPA,
} from "@/store/CompanyManagement/types";
import { BooleanDisplay } from "@/components/PurchaseOrder/PurchaseOrdersList/TableComponents/index.js";
import { mapState } from "vuex";
import { INVOICE_MODULE } from "@/store/Invoice/types";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Modal,
    CloudUploadIcon,
    Button,
    BooleanDisplay,
    Multiselect,
  },
  props: {
    model: { type: String, default: "order" },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    objects: {
      type: [Array, null],
      required: true,
    },
    source: {
      type: String,
      default: "purchaseOrder",
    },
  },
  data() {
    return {
      debtor: this.debtors?.length ? this.debtors[0].id : null,
      loading: false,
      list: [],
      errors: [],
      v$: useValidate(),
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, ["debtors"]),
    debtorOptions() {
      if (!this.debtors.length) return [];

      return this.debtors.map((debtor) => {
        return {
          label: debtor.name,
          value: debtor.id,
        };
      });
    },
    columns() {
      return {
        invoice: [
          {
            field: "invoiceNumber",
            header: this.$t("global.invoiceNumber"),
            type: "text",
          },
          {
            field: "approved",
            header: this.$t("invoice.status.A"),
            type: "bool",
          },
        ],
        order: [
          { field: "title", header: "Title", type: "text" },
          { field: "hasInvoice", header: "Invoice", type: "bool" },
          { field: "isComplete", header: "Complete", type: "bool" },
        ],
      }[this.model];
    },
    allowedList() {
      return this.mappedData(
        this.objects?.filter((order) => {
          return this.isValidRecord(order);
        })
      );
    },
    notAllowedList() {
      const list = this.objects?.filter((order) => {
        return !this.isValidRecord(order);
      });
      if (!list.length) return [];
      return this.mappedData(list);
    },
  },

  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      downloadSepa: DOWNLOAD_SEPA,
    }),

    mappedData(data) {
      return { order: this.orderData(data), invoice: this.invoiceData(data) }[
        this.model
      ];
    },
    invoiceData(objects) {
      return objects.map((invoice) => {
        return {
          id: invoice.id,
          invoiceNumber: invoice.invoiceNumber,
          approved: invoice.status == "A",
        };
      });
    },
    orderData(objects) {
      return objects.map((po) => {
        return {
          title: po.title,
          hasInvoice: this.hasInvoice(po),
          invoices: po.invoices,
          isComplete: this.isComplete(po),
        };
      });
    },
    isValidRecord(record) {
      if (this.model == "invoice") return record.status == "A";

      return record.invoices.length && record.status == "CO";
    },
    hasInvoice(record) {
      if (this.model == "invoice") return true;
      return record.invoices.length;
    },
    isComplete(record) {
      if (this.model == "invoice") return true;

      return record.status == "CO";
    },
    payload() {
      let invoices = [];
      if (this.model == "invoice") {
        invoices = this.allowedList.map((invoice) => invoice.id);
      } else {
        this.allowedList.forEach((order) => {
          const invoiceList = order.invoices.map((invoice) => invoice?.invoice);
          if (invoiceList.length) invoices = [...invoices, ...invoiceList];
        });
      }
      return { invoices, debtor: this.debtor };
    },
    initDownload() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.downloadSepa(this.payload())
          .then(() => {
            this.showNotification(
              this.$t("purchaseOrder.sepaModal.uploadSuccess")
            );
            this.closeModal();
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
  },

  validations() {
    return {
      debtor: { required },
    };
  },
};
</script>
