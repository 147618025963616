<template>
  <div id="tab-section" class="hcontainer pt-1 pb-2 mb-2">
    <div class="htabs">
      <div
        class="hglider"
        :style="{ transform: `translateX(${gliderTranslate}%)` }"
      />
      <div
        v-for="option in options"
        :key="option.value"
        class="htab flex items-center gap-x-2"
        :class="{ checked: isChecked(option.value) }"
        @click="changeTab(option.value)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    valueIndex() {
      return this.options.findIndex(
        (option) => option.value === this.modelValue
      );
    },
    gliderTranslate() {
      return this.valueIndex * 100;
    },
    selectedTab: {
      get() {
        return this.modelValue || this.options[0].value;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
  },
  methods: {
    isChecked(tab) {
      return this.modelValue == tab;
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.hcontainer {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.htabs {
  @apply text-sm rounded border border-gray-300 p-1;
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(#9e4770, 0.15), 0 6px 12px 0 rgba(#9e4770, 0.15);
}
.htabs > * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.htab {
  @apply text-sm font-medium rounded text-gray-400 hover:bg-gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 200px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.checked {
  @apply text-white bg-primary hover:bg-primarydark;
}

/* Glider for animation */
.hglider {
  @apply bg-primary rounded;
  position: absolute;
  display: flex;
  height: 24px;
  width: 200px;
  z-index: 1;
  transition: transform 0.25s ease-out;
}

@media (max-width: 700px) {
  .htabs {
    transform: scale(0.6);
  }
}
</style>
