import * as TYPES from "./types.js";
import { updateOrCreate } from "@/utils/utility_methods";
import {
  handleState,
  statusMapping,
} from "@/utils/helpers/invoiceStateHelper.js";
const getInvoiceObject = (payload) => {
  return [
    payload,
    payload.payload,
    payload?.invoice,
    payload?.object,
    payload?.object?.invoice,
  ].find((obj) => obj && obj.id);
};
const setInvoicePurchaseOrders = (state, ipos) => {
  if (!ipos) return;
  const data = Array.isArray(ipos) ? ipos : [ipos];
  data.forEach((invoicePurchaseOrder) => {
    const oldValue = state.invoicePurchaseOrders[invoicePurchaseOrder.id];
    state.invoicePurchaseOrders[invoicePurchaseOrder.id] = Object.assign(
      {},
      oldValue,
      invoicePurchaseOrder
    );
  });
};
const setInvoiceData = (state, payload) => {
  const data = Array.isArray(payload) ? payload : [payload];
  data.forEach((invoice) => {
    state.invoicesObject[invoice.id] = Object.assign(
      {},
      state.invoicesObject[invoice.id],
      invoice
    );
    setInvoicePurchaseOrders(state, invoice.invoicePurchaseOrders);
  });

  return data.map((invoice) => invoice.id);
};
export default {
  [TYPES.SET_OCR_PREVIEW_DATA]: (state, data) => {
    const { ocrUuid, ocrExtraction, ocrResult } = data;
    state.ocrPreview[ocrUuid] = { ocrUuid, ocrResult, ocrExtraction };
  },
  [TYPES.SET_INVOICES]: (
    state,
    { payload, areFiltersUpdated, queryStatus, accountingView = false }
  ) => {
    const processedInvoices = setInvoiceData(state, payload);
    if (accountingView) {
      state.invoicesTableList = processedInvoices;
    }
    const statuses = [...new Set(payload.map((invoice) => invoice.status))];
    if (!statuses.length) return;

    const matchingStatus = statuses.length ? statuses[0] : queryStatus;
    const status = statuses.length > 1 ? "all" : statusMapping[matchingStatus];
    state.invoices[status] = areFiltersUpdated
      ? processedInvoices
      : [...new Set([...state.invoices[status], ...processedInvoices])];
    if (!queryStatus && status != "all") {
      state.invoices.all = areFiltersUpdated
        ? processedInvoices
        : [...new Set([...state.invoices.all, ...processedInvoices])];
    }
    const paid = [...state.invoices.paid];
    payload.forEach((invoice) => {
      if (!invoice.invoicePaid) return;
      paid.push(invoice.id);
    });
    state.invoices.paid = [...new Set(paid)];
  },
  [TYPES.SET_INVOICE_LIST_FILTERS]: (state, payload) => {
    state.invoiceListFilters = payload;
  },
  [TYPES.SET_INVOICE_LIST_PARAMS]: (state, payload) => {
    state.invoiceListParams = payload;
  },
  [TYPES.UPDATE_PO_STATUS]: (state, data) => {
    let invoiceObj = null;
    let invoiceOldObj = null;
    data?.payload.forEach((invoiceIpo) => {
      const { id, status } = invoiceIpo.invoice;
      if (!id || !status) return;
      invoiceOldObj = state.invoicesObject[id];
      invoiceObj = Object.assign({}, state.invoicesObject[id]);
      if (!invoiceObj) return;

      const ipoIndex = invoiceObj.invoicePurchaseOrders.findIndex(
        (ipo) => ipo.id == invoiceIpo.id
      );
      invoiceObj.invoicePurchaseOrders[ipoIndex] = invoiceIpo;
      invoiceObj.status = status;

      state.invoicesObject[id] = invoiceObj;
    });
    handleState(state, invoiceObj, invoiceOldObj);
  },

  [TYPES.SET_INVOICE_PO_LIVE_UPDATE]: (state, payload) => {
    setInvoicePurchaseOrders(state, payload.object.invoice);
  },
  [TYPES.SET_INVOICE_LIVE_UPDATE]: (state, payload) => {
    const object = getInvoiceObject(payload);
    const oldValue = state.invoicesObject[object.id];
    setInvoiceData(state, object);
    handleState(state, object, oldValue);
  },
  [TYPES.SET_ACTIONABLE_INVOICES_FILTERS]: (state, payload) => {
    state.actionableInvoices = payload;
  },

  [TYPES.DELETE_INVOICE_PO_RELATION]: (state, payload) => {
    const index = state.invoices.findIndex(
      (invoice) => invoice.id == payload.invoiceId
    );
    const purchaseOrders = state.invoices[index].invoicePurchaseOrders;
    const indexOfObject = purchaseOrders.findIndex((object) => {
      return object.id == payload.id;
    });
    purchaseOrders.splice(indexOfObject, 1);
    state.invoices[index].invoicePurchaseOrders = purchaseOrders;
  },

  [TYPES.SET_RECENT_INVOICES]: (state, payload) => {
    state.recentlyAddedInvoices = [...state.recentlyAddedInvoices, ...payload];
  },
  [TYPES.SET_ACTIONABLE_INVOICES_FILTERS_LIST]: (state, payload) => {
    payload.forEach((invoice) => {
      state.invoices.dashboard = updateOrCreate(
        state.invoices.dashboard,
        invoice
      );
    });
  },
  [TYPES.SET_DEBTORS]: (state, payload) => {
    state.debtors = payload;
  },
};
