<template>
  <h3 v-if="label">{{ label }}</h3>
  <div class="mb-4" :class="gridClass">
    <div
      v-for="option in options"
      :key="option.name"
      :name="name"
      class="items-center text-center border rounded-md px-3 py-1 text-xs cursor-pointer min-w-full transition duration-300 ease-in-out hover:bg-primary hover:text-white mt-3"
      :class="
        selected(option.value)
          ? 'bg-primary text-white border border-primary '
          : 'bg-white border-gray-300 text-gray-600'
      "
      @click="() => onValueChange(option.value)"
    >
      {{ option.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: { type: Array, default: () => [] },
    value: { type: [String, Number], default: "" },
    label: { type: String, default: "" },
    name: { type: String, required: true },
    onChangeHandler: { type: Function, default: () => {} }
  },
  computed: {
    gridClass() {
      if (this.options?.length == 2) {
        return "grid grid-cols-2 gap-x-2";
      }
      if (this.options?.length == 3) {
        return "grid grid-cols-3 gap-x-2";
      }
      return "grid grid-cols-3 gap-x-2";
    }
  },
  methods: {
    selected(value) {
      return value == this.value;
    },
    onValueChange(value) {
      this.onChangeHandler({
        target: {
          name: this.name,
          value
        }
      });
    }
  }
};
</script>
