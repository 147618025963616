import { getCookie } from "@/utils/utility_methods";

export class Socket {
  constructor() {
    this.ws = null;
    this.url = null;
    this.token = null;
  }

  connect(url) {
    this.url = url;
    this.openConnection();

    window.onunload = () => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.close();
      }
    };
  }
  jsonParsable(data) {
    try {
      JSON.parse(data);
      return true;
    } catch {
      return false;
    }
  }

  openConnection() {
    if ([WebSocket.OPEN].includes(this.ws?.readyState)) return;

    const newToken = getCookie("access_token");
    if (!newToken) {
      console.error("Access token not found. WebSocket connection aborted.");
      return;
    }
    if (this.token === newToken) return;
    try {
      this.token = newToken;
      this.ws = new WebSocket(this.url);
      this.ws.onopen = () => this.onOpen();
      this.ws.onmessage = (event) => this.validateMessage(event);
      this.ws.onclose = this.onClose;
      this.ws.onerror = this.onError;
    } catch (error) {
      console.error("WebSocket initialization error:", error);
    }
  }

  onOpen() {
    console.log("WebSocket connection opened.");
    this.ws.send(
      JSON.stringify({ type: "websocket.connect", token: this.token })
    );
  }
  newUpdate(event) {
    console.log("WebSocket data received:", event.data);
  }
  validateMessage(event) {
    try {
      const data = this.jsonParsable(event.data)
        ? JSON.parse(event.data)
        : null;
      if (!data) return;

      if (data.eventType == "invalid_token") {
        this.token = null;
        this.ws.close(4000, "Invalid token");
      } else {
        this.newUpdate(event);
      }
    } catch (error) {
      console.error("WebSocket data received:", error);
    }
  }

  onClose(event) {
    const { code, reason } = event;
    console.info(
      "WebSocket connection closed. Code: %d, Reason: %s",
      code,
      reason
    );
    if (code === 4000 && reason === "Invalid token") {
      console.info(
        "WebSocket connection closed due to invalid token. Reconnecting..."
      );
      this.openConnection();
    } else {
      console.error("WebSocket connection closed permanently");
    }
  }

  onError(event) {
    console.error("WebSocket error:", event.message);
  }
}
