<template>
  <div>
    <p v-if="withLabel" class="block text-sm font-medium text-gray-700 my-1">
      {{ $t("global.legalEntity") }}
    </p>
    <div>
      <Multiselect
        v-model="selectedValue"
        :searchable="true"
        :options="legalTendencyList"
        :placeholder="$t('global.legalEntity')"
        value-prop="id"
        label="name"
        track-by="name"
        :can-clear="false"
        :loading="isApiLoading('legalTendencies')"
        :mode="mode"
        :class="classes"
        :can-deselect="canDeselect"
      >
        <template #option="{ option }">
          {{ option.name }}
          <span
            v-if="alreadyConfiguredLegalEntities.includes(option.id)"
            class="ml-2"
          >
            <IdentificationIcon class="h-4 w-4 text-hivebuy-green" />
          </span>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_LEGAL_TENDENCIES,
} from "@/store/CompanyManagement/types";
import { IdentificationIcon } from "@heroicons/vue/outline";

export default {
  name: "LegalTendencySelect",
  components: {
    Multiselect,
    IdentificationIcon,
  },
  props: {
    modelValue: {
      type: [String, Array],
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
    alreadyConfiguredLegalEntities: {
      type: Array,
      default: () => [],
    },
    canDeselect: {
      type: Boolean,
      default: true,
    }
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    if (!this.legalTendencyList.length) this.fetchLegalTendencies();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getLegalTendencies: GET_LEGAL_TENDENCIES,
    }),
    fetchLegalTendencies() {
      this.setApiLoading({ key: "legalTendencies", value: true });
      this.getLegalTendencies()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "legalTendencies", value: false });
        });
    },
  },
};
</script>
