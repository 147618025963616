<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-1xl text-gray-500">
        {{ $t("dashboard.myPurchaseOrderCard.deliveredModal.header") }}
      </h3>
      <p class="text-sm mt-2 font-normal text-gray-500">
        {{ $t("dashboard.myPurchaseOrderCard.deliveredModal.headerSubtext") }}
      </p>
    </template>
    <template #body>
      <div>
        <div class="mb-4 mt-8">
          <label class="block text-sm font-medium text-gray-700 my-2">
            {{ $t("dashboard.myPurchaseOrderCard.deliveredModal.date") }}
          </label>
          <DateCalendar
            v-model="order.receivedAt"
            :field-name="
              $t('dashboard.myPurchaseOrderCard.deliveredModal.date')
            "
            :validation-error="v$.order.receivedAt.$error"
            :min-date="orderDate"
          />
        </div>
        <div class="grid grid-cols-12 gap-6 mt-6">
          <div class="col-span-12">
            <TextArea
              id="comment"
              name="comment"
              :placeholder="
                $t(
                  'dashboard.myPurchaseOrderCard.deliveredModal.commentPlaceholder'
                )
              "
              :label="
                $t('dashboard.myPurchaseOrderCard.deliveredModal.comments')
              "
              :value="order.comment"
              :rows="3"
              :on-value-change="
                ($event) => (order.comment = $event.target.value)
              "
            />
          </div>
        </div>
        <div class="mt-4">
          <h3 class="text-primary">
            {{ $t("purchaseRequest.summary.RequestFilesection.header") }}
          </h3>
          <RequestFileFields
            ref="requestFileFields"
            selected-type="delivery_note"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        v-focus
        :loading="loading"
        type="button"
        :on-click-handler="() => onModalClose()"
        :color="'gray'"
        >{{
          $t("dashboard.myPurchaseOrderCard.deliveredModal.cancelButton")
        }}</Button
      >
      <Button
        v-focus
        type="button"
        :on-click-handler="onSave"
        :loading="loading"
        >{{
          $t("dashboard.myPurchaseOrderCard.deliveredModal.saveButton")
        }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import {
  Button,
  TextArea,
  DateCalendar,
  Modal,
} from "@/components/shared/index";
import {
  formatDateInHyphenFormat,
  formatDatetimeInHyphenFormat,
} from "@/utils/utility_methods.js";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import errorMixin from "@/components/mixins/errorMixin";
import { mapActions, mapState } from "vuex";
import { DASHBOARD_MODULE } from "@/store/Dashboard/types";
import {
  UPDATE_PURCHASE_ORDER_STATUS,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  PURCHASE_ORDER_MODULE,
  UPDATE_PURCHASE_ORDER,
} from "@/store/PurchaseOrder/types";
import { PURCHASE_ORDER_STATUS_MAPPING } from "@/utils/constants";

export default {
  components: {
    Modal,
    CheckIcon,
    Button,
    TextArea,
    DateCalendar,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
    fetchRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      order: this.initializeOrder(),
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    orderDate() {
      const orderStatus = this.purchaseOrder.orderStatuses.filter(
        (orderStatus) => {
          return orderStatus.status === "O";
        }
      );
      return orderStatus[0]?.statusDate;
    },
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      updatePurchaseOrder: UPDATE_PURCHASE_ORDER,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      updatePurchaseOrderStatus: UPDATE_PURCHASE_ORDER_STATUS,
    }),
    ...mapActions(PURCHASE_ORDER_MODULE, {
      updatePurchaseOrder: UPDATE_PURCHASE_ORDER,
    }),
    formatDateInHyphenFormat,
    formatDatetimeInHyphenFormat,
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.order = this.initializeOrder();
        this.v$.$reset();
      }, 1000);
    },
    initializeOrder() {
      return {
        date: "",
        comment: "",
      };
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.order[name] = value;
    },

    updateStatus() {
      const { receivedAt, comment } = this.order;
      const payload = {
        status: PURCHASE_ORDER_STATUS_MAPPING.DELIVERED,
        reason: comment,
        statusDate: this.formatDatetimeInHyphenFormat(receivedAt),
        purchaseOrder: this.purchaseOrder.id,
      };

      this.updatePurchaseOrderStatus(payload)
        .then(() => {
          this.fetchRequest();
          this.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
    onSave() {
      this.v$.$validate() && this.$refs.requestFileFields.v$.$validate();
      if (!this.v$.$error && !this.$refs.requestFileFields.v$.$error) {
        this.loading = true;

        if (this.$refs.requestFileFields.document.file) {
          const updateOrderPayload = {
            purchaseOrderId: this.purchaseOrder.id,
            payload: {
              documents: [
                ...this.purchaseOrder.documents,
                { ...this.$refs.requestFileFields.document },
              ],
            },
          };

          this.updatePurchaseOrder(updateOrderPayload)
            .then(() => this.updateStatus())
            .catch((error) => this.showErrorMessage(error));
        } else {
          this.updateStatus();
        }
      }
    },
  },
  validations() {
    return { order: { receivedAt: { required } } };
  },
};
</script>
