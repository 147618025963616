<template>
  <div class="signup-wrapper">
    <div class="login-container">
      <HivebuyLoader v-if="loading" class="h-32 w-32" />
      <div v-else class="flex items-center justify-center">
        <img
          src="@/assets/images/hivebuy_logo.svg"
          alt="Hivebuy"
          class="h-32 w-32"
        />
      </div>
      <h1 class="text-primary font-semibold text-2xl">
        {{ $t("auth.signup.welcomeToHivebuy") }}
      </h1>
      <div class="py-4 text-lg mt-10">{{ text }}</div>
      <div v-if="!throttled" class="flex gap-x-4 justify-center">
        <div
          class="grid grid-cols-2 items-center text-lg font-medium gap-y-2 gap-x-4"
        >
          <HivebuyLoader v-if="loading" class="h-7 w-7 mx-0 justify-self-end" />
          <CheckCircleIcon
            v-else
            class="h-7 w-7 mx-0 justify-self-end text-hivebuy-green"
          />
          <span class="text-left flex gap-x-0">
            {{ $t("home.dashboard") }}
            <WaitingDots v-if="loading" />
          </span>
          <HivebuyLoader v-if="loading" class="h-7 w-7 mx-0 justify-self-end" />
          <CheckCircleIcon
            v-else
            class="h-7 w-7 mx-0 justify-self-end text-hivebuy-green"
          />
          <span class="text-left flex gap-x-0">
            {{ $tc("global.department", 2) }}
            <WaitingDots v-if="loading" />
          </span>
          <HivebuyLoader v-if="loading" class="h-7 w-7 mx-0 justify-self-end" />
          <CheckCircleIcon
            v-else
            class="h-7 w-7 mx-0 justify-self-end text-hivebuy-green"
          />
          <span class="text-left flex gap-x-0">
            {{ $tc("global.supplier", 2) }}
            <WaitingDots v-if="loading" />
          </span>
        </div>
      </div>
      <div v-if="!loading" class="mt-10">
        <Button :on-click-handler="goToDashboard">
          {{ $t("auth.signup.goToDashboard") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import fusionAuth from "@/utils/services/auth";
import { Button, HivebuyLoader, WaitingDots } from "@/components/shared";
import { SET_AUTH_TOKENS, AUTH_MODULE } from "@/store/Auth/types";
import { CheckCircleIcon } from "@heroicons/vue/solid/esm";
import { mapActions } from "vuex";
import {
  USER_CREATION_IN_PROGRESS,
  THROTTLED,
} from "@/utils/errorConstants.js";
export default {
  name: "SignUp",
  components: {
    Button,
    HivebuyLoader,
    CheckCircleIcon,
    WaitingDots,
  },
  data() {
    return {
      typewriterComplete: false,
      retryCounter: 0,
      loading: true,
      throttled: false,
    };
  },
  computed: {
    text() {
      if (this.throttled) return this.$t("auth.signup.throttledText");

      return this.loading
        ? this.$t("auth.signup.loadingText")
        : this.$t("auth.signup.readyText");
    },
  },
  mounted() {
    this.completeTypewriter();
    this.signUp();
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      setAuthTokens: SET_AUTH_TOKENS,
    }),
    async verifyAuth(tokenResponse, state) {
      if (this.retryCounter > 3) {
        this.loading = false;
        //todo: manual retry
        return;
      }
      const { userId, refresh_token, access_token } = tokenResponse.response;
      const payload = {
        user: { id: userId },
        access_token,
        refresh_token,
        invite_id: state,
      };
      this.retryCounter++;
      const response = await this.setAuthTokens(payload);
      if (response.data.status == USER_CREATION_IN_PROGRESS) {
        setTimeout(() => {
          this.verifyAuth(tokenResponse, state);
        }, 5000 * this.retryCounter);
      } else {
        this.loading = false;
      }
    },
    async signUp() {
      try {
        this.loading = true;
        const { state } = this.$route.query;
        const tokenResponse = await fusionAuth.getOAuthCodeForAccessToken(
          this.$route.query
        );
        await this.verifyAuth(tokenResponse, state);
      } catch (error) {
        fusionAuth.handleAuthError(error);
        if (error.errorCode === THROTTLED) {
          this.throttled = true;
        }
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
    completeTypewriter() {
      setTimeout(() => {
        this.typewriterComplete = true;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.signup-wrapper {
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #9e4770, #5c164e, #342347);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  /* background: rgba(255, 255, 255, 0.8); */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  @apply min-w-[90vw] max-w-[90vw] md:min-w-[70vw] md:max-w-[70vw] xl:max-w-[950px] xl:min-w-[950px] bg-gray-50;
}
</style>
