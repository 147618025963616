<template>
  <div class="flex flex-col mt-8">
    <div
      class="flex md:flex-row md:gap-x-10 flex-col items-center justify-between mb-4"
    >
      <div class="w-full">
        <Input
          id="search"
          :value="search"
          name="search"
          :placeholder="$t('general.search')"
          show-icon
          type="search"
          :on-key-up="(event) => (search = event.target.value)"
        />
      </div>
      <CSVUpload type="budget" level="C" @success="onCSVUploaded" />
    </div>
    <div class="table-card overflow-visible no-shadow">
      <div class="inner-wrapper">
        <TableLoader v-if="!areBudgetsFetched" :columns="2" :rows="4" />
        <TreeTable
          v-else-if="areBudgetsFetched && categoriesPresent"
          :value="categories"
          class="p-treetable-lg"
          filter-mode="lenient"
          :filters="{ global: search }"
        >
          <Column field="label" header="Category" expander />
          <Column :header="companyBudgets.name">
            <template #body="slotProps">
              <EditableText
                v-model="slotProps.node.data.budget.amount"
                type="currency"
                :on-confirm-handler="
                  (value) =>
                    updateCompanyBudget(value, slotProps.node.data.budget)
                "
                class="min-w-[6.5rem]"
                :show-edit-icon="false"
                :loading="budgetLoading[slotProps.node.data.budget.id]"
                :dense="true"
                :show-success-animation="false"
                :currency="companyBudgets.totalAmountCurrency"
              />
            </template>
          </Column>
          <template v-if="!search" #footer>
            <tr class="flex text-primary ml-6">
              <td class="w-1/2">Total:</td>
              <td class="w-1/2">
                <i18n-n
                  :value="parseInt(companyBudgets.totalAmount)"
                  format="budget"
                  minimum-fraction-digits="2"
                  :locale="currencyToLocale(company.currency)"
                />
              </td>
            </tr>
          </template>
        </TreeTable>
        <div v-else class="text-center">
          <EmptyState
            :heading-text="'You have no categories'"
            :description-text="
              'Get started by creating a new category and then the budget will be\n' +
              'automatically displayed here.'
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_BUDGETS,
} from "@/store/CompanyManagement/types";

import {
  currencyToLocale,
  isObjectEmpty,
  sumInArray,
} from "@/utils/utility_methods";
import {
  EmptyState,
  Input,
  TableLoader,
  CSVUpload,
} from "@/components/shared/index";
import EditableText from "@/components/shared/EditableText";
import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import BudgetCsvMixin from "@/components/mixins/budgetCsvMixin.js";
export default {
  components: {
    CSVUpload,
    Input,
    TableLoader,
    EmptyState,
    EditableText,
    TreeTable,
    Column,
  },
  mixins: [BudgetCsvMixin],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      companyBudgets: (state) => state.budgets.companyBudgets,
      budgetsData: (state) => state.budgets,
      company: (state) => state.company,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoryTreeWithChildren"]),
    categories() {
      const filteredCategories = this.categoryTreeWithChildren().filter(
        (category) => this.companyBudgets.categories.includes(category.label)
      );

      return filteredCategories.map((category) => ({
        data: this.mappedCategoryData(category),
        ...(category.children?.length && {
          children: category.children
            .filter((childCategory) =>
              this.companyBudgets.categories.includes(childCategory.label)
            )
            .map((childCategory) => ({
              data: this.mappedCategoryData(childCategory),
            })),
        }),
      }));
    },
    categoriesPresent() {
      return this.companyBudgets.categories.length;
    },
    areBudgetsFetched() {
      return !this.isLoading && !isObjectEmpty(this.budgetsData);
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateBudgets: UPDATE_BUDGETS,
    }),
    updateCompanyBudget(value, budget) {
      this.budgetLoading[budget.id] = true;
      const budgetObj = { ...budget, amount: value };
      const payload = {
        budgetLevel: "C",
        budgets: [budgetObj],
        legalEntity: this.legalEntity,
      };
      this.updateBudgets(payload)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.budgetLoading[budget.id] = false));
    },
    mappedCategoryData(category) {
      const index = this.companyBudgets.categories.indexOf(category.label);
      const budget = this.companyBudgets.budgets[index];
      return { ...category, budget };
    },
    currencyToLocale,
    sumInArray,
  },
};
</script>

<style scoped>
:deep(.p-treetable .p-treetable-thead > tr > th) {
  @apply w-[150px] h-[50px];
}

:deep(.p-treetable .p-treetable-tbody > tr > td) {
  @apply w-[150px] h-[50px];
}
</style>
