import { deleteObject, get, patch, post } from "@/utils/services/api";
import * as ContractTypes from "@/store/Contract/types";
import { urlWithParams } from "@/utils/helpers/apis/requiredParams";
import { CONTRACT_API_URL } from "@/utils/services/apiUrls";

export const getContracts = (
  state,
  commit,
  resolve,
  reject,
  { queryParams = {}, areFiltersUpdated = true }
) => {
  get(urlWithParams(CONTRACT_API_URL, null, queryParams))
    .then((response) => {
      commit(ContractTypes.SET_CONTRACTS, {
        payload: response.data?.results || response.data,
        areFiltersUpdated,
      });

      if (response.data?.results) {
        const { currentPage: page, totalPages, totalRecords } = response.data;
        const payload = Object.assign(state.contractsListFilters);

        payload.paginationParams = {
          ...payload.paginationParams,
          ...{ page, totalPages, totalRecords },
        };
        commit(ContractTypes.SET_CONTRACTS_LIST_FILTERS, payload);
      }
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteContract = (commit, resolve, reject, contractId) => {
  if (!contractId) return;

  deleteObject(`${CONTRACT_API_URL}${contractId}/`)
    .then(() => {
      commit(ContractTypes.REMOVE_CONTRACT, contractId);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const saveContract = (commit, resolve, reject, payload) => {
  if (payload?.id) updateContract(commit, resolve, reject, payload.id, payload);
  else addContract(commit, resolve, reject, payload);
};

const addContract = (commit, resolve, reject, payload) => {
  post(CONTRACT_API_URL, payload)
    .then((response) => {
      commit(ContractTypes.SET_CONTRACT, response.data);
      resolve();
    })
    .catch((err) => reject(err));
};

const updateContract = (commit, resolve, reject, contractId, payload) => {
  patch(`${CONTRACT_API_URL}${contractId}/`, payload)
    .then((response) => {
      commit(ContractTypes.SET_CONTRACT, response.data);
      resolve();
    })
    .catch((err) => reject(err));
};

export const getContract = (resolve, reject, contractId) => {
  if (!contractId) return;

  get(`${CONTRACT_API_URL}${contractId}/`)
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
};
