<template>
  <div
    v-if="purchaseRequestItems.length && purchaseRequestItems.length > 1"
    class="flex -mb-2 mt-2 overflow-x-scroll no-scrollbar"
  >
    <div
      v-for="(item, index) in positionedItems"
      :key="item.id"
      class="flex mr-1"
      @click="() => onItemClick(item.position)"
    >
      <span
        class="inline-flex items-center rounded-md bg-gray-50 px-3 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-primary/50 hover:bg-primary hover:text-white transition-colors cursor-pointer line-clamp-1"
        >{{ index + 1 }}. {{ item.name }}</span
      >
    </div>
  </div>
  <div class="products-holder custom-card no-shadow mt-4 space-y-2">
    <template v-for="(product, index) in positionedItems" :key="product.name">
      <div
        :ref="`product-${product.position}`"
        class="product border-b border-gray-200"
      >
        <Product
          :purchase-request="purchaseRequest"
          :currency="currency"
          :product="product"
          :index="index"
          :read-only="readOnly"
          :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
          :approve-loading="approveLoading"
          :product-loading="loading"
          :is-collapsed="productCollapse[product.position]"
          :update-request="updateRequest"
          @on-collapse="toggleCollapse(product.position)"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { Product } from "@/components/PurchaseRequest";

export default {
  components: {
    Product,
  },
  props: {
    loading: { type: Boolean, default: false },
    purchaseRequest: {
      type: Object,
      required: false,
      default: () => {},
    },
    approveLoading: {
      type: Boolean,
      default: false,
    },
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: "",
    },
    purchaseRequestItems: {
      type: Object,
      required: false,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    updateRequest: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      productCollapse: {},
    };
  },
  computed: {
    positionedItems() {
      const newArray = [...this.purchaseRequestItems];
      return newArray.sort((a, b) => (a.position > b.position ? 1 : -1));
    },
  },
  created() {
    const totalProducts = this.positionedItems.length;
    this.positionedItems.map((item, index) => {
      this.productCollapse[item.position] = index > 0 || totalProducts > 2;
    });
  },
  methods: {
    onItemClick(position) {
      this.$refs[`product-${position}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      if (this.productCollapse[position]) {
        this.productCollapse[position] = false;
      }
    },
    toggleCollapse(position) {
      this.productCollapse[position] = !this.productCollapse[position];
    },
  },
};
</script>

<style scoped>
.products-holder .product {
  @apply pb-4 mb-4 py-4;
  padding-top: 0;
}

.products-holder .product:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
  @apply pt-2;
}

.avatar {
  @apply inline-flex items-center justify-center h-10 w-10 rounded-full cursor-pointer text-sm text-white font-semibold;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
