export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    year: {
      type: [String, Number],
      required: true,
    },
    getBudgets: {
      type: Function,
      required: true,
    },
    selectTab: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    legalEntity: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      budgetLoading: {},
      search: "",
    };
  },
  methods: {
    onCSVUploaded() {
      this.getBudgets();
      this.selectTab(this.type);
    },
  },
};
