<template>
  <div v-if="!isFirstVariant" class="md:col-span-6 col-span-12 mt-2">
    <div class="flex items-center gap-x-4">
      <div class="grow">
        <Input
          :id="'name'"
          :error-text="error(v$.variantData.name)"
          :value="variantData.name"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :placeholder="$t('catalogue.catalogueItemForm.namePlaceholder')"
          :label="$t('catalogue.catalogueItemForm.name')"
        />
      </div>
      <Input
        :id="'shortName'"
        :value="variantData.shortName"
        :name="'shortName'"
        :on-value-change="onAttributeChange"
        :type="'text'"
        :max-length="10"
        :placeholder="$t('catalogue.catalogueItemForm.shortNamePlaceholder')"
        :label="$t('catalogue.catalogueItemForm.shortName')"
      />
    </div>
  </div>
  <div class="mt-1 md:col-span-6 col-span-12">
    <div class="label">
      {{ $t("catalogue.catalogueItemForm.description") }}
    </div>
    <HivebuyEditor v-model="variantData.description" />
  </div>
  <div class="md:col-span-6 col-span-12 mt-2">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="flex flex-col justify-between md:w-4/10 h-fit">
        <div>
          <label class="flex text-sm font-medium text-gray-700">
            {{ $t("catalogue.catalogueItemForm.image") }}
            <span class="ml-1 mt-0.5">
              <Tip help theme="tooltip" placement="right">
                <template #header>
                  {{ $t("catalogue.catalogueItemForm.image") }}
                </template>
                <template #content>
                  <p class="tooltip-subtext">
                    {{
                      $t("catalogue.catalogueItemForm.attachmentTooltipText", {
                        type: $t("global.image"),
                      })
                    }}
                  </p>
                </template>
              </Tip>
            </span>
          </label>
          <FileInput
            :files="variantData.images"
            :type="'image'"
            :show-paste="true"
            :max-files-count="10"
            class="h-full"
            small
            @list-update="saveItemImage"
          />
        </div>
      </div>

      <div class="flex flex-col justify-between md:w-6/10">
        <div class="space-y-4">
          <Input
            :id="'url'"
            :value="variantData.url"
            :name="'url'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="$t('catalogue.catalogueItemForm.urlPlaceholder')"
            :label="$t('catalogue.catalogueItemForm.url')"
          />
          <Input
            :id="'article'"
            :value="variantData.article"
            :name="'article'"
            :on-value-change="onAttributeChange"
            :placeholder="$t('catalogue.catalogueItemForm.article')"
            :label="$t('catalogue.catalogueItemForm.article')"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="md:col-span-6 col-span-12 mt-2">
    <Input
      :id="'estimatedDelivery'"
      :value="variantData.estimatedDelivery"
      :name="'estimatedDelivery'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.estimatedDelivery')"
      :label="$t('catalogue.catalogueItemForm.estimatedDelivery')"
    />
  </div>
  <div class="md:col-span-6 col-span-12 mt-2">
    <PriceAndVAT
      v-model="pricing"
      is-mandatory
      :error-text="error(v$.variantData?.grossAmount)"
      :precision="BACKEND_PRICE_DECIMAL_POINTS"
    />
  </div>
  <div class="md:col-span-3 col-span-12 mt-2">
    <Input
      :id="'minimalQuantity'"
      :error-text="error(v$.variantData.minimalQuantity)"
      :value="variantData.minimalQuantity"
      :name="'minimalQuantity'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.minimalOrderQuantity')"
      :label="$t('catalogue.catalogueItemForm.minimalOrderQuantity')"
    />
  </div>
  <div class="md:col-span-3 col-span-12 mt-2">
    <Input
      :id="'quantityStep'"
      :error-text="error(v$.variantData.quantityStep)"
      :value="variantData.quantityStep"
      :name="'quantityStep'"
      :on-value-change="onAttributeChange"
      :type="'number'"
      :placeholder="$t('catalogue.catalogueItemForm.quantityStep')"
      :label="$t('catalogue.catalogueItemForm.quantityStep')"
    />
  </div>

  <div
    v-if="isFeatureAllowed('customFields') && variantData.customFields?.length"
    class="md:col-span-6 col-span-12 mt-2"
  >
    <CustomFieldForm
      v-model="variantData.customFields"
      :source="'catalogue'"
      :is-in-modal="true"
    />
  </div>
  <div class="md:col-span-6 col-span-12 text-center">
    <div class="space-y-4">
      <div
        class="mt-4 md:col-span-3 col-span-12 text-lg leading-6 font-medium space-y-1"
      >
        <label class="flex text-sm font-medium text-gray-700">
          {{ $t("global.file") }}
          <span class="ml-1 mt-0.5">
            <Tip help theme="tooltip" placement="right">
              <template #header>
                {{ $t("global.file") }}
              </template>
              <template #content>
                <p class="tooltip-subtext">
                  {{
                    $t("catalogue.catalogueItemForm.attachmentTooltipText", {
                      type: $t("global.file"),
                    })
                  }}
                </p>
              </template>
            </Tip>
          </span>
        </label>
        <FileInput
          key="files"
          :files="variantData.files"
          :max-files-count="10"
          @list-update="saveItemFiles"
        />
      </div>
    </div>
  </div>
  <div class="flex flex-col col-span-full mt-2">
    <SwitchCheckbox
      v-model="variantData.active"
      :label="$t('catalogue.catalogueItemForm.variantActive')"
    />
    <p class="text-xs text-gray-700 font-normal leading-5">
      {{ $t("catalogue.catalogueItemForm.variantActiveText") }}
    </p>
  </div>
</template>

<script>
import { Input, PriceAndVAT, SwitchCheckbox } from "@/components/shared";
import CustomFieldForm from "@/components/CustomFields/CustomFieldForm.vue";
import { isHashEqual, isObjectEmpty } from "@/utils/utility_methods";
import { BACKEND_PRICE_DECIMAL_POINTS, CURRENCY } from "@/utils/constants";
import {
  catalogueMixin,
  errorMixin,
  addFilesImagesToObjectMixin,
} from "@/components/mixins";
import { required, requiredIf } from "@vuelidate/validators";
import { greaterThanZero } from "@/utils/helpers/purchaseRequestHelper";
import useValidate from "@vuelidate/core";
import { HivebuyEditor } from "design-buy";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ItemVariant",
  components: {
    CustomFieldForm,
    Input,
    PriceAndVAT,
    HivebuyEditor,
    SwitchCheckbox,
  },
  mixins: [errorMixin, catalogueMixin, addFilesImagesToObjectMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    catalogueItem: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      v$: useValidate(),
      pricing: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCustomFieldsBySource"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      company: (state) => state.company,
    }),
    isFirstVariant() {
      return this.index < 1;
    },
    variantData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const itemVariant = Object.assign({}, newValue);
        this.$emit("update:modelValue", itemVariant);
      },
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (
          this.isEditMode &&
          !isObjectEmpty(newVal) &&
          !isHashEqual(newVal, oldVal)
        ) {
          this.pricing = this.defaultPricingDetails();
        }
      },
    },
    pricing: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        const { netAmount, grossAmount, currency, vat } = newVal;
        this.variantData = {
          ...this.variantData,
          grossAmount,
          netAmount,
          vat,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
          currency: currency,
        };
      },
    },
  },
  created() {
    this.BACKEND_PRICE_DECIMAL_POINTS = BACKEND_PRICE_DECIMAL_POINTS;
  },
  mounted() {
    this.pricing = this.defaultPricingDetails();
    this.variantData.customFields = this.getCustomFields();
  },
  methods: {
    async saveItemFiles(files) {
      this.variantData.files = await this.formatObjects(files);
    },
    getCustomFields() {
      if (!this.isFeatureAllowed("customFields")) return [];

      let catalogueCustomFields = this.getCustomFieldsBySource("catalogue");

      if (!this.variantData?.id) {
        this.variantData.customFields =
          this.catalogueItem.itemVariants[0]?.customFields ||
          catalogueCustomFields;
      }

      if (this.variantData?.customFields?.length) {
        catalogueCustomFields = catalogueCustomFields.map((field) => {
          const existingField = this.variantData.customFields.find(
            (item) => item.name === field.name
          );
          if (existingField) {
            field = Object.assign({}, field, {
              value: existingField.value,
              customField: field.id,
            });
            if (this.isEditMode) {
              field.id = existingField.id;
            } else {
              delete field.id;
            }
          } else {
            field.customField = field.id;
          }
          return field;
        });
      }

      return catalogueCustomFields;
    },
    defaultPricingDetails() {
      const defaultCurrency = CURRENCY.find(
        (currency) => currency.value === this.company.currency
      );
      const { vatRates } = defaultCurrency;
      return {
        inputPrice:
          this.modelValue?.netGross === "gross"
            ? this.modelValue?.grossAmount
            : this.modelValue?.netAmount,
        vat: this.modelValue?.vat || vatRates[0].value,
        currency: this.modelValue?.netAmountCurrency || defaultCurrency.value,
        grossAmount: this.modelValue?.grossAmount,
        netAmount: this.modelValue?.netAmount,
        netGross: this.modelValue?.netGross ? this.modelValue?.netGross : "net",
      };
    },
    async saveItemImage(images) {
      this.variantData.images = await this.formatObjects(images, "image");
    },
    onAttributeChange(event) {
      const { name, value, type } = event.target;
      this.variantData[name] = type === "number" && !value ? null : value;
    },
  },
  validations() {
    return {
      variantData: {
        name: { required: requiredIf(() => !this.isFirstVariant) },
        vat: { required },
        grossAmount: { minValue: greaterThanZero },
        minimalQuantity: { required, minValue: (value) => value > 0 },
        quantityStep: { required, minValue: (value) => value > 0 },
      },
    };
  },
};
</script>
