<template>
  <Tip>
    <template #content>
      <div class="text-sm font-normal px-1 py-2">
        <UserWithAvatar :user="user" show-name show-email />
      </div>
      <div
        v-if="isRequester || hasApproved || hasRejected || isBuyer"
        class="font-normal flex flex-col gap-y-1 mt-2"
      >
        <div v-if="isRequester" class="flex gap-x-2 items-center">
          <HeartIcon class="h-5 w-5 text-hivebuy-red" />
          <span>{{ $t("purchaseRequest.summary.comment.isRequester") }}</span>
        </div>
        <div
          v-if="isApproverOrRejecter('Approved')"
          class="flex gap-x-2 items-center"
        >
          <ThumbUpIcon class="h-5 w-5 text-hivebuy-green" />
          <span>{{ $t("purchaseRequest.summary.comment.hasApproved") }}</span>
        </div>
        <div
          v-if="isApproverOrRejecter('Rejected')"
          class="flex gap-x-2 items-center"
        >
          <ThumbDownIcon class="h-5 w-5 text-hivebuy-red" />
          <span>{{ $t("purchaseRequest.summary.comment.hasRejected") }}</span>
        </div>
        <div v-if="isBuyer" class="flex gap-x-2 items-center">
          <ShoppingCartIcon class="h-5 w-5 text-blue-400" />
          <span>{{ $t("purchaseRequest.summary.comment.isBuyer") }}</span>
        </div>
      </div>
    </template>
  </Tip>
</template>
<script>
import { Tip, UserWithAvatar } from "@/components/shared/index";
import {
  HeartIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  ShoppingCartIcon,
} from "@heroicons/vue/solid";
import { mapState } from "vuex";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
export default {
  components: {
    Tip,
    UserWithAvatar,
    HeartIcon,
    ThumbUpIcon,
    ThumbDownIcon,
    ShoppingCartIcon,
  },
  inject: ["purchaseRequest"],
  props: {
    user: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["openCurrentRequest"]),
    isRequester() {
      return this.purchaseRequest.requestedBy === this.user;
    },

    isBuyer() {
      let isBuyer;

      if (this.openCurrentRequest) {
        if (
          this.openCurrentRequest.isItemLevelApprover &&
          !this.openCurrentRequest.autoProcessing
        ) {
          this.openCurrentRequest?.items?.forEach((item) => {
            isBuyer = item?.results?.users?.find(
              (result) => result === this.user
            );
          });
        } else if (!this.openCurrentRequest.autoProcessing) {
          isBuyer = this.openCurrentRequest?.result?.users?.find(
            (result) => result === this.user
          );
        }
      }
      return !!isBuyer;
    },
  },
  methods: {
    isApproverOrRejecter(status) {
      let result;
      if (this.openCurrentRequest) {
        if (this.openCurrentRequest.isItemLevelApprover) {
          this.openCurrentRequest.items.forEach((item) => {
            result = item.approvals.find((approval) => {
              return (
                approval.status === status && approval.decidedBy === this.user
              );
            });
          });
        } else {
          result = this.openCurrentRequest?.approvals?.find((approval) => {
            return (
              approval.status === status && approval.decidedBy === this.user
            );
          });
        }
      }

      return !!result;
    },
  },
};
</script>
