import { get } from "@/utils/services/api.js";
import * as Types from "@/store/Downloads/types.js";
import { formattedUrlApi } from "@/utils/utility_methods";
import { DOWNLOAD_URL, DOWNLOAD_FILES } from "@/utils/services/apiUrls";
export const downloadFile = (file, resolve, reject) => {
  if (!file) return;

  get(`${DOWNLOAD_URL}${file}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const downloadFiles = (files, commit, resolve, reject) => {
  if (!files.length) return;

  get(`${DOWNLOAD_FILES}?ids=${files.join(",")}`)
    .then((response) => {
      commit(Types.SET_DOWNLOAD_STATUS, {
        storeState: "downloadZip",
        downloading: true,
        value: null,
      });
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const downloadList = (commit, resolve, reject, params) => {
  get(formattedUrlApi(DOWNLOAD_URL, params))
    .then((response) => {
      commit(Types.SET_LIST, response.data.results);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
