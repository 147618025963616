import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  data() {
    return {
      includePersonalBudget: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["budgets"]),
    totalBudget() {
      let total = 0;
      const { totalDepartmentBudget, totalCompanyBudget, totalPersonalBudget } =
        this.budgets;

      total = totalDepartmentBudget + totalCompanyBudget;
      return this.includePersonalBudget
        ? total
        : totalDepartmentBudget + totalPersonalBudget;
    },
    amountRemaining() {
      const { approvedPurchasedRequestAmount, pendingPurchasedRequestAmount } =
        this.budgets;
      return (
        this.totalBudget -
        (approvedPurchasedRequestAmount + pendingPurchasedRequestAmount)
      );
    },
    usedAmountPercentage() {
      const { approvedPurchasedRequestAmount } = this.budgets;
      return `${this.progressBarPercentage(
        approvedPurchasedRequestAmount,
        this.totalBudget
      )}%`;
    },
    pendingAmountPercentage() {
      const { pendingPurchasedRequestAmount } = this.budgets;
      return `${this.progressBarPercentage(
        pendingPurchasedRequestAmount,
        this.totalBudget
      )}%`;
    },
    remainingAmountPercentage() {
      return `${this.progressBarPercentage(
        this.amountRemaining,
        this.totalBudget
      )}%`;
    },
  },
  methods: {
    progressBarPercentage(amount, total) {
      return ((amount / total) * 100).toFixed(2);
    },
  },
};
