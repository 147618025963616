<template>
  <div class="bg-yellow-50 border-gray-300 border text-center mb-2 rounded">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <span class="flex p-2 rounded-lg">
          <BriefcaseIcon class="h-8 w-8 text-hivebuy_plum" />
        </span>
        <div class="w-0 flex-1 flex items-center text-base">
          <p class="ml-3 font-medium text-hivebuy_plum truncate">
            <span v-if="substituteActive" class="md:inline text-center">
              {{
                $t("alerts.away", {
                  time: `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`,
                })
              }}
              <div class="flex items-center">
                <UserWithAvatar
                  :user="user.substitute"
                  :show-email="false"
                  :show-name-only="true"
                  :show-tooltip="false"
                  :avatar-dimension="8"
                />
                <p class="ml-1">{{ $t("profile.isYourSubstitute") }}</p>
              </div>
            </span>
            <span v-else>{{ $t("alerts.awayTimeEnded") }}</span>
          </p>
        </div>
        <div class="flex items-center align-middle gap-x-4">
          <Button :on-click-handler="changeDetails">{{
            $t("buttons.change")
          }}</Button>
          <Button :on-click-handler="resetAway" :color="'gray'">
            {{ $t("profile.imBackButton") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BriefcaseIcon } from "@heroicons/vue/outline";
import { UserWithAvatar } from "@/components/shared/index";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_USER,
} from "@/store/CompanyManagement/types";
import { mapActions, mapMutations } from "vuex";
import { Button } from "@/components/shared";
import { AUTH_MODULE, SET_USER, UPDATE_ME } from "@/store/Auth/types";

export default {
  components: {
    BriefcaseIcon,
    UserWithAvatar,
    Button,
  },

  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      substituteUserDetails: {},
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  computed: {
    substituteActive() {
      const subDate = new Date(this.user.substituteUntil).getTime();
      const dateToday = new Date().getTime();
      return subDate >= dateToday;
    },
  },
  mounted() {
    this.countDown();
  },
  methods: {
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUser: GET_USER,
    }),
    changeDetails() {
      this.$router.push({ name: "EditProfile" });
    },
    resetAway() {
      this.updateMe({
        payload: {
          substitute: null,
          substitute_until: null,
        },
      })
        .then((response) => {
          this.showNotification(`Welcome back ${response.data.firstName}`);
          this.setUser(response.data);
        })
        .catch((error) => this.showErrorMessage(error));
    },
    countDown() {
      const countDownDate = new Date(this.user.substituteUntil).getTime();

      setInterval(() => {
        const now = new Date().getTime();

        const distance = countDownDate - now;
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
  },
};
</script>
