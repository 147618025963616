import actions from "@/store/Dashboard/actions";
import mutations from "@/store/Dashboard/mutations";
import state from "@/store/Dashboard/state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
