import {
  getInitialsOfFullName,
  getInitialsOfName,
  isObjectEmpty,
} from "@/utils/utility_methods";
import { USER_ROLES_MAPPING } from "@/utils/constants";
import { USER_BADGE_STYLE } from "../../utils/constants";

import memberDetailsMixin from "@/components/mixins/memberDetailsMixin";
export default {
  mixins: [memberDetailsMixin],
  methods: {
    rolesPresent(user) {
      return user.roles.length;
    },
    // Return String of all roles of the user, separated by ","
    role(user) {
      let { roles } = user;

      if (!roles.length) {
        return "";
      }
      roles = roles.map((role) => USER_ROLES_MAPPING[role]);
      return roles.join(", ");
    },

    // Return full role name of the abbreviation, e.g. "O" -> "Admin"
    roleName(role) {
      return USER_ROLES_MAPPING[role];
    },

    roleStyle(role) {
      return USER_BADGE_STYLE[role];
    },

    fullName(user) {
      let name = "Name not found";

      if (!isObjectEmpty(user)) {
        const { firstName, lastName, email } = user;

        if (firstName && lastName) {
          name = `${firstName} ${lastName}`;
        } else if (firstName || lastName) {
          name = firstName || lastName;
        } else if (user?.name) {
          name = user.name;
        } else {
          name = email.split("@")[0];
        }
      }

      return name;
    },
    nameInitials(user) {
      const { firstName, lastName } = user;
      const userFullname = this.fullName(user);

      return firstName
        ? getInitialsOfName(firstName, lastName)
        : getInitialsOfFullName(userFullname);
    },
  },
};
