export default {
  data() {
    return {
      loadMoreRecordsLoading: false,
      observer: null,
    };
  },
  methods: {
    handleOpen(ref) {
      const dropdown = this.$refs?.[ref]?.$el.querySelector(
        ".multiselect-dropdown"
      );

      if (dropdown) {
        this.setupObserver(dropdown);
      }
    },
    setupObserver(dropdown) {
      if (this.observer) {
        this.observer.disconnect();
      }

      this.observeLastOption(dropdown);
    },
    observeLastOption(dropdown) {
      const lastOption = dropdown.querySelector(
        ".multiselect-option:last-child"
      );

      if (lastOption) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.loadMoreOptions(dropdown);
            }
          });
        });

        this.observer.observe(lastOption);
      }
    },
    async loadMoreOptions(dropdown) {
      if (!this.loadMoreRecordsLoading) {
        this.loadMoreRecordsLoading = true;
        await this.loadMore();
        this.loadMoreRecordsLoading = false;

        this.observeLastOption(dropdown);
      }
    },
    handleClose() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
  },
};
