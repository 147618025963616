<template>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div :class="['col-span-12 no-shadow', { 'custom-card': !showBarOnly }]">
      <div v-if="!showBarOnly" class="section-title">
        {{ $t("purchaseRequest.summary.invoiceBudget.title") }}
      </div>
      <div
        v-if="!showBarOnly"
        class="block xl:flex items-center justify-between"
      >
        <div class="flex items-center xl:mb-0 mb-1">
          <span><CashIcon class="w-6 h-6 text-hivebuy-green mr-3" /> </span>
          <span class="text-sm font-medium">
            {{ $t("purchaseRequest.summary.invoiceBudget.total") }}
            <i18n-n
              :value="parseFloat(invoiceBudget.totalAmount)"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </span>
        </div>
        <div class="block xl:flex items-center">
          <div class="font-medium text-sm flex items-center xl:mb-0 mb-1">
            <span class="text-primary mx-1">
              {{ $t("purchaseRequest.summary.invoiceBudget.used") }}
            </span>
            <span class="font-bold">
              <i18n-n
                :value="parseFloat(invoiceBudget.usedAmount)"
                format="currency"
                :locale="currencyToLocale(company.currency)"
              />
            </span>
          </div>
          <div
            class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
          >
            <span class="text-primary mx-1">
              {{ $t("purchaseRequest.summary.invoiceBudget.remaining") }}
            </span>
            <span
              class="font-bold"
              :class="
                parseFloat(invoiceBudget.remainingAmount) < 0
                  ? 'text-hivebuy-red'
                  : ''
              "
            >
              <i18n-n
                :value="parseFloat(invoiceBudget.remainingAmount)"
                format="currency"
                :locale="currencyToLocale(company.currency)"
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        <div
          v-if="invoiceBudget.remainingAmount >= 0"
          class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
        >
          <div
            v-if="invoiceBudgetBar.usedPercentage"
            class="progress-bar progress-bar-approved"
            :style="{ width: `${invoiceBudgetBar.usedPercentage}%` }"
          >
            <i18n-n
              :value="invoiceBudgetBar.usedPercentage / 100"
              format="percent"
              :locale="currencyToLocale(currency)"
            />
          </div>
          <div
            v-if="invoiceBudgetBar.remainingPercentage"
            class="flex-grow text-center text-xs font-medium"
          >
            <i18n-n
              :value="invoiceBudgetBar.remainingPercentage / 100"
              format="percent"
              :locale="currencyToLocale(currency)"
            />
          </div>
        </div>
        <div
          v-else
          class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
        >
          <div class="progress-bar progress-bar-overused !w-full">> 100%</div>
        </div>
        <!-- <span v-if="!showBarOnly" class="text-sm">
          {{ $t("purchaseRequest.summary.invoiceBudget.usageText") }}
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { CashIcon } from "@heroicons/vue/outline";
import { currencyToLocale } from "@/utils/utility_methods";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { PURCHASE_REQUEST_STATUSES } from "@/utils/constants";

export default {
  name: "InvoiceBudget",
  components: {
    CashIcon,
  },
  props: {
    currency: {
      type: String,
      default: null,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
    showBarOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    invoiceBudget() {
      const { netAmount, purchaseOrderInvoices } = this.purchaseOrder;
      const totalAmount = Number(netAmount);
      const approvedPurchaseOrderInvoices = purchaseOrderInvoices.filter(
        ({ status }) => status === PURCHASE_REQUEST_STATUSES.APPROVED
      );
      const usedAmount = approvedPurchaseOrderInvoices?.length
        ? approvedPurchaseOrderInvoices.reduce(
            (acc, { exchangeRate, totalNetAmount }) => {
              let amount = Number(totalNetAmount);
              if (Number(exchangeRate)) {
                amount = amount * exchangeRate;
              }
              return acc + Number(amount);
            },
            0
          )
        : 0;

      const remainingAmount = totalAmount - usedAmount;

      return { totalAmount, usedAmount, remainingAmount };
    },
    invoiceBudgetBar() {
      const { usedAmount, totalAmount } = this.invoiceBudget;
      const usedPercentage = (usedAmount / totalAmount) * 100;
      const remainingPercentage = 100 - usedPercentage;

      return { usedPercentage, remainingPercentage };
    },
  },
  methods: {
    currencyToLocale,
  },
};
</script>

<style scoped>
.progress {
  height: 22px;
  overflow: hidden;
  @apply bg-hivebuy-yellow;
  @apply rounded-md ring-gray-400;

  -webkit-box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
}
.progress-bar {
  @apply font-normal;
  float: left;
  width: 0%;
  height: 100%;
  line-height: 16px;
  color: #fff;
  @apply text-xs;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-approved {
  @apply bg-hivebuy-green;
}
.progress-bar-remaining {
  @apply bg-yellow-400;
}

.progress-bar-overused {
  @apply bg-red-400;
}
.product-budget .product-budget {
  @apply mt-3 w-full;
}

.product-budget .product-budget .mt-8 {
  @apply mt-0;
}

.product-budget .budget {
  @apply w-full;
}

.product-budget .budget .product-budget .wrapper {
  @apply mb-0;
}

.product-budget .budget .product-budget .wrapper .icon-holder {
  @apply w-12 h-12;
}

.product-budget .budget .product-budget .wrapper .icon-holder svg {
  @apply w-6 h-6;
}

.product-budget .budget .product-budget .wrapper .content-holder {
  @apply h-12 py-0;
}
</style>
