<template>
  <div class="detail-wrapper">
    <OrdersList
      v-if="showState.poList"
      :add-purchase-order-to-list="selectInvoicePoForEdit"
      :assigned-orders="
        invoiceObject.invoicePurchaseOrders.map((ipo) => {
          return { purchaseOrder: ipo.purchaseOrder.id, id: ipo.id };
        })
      "
      :update-invoice="updateInvoice"
      :set-visible-state="setVisibleState"
    />
    <div v-if="showState.form" class="flex-1">
      <div v-if="invoiceObject.complete || invoiceObject.status == 'A'">
        <p class="text-primary text-center mt-10 p-10">
          {{ $t("invoice.form.instantApprovedNoActionNecessary") }}
        </p>
        <div class="flex items-center text-base font-medium justify-center">
          <button
            class="hover:text-primary cursor-pointer py-2 px-4 border border-gray-300 rounded hover:bg-gray-100"
            @click="
              () => {
                setVisibleState();
              }
            "
          >
            {{ $t("buttons.close") }}
          </button>
        </div>
      </div>
      <Form
        v-else
        :invoice-object="invoiceObject"
        :show-assign-button="showAssignButton"
        :set-visible-state="setVisibleState"
        :close-action="() => setVisibleState()"
        :update-filter="updateFilter"
      />
    </div>
  </div>
</template>
<script>
import { Form } from "@/components/InvoiceApproval/index.js";
import OrdersList from "@/components/InvoiceApproval/OrdersList.vue";
export default {
  components: { Form, OrdersList },
  props: {
    invoiceObject: { type: Object, required: true },
    updateInvoice: { type: Function, required: true },
    selectInvoicePoForEdit: { type: Function, default: () => {} },
    showState: { type: Object, required: true },
    setVisibleState: { type: Function, required: true },
    updateFilter: { type: Function, required: true },
    showAssignButton: { type: Boolean, default: true },
  },
  data() {
    return {
      showPoDetails: false,
      selectedPOforDetails: {},
    };
  },
  methods: {
    addPoDetails(purchaseOrder) {
      this.showPoDetails = true;
      this.selectedPOforDetails = purchaseOrder;
    },
  },
};
</script>

<style scoped>
.detail-wrapper {
  @apply flex  relative h-full;
}
</style>
