<template>
  <Modal
    :show-modal="open"
    :close-modal="closeSelectDeptModal"
    :prevent-click-outside="true"
  >
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      {{ $t("purchaseRequest.detail.multipleDepartmentSelectionHeader") }}
    </template>
    <template #body>
      <div class="mt-2">
        <p class="text-sm text-gray-500 mb-6">
          {{ $t("purchaseRequest.detail.multipleDepartmentSelectionText") }}
        </p>
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div
            v-for="department in departments"
            :key="`dept${department.id}`"
            class="cursor-pointer relative rounded-lg border border-gray-300 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-primary group"
            @click="
              () => {
                selectDepartment(department);
              }
            "
          >
            <div class="flex-1 min-w-0">
              <div class="focus:outline-none text-center">
                <span class="absolute inset-0" aria-hidden="true" />
                <p
                  class="text-sm font-medium text-gray-900 text-center group-hover:text-primary"
                >
                  {{ department.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="w-0 h-0"></button>
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import { Modal } from "@/components/shared/index";

export default {
  components: {
    Modal,
    CheckIcon,
  },
  props: {
    departments: {
      type: Array,
      required: true,
      default: () => [],
    },
    setDepartment: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    closeSelectDeptModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    selectDepartment(dept) {
      this.setDepartment(dept);
      this.closeSelectDeptModal();
    },
  },
};
</script>
