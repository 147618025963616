export default {
  getProject: (state) => (id) => {
    return state.projects[id];
  },
  projectName: (state) => (id) => {
    return state.projects[id]?.name;
  },
  projectsList:
    (state) =>
    (search = "") => {
      const list = Object.values(state.projects);
      return list.filter((proj) => {
        return (
          proj.name.toLowerCase().includes(search) ||
          proj.costCenter?.toLowerCase().includes(search) ||
          proj.projectNumber?.toLowerCase().includes(search)
        );
      });
    },
};
