import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import { PURCHASE_REQUEST_STATUSES } from "@/utils/constants";
import requestTypeMixin from "@/components/mixins/requestTypeMixin";
import { isObjectEmpty } from "@/utils/utility_methods";
import approvalsMixin from "@/components/mixins/approvalsMixin";

export default {
  mixins: [requestTypeMixin, approvalsMixin],
  computed: {
    ...mapState(AUTH_MODULE, ["user", "isUserAdmin"]),

    canUserResendWebhook() {
      return (
        !isObjectEmpty(this.purchaseRequest) &&
        this.purchaseRequest.purchaseOrder &&
        this.isFeatureAllowed("webhooks") &&
        this.isFeatureAllowed("zapierIntegration") &&
        this.user.roles.some((role) => ["O", "MD", "AC"].includes(role))
      );
    },
  },
  methods: {
    editableRequest(purchaseRequest) {
      if (
        !purchaseRequest?.id ||
        this.isSupplierRequest(purchaseRequest) ||
        !this.companySetting("SPECIAL_PROJECT_PROCESS")?.active
      ) {
        return false;
      }
      const isOriginalRequester = this.user.id === purchaseRequest.requestedBy;

      return Boolean(
        purchaseRequest.status === PURCHASE_REQUEST_STATUSES.PENDING &&
          isOriginalRequester &&
          this.areAllApprovalsPending(purchaseRequest)
      );
    },
    areAllApprovalsPending(purchaseRequest) {
      let onlyPendingApprovals = false;
      if (purchaseRequest.isItemLevelApprover) {
        if (purchaseRequest.items && purchaseRequest.items.length) {
          purchaseRequest.items.forEach((item) => {
            onlyPendingApprovals = this.checkApprovals(item);
          });
        }
      } else {
        onlyPendingApprovals = this.checkApprovals(purchaseRequest);
      }
      return onlyPendingApprovals;
    },
    originalRequester(purchaseRequest) {
      return (
        this.user.id === purchaseRequest.requestedBy?.id ||
        this.user.id === purchaseRequest.requestedBy
      );
    },
    isDeleteAllowed(purchaseRequest) {
      if (!purchaseRequest || !purchaseRequest.isItemLevelApprover)
        return false;
      const isOriginalRequester = this.originalRequester(purchaseRequest);

      return (
        (this.isUserAdmin || isOriginalRequester) &&
        this.areAllApprovalsPending(purchaseRequest)
      );
    },

    showDangerZone(purchaseRequest) {
      if (!purchaseRequest) return false;

      return (
        !this.isSupplierRequest(purchaseRequest) &&
        this.isDeleteAllowed(purchaseRequest)
      );
    },
  },
};
