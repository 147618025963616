const MAPPING = {
  companySuppliers: {
    fields: [],
    expand: ["categories", "responsible_user", "supplier"],
  },
  project: {
    expand: ["participating_departments.department"],
  },
};

export const paramsForKey = (key) => {
  const mappedKeys = MAPPING[key];
  const response = {};
  if (mappedKeys?.fields?.length) response.fields = mappedKeys.fields.join(",");
  if (mappedKeys?.expand?.length) response.expand = mappedKeys.expand.join(",");
  if (mappedKeys?.omit?.length) response.omit = mappedKeys.omit.join(",");

  return response;
};
