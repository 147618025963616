<template>
  <div
    :class="[
      'custom-card no-shadow',
      { 'flex justify-between gap-2 ': allowDelete },
    ]"
  >
    <div>
      <h2 class="text-base font-semibold text-primary mb-4">
        {{ $t("purchaseRequest.summary.RequestFilesection.header") }}
      </h2>
      <div v-if="!filesSource?.length" class="text-sm">
        {{ $t("purchaseRequest.summary.RequestFilesection.emptyState") }}
      </div>

      <div v-if="filesSource?.length">
        <div v-for="(document, index) in filesSource" :key="index" class="mb-4">
          <div class="flex items-center justify-between mb-1">
            <div class="mr-4">{{ document.title }}</div>
            <span v-if="document.file">
              <a
                ref="downloadFile"
                :href="document.file"
                download="download"
                target="_blank"
                class="hidden"
              ></a>
              <div class="flex gap-1">
                <div
                  class="py-0.5 px-1 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
                  @click="() => onDownloadFile(index)"
                >
                  <DownloadIcon class="h-5 w-5 text-hivebuy-green" />
                </div>
                <div
                  v-if="allowDelete"
                  class="py-0.5 px-1 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
                  @click="() => deleteFile(index)"
                >
                  <TrashIcon class="h-5 w-5 text-hivebuy-red" />
                </div>
              </div>
            </span>
          </div>
          <div class="flex items-center justify-between">
            <div
              :class="`px-2 py-0.5 rounded-xl text-xs font-medium text-white ${typeOptionClass(
                document.type
              )}`"
            >
              {{ getTypeLabelByValue(document.type) }}
            </div>
            <div
              v-if="document.createdAt"
              class="flex items-center text-sm text-gray-600 ml-4"
            >
              <UserWithAvatar
                :user="document.createdBy"
                :show-name-only="true"
              />
              <span class="text-primary ml-1 mr-1">@</span>
              {{ formattedDate(document.createdAt) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isUserOnlyAuditor" class="flex flex-col h-full justify-center">
      <Button
        :on-click-handler="toggleFileUploadModalClose"
        :color-outline="true"
        class="h-12"
      >
        <div class="flex gap-1">
          <UploadIcon class="h-5 w-5" />
          {{
            $t(
              "purchaseRequest.summary.RequestFilesection.uploadDocumentButton"
            )
          }}
        </div>
      </Button>
    </div>
  </div>
  <RequestFileModal
    :source="source"
    :show-modal="showFileUploadModal"
    :close-modal="toggleFileUploadModalClose"
    :save-files="saveFiles"
  />
</template>

<script>
import { Button, UserWithAvatar } from "@/components/shared";
import RequestFileModal from "@/components/PurchaseRequest/SummarySections/RequestFileModal.vue";
import { DownloadIcon, TrashIcon, UploadIcon } from "@heroicons/vue/outline";
import { requestFileMixin } from "@/components/mixins";
export default {
  name: "RequestFiles",
  components: {
    UserWithAvatar,
    DownloadIcon,
    Button,
    RequestFileModal,
    TrashIcon,
    UploadIcon,
  },
  mixins: [requestFileMixin],
  props: {
    sourceObject: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      required: true,
    },
    fileKey: {
      type: String,
      default: "documents",
    },
    saveFiles: {
      type: Function,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    deleteFile: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      showFileUploadModal: false,
    };
  },
  computed: {
    filesSource() {
      return this.sourceObject[this.fileKey];
    },
  },

  methods: {
    toggleFileUploadModalClose() {
      this.showFileUploadModal = !this.showFileUploadModal;
    },
    getTypeLabelByValue(type) {
      return this.typeOptions.find((option) => option.value === type)?.label;
    },
    onDownloadFile(index) {
      this.$refs.downloadFile[index].click();
    },
  },
};
</script>
