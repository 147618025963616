<template>
  <!-- <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3"> -->
  <ul
    role="list"
    class="divide-y divide-gray-200 border border-gray-300 rounded-md mt-4"
  >
    <li v-for="item in options" :key="item.label">
      <div
        :id="item.label"
        class="block hover:bg-gray-50 rounded-md cursor-pointer group"
        @click="() => onSelect(item.value)"
      >
        <div class="px-4 py-4 flex items-center sm:px-6">
          <div
            class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
          >
            <div class="truncate">
              <div class="flex text-sm">
                <p class="font-medium text-base text-primary truncate">
                  {{ $t(`workflow.editor.${item.label}`) }}
                </p>
              </div>
              <div class="mt-1 flex">
                <div
                  class="flex items-center text-sm text-gray-500 whitespace-normal"
                >
                  <p>{{ $t(`workflow.editor.${item.description}`) }}</p>
                </div>
              </div>
            </div>
            <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
              <div class="flex overflow-hidden -space-x-1"></div>
            </div>
          </div>
          <div class="ml-5 flex-shrink-0 inline-flex align-middle">
            <ChevronRightIcon
              class="h-5 w-5 text-gray-400 group-hover:text-primary"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </li>
  </ul>
  <!-- <div
      v-for="item in options"
      :key="item.label"
      class="
        px-4
        py-5
        shadow
        rounded-lg
        overflow-hidden
        sm:p-6
        group
        bg-white
        hover:bg-teal-500
        hover:shadow-lg
        hover:border-transparent
      "
      @click="() => onSelect(item.value)"
    >
      <dt class="text-sm font-medium truncate group-hover:text-white">
        {{ item.label }}
      </dt>
    </div> -->
  <!-- </dl> -->
</template>
<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ChevronRightIcon,
  },
  props: {
    options: { type: Array, required: true },
    onSelect: { type: Function, required: true },
  },
};
</script>
