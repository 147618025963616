<template>
  <dl
    class="space-y-4 border border-gray-200 text-sm pt-4 col-span-12 mx-8 px-4 py-4 rounded bg-gray-50"
  >
    <div class="flex justify-between">
      <dt class="font-medium text-gray-900 flex items-center">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.totalPriceNet") }}
      </dt>
      <dd v-if="samePriceRates" class="text-gray-700">
        <i18n-n
          :value="formatPrice(purchaseOrder.netAmount)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.netAmountCurrency)"
        />
      </dd>
      <dd v-else>
        <CurrencyInput
          id="total_net_amount"
          v-model.lazy="order.netAmount"
          :error-text="v$.order.netAmount ? error(v$.order.netAmount) : ''"
        />
      </dd>
    </div>
    <div class="flex justify-between">
      <dt class="flex font-medium text-gray-900 flex items-center">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.tax") }}
      </dt>
      <dd v-if="samePriceRates" class="text-gray-700">
        <i18n-n
          :value="formatPrice(purchaseOrder.tax)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.taxCurrency)"
        />
      </dd>
      <dd v-else>
        <i18n-n
          :value="formatPrice(order.tax)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.taxCurrency)"
        />
      </dd>
    </div>
    <div class="flex justify-between">
      <dt class="font-medium text-gray-900 flex items-center">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.totalPriceGross") }}
      </dt>
      <dd v-if="samePriceRates" class="text-gray-700">
        <i18n-n
          :value="formatPrice(purchaseOrder.grossAmount)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.grossAmountCurrency)"
        />
      </dd>
      <dd v-else>
        <CurrencyInput
          id="total_gross_amount"
          v-model.lazy="order.grossAmount"
          :error-text="grossPriceError"
        />
      </dd>
    </div>
    <div class="flex justify-between">
      <dt class="font-medium text-gray-900 flex items-center">
        {{ $t("dashboard.myPurchaseOrderCard.orderedModal.shipping") }}
      </dt>
      <dd v-if="samePriceRates" class="text-gray-700">
        <i18n-n
          :value="formatPrice(purchaseOrder.shipping)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.shippingCurrency)"
        />
      </dd>
      <dd v-else>
        <CurrencyInput
          id="shipping"
          v-model.lazy="order.shipping"
          :error-text="v$.order.shipping ? error(v$.order.shipping) : ''"
        />
      </dd>
    </div>
    <div class="flex justify-between">
      <dt class="font-medium text-gray-900 flex items-center">
        {{ $t("purchaseRequest.summary.total") }}
      </dt>
      <dd v-if="samePriceRates" class="text-gray-900">
        <i18n-n
          :value="formatPrice(purchaseOrder.totalAmount)"
          format="currency"
          :locale="currencyToLocale(purchaseOrder.netAmountCurrency)"
        />
      </dd>
      <dd v-else class="text-gray-900">
        <i18n-n
          :value="formatPrice(order.totalAmount)"
          format="currency"
          :locale="currencyToLocale(order.netAmountCurrency)"
        />
      </dd>
    </div>
  </dl>
</template>

<script>
import { currencyToLocale, formatPrice } from "@/utils/utility_methods";
import { minValue, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import { CurrencyInput } from "@/components/shared/index";

export default {
  components: {
    CurrencyInput,
  },
  mixins: [errorMixin],
  props: {
    samePriceRates: {
      type: Boolean,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      order: this.initializeOrder(),
      v$: useValidate(),
    };
  },
  computed: {
    grossPriceError() {
      let error = "";
      const { grossAmount, tax } = this.v$.order;
      error = this.error(grossAmount) || this.error(tax);
      return error;
    },
  },
  watch: {
    order: {
      handler(newValue) {
        const { netAmount, grossAmount, shipping } = newValue;
        this.order.tax = this.calculateTaxPrice(
          netAmount || 0,
          grossAmount || 0
        );
        this.order.totalAmount = this.calculateTotalPrice(
          shipping || 0,
          grossAmount || 0
        );
      },
      deep: true,
    },
  },
  methods: {
    currencyToLocale,
    initializeOrder() {
      const {
        netAmount,
        netAmountCurrency,
        grossAmount,
        grossAmountCurrency,
        shipping,
      } = this.purchaseOrder;
      return {
        netAmount,
        netAmountCurrency,
        grossAmount,
        grossAmountCurrency,
        shipping,
        tax: this.calculateTaxPrice(netAmount, grossAmount),
        totalAmount: this.calculateTotalPrice(shipping, grossAmount),
      };
    },
    calculateTaxPrice(netAmount, grossAmount) {
      return this.formatPrice(grossAmount) - this.formatPrice(netAmount);
    },
    calculateTotalPrice(shipping, grossAmount) {
      return this.formatPrice(shipping) + this.formatPrice(grossAmount);
    },
    formatPrice,
  },
  validations() {
    if (!this.samePriceRates) {
      return {
        order: {
          netAmount: { required },
          grossAmount: { required },
          shipping: { required },
          tax: { grossGreaterThanNet: minValue(0) },
        },
      };
    }
  },
};
</script>
