<template>
  <div>
    <div class="flex items-center justify-between">
      <span class="flex items-center">
        <h3 class="page-title">
          {{ $t("companyManagement.companySupplier.companyCompanySupplier") }}
        </h3>
      </span>
      <Toggle v-model="supplierType" :options="tabOptions" />
      <div class="flex items-center gap-x-4">
        <button
          v-if="!isUserOnlyAuditor"
          type="button"
          class="ml-2 flex font-semibold items-center text-primary hover:text-primarydark focus:outline-none"
          @click="showCompanySupplierModal"
        >
          <PlusCircleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
          {{ $t("companyManagement.companySupplier.addCompanySupplierButton") }}
        </button>
        <CSVUpload type="companysupplier" @success="onCSVUploaded" />
      </div>
    </div>
    <div class="mt-2 sm:rounded-md sm:overflow-hidden shadow">
      <CompanySuppliersList
        ref="supplerList"
        :on-edit="onEdit"
        :supplier-type="supplierType"
      />
    </div>
    <CompanySupplierForm
      :show-modal="showModal"
      :close-modal-handler="onModalClose"
      :company-supplier-to-edit="editObject"
    />
  </div>
</template>

<script>
import { PlusCircleIcon } from "@heroicons/vue/solid";
import CompanySuppliersList from "@/components/CompanyManagement/CompanySuppliers/CompanySuppliersList";
import { CSVUpload } from "@/components/shared/index";
import CompanySupplierForm from "@/components/CompanyManagement/CompanySuppliers/CompanySupplierForm";
import Toggle from "@/components/shared/Toggle.vue";

export default {
  name: "CompanySuppliers",
  components: {
    CompanySuppliersList,
    PlusCircleIcon,
    CSVUpload,
    CompanySupplierForm,
    Toggle,
  },

  data() {
    return {
      showModal: false,
      editObject: null,
      supplierType: "companySuppliers",
    };
  },
  computed: {
    tabOptions() {
      return [
        { label: this.$tc("global.supplier", 2), value: "companySuppliers" },
        {
          label: this.$tc("global.integratedSupplier", 2),
          value: "integratedSuppliers",
        },
      ];
    },
  },
  methods: {
    onEdit(companySupplier) {
      this.editObject = companySupplier;
      this.showCompanySupplierModal();
    },
    onModalClose() {
      this.showModal = false;
      this.editObject = null;
    },
    showCompanySupplierModal() {
      this.showModal = true;
    },
    onCSVUploaded() {
      this.$refs.supplerList.fetchCompanySuppliers();
    },
  },
};
</script>
