import * as ContractTypes from "@/store/Contract/types";
import {
  deleteContract,
  getContract,
  getContracts,
  saveContract,
} from "@/utils/services/models/contract";

export default {
  [ContractTypes.GET_CONTRACTS]: (
    { commit, state },
    { queryParams = {}, areFiltersUpdated = true }
  ) => {
    return new Promise((resolve, reject) => {
      getContracts(state, commit, resolve, reject, {
        queryParams,
        areFiltersUpdated,
      });
    });
  },

  [ContractTypes.DELETE_CONTRACT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      deleteContract(commit, resolve, reject, payload);
    });
  },

  [ContractTypes.SAVE_CONTRACT]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      saveContract(commit, resolve, reject, payload);
    });
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ContractTypes.GET_CONTRACT]: ({ commit }, contractId) => {
    return new Promise((resolve, reject) => {
      getContract(resolve, reject, contractId);
    });
  },
};
