<template>
  <div v-if="isApiLoading('userRelevantNews')">
    <div
      class="h-[100px] flex flex-col w-full rounded items-center justify-center"
    >
      <HivebuyLoader class="w-10 h-10" />
    </div>
  </div>
  <div v-else-if="unreadNews.length">
    <div>
      <div
        class="flex rounded justify-center px-2 py-1 border"
        :class="severityClasses"
      >
        <div
          class="flex items-center gap-x-2 cursor-pointer"
          @click="newsOpen = !newsOpen"
        >
          <ChevronDownIcon
            :class="[
              'h-4 w-4 transition-transform',
              { 'rotate-180': newsOpen }
            ]"
          />
          <span>{{
            $t("news.youHaveNews", {
              count: unreadNews.length,
              news: $tc("global.news", unreadNews.length)
            })
          }}</span>
          <ChevronDownIcon
            :class="[
              'h-4 w-4 transition-transform',
              { 'rotate-180': newsOpen }
            ]"
          />
        </div>
      </div>
      <CollapseTransition>
        <div
          v-if="newsOpen"
          class="grid grid-cols-1 gap-y-2 gap-x-4 mt-2"
          :class="{ 'lg:grid-cols-2': unreadNews.length > 1 }"
        >
          <div
            v-for="relevantNews in unreadNews"
            :key="relevantNews.id"
            class="overflow-hidden rounded-xl border border-gray-200 shadow flex flex-col"
          >
            <div
              class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-3"
            >
              <div
                class="text-sm font-medium leading-6 text-gray-900 flex items-center gap-x-2"
              >
                {{ relevantNews.news.title }}
                <div>
                  <div
                    :class="`px-2 py-0.5 rounded-xl text-xs font-medium text-white ${severityOptionClass(
                      relevantNews.news.severity
                    )}`"
                  >
                    {{
                      titleize(
                        $t(
                          `news.newsModal.severityOptions.${relevantNews.news.severity}`
                        )
                      )
                    }}
                  </div>
                </div>
              </div>
              <Menu as="div" class="relative ml-auto">
                <MenuButton
                  class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Open options</span>
                  <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  >
                    <MenuItem v-slot="{ active }">
                      <div
                        :class="[
                          active ? 'bg-gray-50' : '',
                          'flex items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        ]"
                        @click="() => onMarkAsRead(relevantNews.id)"
                      >
                        <CheckIcon class="h-4 w-4" />
                        {{ $t("global.decisions.markAsRead") }}
                      </div>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
            <div class="px-3 py-2 bg-white flex-1">
              <!-- eslint-disable vue/no-v-html -->
              <p
                id="news-description"
                class="text-sm leading-6 text-gray-600"
                v-html="relevantNews.news.description"
              />
            </div>
            <div
              v-if="relevantNews.news?.files?.length"
              class="border-t border-gray-300 px-3 py-2"
            >
              <div class="flex flex-row items-center gap-x-4">
                <a
                  v-for="file in relevantNews.news.files"
                  :key="file"
                  :href="file.file"
                  download="download"
                  target="_blank"
                  class="flex items-center p-2 border border-gray-300 rounded gap-x-2 w-fit bg-white hover:bg-hivebuy-green/20"
                >
                  <DocumentDownloadIcon class="h-9 w-9" />
                  <div>
                    <ul class="max-w-[10rem] text-sm">
                      <li class="truncate">
                        <TruncateText :text="file.title" />
                      </li>
                    </ul>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </CollapseTransition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import {
  DASHBOARD_MODULE,
  GET_USER_RELEVANT_NEWS,
  MARK_NEWS_AS_READ
} from "@/store/Dashboard/types";
import { HivebuyLoader } from "@/components/shared";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { DotsHorizontalIcon } from "@heroicons/vue/solid";
import { NEWS_SEVERITY_OPTIONS_CLASS_MAPPING } from "@/utils/constants";
import { titleize } from "@/utils/utility_methods";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/solid/esm";

import { DocumentDownloadIcon } from "@heroicons/vue/outline";

export default {
  name: "UserRelevantNews",
  components: {
    HivebuyLoader,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsHorizontalIcon,
    ChevronDownIcon,
    CollapseTransition,
    DocumentDownloadIcon,
    CheckIcon
  },
  data() {
    return {
      newsOpen: false
    };
  },
  computed: {
    ...mapState(DASHBOARD_MODULE, ["userRelevantNews"]),
    unreadNews() {
      return this.userRelevantNews.filter((news) => !news.isRead);
    },
    highestSeverity() {
      let result = "information";
      if (this.unreadNews.some((news) => news.news.severity === "critical")) {
        result = "critical";
      } else if (
        this.unreadNews.some((news) => news.news.severity === "normal")
      ) {
        result = "normal";
      }
      return result;
    },

    severityClasses() {
      return {
        critical: "bg-hivebuy-red/30 text-red-800 border-hivebuy-red",
        normal: "bg-blue-400/30 text-blue-800 border-blue-400",
        information: "bg-hivebuy-green/20 text-gray-700 border-hivebuy-green"
      }[this.highestSeverity];
    }
  },
  mounted() {
    !this.userRelevantNews.length && this.fetchUserRelevantNews();
  },
  methods: {
    titleize,
    ...mapActions(DASHBOARD_MODULE, {
      getUserRelevantNews: GET_USER_RELEVANT_NEWS,
      markedNewsAsRead: MARK_NEWS_AS_READ
    }),
    fetchUserRelevantNews() {
      this.setApiLoading({ key: "userRelevantNews", value: true });
      this.getUserRelevantNews()
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({ key: "userRelevantNews", value: false })
        );
    },
    severityOptionClass(type) {
      return NEWS_SEVERITY_OPTIONS_CLASS_MAPPING[type];
    },
    onMarkAsRead(newsId) {
      this.setApiLoading({ key: "markNewsAsRead", value: true });
      this.markedNewsAsRead({ id: newsId, payload: { isRead: true } })
        .catch((error) => {
          console.log("error", error);
          this.showErrorMessage(error);
        })
        .finally(() =>
          this.setApiLoading({ key: "markNewsAsRead", value: false })
        );
    }
  }
};
</script>

<style>
#news-description ul li {
  @apply list-disc list-inside;
}

#news-description ol li {
  @apply list-decimal list-inside;
}

#news-description h1 {
  @apply text-lg font-semibold my-2;
}
</style>
