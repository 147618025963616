<template>
  <div class="text-sm font-medium">
    <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
      <span class="icon-column bg-primary text-white">
        <DeliverSVG class="h-5 w-5" />
      </span>
      <div class="text-column ml-4 mt-0">
        <div class="flex">
          <span class="text-gray-400 mr-1">
            {{ $t("timeline.partiallyDelivered") }}
          </span>
          <span class="text-gray-900 font-semibold">
            <UserWithAvatar :user="details.createdBy" :show-name-only="true" />
          </span>
        </div>
        <p class="text-sm md:text-xs text-gray-800 mt-2">
          {{ details.reason }}
        </p>
        <div v-if="isValuePresent(details.quantity)" class="flex items-center">
          <span class="text-gray-400 mr-2">
            {{ $t("purchaseRequest.detail.quantity") }}:
          </span>
          <span class="text-gray-900 font-semibold">{{
            parseInt(details.quantity)
          }}</span>
        </div>
        <div v-if="isValuePresent(details.amount)" class="flex items-center">
          <span class="text-gray-400 mr-2">
            {{ $t("purchaseRequest.detail.price") }}:
          </span>
          <i18n-n
            :value="parseFloat(details.amount)"
            format="currency"
            :locale="currencyToLocale(details.amountCurrency)"
            class="text-gray-900 font-semibold"
          />
        </div>
        <div class="text-xs text-gray-500 my-1">
          <TimeDisplay :time="details.createdAt" />
        </div>
      </div>
      <div class="avatar-column">
        <UserWithAvatar
          :user="details.createdBy"
          :avatar-dimension="9"
          show-tooltip
          class="cursor-help"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DeliverSVG from "@/assets/images/DeliverSVG.vue";
import { TimeDisplay, UserWithAvatar } from "@/components/shared/index";
import { currencyToLocale, isValuePresent } from "@/utils/utility_methods";
export default {
  components: { DeliverSVG, TimeDisplay, UserWithAvatar },
  props: {
    details: { type: Object, required: true },
  },
  methods: {
    isValuePresent,
    currencyToLocale,
  },
};
</script>
<style scoped>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5 mt-1;
}
</style>
