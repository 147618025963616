<template>
  <table class="min-w-full divide-y divide-gray-200 animate-pulse">
    <thead class="bg-primary text-white">
      <tr>
        <th
          v-for="index in columns"
          :key="index"
          scope="col"
          :width="columnWidth"
        >
          <div class="flex items-center justify-center px-4 py-4">
            <span class="w-full h-6 bg-gray-100 rounded-md" />
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="bg-gray-50 divide-y divide-gray-200">
      <tr v-for="row in rows" :key="row">
        <td v-for="col in columns" :key="col">
          <div class="items-center justify-center px-4 py-2">
            <p class="w-full h-3 bg-gray-200 rounded-md mb-2" />
            <p class="w-3/4 h-3 bg-gray-200 rounded-md mb-2" />
            <p class="w-2/4 h-3 bg-gray-200 rounded-md" />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 5,
      required: false,
    },
    columns: {
      type: Number,
      default: 5,
      required: false,
    },
  },
  computed: {
    columnWidth() {
      return 1 / parseInt(this.columns);
    },
  },
};
</script>
