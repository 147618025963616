<template>
  <ul class="min-w-full divide-y divide-gray-200 animate-pulse">
    <li v-for="index in rows" :key="index">
      <div class="items-center justify-center px-4 py-2">
        <p class="w-full h-3 bg-gray-200 rounded-md mb-2"></p>
        <p class="w-3/4 h-3 bg-gray-200 rounded-md mb-2"></p>
        <p class="w-2/4 h-3 bg-gray-200 rounded-md"></p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 5,
      required: false,
    },
    columns: {
      type: Number,
      default: 2,
      required: false,
    },
  },

  computed: {
    columnWidth() {
      return 1 / parseInt(this.columns);
    },
  },
};
</script>
