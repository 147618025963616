import { post, get, deleteObject, patch } from "../api";
import { WORKFLOW_API_URL } from "@/utils/services/apiUrls.js";
import {
  approvalData,
  resultData,
  conditionData,
} from "@/utils/helpers/workflowHelper.js";
import {
  SET_WORKFLOW,
  SET_PUR_WORKFLOWS,
  SET_INVOICE_WORKFLOWS,
} from "@/store/CompanyManagement/types.js";
const formatData = (payload) => {
  const { conditions, requiredApprovalTypes, result } = payload.details;
  return {
    title: payload.title,
    order: payload.order,
    conditions: conditionData(conditions),
    requiredApprovalTypes: approvalData(requiredApprovalTypes),
    result: resultData(result),
    flowType: payload.flowType,
  };
};
export const addWorkflow = (payload, commit, resolve, reject) => {
  post(WORKFLOW_API_URL, formatData(payload))
    .then((response) => {
      commit(SET_WORKFLOW, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const updateWorkflow = (id, payload, commit, resolve, reject) => {
  if (!id) return;

  patch(`${WORKFLOW_API_URL}${id}/`, formatData(payload))
    .then((response) => {
      commit(SET_WORKFLOW, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getWorkflows = (type, commit, resolve, reject) => {
  get(WORKFLOW_API_URL, { params: { flow_type: type } })
    .then((response) => {
      if (type == "purchase_request") {
        commit(SET_PUR_WORKFLOWS, response.data);
      } else {
        commit(SET_INVOICE_WORKFLOWS, response.data);
      }
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getWorkflow = (id, resolve, reject) => {
  if (!id) return;

  get(`${WORKFLOW_API_URL}${id}/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteWorkflow = (workflowId, resolve, reject) => {
  if (!workflowId) return;

  deleteObject(`${WORKFLOW_API_URL}${workflowId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const setWorkflowsOrder = (commit, resolve, reject, payload) => {
  post(
    `${WORKFLOW_API_URL}reorder/?flow_type=${payload.flowType}`,
    payload.data
  )
    .then((response) => {
      if (payload.flowType == "purchase_request") {
        commit(SET_PUR_WORKFLOWS, response.data);
      } else {
        commit(SET_INVOICE_WORKFLOWS, response.data);
      }
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
