<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserGroupIcon
        v-if="!category.emoji"
        class="h-7 w-7 text-white"
        aria-hidden="true"
      />
      <p
        v-else
        class="text-2xl mx-auto flex items-center justify-center h-11 w-11 rounded-full bg-white"
      >
        {{ category.emoji }}
      </p>
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{
          isEdit
            ? $t("companyManagement.category.form.headingEdit")
            : $t("companyManagement.category.form.headingAdd")
        }}
      </h3>
    </template>
    <template #body>
      <div class="mt-6">
        <Input
          id="category_name"
          :error-text="error(v$.category.name)"
          :value="category.name"
          name="name"
          :placeholder="$t('companyManagement.category.form.placeholderName')"
          type="text"
          :label="$t('companyManagement.category.form.name')"
          :on-value-change="onAttributeChange"
          :classes="'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'"
        />
      </div>
      <div v-if="isFeatureAllowed('budget')" class="mt-6">
        <p class="block text-sm font-medium text-gray-700">
          {{ $t("companyManagement.category.form.defaultBudgetLevel") }}
        </p>
        <div class="mt-1">
          <Multiselect
            v-model="category.defaultBudgetLevel"
            :mode="'single'"
            :searchable="true"
            :options="defaultBudgetList()"
            :placeholder="
              $t(
                'companyManagement.category.form.placeholderDefaultBudgetLevel'
              )
            "
            :value-prop="'value'"
            :label="'label'"
            :track-by="'value'"
            :class="{
              'border-red-400': v$.category.defaultBudgetLevel?.$error,
            }"
            :can-clear="false"
          />
        </div>
        <FieldError
          v-if="v$.category.defaultBudgetLevel?.$error"
          :text="'Please select default budget'"
        />
        <div v-if="isFeatureAllowed('budget')" class="mt-6">
          <SwitchCheckbox
            v-model="category.hasBudget"
            :label="$t('companyManagement.category.form.hasBudget')"
            state-icons
          />
        </div>
      </div>
      <div class="mt-6">
        <label for="notes" class="block text-sm font-medium text-gray-700">
          {{ $t("companyManagement.category.form.notes") }}
        </label>
        <textarea
          id="notes"
          v-model="category.notes"
          name="notes"
          rows="3"
          class="mt-1 shadow-sm block w-full focus:ring-teal-500 focus:border-teal-400 sm:text-sm border-gray-300 rounded-md"
          :placeholder="$t('companyManagement.category.form.placeholderNotes')"
        />
      </div>
      <div class="mt-6">
        <Input
          id="category_glAccount"
          :value="category.glAccount"
          name="glAccount"
          :placeholder="
            $t('companyManagement.category.form.glAccountPlaceholder')
          "
          type="text"
          :label="$t('companyManagement.category.form.glAccount')"
          :on-value-change="onAttributeChange"
          :classes="'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'"
        />
      </div>
      <div v-if="isFeatureAllowed('subCategories')" class="mt-6">
        <p class="block text-sm font-medium text-gray-700">
          {{ $t("companyManagement.category.form.parentCategory") }}
        </p>
        <div class="mt-1">
          <Multiselect
            v-model="category.parent"
            :searchable="true"
            :options="formattedCategoriesList"
            :placeholder="$t('companyManagement.category.form.parentCategory')"
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            :can-clear="false"
          />
        </div>
      </div>
      <div class="py-4">
        <div class="font-light">
          {{ $t("companyManagement.category.form.deliveryRequiredText") }}
        </div>
        <SwitchCheckbox
          v-model="category.deliveryRequired"
          :label="$t('companyManagement.category.form.deliveryRequiredTitle')"
          state-icons
        />
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="loading"
        >Cancel
      </Button>
      <Button :on-click-handler="onSave" :loading="loading">
        {{ isEdit ? $t("buttons.update") : $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/shared/Modal";
import { DEFAULT_BUDGET_MAPPING } from "@/utils/constants";
import Multiselect from "@vueform/multiselect";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { UserGroupIcon } from "@heroicons/vue/solid";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";
import Button from "@/components/shared/Button.vue";
import { errorMixin } from "@/components/mixins/index.js";
import Input from "@/components/shared/Input.vue";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import FieldError from "@/components/shared/FieldError";
import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  SAVE_CATEGORY,
} from "@/store/CompanyManagement/types";

export default {
  name: "CategoryForm",
  components: {
    Multiselect,
    UserGroupIcon,
    Modal,
    Button,
    Input,
    SwitchCheckbox,
    FieldError,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    categoryToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      category: this.defaultValue(),
      showIcon: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoriesList"]),
    isEdit() {
      return !!this.categoryToEdit?.id;
    },
    formattedCategoriesList() {
      return this.isEdit
        ? this.categoriesList.filter(
            (category) => category?.id !== this.categoryToEdit.id
          )
        : this.categoriesList;
    },
  },
  watch: {
    categoryToEdit: {
      handler(newVal, oldVal) {
        if (!isObjectEmpty(newVal) && newVal != oldVal) {
          this.category = deepClone(newVal);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      saveCategory: SAVE_CATEGORY,
    }),
    defaultValue() {
      return {
        hasBudget: this.isFeatureAllowed("budget"),
        unicode: "null",
        glAccount: null,
        defaultBudgetLevel: !this.isFeatureAllowed("budget") ? "D" : "",
        deliveryRequired: true,
      };
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.category[name] = value;
    },
    defaultBudgetList() {
      const options = [];

      Object.keys(DEFAULT_BUDGET_MAPPING).forEach((key) => {
        options.push({
          label: this.$t(
            `companyManagement.category.form.budgetLevelOptions.${key}`
          ),
          value: key,
        });
      });

      return options;
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.saveCategory(this.category)
          .then(() => {
            this.onModalClose();
            this.isEdit
              ? this.showNotification(this.$t("toasts.genericSuccess"))
              : this.showNotification(this.$t("toasts.genericCreationSuccess"));
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.category = this.defaultValue();
        this.showIcon = false;
        this.v$.$reset();
      }, 1000);
    },
  },
  validations() {
    return {
      category: {
        name: { required },
        defaultBudgetLevel: { required },
      },
    };
  },
};
</script>
