<template>
  <div id="supplier">
    <div
      v-if="isApiLoading('integratedSuppliersLoading')"
      class="flex items-center"
    >
      <HivebuyLoader class="h-40" />
    </div>
    <div v-else>
      <div>
        <h3 class="page-title">
          {{ $t("companyManagement.connectedSupplier.title") }}
        </h3>
      </div>

      <ul role="list">
        <li v-for="supplier in list" :key="supplier.id">
          <Supplier :supplier="supplier" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Supplier from "@/components/CompanyManagement/ConnectedSuppliers/Supplier";
import { HivebuyLoader } from "@/components/shared/index";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_INTEGRATED_SUPPLIERS,
} from "@/store/CompanyManagement/types";
export default {
  components: {
    Supplier,
    HivebuyLoader,
  },
  data(){
    return {
      list: [],
    }
  },
  async mounted() {
    this.setApiLoading({ key: "integratedSuppliersLoading", value: true});
    this.list = await this.getIntegratedSuppliers();
    this.setApiLoading({ key: "integratedSuppliersLoading", value: false});
  },
  methods: {
  ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getIntegratedSuppliers: GET_INTEGRATED_SUPPLIERS,
    }),
  },
  
};
</script>
