<template>
  <Modal
    :show-modal="open"
    :close-modal="onModalClose"
    :size-classes="['sm:max-w-xl md:max-w-3xl lg:max-w-4xl']"
  >
    <template #icon>
      <component :is="getIcon" class="h-6 w-6 text-white" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{
          selectedOption === "category"
            ? $t("global.category")
            : $t("global.supplier")
        }}
      </h3>
    </template>
    <template #body>
      <CategorySelection
        v-if="selectedOption == 'category'"
        class="section w-full"
        :save-to-store="false"
        :save-category="saveCategory"
        :selected-category="selectedCategory"
      />
      <SupplierSelection
        v-else-if="selectedOption == 'supplier'"
        :save-to-store="false"
        :show-skip="showSkip"
        :save-supplier="saveSupplier"
        :selected-supplier="selectedSupplier"
        :category="selectedCategory"
      />
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'"
        >{{ $t("buttons.cancel") }}
      </Button>
    </template>
  </Modal>
</template>
<script>
import { Button, Modal } from "@/components/shared";
import { ViewBoardsIcon, GlobeAltIcon } from "@heroicons/vue/outline";
import CategorySelection from "../FreeForm/Category.vue";
import SupplierSelection from "../FreeForm/Supplier.vue";
export default {
  components: {
    Modal,
    Button,
    CategorySelection,
    SupplierSelection,
  },
  props: {
    open: { type: Boolean, default: false },
    onModalClose: { type: Function, required: true },
    selectedOption: { type: [String, null], required: true },
    saveDetailsAttr: { type: Function, required: true },
    selectedCategory: { type: String, default: null },
    selectedSupplier: { type: [String, Object], default: null },
    showSkip: { type: Boolean, default: true },
  },
  computed: {
    getIcon() {
      if (this.selectedOption === "category") return ViewBoardsIcon;
      else if (this.selectedOption === "supplier") return GlobeAltIcon;
      else return null;
    },
  },
  methods: {
    saveSupplier(value) {
      this.saveDetailsAttr("supplier", value);
      this.onModalClose();
    },
    saveCategory(value) {
      this.saveDetailsAttr("category", value);
      this.onModalClose();
    },
  },
};
</script>
