<template>
  <div>
    <span
      class="px-2 py-1 rounded text-sm font-semibold"
      :class="getBadge.badgeStyle"
    >
      {{ $t(`orderList.types.${type}`) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    getBadge() {
      switch (this.type) {
        case "email":
          return {
            badgeStyle: "bg-hivebuy-lilac text-gray-700",
          };
        case "integrated":
          return {
            badgeStyle: "bg-primary text-white",
          };
        default:
          return { badgeStyle: "bg-hivebuy-grey text-gray-400" };
      }
    },
  },
};
</script>
