/* eslint-disable no-unused-vars */
import * as FeatureTypes from "@/store/Feature/types.js";
import { FEATURE_FLAG_URL } from "@/utils/services/apiUrls";
import { patch } from "@/utils/services/api";

export const updateFeatureFlag = (
  flagId,
  allowed,
  flagName,
  commit,
  resolve,
  reject
) => {
  if (!flagId) return;

  patch(`${FEATURE_FLAG_URL}${flagId}/`, allowed)
    .then((response) => {
      const payload = {
        updatedData: response.data,
        flagName: flagName,
      };
      commit(FeatureTypes.SET_FEATURE, payload);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
