<template>
  <SuggestionForm
    ref="createSuggestionForm"
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-suggestion="onSave"
    :proposal="proposal"
    :fetch-proposal="fetchProposal"
  />
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_SUGGESTION, PROPOSAL_MODULE } from "@/store/Proposal/types";
import SuggestionForm from "@/components/Proposals/ProposalBoard/SuggestionForm";

export default {
  components: {
    SuggestionForm,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    proposal: {
      type: Object,
      required: true,
    },
    fetchProposal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      createSuggestion: CREATE_SUGGESTION,
    }),
    onSave(suggestion) {
      const payload = {
        poll: this.proposal.id,
        purchaseRequest: {
          title: this.proposal.title,
          status: "D",
          items: [this.suggestionPayload(suggestion)],
        },
      };
      this.createSuggestion(payload)
        .then(() => {
          this.showNotification("Suggestion has been added successfully");
          this.fetchProposal();
          this.$refs.createSuggestionForm.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error));
    },
    suggestionPayload(suggestion) {
      const {
        name,
        description,
        link,
        netAmount,
        grossAmount,
        images,
        vat,
        currency,
        files,
      } = suggestion;
      return {
        name,
        description,
        link,
        unit: "pc",
        images,
        files,
        itemPrices: [
          {
            netAmount,
            grossAmount,
            vat,
            netAmountCurrency: currency,
            grossAmountCurrency: currency,
            quantity: 1,
          },
        ],
      };
    },
  },
};
</script>
