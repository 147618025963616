import actions from "@/store/Feature/actions.js";
import mutations from "@/store/Feature/mutations.js";
import state from "@/store/Feature/state.js";
import getters from "@/store/Feature/getters.js";

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
