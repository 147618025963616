<template>
  <Modal
    :show-modal="showModal"
    :size-classes="['sm:max-w-xl']"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
  >
    <template #icon>
      <PencilAltIcon
        v-if="isEdit"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <UserAddIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-primary">
        {{
          isEdit
            ? $t("proposals.suggestions.suggestionForm.editSuggestion")
            : $t("proposals.suggestions.suggestionForm.addSuggestion")
        }}
      </h3>
    </template>
    <template #body>
      <div class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6 text-sm">
        <div class="md:col-span-6 col-span-12">
          <Input
            :id="'name'"
            :error-text="error(v$.suggestion.name)"
            :value="suggestion.name"
            :name="'name'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="
              $t('proposals.suggestions.suggestionForm.namePlaceholder')
            "
            :label="$t('proposals.suggestions.suggestionForm.name')"
          />
        </div>
        <div class="md:col-span-6 col-span-12">
          <TextArea
            id="description"
            name="description"
            :placeholder="
              $t('proposals.suggestions.suggestionForm.descriptionPlaceholder')
            "
            :label="$t('proposals.suggestions.suggestionForm.description')"
            :value="suggestion.description"
            :rows="3"
            :on-value-change="onAttributeChange"
          />
        </div>
        <div class="md:col-span-6 col-span-12">
          <Input
            :id="'link'"
            :value="suggestion.link"
            :name="'link'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="
              $t('proposals.suggestions.suggestionForm.linkPlaceholder')
            "
            :label="$t('proposals.suggestions.suggestionForm.link')"
            :is-link="true"
          />
        </div>
        <div class="md:col-span-6 col-span-12">
          <PriceAndVAT v-model="pricing" is-mandatory />
        </div>
        <div class="md:col-span-6 col-span-12 text-center">
          <div class="space-y-4">
            <div
              class="mt-4 md:col-span-3 col-span-12 text-lg leading-6 font-medium space-y-1"
            >
              <UploadMedia
                :on-item-file-upload="onItemFileUpload"
                :details="suggestion"
                :on-item-image-upload="onItemImageUpload"
                object-to-update="suggestion"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button v-focus :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("proposals.suggestions.suggestionForm.cancel") }}
      </Button>
      <Button :on-click-handler="onSave">
        {{
          isEdit
            ? $t("proposals.suggestions.suggestionForm.update")
            : $t("proposals.suggestions.suggestionForm.save")
        }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { UserAddIcon, PencilAltIcon } from "@heroicons/vue/solid";
import { currencyToLocale } from "@/utils/utility_methods";
import {
  Modal,
  Button,
  Input,
  TextArea,
  PriceAndVAT,
} from "@/components/shared/index";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {
  errorMixin,
  addFilesImagesToObjectMixin,
} from "@/components/mixins/index.js";
import { mapActions, mapState } from "vuex";
import { CREATE_SUGGESTION, PROPOSAL_MODULE } from "@/store/Proposal/types";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import UploadMedia from "@/components/PurchaseRequest/UploadMedia";

export default {
  components: {
    Modal,
    Button,
    Input,
    UserAddIcon,
    TextArea,
    PencilAltIcon,
    PriceAndVAT,
    UploadMedia,
  },
  mixins: [errorMixin, addFilesImagesToObjectMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    saveSuggestion: {
      type: Function,
      required: true,
    },
    suggestionToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      suggestion: {
        vat: 19,
        netGross: "gross",
        priceCurrency: "EUR",
      },
      pricing: null,
    };
  },

  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  watch: {
    suggestionToEdit: {
      handler(newVal, oldVal) {
        if (this.isEdit && !isObjectEmpty(newVal) && newVal != oldVal) {
          const { netGross, grossAmount, netAmount, vat } =
            this.suggestionToEdit;
          this.pricing = {
            netAmount,
            grossAmount,
            vat,
            netGross,
            currency: this.suggestionToEdit.grossAmountCurrency,
            inputPrice: netGross === "gross" ? grossAmount : netAmount,
          };
          this.suggestion = deepClone(newVal);
          this.suggestion.priceCurrency = newVal.grossAmountCurrency;
        }
      },
    },
    pricing: {
      handler() {
        if (this.pricing) {
          const { netAmount, grossAmount, currency, vat } = this.pricing;
          this.suggestion = {
            ...this.suggestion,
            netAmount,
            grossAmount,
            currency,
            vat,
          };
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isEdit) {
      this.suggestion.priceCurrency = this.company.currency;
    }
  },
  methods: {
    currencyToLocale,
    ...mapActions(PROPOSAL_MODULE, {
      createSuggestion: CREATE_SUGGESTION,
    }),
    onModalClose() {
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.suggestion = {
          vat: 19,
          netGross: "gross",
        };
        this.v$.$reset();
      }, 500);
    },
    onAttributeChange(event) {
      let { value } = event.target;
      const name = event.target.name;
      if (
        name === "link" &&
        !value.includes("http://") &&
        !value.includes("https://")
      ) {
        value = "http://" + value.replace(/\s/g, "");
      }
      this.suggestion[name] = value;
    },
    priceOptions() {
      return {
        vat: this.suggestion?.vat || "19",
        netGross: this.suggestion?.netGross || "gross",
      };
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const { grossAmount, netAmount } = this.suggestion;
        this.suggestion.grossAmount = parseFloat(grossAmount).toFixed(
          this.backendDecimalPoint
        );
        this.suggestion.netAmount = parseFloat(netAmount).toFixed(
          this.backendDecimalPoint
        );
        this.saveSuggestion(this.suggestion);
      }
    },
  },
  validations() {
    return {
      suggestion: {
        name: { required },
        netAmount: { required },
        grossAmount: { required },
        priceCurrency: { required },
      },
    };
  },
};
</script>
