<template>
  <div v-if="items" class="custom-card no-shadow">
    <h2 id="summary-heading" class="total-price">
      {{ $t("purchaseRequest.summary.totalPrice.header") }}
    </h2>
    <dl>
      <ul class="text-sm text-gray-600">
        <li
          v-for="item in pendingAndApprovedItems()"
          :key="item.id"
          class="grid grid-cols-2 py-2"
        >
          <div class="truncate">
            {{ item.name }}
          </div>
          <div class="justify-self-end">
            <i18n-n
              v-if="item.netAmount"
              :value="parseFloat(item.netAmount * item.quantity)"
              format="currency"
              :locale="currencyToLocale(item.netAmountCurrency)"
            />
          </div>
        </li>
      </ul>
      <div class="price-row border-t">
        <dt class="price-lable">
          {{ $t("purchaseRequest.summary.totalPrice.netTotal") }}
        </dt>
        <dd class="price-value">
          <i18n-n
            v-if="totalNetAmount"
            :value="parseFloat(totalNetAmount)"
            format="currency"
            :locale="currencyToLocale(company.currency)"
          />
        </dd>
      </div>
      <div v-if="parseFloat(purchaseRequest.shipping)" class="price-row">
        <dt class="price-label">
          <span>{{ $t("purchaseRequest.summary.totalPrice.shipping") }}</span>
        </dt>
        <dd class="price-value">
          <i18n-n
            :value="parseFloat(purchaseRequest.shipping)"
            format="currency"
            :locale="currencyToLocale(company.currency)"
          />
        </dd>
      </div>

      <div class="price-row">
        <dt class="price-label">
          <span>{{ $t("purchaseRequest.summary.totalPrice.tax") }}</span>
        </dt>
        <dd class="price-value">
          <i18n-n
            v-if="purchaseRequest.tax"
            :value="parseFloat(purchaseRequest.tax)"
            format="currency"
            :locale="currencyToLocale(company.currency)"
          />
        </dd>
      </div>

      <div class="border-b"></div>
      <div class="price-row">
        <dt class="price-label text-lg mt-2 font-semibold">
          {{ $t("purchaseRequest.summary.totalPrice.orderTotal") }}
        </dt>
        <dd class="price-value text-lg">
          <i18n-n
            v-if="purchaseRequestTotalPrice"
            :value="purchaseRequestTotalPrice"
            format="currency"
            :locale="currencyToLocale(company.currency)"
          />
        </dd>
      </div>
      <!-- WHEN THERE IS A DIFFERENCE AFTER ORDERING, THIS WILL BE SHOWN -->
      <div v-if="purchaseOrder && difPuRPO() != 0" class="pt-3 space-y-4">
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-hivebuy_darkplum" />
          </div>
          <div class="relative flex justify-center">
            <span class="px-3 bg-white text-base text-gray-500">{{
              $t("purchaseRequest.summary.totalPrice.updateAfterOrder")
            }}</span>
          </div>
        </div>
        <div class="flex items-center justify-between pt-4">
          <dt class="text-sm text-gray-600">
            {{ $t("purchaseRequest.summary.totalPrice.netTotal") }}
          </dt>
          <dd class="text-sm font-medium text-gray-900">
            <i18n-n
              v-if="purchaseOrder.netAmount"
              :value="parseFloat(purchaseOrder.netAmount)"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </dd>
        </div>
        <div
          class="border-t border-gray-200 pt-4 flex items-center justify-between"
        >
          <dt class="flex text-sm text-gray-600">
            <span>{{ $t("purchaseRequest.summary.totalPrice.tax") }}</span>
          </dt>
          <dd
            v-if="purchaseOrder.tax"
            class="text-sm font-medium text-gray-900"
          >
            <i18n-n
              :value="parseFloat(purchaseOrder.tax)"
              format="currency"
              :locale="currencyToLocale(purchaseOrder.taxCurrency)"
            />
          </dd>
          <dd v-else class="text-sm font-medium text-gray-900">
            <i18n-n
              v-if="totalNetAmount && totalGrossAmount"
              :value="parseFloat(totalGrossAmount - totalNetAmount)"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </dd>
        </div>
        <div
          v-if="purchaseOrder.shipping"
          class="border-t border-gray-200 pt-4 flex items-center justify-between"
        >
          <dt class="flex text-sm text-gray-600">
            <span>{{ $t("purchaseRequest.summary.totalPrice.shipping") }}</span>
          </dt>
          <dd class="text-sm font-medium text-gray-900">
            <i18n-n
              :value="parseFloat(purchaseOrder.shipping)"
              format="currency"
              :locale="
                currencyToLocale(purchaseRequest.totalGrossAmountCurrency)
              "
            />
          </dd>
        </div>
        <div class="price-row">
          <dt class="price-label">
            {{ $t("purchaseRequest.summary.totalPrice.newOrderTotal") }}
          </dt>
          <dd class="price-value">
            <i18n-n
              v-if="purchaseOrder.totalAmount"
              :value="parseFloat(purchaseOrder.totalAmount)"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </dd>
        </div>
        <div class="flex items-center justify-between">
          <dt
            class="text-sm mt-1"
            :class="[difPuRPO() > 0 ? 'text-red-600' : 'text-primary']"
          >
            {{ $t("purchaseRequest.summary.totalPrice.delta") }}
          </dt>
          <dd
            class="text-sm font-medium text-gray-900"
            :class="[difPuRPO() > 0 ? 'text-red-600' : 'text-primary']"
          >
            <i18n-n
              v-if="totalNetAmount"
              :value="difPuRPO()"
              format="currency"
              :locale="currencyToLocale(company.currency)"
            />
          </dd>
        </div>
      </div>
    </dl>
    <div v-if="exchangeRates && exchangeRates.length">
      <div
        v-for="(rate, index) in exchangeRates"
        :key="index"
        class="rounded-md bg-blue-50 p-4 my-12 text-sm"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <InformationCircleIcon
              class="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <p class="text-sm font-light text-blue-700">
              {{ $t("purchaseRequest.summary.totalPrice.difCurrency") }}
              {{ getCurrencyName(rate)[0] }}
            </p>
          </div>
        </div>
        <div class="font-medium mx-1 text-center mt-2 text-blue-800">
          1 {{ company.currency }} = {{ rate[getCurrencyName(rate)] }}
          {{ getCurrencyName(rate)[0] }}
        </div>
      </div>
    </div>

    <div v-if="showButtons" class="mt-12 space-y-4">
      <Button
        id="sendRequest"
        full
        :loading="loading"
        :on-click-handler="saveAction"
      >
        <template #icon><CheckCircleIcon /></template>
        <span v-if="newRequest">{{
          $t("purchaseRequest.summary.totalPrice.sendRequest")
        }}</span>
        <span v-else>{{
          $t("purchaseRequest.summary.editRequestSection.btnText")
        }}</span>
      </Button>
      <Button
        id="sendRequest"
        full
        :disabled="loading"
        :on-click-handler="resetForm"
        :color="'gray'"
      >
        <template #icon><XCircleIcon /></template>
        {{ $t("buttons.cancel") }}
      </Button>
    </div>
  </div>
</template>

<script>
import { currencyToLocale } from "@/utils/utility_methods.js";
import Button from "@/components/shared/Button.vue";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";

import { mapState } from "vuex";
import {
  PURCHASE_REQUEST_STATUS_MAPPING,
  PURCHASE_REQUEST_STATUSES,
} from "@/utils/constants";

import {
  cancelEditMixin,
  priceCalcMixin,
  purchaseRequestCostDetails,
} from "@/components/mixins/index.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    Button,
    CheckCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
  },
  mixins: [priceCalcMixin, purchaseRequestCostDetails, cancelEditMixin],
  props: {
    saveAction: {
      type: Function,
      default: () => {},
    },
    newRequest: {
      type: Boolean,
      default: true,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    items() {
      const items = this.purchaseRequest.items;
      return items.sort((a, b) => a.position - b.position);
    },
    purchaseOrder() {
      return this.purchaseRequest.purchaseOrder;
    },
    totalGrossAmount() {
      return parseFloat(this.purchaseRequest.totalGrossAmount);
    },
    totalNetAmount() {
      return parseFloat(this.purchaseRequest.totalNetAmount);
    },
    exchangeRates() {
      return this.purchaseRequest.exchangeRates;
    },
    actionRequired() {
      return this.purchaseRequest.actionRequired;
    },

    purchaseRequestTotalPrice() {
      let totalPrice = 0;
      const { totalAmount, shipping } = this.purchaseRequest;

      if (totalAmount) {
        totalPrice = parseFloat(totalAmount);
      } else {
        const grossAmount = parseFloat(this.totalGrossAmount);
        totalPrice = shipping
          ? grossAmount + parseFloat(shipping)
          : grossAmount;
      }

      return totalPrice;
    },
  },
  created() {
    this.PURCHASE_REQUEST_STATUS_MAPPING = PURCHASE_REQUEST_STATUS_MAPPING;
    this.PURCHASE_REQUEST_STATUSES = PURCHASE_REQUEST_STATUSES;
  },
  methods: {
    currencyToLocale,
    pendingAndApprovedItems() {
      return this.items.filter((item) => {
        const { APPROVED, PENDING } = PURCHASE_REQUEST_STATUSES;
        return [APPROVED, PENDING].includes(item.requestStatus);
      });
    },
    getCurrencyName(currency) {
      return Object.keys(currency);
    },

    difPuRPO() {
      if (!this.purchaseOrder.totalAmount) return 0;

      return parseFloat(
        this.purchaseOrder.totalAmount - this.purchaseRequestTotalPrice
      );
    },
  },
};
</script>

<style scoped>
.price-row {
  @apply flex text-base items-center justify-between py-2;
}

.price-row .price-label {
}

.price-row .price-value {
  @apply text-primary font-semibold;
}

.total-price {
  @apply text-base font-semibold text-primary mb-4;
}
</style>
