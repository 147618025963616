<template>
  <div id="contract">
    <div class="flex flex-col">
      <div class="flex items-center justify-between">
        <h3 class="page-title">
          {{ $t("contracts.title") }}
        </h3>
        <router-link
          v-if="!isUserOnlyAuditor"
          class="flex text-primary font-semibold -mt-2 hover:text-primarydark"
          :to="{ name: 'NewContract' }"
        >
          <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
          {{ $t("contracts.addContract") }}
        </router-link>
      </div>

      <div class="custom-card overflow-visible">
        <div class="-m-5">
          <table class="table overflow-visible 2xl:table-fixed">
            <thead class="table-header">
              <tr>
                <th
                  v-for="(columnName, index) in tableColumns()"
                  :key="index"
                  scope="col"
                >
                  {{ columnName }}
                </th>
              </tr>
            </thead>

            <tbody class="table-body">
              <tr>
                <td :colspan="tableColumns().length" class="search-holder">
                  <div class="table-search sticky-search">
                    <SearchInput
                      :value="paginationParams.search"
                      :on-change="onSearchInput"
                      :placeholder="$t('contracts.search')"
                    />
                    <Filters
                      :set-advanced-filters="setAdvancedFilters"
                      :disabled="loading"
                    />
                  </div>
                </td>
              </tr>
              <SkeletonTable
                v-if="loading"
                :columns="tableColumns().length"
                :rows="6"
              />
              <tr v-else-if="!loading && !areContractsPresent">
                <td :colspan="tableColumns().length" class="search-holder">
                  <p
                    class="bg-white px-4 py-4 w-full font-medium text-bayoux-500"
                  >
                    {{ $t("general.noRecordsFound") }}
                  </p>
                </td>
              </tr>
              <tr
                v-for="contract in contracts"
                v-else
                :key="contract.id"
                class="hover:bg-gray-50 group"
              >
                <td :title="contract.contractNumber">
                  <router-link
                    class="flex items-center justify-between title hover:text-primary"
                    :to="{
                      name: 'ViewContract',
                      params: { id: contract.id },
                    }"
                  >
                    <div class="flex items-center justify-between title w-full">
                      <div class="mr-4 truncate title-wrapper">
                        {{ contract?.name || "N/A" }}
                      </div>

                      <span class="arrow-icon">
                        <div class="group-hover:text-primary">
                          <ListButton>
                            <template #icon>
                              <ChevronRightIcon class="h-6 w-6" />
                            </template>
                          </ListButton>
                        </div>
                      </span>
                    </div>
                  </router-link>
                </td>
                <td>
                  {{ contract.contractNumber }}
                </td>
                <td>
                  <ContractTypeBadge :type="contract.contractType" />
                </td>
                <td
                  v-if="
                    contract.freeLicenseAmount > 0 && contract.licenseAmount > 0
                  "
                  class="text-sm font-medium"
                >
                  {{ contract.freeLicenseAmount }} /
                  {{ contract.licenseAmount }}
                </td>
                <td v-else></td>
                <td>
                  {{ formattedDate(contract.startDate) }}
                </td>
                <td class="relative">
                  <div class="group">
                    <span>{{ formattedDate(contract.endDate) }}</span>
                  </div>
                  <div v-if="!isUserOnlyAuditor" class="actions">
                    <router-link
                      :to="{
                        name: 'EditContract',
                        params: { id: contract.id },
                      }"
                    >
                      <ListButton hover-edit>
                        <template #icon>
                          <PencilAltIcon class="h-5 w-5" />
                        </template>
                      </ListButton>
                    </router-link>
                    <ListButton
                      hover-danger
                      :on-click-handler="() => onDelete(contract)"
                    >
                      <template #icon>
                        <TrashIcon class="h-5 w-5" />
                      </template>
                    </ListButton>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="paginationParams.page < paginationParams.totalPages"
          id="load-more-section"
        >
          <LoadMoreSection
            :load-more="loadMore"
            :load-more-btn-loading="loadMoreBtnLoading"
            class="!mt-0"
          />
        </div>
      </div>
    </div>
    <ConfirmDialog
      :text="$t('contracts.deleteContract')"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      :title="contractToDelete?.name"
      :confirm-modal="onConfirmDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  CONTRACT_MODULE,
  DELETE_CONTRACT,
  GET_CONTRACTS,
  SET_CONTRACTS_LIST_FILTERS,
} from "@/store/Contract/types";
import { loadMoreFiltersMixin } from "@/components/mixins";
import {
  ConfirmDialog,
  ListButton,
  LoadMoreSection,
  TableRowsLoader as SkeletonTable,
} from "@/components/shared";
import {
  PlusCircleIcon,
  ChevronRightIcon,
  TrashIcon,
  PencilAltIcon,
} from "@heroicons/vue/solid";
import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import { getKeyByValue, titleize } from "@/utils/utility_methods";
import Filters from "@/components/Contracts/Filters";
import { CONTRACT_TYPES } from "@/utils/constants";
import snakecaseKeys from "snakecase-keys";
import ContractTypeBadge from "@/components/Contracts/ContractTypeBadge.vue";

export default {
  name: "List",
  components: {
    ConfirmDialog,
    SearchInput,
    SkeletonTable,
    PlusCircleIcon,
    Filters,
    LoadMoreSection,
    ChevronRightIcon,
    TrashIcon,
    ListButton,
    PencilAltIcon,
    ContractTypeBadge,
  },
  mixins: [loadMoreFiltersMixin],
  data() {
    return {
      loading: false,
      showDeleteConfirmModal: false,
      contractToDelete: {},
    };
  },
  computed: {
    ...mapState(CONTRACT_MODULE, {
      contracts: (state) => state.contracts,
      contractsListFilters: (state) => state.contractsListFilters,
      paginationParams: (state) => state.contractsListFilters.paginationParams,
      advancedFilters: (state) => state.contractsListFilters.advancedFilters,
    }),
    areContractsPresent() {
      return this.contracts.length && !this.loading;
    },
  },
  created() {
    this.CONTRACT_TYPES = CONTRACT_TYPES;
  },
  mounted() {
    !this.contracts.length && this.fetchContracts();
  },
  methods: {
    ...mapActions(CONTRACT_MODULE, {
      getContracts: GET_CONTRACTS,
      deleteContract: DELETE_CONTRACT,
    }),
    ...mapMutations(CONTRACT_MODULE, {
      setContractsListFilters: SET_CONTRACTS_LIST_FILTERS,
    }),
    tableColumns() {
      return [
        this.$t("contracts.table.fields.contractName"),
        this.$t("contracts.table.fields.contractNumber"),
        this.$t("contracts.table.fields.contractType"),
        this.$t("contracts.table.fields.totalLicenseAmount"),
        this.$t("contracts.table.fields.startDate"),
        this.$t("contracts.table.fields.endDate"),
      ];
    },
    fetchContracts(loadMore = false) {
      if (!loadMore) {
        this.loading = true;
      }

      const payload = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getContracts(payload)
        .then(() => (this.areFiltersUpdated = false))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loadMoreBtnLoading = false;
          this.loading = false;
        });
    },
    requestQueryParams() {
      const params = Object.assign(
        {},
        this.paginationParams,
        this.advancedFilters
      );
      if (this.areFiltersUpdated) params.page = 1;
      return params;
    },
    updateFilters(key, value, parentKey = null) {
      const payload = this.createFiltersPayload(
        this.contractsListFilters,
        key,
        value,
        parentKey
      );

      this.setContractsListFilters(payload);

      if (key === "page") {
        this.fetchContracts(true);
      } else {
        this.areFiltersUpdated = true;
        this.fetchContracts();
      }
    },
    setAdvancedFilters(advancedFilters) {
      const payload = Object.assign(
        {},
        snakecaseKeys(advancedFilters, {
          exclude: ["start_date__gte", "end_date__lte"],
        })
      );
      this.updateFilters("advancedFilters", payload);
    },
    onDelete(contract) {
      this.showDeleteConfirmModal = true;
      this.contractToDelete = contract;
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    onConfirmDelete() {
      this.showDeleteConfirmModal = false;
      this.deleteContract(this.contractToDelete.id)
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error));
    },
    descriptiveValue(obj, value) {
      return titleize(getKeyByValue(obj, value));
    },
  },
};
</script>

<style scoped>
.table-search {
  @apply flex;
}
.table .table-header tr th:nth-child(1) {
  @apply w-[10rem] 2xl:w-auto relative;
}

.table .table-header tr th:nth-child(2) {
  @apply w-[10rem];
}

.table .table-header tr th:nth-child(3) {
  @apply w-[14rem];
}

.table .table-header tr th:nth-child(4) {
  @apply w-[8rem];
}

.table .table-header tr th:nth-child(5) {
  @apply w-[14rem];
}

.arrow-icon {
  @apply items-center space-x-1 text-gray-300 cursor-pointer group-hover:visible;
  visibility: collapse;
}

.actions {
  @apply absolute hidden group-hover:flex right-6 top-3;
}
</style>
