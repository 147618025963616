import { post } from "../api";
import { INTEGRATIONS_API_URL } from "@/utils/services/apiUrls.js";

export const getSvixLoginData = (integrationId, resolve, reject) => {
  post(`${INTEGRATIONS_API_URL}${integrationId}/zapier-integration-details/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
