<template>
  <CheckCircleIcon v-if="value" class="h-5 w-5 text-teal-500" />
  <ClockIcon v-else class="h-5 w-5 text-pending" />
</template>
<script>
import { CheckCircleIcon, ClockIcon } from "@heroicons/vue/outline";
export default {
  components: {
    CheckCircleIcon,
    ClockIcon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
