<template>
  <div
    ref="truncateText"
    v-tippy="{
      content: text,
      delay: [200, 400],
      theme: 'time',
      followCursor: 'horizontal',
      trigger: showTooltip,
      placement: placement,
    }"
    class="truncate"
  >
    {{ text || "-" }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: "top",
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isTextTruncated: false,
    };
  },
  computed: {
    showTooltip() {
      return this.isTextTruncated && this.tooltip ? "mouseenter focus" : "manual";
    },
  },

  mounted() {
    this.calculateTruncate(this.$refs.truncateText);
  },
  updated() {
    this.calculateTruncate(this.$refs.truncateText);
  },
  methods: {
    calculateTruncate(element) {
      let truncate = false;
      if (element.scrollWidth > element.clientWidth) truncate = true;
      this.isTextTruncated = truncate;
    },
  },
};
</script>
