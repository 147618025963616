<template>
  <div class="border border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-lg">
    <div
      class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap"
    >
      <div class="ml-4 mt-2">
        <h3 class="text-base font-medium leading-6 text-gray-700 line-clamp-1">
          {{ shoppingList.title }}
        </h3>
        <h2
          v-tippy="{
            content: shoppingList.description,
            delay: [50, 200],
            theme: 'time',
          }"
          class="font-normal text-sm leading-6 text-gray-600 line-clamp-1"
        >
          {{ shoppingList.description }}
        </h2>
      </div>
      <div class="ml-4 mt-2 flex-shrink-0">
        <h2 class="font-normal text-sm leading-6 text-gray-600">
          {{ $t("global.requestedBy") }}
        </h2>
        <UserWithAvatar
          :user="shoppingList.requestedBy"
          show-tooltip
          class="flex justify-end"
        />
      </div>
    </div>
    <ul role="list" class="divide-y divide-gray-200 mt-2">
      <li v-for="item in shoppingList.items" :key="item.id">
        <ListItem :item="item" :list="shoppingList" />
      </li>
    </ul>
  </div>
</template>

<script>
import { UserWithAvatar } from "@/components/shared/index.js";
import { ListItem } from "@/components/ShoppingList/index.js";
export default {
  components: {
    UserWithAvatar,
    // eslint-disable-next-line vue/no-unused-components
    ListItem,
  },
  props: {
    shoppingList: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
