<template>
  <div>
    <div class="relative mb-3">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-2 text-hivebuy-green text-xs">
          {{ invoice.invoiceNumber }}
        </span>
        <div
          v-if="invoice.status && isFeatureAllowed('invoiceApproval')"
          class="text-gray-900 truncate -mt-1"
        >
          <StatusTag type="invoice" :status="invoice.status" size="small" />
        </div>
      </div>
    </div>
    <div class="relative mb-6">
      <div class="">
        <div class="flex">
          <div class="text-sm text-gray-500 grid grid-cols-2 grow gap-x-4">
            <div v-if="invoice.title">
              {{ $t("invoice.quickView.title") }}
            </div>
            <div v-if="invoice.title" class="text-gray-900 truncate">
              {{ invoice.title }}
            </div>

            <p>{{ $t("invoice.quickView.uploaded") }}</p>
            <div>
              <span class="text-gray-900">{{
                formattedDate(invoice.createdAt)
              }}</span>
            </div>
            <div>
              {{ $t("invoice.quickView.number") }}
            </div>
            <div class="text-gray-900 truncate">
              {{ invoice.invoiceNumber }}
            </div>
          </div>
          <div
            v-if="invoice.createdBy"
            class="text-white justify-self-end shrink"
          >
            <UserWithAvatar
              :user="invoice.createdBy"
              :show-tooltip="true"
              :avatar-dimension="9"
            />
          </div>
        </div>
      </div>
      <div class="flex gap-x-2 mt-4 items-center justify-center">
        <HivebuyTooltip open-delay="50">
          <div
            class="py-0.5 px-4 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
            @click="() => $refs.downloadInvoice.click()"
          >
            <DownloadIcon class="h-5 w-5 text-primary" />
          </div>
          <template #content>
            <div class="p-1 text-sm">
              {{ $t("invoice.quickView.download") }}
            </div>
          </template>
        </HivebuyTooltip>
        <HivebuyTooltip v-if="auditTrailFile" open-delay="50">
          <div
            class="py-0.5 px-4 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100 relative"
            @click="() => $refs.downloadAuditTrail.click()"
          >
            <div class="flex items-center justify-center gap-x-2">
              <DownloadIcon class="h-5 w-5 text-primary" />
              + <ThumbUpIcon class="h-5 w-5 text-primary" />
            </div>
          </div>
          <template #content>
            <div class="p-1 text-sm">
              {{ $t("invoice.quickView.invoiceAuditTrail") }}
            </div>
          </template>
        </HivebuyTooltip>
        <HivebuyTooltip v-if="isUserAllowed" open-delay="50">
          <div
            class="py-0.5 px-4 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
            @click="showDetails"
          >
            <ClipboardListIcon class="h-5 w-5 text-primary" />
          </div>
          <template #content>
            <div class="p-1 text-sm max-w-xs">
              {{ $t("invoice.quickView.goToDetails") }}
            </div>
          </template>
        </HivebuyTooltip>
      </div>
      <div v-if="invoice.notes" class="mt-3">
        <div class="text-sm text-gray-500 p-2 border border-gray-200 rounded">
          <span class="text-gray-500">
            {{ invoice.notes }}
          </span>
        </div>
      </div>
    </div>
    <a
      ref="downloadInvoice"
      :href="invoiceFile"
      download="download"
      target="_blank"
    />
    <a
      v-if="auditTrailFile"
      ref="downloadAuditTrail"
      :href="auditTrailFile"
      download="download"
      target="_blank"
    />
  </div>
</template>

<script>
import { formattedTime } from "@/utils/utility_methods";
import { UserWithAvatar, HivebuyTooltip } from "@/components/shared/index";
import { DownloadIcon } from "@heroicons/vue/outline";
import { ClipboardListIcon, ThumbUpIcon } from "@heroicons/vue/solid";
import { featuresMixin as FeaturesMixin } from "@/components/mixins/index.js";
import StatusTag from "@/components/shared/StatusTag";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
export default {
  components: {
    UserWithAvatar,
    HivebuyTooltip,
    DownloadIcon,
    ClipboardListIcon,
    StatusTag,
    ThumbUpIcon,
  },
  mixins: [FeaturesMixin],
  props: {
    purchaseOrderId: { type: String, required: true },
    invoice: { type: Object, required: true },
  },

  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    invoiceFile() {
      if (this.invoice.files?.length) {
        return this.invoice.files[0].file;
      }
      return null;
    },
    auditTrailFile() {
      if (this.invoice.mergedInvoice?.length) {
        return this.invoice.mergedInvoice[0].file;
      }
      return null;
    },
    isUserAnApprover() {
      const isUserApprover =
        this.invoice?.invoicePurchaseOrders[0]?.actionRequired;
      return isUserApprover;
    },

    isUserAllowed() {
      if (!this.isFeatureAllowed("invoiceApproval")) return false;

      const allowedRoles = ["O", "MD", "AC", "BU"];

      return (
        this.isUserAnApprover ||
        this.user?.roles?.some((role) => allowedRoles.includes(role))
      );
    },
  },

  methods: {
    formattedTime,
    showDetails() {
      this.$router.push({
        name: "InvoiceDecision",
        params: {
          invoiceId: this.invoice.id,
        },
        query: { poId: this.purchaseOrderId },
      });
    },
  },
};
</script>
