import requestTypeMixin from "@/components/mixins/requestTypeMixin";
import { mapGetters, mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  FETCH_COMPANY_SUPPLIER,
} from "@/store/CompanyManagement/types";
import {
  isEmptyString,
  objectKeyCount,
  isObject,
} from "@/utils/utility_methods";

export default {
  mixins: [requestTypeMixin],
  computed: {
    integratedSupplier() {
      if (this.supplier) {
        const integratedSupplier = this.getSupplierDetails(
          this.integratedSupplier
        );
        if (integratedSupplier?.punchoutUrl) return integratedSupplier;
      } else if (this.purchaseRequest?.items?.length) {
        const { source } = this.purchaseRequest.items[0];
        return source?.object?.supplier;
      }
      return null;
    },
    isIntegratedSupplier() {
      return !!this.integratedSupplier;
    },
  },
  methods: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "getSupplier",
      "getCompanySupplier",
    ]),
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchSupplier: FETCH_COMPANY_SUPPLIER,
    }),
    getCompanySupplierName(supplierObject) {
      return this.getSupplierPreferredName(
        this.getSupplierDetails(supplierObject, "CompanySupplier")
      );
    },
    getSupplierName(supplierObject) {
      return this.getSupplierPreferredName(
        this.getSupplierDetails(supplierObject)
      );
    },
    getSupplierPreferredName({ displayName, name, supplier }) {
      if (supplier?.name) {
        return supplier.name;
      }
      return this.isEmptyString(displayName) ? name : displayName;
    },
    supplierId(supplierObject) {
      let id = supplierObject;
      if (this.isObject(supplierObject)) {
        if (supplierObject.id) id = supplierObject?.id;
        else if (supplierObject.details && !isObject(supplierObject.details))
          id = supplierObject.details;
      }
      return id;
    },
    getSupplierDetails(supplierObject, target = "Supplier") {
      if (
        this.isObject(supplierObject) &&
        this.objectKeyCount(supplierObject) > 1 &&
        Object.keys(supplierObject).includes("name")
      )
        return supplierObject;

      const id = this.supplierId(supplierObject);
      const supplier = this[`get${target}`]()(id);
      if (supplier) return supplier;
      this.fetchSupplier(id);
      return { name: "-" };
    },
    isObject,
    objectKeyCount,
    isEmptyString,
  },
};
