\
<template>
  <label v-if="label" class="label">
    {{ label }}
  </label>
  <textarea
    :id="id || name"
    :name="name"
    :value="value"
    class="input-field relative"
    :rows="rows"
    :placeholder="placeholder"
    @change="onValueChange"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @keyup="onKeyUp($event)"
  />
  <div v-if="ocrDetails?.data" class="absolute top-10 right-2">
    <OCRButtonInput :ocr-details="ocrDetails" :on-update-value="updateValue" />
  </div>
</template>
<script>
import OCRButtonInput from "@/components/shared/OCRButtonInput.vue";
export default {
  components: {
    OCRButtonInput,
  },
  props: {
    label: { type: String, required: false, default: "" },
    value: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    rows: { type: Number, default: 6 },
    onValueChange: { type: Function, default: () => {} },
    name: { type: String, required: true },
    id: { type: String, default: null },
    onMouseEnter: {
      type: Function,
      default: () => {},
    },
    onMouseLeave: {
      type: Function,
      default: () => {},
    },
    onKeyUp: {
      type: Function,
      default: () => {},
    },
    ocrDetails: {
      type: Object,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.onValueChange({
        name: this.name,
        value: value,
      });
    },
  },
};
</script>
