<template>
  <div>
    <h1 class="page-title">
      {{ $tc("home.orders", 2) }}
    </h1>
  </div>
  <List />
</template>

<script>
import List from "@/components/OrderList/List.vue";
export default {
  name: "OrderList",
  components: {
    List,
  }
};
</script>
