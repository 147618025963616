<template>
  <div class="flex justify-between">
    <p class="text-gray-700 font-semibold mb-3">
      {{ $t("workflow.editor.approvedRequestSentTo") }}
    </p>
    <div class="align-middle content-center">
      <EditorActions
        :allow-actions="allowActions"
        :on-delete="onDelete"
        :on-edit="onEdit"
        :type="'result'"
        :category="'all'"
      />
    </div>
  </div>
  <div class="text-center">
    <p v-if="results.user" class="workflowItem text-center">
      {{ results.user.firstName }}
      {{ results.user.lastName }}
    </p>
    <p v-else-if="results.responsibleUser" class="workflowItem text-center">
      {{ $t("workflow.editor.responsibleUser") }}
    </p>
    <p v-else class="workflowItem text-center">
      {{ $t(`global.roles.${results.role}`) }}
    </p>
  </div>
</template>
<script>
import EditorActions from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/actions.vue";
import { roleName } from "@/utils/helpers/workflowHelper.js";
export default {
  components: { EditorActions },
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    results: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    roleName,
  },
};
</script>
