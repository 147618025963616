<template>
  <a
    v-if="item && isItemRestorable"
    :class="['cursor-pointer', ...classes]"
    @click.stop="() => confirmItemStatus('restore', [item])"
  >
    <RefreshIcon class="h-5 w-5 cursor-pointer" aria-hidden="true" />
  </a>

  <div
    v-if="isCancelable"
    :class="['cursor-pointer', ...classes]"
    @click.stop="cancelAction"
  >
    <a
      :class="[
        'flex',
        { 'align-middle justify-center items-center': !allowText },
      ]"
    >
      <BanIcon
        :class="['h-5 w-5', allowText ? 'mr-2' : '']"
        aria-hidden="true"
      />
      <span v-if="allowText">
        {{ $t("purchaseRequest.summary.dangerZone.headingCancel") }}
      </span>
    </a>
  </div>
</template>
<script>
import cancelRequestMixin from "@/components/mixins/cancelRequestMixin.js";
import { BanIcon } from "@heroicons/vue/solid";

import { RefreshIcon } from "@heroicons/vue/outline";
export default {
  components: {
    BanIcon,
    RefreshIcon,
  },
  mixins: [cancelRequestMixin],
  inject: ["toggleCancelModal", "confirmItemStatus"],
  props: {
    purchaseRequest: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    classes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    allowText() {
      return !this.item;
    },
  },
  methods: {
    cancelAction() {
      if (this.purchaseRequest?.isItemLevelApprover) {
        if (this.isPO) {
          if (this.item) {
            this.toggleCancelModal(this.item);
          } else {
            this.toggleCancelModal();
          }
        } else {
          if (this.item) {
            this.confirmItemStatus("cancelled", [this.item]);
          } else {
            this.confirmItemStatus("cancelled", this.purchaseRequest.items);
          }
        }
      } else {
        if (this.isPO) {
          this.toggleCancelModal();
        } else {
          this.confirmItemStatus("cancelled");
        }
      }
    },
  },
};
</script>
