import { isObjectEmpty } from "@/utils/utility_methods.js";
import { mapAdvanceFilter } from "@/utils/Constants/filters/mapFilterKeys.js";
import store from "@/store/index.js";
import storeValueGetterMixin from "@/components/mixins/storeValueGetterMixin.js";
import { moduleDetails } from "@/utils/helpers/filtersModuleDetailsHelper.js";

export default {
  props: {
    filterName: {
      type: String,
      default: "",
    },
  },
  mixins: [storeValueGetterMixin],
  methods: {
    requestQueryParams() {
      const filters = this.mainFilterObject();
      const params = isObjectEmpty(filters.advancedFilters)
        ? filters.paginationParams
        : Object.assign(
            {},
            filters.paginationParams,
            mapAdvanceFilter(filters.advancedFilters, this.moduleName)
          );

      return { ...params, page: 1 };
    },
    mainFilterObject() {
      return this.getStoreValue(
        this.moduleName,
        moduleDetails(this.moduleName)?.property
      );
    },
    dispatchFilters() {
      this.setApiLoading({ key: "component", value: true });
      store
        .dispatch(this.storeDetails.action, this.filtersPayload())
        .finally(() => {
          this.setApiLoading({ key: "component", value: false });
        });
    },
    filtersPayload() {
      return {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: true,
      };
    },
    commitAndDispatch(filters) {
      store.commit(this.storeDetails.mutation, filters);
      this.dispatchFilters();
    },
  },
  computed: {
    storeDetails() {
      const { mutation, property, action } = moduleDetails(this.moduleName);
      return {
        value: this.mainFilterObject()?.advancedFilters[this.filterName],
        advancedFilters: this.mainFilterObject()?.advancedFilters,
        mutation,
        property,
        action,
      };
    },
  },
};
