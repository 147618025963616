<template>
  <div class="flex items-center">
    <img
      :src="require('@/assets/images/' + option.flag)"
      alt=""
      class="flex-shrink-0 h-6 w-6 rounded-full"
    />
    <span
      :class="[
        selected ? 'font-semibold' : 'font-normal',
        'ml-3 block truncate',
      ]"
    >
      {{ option.label }}
    </span>

    <span
      v-if="selected"
      class="text-primary-600 absolute inset-y-0 right-0 flex items-center pr-4"
    >
      <CheckIcon class="h-5 w-5" aria-hidden="true" />
    </span>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/solid";
export default {
  name: "CurrencyDisplay",
  components: {
    CheckIcon,
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
