<template>
  <div class="table-search relative">
    <button v-if="!loading" type="button" class="focus:outline-none">
      <SearchIcon
        :class="['text-gray-400 hover:text-gray-500', ...searchButtonClasses]"
      />
    </button>
    <div v-else class="pointer-events-none flex items-center">
      <SpinningLoaderSVG class="text-hivebuy-green" classes="h-5 w-5" />
    </div>
    <input
      :class="[
        'flex w-full border-0 focus:outline-none outline-0 search text-base',
        ...searchClasses,
        disabled ? 'cursor-not-allowed' : '',
      ]"
      type="search"
      class="group"
      name="search"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="nope"
      :value="value"
      @input="
        (event) =>
          executeDebounceSearch(onChange, {
            key: 'search',
            value: event.target.value,
          })
      "
    />
  </div>
</template>
<script>
import debounceMixin from "@/components/mixins/debounceMixin.js";
import { SearchIcon } from "@heroicons/vue/solid";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
export default {
  components: { SearchIcon, SpinningLoaderSVG },
  mixins: [debounceMixin],
  props: {
    value: { type: String, default: "" },
    onChange: { type: Function, required: true },
    searchClasses: {
      type: Array,
      default: () => ["border-0"],
    },
    searchButtonClasses: {
      type: Array,
      default: () => ["h-5 w-5"],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search Request",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.table-search {
  @apply flex flex-1;
}

.search {
  outline: 0 transparent !important;
  border: transparent !important;
  box-shadow: none !important;
}
</style>
