<template>
  <div class="flex flex-wrap">
    <div
      v-for="subCategory in subCategories(categoryId)"
      :key="subCategory"
      class="bg-hivebuy-green text-white inline-block px-2 font-regular rounded-full whitespace-nowrap py-0.5 text-xs mr-1 mb-1"
    >
      <p>{{ subCategory.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  name: "SubCategories",
  props: {
    categoryId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["subCategories"]),
  },
};
</script>
