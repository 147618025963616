<template>
  <div class="relative">
    <div class="cards-holder">
      <div
        v-for="(requestOption, index) in requestOptions"
        :id="toKebabCase(requestOption.name)"
        :key="index"
        class="custom-card request-card group relative"
        :class="requestOption.enabled ? 'enabled' : ''"
        @mouseover="hoverOnCard[index] = true"
        @mouseleave="hoverOnCard[index] = false"
        @click="requestOption.onClick"
      >
        <div class="upper-part">
          <div v-if="requestOption.beta" class="absolute top-3 right-3">
            <div class="relative">
              <button
                class="bg-gradient-to-r from-primary to-hivebuy-green text-primary font-normal rounded p-0.5 text-sm"
              >
                <span class="flex w-full bg-gray-100 rounded p-2">
                  Beta Access
                </span>
              </button>
            </div>
          </div>
          <div class="icon-holder group-hover:border-primary duration-500">
            <component
              :is="requestOption.image"
              class="h-[24px] w-[24px] sm:h-[44px] sm:w-[44px] text-gray-400 group-hover:text-primary duration-500 group-hover:scale-150"
            />
          </div>
        </div>

        <div class="lower-part">
          <h3 class="card-title group-hover:text-white">
            {{ requestOption.name }}
          </h3>
          <dt class="sr-only">Title</dt>
          <dd class="description group-hover:text-white">
            {{ requestOption.title }}
          </dd>
          <div class="request-button">
            <div class="flex items-end">
              <p>{{ $t("purchaseRequest.newRequest.startNewRequest") }}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ShoppingBagIcon,
  ClipboardListIcon,
  LightningBoltIcon,
} from "@heroicons/vue/outline";
import { Button } from "@/components/shared";
import { toKebabCase } from "@/utils/utility_methods.js";
import { SpeakerphoneIcon } from "@heroicons/vue/outline";
import ShoppinglistSVG from "@/assets/images/ShoppinglistSVG.vue";
import ConstructionSVG from "@/assets/images/ConstructionSVG.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
export default {
  components: {
    Button,
    SpeakerphoneIcon,
    ShoppinglistSVG,
    ConstructionSVG,
  },
  props: {
    closeAction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      hoverOnCard: [],
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    requestOptions() {
      const options = [
        // {
        //   name: this.$t("purchaseRequest.newRequest.shoppingList"),
        //   title: this.$t("purchaseRequest.newRequest.shoppingListSubtext"),
        //   type: "Internal",
        //   image: "ShoppinglistSVG",
        //   enabled: true,
        //   featureAllowed: this.isFeatureAllowed("shoppingList"),
        //   onClick: () => this.onClick("NewShoppingList"),
        // },
        // {
        //   name: this.$t("purchaseRequest.newRequest.freeForm"),
        //   title: this.$t("purchaseRequest.newRequest.freeFormSubtext"),
        //   type: "Internal",
        //   image: ClipboardListIcon,
        //   enabled: true,
        //   featureAllowed: true,
        //   onClick: () => this.onClick("PurchaseRequest"),
        // },
        {
          name: this.$t("purchaseRequest.newRequest.creation.name"),
          title: this.$t("purchaseRequest.newRequest.creation.title"),
          type: "Internal",
          image: ClipboardListIcon,
          enabled: true,
          beta: false,
          featureAllowed: true,
          onClick: () => this.onClick("CreatePurchaseRequest"),
        },
        {
          name: this.$t("purchaseRequest.newRequest.internal"),
          title: this.$t("purchaseRequest.newRequest.internalSubtext"),
          type: "Internal",
          image: ShoppingBagIcon,
          enabled: true,
          featureAllowed: true,
          onClick: () => this.onClick("Catalogue"),
        },
        {
          name: this.$tc("global.integratedSupplier", 2),
          title: this.$t("purchaseRequest.newRequest.integratedSubtext"),
          type: "External",
          image: LightningBoltIcon,
          enabled: true,
          featureAllowed: true,
          beta: false,
          onClick: () => this.onClick("Suppliers"),
        },
        {
          name: this.$t("purchaseRequest.newRequest.shoppingList"),
          title: this.$t("purchaseRequest.newRequest.shoppingListSubtext"),
          type: "Internal",
          image: "ShoppinglistSVG",
          enabled: true,
          featureAllowed: this.isFeatureAllowed("shoppingList"),
          onClick: () => this.onClick("NewShoppingList"),
        },
      ];
      return options.filter((option) => option.featureAllowed);
    },
  },
  methods: {
    toKebabCase,
    onClick(name) {
      this.$router.push({ name });
      this.closeAction();
    },
  },
};
</script>
<style scoped>
.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 22px;
  margin-right: -50px;
  margin-top: 15px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}

.beta {
  @apply absolute -inset-2 bg-gradient-to-r from-hivebuy_plum to-hivebuy_turquoise rounded-lg blur opacity-75 group-hover:opacity-100;
}

.page-heading {
  @apply text-left;
}

.page-heading h1 {
  @apply text-primary font-bold text-base;
  font-size: 18px;
}

.page-heading p {
  @apply text-gray-500 text-base;
}

.cards-holder {
  @apply grid grid-rows-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-6 mt-4;
}
.cards-holder div.enabled {
  @apply cursor-pointer transition duration-1000 ease-in-out group-hover:shadow-xl;
}
.request-card {
  @apply flex !overflow-hidden gap-4 sm:gap-6 relative py-8 sm:py-5;
}

.request-card:before {
  content: "";
  @apply absolute left-0 right-0 top-0 bottom-0  z-[1] opacity-0 transition-all bg-gradient-to-b  from-[#9e4770] to-[#5c164e] duration-500;
}

.request-card:hover:before {
  @apply opacity-100;
}

.request-card .upper-part {
  @apply flex items-center z-[2];
}

.request-card .lower-part {
  @apply flex sm:flex-col  sm:items-start w-full justify-between items-center z-[2];
}

.request-card .card-ribbon {
  @apply ribbon bg-gray-200 text-xs font-medium z-10 flex place-content-center items-center;
}
.request-card .icon-holder {
  @apply w-[50px] h-[50px] sm:w-[100px] sm:h-[100px]  rounded-full items-center flex bg-gray-50 relative justify-center border-2 border-gray-200;
}

.request-card .card-title {
  @apply text-gray-700 font-semibold group-hover:text-white duration-500;
}

.request-card .description {
  @apply text-gray-500 text-sm mb-4 pr-6 mt-2 group-hover:text-white  duration-500 hidden sm:flex;
}

.request-card .request-button {
  @apply flex justify-center w-[36px] h-[36px] sm:w-auto sm:h-auto px-0 py-0 text-primary font-semibold sm:px-6 sm:py-3 border border-gray-200 rounded-full bg-white items-center mr-4 sm:mr-0;
}

.request-card .request-button p {
  @apply hidden sm:flex sm:text-sm;
}

.request-card .request-button svg {
  @apply md:ml-2 h-[18px];
}
</style>
