<template>
  <div id="spark" class="custom-card analytic-card">
    <ChartLoader v-if="loading" />
    <div v-else class="card-data">
      <div class="custom-card-header">
        <h2 class="card-title">{{ subHeading }}</h2>
        <Description v-if="description" :description="description" />
      </div>
      <div class="card-content justify-end">
        <apexchart
          class="apex-chart"
          type="area"
          height="160"
          :options="options"
          :series="series"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { groupBy } from "@/utils/utility_methods.js";
import { ChartLoader } from "@/components/shared/index";
import Description from "@/components/Analytics/charts/description.vue";
export default {
  components: { ChartLoader, Description },
  props: {
    description: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    heading: { type: String, required: true },
    subHeading: { type: String, required: true },
    records: { type: Array, required: true },
  },
  computed: {
    options() {
      return {
        chart: {
          type: "area",
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#008FFB"],
        title: {
          text: this.heading,
          offsetX: 30,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: this.subHeading,
          offsetX: 30,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        xaxis: {
          type: "datetime",
          categories: Object.keys(groupBy(this.records, "createdAt")),
        },
      };
    },
    series() {
      return [
        {
          name: this.$t("charts.spark.series"),
          data: Object.values(groupBy(this.records, "createdAt")).map(
            (value) => {
              return value.length;
            }
          ),
        },
      ];
    },
  },
};
</script>
<style scope>
.apex-chart {
  @apply w-full;
}
</style>
