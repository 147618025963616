<template>
  <Form :key="componentKey" />
</template>

<script>
import { Form } from "@/components/RequestCreation";
export default {
  name: "RequestCreation",
  components: {
    Form,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  created() {
    this.$router.afterEach((to, from) => {
      if (to.fullPath === from.fullPath) {
        this.componentKey += 1;
      }
    });
  },
};
</script>
