import { mapActions, mapGetters } from "vuex";
import {
  GET_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import { isObjectEmpty } from "@/utils/utility_methods.js";
export default {
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getPurchaseRequest: GET_PURCHASE_REQUEST,
    }),
    async getRequestData(prId) {
      const requestData = Object.assign({}, this.requestDetails(prId));
      if (isObjectEmpty(requestData)) {
        return await this.getPurchaseRequest(prId);
      } else {
        return requestData;
      }
    },
  },
};
