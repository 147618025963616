<template>
  <PaymentMethodForm
    :show-modal="showModal"
    :close-modal="closeModal"
    :save-payment-method="onUpdate"
    :is-edit="true"
    :payment-method-to-edit="paymentMethodToEdit"
  />
</template>

<script>
import { mapActions } from "vuex";

import PaymentMethodForm from "@/components/CompanyManagement/Profile/PaymentMethods/PaymentMethodForm";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHOD,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    PaymentMethodForm,
  },

  props: {
    paymentMethodToEdit: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updatePaymentMethod: UPDATE_PAYMENT_METHOD,
      getPaymentMethods: GET_PAYMENT_METHODS,
    }),
    onUpdate(paymentMethod) {
      this.updatePaymentMethod(paymentMethod)
        .then(() => {
          this.closeModal();
          this.getPaymentMethods();
          this.showNotification("Payment Method updated successfully");
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>

<style scoped></style>
