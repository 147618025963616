<template>
  <div>
    <label class="block text-sm font-medium text-gray-700"
      >{{ $t("global.pricing.quantity") }}
    </label>
    <div class="group">
      <div class="mt-2 relative flex items-center rounded-md shadow-sm">
        <div class="flex-1">
          <InputNumber
            v-model="value"
            :show-buttons="showButtons"
            button-layout="horizontal"
            :min="minimumQuantity"
            :step="steps"
            :disabled="disabled"
            :max-fraction-digits="4"
            :locale="locale"
            class="w-full focus:ring-0 hover:ring-0"
          >
            <template #incrementbuttonicon>
              <span class="pi pi-plus" />
            </template>
            <template #decrementbuttonicon>
              <span class="pi pi-minus" />
            </template>
          </InputNumber>
        </div>

        <div class="absolute inset-y-0 right-1 flex items-center">
          <HivebuySelect
            v-model="unit"
            :filter="false"
            :options="unitOptions"
            :error-text="errorText('unit')"
            :disabled="shouldDisableOptions"
            :clearable="false"
            class="ml-2 w-24 focus:ring-0 hover:ring-0"
          />
        </div>
      </div>

      <FieldError :text="error" />
    </div>
  </div>
</template>

<script>
import { HivebuySelect } from "design-buy";
import InputNumber from "primevue/inputnumber";
import { newRequestMixin } from "@/components/mixins/index.js";
import { FieldError } from "@/components/shared/index";
import { CURRENCY_TO_LOCALE } from "@/utils/constants.js";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    InputNumber,
    FieldError,
    HivebuySelect,
  },
  mixins: [newRequestMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    errorText: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    locale() {
      return CURRENCY_TO_LOCALE[this.company.currency];
    },
    unitOptions() {
      return [
        {
          label: this.$t("purchaseRequest.detail.unitOptions.pc"),
          value: "pc",
        },
        {
          label: this.$t("purchaseRequest.detail.unitOptions.days"),
          value: "days",
        },
        {
          label: this.$t("purchaseRequest.detail.unitOptions.hours"),
          value: "hours",
        },
      ];
    },
    minNumber() {
      return this.unit == "pc" ? 1 : 0.1;
    },
    minimumQuantity() {
      return this.item.minimalQuantity || this.minNumber;
    },
    steps() {
      return this.item.quantityStep || this.minNumber;
    },

    error() {
      const tolerance = 0.00001; // Tolerance to handle floating-point precision
      if (this.errorText("quantity")) {
        return this.errorText("quantity");
      }

      if (this.value < this.minimumQuantity) {
        return this.$t("catalogue.catalogueItemForm.quantityError.min", {
          attr: this.minimumQuantity,
        });
      }

      const difference = this.value - this.minimumQuantity;

      if (
        Math.abs(
          difference / this.steps - Math.round(difference / this.steps)
        ) > tolerance
      ) {
        return this.$t("catalogue.catalogueItemForm.quantityError.step", {
          step: parseFloat(this.steps),
          min: parseFloat(this.minimumQuantity),
        });
      }

      return null;
    },
    unit: {
      get() {
        return this.item.unit;
      },
      set(value) {
        this.updateValue({ unit: value });
      },
    },
    value: {
      get() {
        return parseFloat(this.item.quantity);
      },
      set(value) {
        this.updateValue({
          quantity: parseFloat(value || this.minimumQuantity).toFixed(4),
        });
      },
    },
  },
  methods: {
    updateValue(value) {
      const object = {
        item: value,
        index: this.index,
        action: "update",
      };
      this.saveRequestAttributes(object);
    },
  },
};
</script>

<style scoped>
:deep(.p-inputnumber-input) {
  @apply box-border w-full;
}

:deep(.p-dropdown) {
  @apply !border-none;
}
</style>
