<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :prevent-click-outside="true"
    :size-classes="['w-full sm:max-w-lg lg:max-w-4xl']"
  >
    <template #icon>
      <PencilAltIcon
        v-if="isEdit"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <UserAddIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">
        {{ $t("legalTendency.legalTendencyModal.addLegalTendency") }}
      </h3>
    </template>
    <template #body>
      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <div class="sm:col-span-full">
          <Input
            :id="'name'"
            :error-text="error(v$.legalTendency.name)"
            :value="legalTendency.name"
            :name="'name'"
            :on-value-change="onAttributeChange"
            :type="'text'"
            :placeholder="$t('legalTendency.legalTendencyModal.fields.name')"
            :label="$t('legalTendency.legalTendencyModal.fields.name')"
          />
        </div>

        <div class="sm:col-span-full">
          <div class="label">
            {{ $t("legalTendency.legalTendencyModal.fields.categories") }}
          </div>
          <CategorySelect v-model="legalTendency.categories" :multiple="true" />
        </div>

        <div class="sm:col-span-full">
          <PaymentMethod
            v-model="legalTendency.legalEntityPaymentMethods"
            no-display
            :is-invalid="v$.legalTendency.legalEntityPaymentMethods.$error"
            mode="tags"
            :object-type="true"
            :add-default="true"
          />
        </div>

        <div class="sm:col-span-full">
          <RequestAddress
            v-model="legalTendency.legalEntityDeliveryAddresses"
            no-display
            mode="tags"
            :object-type="true"
            :is-invalid="v$.legalTendency.legalEntityDeliveryAddresses.$error"
            :add-default="true"
            object-filter-key="id"
          />
        </div>

        <div class="sm:col-span-full">
          <RequestAddress
            v-model="legalTendency.legalEntityInvoiceAddresses"
            no-display
            is-invoice-address
            :label="$t('purchaseRequest.detail.invoiceAddress')"
            :is-invalid="v$.legalTendency.legalEntityInvoiceAddresses.$error"
            :add-default="true"
            mode="tags"
            :object-type="true"
            object-filter-key="id"
          />
        </div>
        <div
          class="col-span-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4"
        >
          <Input
            :value="legalTendency.accountingArea"
            :name="'accountingArea'"
            :placeholder="
              $t('legalTendency.legalTendencyModal.fields.accountingArea')
            "
            :label="
              $t('legalTendency.legalTendencyModal.fields.accountingArea')
            "
            :on-value-change="onAttributeChange"
          />
          <Input
            :value="legalTendency.identifier"
            :name="'identifier'"
            :placeholder="
              $t('legalTendency.legalTendencyModal.fields.identifier')
            "
            :label="$t('legalTendency.legalTendencyModal.fields.identifier')"
            :on-value-change="onAttributeChange"
          />
          <Input
            :value="legalTendency.vatNumber"
            :name="'vatNumber'"
            :placeholder="
              $t('legalTendency.legalTendencyModal.fields.vatNumber')
            "
            :label="$t('legalTendency.legalTendencyModal.fields.vatNumber')"
            :on-value-change="onAttributeChange"
          />
        </div>
        <div
          v-if="isFeatureAllowed('legalEntityFiltering')"
          class="sm:col-span-2"
        >
          <SwitchCheckbox
            v-model="legalTendency.isGlobal"
            class="inline-flex mb-2 mr-0"
            :label="$t('legalTendency.legalTendencyModal.fields.isGlobal')"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        :on-click-handler="onModalClose"
        :color="'gray'"
        :disabled="isApiLoading('saveLegalEntity')"
      >
        {{ $t("buttons.cancel") }}
      </Button>

      <Button
        :on-click-handler="onSave"
        :loading="isApiLoading('saveLegalEntity')"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import useValidate from "@vuelidate/core";
import { UserAddIcon, PencilAltIcon } from "@heroicons/vue/solid";
import {
  Button,
  Modal,
  Input,
  PaymentMethod,
  CategorySelect,
  SwitchCheckbox,
} from "@/components/shared/index";
import { errorMixin } from "@/components/mixins/index.js";
import { required } from "@vuelidate/validators";
import RequestAddress from "@/components/shared/RequestAddress";
import { mapActions, mapGetters } from "vuex";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";
import {
  COMPANY_MANAGEMENT_MODULE,
  SAVE_LEGAL_TENDENCY,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    RequestAddress,
    UserAddIcon,
    Modal,
    Button,
    Input,
    PaymentMethod,
    PencilAltIcon,
    CategorySelect,
    SwitchCheckbox,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    legalTendencyToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      v$: useValidate(),
      legalTendency: this.initializeLegalEntity(),
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoriesList",
      "addressList",
      "paymentList",
    ]),
    isEdit() {
      return !isObjectEmpty(this.legalTendencyToEdit);
    },
  },
  watch: {
    showModal: {
      handler(val) {
        if (val) {
          this.legalTendency = Object.assign(
            {},
            this.isEdit ? this.legalTendencyToEdit : this.legalTendency
          );

          if (!this.isEdit && !this.legalTendency?.categories?.length) {
            this.legalTendency.categories = this.categoriesList.map(
              (obj) => obj.id
            );
          }

          if (this.isEdit) {
            this.legalTendency = this.transformDefaultValues("edit");
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      saveLegalTendency: SAVE_LEGAL_TENDENCY,
    }),
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.onSaveLegalTendency();
      }
    },
    onSaveLegalTendency() {
      this.setApiLoading({ key: "saveLegalEntity", value: true });
      const payload = this.transformDefaultValues();
      const extraKeys = ["buyers", "members", "departments"];
      extraKeys.forEach((key) => {
        delete payload[key];
      });
      this.saveLegalTendency(payload)
        .then(() => {
          const successText = this.isEdit
            ? this.$t("legalTendency.legalTendencyUpdateSuccess")
            : this.$t("legalTendency.legalTendencyCreateSuccess");
          this.showNotification(successText);
          this.onModalClose();
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({ key: "saveLegalEntity", value: false })
        );
    },
    transformDefaultValues(action = "save") {
      const legalTendency = deepClone(this.legalTendency);
      const legalEntityValues = {
        legalEntityPaymentMethods: {
          attribute: "paymentMethod",
          storeState: "paymentList",
          titleKey: "title",
        },
        legalEntityDeliveryAddresses: {
          attribute: "deliveryAddress",
          storeState: "addressList",
          titleKey: "name",
        },
        legalEntityInvoiceAddresses: {
          attribute: "invoiceAddress",
          storeState: "addressList",
          titleKey: "name",
        },
      };
      Object.entries(legalEntityValues).forEach(([key, value]) => {
        if (legalTendency[key].length) {
          legalTendency[key] = legalTendency[key].map((obj) => {
            let updatedObj;
            if (action === "save") {
              updatedObj = {
                [value.attribute]: obj.id,
                isDefault: obj.isDefault,
              };
              if (obj?.legalEntityId) {
                updatedObj.id = obj.legalEntityId;
              } else if (this.isEdit) {
                const existingItem = this.legalTendencyToEdit[key].find(
                  (item) => item[value.attribute] === obj.id
                );
                if (existingItem) {
                  updatedObj.id = existingItem.id;
                }
              }
            } else {
              const storeObject = this[value.storeState].find(
                (item) => item.id === obj[value.attribute]
              );
              updatedObj = {
                [value.titleKey]: storeObject[value.titleKey],
                id: obj[value.attribute],
                legalEntityId: obj.id,
                isDefault: obj.isDefault,
              };
            }
            return updatedObj;
          });
        }
      });
      return legalTendency;
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.legalTendency = this.initializeLegalEntity();
        this.v$.$reset();
      }, 200);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.legalTendency[name] = value;
    },
    initializeLegalEntity() {
      return {
        legalEntityPaymentMethods: [],
        legalEntityDeliveryAddresses: [],
        legalEntityInvoiceAddresses: [],
      };
    },
  },
  validations() {
    return {
      legalTendency: {
        name: { required },
        legalEntityPaymentMethods: { required },
        legalEntityDeliveryAddresses: { required },
        legalEntityInvoiceAddresses: { required },
      },
    };
  },
};
</script>
