<template>
  <div>
    <Button
      v-if="!workflow.requiredApprovalTypes.length"
      :on-click-handler="() => (showApprovalModal = true)"
    >
      {{ $t("approvalWorkflow.globalPolicies.addApprover.btnText") }}
    </Button>
    <FieldError
      v-if="v$.workflow.requiredApprovalTypes.$error"
      :text="$t('approvalWorkflow.globalPolicies.addApprover.errorText')"
    />

    <div class="mt-4">
      <Approvers
        :approvals="workflow.requiredApprovalTypes"
        :on-delete="() => {}"
        :on-edit="editApproval"
        :allow-actions="true"
        :is-global-policy="true"
      />
    </div>

    <ApproversModal
      :show-modal="showApprovalModal"
      :close-modal="closeModal"
      :workflow="workflow"
      :edit-approval-value="editApprovalValue"
      :save-approver="saveApprover"
      :is-global-policy="true"
    />
  </div>
</template>

<script>
import { isObject } from "@/utils/utility_methods";
import { Approvers } from "@/components/PurchaseRequest";
import ApproversModal from "@/components/CompanyManagement/ApprovalWorkflow/GlobalPolicies/ApproversModal";
import { mapState, mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { FieldError, Button } from "@/components/shared/index";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default {
  components: {
    ApproversModal,
    Approvers,
    FieldError,
    Button,
  },
  props: {
    approvalWorkflow: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      v$: useValidate(),
      showApprovalModal: false,
      workflow: this.approvalWorkflowDefaultValue(),
      editApprovalValue: null,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["users"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentDetails"]),
  },
  created() {
    this.initializeApprovalTypes();
  },
  methods: {
    onAmountChange(event) {
      this.amount = event.target.value;
    },
    initializeApprovalTypes() {
      Object.entries(this.approverKeysMapping()).forEach(
        ([approvalKey, mappedKey]) => {
          let value = this.approvalWorkflow[approvalKey];
          if (value) {
            if (approvalKey === "userForResult") {
              value = this.users[value];
            } else if (approvalKey === "departmentForResult") {
              value = this.departmentDetails(value, true);
            }
            this.workflow.requiredApprovalTypes.push({ [mappedKey]: value });
          }
        }
      );
    },
    approverKeysMapping() {
      return {
        roleForResult: "role",
        userForResult: "user",
        departmentForResult: "approverOfDepartment",
        departmentApprover: "departmentApprover",
      };
    },
    requestPayload() {
      const approverKeysMapping = this.approverKeysMapping();
      const { requiredApprovalTypes } = this.workflow;
      const approverType = requiredApprovalTypes[0];
      const [key, value] = Object.entries(approverType)[0];
      let payload = {};

      if (key === approverKeysMapping.roleForResult) {
        payload = { ...payload, roleForResult: value };
      } else if (key === approverKeysMapping.userForResult) {
        payload = { ...payload, userForResult: value.id };
      } else {
        payload = isObject(value)
          ? { ...payload, departmentForResult: value.id }
          : { ...payload, departmentApprover: value };
      }

      return payload;
    },
    closeModal() {
      this.showApprovalModal = false;
    },
    saveApprover(type, category, value, index = null) {
      const approval = {};

      approval[this.mappedCategory(category, value)] = value;
      if (index != null) {
        this.workflow[type][index] = approval;
      } else {
        this.workflow[type] = [approval];
      }
    },
    mappedCategory(category, value) {
      let mappedCategory = category;
      if (category == "approverOfDepartment" && typeof value == "boolean")
        mappedCategory = "departmentApprover";
      if (category == "departmentApprover" && typeof value == "object")
        mappedCategory = "approverOfDepartment";

      return mappedCategory;
    },
    editApproval(type, value) {
      this.editApprovalValue = value;
      this.showApprovalModal = true;
    },
    approvalWorkflowDefaultValue() {
      return {
        requiredApprovalTypes: [],
      };
    },
  },
  validations() {
    return {
      workflow: {
        requiredApprovalTypes: { required },
      },
    };
  },
};
</script>
