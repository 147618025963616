import {
  PURCHASE_ORDER_MODULE,
  GET_PURCHASE_ORDERS,
} from "@/store/PurchaseOrder/types";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(PURCHASE_ORDER_MODULE, ["purchaseOrders"]),
    arePurchaseOrdersPresent() {
      return this.purchaseOrders.length;
    },
    arePurchaseOrdersLoading() {
      return this.isLoading || !this.purchaseOrders;
    },
    arePurchaseRequestsLoaded() {
      return !!this.purchaseOrders;
    },
    arePurchaseRequestsLoading() {
      return this.isLoading || !this.arePurchaseRequestsLoaded;
    },
  },
  mounted() {
    (!this.purchaseOrders || !this.purchaseOrders.length) &&
      this.fetchPurchaseOrders();
  },
  methods: {
    ...mapActions(PURCHASE_ORDER_MODULE, {
      getPurchaseOrders: GET_PURCHASE_ORDERS,
    }),
    fetchPurchaseOrders() {
      this.isLoading = true;

      const params = {};

      this.getPurchaseOrders(params)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
