<template>
  <span>
    <div class="relative mt-6">
      <div class="absolute inset-0 flex items-center px-8" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center group mb-5">
        <span
          class="bg-gray-100 text-sm text-gray-500 group-hover:text-primary cursor-pointer"
          @click="showAdvancedOptions = !showAdvancedOptions"
        >
          <MinusIcon v-if="showAdvancedOptions" class="h-4 inline-flex px-4" />
          <PlusIcon v-else class="h-4 inline-flex px-4" />{{
            $t("purchaseRequest.detail.advanced")
          }}<MinusIcon
            v-if="showAdvancedOptions"
            class="h-4 inline-flex px-4"
          />
          <PlusIcon v-else class="h-4 inline-flex px-4" />
        </span>
      </div>
    </div>
    <CollapseTransition>
      <AdvanceOptionsForm
        v-show="showAdvancedOptions"
        v-model="item"
        :default-product="defaultProduct"
        :requested-for="requestedFor"
        :v="v"
      />
    </CollapseTransition>
  </span>
</template>
<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { PlusIcon, MinusIcon } from "@heroicons/vue/solid";

import AdvanceOptionsForm from "@/components/PurchaseRequest/Form/AdvanceOptionsForm.vue";

export default {
  components: {
    MinusIcon,
    PlusIcon,
    CollapseTransition,
    AdvanceOptionsForm,
  },
  props: {
    defaultProduct: { type: Object, default: null },
    modelValue: {
      type: Object,
      default: () => {},
    },
    requestedFor: {
      type: Object,
      default: null,
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      showAdvancedOptions: false,
    };
  },
  computed: {
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
