<template>
  <!-- The v-if checks: Should be no PO but a PuR, either in status = P or no status (in creation) -->
  <div v-if="!budget && !isInBudget">
    <div class="section-title mt-8">
      {{ $t("purchaseRequest.summary.budget.title") }}
    </div>
    <BudgetDisplay :is-in-budget="false" />
  </div>
  <div v-else-if="!isPurchaseOrder" class="budget">
    <div v-if="budget" class="py-4">
      <div class="section-title">
        {{ $t("purchaseRequest.summary.budget.title") }}

        <span
          v-if="!isBudgetLevelEditable && false && !hidden"
          class="float-right"
        >
          <Button :on-click-handler="onEditBudgetLevel">{{
            $t("purchaseRequest.summary.budget.button")
          }}</Button>
        </span>
      </div>

      <!-- START QUICK CHECK -->
      <div
        v-if="isInBudget && !isBudgetLevelEditable"
        class="col-span-1 flex shadow-sm rounded-md mb-10"
      >
        <div
          class="bg-hivebuy-green flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-sm font-medium rounded-l-md"
        >
          <ThumbUpIcon class="w-8 h-8 text-white" />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 bg-white rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm font-medium">
            <p class="text-gray-700 py-3 hidden sm:block">
              {{ $t("purchaseRequest.summary.budget.requestFits") }}
            </p>
            <p class="text-gray-700 py-3 block sm:hidden">
              {{ $t("purchaseRequest.summary.budget.aligned") }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-else-if="isBudgetLevelEditable && false"
        class="flex rounded-md mb-10"
      >
        <Multiselect
          v-model="budgetLevelToUpdate"
          class="ml-0 w-9/12 bg-white"
          mode="single"
          :options="budgetOptionsToUpdate()"
          :placeholder="'Select Budget Level'"
          :label="'Budget Level'"
          :can-deselect="false"
        />
        <span class="inline-flex group-hover:text--hivebuy-green">
          <button
            type="button"
            class="mt-3 ml-3 w-full inline-flex justify-center hover:text-hivebuy-green focus:outline-none"
            @click="onUpdate"
          >
            <CheckCircleIcon class="w-6 h-6 text-hivebuy-green" />
          </button>
          <button
            type="button"
            class="mt-3 ml-3 w-full inline-flex justify-center hover:text-hivebuy-red focus:outline-none"
            @click="onCancelEditBudgetLevel"
          >
            <XCircleIcon class="w-6 h-6 text-hivebuy-red" />
          </button>
        </span>
      </div>

      <div v-if="!isInBudget" class="col-span-1 flex shadow-sm rounded-md mb-4">
        <div
          class="bg-hivebuy-red flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-sm font-medium rounded-l-md"
        >
          <ThumbDownIcon class="w-8 h-8 text-white" />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 bg-white rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm font-medium">
            <p class="text-gray-700 py-3 hidden sm:block">
              {{ $t("purchaseRequest.summary.budget.requestFitsNot") }}
            </p>
            <p class="text-gray-700 py-3 block sm:hidden">
              {{ $t("purchaseRequest.summary.budget.notAligned") }}
            </p>
          </div>
        </div>
      </div>
      <!-- END QUICK CHECK -->
      <div v-if="isAllowedToSeeDetails">
        <div class="text-bayoux-500 font-medium text-md mt-6 mb-2">
          {{ $t("purchaseRequest.summary.budget.details") }}
        </div>
        <div class="block xl:flex items-center justify-between">
          <div class="flex items-center xl:mb-0 mb-1">
            <span><CashIcon class="w-6 h-6 text-hivebuy-green mr-3" /> </span>
            <span class="text-sm font-medium">
              {{ $t("purchaseRequest.summary.budget.total") }}
              <i18n-n
                :value="parseFloat(budget.amount)"
                format="currency"
                :locale="currencyToLocale(budget.amountCurrency)"
              />
            </span>
          </div>
          <div class="block xl:flex items-center">
            <div class="font-medium text-sm flex items-center xl:mb-0 mb-1">
              <span class="text-hivebuy-red mx-1">
                {{ $t("purchaseRequest.summary.budget.used") }}
              </span>
              <span
                ><i18n-n
                  :value="parseFloat(budget.usedBudget)"
                  format="currency"
                  :locale="currencyToLocale(budget.amountCurrency)"
              /></span>
            </div>
            <div
              class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
            >
              <span class="text-yellow-500 mx-1">
                {{ $t("purchaseRequest.summary.budget.pending") }}</span
              >
              <span
                ><i18n-n
                  :value="parseFloat(pending)"
                  format="currency"
                  :locale="currencyToLocale(budget.amountCurrency)"
              /></span>
            </div>
            <div
              class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
            >
              <span class="text-hivebuy-green mx-1">
                {{ $t("purchaseRequest.summary.budget.remaining") }}
              </span>
              <span
                ><i18n-n
                  :value="parseFloat(budget.remainingBudget)"
                  format="currency"
                  :locale="currencyToLocale(budget.amountCurrency)"
              /></span>
            </div>
          </div>
        </div>
        <div v-if="showBar">
          <div
            class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
          >
            <div
              v-if="calcBar().used > 0"
              class="progress-bar progress-bar-approved"
              :style="{ width: `${calcBar().used}%` }"
            >
              <i18n-n
                :value="calcBar().used / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              v-if="calcBar().pending > 0"
              class="progress-bar progress-bar-pending"
              :style="{ width: `${calcBar().pending}%` }"
            >
              <i18n-n
                :value="calcBar().pending / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              class="progress-bar progress-bar-remaining"
              :style="{ width: `${calcBar().current}%` }"
            >
              <i18n-n
                :value="calcBar().current / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              class="flex-grow text-center text-gray-600 text-xs font-medium"
            >
              <i18n-n
                :value="calcBar().remaining / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
          </div>
          <span class="text-sm">
            <i18n-t keypath="purchaseRequest.summary.budget.usageText" tag="p">
              <template #value>
                <span class="bg-gray-400 py-1 px-2 rounded-md text-white">
                  <i18n-n
                    :value="calcBar().current / 100"
                    format="percent"
                    :locale="currencyToLocale(currency)"
                  />
                </span>
              </template>
            </i18n-t>
          </span>
        </div>
        <div class="xl:mb-0 mb-1">
          <span class="flex items-center text-sm font-medium">
            <span>
              {{ $t("purchaseRequest.summary.budget.source") }}:
              <span v-if="budgetLevel == 'C'" class="ml-2 font-medium">
                {{ $t("purchaseRequest.summary.budget.companySource") }}
              </span>
            </span>
            <EntityDisplay
              v-if="budgetLevel !== 'C'"
              :entity="budget"
              :read-only="true"
              :show-name="true"
              :is-budget="true"
            />
          </span>
        </div>
      </div>
    </div>
    <div v-else class="product-budget">
      <div class="section-title mt-8">
        {{ $t("purchaseRequest.summary.budget.title") }}
      </div>
      <div class="wrapper">
        <div class="icon-holder">
          <XIcon class="w-8 h-8 text-white" />
        </div>
        <div class="content-holder">
          <p class="text-gray-700 py-3 hidden sm:block">
            {{ $t("purchaseRequest.summary.budget.noBudgetNecessary") }}
          </p>
          <p class="text-gray-700 py-3 block sm:hidden">N/A</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="section-title mt-8">
      {{ $t("purchaseRequest.summary.budget.title") }}
    </div>
    <div
      v-if="
        (purchaseRequest && purchaseRequest.requestStatus === 'A') ||
        purchaseRequest?.status === 'A'
      "
    >
      <BudgetDisplay :is-in-budget="Boolean(isInBudget)" />
    </div>
    <div v-else>
      <div class="col-span-1 flex shadow-sm rounded-md mb-10">
        <div
          class="bg-gray-400 flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-sm font-medium rounded-l-md"
          :class="['']"
        >
          <XIcon class="w-8 h-8 text-white" />
        </div>
        <div
          class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 bg-white rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm font-medium">
            <p class="text-gray-700 py-3 hidden sm:block">
              {{ $t("charts.budgets.noHistory") }}
            </p>
            <p class="text-gray-700 py-3 block sm:hidden">N/A</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_BUDGET_MAPPING } from "@/utils/constants";
import { CashIcon } from "@heroicons/vue/outline";
import { getItemFromLocalStorage } from "../../../utils/utility_methods";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  XIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";
import {
  currencyToLocale,
  getKeyByValue,
  removeKeysFromObject,
} from "@/utils/utility_methods.js";
import Button from "@/components/shared/Button";
import Multiselect from "@vueform/multiselect";
import EntityDisplay from "@/components/shared/EntityDisplay.vue";
import BudgetDisplay from "@/components/PurchaseRequest/SummarySections/BudgetDisplay";

export default {
  components: {
    BudgetDisplay,
    Button,
    CashIcon,
    ThumbUpIcon,
    // eslint-disable-next-line vue/no-unused-components
    ThumbDownIcon,
    XIcon,
    Multiselect,
    CheckCircleIcon,
    XCircleIcon,
    EntityDisplay,
  },
  props: {
    budgetLevel: {
      type: String,
      default: "",
    },
    hidden: {
      type: Boolean,
      default: true,
    },
    actionRequired: {
      type: Boolean,
      default: false,
    },
    budget: {
      type: Object,
      default: null,
    },
    isInBudget: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: null,
    },
    totalNetAmount: {
      type: [String, Number],
      default: "0",
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    onBudgetUpdate: {
      type: Function,
      default: () => {},
    },
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDetails: false,
      budgetLevelToUpdate: "",
      isBudgetLevelEditable: false,
    };
  },
  computed: {
    budgetValue() {
      return (
        DEFAULT_BUDGET_MAPPING[this.budgetLevel] ||
        DEFAULT_BUDGET_MAPPING.COMPANY
      );
    },
    showBar() {
      return (
        parseInt(this.budget.remainingBudget) > 0 &&
        parseInt(this.budget.amount) > 0
      );
    },

    isAllowedToSeeDetails() {
      const allowedRoles = ["MD", "AC", "O"];
      const userRoles = getItemFromLocalStorage("user").roles;

      return (
        ![
          this.purchaseRequest.requestStatus,
          this.purchaseRequest.status,
        ].includes("R") &&
        (this.actionRequired ||
          userRoles.some((role) => allowedRoles.includes(role)))
      );
    },
    total() {
      return parseFloat(this.budget.amount);
    },
    used() {
      return parseFloat(this.budget.usedBudget);
    },
    current() {
      return parseFloat(this.totalNetAmount);
    },
    pending() {
      const pending = parseFloat(this.budget.pendingBudget) - this.current;
      if (pending < 0) {
        return 0;
      }
      return pending;
    },
    bar() {
      const bar = {
        total: this.total,
        used: this.used,
        current: this.current,
        pending: this.pending,
      };
      return bar;
    },
  },
  methods: {
    currencyToLocale,

    calcBar() {
      const total = parseFloat(this.budget.amount);
      const used = parseFloat(this.budget.usedBudget);
      const current = parseFloat(this.totalNetAmount);
      const remaining = parseFloat(this.budget.remainingBudget);
      let pending = parseFloat(this.budget.pendingBudget) - current;
      if (pending < 0) {
        pending = 0;
      }
      const bar = {};
      bar.pending = (pending / total) * 100;
      bar.used = (used / total) * 100;
      bar.remaining = (remaining / total) * 100;
      bar.current = (current / total) * 100;
      return bar;
    },
    budgetOptionsToUpdate() {
      const filteredOptions = removeKeysFromObject(DEFAULT_BUDGET_MAPPING, [
        "U",
      ]);
      return Object.values(filteredOptions);
    },
    onCancelEditBudgetLevel() {
      this.budgetLevelToUpdate = this.purchaseRequest.budgetLevel;
      this.isBudgetLevelEditable = false;
    },
    onEditBudgetLevel() {
      this.budgetLevelToUpdate =
        DEFAULT_BUDGET_MAPPING[this.purchaseRequest.budgetLevel];
      this.isBudgetLevelEditable = true;
    },
    onUpdate() {
      const payload = {
        purchaseRequestId: this.purchaseRequest.id,
        payload: {
          budgetLevel: getKeyByValue(
            DEFAULT_BUDGET_MAPPING,
            this.budgetLevelToUpdate
          ),
        },
      };
      this.onBudgetUpdate(payload);
      this.isBudgetLevelEditable = false;
    },
  },
};
</script>

<style scoped>
.progress {
  height: 22px;
  overflow: hidden;
  @apply bg-hivebuy-green bg-opacity-10;
  @apply rounded-md ring-gray-400;

  -webkit-box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
}
.progress-bar {
  @apply font-normal;
  float: left;
  width: 0%;
  height: 100%;
  line-height: 16px;
  color: #fff;
  @apply text-xs;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar-pending {
  @apply bg-hivebuy-yellow;
}
.progress-bar-approved {
  @apply bg-hivebuy-red;
}
.progress-bar-remaining {
  @apply bg-gray-400;
}

.wrapper {
  @apply col-span-1 flex shadow-sm rounded-md mb-10;
}
.icon-holder {
  @apply bg-gray-400 flex-shrink-0 flex items-center justify-center w-16 h-16 text-white text-sm font-medium rounded-l-md;
}

.content-holder {
  @apply flex-1 flex items-center justify-between border-t border-r border-b border-gray-300 bg-white rounded-r-md flex-1 px-4 py-2 text-sm font-medium;
}

.product-budget .product-budget {
  @apply mt-3 w-full;
}

.product-budget .product-budget .mt-8 {
  @apply mt-0;
}

.product-budget .budget {
  @apply w-full;
}

.product-budget .budget .product-budget .wrapper {
  @apply mb-0;
}

.product-budget .budget .product-budget .wrapper .icon-holder {
  @apply w-12 h-12;
}

.product-budget .budget .product-budget .wrapper .icon-holder svg {
  @apply w-6 h-6;
}

.product-budget .budget .product-budget .wrapper .content-holder {
  @apply h-12 py-0;
}
</style>
