import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_SUPPLIERS,
} from "@/store/CompanyManagement/types";

export default {
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "integratedSuppliersList",
      "companySuppliersList",
    ]),
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompanySuppliers: GET_COMPANY_SUPPLIERS,
    }),
    fetchCompanySuppliers(params = {}) {
      this.setApiLoading({ key: "companySuppliers", value: true });
      this.getCompanySuppliers(params).catch((error) => this.showErrorMessage(error)).finally(() => {
        this.setApiLoading({ key: "companySuppliers", value: false });
      });
    },
  },
};
