<template>
  <div class="inline-block">
    <div :id="uniqueId" ref="wrapper" class="edit_wrapper group">
      <div v-if="!enableEdit" class="edit_div" @click="allowEdit">
        <i18n-n
          v-if="isCurrency"
          :value="parseFloat(value)"
          :format="currencyFormat"
          :locale="locale"
        />
        <span v-else>{{ value }}</span>
      </div>
      <CurrencyInput
        v-else-if="isCurrency"
        :id="id"
        v-model.lazy="value"
        placeholder="0"
        :dense="dense"
        :options="{
          locale: locale,
          currency: currency,
        }"
        @change="changeHandler"
        @blur="blurHandler"
      />
      <input
        v-else
        :id="id"
        ref="editinput"
        v-model="value"
        v-focus
        class="edit_input"
        @blur="blurHandler"
        @keyup="keyUpHandler"
        @change="changeHandler"
      />
      <PencilIcon
        v-if="!enableEdit && !loading && showEditIcon"
        class="edit_icon"
        @click.stop="allowEdit"
      />
      <div
        v-if="showIcons && enableEdit"
        class="flex items-center gap-x-0.5 absolute right-1 z-20 bg-white"
      >
        <CheckCircleIcon class="confirm_icon" @click.stop="confirmHandler" />
        <XCircleIcon class="cancel_icon" @click="cancelHandler" />
      </div>
      <SpinningLoaderSVG v-if="loading" class="h-5 w-5 text-primary" />
    </div>
  </div>
</template>

<script>
import { PencilIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/vue/solid";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import { CurrencyInput } from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
import { currencyToLocale } from "@/utils/utility_methods.js";

export default {
  components: {
    PencilIcon,
    CheckCircleIcon,
    XCircleIcon,
    SpinningLoaderSVG,
    CurrencyInput,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    onConfirmHandler: {
      type: Function,
      default: () => {},
    },
    onCancelHandler: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    showEditIcon: {
      type: Boolean,
      default: true,
    },
    showIcons: {
      type: Boolean,
      default: true,
    },
    updateOnKeyUp: {
      type: Boolean,
      default: false,
    },
    updateOnChange: {
      type: Boolean,
      default: false,
    },
    updateOnBlur: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: "EUR",
    },
    currencyFormat: {
      type: String,
      default: "currency",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    showSuccessAnimation: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["update:modelValue"],
  data() {
    return {
      enableEdit: false,
      oldValue: "",
      value: this.modelValue,
      uniqueId: null,
    };
  },

  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    locale() {
      if (this.currency) {
        return this.currencyToLocale(this.currency);
      }
      return this.currencyToLocale(this.company.currency);
    },
    isCurrency() {
      return this.type === "currency";
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.value = value;
      },
    },
  },
  mounted() {
    this.uniqueId = this.$.uid;
  },
  methods: {
    allowEdit() {
      if (!this.loading) {
        this.oldValue = this.value;
        this.enableEdit = true;
      }
    },
    confirmHandler() {
      this.$emit("update:modelValue", this.value);
      if (this.showSuccessAnimation) {
        const el = document.getElementById(this.uniqueId);
        // necessary to restart the animation
        el.classList.remove("success_anim");
        el.offsetHeight;
        el.classList.add("success_anim");
      }
      this.enableEdit = false;
      this.onConfirmHandler(this.value);
    },

    cancelHandler() {
      this.enableEdit = false;
      this.value = this.oldValue;
      this.onCancelHandler();
    },

    keyUpHandler() {
      if (this.updateOnKeyUp) {
        this.$emit("update:modelValue", this.value);
      }
    },

    changeHandler() {
      if (this.updateOnChange) {
        this.confirmHandler();
      }
    },

    blurHandler() {
      if (this.updateOnBlur) {
        this.confirmHandler();
      }
    },
    currencyToLocale,
  },
};
</script>

<style>
@keyframes success {
  from {
    @apply bg-hivebuy-green/30;
  }
  to {
    @apply bg-transparent;
  }
}

.success_anim {
  animation-name: success;
  animation-duration: 2s;
}

.edit_div {
  @apply py-0.5 px-2 mr-1 group-hover:bg-gray-100 hover:ring-1 hover:ring-gray-200 rounded block w-full;
}

.edit_wrapper {
  @apply flex items-center relative rounded;
}

.edit_input {
  @apply py-0.5 pl-2 pr-16 rounded border-none placeholder-gray-400 block w-full;
  @apply focus:outline-none focus:ring-primary focus:ring-2 focus:bg-white;
  @apply ring-1 ring-gray-200 bg-gray-50 hover:bg-white;
}

.edit_icon {
  @apply h-5 w-5 hidden group-hover:block text-primary;
  @apply absolute -right-5;
}

.confirm_icon {
  @apply h-5 w-5 cursor-pointer text-hivebuy-green;
}

.cancel_icon {
  @apply h-5 w-5 cursor-pointer text-hivebuy-red;
}
</style>
