import { ChevronDownIcon } from "@heroicons/vue/solid";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG";

export default {
  components: {
    ChevronDownIcon,
    SpinningLoaderSVG,
  },
  data() {
    return {
      loadMoreBtnLoading: false,
      areFiltersUpdated: false,
    };
  },
  methods: {
    onSearchInput(key, value) {
      this.updateFilters(key, value, "paginationParams");
    },
    loadMore() {
      this.loadMoreBtnLoading = true;
      const pageValue = this.paginationParams.page + 1;
      this.updateFilters("page", pageValue, "paginationParams");
    },
  },
};
