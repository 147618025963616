<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="1px"
    y="1px"
    viewBox="-1 0 24 24"
    enable-background="new 0 0 22.9678 23.4873"
    xml:space="preserve"
    class="fill-current"
  >
    <g id="logistic-cart">
      <path
        fill="current"
        d="M22.3076,14.3174l-9.0234,3.1479c0.5293,0.4546,0.9336,1.0386,1.1699,1.7105l8.5137-2.9702L22.3076,14.3174
		z"
      />
      <path
        fill="current"
        d="M4.4346,2.5478l4.9453,14.1788c0.4141-0.1451,0.8506-0.2393,1.3125-0.2393
		c0.2568,0,0.5059,0.0293,0.75,0.0757L5.6651,0L4.7207,0.3296L0,1.9766l0.6582,1.8881L4.4346,2.5478z"
      />
      <path
        fill="current"
        d="M7.6924,20.4873c0,1.6572,1.3438,3,3,3c1.6572,0,3-1.3428,3-3c0-1.6572-1.3428-3-3-3
		C9.0362,17.4873,7.6924,18.8301,7.6924,20.4873z M10.9365,19.5366c0.0567,0.0142,0.1153,0.02,0.168,0.0445
		c0.0567,0.0253,0.1025,0.0673,0.1543,0.104c0.0527,0.0376,0.1094,0.0688,0.1543,0.1157c0.041,0.0429,0.0664,0.0967,0.0996,0.146
		c0.0391,0.0591,0.0811,0.1147,0.1074,0.1816c0.0039,0.0112,0.0137,0.0186,0.0176,0.0298c0.0205,0.0596,0.0156,0.1191,0.0244,0.1792
		c0.0078,0.0513,0.0303,0.0967,0.0303,0.1499c0,0.0103-0.0059,0.0186-0.0059,0.0288c-0.0019,0.0786-0.0253,0.1509-0.0449,0.2256
		c-0.0146,0.0522-0.0195,0.1069-0.041,0.1558c-0.0273,0.0595-0.0713,0.1084-0.1094,0.1621
		c-0.0371,0.0507-0.0664,0.1054-0.1113,0.1484c-0.042,0.0396-0.0957,0.0645-0.1436,0.0967
		c-0.0615,0.0405-0.1181,0.084-0.1875,0.1103c-0.0107,0.0039-0.0166,0.0127-0.0273,0.0166
		c-0.0547,0.0191-0.1103,0.0137-0.165,0.0225c-0.0557,0.0098-0.1055,0.0332-0.1641,0.0332c-0.0117,0-0.0205-0.0059-0.0312-0.0063
		c-0.0743-0.0025-0.1426-0.0249-0.2129-0.043c-0.0567-0.0147-0.1153-0.02-0.167-0.0444c-0.0576-0.0254-0.1035-0.0679-0.1553-0.1041
		c-0.0527-0.0376-0.1094-0.0688-0.1543-0.1157c-0.041-0.0425-0.0664-0.0967-0.0996-0.1465
		c-0.0391-0.0586-0.0811-0.1142-0.1074-0.1811c-0.0039-0.0112-0.0137-0.0181-0.0176-0.0298
		c-0.0195-0.0576-0.0137-0.1157-0.0234-0.1738c-0.0079-0.0528-0.0313-0.1001-0.0313-0.1553c0-0.0107,0.0059-0.02,0.0059-0.0312
		c0.0029-0.0733,0.0254-0.1407,0.0429-0.211c0.0147-0.0571,0.0215-0.1167,0.045-0.1694c0.0253-0.0562,0.0664-0.1011,0.1015-0.1519
		c0.0391-0.0542,0.0703-0.1123,0.1192-0.1582c0.04-0.0381,0.0918-0.062,0.1386-0.0937c0.0625-0.0415,0.1192-0.0855,0.1905-0.1123
		c0.0107-0.0039,0.0166-0.0127,0.0273-0.0166c0.0547-0.0191,0.1104-0.0137,0.166-0.0225c0.0557-0.0098,0.1045-0.0332,0.1631-0.0332
		c0.0117,0,0.0205,0.0059,0.0313,0.0063C10.7979,19.4961,10.8662,19.5186,10.9365,19.5366z"
      />

      <rect
        x="10.9971"
        y="7.5723"
        transform="matrix(0.9442 -0.3293 0.3293 0.9442 -2.9746 5.5846)"
        fill="current"
        width="7.9994"
        height="8.0001"
      />

      <rect
        x="8.8025"
        y="3.6216"
        transform="matrix(0.9441 -0.3296 0.3296 0.9441 -1.2492 3.8743)"
        fill="current"
        width="3.9996"
        height="4.0003"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "PoSVG",
};
</script>
