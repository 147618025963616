import * as AuthTypes from "./types.js";
import {
  removeItemInLocalStorage,
  removeKeysFromObject,
  setItemInLocalStorage,
  updateOrCreate,
} from "@/utils/utility_methods";
import * as Sentry from "@sentry/vue";

export default {
  [AuthTypes.SET_USER]: (state, payload) => {
    const payloadWithoutCompany = removeKeysFromObject(payload, ["company"]);

    state.user = payloadWithoutCompany;
    setItemInLocalStorage("user", state.user);
    if (state.user && state.user.name) {
      const { id, email, name } = state.user;

      Sentry.setUser({
        id: String(id),
        username: name,
        email: email,
      });
    }
  },
  [AuthTypes.UPDATE_CURRENT_USER]: (state, payload) => {
    state.user = Object.assign({}, state.user, payload);
    setItemInLocalStorage("user", state.user);
  },

  [AuthTypes.REMOVE_USER]: (state) => {
    removeItemInLocalStorage("user");
    state.user = {};
  },

  [AuthTypes.SET_USER_NOTIFICATION_SETTINGS]: (state, payload) => {
    state.userNotificationSettings = payload;
  },

  [AuthTypes.SET_USER_NOTIFICATION_SETTING]: (state, payload) => {
    state.userNotificationSettings = updateOrCreate(
      state.userNotificationSettings,
      payload
    );
  },
};
