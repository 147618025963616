import store from "@/store/index.js";
import { DOWNLOAD_MODULE, SET_DOWNLOAD_STATUS } from "@/store/Downloads/types";

export const poCsvReady = (data) => {
  store.commit(`${DOWNLOAD_MODULE}/${SET_DOWNLOAD_STATUS}`, {
    storeState: "csvOrders",
    downloading: false,
    value: data.download.id,
  });
};
