<template>
  <Popover
    v-click-outside="() => (showPopover = false)"
    :placement="'auto'"
    :popover-classes="popoverClasses"
    :show="showPopover"
    :open-popover="
      () => {
        showPopover = true;
      }
    "
  >
    <template #header>
      <div class="px-4 max-w-[30vw]">
        <div class="flex justify-center">
          <div class="modal-heading">
            {{ headerText }}
          </div>
        </div>
        <div>
          <div class="relative mx-auto text-gray-600">
            <input
              v-model="search"
              v-focus
              class="border-1 border-gray-300 bg-white h-10 px-5 rounded text-sm focus:outline-none focus:ring-primary focus:border-primary w-full"
              type="search"
              name="search"
              :placeholder="$t('global.search')"
            />
            <button
              v-if="!search"
              type="button"
              class="absolute right-0 top-0 mt-3 mr-4 focus:outline-none"
            >
              <SearchIcon class="text-gray-400 hover:text-gray-500 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="max-w-[30vw]">
        <div v-if="infoText" class="text-xs px-6 pt-4">
          <div class="p-2 rounded bg-gray-100">
            {{ infoText }}
          </div>
        </div>
        <ul class="pt-2 px-4 divide-y divide-gray-200">
          <li
            v-for="user in filteredUsers"
            :key="user.id"
            class="hover:bg-gray-50 cursor-pointer"
          >
            <div
              class="flex items-center p-2"
              @click="onUserSelectionStatusUpdate(user)"
            >
              <UserWithAvatar
                :user="user"
                :show-name="true"
                :avatar-dimension="8"
              />
              <div v-if="selectedUsers.includes(user.id)" class="ml-auto">
                <CheckIcon class="w-5 text-primary" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <div class="px-4 my-4">
        <button
          type="button"
          class="confirm-button"
          :disabled="noUsersSelected"
          @click="onSave"
        >
          {{ submitBtnText }}
        </button>
      </div>
    </template>
  </Popover>
</template>
<script>
import { CheckIcon, SearchIcon } from "@heroicons/vue/solid";
import { userMixin } from "@/components/mixins/index.js";
import { isEmptyString, isObjectEmpty } from "@/utils/utility_methods";
import { UserWithAvatar, Popover } from "@/components/shared/index";

export default {
  components: {
    Popover,
    CheckIcon,
    SearchIcon,
    UserWithAvatar,
  },
  mixins: [userMixin],
  props: {
    users: {
      type: Array,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    submitBtnText: {
      type: String,
      required: true,
    },
    popoverClasses: {
      type: Array,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
    selectedMembers: {
      type: Array,
      default: () => [],
    },
    infoText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      selectedUsers: [],
      showPopover: false,
    };
  },
  computed: {
    filteredUsers() {
      if (isEmptyString(this.search)) {
        return this.users;
      }

      const searchQuery = this.search.toLowerCase();
      return this.users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchQuery) ||
          user.lastName.toLowerCase().includes(searchQuery)
      );
    },
    noUsersSelected() {
      return isObjectEmpty(this.selectedUsers);
    },
  },
  watch: {
    selectedMembers: {
      immediate: true,
      handler(newVal) {
        this.selectedUsers = newVal;
      },
    },
  },
  methods: {
    onSave() {
      if (!this.noUsersSelected) {
        this.onSubmit(this.selectedUsers);
        this.resetValues();
      }
      this.showPopover = false;
    },
    resetValues() {
      this.selectedUsers = [];
      this.search = "";
    },
    onUserSelectionStatusUpdate(user) {
      if (!this.selectedUsers.includes(user.id)) {
        this.selectedUsers.push(user.id);
      } else {
        this.selectedUsers = this.selectedUsers.filter((u) => u != user.id);
      }
    },
  },
};
</script>

<style scoped>
input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.confirm-button {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primarydark focus:outline-none sm:mt-0 cursor-pointer sm:col-start-2 sm:text-sm disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed transition duration-500;
}
</style>
