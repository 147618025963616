<template>
  <div v-if="noDisplay">
    <div class="label">{{ $tc("global.paymentMethod", 2) }}</div>
    <Multiselect
      ref="paymentSelect"
      v-model="selectorValue"
      class="w-full bg-white"
      :mode="mode"
      :label="label"
      :track-by="'title'"
      :value-prop="'id'"
      :object="objectType"
      :options="optionsList"
      :placeholder="$t('purchaseRequest.detail.selectPaymentMethod')"
      :can-clear="canClear"
      :searchable="true"
      :can-deselect="false"
    >
      <template #tag="{ option, handleTagRemove, disabled }">
        <div
          :class="[
            'multiselect-tag tag-section',
            { 'is-disabled': disabled },
            option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
          ]"
        >
          <span v-if="addDefault" class="multiselect-tag-remove">
            <UndefaultIcon
              v-if="option.isDefault"
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
            <DefaultIcon
              v-else
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
          </span>
          {{ option.title }}
          <span
            v-if="!disabled"
            class="multiselect-tag-remove"
            @mousedown.prevent="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon" />
          </span>
        </div>
      </template>
    </Multiselect>
  </div>
  <div v-else class="section bg-white">
    <dt class="section-title">
      {{ $tc("global.paymentMethod", list.length) }}
    </dt>
    <dt>
      <HivebuyInlineEdit ref="payment" :allow-edit="isEdit">
        <template #header>
          {{ getPaymentMethodDetails?.[0]?.title }}
          <p v-if="getPaymentMethodDetails?.isDefault && !loading">(Default)</p>
        </template>
        <template #subtitle>
          <HivebuyLoader v-if="loading" class="h-12 w-12" />
          <dd
            v-if="getPaymentMethodDetails?.length === 1"
            class="text-gray-500 text-sm"
          >
            <p>
              {{ getPaymentMethodDetails[0].description }}
            </p>
          </dd>
          <dd v-else class="flex">
            <p class="mr-1">
              {{
                $t("tooltip.squashedText.paymentMethod", {
                  count: list.length || modelValue.length,
                })
              }}
            </p>
            <Tip help theme="tooltip">
              <template #header>
                <div class="flex items-center truncate">
                  {{ $tc("global.paymentMethod", 2) }}
                </div>
              </template>
              <template #content>
                <ul class="list-inside list-decimal">
                  <li
                    v-for="(paymentMethod, index) in getPaymentMethodDetails"
                    :key="index"
                    class="tooltip-subtext text-gray-700 font-medium"
                  >
                    {{
                      paymentMethod?.title ||
                      $t(
                        "purchaseRequest.summary.deliveryPaymentSection.configuredViaSupplier"
                      )
                    }}
                  </li>
                </ul>
              </template>
            </Tip>
          </dd>
        </template>
        <template #editSection>
          <Multiselect
            ref="paymentSelect"
            v-model="selectorValue"
            class="w-full bg-white"
            :mode="mode"
            :label="label"
            :track-by="'title'"
            :value-prop="'id'"
            :object="objectType"
            :options="optionsList"
            :placeholder="$t('purchaseRequest.detail.selectPaymentMethod')"
            :can-clear="canClear"
            :searchable="true"
            :can-deselect="false"
          >
            <template #tag="{ option, handleTagRemove, disabled }">
              <div
                :class="[
                  'multiselect-tag tag-section',
                  { 'is-disabled': disabled },
                  option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
                ]"
              >
                <span v-if="addDefault" class="multiselect-tag-remove">
                  <UndefaultIcon
                    v-if="option.isDefault"
                    class="w-4 h-4 text-white flex-none"
                    @mousedown.prevent="onMarkAsDefault(option)"
                  />
                  <DefaultIcon
                    v-else
                    class="w-4 h-4 text-white flex-none"
                    @mousedown.prevent="onMarkAsDefault(option)"
                  />
                </span>
                {{ option.title }}
                <span
                  v-if="!disabled"
                  class="multiselect-tag-remove"
                  @mousedown.prevent="handleTagRemove(option, $event)"
                >
                  <span class="multiselect-tag-remove-icon" />
                </span>
              </div>
            </template>
          </Multiselect>
          <div v-if="showSaveButtons" class="flex items-center ml-1">
            <CheckCircleIcon class="confirm_icon" @click.stop="onConfirm" />
            <XCircleIcon class="cancel_icon" @click.stop="onCancel" />
          </div>
          <div class="flex items-center my-2">
            <HivebuyTooltip v-if="addDefault" :open-delay="50">
              <QuestionMarkCircleIcon class="w-4 h-4 text-primary" />
              <template #content>
                <div class="p-2">
                  <i18n-t
                    keypath="legalTendency.legalTendencyModal.tooltipDefault"
                    tag="span"
                    class="text-base"
                  >
                    <template #icon>
                      <span class="inline-block">
                        <DefaultIcon class="w-4 h-4 text-primary" />
                      </span>
                    </template>
                    <template #object>
                      {{ $t("purchaseRequest.detail.paymentMethod") }}
                    </template>
                  </i18n-t>
                </div>
              </template>
            </HivebuyTooltip>
            <FieldError v-if="isInvalid" :text="errorMessage" />
          </div>
        </template>
      </HivebuyInlineEdit>
    </dt>
  </div>
</template>

<script>
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_PAYMENT_METHOD,
} from "@/store/CompanyManagement/types";
import { mapActions, mapGetters, mapState } from "vuex";
import storeValueGetterMixin from "@/components/mixins/storeValueGetterMixin";
import Multiselect from "@vueform/multiselect";
import {
  BookmarkIcon as UndefaultIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
import { BookmarkIcon as DefaultIcon } from "@heroicons/vue/outline";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import FieldError from "@/components/shared/FieldError.vue";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { HivebuyInlineEdit } from "design-buy";
import HivebuyLoader from "@/components/shared/HivebuyLoader.vue";
export default {
  components: {
    QuestionMarkCircleIcon,
    HivebuyTooltip,
    Multiselect,
    DefaultIcon,
    UndefaultIcon,
    FieldError,
    HivebuyInlineEdit,
    HivebuyLoader,
  },
  mixins: [storeValueGetterMixin],
  props: {
    noDisplay: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Object, String, Array],
      default: "",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "",
    },
    objectType: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    requestedForId: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    addDefault: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "title",
    },
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSaveButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      tempValue: this.modelValue,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["paymentMethods"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList"]),
    isMultiSelect() {
      return this.mode !== "single";
    },
    getPaymentMethodDetails() {
      if (!this.paymentList || (!this.modelValue && !this.list.length)) return;
      return this.extractPaymentDetails();
    },
    selectorValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (!this.modelValue) {
          this.emitModelValue(value);
          return;
        }
        if (this.showSaveButtons) {
          this.tempValue = value;
        }
        this.emitModelValue(value);
        if (!this.noDisplay) this.$refs.payment.toggle();
      },
    },
    errorMessage() {
      if (this.errorText) return this.errorText;
      return this.$t(
        "catalogue.myCart.purchaseRequestInfoModal.paymentMethodError"
      );
    },
    optionsList() {
      return this.addDefault
        ? this.paymentList.map((item) => {
            return {
              ...item,
              isDefault: false,
            };
          })
        : this.paymentList;
    },
  },
  watch: {
    requestedForId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          this.requestedForValues[newVal] &&
          ((oldVal && newVal !== oldVal) || !this.selectorValue)
        ) {
          this.setPaymentMethod(this.requestedForValues[newVal].defaultValues);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchPaymentMethod: GET_PAYMENT_METHOD,
    }),
    emitModelValue(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
    onConfirm() {
      if (this.showSaveButtons) this.$refs.payment.toggle();
      if (!this.tempValue) {
        this.showNotification("value not changed", "warning");
        return;
      }
      this.emitModelValue(this.tempValue);
    },
    onCancel() {
      if (this.showSaveButtons) this.$refs["payment"].toggle();
      this.$refs["paymentSelect"].select(this.selectorValue);
    },

    extractPaymentDetails() {
      if (this.modelValue && !this.isMultiSelect) {
        const id = this.modelValue.id || this.modelValue;
        const paymentMethodDetails = this.valueFromStore(id);
        !paymentMethodDetails && this.fetchPaymentMethod(id);
        return [paymentMethodDetails];
      } else if (this.list.length || this.modelValue.length) {
        const obj = this.list.length ? this.list : this.modelValue;
        return obj.map((item) =>
          this.valueFromStore(item.paymentMethod || item.id || item)
        );
      }
      return null;
    },
    valueFromStore(value) {
      return this.getStoreValue(
        COMPANY_MANAGEMENT_MODULE,
        "paymentMethods",
        value
      );
    },
    setPaymentMethod(data) {
      this.selectorValue = this.objectType
        ? this.paymentMethods[data.paymentMethod]
        : data.paymentMethod;
    },
    onMarkAsDefault(option) {
      this.selectorValue.forEach((item) => {
        if (item.id === option.id) {
          item.isDefault = !item.isDefault;
          const message = item.isDefault
            ? `${item.title} ${this.$t("home.markAsDefault")}`
            : `${item.title} ${this.$t("home.markAsUnDefault")}`;

          this.showNotification(message);
        } else {
          item.isDefault = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.multiselect-tag.tag-section i:before {
  @apply text-white rounded-full;
}
.section-title {
  @apply text-base mb-1;
}

.custom-card {
  @apply p-0;
}

:deep(.section) {
  @apply px-3 flex-1 last:border-r-0 pt-0;
}

.multiselect-tag.tag-section i:before {
  @apply text-white rounded-full;
}
</style>
