<template>
  <div>
    <Popper :placement="placement" :show="show">
      <button
        :class="[
          'inline-flex items-center py-2 text-base font-medium text-white  rounded-md group hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
          { 'text-opacity-90': open },
        ]"
        @click="openPopover"
      >
        <div
          class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-white cursor-pointer border-gray-200 border hover:bg-primary hover:border-white text-primary hover:text-white"
        >
          <PlusIcon class="w-6" />
        </div>
      </button>
      <template v-if="show" #content>
        <div class="popover">
          <div class="popover-content bg-white">
            <div class="popover-header">
              <slot name="header" />
            </div>
            <div class="popover-body">
              <slot name="body" />
            </div>
            <div class="popover-footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";

export default {
  components: { PlusIcon },
  props: {
    placement: { type: String, default: "right" },
    openPopover: {
      type: Function,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    popoverClasses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style scoped>
.popover .popover-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  min-width: 320px;
  min-height: 15rem;
  max-height: 28rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 250;
  margin: 0.75rem 0;
}
.popover-content .popover-body {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
}
</style>
