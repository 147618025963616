<template>
  <div class="flex min-h-full bg-white pb-8 pt-12">
    <main
      class="flex w-full max-w-7xl flex-grow flex-col justify-start px-4 sm:px-6 lg:px-8"
    >
      <div class="flex flex-shrink-0 justify-center">
        <a href="/" class="inline-flex">
          <span class="sr-only">{{ $t("global.errorPages.notFound") }}</span>
          <img
            src="https://hivebuy.com/wp-content/uploads/2021/12/hivebuy-benefits-approval-workflow.svg"
            alt=""
            class="h-[280px]"
          />
        </a>
      </div>
      <div class="mt-8">
        <div class="text-center">
          <h1
            class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
          >
            {{ $t("global.errorPages.notFound") }}
          </h1>
          <p class="mt-2 text-base text-gray-500">
            {{ $t("global.errorPages.notFoundDetails") }}
          </p>
          <div class="mt-6">
            <Button :on-click-handler="goToDashboard">
              {{ $t("global.errorPages.goToDashboard") }}
              <span class="ml-2" aria-hidden="true"> &rarr;</span></Button
            >
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Button } from "@/components/shared/index.js";
export default {
  components: { Button },
  methods: {
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>
