<template>
  <div id="user-management">
    <div class="flex items-center justify-between">
      <h2 class="page-title">{{ $t("companyManagement.users.heading") }}</h2>
      <div class="flex items-center">
        <BarButton
          :on-click-handler="
            () => {
              setDisplayList(true);
            }
          "
          btn-group="left"
          :active="activeUsers && !showPendingUsers"
        >
          <span class="uppercase">{{ $t("global.prepositions.active") }}</span>
        </BarButton>
        <BarButton
          :on-click-handler="
            () => {
              setDisplayList(false);
            }
          "
          btn-group="right"
          :active="!activeUsers && !showPendingUsers"
          class="mr-2"
        >
          <span class="uppercase">{{
            $t("global.prepositions.inactive")
          }}</span>
        </BarButton>
        <BarButton
          :on-click-handler="
            () => {
              displayPendingUsers();
            }
          "
          btn-group="solo"
          :active="showPendingUsers"
        >
          <span class="uppercase">{{ $t("statuses.request.P") }}</span>
        </BarButton>
      </div>
      <div class="flex items-center">
        <div v-if="isUserAdmin" class="flex -mt-2 items-center">
          <HivebuyTooltip :open-delay="50" :disabled="!isUserLimitReached">
            <button
              type="button"
              :class="[
                isUserLimitReached
                  ? 'text-gray-400'
                  : 'text-primary hover:text-primarydark',
                'flex font-semibold items-center mr-4 -mt-1',
              ]"
              @click="toggleAddUserModal"
            >
              <PlusCircleIcon class="mr-1 h-5 w-5" aria-hidden="true" />
              {{ $t("companyManagement.users.addUser") }}
            </button>
            <template #content
              ><div
                class="p-2 text-sm font-medium whitespace-pre-line max-w-xs"
              >
                <p class="mb-2 font-medium">
                  You have reached your user limit.
                </p>
                Contact our
                <a
                  class="text-primary font-normal"
                  href="mailto:sales@hivebuy.de"
                  >Sales Team</a
                >
                to enable more users.
              </div></template
            >
          </HivebuyTooltip>
        </div>
        <CSVUpload type="user" :show-import="false" />
      </div>
    </div>

    <PendingUsersList v-if="showPendingUsers" />
    <UsersList v-else :search="search" :active="activeUsers" />

    <AddUserModal :show-modal="showAddUserModal" :close-modal="onModalClose" />
  </div>
</template>

<script>
import { PlusCircleIcon } from "@heroicons/vue/solid";
import UsersList from "@/components/CompanyManagement/Users/UsersList";
import AddUserModal from "@/components/CompanyManagement/Users/AddUserModal";
import PendingUsersList from "@/components/CompanyManagement/Users/PendingUsersList";
import { isEmptyString } from "@/utils/utility_methods";
import { mapGetters, mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import { AUTH_MODULE } from "@/store/Auth/types";
import BarButton from "@/components/shared/BarButton.vue";
import { CSVUpload } from "@/components/shared/index";
export default {
  components: {
    AddUserModal,
    UsersList,
    PlusCircleIcon,
    PendingUsersList,
    HivebuyTooltip,
    BarButton,
    CSVUpload,
  },
  data() {
    return {
      showPendingUsers: false,
      showAddUserModal: false,
      activeUsers: true,
      search: "",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(AUTH_MODULE, ["isUserAdmin"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getAmountMembers"]),
    switchCheckboxLabel() {
      return `${
        this.showPendingUsers
          ? this.$t("companyManagement.users.pendingToggleOff")
          : this.$t("companyManagement.users.pendingToggleOn")
      }`;
    },
    isUserLimitReached() {
      return this.getAmountMembers() > this.getfeatFlagAmount("numberOfUsers");
    },
  },
  methods: {
    onAttributeChange(event) {
      this.search = event.target.value;
    },
    onModalClose() {
      this.showAddUserModal = false;
    },
    resetSearch() {
      if (this.showPendingUsers && !isEmptyString(this.search)) {
        this.search = "";
      }
    },
    toggleAddUserModal() {
      if (!this.isUserLimitReached) {
        this.showAddUserModal = true;
      }
    },
    displayPendingUsers() {
      this.showPendingUsers = true;
      this.activeUsers = null;
    },

    setDisplayList(status) {
      this.showPendingUsers = false;
      this.activeUsers = status;
    },
  },
};
</script>

<style scoped></style>
