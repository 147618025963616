export default {
  downloadStatus: (state) => (downloadState) => {
    return state[downloadState];
  },
  downloadedFiles: (state) => () => {
    return Object.keys(state)
      .filter((fileType) => state[fileType].value)
      .map((key) => Object.assign({}, state[key], { model: key }));
  },
  fileDownloading: (state) => () => {
    return Object.keys(state).filter((fileType) => state[fileType].downloading)
      .length;
  },
  fileUploading: (state) => () => {
    const uploads = state.uploads;
    return Object.keys(uploads).filter(
      (fileType) => state.uploads[fileType].inProgress
    ).length;
  },

  downloadStatusModel: (state) => (model) => {
    return state[model];
  },

  exportStatusesForArray: (state) => (models) => {
    return Object.keys(state)
      .filter((fileType) => models.includes(fileType))
      .map((key) => Object.assign({}, state[key], { model: key }));
  },
};
