<template>
  <div
    class="bg-white border border-hivebuy_plum p-5 rounded-md w-full text-base mb-4 no-shadow"
  >
    <div class="flex flex-col lg:flex-row space-x-4">
      <div class="flex items-center text-sm space-x-2">
        <span class="p-1 rounded-lg bg-hivebuy_plum">
          <ExclamationCircleIcon
            class="h-10 w-10 text-white"
            aria-hidden="true"
          />
        </span>
        <p class="my-4 text-base font-normal p-2">
          A new invoice was assigned to this order. Please review.
        </p>
      </div>
      <div class="flex flex-1 justify-around lg:justify-end">
        <div class="flex justify-around items-center">
          <Button id="approve-button" :on-click-handler="changePage">
            <template #icon><ThumbUpIcon /></template>
            Decide
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ThumbUpIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import Button from "@/components/shared/Button.vue";
export default {
  components: { ThumbUpIcon, Button, ExclamationCircleIcon },
  props: {
    poId: { type: String, required: true },
  },
  methods: {
    changePage() {
      this.$router.push({
        name: "InvoiceDecision",
        params: { poId: this.poId },
      });
    },
  },
};
</script>
