export default {
  methods: {
    isApprovalAndBudgetPending(object) {
      if (!object.isItemLevelApprover) {
        return !(object?.instantApproval || object?.approvals?.length);
      }

      return object.items.some(
        (item) => !(item?.instantApproval || item?.approvals?.length)
      );
    },
  },
};
