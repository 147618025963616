<template>
  <div class="bg-hivebuy_plum/20 mb-10">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-hivebuy_darkplum text-white">
            <SpeakerphoneIcon class="h-7 w-7" />
          </span>
          <p class="ml-3 font-medium text-hivebuy_darkplum truncate">
            <span class="hidden md:inline">
              {{ $t("alerts.newVersion") }}
            </span>
          </p>
        </div>
        <div
          class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
        >
          <Button
            type="button"
            :on-click-handler="reloadPage"
            :color="'primary-outline'"
          >
            {{ $t("alerts.newVersionButton") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/shared/Button.vue";
import { SpeakerphoneIcon } from "@heroicons/vue/outline";
export default {
  components: { Button, SpeakerphoneIcon },
  methods: {
    reloadPage() {
      window.location.reload(true);
    },
  },
};
</script>
