<template>
  <div class="flex items-center text-sm">
    <div v-if="!['A', 'R', 'P'].includes(invoiceObject.status)">
      <div
        v-if="isProcessing"
        class="flex items-center place-content-center text-hivebuy_plum"
      >
        <div
          v-tippy="{
            content: $t('invoice.ocr.loading'),
            placement: 'bottom',
          }"
          class="button-action flex items-center"
        >
          <SpinningLoaderSVG class="h-5 w-5 mr-1" />
          {{ $t("invoice.ocr.loadingShort") }}
        </div>
      </div>
      <div v-else class="flex items-center gap-x-2">
        <button
          v-if="showAdjust"
          type="button"
          class="action rounded-md button text-black"
          @click="() => setVisibleState('form')"
        >
          <PencilAltIcon class="xl:mr-2 h-5 w-5" aria-hidden="true" />
          <span class="hidden xl:flex">{{ $t("invoice.adjustValues") }}</span>
        </button>
        <button
          v-if="collectiveInvoiceAllowPOAddition(invoiceObject) && showAssign"
          type="button"
          class="action rounded-md button text-black"
          @click="() => setVisibleState('poList')"
        >
          <ClipboardCopyIcon class="xl:mr-2 h-5 w-5" aria-hidden="true" />
          <span class="hidden xl:flex">{{ $t("invoice.assignOrder") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ClipboardCopyIcon, PencilAltIcon } from "@heroicons/vue/outline";
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import { collectiveInvoiceAllowPOAddition } from "@/utils/helpers/invoiceHelper.js";
import {
  isAllowedToAssign,
  isAllowedToEdit,
} from "@/utils/helpers/invoiceHelper.js";
export default {
  components: {
    SpinningLoaderSVG,
    ClipboardCopyIcon,
    PencilAltIcon,
  },
  inject: ["showSection"],
  props: {
    buttonsStacked: { type: Boolean, default: true },
    invoiceObject: { type: Object, required: true },
    setVisibleState: { type: Function, default: () => {} },
  },

  computed: {
    isProcessing() {
      return (
        this.invoiceObject.ocrStatus == "S" ||
        this.invoiceObject.ocrStatus == "N"
      );
    },
    showAssign() {
      return (
        !this.showSection("poList") &&
        this.isAllowedToAssign(this.invoiceObject)
      );
    },
    showAdjust() {
      return (
        !this.showSection("form") && this.isAllowedToEdit(this.invoiceObject)
      );
    },
  },

  methods: {
    collectiveInvoiceAllowPOAddition,
    isAllowedToAssign,
    isAllowedToEdit,
  },
};
</script>

<style scoped>
.action {
  @apply relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 bg-white hover:bg-primary hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary;
}
</style>
