<template>
  <SwitchGroup
    as="div"
    :class="[`flex items-center ${classes}`, { disabled: readOnly }]"
  >
    <SwitchLabel v-if="label" as="span" class="mr-3">
      <div class="flex">
        <span class="label !mb-0">
          {{ label }}
          <span v-if="isMandatory" class="text-hivebuy-red ml-0.5">*</span>
        </span>
        <div v-if="showInfo">
          <Tooltip :text="infoText">
            <InformationCircleIcon
              class="w-5 h-5 ml-2 text-success cursor-help"
            />
          </Tooltip>
        </div>
      </div>
    </SwitchLabel>
    <Switch
      :value="value.toString()"
      :class="[
        value ? 'switch-on' : 'switch-off',
        { 'cursor-not-allowed bg-gray-200': readOnly },
        'switch',
      ]"
      @click="onClick"
    >
      <span class="sr-only">{{ label }}</span>
      <span
        aria-hidden="true"
        :class="[
          value ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none flex items-center justify-center h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      >
        <span v-if="loading">
          <DashboardLoader
            :height="'4'"
            :width="'4'"
            class="w-3 text-gray-600"
          />
        </span>
        <span v-if="stateIcons && !loading">
          <span v-if="!value">
            <XIcon class="text-gray-500 w-3" />
          </span>
          <span v-else>
            <CheckIcon class="text-primary w-3" />
          </span>
        </span>
      </span>
    </Switch>
  </SwitchGroup>
</template>

<script>
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { XIcon, CheckIcon } from "@heroicons/vue/solid";
import Tooltip from "@/components/shared/Tooltip";
import { InformationCircleIcon } from "@heroicons/vue/outline/esm";
import { DashboardLoader } from "@/components/shared/index";

export default {
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    XIcon,
    CheckIcon,
    InformationCircleIcon,
    Tooltip,
    DashboardLoader,
  },
  props: {
    isMandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    onClickHandler: {
      type: Function,
      default: () => {},
    },
    classes: {
      type: String,
      default: "",
    },
    stateIcons: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "on-change"],
  computed: {
    value() {
      return this.modelValue || this.checked;
    },
  },
  methods: {
    onClick() {
      if (!this.readOnly) {
        this.$emit("update:modelValue", !this.modelValue);
        this.$emit("on-change", this.modelValue || this.checked);
        if (this.onClickHandler) {
          this.onClickHandler();
        }
      }
    },
  },
};
</script>
<style>
.switch {
  @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none;
}

.switch-off {
  @apply bg-gray-100 !important;
}

.switch-on {
  @apply bg-primary !important;
}
</style>
