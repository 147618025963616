import { get, patch } from "../api";
import { BUDGET_API_URL } from "@/utils/services/apiUrls";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types";
import { objectToQueryString } from "@/utils/utility_methods";

export const getBudgets = (commit, resolve, reject, filteredParams) => {
  get(`${BUDGET_API_URL}?${objectToQueryString(filteredParams)}`)
    .then((response) => {
      commit(CompanyManagementTypes.SET_BUDGETS, response.data);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateBudgets = (commit, resolve, reject, payload) => {
  const { legalEntity, ...requestPayload } = payload;
  let url = `${BUDGET_API_URL}bulk-update/`;

  if (legalEntity) {
    url = `${url}?legal_entity=${legalEntity}`;
  }

  patch(url, requestPayload)
    .then((response) => {
      const data = { type: payload.budgetLevel, payload: response.data };
      commit(CompanyManagementTypes.SET_BUDGETS, data);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateBudget = (commit, resolve, reject, payload) => {
  const { id, ...requestPayload } = payload;
  patch(`${BUDGET_API_URL}${id}/`, requestPayload)
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
};
