<template>
  <div>
    <div v-if="!selectedCategory">
      <h3 class="text-sm leading-6 font-normal text-gray-600">
        {{ $t("workflow.editor.resultTitle") }}
      </h3>
      <SelectOptions
        :options="resultTypes"
        :on-select="
          (value) => {
            selectedCategory = value;
          }
        "
      />
    </div>

    <div v-if="selectedCategory == 'user'">
      <div class="sm:col-span-3 mt-10">
        <MemberSelector
          :value="value"
          :on-value-change="
            (response) => {
              value = response;
            }
          "
          :object="true"
          :members-list="users"
        />
      </div>
    </div>
    <div v-else-if="selectedCategory == 'responsibleUser'">
      <div class="workflowItem text-center mt-2">
        {{ $t("workflow.editor.responsibleUser") }}
      </div>
    </div>
    <div v-else-if="selectedCategory">
      <div class="workflowItem text-center mt-2">
        <Role :role="selectedCategory" :badge="false" />
      </div>
    </div>
    <EditorButtons
      :reset="
        () => {
          selectedCategory = null;
        }
      "
      :save="onSave"
      :cancel="closeModal"
      :show-reset="selectedCategory != null"
      :show-save="isValidSubmission"
    />
  </div>
</template>
<script>
// eslint-disable-next-line @typescript-eslint/no-var-requires
const workflowJson = require("@/assets/data/workflow.json");
const { resultTypes } = workflowJson;
import { USER_ROLES_MAPPING, WORKFLOW_ROLES_MAPPING } from "@/utils/constants";
import { workflowMixin } from "@/components/mixins/index.js";
import { MemberSelector } from "@/components/shared/index";
import Role from "@/components/CompanyManagement/Users/Role.vue";
export default {
  components: { MemberSelector, Role },
  mixins: [workflowMixin],
  data() {
    return {
      resultTypes,
      roles: USER_ROLES_MAPPING,
      selectedCategory: null,
      value: null,
    };
  },
  computed: {
    isValidSubmission() {
      if (this.selectedCategory == null) return false;
      if (this.selectedCategory == "user") return this.value != null;
      else return this.selectedCategory != null;
    },
  },
  created() {
    this.WORKFLOW_ROLES_MAPPING = WORKFLOW_ROLES_MAPPING;
  },
  methods: {
    onSave() {
      this.saveValue("result", this.selectedCategory, this.value);
      this.closeModal();
    },
  },
};
</script>
