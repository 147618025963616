import {
  ADD_INVOICE,
  INVOICE_MODULE,
  SET_RECENT_INVOICES,
  UPDATE_INVOICE,
  GET_INVOICES,
  ASSIGN_PURCHASE_ORDER,
  UPDATE_INVOICE_RELATION,
  SET_NOT_INVOICE,
} from "@/store/Invoice/types";
import { mapActions, mapMutations } from "vuex";
import { toBase64 } from "@/utils/utility_methods";
import {
  isAllowedToAssign,
  isAllowedToEdit,
} from "@/utils/helpers/invoiceHelper.js";
export default {
  methods: {
    ...mapMutations(INVOICE_MODULE, {
      setRecentInvoices: SET_RECENT_INVOICES,
    }),
    ...mapActions(INVOICE_MODULE, {
      addInvoice: ADD_INVOICE,
      updateInvoice: UPDATE_INVOICE,
      getInvoices: GET_INVOICES,
      assignPO: ASSIGN_PURCHASE_ORDER,
      updateRelation: UPDATE_INVOICE_RELATION,
      markNotInvoice: SET_NOT_INVOICE,
    }),
    isAllowedToAssign,
    isAllowedToEdit,
    isAllowedToSetStatusToNI(invoice) {
      return invoice.status === "N";
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isAllowedToSetStatusToNew(invoice) {
      // return invoice.status === "NI";
      return false;
    },

    markStatusInvoice(invoice, status) {
      const payload = {
        status: status,
      };
      return this.updateInvoice({ invoiceId: invoice.id, payload: payload });
    },

    async attachFile(data, poId = null) {
      this.isLoadingInvoices = true;
      const payload = [];
      for (const file of data) {
        const encodedFile = await toBase64(file);
        const title = file.title || file.name;
        const invoicePayload = {
          invoiceNumber: title,
          files: [{ title, file: encodedFile }],
        };
        if (poId) invoicePayload.purchaseOrders = [poId];
        payload.push(invoicePayload);
      }
      const invoiceData = await this.createInvoices(payload);
      this.isLoadingInvoices = false;

      return invoiceData;
    },
    async createInvoices(payload) {
      const { data } = await this.addInvoice(payload);
      this.showNotification("Invoice created Successfully");
      return data;
    },
    saveInvoiceObject(object) {
      this.saveLoading = true;
      this.updateInvoice({ invoiceId: object.id, payload: object })
        .then(() => this.showNotification("Invoice Updated Successfully"))
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.saveLoading = false;
        });
    },
    validData(payload) {
      if (!payload.invoiceNumber || !payload.title) {
        this.invoiceError = this.$t("invoice.errors.titleAndNumber");
        return false;
      }
      if (payload.invoiceNumber == payload.title) {
        this.invoiceError = this.$t("invoice.errors.titleMatch");
        return false;
      }
      return true;
    },
    async addInvoiceToPo(files, payload = {}, ocrData = {}) {
      try {
        const updatedPayload = Object.keys(payload).length
          ? payload
          : { invoiceNumber: files[0].invoiceNumber, title: files[0].title };
        if (!this.validData(updatedPayload)) return;
        const { file, title, invoiceNumber } = files[0];
        const encodedFile = await toBase64(file);
        const updatedFiles = [
          {
            title,
            invoiceNumber,
            file: encodedFile,
          },
        ];
        const invoicePayload = Object.assign(
          {},
          updatedPayload,
          {
            files: updatedFiles,
            purchaseOrders: [this.purchaseOrder.id],
          },
          ocrData
        );
        const data = await this.addInvoice([invoicePayload]);

        const invoice = data.data[0];
        const updatedPoPayload = Object.assign({}, invoicePayload, {
          invoice: invoice.id,
          purchaseOrder: this.purchaseOrder.id,
        });
        await this.assignPO(updatedPoPayload);
        this.isRequestDetailPage && this.fetchRequest(false);
        this.onModalClose();
        this.showNotification("Invoice Created Successfully");
      } catch {
        this.showErrorMessage();
      }
    },
  },
};
