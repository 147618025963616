import { mapMutations } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  SET_NEW_PURCHASE_REQUEST,
  RESET_CURRENT_REQUEST,
} from "@/store/PurchaseRequest/types";

export default {
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setNewPurchaseRequest: SET_NEW_PURCHASE_REQUEST,
      resetRequest: RESET_CURRENT_REQUEST,
    }),

    resetForm() {
      this.$router.push({
        name: "Requests",
      });
      setTimeout(() => this.resetRequest({}), 300);
    },
  },
};
