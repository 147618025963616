<template>
  <div class="px-4 py-5 border rounded" :class="style" role="alert">
    <slot name="textSection">
      <p>
        <span class="font-bold">System Alert: </span>
        <span class="text-sm">{{ text }}</span>
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Message",
    },
    type: {
      type: String,
      default: "info",
    },
  },

  computed: {
    style() {
      return {
        info: "bg-blue-100 border-blue-500 text-blue-700",
        warning: "bg-yellow-50 border-yellow-700 text-yellow-900",
        error: "bg-red-100 border-red-500 text-red-700",
      }[this.type];
    },
  },
};
</script>
