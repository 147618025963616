<template>
  <div class="w-full">
    <span v-if="label" class="label">
      {{ label }}
      <span v-if="isMandatory" class="text-hivebuy-red ml-0.5">*</span>
    </span>
    <div
      class="flex relative"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <input
        ref="inputRef"
        type="text"
        class="relative"
        :class="[
          'appearance-none block w-full  border border-gray-200 rounded placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary   shadow-none',
          { 'bg-gray-100 text-gray-500 cursor-not-allowed': disabled },
          errorText ? 'border-hivebuy-red' : '',
          dense ? 'py-0.5 text-base px-2' : 'min-h-[42px] sm:text-base px-3',
        ]"
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <div v-if="ocrDetails" class="absolute top-2 right-3">
        <OCRButtonInput
          :ocr-details="ocrDetails"
          :field-value="modelValue"
          :label="label"
          :on-update-value="updateValue"
        />
      </div>
      <span
        v-if="showCalc"
        class="justify-self-center self-center text-gray-500 cursor-pointer hover:text-primary"
        @click="onCalc"
      >
        <CalculatorIcon class="ml-0.5 h-6 w-6" aria-hidden="true" />
      </span>
    </div>
    <FieldError v-if="errorText" :text="errorText" />
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { isObjectEmpty } from "@/utils/utility_methods";
import FieldError from "@/components/shared/FieldError";
import { useStore } from "vuex";
import { CURRENCY_TO_LOCALE } from "@/utils/constants";
import { CalculatorIcon } from "@heroicons/vue/outline";
import OCRButtonInput from "@/components/shared/OCRButtonInput.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  name: "CurrencyInput",
  components: {
    FieldError,
    CalculatorIcon,
    OCRButtonInput,
  },
  props: {
    modelValue: {
      type: [Number, String, null],
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: "",
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    showCalc: {
      type: Boolean,
      default: false,
    },
    onCalc: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: "",
    },
    onMouseEnter: {
      type: Function,
      default: () => {},
    },
    onMouseLeave: {
      type: Function,
      default: () => {},
    },
    ocrDetails: {
      type: Object,
      default: null,
    },
    currencyDisplay: {
      type: String,
      default: "symbol",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    currencyValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const company = computed(
      () => store.state[COMPANY_MANAGEMENT_MODULE].company
    );
    let options = Object.assign({}, props.options);

    if (isObjectEmpty(props.options)) {
      options = {
        locale: CURRENCY_TO_LOCALE[company.value.currency],
        currency: props.currencyValue || company.value.currency,
        currencyDisplay: props.currencyDisplay,
      };
    }

    const { inputRef, setValue, setOptions } = useCurrencyInput(options);

    function updateValue(value) {
      setValue(value);
    }

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef, updateValue };
  },
};
</script>
