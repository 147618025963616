<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog
      as="div"
      static
      class="fixed z-[100] inset-0"
      :open="showModal"
      v-on="!preventClickOutside ? { close: closeModal } : {}"
    >
      <div
        class="flex justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :class="[
              'inline-flex flex-col align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:p-6 max-h-[90vh]',
              ...sizeClasses,
            ]"
          >
            <div class="modal-header shrink-0">
              <div
                v-if="showIcon"
                class="modal-header__icon"
                :class="iconColor"
              >
                <slot name="icon" />
              </div>
              <DialogTitle as="h3" class="modal-heading">
                <slot name="header" />
              </DialogTitle>

              <div v-if="showClose" class="close-button">
                <XIcon
                  class="h-6 w-6 text-gray-400 hover:text-primary"
                  aria-hidden="true"
                  @click="closeModal"
                />
              </div>
            </div>
            <div
              class="mt-2 sm:mt-3 flex flex-col max-h-[70vh]"
              :class="scrollableContent ? 'overflow-y-scroll' : ''"
            >
              <div class="text-sm text-gray-500">
                <slot name="body" />
              </div>
            </div>
            <div
              :class="['mt-5 grid-flow-row-dense shrink-0', ...footerClasses]"
            >
              <slot name="footer" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    sizeClasses: {
      type: Array,
      required: false,
      default: () => ["w-full sm:max-w-lg"],
    },
    footerClasses: {
      type: Array,
      required: false,
      default: () => ["grid sm:grid-cols-2 gap-3"],
    },
    iconColor: {
      type: String,
      required: false,
      default: "bg-primary",
    },
    closeModal: {
      type: Function,
      required: true,
    },
    preventClickOutside: {
      type: Boolean,
      default: false,
    },
    scrollableContent: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.modal-header {
  @apply flex items-center mb-2 relative;
}

.modal-header__icon {
  @apply flex items-center justify-center h-12 w-12 rounded-full bg-primary mr-4 shrink-0;
}

.close-button {
  @apply w-12 h-12 cursor-pointer flex items-center justify-end shrink-0 ml-4;
}
</style>
