export const REDIRECTION_ROUTES = {
  "purchase-request": ["purchase-request"],
  catalogue: ["catalogue"],
  proposals: ["proposals"],
  contracts: ["contracts"],
  "project-view": ["project"],
  requests: ["requests", "purchase-request"],
  orders: ["orders"],
  "invoice-decide": ["invoice"],
  "approval-workflow-editor": ["approval-flow"],
  "premium-feature": ["premium-feature"],
};
