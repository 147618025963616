<template>
  <div class="flex flex-col mt-8">
    <div
      class="flex md:flex-row md:gap-x-10 flex-col items-center justify-between mb-4"
    >
      <div class="w-full">
        <Input
          id="search"
          :value="search"
          name="search"
          :placeholder="$t('general.search')"
          show-icon
          type="search"
          :on-key-up="onSearchChange"
        />
      </div>
    </div>
    <div
      v-if="!areBudgetsFetched"
      class="custom-card overflow-visible no-shadow"
    >
      <div class="inner-wrapper">
        <table class="table">
          <thead class="table-header">
            <tr>
              <th
                v-for="(columnName, index) in tableColumns()"
                :key="index"
                scope="col"
              >
                {{ columnName }}
              </th>
              <th scope="col" class="relative px-6 py-3 w-12">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <TableRowsLoader :columns="4" :rows="1" />
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <div v-if="usersPresent">
        <div class="custom-card overflow-visible no-shadow">
          <div class="inner-wrapper">
            <table class="table">
              <thead class="table-header">
                <tr>
                  <th
                    v-for="(columnName, index) in tableColumns()"
                    :key="index"
                    scope="col"
                  >
                    {{ columnName }}
                  </th>
                  <th scope="col" class="relative px-6 py-3 w-12">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="table-body">
                <tr
                  v-for="userBudget in filteredList"
                  :key="userBudget.id"
                  class="hover:bg-gray-100 group"
                >
                  <td class="w-80">
                    <UserWithAvatar
                      :user="userBudget.user"
                      :show-email="false"
                      :show-name="true"
                      :show-tooltip="false"
                    />
                  </td>
                  <td class="w-56">
                    <div
                      v-if="isEditModeEnabled(userBudget)"
                      class="min-w-[4.5rem]"
                    >
                      <CurrencyInput
                        :id="userBudget.budget.id"
                        v-model.lazy="budgetToUpdate.amount"
                      />
                    </div>
                    <div
                      v-else
                      class="text-primary font-semibold text-left min-w-[4.5rem]"
                    >
                      <i18n-n
                        :value="parseInt(userBudget.budget.amount)"
                        format="budget"
                        minimum-fraction-digits="2"
                        :locale="
                          currencyToLocale(userBudget.budget.amountCurrency)
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      v-if="isEditModeEnabled(userBudget)"
                      class="min-w-[4.5rem]"
                    >
                      <TextArea
                        id="comment"
                        name="comment"
                        :placeholder="
                          $t(
                            'catalogue.catalogueItemForm.descriptionPlaceholder'
                          )
                        "
                        :value="budgetToUpdate.comment"
                        :rows="2"
                        :on-value-change="onAttributeChange"
                      />
                    </div>
                    <div v-else class="text-sm text-left min-w-[4.5rem]">
                      {{ userBudget.budget.comment }}
                    </div>
                  </td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm w-12 relative"
                  >
                    <div
                      :class="{ 'opacity-100': isEditModeEnabled(userBudget) }"
                      class="opacity-0 flex gap-x-0.5"
                    >
                      <button type="button" @click="onSave">
                        <CheckCircleIcon class="w-6 h-6 text-hivebuy-green" />
                      </button>
                      <button type="button" @click="onCancel">
                        <XCircleIcon class="w-6 h-6 text-hivebuy-red" />
                      </button>
                    </div>
                    <div
                      v-if="!isEditModeEnabled(userBudget)"
                      class="inline-flex md:group-hover:opacity-100 md:opacity-0 col-span-1 group-hover:text-success absolute top-6 right-5"
                    >
                      <PencilAltIcon
                        class="w-5 h-5 text-hivebuy-green cursor-pointer"
                        @click="onEdit(userBudget)"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td
                    class="px-6 py-4 whitespace-nowrap text-gray-700 font-semibold"
                  >
                    <div class="text-left">
                      <i18n-n
                        :value="parseInt(userBudgets.totalAmount)"
                        format="currency"
                        minimum-fraction-digits="2"
                        :locale="
                          currencyToLocale(userBudgets.totalAmountCurrency)
                        "
                      />
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <EmptyState
          :heading-text="'You have no budget for users'"
          :description-text="'Get started by creating a new user budget and then budget will be automatically displayed here.'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_BUDGETS,
} from "@/store/CompanyManagement/types";

import {
  PencilAltIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";

import {
  currencyToLocale,
  getInitialsOfFullName,
  isObjectEmpty,
} from "@/utils/utility_methods";
import {
  UserWithAvatar,
  EmptyState,
  CurrencyInput,
  TableRowsLoader,
  TextArea,
  Input,
} from "@/components/shared/index";

export default {
  components: {
    TableRowsLoader,
    PencilAltIcon,
    TextArea,
    CheckCircleIcon,
    XCircleIcon,
    EmptyState,
    UserWithAvatar,
    CurrencyInput,
    Input,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    legalEntity: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      budgetToUpdate: {},
      isEditMode: false,
      search: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, {
      userBudgets: (state) => state.budgets.userBudgets,
    }),
    areBudgetsFetched() {
      return !this.loading && !isObjectEmpty(this.userBudgets);
    },
    usersPresent() {
      return this.userBudgets.users.length;
    },
    filteredList() {
      return this.userBudgets.users.filter((user) => {
        const userObj = user.user;
        return userObj.isActive && userObj.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    isLoading: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loading = newVal;
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateBudgets: UPDATE_BUDGETS,
    }),
    tableColumns() {
      return [
        this.$t("global.name"),
        this.$t("global.budget"),
        this.$t("global.comment"),
      ];
    },
    isEditModeEnabled(userBudget) {
      return this.isEditMode && this.budgetToUpdate.id == userBudget.budget.id;
    },
    onEdit(userBudget) {
      this.isEditMode = true;
      this.budgetToUpdate.id = userBudget.budget.id;
      this.budgetToUpdate.amount = parseInt(userBudget.budget.amount);
      this.budgetToUpdate.comment = userBudget.budget.comment;
    },
    onSearchChange(event) {
      const { value } = event.target;
      this.search = value;
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.budgetToUpdate[name] = value;
    },
    onSave() {
      this.updateUserBudget();
      this.isEditMode = false;
    },
    updateUserBudget() {
      this.loading = true;
      const payload = {
        budgetLevel: "U",
        budgets: [this.budgetToUpdate],
        legalEntity: this.legalEntity,
      };

      this.updateBudgets(payload)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    onCancel() {
      this.budgetToUpdate = {};
      this.isEditMode = false;
    },
    currencyToLocale,
    getInitialsOfFullName,
  },
};
</script>

<style scoped>
table tbody tr:last-child {
  @apply left-0 sticky bg-gray-50 bottom-0;
}

.custom-card {
  @apply max-h-[60vh];
}

.custom-card .inner-wrapper {
  @apply -m-5 max-h-[60vh] overflow-auto;
}
</style>
