import * as CatalogueTypes from "@/store/Catalogue/types";
import { updateOrCreate } from "@/utils/utility_methods";

export default {
  [CatalogueTypes.SET_CATALOGUE_ITEMS]: (
    state,
    { payload, areFiltersUpdated }
  ) => {
    state.catalogueItems = areFiltersUpdated
      ? payload
      : [...(state.catalogueItems || []), ...payload];
  },
  [CatalogueTypes.SET_CATALOGUES_ITEMS_LIST_FILTERS]: (state, payload) => {
    state.catalogueItemsListFilters = payload;
  },

  [CatalogueTypes.SET_ADMIN_CATALOGUE_ITEMS]: (
    state,
    { payload, areFiltersUpdated }
  ) => {
    state.adminCatalogueItems = areFiltersUpdated
      ? payload
      : [...(state.adminCatalogueItems || []), ...payload];
  },
  [CatalogueTypes.SET_ADMIN_CATALOGUE_ITEMS_LIST_FILTERS]: (state, payload) => {
    state.adminCatalogueItemsListFilters = payload;
  },

  [CatalogueTypes.SET_CATALOGUE]: (state, payload) => {
    const catalogueToSet = state.catalogues.findIndex(
      (p) => p.id === payload.id
    );
    if (catalogueToSet >= 0) {
      state.catalogues[catalogueToSet] = payload;
    } else {
      state.catalogues = [...state.catalogues, payload];
    }
  },

  [CatalogueTypes.SET_CATALOGUES]: (state, payload) => {
    state.catalogues = payload;
  },

  [CatalogueTypes.SET_CATALOGUE_ITEM]: (state, payload) => {
    const objects = ["adminCatalogueItems", "catalogueItems"];
    objects.forEach((stateObj) => {
      if (state[stateObj]?.length) {
        state[stateObj] = updateOrCreate(state[stateObj], payload, false);
      }
    });
  },

  [CatalogueTypes.REMOVE_CATALOGUE_ITEM]: (state, catalogueItemId) => {
    state.adminCatalogueItems = state.adminCatalogueItems.filter(
      (item) => item.id != catalogueItemId
    );
  },

  [CatalogueTypes.REMOVE_CATALOGUE]: (state, catalogueId) => {
    state.catalogues = state.catalogues.filter((c) => c.id != catalogueId);
  },

  [CatalogueTypes.SET_CATALOGUE_DISPLAY_VIEW]: (state, payload) => {
    state.catalogueDisplayView = payload;
  },
};
