<template>
  <div id="steps">
    <div v-auto-animate class="max-w-7xl mx-auto mt-6 mb-8">
      <div class="steps-holder">
        <!-- First Step -->
        <div
          :class="[
            'step group',
            { active: currentStep().category },
            { completed: !blankValue('category') },
          ]"
          @click="() => changeFormState('category')"
        >
          <div class="step-icon-holder">
            <div class="icon">
              <ShoppingBagIcon
                class="default text-gray-300 group-hover:text-primary"
              />
              <CheckCircleIcon class="completed" />
            </div>
            <div class="label">
              <span
                v-if="
                  !currentRequest?.items?.length && !currentRequest.category
                "
              >
                Select Category</span
              >
              <Category v-else :purchase-request="currentRequest" squashed />
            </div>
          </div>
        </div>
        <div :class="['step step-bar', { completed: !blankValue('category') }]">
          <div class="step-bar-holder">
            <div class="progress"></div>
          </div>
        </div>

        <!-- Second Step -->

        <div
          :class="[
            'step group',
            { active: currentStep().supplier },
            { completed: !blankValue('supplier') },
          ]"
          @click="() => changeFormState('supplier')"
        >
          <div class="step-icon-holder group">
            <div class="icon">
              <UserAddIcon
                class="default text-gray-300 group-hover:text-primary"
              />
              <CheckCircleIcon class="completed" />
            </div>
            <div class="label">
              <SupplierName :purchase-request="currentRequest" />
            </div>
          </div>
        </div>
        <div :class="['step step-bar', { completed: !blankValue('supplier') }]">
          <div class="step-bar-holder">
            <div class="progress"></div>
          </div>
        </div>

        <!-- Third Step -->
        <div
          :class="[
            'step group',
            { active: currentStep().item },
            { completed: currentRequest.items.length },
          ]"
          @click="() => !isEditRoute && changeFormState('items')"
        >
          <div class="step-icon-holder group">
            <div class="icon">
              <CogIcon class="default text-gray-300 group-hover:text-primary" />
              <CheckCircleIcon class="completed" />
            </div>
            <div class="label">Details</div>
          </div>
        </div>
        <div
          :class="['step step-bar', { completed: currentRequest.items.length }]"
        >
          <div class="step-bar-holder">
            <div class="progress"></div>
          </div>
        </div>
        <!-- Fourth Step -->
        <div
          :class="['step group', { active: currentStep().summary }]"
          @click="() => changeFormState('summary')"
        >
          <div class="step-icon-holder">
            <div class="icon">
              <ShoppingCartIcon
                class="default text-gray-300 group-hover:text-primary"
              />
              <CheckCircleIcon class="completed" />
            </div>
            <div class="label">
              {{ $t("purchaseRequest.newRequest.summary") }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="false" class="bg-white p-4 rounded-md mt-4">
        <div class="flex">
          <div class="ml-3">
            <p class="text-xs text-gray-500">{{ currentStepDetail() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UserAddIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  CogIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
} from "@heroicons/vue/outline";
import { isObjectEmpty } from "@/utils/utility_methods.js";
import { mapState, mapMutations, mapGetters } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_PAGE,
} from "@/store/PurchaseRequest/types.js";
import { Category, SupplierName } from "@/components/shared/index";
export default {
  components: {
    UserAddIcon,
    ShoppingBagIcon,
    CheckCircleIcon,
    ShoppingCartIcon,
    CogIcon,
    Category,
    SupplierName,
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["currentStep"]),
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
  },
  watch: {
    currentRequest: {
      handler() {
        const elem = document.getElementById("product-wizard");
        elem && elem.scrollIntoView(false);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCurrentPage: SET_CURRENT_PAGE,
    }),
    blankValue(currentStep) {
      return (
        isObjectEmpty(this.currentRequest[currentStep]) &&
        this.itemBlankValue(currentStep)
      );
    },
    itemBlankValue(currentStep) {
      if (!this.currentRequest?.items.length) return true;

      return isObjectEmpty(this.currentRequest?.items[0][currentStep]);
    },
    isSelected(currentStep) {
      return `w-8 h-8 mx-auto rounded-full flex items-center justify-center text-white relative ${
        this.step[currentStep] && this.blankValue(currentStep) && "bg-teal-500"
      }`;
    },
    currentStepDetail() {
      let text = "";
      if (this.currentStep().category)
        text = "Please select category for your Purchase Request";
      else if (this.currentStep().supplier)
        text = "Please select companySupplier";
      else if (this.currentStep().item) text = "Please fill out details";
      else text = "Review Products in your cart";

      return text;
    },
    changeFormState(currentStep) {
      if (!this.validTransition(currentStep)) {
        this.showNotification(
          this.$t("errors.fillPreviousSteps"),
          "error",
          this.$t("errors.stepsMissing")
        );
        return;
      }

      if (this.blankValue(currentStep) || this.currentRequest.items == 0) {
        this.setCurrentPage(currentStep);
      }
    },
    validTransition(currentStep) {
      const steps = Object.keys(this.currentRequest);
      const index = steps.findIndex((step) => step == currentStep);
      return index == 0 || !this.blankValue(steps[index - 1]);
    },
  },
};
</script>

<style scoped>
#steps {
}

.steps-holder {
  @apply flex px-12;
}

.steps-holder .step {
  @apply flex justify-center relative;
}

.steps-holder .step.step-bar {
  @apply items-center;
  flex: 2;
}

.step-icon-holder {
  @apply flex flex-col items-center;
}

.step-icon-holder .icon {
  @apply flex justify-center items-center bg-gray-50 mb-3;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 32px;
  height: 28px;
  position: relative;
}

.step-icon-holder .icon:after {
  content: "";
  @apply bg-gray-50;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 30px;
  height: 26px;
  position: absolute;
  left: 1px;
  top: 1px;
  display: none;
}

.step-icon-holder .icon svg {
  @apply h-5 w-5 z-10 transition;
}
.step-icon-holder .icon .completed {
  @apply hidden;
}

.step-icon-holder .label {
  @apply text-sm text-gray-700 font-semibold whitespace-nowrap mb-0 absolute -bottom-4 max-w-[10rem] truncate group-hover:text-primary;
}

.step.active .step-icon-holder .icon:after {
  display: block;
}
.step.active .step-icon-holder .icon {
  @apply bg-primary;
}

.step.active .step-icon-holder .label,
.step.step.completed .step-icon-holder .label {
  @apply text-primary;
}

.step.completed .step-icon-holder .icon {
  @apply bg-primary;
}

.step.completed .step-icon-holder .icon .completed {
  @apply block;
}
.step.completed .step-icon-holder .icon .default {
  @apply hidden;
}

.step.active .step-icon-holder .icon svg {
  @apply text-primary;
}

.step.active.completed .step-icon-holder .icon svg {
  @apply text-primary;
}

.step.completed .step-icon-holder .icon svg {
  @apply text-white;
}

.step-bar-holder {
  @apply bg-gray-300 rounded-lg w-full -mt-[12px];
  height: 2px;
}

.step .step-bar-holder .progress {
  @apply w-0 h-full bg-primary;
  transition: 0.35s;
}

.step.completed .step-bar-holder .progress {
  @apply w-full h-full;
}
</style>
