<template>
  <span class="flex justify-center">
    <div class="flex items-center">
      <div
        v-if="!getPercentIndicator.over100"
        class="p-1.5 px-3 text-base font-semibold rounded-full"
        :class="[getPercentIndicator.textColor]"
      >
        <i18n-n
          :value="getPercentIndicator.value"
          format="percent"
          :locale="currencyToLocale(company.currency)"
        />
      </div>
      <div
        v-else
        class="inline-flex text-base mx-2 font-semibold"
        :class="[getPercentIndicator.textColor]"
      >
        > 100 %
      </div>
      <div
        v-if="showTotal"
        class="text-base font-normal -ml-2"
        :class="[getPercentIndicator.textColor]"
      >
        /
        <i18n-n
          :value="parseFloat(Math.abs(actualAmount - expectedAmount))"
          format="currency"
          :locale="currencyToLocale(company.currency)"
        />
      </div>
    </div>
  </span>
</template>
<script>
import { differenceIndicator, currencyToLocale } from "@/utils/utility_methods";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  props: {
    actualAmount: { type: [String, Number], required: true },
    expectedAmount: { type: [String, Number], required: true },
    showTotal: { type: Boolean, default: false },
  },
  data() {
    return {
      indicatorThreshold: 0.05,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    difference() {
      return parseFloat(this.valueOne) - parseFloat(this.valueTwo);
    },
    percentageDifference() {
      if (this.valueOne == 0) return 100;
      return (this.difference / parseFloat(this.valueOne)).toFixed(2) * 100;
    },
    getPercentIndicator() {
      return differenceIndicator(
        this.actualAmount,
        this.expectedAmount,
        this.indicatorThreshold
      );
    },
  },
  methods: {
    currencyToLocale,
  },
};
</script>
