<template>
  <article>
    <div class="bg-white min-h-screen lg:bg-gray-100">
      <div class="hidden lg:block"></div>
      <div class="">
        <div class="flex items-center justify-center sm:space-x-5 mb-8">
          <div>
            <img
              v-if="user.avatar"
              class="h-24 w-24 rounded-full ring-2 ring-gray-100 sm:h-32 sm:w-32"
              :src="user.avatar"
            />
            <!-- <div
              v-if="!user.avatar"
              class="h-24 w-24 rounded-full"
              :class="initialsBg"
            ></div> -->
            <div v-else>
              <UserWithAvatar
                :user="user"
                :show-name="false"
                :show-email="false"
                :show-avatar="true"
                :avatar-dimension="24"
              />
            </div>
          </div>
          <div class="flex items-center mx-3">
            <div>
              <h1 class="text-xl font-semibold text-bayoux-900 truncate">
                {{ fullName(user) }}
              </h1>
            </div>
            <div class="">
              <button class="focus:outline-none" @click="editProfile">
                <PencilIcon
                  class="h-6 w-6 text-teal-500 mx-2"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mb-8">
          <div class="sm:col-span-1">
            <div
              class="relative rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <dt class="text-sm font-medium text-gray-900">Departments</dt>
              <dd class="text-sm text-gray-900">
                <span
                  v-for="(department, index) in user.departments"
                  :key="department.id"
                >
                  <template v-if="index > 0">, </template>
                  {{ department.name }}
                </span>
              </dd>
            </div>
          </div>

          <div class="sm:col-span-1">
            <div
              class="relative rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <dt class="text-sm font-medium text-gray-900">Email</dt>
              <dd class="text-sm text-gray-900">
                {{ user.email }}
              </dd>
            </div>
          </div>

          <div class="sm:col-span-1">
            <div
              class="relative rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <dt class="text-sm font-medium text-gray-900">Company</dt>
              <dd class="text-sm text-gray-900">
                {{ company.name }}
              </dd>
            </div>
          </div>

          <div v-if="rolesPresent(user)" class="sm:col-span-1">
            <div
              class="relative rounded-lg border border-gray-300 bg-white px-6 py-2 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <dt class="text-sm font-medium text-gray-900">Role</dt>
              <dd class="text-sm text-gray-900">{{ role(user) }}</dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import userMixin from "@/components/mixins/userMixin.js";
import { PencilIcon } from "@heroicons/vue/solid";
import { UserWithAvatar } from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    PencilIcon,
    UserWithAvatar,
  },
  mixins: [userMixin],
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
  methods: {
    editProfile() {
      this.$router.push({ name: "EditProfile" });
    },
  },
};
</script>
