import Multiselect from "@vueform/multiselect";
import EditorButtons from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/buttons.vue";
import SelectOptions from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/options.vue";
export default {
  components: { Multiselect, EditorButtons, SelectOptions },
  props: {
    selectedOption: { type: String, default: null },
    saveValue: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    departments: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
    },
    workflow: {
      type: Object,
      required: true,
    },
    editValue: {
      type: Number,
      required: false,
    },
    legalTendencies: {
      type: Array,
      required: true,
    },
    suppliers: {
      type: Array,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    }
  },
};
