import store from "@/store/index.js";
import {
  DOWNLOAD_MODULE,
  SET_DOWNLOAD_STATUS,
  SET_UPLOAD_STATUS,
} from "@/store/Downloads/types";

export const importFailedRecords = (data) => {
  store.commit(`${DOWNLOAD_MODULE}/${SET_DOWNLOAD_STATUS}`, {
    storeState: data.modelName,
    downloading: false,
    value: data.download.id,
    error: true,
  });
  store.commit(`${DOWNLOAD_MODULE}/${SET_UPLOAD_STATUS}`, {
    storeState: data.modelName,
    inProgress: false,
    value: data.download.id,
    error: true,
  });
};
