import * as ProposalTypes from "@/store/Proposal/types";
import { formattedURL } from "@/utils/utility_methods";
import { deleteObject, get, patch, post } from "../api";
import {
  PROPOSAL_SUGGESTIONS_API_URL,
  PROPOSALS_API_URL,
} from "@/utils/services/apiUrls";
import { updatePaginationParams } from "@/utils/helpers/paginationHelper";

export const getProposals = (
  commit,
  resolve,
  reject,
  queryParams,
  areFiltersUpdated
) => {
  get(formattedURL(PROPOSALS_API_URL, queryParams))
    .then((response) => {
      updatePaginationParams("proposalsListFilters", response.data);
      commit(ProposalTypes.SET_PROPOSALS, {
        payload: response.data.results,
        areFiltersUpdated,
      });
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createProposal = (commit, resolve, reject, payload) => {
  post(PROPOSALS_API_URL, payload)
    .then((response) => {
      commit(ProposalTypes.SET_PROPOSAL, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getProposal = (resolve, reject, proposalId) => {
  if (!proposalId) return;

  get(`${PROPOSALS_API_URL}${proposalId}/`)
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
};

export const updateProposalMembers = (resolve, reject, proposalId, payload) => {
  patch(`${PROPOSALS_API_URL}${proposalId}/update-members/`, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createSuggestion = (resolve, reject, payload) => {
  post(PROPOSAL_SUGGESTIONS_API_URL, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const likeSuggestion = (resolve, reject, suggestionId) => {
  if (!suggestionId) return;

  patch(`${PROPOSAL_SUGGESTIONS_API_URL}${suggestionId}/add_like/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const dislikeSuggestion = (resolve, reject, suggestionId) => {
  if (!suggestionId) return;

  patch(`${PROPOSAL_SUGGESTIONS_API_URL}${suggestionId}/add_dislike/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteSuggestion = (resolve, reject, suggestionId) => {
  if (!suggestionId) return;

  deleteObject(`${PROPOSAL_SUGGESTIONS_API_URL}${suggestionId}/`)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateSuggestionPurchaseRequest = (
  resolve,
  reject,
  suggestionId,
  payload
) => {
  if (!suggestionId) return;

  patch(`${PROPOSAL_SUGGESTIONS_API_URL}${suggestionId}/`, payload)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const finaliseSuggestion = (resolve, reject, suggestionId) => {
  if (!suggestionId) return;

  patch(`${PROPOSAL_SUGGESTIONS_API_URL}${suggestionId}/select-suggestion/`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateProposal = (
  commit,
  resolve,
  reject,
  proposalId,
  payload
) => {
  if (!proposalId) return;

  patch(`${PROPOSALS_API_URL}${proposalId}/`, payload)
    .then((response) => {
      commit(ProposalTypes.SET_PROPOSAL, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
