import { LOADING_MODULE, SET_LOADING_STATE } from "@/store/Loading/types";
import { mapMutations, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(LOADING_MODULE, ["isApiLoading"]),
  },
  methods: {
    ...mapMutations(LOADING_MODULE, {
      setApiLoading: SET_LOADING_STATE,
    }),
  },
};
