<template>
  <ul class="payment-list">
    <li
      v-for="paymentMethod in paymentList"
      :key="paymentMethod.id"
      class="content-row"
    >
      <div class="content-row__label">
        <div class="flex items-center">
          <h3 class="payment-title">
            {{ paymentMethod.title }}
          </h3>
          <span v-if="paymentMethod.isDefault" class="default-badge">{{
            $t("global.default")
          }}</span>
        </div>

        <p class="content-row__content">
          {{ paymentMethod.description }}
        </p>
      </div>

      <div class="flex items-center ml-8">
        <a
          class="delete-button"
          @click="
            () => {
              setDelete(paymentMethod);
            }
          "
        >
          <TrashIcon class="h-5 w-5" aria-hidden="true" />
        </a>
        <a class="edit-button" @click="onEditPaymentMethod(paymentMethod)">
          <PencilIcon class="h-5 w-5" aria-hidden="true" />
        </a>
      </div>
    </li>
  </ul>
  <div>
    <EditPaymentMethod
      :payment-method-to-edit="paymentMethodToEdit"
      :show-modal="showEditPaymentMethodModal"
      :close-modal="onEditPaymentMethodModalClose"
    />
    <ConfirmDialog
      v-if="paymentMethodToDelete.id"
      :text="'Are you sure you want to delete this?'"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      title="Delete Payment Method"
      :item-to-confirm="paymentMethodToDelete.title"
      :confirm-modal="confirmDelete"
    />
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  ADD_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
} from "@/store/CompanyManagement/types";

import { ConfirmDialog } from "@/components/shared/index";
import { PencilIcon, TrashIcon } from "@heroicons/vue/solid";
import EditPaymentMethod from "@/components/CompanyManagement/Profile/PaymentMethods/EditPaymentMethod";

export default {
  components: {
    EditPaymentMethod,
    ConfirmDialog,
    PencilIcon,
    TrashIcon,
  },

  data() {
    return {
      showEditPaymentMethodModal: false,
      showDeleteConfirmModal: false,
      paymentMethodToEdit: {},
      paymentMethodToDelete: {},
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["paymentMethods"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList"]),
  },
  mounted() {
    this.fetchPaymentMethods();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addPaymentMethod: ADD_PAYMENT_METHOD,
      getPaymentMethods: GET_PAYMENT_METHODS,
      deletePaymentMethod: DELETE_PAYMENT_METHOD,
      updatePaymentMethod: UPDATE_PAYMENT_METHOD,
    }),
    fetchPaymentMethods() {
      if (this.paymentMethods.length) return;

      this.getPaymentMethods();
    },
    setDelete(paymentMethod) {
      this.paymentMethodToDelete = paymentMethod;
      this.showDeleteConfirmModal = true;
    },
    confirmDelete() {
      this.deletePaymentMethod(this.paymentMethodToDelete.id)
        .then(() => {
          this.getPaymentMethods();
          this.paymentMethodToDelete = {};
          this.showNotification("Payment Method Deleted");
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    onEditPaymentMethod(paymentMethod) {
      this.showEditPaymentMethodModal = true;
      this.paymentMethodToEdit = paymentMethod;
    },
    onEditPaymentMethodModalClose() {
      this.paymentMethodToEdit = {};
      this.showEditPaymentMethodModal = false;
    },
  },
};
</script>

<style scoped>
.content-row {
  @apply flex border-b border-gray-200 py-4 justify-between last:border-b-0;
}

.content-row__label {
  @apply text-sm text-gray-800 mb-2;
}

.payment-title {
  @apply font-semibold text-gray-800 text-base mb-1;
}

.default-badge {
  @apply inline-block px-2 py-0.5 text-hivebuy-green text-xs bg-hivebuy-green bg-opacity-20 rounded-full ml-2;
}

.content-row__content {
  @apply text-sm text-gray-500 flex-1;
}

.edit-button {
  @apply h-8 flex items-center justify-center rounded-full bg-hivebuy-green text-white px-4 cursor-pointer hover:contrast-125;
}

.delete-button {
  @apply h-8 flex items-center justify-center rounded-full border border-hivebuy-red text-hivebuy-red mr-2 px-4 cursor-pointer;
}
</style>
