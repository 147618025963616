<template>
  <div class="flex flex-col w-full">
    <div class="grid grid-cols-3 gap-2 pb-2">
      <div class="w-full">
        <Input
          id="search"
          :value="search"
          name="search"
          :placeholder="$t('general.search')"
          show-icon
          type="search"
          :on-key-up="
            (event) =>
              executeDebounceSearch(
                onSearchUpdate,
                { key: 'search', value: event.target.value },
                searchDebounceTimeout
              )
          "
          @keyup.enter="
            (event) =>
              executeDebounceSearch(
                onSearchUpdate,
                { key: 'search', value: event.target.value },
                0
              )
          "
        />
      </div>
      <div class="w-4/6">
        <div v-if="isFeatureAllowed('legalEntity')">
          <Multiselect
            v-model="legalEntity"
            :searchable="true"
            :options="legalTendencyList"
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            :can-clear="false"
            :placeholder="$t('legalTendency.title')"
          />
        </div>
      </div>
      <div class="justify-self-end">
        <CSVUpload type="department" @success="onCSVUploaded" />
      </div>
    </div>

    <div
      v-if="isApiLoading('departments')"
      class="card-loading-section flex justify-center items-center h-full"
    >
      <div class="w-36 h-36"><HivebuyLoader /></div>
    </div>

    <div v-else-if="!isApiLoading('departments') && !areDepartmentsPresent">
      <div class="text-center">
        <EmptyState
          :heading-text="$t('emptyStates.departmentHeading')"
          :description-text="$t('emptyStates.departmentText')"
          :button-text="$t('emptyStates.departmentButton')"
          :button-function="onAddDepartment"
        />
      </div>
    </div>
    <div v-else class="department-holder">
      <div
        v-for="department in filteredList"
        :key="department.id"
        class="flex w-full"
      >
        <TableRow
          :department="department"
          :on-edit="onEdit"
          :on-delete-confirmed="onDeleteConfirmed"
        />
      </div>
      <div v-if="filteredList && !filteredList.length">
        <EmptyState
          :heading-text="$t('companyManagement.departments.noResult')"
          :description-text="$t('companyManagement.departments.noResultText')"
        />
      </div>
    </div>

    <EditDepartmentModal
      :department-to-edit="departmentToEdit"
      :show-modal="showEditDepartmentModal"
      :close-modal="onEditDepartmentModalClose"
    />
    <ConfirmDialog
      :text="$t('companyManagement.departments.deleteDepartment?')"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteClose"
      :title="$t('companyManagement.departments.deleteDepartment')"
      :item-to-confirm="departmentToDelete.name"
      :confirm-modal="onDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_DEPARTMENT,
} from "@/store/CompanyManagement/types";
import { translateMixin } from "@/components/mixins";
import EditDepartmentModal from "@/components/CompanyManagement/Departments/EditDepartmentModal";
import {
  ConfirmDialog,
  EmptyState,
  Input,
  HivebuyLoader,
  CSVUpload,
} from "@/components/shared/index";
import TableRow from "@/components/CompanyManagement/Departments/ListingTable/TableRow";
import Multiselect from "@vueform/multiselect";
import departmentsMixin from "@/components/mixins/departmentsMixin";

export default {
  name: "DepartmentsList",
  components: {
    TableRow,
    EditDepartmentModal,
    ConfirmDialog,
    HivebuyLoader,
    EmptyState,
    Input,
    Multiselect,
    CSVUpload,
  },
  mixins: [translateMixin, departmentsMixin],
  props: {
    onAddDepartment: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showEditDepartmentModal: false,
      departmentToEdit: {},
      showDeleteConfirmModal: false,
      departmentToDelete: {},
      search: "",
      legalEntity: "",
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "departmentsList",
      "departmentDetails",
      "legalTendencyList",
    ]),
    areDepartmentsPresent() {
      return this.departmentsList().length;
    },
    filteredList() {
      const search = this.search.toLowerCase();
      const legalEntityFilter = this.legalEntity;
      const attributesToFilter = ["name", "costCenter"];

      return this.departmentsList().filter((department) => {
        return (
          attributesToFilter.some((attribute) => {
            const departmentValue = department[attribute];
            return !search || departmentValue?.toLowerCase().includes(search);
          }) &&
          (!legalEntityFilter || department.legalEntity === legalEntityFilter)
        );
      });
    },
  },
  watch: {
    legalEntity: {
      async handler(newVal) {
        if (newVal && this.shouldExecutePaginationApi) {
          this.setApiLoading({ key: "departments", value: true });
          const updatedParams = {
            ...this.departmentPaginationParams,
            legal_entity: newVal,
          };
          await this.getDepartments(updatedParams);
          this.setApiLoading({ key: "departments", value: false });
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      deleteDepartment: DELETE_DEPARTMENT,
    }),
    onEdit(department) {
      this.departmentToEdit = department;
      this.showEditDepartmentModal = true;
    },

    onDeleteConfirmed(department) {
      this.departmentToDelete = department;
      this.showDeleteConfirmModal = true;
    },

    onDeleteClose() {
      this.showDeleteConfirmModal = false;
    },
    onEditDepartmentModalClose() {
      this.departmentToEdit = {};
      this.showEditDepartmentModal = false;
    },
    onDelete() {
      this.showDeleteConfirmModal = false;
      this.deleteDepartment(this.departmentToDelete.id)
        .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
        .catch((error) => this.showErrorMessage(error));
    },
    onCSVUploaded() {
      this.setApiLoading({ key: "departments", value: true });
      this.getDepartments()
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "departments", value: false });
        });
    },
  },
};
</script>

<style scoped>
.card-loading-section {
  height: calc(100vh - 180px);
}
</style>
