
import { INVOICE_MODULE } from "@/store/Invoice/types";
import {  mapState } from "vuex";
export default {
  data(){
    return {
      invoice: {}
    }
  },
  computed: {
    ...mapState(INVOICE_MODULE, ["invoicesObject", "invoicePurchaseOrders"]),
    poId(){
      this.$route.query?.poId ;
    },
    invoiceId() {
      return this.$route.params.invoiceId;
    },
    invoicePurchaseOrder(){
      if (this.invoice.invoiceLevelApprovers || !this.invoice.invoicePurchaseOrders.length) return {};

      let ipoObject = {};
      if(this.poId) ipoObject =  this.invoice.invoicePurchaseOrders.find((ipo) => ipo.purchaseOrder.id == this.poId)
      else ipoObject =  this.invoice.invoicePurchaseOrders.find((ipo) =>  ipo.actionRequired);

      const ipoId =  ipoObject?.id || this.invoice.invoicePurchaseOrders[0]?.id
      return this.invoicePurchaseOrders[ipoId]

    },
    purchaseOrder (){
      return this.invoicePurchaseOrder?.purchaseOrder || {}
    },
    purchaseRequest() {
      return this.purchaseOrder?.purchaseRequest || {};
    },
  },
  watch:{
    invoicesObject: {
      deep: true,
      immediate: true,
      handler(value){
        if(!value) return;

        this.invoice = value[this.invoiceId]
      }
    },
  },
};
