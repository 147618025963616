<template>
  <div class="self-signup">
    <div class="page-title">
      <h2>
        {{ $t("home.companySelfSignupCompanyForm.title") }}
      </h2>
    </div>

    <div class="page-content" :class="{ wider: isLastStep }">
      <div v-auto-animate class="custom-card">
        <template v-if="isFirstStep">
          <div class="step">
            <div>
              <Input
                :id="'name'"
                :error-text="error(v$.company.name)"
                :value="company.name"
                :name="'name'"
                :on-value-change="onAttributeChange"
                :type="'text'"
                :placeholder="
                  $t('home.companySelfSignupCompanyForm.fields.name')
                "
                :label="$t('home.companySelfSignupCompanyForm.fields.name')"
              />
            </div>

            <div>
              <div class="label">
                {{
                  $t("home.companySelfSignupCompanyForm.fields.employeesRange")
                }}
              </div>
              <Multiselect
                v-model="company.employeesRange"
                :options="employeesRangeOptions()"
                :placeholder="
                  $t('home.companySelfSignupCompanyForm.fields.employeesRange')
                "
                :can-clear="false"
                :class="{ 'border-red-400': v$.company.employeesRange.$error }"
              />
              <FieldError
                v-if="v$.company.employeesRange.$error"
                :text="$t('errors.required', { entity: 'employeesRange' })"
              />
            </div>
          </div>
        </template>
        <template v-else-if="isSecondStep">
          <div class="step">
            <div>
              <Input
                :id="'contactNumber'"
                v-mask="'+##-###-############'"
                :error-text="error(v$.company.contactNumber)"
                :value="company.contactNumber"
                :name="'contactNumber'"
                :on-value-change="onAttributeChange"
                :type="'tel'"
                :placeholder="
                  $t('home.companySelfSignupCompanyForm.fields.contactNumber')
                "
                :label="
                  $t('home.companySelfSignupCompanyForm.fields.contactNumber')
                "
                is-mandatory
              />
            </div>
          </div>
        </template>
        <template v-else-if="isThirdStep">
          <div class="step">
            <div>
              <div class="label">
                {{ $t("home.companySelfSignupCompanyForm.fields.industry") }}
              </div>
              <Multiselect
                v-model="company.industry"
                :options="industryOptions()"
                :placeholder="
                  $t('home.companySelfSignupCompanyForm.fields.industry')
                "
                :can-clear="false"
                :class="{ 'border-red-400': v$.company.industry.$error }"
              />
              <FieldError
                v-if="v$.company.industry.$error"
                :text="$t('errors.required', { entity: 'industry' })"
              />
            </div>

            <div>
              <div class="label">
                {{ $t("home.companySelfSignupCompanyForm.fields.interest") }}
              </div>
              <Multiselect
                v-model="company.interest"
                :options="interestOptions()"
                :placeholder="
                  $t('home.companySelfSignupCompanyForm.fields.interest')
                "
                :can-clear="false"
                :class="{ 'border-red-400': v$.company.interest.$error }"
              />
              <FieldError
                v-if="v$.company.interest.$error"
                :text="$t('errors.required', { entity: 'interest' })"
              />
            </div>
          </div>
        </template>
        <template v-else-if="isLastStep">
          <div class="step">
            <div class="row">
              <div class="flex-1">
                <Input
                  :value="company.address.name"
                  :name="'name'"
                  :error-text="error(v$.company.address.name)"
                  :placeholder="
                    $t('home.companySelfSignupCompanyForm.fields.address.name')
                  "
                  :label="
                    $t('home.companySelfSignupCompanyForm.fields.address.name')
                  "
                  :on-value-change="onAddressAttributeChange"
                  :type="'text'"
                  is-mandatory
                  class="col-span-6"
                />
              </div>

              <div class="flex-1">
                <Input
                  id="address_street"
                  :value="company.address.street"
                  :error-text="error(v$.company.address.street)"
                  :name="'street'"
                  :placeholder="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.street'
                    )
                  "
                  :label="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.street'
                    )
                  "
                  :on-value-change="onAddressAttributeChange"
                  is-mandatory
                  class="col-span-4"
                />
              </div>
            </div>
            <div class="row">
              <div class="flex-1">
                <Input
                  :value="company.address.houseNumber"
                  :error-text="error(v$.company.address.houseNumber)"
                  :name="'houseNumber'"
                  :placeholder="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.number'
                    )
                  "
                  :label="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.number'
                    )
                  "
                  :on-value-change="onAddressAttributeChange"
                  is-mandatory
                  class="col-span-2"
                />
              </div>

              <div class="flex-1">
                <Input
                  :value="company.address.addressSupplement"
                  :name="'addressSupplement'"
                  :placeholder="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.supplement'
                    )
                  "
                  :label="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.supplement'
                    )
                  "
                  :on-value-change="onAddressAttributeChange"
                  :type="'text'"
                  class="col-span-6"
                />
              </div>
            </div>
            <div class="row">
              <div class="flex-1">
                <Input
                  :value="company.address.postalCode"
                  :error-text="error(v$.company.address.postalCode)"
                  :name="'postalCode'"
                  :placeholder="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.postal'
                    )
                  "
                  :label="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.postal'
                    )
                  "
                  :on-value-change="onAddressAttributeChange"
                  is-mandatory
                  class="col-span-2"
                />
              </div>

              <div class="flex-1">
                <Input
                  :value="company.address.city"
                  :error-text="error(v$.company.address.city)"
                  :name="'city'"
                  :placeholder="
                    $t('home.companySelfSignupCompanyForm.fields.address.city')
                  "
                  :label="
                    $t('home.companySelfSignupCompanyForm.fields.address.city')
                  "
                  :on-value-change="onAddressAttributeChange"
                  is-mandatory
                  class="col-span-4"
                />
              </div>
            </div>
            <div class="row">
              <div class="flex-1">
                <div class="label">
                  {{
                    $t(
                      "home.companySelfSignupCompanyForm.fields.address.country"
                    )
                  }}
                </div>
                <Multiselect
                  v-model="company.address.country"
                  :options="sortedCountries()"
                  :searchable="true"
                  :track-by="'name_en'"
                  :mode="'single'"
                  :placeholder="
                    $t(
                      'home.companySelfSignupCompanyForm.fields.address.country'
                    )
                  "
                  :value-prop="'abbr'"
                  :label="'name_en'"
                  :can-clear="false"
                >
                  <template #option="{ option, selected }">
                    <div class="flex items-center">
                      <img
                        :src="require('@/assets/images/' + option.flag)"
                        alt=""
                        class="flex-shrink-0 h-6 w-6 rounded-full border border-gray-200"
                      />
                      <span
                        :class="[
                          selected ? 'font-semibold' : 'font-normal',
                          'ml-3 block truncate',
                        ]"
                      >
                        {{ option.name_en }}
                      </span>
                    </div>
                  </template>
                </Multiselect>
                <FieldError
                  v-if="v$.company.address.country.$error"
                  :text="$t('errors.required', { entity: 'country' })"
                />
              </div>
            </div>

            <div class="row">
              <div class="flex-1">
                <div class="label">
                  {{ $t("home.companySelfSignupCompanyForm.fields.legalForm") }}
                </div>
                <div class="mt-1">
                  <Multiselect
                    v-model="company.legalForm"
                    :options="legalFormsOptions()"
                    :placeholder="
                      $t('home.companySelfSignupCompanyForm.fields.legalForm')
                    "
                    :value-prop="'name'"
                    :label="'name'"
                    :can-clear="false"
                    :class="{ 'border-red-400': v$.company.legalForm.$error }"
                  />
                  <FieldError
                    v-if="v$.company.legalForm.$error"
                    :text="$t('errors.required', { entity: 'legalForm' })"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="flex-1">
                <Input
                  id="company_website"
                  :error-text="error(v$.company.domain)"
                  :name="'domain'"
                  :on-value-change="onAttributeChange"
                  :value="company.domain"
                  :placeholder="'www.example.com'"
                  :label="
                    $t('home.companySelfSignupCompanyForm.fields.website')
                  "
                  :is-link="true"
                />
              </div>
            </div>
          </div>
        </template>
        <div class="mt-6 flex justify-center items-center">
          <Button
            v-if="isLastStep"
            :on-click-handler="onSkipAndComplete"
            :color="'gray'"
            class="mr-2 btn-skip"
            :loading="!isAddressDataPresent && loading"
          >
            {{ $t("home.companySelfSignupCompanyForm.skipAndComplete") }}
          </Button>
          <Button
            v-focus
            :on-click-handler="onNext"
            :full="!isLastStep"
            :class="{ 'btn-save': isLastStep }"
            :loading="isNextBtnLoading"
          >
            {{
              isLastStep
                ? $t("shared.saveButton")
                : $t("home.companySelfSignupCompanyForm.next")
            }}
          </Button>
        </div>
      </div>
      <div class="dots">
        <ul>
          <li :class="{ active: isFirstStep }" />
          <li :class="{ active: isSecondStep }" />
          <li :class="{ active: isThirdStep }" />
          <li :class="{ active: isLastStep }" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Input, FieldError } from "@/components/shared/index";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import Multiselect from "@vueform/multiselect";
import { errorMixin } from "@/components/mixins";
import countries from "@/assets/data/countries.json";
import { mask } from "vue-the-mask";
import { mapActions, mapMutations, mapState } from "vuex";
import { AUTH_MODULE, SET_USER } from "@/store/Auth/types";
import {
  DASHBOARD_MODULE,
  SAVE_SELF_SIGNUP_COMPANY_INFO,
} from "@/store/Dashboard/types";
import {
  getItemFromLocalStorage,
  isObjectEmpty,
} from "@/utils/utility_methods";
import legalForms from "@/assets/data/legalforms.json";

export default {
  components: {
    Button,
    Input,
    Multiselect,
    FieldError,
  },
  directives: { mask },
  mixins: [errorMixin],
  data() {
    return {
      v$: useValidate(),
      company: {},
      loading: false,
      step: "",
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),

    isAddressDataPresent() {
      return !!this.company.address?.name;
    },
    currentUser() {
      return !isObjectEmpty(this.user)
        ? this.user
        : getItemFromLocalStorage("user");
    },
    isFirstStep() {
      return this.step === "step1";
    },
    isSecondStep() {
      return this.step === "step2";
    },
    isThirdStep() {
      return this.step === "step3";
    },
    isLastStep() {
      const stepsMapping = this.stepsMapping();
      return Object.keys(stepsMapping).pop() === this.step;
    },
    isNextBtnLoading() {
      return (
        (!this.isLastStep && this.loading) ||
        (this.isAddressDataPresent && this.loading)
      );
    },
  },
  created() {
    const locale = window.localStorage.getItem("fusion_auth_locale");
    if (locale) this.$i18n.locale = locale;

    this.initializeCompany();
    this.navigateToStep();
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      saveSelfSignupCompanyInfo: SAVE_SELF_SIGNUP_COMPANY_INFO,
    }),
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    initializeCompany() {
      const { selfSignUp } = this.currentUser;
      this.company = {
        name: selfSignUp?.company?.name || "",
        employeesRange: selfSignUp?.employeesRange || "",
        contactNumber: selfSignUp?.contactNumber || "",
        industry: selfSignUp?.industry || "",
        interest: selfSignUp?.interest || "",
        address: selfSignUp?.address || { isDefault: true },
        legalForm: selfSignUp?.legalForm || "",
        domain: selfSignUp?.domain || "",
      };
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.company[name] = value;
    },
    onAddressAttributeChange(event) {
      const { name, value } = event.target;
      this.company.address[name] = value;
    },
    employeesRangeOptions() {
      return ["1-10", "10 - 100", "100 - 1.000", "1.000 - 10.000"];
    },
    industryOptions() {
      return [
        "Agriculture",
        "Apparel",
        "Banking",
        "Biotechnology",
        "Chemicals",
        "Communication",
        "Construction",
        "Consulting",
        "Education",
        "Electronics",
        "Energy",
        "Engineering",
        "Entertainment",
        "Environmental",
        "Finance",
        "Food & Beverage",
        "Governance",
        "Healthcare",
        "Hospitality",
        "Insurance",
        "Machinery",
        "Manufacturing",
        "Media",
        "Non for Profit",
        "Other",
        "Recreation",
        "Retail",
        "Shipping",
        "Technology",
        "Telecommunications",
        "Transportation",
        "Utilities",
      ];
    },
    interestOptions() {
      return ["Procurement", "Invoice Management", "Contract Management"];
    },
    stepsMapping() {
      return {
        step1: ["name", "employeesRange"],
        step2: ["contactNumber"],
        step3: ["industry", "interest"],
        step4: ["address", "legalForm", "domain"],
      };
    },
    navigateToStep() {
      let step;
      Object.entries(this.stepsMapping()).forEach(([key, value]) => {
        if (
          !step &&
          value.some((value) =>
            key !== "step4" ? !this.company[value] : !this.company[value].name
          )
        ) {
          step = key;
        }
      });

      this.step = step;
    },
    onNext() {
      const payload = {};
      this.v$.$validate();
      if (!this.v$.$error) {
        const stepsMapping = this.stepsMapping();
        const payloadFieldNames = stepsMapping[this.step];
        payloadFieldNames.forEach(
          (fieldName) => (payload[fieldName] = this.company[fieldName])
        );
        this.saveCompanyInfo(payload);
      }
    },
    onSkipAndComplete() {
      this.saveCompanyInfo({}, true);
    },
    saveCompanyInfo(payload, completed = false) {
      const formattedPayload = this.formatPayload(payload, completed);

      this.loading = true;
      this.saveSelfSignupCompanyInfo(formattedPayload)
        .then((response) => {
          const updatedUser = Object.assign({}, this.currentUser, {
            selfSignUp: response.isCompleted ? null : response,
          });
          this.setUser(updatedUser);
          !this.isLastStep && this.navigateToStep();
        })
        .finally(() => (this.loading = false));
    },
    sortedCountries() {
      return countries.slice(0).sort((a, b) => a.abbr.localeCompare(b.abbr));
    },
    formatPayload(payload, completed) {
      let updatedPayload = {};

      if (this.isFirstStep) {
        const { name, employeesRange } = payload;
        updatedPayload = { company: { name }, employeesRange };
      } else {
        updatedPayload = payload;
      }

      updatedPayload = Object.assign({}, updatedPayload, {
        id: this.currentUser.selfSignUp.id,
        isCompleted: completed || this.isLastStep,
      });

      return updatedPayload;
    },
    legalFormsOptions() {
      return legalForms;
    },
  },
  validations() {
    const payloadFieldNames = this.stepsMapping()[this.step];
    const validations = { company: {} };

    payloadFieldNames.forEach((fieldName) => {
      if (fieldName === "address") {
        validations.company[fieldName] = {
          street: { required },
          name: { required },
          houseNumber: { required },
          postalCode: { required },
          country: { required },
          city: { required },
        };
      } else if (fieldName === "contactNumber") {
        validations.company[fieldName] = {
          required,
          minContactNumberLength: (value) => value.length > 4,
        };
      } else {
        validations.company[fieldName] = { required };
      }
    });
    return validations;
  },
};
</script>

<style scoped>
.self-signup {
  @apply bg-gray-100 h-screen w-screen flex justify-center items-center flex-col;
  background-image: url(../../assets/images/bg-logo.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.page-title {
  @apply mb-[30px] tracking-wide font-light text-2xl;
}
.page-content {
  @apply w-[500px];
}

.page-content.wider {
  @apply w-[800px];
}

.step {
  @apply space-y-3;
}

.page-content.wider .step .row {
  @apply flex gap-x-4;
}

.btn-skip {
  @apply text-sm border-0;
}

.btn-save {
  @apply w-[200px];
}

.dots {
  @apply mt-6;
}

.dots ul {
  @apply flex gap-3 justify-center items-center;
}

.dots ul li {
  @apply w-[8px] h-[8px] bg-gray-300 rounded-full transition duration-500;
}
.dots ul li.active {
  @apply w-[40px] h-[8px] bg-primary rounded-full;
}
</style>
