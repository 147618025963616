<template>
  <div class="logo-holder">
    <router-link v-if="company.logo" :to="{ name: 'Dashboard' }">
      <img :src="company.logo" :alt="company.name" />
    </router-link>

    <div v-else class="company-initial">
      {{ company.name.charAt(0) }}
    </div>
  </div>
</template>
<script>
import { HomeIcon } from "@heroicons/vue/outline";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: [HomeIcon],
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
  },
};
</script>
<style scoped>
.menu-close {
  @apply ml-3 mt-3 mr-4 flex items-center justify-center rounded border-solid outline-none focus:outline-none absolute z-50 top-[2px] sm:hidden;
}
.logo-holder {
  @apply flex items-center  bg-white  justify-center border-r  w-[88px] mr-4 h-[64px];
}
.logo-holder a {
  @apply cursor-pointer;
}
.logo-holder a img {
  @apply h-10;
}

.company-initial {
  @apply flex place-content-center items-center h-10 w-10 mt-3 mb-3 rounded-md object-center object-cover bg-gray-200 text-center text-gray-700 font-semibold border border-gray-300 text-xl uppercase;
}
</style>
