<template>
  <div class="general-settings">
    <div class="general-settings__header">
      <div class="general-settings__header__left">
        <h3 class="card-title">
          {{ $t("companyManagement.profile.generalSettings") }}
        </h3>
        <span class="sub-title">
          {{ $t("companyManagement.profile.generalSettingsTooltip") }}
        </span>
      </div>
      <div>
        <div v-if="editMode">
          <Button
            class="button-small"
            :on-click-handler="resetValues"
            :color="'gray'"
            :disabled="loading"
          >
            {{ $t("companyManagement.profile.buttons.cancel") }}
          </Button>
          <Button
            :on-click-handler="onSave"
            class="button-small"
            :loading="loading"
          >
            {{ $t("companyManagement.profile.buttons.save") }}
          </Button>
        </div>
        <div v-else>
          <Button
            :on-click-handler="toggleEditMode"
            class="button-small"
            :loading="loading"
          >
            {{ $t("companyManagement.profile.buttons.edit") }}
          </Button>
        </div>
      </div>
    </div>
    <div class="profile-card-content">
      <div class="col-span-6 sm:col-span-4 mt-0">
        <div v-if="editMode">
          <CurrencySelect
            v-model="generalSettings.currency"
            :label="$t('general.defaultCurrency')"
          />

          <Input
            id="prefix"
            :error-text="error(v$.generalSettings.prefix)"
            class="block px-4 py-2"
            :value="generalSettings.prefix"
            name="prefix"
            :placeholder="$t('companyManagement.profile.prefix')"
            type="text"
            :label="$t('companyManagement.profile.prefix')"
            :on-value-change="
              ($event) => (generalSettings.prefix = $event.target.value)
            "
          />

          <div class="px-4 py-2">
            <label
              for="defaultUserBudget"
              class="block text-sm font-medium text-gray-700 my-2"
            >
              {{ $t("companyManagement.profile.defaultUserBudget") }}
            </label>
            <div class="flex justify-between items-start">
              <CurrencyInput
                id="default_user_budget"
                v-model.lazy="generalSettings.defaultUserBudget"
                class="w-2/3 mr-5"
                :error-text="error(v$.generalSettings.defaultUserBudget)"
              />
            </div>
          </div>

          <div class="px-4 py-2">
            <DateRangeCalendar
              v-model="budgetYearDate"
              :start-date-tooltip-text="
                $t('companyManagement.profile.defaultUserBudgetStartDate')
              "
              :end-date-tooltip-text="
                $t('companyManagement.profile.defaultUserBudgetEndDate')
              "
              start-date-before-today
            />
          </div>

          <div class="block px-4 py-2">
            <p class="text-sm font-medium text-gray-700 my-2">
              {{ $t("companyManagement.profile.dateFormat") }}
            </p>
            <Multiselect
              v-model="generalSettings.defaultDateFormat"
              :options="dateFormatOptions()"
              :track-by="'value'"
              :value-prop="'value'"
              :label="'label'"
              :can-clear="false"
            />
          </div>

          <div class="block px-4 py-2">
            <p class="text-sm font-medium text-gray-700 my-2">
              {{ $t("profile.language") }}
            </p>
            <Multiselect
              v-model="generalSettings.defaultLanguage"
              :options="languageOptions()"
              :track-by="'value'"
              :value-prop="'value'"
              :label="'label'"
              :can-clear="false"
            />
          </div>
        </div>

        <div v-else>
          <div class="content-row">
            <p v-if="selectedCurrency" class="content-row__label flex">
              {{ $t("companyManagement.profile.currencyText") }}
              <img
                :src="require('@/assets/images/' + selectedCurrency.flag)"
                :alt="company.currency"
                class="h-5 w-5 inline rounded-full mb-1 ml-1"
              />
              <span class="font-semibold ml-1 text-primary">
                {{ selectedCurrency.value }}.
              </span>
            </p>

            <p class="content-row__content">
              <i18n-t keypath="companyManagement.profile.currencySubText">
                <template #change>
                  <i18n-t
                    keypath="companyManagement.profile.currencyChange"
                    tag="p"
                  >
                    <template #edit>
                      <span
                        class="text-primary cursor-pointer"
                        @click="toggleEditMode()"
                      >
                        {{ $t("companyManagement.profile.currencyChangeEdit") }}
                      </span>
                    </template>
                  </i18n-t>
                </template>
              </i18n-t>
            </p>
          </div>
          <div class="content-row">
            <p class="content-row__label">
              {{ $t("companyManagement.profile.prefixSetTo") }}
              <span class="font-semibold ml-1 text-primary">
                {{ company.prefix }}</span
              >
            </p>
            <p class="content-row__content">
              {{ $t("companyManagement.profile.prefixDesc") }}
            </p>
          </div>

          <div class="content-row">
            <p class="content-row__label">
              {{ $t("companyManagement.profile.defaultUserBudgetSetTo") }}
              <span class="font-semibold ml-1 text-primary">
                <i18n-n
                  v-if="company.defaultUserBudget"
                  :value="parseFloat(company.defaultUserBudget)"
                  format="currency"
                  :locale="currencyToLocale(company.currency)"
                />
              </span>
            </p>
            <p class="content-row__content">
              {{ $t("companyManagement.profile.defaultUserBudgetDesc") }}
            </p>
          </div>

          <div class="content-row">
            <p class="content-row__label">
              {{
                $t("companyManagement.profile.defaultUserBudgetStartAndEndDate")
              }}
              <span class="font-semibold ml-1 text-primary">
                {{ budgetYearDateDisplay }}
              </span>
            </p>
            <p class="content-row__content">
              {{
                $t(
                  "companyManagement.profile.defaultUserBudgetStartAndEndDateDesc"
                )
              }}
            </p>
          </div>

          <div class="content-row">
            <p class="content-row__label">
              {{ $t("companyManagement.profile.defaultDateFormat") }}
              <span class="font-semibold ml-1 text-primary">
                {{ company.defaultDateFormat }}
              </span>
            </p>
            <p class="content-row__content">
              {{ $t("companyManagement.profile.defaultDateFormatDesc") }}
            </p>
          </div>

          <div class="content-row">
            <p class="content-row__label">
              {{ $t("companyManagement.profile.defaultLanguage") }}
              <span class="font-semibold ml-1 text-primary">
                {{ defaultLanguageValue }}
              </span>
            </p>
            <p class="content-row__content">
              {{ $t("companyManagement.profile.defaultLanguageDesc") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Input,
  CurrencyInput,
  DateRangeCalendar,
} from "@/components/shared/index";
import {
  alpha,
  maxLength,
  required,
  minValue,
  helpers,
} from "@vuelidate/validators";
import {
  currencyToLocale,
  formatDateInHyphenFormat,
} from "@/utils/utility_methods";
import { companyProfileMixin } from "@/components/mixins/index.js";
import Multiselect from "@vueform/multiselect";
import { CURRENCY_OPTIONS } from "@/utils/constants.js";
import CurrencySelect from "@/components/shared/CurrencySelect/index.vue";
export default {
  components: {
    DateRangeCalendar,
    Button,
    Input,
    CurrencyInput,
    Multiselect,
    CurrencySelect,
  },
  mixins: [companyProfileMixin],
  data() {
    return {
      generalSettings: this.initializeGeneralSettings(),
      budgetYearDate: {
        start: this.company.budgetValidFrom,
        end: this.company.budgetValidTo,
      },
    };
  },
  computed: {
    currencyOptions() {
      return CURRENCY_OPTIONS;
    },
    selectedCurrency() {
      return this.currencyOptions.find(
        (curr) => curr.label === this.generalSettings.currency
      );
    },
    budgetYearDateDisplay() {
      return `${this.formattedDate(
        this.company.budgetValidFrom
      )} - ${this.formattedDate(this.company.budgetValidTo)}`;
    },
    defaultLanguageValue() {
      return this.languageOptions().find(
        (lang) => lang.value === this.generalSettings.defaultLanguage
      )?.label;
    },
  },
  watch: {
    budgetYearDate: {
      handler(val) {
        this.generalSettings = {
          ...this.generalSettings,
          budgetValidFrom: val.start,
          budgetValidTo: val.end,
        };
      },
      deep: true,
    },
  },
  methods: {
    initializeGeneralSettings() {
      const {
        id,
        prefix,
        defaultUserBudget,
        currency,
        defaultDateFormat,
        budgetValidFrom,
        budgetValidTo,
        defaultLanguage,
      } = this.company;
      return Object.assign(
        {},
        {
          id,
          prefix,
          defaultUserBudget,
          currency,
          defaultDateFormat,
          budgetValidFrom,
          budgetValidTo,
          defaultLanguage,
        }
      );
    },
    resetValues() {
      this.generalSettings = this.initializeGeneralSettings();
      this.toggleEditMode();
    },
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const {
          prefix,
          defaultUserBudget,
          defaultDateFormat,
          budgetValidFrom,
          budgetValidTo,
          defaultLanguage,
        } = this.generalSettings;
        const currencyVal = this.selectedCurrency.value;
        const payload = {
          payload: {
            id: this.generalSettings.id,
            currency: currencyVal,
            prefix,
            defaultUserBudget,
            defaultDateFormat,
            budgetValidFrom: formatDateInHyphenFormat(budgetValidFrom),
            budgetValidTo: formatDateInHyphenFormat(budgetValidTo),
            defaultLanguage,
          },
        };
        this.updateCompanyData(payload, "Settings have been updated");
      }
    },
    onCurrencyChange(val) {
      this.generalSettings.currency = val;
    },
    onDefaultUserBudgetChange(event) {
      this.generalSettings.defaultUserBudget = parseFloat(event.target.value);
      if (this.generalSettings.defaultUserBudget == null)
        this.generalSettings.defaultUserBudget = 0;
    },
    dateFormatOptions() {
      return [
        {
          label: "German (dd.MM.yyyy)",
          value: "dd.MM.yyyy",
        },
        {
          label: "International (yyyy-MM-dd)",
          value: "yyyy-MM-dd",
        },
        {
          label: "US (MM/dd/yyyy)",
          value: "MM/dd/yyyy",
        },
      ];
    },
    currencyToLocale,
    languageOptions() {
      return [
        { label: this.$t("global.language.en"), value: "EN", flag: "flag-us.png" },
        { label: this.$t("global.language.de"), value: "DE", flag: "flag-de.png" },
        { label: this.$t("global.language.es"), value: "ES", flag: "flag-es.png" },
      ];
    },
    onLanguageChange(label) {
      this.generalSettings.defaultLanguage = this.languageOptions().find(
        (lang) => lang.label === label
      ).value;
    },
  },
  validations() {
    return {
      generalSettings: {
        prefix: { required, maxLength: maxLength(3), alpha },
        defaultUserBudget: {
          required: helpers.withMessage("Value missing", required),
          minValue: helpers.withMessage("Value missing", minValue(0)),
        },
      },
    };
  },
};
</script>

<style scoped>
.button-small {
  @apply border-0 py-1 px-2 bg-transparent text-sm text-gray-800 hover:text-white  hover:bg-primary ml-3;
}

.general-settings__header {
  @apply flex justify-between border-b border-gray-200 pb-4;
}

.general-settings__header__left {
  @apply flex flex-col;
}
.general-settings__header__left .card-title {
  @apply font-bold text-primary mb-1 text-sm;
}
.general-settings__header__left .sub-title {
  @apply text-sm text-gray-500;
}

.content-row {
  @apply flex flex-col border-b border-gray-200 py-4 last:border-b-0;
}

.content-row__label {
  @apply text-sm font-semibold text-gray-800 mb-2;
}
.content-row__content {
  @apply text-sm text-gray-500 flex-1;
}
</style>
