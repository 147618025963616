<template>
  <table class="table table-auto no-border">
    <thead class="table-header">
      <tr>
        <th>
          <StatusTag
            v-if="ipo?.status"
            :status="ipo?.status"
            type="invoice"
            size="small"
          />
        </th>
        <th v-if="!isObjectEmpty(ipo)" scope="col">
          <span class="flex gap-x-1">
            <span> {{ $t("global.purchaseOrder") }}</span>

            <Tip>
              <template #placeholder>
                <InformationCircleIcon class="h-4 w-4 text-hivebuy_plum" />
              </template>
              <template #content>
                <div class="p-2 text-sm font-normal">
                  <StatusTag
                    :status="ipo?.purchaseOrder.status"
                    type="order"
                    :show-tool-tip="false"
                  />
                </div>
              </template>
            </Tip>
          </span>
          <div class="flex">
            <p
              class="text-xs font-normal text-gray-600 hover:text-primary cursor-pointer"
              @click="goToPoDetails"
            >
              {{ purchaseRequest?.externalId }}
            </p>

            <div v-if="showNav" class="flex justify-center items-center">
              <div
                v-for="invoiceObject in invoice.invoicePurchaseOrders"
                :key="invoiceObject.id"
                :class="['nav-div', { selected: ipo.id === invoiceObject.id }]"
                @click="ipoId = invoiceObject.id"
              ></div>
            </div>
          </div>
        </th>
        <th scope="col">
          <span class="flex gap-x-1">
            <span>{{ $t("global.invoice.title") }}</span>

            <Tip v-if="invoice.invoicePurchaseOrders.length > 1">
              <template #placeholder>
                <InformationCircleIcon class="h-4 w-4 text-hivebuy_plum" />
              </template>
              <template #content>
                <div class="text-sm font-normal">
                  <div v-if="invoice.purchaseOrders.length > 1" class="p-3">
                    This Invoice is against multiple purchase orders
                  </div>
                </div>
              </template>
            </Tip>
          </span>
          <p class="text-xs font-normal text-gray-600">
            {{ invoice.invoiceNumber }}
          </p>
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.net") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">
          <i18n-n
            v-if="ipo?.purchaseOrder.netAmount"
            :value="parseFloat(ipo?.purchaseOrder.netAmount)"
            format="currency"
            :locale="currencyToLocale(ipo?.purchaseOrder.netAmountCurrency)"
          />
        </td>
        <td class="w-2/5">
          <i18n-n
            :value="parseFloat(invoice.totalNetAmount)"
            format="currency"
            :locale="currencyToLocale(invoice.totalNetAmountCurrency)"
          />
        </td>
      </tr>
      <tr>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.tax") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">
          <i18n-n
            v-if="ipo?.purchaseOrder.tax"
            :value="parseFloat(ipo?.purchaseOrder.tax)"
            format="currency"
            :locale="currencyToLocale(ipo?.purchaseOrder.taxCurrency)"
          />
        </td>
        <td>
          <span class="font-semibold">
            <i18n-n
              :value="parseFloat(invoice.tax)"
              format="currency"
              :locale="currencyToLocale(invoice.totalNetAmountCurrency)"
            />
          </span>
          <span
            class="mx-2 text-sm cursor-pointer hover:text-primary px-2 py-0.5 border border-gray-300 rounded"
            @click="showTaxDetails = !showTaxDetails"
            >Details</span
          >
          <CollapseTransition>
            <div
              v-if="showTaxDetails"
              class="bg-gray-100 rounded border-gray-300 border px-1 py-2 pl-2 mt-2"
            >
              <div v-for="tax in invoice.taxes" :key="tax">
                <ul>
                  <li v-if="isFeatureAllowed('invoicePostings')">
                    <span>{{ $t("global.pricing.taxNet") }}:</span>
                    <span class="font-semibold ml-1">
                      <i18n-n
                        :value="parseFloat(tax.taxNet)"
                        format="currency"
                        :locale="currencyToLocale(tax.taxCurrency)"
                      />
                    </span>
                  </li>
                  <li>
                    <span>{{ $t("global.pricing.rate") }}:</span>
                    <span class="font-semibold ml-1">
                      <i18n-n
                        :value="parseFloat(tax.taxRate / 100)"
                        format="percent0"
                        :locale="currencyToLocale(tax.taxCurrency)"
                      />
                    </span>
                  </li>
                  <li>
                    <span>{{ $t("global.pricing.tax") }}:</span>
                    <span class="font-semibold ml-1">
                      <i18n-n
                        :value="parseFloat(tax.tax)"
                        format="currency"
                        :locale="currencyToLocale(tax.taxCurrency)"
                      />
                    </span>
                  </li>
                  <ul
                    v-if="isFeatureAllowed('invoicePostings')"
                    class="list-disc pl-5"
                  >
                    <li>{{ tax.taxCountry }}</li>
                    <li>{{ tax.taxType }}</li>
                  </ul>
                </ul>
              </div>
            </div>
          </CollapseTransition>
        </td>
      </tr>
      <tr>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.gross") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">
          <i18n-n
            v-if="ipo?.purchaseOrder?.grossAmount"
            :value="parseFloat(ipo?.purchaseOrder.grossAmount)"
            format="currency"
            :locale="currencyToLocale(ipo?.purchaseOrder?.grossAmountCurrency)"
          />
        </td>
        <td>
          <i18n-n
            :value="parseFloat(invoice.totalGrossAmount)"
            format="currency"
            :locale="currencyToLocale(invoice.totalNetAmountCurrency)"
          />
        </td>
      </tr>
      <tr v-if="displayShippingCost">
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.shippingNet") }}
        </th>
        <td>
          <i18n-n
            v-if="invoice.shippingNet"
            :value="parseFloat(invoice.shippingNet)"
            format="currency"
            :locale="currencyToLocale(invoice.shippingNetCurrency)"
          />
          <span v-else>-</span>
        </td>
      </tr>
      <tr v-if="displayShippingCost">
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.shippingGross") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">
          <i18n-n
            v-if="ipo?.purchaseOrder?.shipping"
            :value="parseFloat(ipo?.purchaseOrder.shipping)"
            format="currency"
            :locale="currencyToLocale(ipo?.purchaseOrder?.shippingCurrency)"
          />
        </td>
        <td>
          <i18n-n
            :value="parseFloat(invoice.shippingGross)"
            format="currency"
            :locale="currencyToLocale(invoice.shippingGrossCurrency)"
          />
        </td>
      </tr>
      <tr v-if="invoice.discount > 0">
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.discount") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">-</td>
        <td>
          <i18n-n
            :value="parseFloat(invoice.discount)"
            format="currency"
            :locale="currencyToLocale(invoice.discountCurrency)"
          />
        </td>
      </tr>
      <tr>
        <th
          scope="col"
          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          {{ $t("global.pricing.total") }}
        </th>
        <td v-if="!isObjectEmpty(ipo)">
          <i18n-n
            v-if="ipo?.purchaseOrder?.totalAmount"
            :value="parseFloat(ipo?.purchaseOrder.totalAmount)"
            format="currency"
            :locale="currencyToLocale(ipo?.purchaseOrder?.totalAmountCurrency)"
          />
        </td>
        <td>
          <i18n-n
            :value="parseFloat(invoice.totalAmount)"
            format="currency"
            :locale="currencyToLocale(invoice.totalAmountCurrency)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";
import StatusTag from "@/components/shared/StatusTag";
import { currencyToLocale, isObjectEmpty } from "@/utils/utility_methods.js";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
export default {
  components: { InformationCircleIcon, StatusTag, CollapseTransition },
  props: {
    invoice: { type: Object, required: true },
    showNav: { type: Boolean, default: false },
    selectedIpo: { type: String, default: "" },
  },
  data() {
    return {
      showTaxDetails: false,
    };
  },
  computed: {
    ipo() {
      return this.invoice.invoicePurchaseOrders.find(
        (ipo) => ipo.id === this.ipoId
      );
    },
    ipoId() {
      return (
        this.selectedIpo || this.invoice?.invoicePurchaseOrders[0]?.id || null
      );
    },
    purchaseRequest() {
      return this.ipo?.purchaseOrder?.purchaseRequest;
    },
    displayShippingCost() {
      return this.invoice.shippingNet > 0 || this.invoice.shippingGross > 0;
    },
  },
  methods: {
    currencyToLocale,
    isObjectEmpty,
    goToPoDetails() {
      this.$router.push({
        name: "RequestDetail",
        params: { id: this?.ipo?.purchaseOrder?.purchaseRequest?.id },
      });
    },
  },
};
</script>
<style scoped>
.nav-div {
  @apply bg-gray-100 h-3 w-3 rounded-full ml-1 cursor-pointer border border-primary transition-colors duration-300;
}
.nav-div.selected {
  @apply bg-primary;
}
</style>
