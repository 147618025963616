<template>
  <div>
    <div v-if="unlistedSupplier" class="flex gap-x-2 items-center">
      {{ unlistedSupplier }}
      <InformationPopover>
        <span
          class="flex items-center px-2.5 rounded-full text-sm font-medium bg-gray-50 text-gray-500 cursor-pointer group border border-gray-200"
        >
          unlisted
          <QuestionMarkCircleIcon
            class="ml-1.5 -mr-0.5 h-4 w-4 text-gray-400 group-hover:text-hivebuy-yellow"
          />
        </span>
        <template #header>
          {{ $t("companyManagement.companySupplier.unlistedHeader") }}
        </template>
        <template #body>
          <div v-if="reason" class="flex justify-center text-base font-medium">
            {{ reason }}
          </div>
          <div class="whitespace-pre-wrap font-light text-xs mt-2">
            {{ $t("companyManagement.companySupplier.unlistedText") }}
          </div>
        </template>
      </InformationPopover>
    </div>
    <div v-if="skipped">
      <InformationPopover>
        <span
          class="flex items-center px-2.5 rounded-full text-sm font-medium bg-gray-50 text-gray-500 group cursor-pointer border border-gray-200"
        >
          {{ $t("companyManagement.companySupplier.noCompanySupplier") }}
          <QuestionMarkCircleIcon
            class="ml-1.5 -mr-0.5 h-4 w-4 text-gray-400 group-hover:text-hivebuy-red"
          />
        </span>
        <template #header>
          {{ $t("companyManagement.companySupplier.noCompanySupplierHeader") }}
        </template>
        <template #body>
          <div class="whitespace-pre-wrap text-gray-500 font-light">
            {{ $t("companyManagement.companySupplier.noCompanySupplierText") }}
          </div>
        </template>
      </InformationPopover>
    </div>
  </div>
</template>

<script>
import InformationPopover from "@/components/shared/InformationPopover";
import { QuestionMarkCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    InformationPopover,
    QuestionMarkCircleIcon,
  },
  props: {
    skipped: {
      type: Boolean,
      default: false,
    },
    unlistedSupplier: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
