<template>
  <ul
    role="list"
    class="divide-y divide-gray-200 border border-gray-200 rounded-md"
  >
    <li
      v-for="notification in notifications"
      :key="notification.name"
      :class="[
        'py-6 px-4 flex notifications-center',
        { 'bg-blue-50': !notification.read },
      ]"
      @click="() => notificationAction(notification)"
    >
      <component
        :is="getNotificationComponent(notification.type)"
        class="text-gray-400"
        aria-hidden="true"
        :notification="notification"
      />
    </li>
  </ul>
</template>
<script>
import { notificationComponent } from "@/utils/helpers/notificationsHelper.js";
export default {
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    notificationAction(notification) {
      notification.read = true;
      this.$router.push({
        path: this.getNotficationPath(notification.notificationFor),
      });
    },
    getNotficationPath(notificationFor) {
      const path = {
        purchaseRequest: "/requests/",
      }[notificationFor.type];
      return `${path}${notificationFor.object.id}`;
    },
    getNotificationComponent(type) {
      return notificationComponent(type);
    },
  },
};
</script>
