<template>
  <section class="relative" aria-labelledby="contactHeading">
    <h2 id="changePassword" class="sr-only card-title">
      {{ $t("profile.password.title") }}
    </h2>

    <div class="header-wrapper">
      <h3 class="card-title">
        {{ $t("profile.password.title") }}
      </h3>
    </div>
    <div class="content-wrapper">
      <div class="input-wrapper">
        <Input
          :id="'currentPassword'"
          :error-text="error(v$.currentPassword)"
          :name="'currentPassword'"
          :on-value-change="(event) => (currentPassword = event.target.value)"
          :type="'password'"
          :placeholder="$t('profile.password.current')"
          :label="$t('profile.password.current')"
          :value="currentPassword"
        />
      </div>
      <div class="input-wrapper">
        <Input
          :id="'newPassword'"
          :error-text="error(v$.newPassword)"
          :name="'newPassword'"
          :on-value-change="(event) => (newPassword = event.target.value)"
          :type="'password'"
          :placeholder="$t('profile.password.new')"
          :label="$t('profile.password.new')"
          :value="newPassword"
        />
      </div>
      <div class="input-wrapper">
        <Input
          :id="'confirmNewPassword'"
          :error-text="error(v$.confirmNewPassword)"
          :name="'confirmNewPassword'"
          :on-value-change="
            (event) => (confirmNewPassword = event.target.value)
          "
          :type="'password'"
          :placeholder="$t('profile.password.confirm')"
          :label="$t('profile.password.confirm')"
          :value="confirmNewPassword"
        />
      </div>
      <div class="input-wrapper mt-8">
        <Button :on-click-handler="updatePassword" :loading="loading">
          {{ $t("profile.password.change") }}
        </Button>
      </div>
    </div>
  </section>
  <Dialog
    v-if="showDialog"
    :show-dialog="showDialog"
    :close-dialog="closeDialog"
    :dialog-title="dialogTitle"
    :dialog-body-text="dialogBodyText"
  />
</template>

<script>
import { validatePasswordRegex } from "@/utils/utility_methods";
import { mapActions } from "vuex";
import { AUTH_MODULE, UPDATE_USER_PASSWORD } from "@/store/Auth/types";
import { Button, Input } from "@/components/shared";
import { required, sameAs } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {
  errorMixin,
  dialogMixin,
  translateMixin,
} from "@/components/mixins/index.js";

export default {
  components: {
    Input,
    Button,
  },
  mixins: [errorMixin, dialogMixin, translateMixin],
  props: {},
  data() {
    return {
      v$: useValidate(),
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      updateUserPassword: UPDATE_USER_PASSWORD,
    }),
    updatePassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const payload = { password: this.newPassword };
        this.loading = true;
        this.updateUserPassword(payload)
          .then(() => {
            this.showNotification("User password has been updated");
            this.$router.push({ name: "UserProfile" });
          })
          .catch(() => {
            this.dialogTitle = this.$t("auth.errors.genericError");
            this.dialogBodyText = this.$t("auth.errors.invalid_password");
            this.showDialog = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  validations() {
    return {
      currentPassword: { required },
      newPassword: {
        required,
        goodPassword: (password) => validatePasswordRegex(password),
      },
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },
};
</script>

<style scoped>
.header-wrapper {
  @apply p-4 flex-1 border-b border-gray-200 flex justify-between items-center;
}

.card-title {
  @apply text-sm text-primary font-semibold;
}
.card-sub-title {
  @apply text-sm text-gray-500;
}

.content-wrapper {
  @apply p-5 gap-y-2;
}

.input-wrapper {
  @apply transition duration-500 ease-in-out lg:max-w-[60%] mb-3;
}
</style>
