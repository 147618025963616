export default {
  methods: {
    calcNetPriceFromProduct(product) {
      let netPrice;

      if (product.netGross === "gross") {
        netPrice = parseFloat(product.price) / (1 + product.vat / 100);
      } else {
        netPrice = parseFloat(product.price);
      }

      return parseFloat(netPrice) || 0;
    },

    calcGrossPriceFromProduct(product) {
      let grossPrice;

      if (product.netGross === "net") {
        grossPrice = parseFloat(product.price) * (1 + product.vat / 100);
      } else {
        grossPrice = parseFloat(product.price);
      }

      return parseFloat(grossPrice) || 0;
    },

    priceFloatWith2Decimals(price) {
      return parseFloat(price).toFixed(2);
    },

    calcVAT(gross, net) {
      return parseFloat(gross) - parseFloat(net);
    },

    calcTotalNet(products) {
      let price = 0;
      products.forEach((product) => {
        price += parseFloat(product.priceNet * product.quantity);
      });

      return price;
    },

    calcTotalGross(products) {
      let price = 0;
      products.forEach((product) => {
        price += parseFloat(product.priceGross * product.quantity);
      });

      return price;
    },
  },
};
