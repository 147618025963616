<template>
  <span id="load-more-text" @click="loadMore">
    {{ $t("button.loadMore") }}
    <SpinningLoaderSVG v-if="loadMoreBtnLoading" :classes="'w-3 h-3 ml-1'" />
    <ChevronDownIcon v-else class="h-5 w-5 text-primary ml-1" />
  </span>
</template>

<script>
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "LoadMoreSection",
  components: {
    SpinningLoaderSVG,
    ChevronDownIcon,
  },
  props: {
    loadMore: {
      type: Function,
      required: true,
    },
    loadMoreBtnLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
@import "../../assets/styles/load-more-pagination.css";
</style>
