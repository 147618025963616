<template>
  <div
    v-if="
      purchaseOrder?.purchaseOrderInvoices?.length ||
      !isItemLevelApprovalAllowed
    "
    class="custom-card no-shadow flex flex-col mt-2"
  >
    <h2 class="section-title">
      {{ $t("purchaseRequest.summary.purchaseOrderStatus.header") }}
    </h2>

    <div v-if="!isItemLevelApprovalAllowed">
      <div
        v-if="purchaseOrder.status != PURCHASE_ORDER_STATUS_MAPPING.CANCELED"
      >
        <OrderDetail
          v-for="(orderDetail, index) in orderDetails"
          :key="index"
          v-bind="orderDetail"
        />
      </div>
      <div v-else class="text-sm">
        <div>
          <div class="text-gray-500 font-medium mb-4">
            Order was canceled by:
          </div>
          <UserWithAvatar :user="poSimpleStatusObject.canceledBy" show-name />
        </div>
        <div class="mt-2 text-sm mb-1 text-gray-500">
          At {{ formattedDate(poSimpleStatusObject.canceledAt) }}
          {{ poSimpleStatusObject.canceledBy?.firstName }} wrote:
        </div>
        <div class="border border-gray-200 p-2 text-sm whitespace-pre">
          {{ poSimpleStatusObject.canceledReason }}
        </div>
      </div>
    </div>
    <PoInvoices
      v-if="purchaseOrder?.purchaseOrderInvoices?.length"
      :invoices="invoiceList"
      :purchase-order-id="purchaseOrder.id"
      :purchase-request="purchaseRequest"
    />
  </div>
  <OrderModal
    :show-modal="showOrderModal"
    :close-modal="onOrderModalClose"
    :purchase-order="purchaseOrder"
    :fetch-request="fetchPurchaseRequest"
  />
  <DeliverModal
    :show-modal="showDeliverModal"
    :close-modal="onDeliverModalClose"
    :purchase-order="purchaseOrder"
    :fetch-request="fetchPurchaseRequest"
  />
</template>

<script>
import { purchaseOrderMixin } from "@/components/mixins/index.js";
import { UserWithAvatar } from "@/components/shared/index";
import { formattedTime } from "@/utils/utility_methods";
import { PURCHASE_ORDER_STATUS_MAPPING } from "@/utils/constants";
import PoInvoices from "@/components/PurchaseRequest/SummarySections/poInvoices.vue";
import poStatusMixin from "@/components/mixins/poStatusMixin.js";
import OrderDetail from "@/components/PurchaseRequest/OrderDetail";

export default {
  components: {
    OrderDetail,
    UserWithAvatar,
    PoInvoices,
  },
  mixins: [purchaseOrderMixin, poStatusMixin],
  props: {
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
    purchaseRequest: {
      type: Object,
      required: true,
    },
    fetchPurchaseRequest: {
      type: Function,
      optional: true,
      default: null,
    },
    toggleInvoiceModal: { type: Function, default: () => {} },
  },
  data() {
    return {
      orderDetails: [],
      poSimpleStatusObject: {},
    };
  },
  computed: {
    invoiceList() {
      if (!this.purchaseOrder?.purchaseOrderInvoices) return [];

      return this.purchaseOrder.purchaseOrderInvoices.map(
        (poInvoice) => poInvoice.invoice
      );
    },
  },
  watch: {
    purchaseOrder: {
      async handler() {
        await this.populateOrderDetails();
        await this.populateSimpleStatusObject();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.PURCHASE_ORDER_STATUS_MAPPING = PURCHASE_ORDER_STATUS_MAPPING;
  },
  methods: {
    formattedTime,
    async populateOrderDetails() {
      const { ordered, delivered, invoiced } = await this.poStatusDetails(
        this.purchaseOrder,
        this.$t
      );
      const autoProcessing = this.purchaseRequest.items[0].autoProcessing;
      ordered["on-detail-click"] = this.onMarkAsOrdered;
      ordered["autoProcessing"] = autoProcessing;
      ordered["disabled"] = autoProcessing;

      delivered["on-detail-click"] = this.onMarkAsDelivered;
      delivered["disabled"] = !ordered.status;

      invoiced["on-detail-click"] = () => this.toggleInvoiceModal(true);
      invoiced["file"] = this.purchaseOrder.invoiceFile;
      invoiced["disabled"] = !ordered.status;
      invoiced["showFiles"] = false;
      this.orderDetails = [ordered, delivered, invoiced];
    },
    async populateSimpleStatusObject() {
      this.poSimpleStatusObject = await this.posSimpleStatus(
        this.purchaseOrder
      );
    },
  },
};
</script>
