<template>
  <SquashedList
    :list="formattedList"
    display-attribute="name"
    type="legalEntity"
  />
</template>
<script>
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";
import { SquashedList } from "@/components/shared";

export default {
  components: {
    SquashedList,
  },
  props: {
    legalTendency: {
      type: [Object, String],
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyDetails"]),
    formattedList() {
      const result = this.list.length ? this.list : [this.legalTendency];

      return result.map((legalEntity) => {
        const id = legalEntity?.id || legalEntity;
        return this.legalTendencyDetails(id);
      });
    },
  },
};
</script>
