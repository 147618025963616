import { createStore } from "vuex";

import AUTH from "@/store/Auth/module";
import { AUTH_MODULE } from "@/store/Auth/types";
import DASHBOARD from "@/store/Dashboard/module";
import { DASHBOARD_MODULE } from "@/store/Dashboard/types";
import FEATURE from "@/store/Feature/module";
import { FEATURE_MODULE } from "@/store/Feature/types";
import CATALOGUE from "@/store/Catalogue/module";
import { CATALOGUE_MODULE } from "@/store/Catalogue/types";
import DOWNLOAD from "@/store/Downloads/module";
import { DOWNLOAD_MODULE } from "@/store/Downloads/types";
import PROJECT from "@/store/Project/module";
import { PROJECT_MODULE } from "@/store/Project/types";
import PURCHASE_ORDER from "@/store/PurchaseOrder/module";
import { PURCHASE_ORDER_MODULE } from "@/store/PurchaseOrder/types";
import PROPOSAL from "@/store/Proposal/module";
import { PROPOSAL_MODULE } from "@/store/Proposal/types";
import INVOICE from "@/store/Invoice/module";
import { INVOICE_MODULE } from "@/store/Invoice/types";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import PURCHASE_REQUEST from "@/store/PurchaseRequest/module.js";
import { CONTRACT_MODULE } from "@/store/Contract/types";
import CONTRACT from "@/store/Contract/module.js";
import { LOADING_MODULE } from "@/store/Loading/types";
import LOADING from "@/store/Loading/module.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import COMPANY_MANAGEMENT from "@/store/CompanyManagement/module";
import { SHOPPING_LIST_MODULE } from "@/store/ShoppingList/types";
import SHOPPING_LIST from "@/store/ShoppingList/module";

export default createStore({
  modules: {
    [AUTH_MODULE]: AUTH,
    [CATALOGUE_MODULE]: CATALOGUE,
    [COMPANY_MANAGEMENT_MODULE]: COMPANY_MANAGEMENT,
    [DOWNLOAD_MODULE]: DOWNLOAD,
    [FEATURE_MODULE]: FEATURE,
    [DASHBOARD_MODULE]: DASHBOARD,
    [INVOICE_MODULE]: INVOICE,
    [LOADING_MODULE]: LOADING,
    [PURCHASE_ORDER_MODULE]: PURCHASE_ORDER,
    [PROJECT_MODULE]: PROJECT,
    [PROPOSAL_MODULE]: PROPOSAL,
    [PURCHASE_REQUEST_MODULE]: PURCHASE_REQUEST,
    [SHOPPING_LIST_MODULE]: SHOPPING_LIST,
    [CONTRACT_MODULE]: CONTRACT,
  },
});
