<template>
  <div>
    <div
      class="grid sm:grid-cols-2 grid-cols-1 custom-card no-shadow lg:grid-cols-4 sm:gap-4"
    >
      <div :class="showAddress ? '' : 'col-span-2'">
        <PaymentMethod
          v-model="item.paymentMethod"
          is-edit
          :requested-for-id="item.requestedForId"
          :is-invalid="!!errorMessage('paymentMethod').length"
          :error-text="errorMessage('paymentMethod')"
          :can-clear="false"
        />
      </div>

      <Addresses v-if="showAddress" v-model="item" :v="v" />
      <!-- Need By Date -->
      <div :class="showAddress ? '' : 'col-span-2'">
        <label class="block text-sm font-medium text-gray-500 my-2">
          {{ $t("purchaseRequest.detail.needByDate") }}
        </label>
        <DateCalendar
          v-model="item.needByDate"
          :field-name="$t('purchaseRequest.detail.needByDate')"
          name="needByDate"
          visibility="focus"
          :classes="'block w-full px-6 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { DateCalendar } from "@/components/shared";
import { CURRENCY } from "@/utils/constants";
import PaymentMethod from "@/components/shared/PaymentMethod";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import Addresses from "@/components/PurchaseRequest/Form/addresses.vue";
export default {
  components: {
    DateCalendar,
    PaymentMethod,
    Addresses,
  },
  mixins: [newRequestErrorMixin],
  props: {
    defaultProduct: { type: Object, default: null },
    modelValue: {
      type: Object,
      default: () => {},
    },
    showAddress: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    getCurrencyValue(currency) {
      const currencyValue = currency || "EUR";
      return CURRENCY.find((element) => element.value == currencyValue);
    },
    // onShippingValueChange(event) {
    //   const { name, value } = event.target;
    //   let shipping = value;
    //   if (!value || value < 0) {
    //     shipping = 0;
    //   }
    //   this.changeHandler({ target: { name, value: shipping } });
    // },
  },
};
</script>
