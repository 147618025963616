<template>
  <div v-if="isBudgetLoading" class="flex items-center">
    <HivebuyLoader class="w-10 h-10" />
  </div>
  <div v-else :class="{ 'custom-card no-shadow mt-4 mb-4': showCardSection }">
    <div class="grid grid-cols-12 gap-4 mt-4">
      <div class="col-span-12 no-shadow">
        <div class="section-title">
          {{ $t("companyManagement.companySupplier.supplierBudget.title") }}
        </div>
        <div class="block xl:flex items-center justify-between">
          <div class="flex items-center xl:mb-0 mb-1">
            <span><CashIcon class="w-6 h-6 text-hivebuy-green mr-3" /> </span>
            <span class="text-sm font-medium">
              {{ $t("companyManagement.companySupplier.supplierBudget.total") }}
              <i18n-n
                :value="parseFloat(budget.amount)"
                format="currency"
                :locale="currencyToLocale(company.currency)"
              />
            </span>
          </div>
          <div class="block xl:flex items-center">
            <div class="font-medium text-sm flex items-center xl:mb-0 mb-1">
              <span class="text-primary mx-1">
                {{
                  $t("companyManagement.companySupplier.supplierBudget.used")
                }}
              </span>
              <span class="font-bold">
                <i18n-n
                  :value="parseFloat(budget.usedBudget)"
                  format="currency"
                  :locale="currencyToLocale(company.currency)"
                />
              </span>
            </div>
            <div
              class="font-medium text-sm ml-0 xl:ml-3 xl:mb-0 mb-2 flex items-center"
            >
              <span class="text-primary mx-1">
                {{
                  $t(
                    "companyManagement.companySupplier.supplierBudget.remaining"
                  )
                }}
              </span>
              <span
                :class="[
                  'font-bold',
                  {
                    'text-hivebuy-red': parseFloat(budget.remainingBudget) < 0,
                  },
                ]"
              >
                <i18n-n
                  :value="parseFloat(budget.remainingBudget)"
                  format="currency"
                  :locale="currencyToLocale(company.currency)"
                />
              </span>
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="budget.remainingBudget >= 0"
            class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
          >
            <div
              v-if="supplierBudgetBar.usedPercentage"
              class="progress-bar progress-bar-approved"
              :style="{ width: `${supplierBudgetBar.usedPercentage}%` }"
            >
              <i18n-n
                :value="supplierBudgetBar.usedPercentage / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
            <div
              v-if="supplierBudgetBar.remainingPercentage"
              class="flex-grow text-center text-xs font-medium"
            >
              <i18n-n
                :value="supplierBudgetBar.remainingPercentage / 100"
                format="percent"
                :locale="currencyToLocale(currency)"
              />
            </div>
          </div>
          <div
            v-else
            class="progress flex items-center mt-6 md:mt-0 w-full mb-4 text-center"
          >
            <div class="progress-bar progress-bar-overused !w-full">> 100%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CashIcon } from "@heroicons/vue/outline";
import { currencyToLocale } from "@/utils/utility_methods";
import { mapState, mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  FETCH_COMPANY_SUPPLIER,
} from "@/store/CompanyManagement/types";
import { HivebuyLoader } from "@/components/shared/index";
import { isObjectEmpty } from "../../../utils/utility_methods";

export default {
  name: "SupplierBudget",
  components: {
    CashIcon,
    HivebuyLoader,
  },
  props: {
    currency: {
      type: String,
      default: null,
    },
    supplier: {
      type: String,
      required: true,
    },
    showCardSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      budget: {},
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    supplierBudgetBar() {
      const { usedBudget, amount } = this.budget;
      const usedPercentage = (usedBudget / amount) * 100;
      const remainingPercentage = 100 - usedPercentage;

      return { usedPercentage, remainingPercentage };
    },
    isBudgetLoading() {
      return (
        this.isApiLoading("fetchingCompanySupplier") ||
        isObjectEmpty(this.budget)
      );
    },
  },
  async mounted() {
    try {
      this.setApiLoading({ key: "fetchingCompanySupplier", value: true });
      const supplier = await this.fetchCompanySupplier(this.supplier);
      this.budget = { ...supplier.budget };
    } catch (error) {
      this.showErrorMessage(error);
    } finally {
      this.setApiLoading({ key: "fetchingCompanySupplier", value: false });
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchCompanySupplier: FETCH_COMPANY_SUPPLIER,
    }),
    currencyToLocale,
  },
};
</script>

<style scoped>
.progress {
  height: 22px;
  overflow: hidden;
  @apply bg-hivebuy-yellow;
  @apply rounded-md ring-gray-400;

  -webkit-box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
  box-shadow: inset 0 2px 3px rgb(0 0 0 / 10%);
}
.progress-bar {
  @apply font-normal;
  float: left;
  width: 0%;
  height: 100%;
  line-height: 16px;
  color: #fff;
  @apply text-xs;
  -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-approved {
  @apply bg-hivebuy-green;
}
.progress-bar-remaining {
  @apply bg-yellow-400;
}

.progress-bar-overused {
  @apply bg-red-400;
}
</style>
