<template>
  <div>
    <ul v-if="approvals && approvals.length">
      <li v-for="(approval, index) in approvals" :key="index" class="user-row">
        <div class="flex justify-between mb-2">
          <span class="font-semibold text-gray-700">
            {{ approvalTypeTransform(approval, $t).name }}:
          </span>
          <div class="grow-0">
            <EditorActions
              :allow-actions="allowActions"
              :on-delete="onDelete"
              :on-edit="onEdit"
              :type="'approvals'"
              :category="index"
              :is-global-policy="isGlobalPolicy"
            />
          </div>
        </div>
        <div class="workflowItem text-center">
          <Role
            v-if="approvalTypeTransform(approval, $t).type === 'Role'"
            :role="approvalTypeTransform(approval, $t).value"
            :badge="false"
          />
          <div v-else>
            {{ approvalTypeTransform(approval, $t).value }}
          </div>
        </div>
      </li>
    </ul>
    <div v-else-if="!isGlobalPolicy" class="workflowItem text-center">
      {{ $t("workflow.editor.instantApproval") }}
    </div>
  </div>
</template>
<script>
import { approvalTypeTransform } from "@/utils/helpers/workflowHelper.js";
import EditorActions from "@/components/CompanyManagement/ApprovalWorkflow/Editor/Forms/actions.vue";
import Role from "@/components/CompanyManagement/Users/Role.vue";
export default {
  components: { EditorActions, Role },
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    approvals: {
      type: Array,
      default: () => [],
    },
    isGlobalPolicy: {
      type: Boolean,
      default: false,
    },
  },
  methods: { approvalTypeTransform },
};
</script>

<style scoped>
.user-row {
  @apply mb-4;
}
</style>
