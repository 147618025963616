import SearchInput from "@/components/PurchaseRequest/PurchaseRequestList/SearchInput";
import SkeletonTable from "@/components/shared/SkeletonLoaders/TableRows";
import loadMorePaginationMixin from "@/components/mixins/loadMorePaginationMixin";
import { createFiltersPayload } from "@/utils/utility_methods";
export default {
  mixins: [loadMorePaginationMixin],
  components: {
    SearchInput,
    SkeletonTable,
  },
  methods: { createFiltersPayload },
};
