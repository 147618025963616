<template>
  <div>
    <div class="max-w-7xl mx-auto py-3 px-3" :class="isSidebarOpen ? '' : ''">
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-yellow-400">
            <SpeakerphoneIcon class="h-6 w-6" />
          </span>
          <div
            v-if="isSidebarOpen"
            class="font-medium text-yellow-500 truncate ml-2"
          >
            <!-- <p v-if="trailActive" class="text-center">
              {{ alertText }}
            </p> -->
            <div v-if="trailActive" class="text-sm">
              <p>{{ $t("alerts.trialRemaning") }}</p>
              <p>{{ alertText }}</p>
            </div>
            <p v-else>{{ $t("alerts.trialExpired") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SpeakerphoneIcon } from "@heroicons/vue/outline";
export default {
  components: {
    SpeakerphoneIcon,
  },
  props: {
    demoModeUntil: { type: String, required: true },
    isSidebarOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  computed: {
    trailActive() {
      const demoDate = new Date(this.demoModeUntil).getTime();
      const dateToday = new Date().getTime();
      return demoDate >= dateToday;
    },
    alertText() {
      const time = `${this.days}d ${this.hours}h ${this.minutes}m ${this.seconds}s `;
      return time;
    },
  },
  mounted() {
    this.countDown();
  },
  methods: {
    countDown() {
      const countDownDate = new Date(this.demoModeUntil).getTime();

      setInterval(() => {
        const now = new Date().getTime();

        const distance = countDownDate - now;
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
  },
};
</script>
