<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 489.604 489.604"
    xml:space="preserve"
    fill="currentColor"
    class="fill-current"
  >
    <g id="XMLID_291_">
      <path
        id="XMLID_304_"
        d="M262.746,250.826c10.223,2.382,20.56-3.992,22.927-14.345c1.119-4.895,0.146-9.741-2.172-13.775
		l9.212-37.695l11.506,2.817c2.464,0.6,5.068-0.009,7.021-1.649c1.954-1.63,2.994-4.104,2.818-6.639l-5.251-76.983
		c-0.128-1.856-1.436-3.423-3.262-3.873c-1.806-0.438-3.693,0.343-4.672,1.938l-40.16,65.879c-1.326,2.166-1.533,4.837-0.571,7.189
		c0.991,2.346,3.005,4.088,5.488,4.697l11.5,2.808l-7.808,31.901l-40.158-9.239l-45.246-66.268
		c-5.409-7.929-13.472-8.473-23.134-8.473c0,108.094,0,101.246,0,150.124h22.958c0,73.013,0,4.64,0,171.363H137.84V329.902h-16.721
		v120.702H75.216c0-166.236,0-98.532,0-171.363h22.945c0-48.854,0-42.015,0-150.124c-11.074,0-20.128,1.233-24.734,11.32
		L9.648,280.135c-4.381,9.607-0.159,20.943,9.473,25.328c9.553,4.378,20.925,0.191,25.331-9.456l30.813-67.509v34.695h-0.049
		c-8.865,0-16.045,7.184-16.045,16.048v171.363c0,8.863,7.181,16.048,16.045,16.048c0,12.672,10.271,22.952,22.945,22.952
		c12.668,0,22.958-10.28,22.958-22.952c3.054,0,5.92-0.863,8.367-2.352c2.434,1.488,5.281,2.352,8.354,2.352
		c0,12.672,10.271,22.952,22.945,22.952c12.668,0,22.958-10.28,22.958-22.952c8.865,0,16.045-7.185,16.045-16.048V279.241
		c0-8.865-7.18-16.048-16.045-16.048h-0.05V205.09l18.097,26.511c2.702,3.977,6.833,6.785,11.5,7.856L262.746,250.826z"
      />
      <rect
        id="XMLID_303_"
        x="114.225"
        y="129.117"
        width="30.527"
        height="64.535"
      />
      <path
        id="XMLID_300_"
        d="M84.817,76.286c0.257,24.469,20.129,44.23,44.669,44.23c24.527,0,44.418-19.761,44.673-44.23
		c8.653-0.231,15.644-7.247,15.644-15.975C189.803,27.056,162.732,0,129.486,0C96.209,0,69.15,27.056,69.15,60.311
		C69.15,69.04,76.147,76.055,84.817,76.286z M97.389,29.967v4.545c0,4.43,3.585,8.023,8.02,8.023c4.429,0,8.014-3.593,8.014-8.023
		V19.161c2.58-1.001,5.251-1.792,8.031-2.307v17.658c0,4.43,3.584,8.023,8.033,8.023c4.417,0,8.018-3.593,8.018-8.023V16.854
		c2.781,0.515,5.452,1.306,8.014,2.307v15.351c0,4.43,3.584,8.023,8.032,8.023c4.429,0,8.032-3.593,8.032-8.023v-4.545
		c7.489,7.929,12.157,18.561,12.157,30.344H85.201C85.201,48.528,89.875,37.896,97.389,29.967z"
      />
      <path
        id="XMLID_299_"
        d="M345.291,435.467h-98.973c-4.448,0-8.032,3.585-8.032,8.023v35.872c0,4.433,3.584,8.022,8.032,8.022
		h98.973c4.411,0,8.013-3.589,8.013-8.022v-35.872C353.304,439.052,349.702,435.467,345.291,435.467z"
      />
      <path
        id="XMLID_298_"
        d="M473.671,435.467h-98.973c-4.448,0-8.032,3.585-8.032,8.023v35.872c0,4.433,3.583,8.022,8.032,8.022
		h98.973c4.411,0,8.014-3.589,8.014-8.022v-35.872C481.685,439.052,478.082,435.467,473.671,435.467z"
      />
      <path
        id="XMLID_297_"
        d="M473.671,307.078h-98.973c-4.448,0-8.032,3.594-8.032,8.027v35.869c0,4.442,3.583,8.026,8.032,8.026
		h98.973c4.411,0,8.014-3.584,8.014-8.026v-35.869C481.685,310.671,478.082,307.078,473.671,307.078z"
      />
      <path
        id="XMLID_296_"
        d="M345.291,307.078h-98.973c-4.448,0-8.032,3.594-8.032,8.027v35.869c0,4.442,3.584,8.026,8.032,8.026
		h98.973c4.411,0,8.013-3.584,8.013-8.026v-35.869C353.304,310.671,349.702,307.078,345.291,307.078z"
      />
      <path
        id="XMLID_295_"
        d="M302.473,379.295v35.878c0,4.432,3.584,8.022,8.032,8.022h98.973c4.417,0,8.02-3.59,8.02-8.022v-35.878
		c0-4.433-3.603-8.026-8.02-8.026h-98.973C306.057,371.269,302.473,374.862,302.473,379.295z"
      />
      <path
        id="XMLID_294_"
        d="M302.473,250.913v35.869c0,4.443,3.584,8.026,8.032,8.026h98.973c4.417,0,8.02-3.583,8.02-8.026v-35.869
		c0-4.432-3.603-8.025-8.02-8.025h-98.973C306.057,242.888,302.473,246.481,302.473,250.913z"
      />
      <path
        id="XMLID_293_"
        d="M473.671,371.269h-37.475c-4.43,0-8.014,3.593-8.014,8.026v35.878c0,4.432,3.583,8.022,8.014,8.022h37.475
		c4.411,0,8.014-3.59,8.014-8.022v-35.878C481.685,374.862,478.082,371.269,473.671,371.269z"
      />
      <path
        id="XMLID_292_"
        d="M473.671,242.888h-37.475c-4.43,0-8.014,3.593-8.014,8.025v35.869c0,4.443,3.583,8.026,8.014,8.026h37.475
		c4.411,0,8.014-3.583,8.014-8.026v-35.869C481.685,246.481,478.082,242.888,473.671,242.888z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ConstructionSVG",
};
</script>
