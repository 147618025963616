<template>
  <div
    v-for="invoice in itemsArray"
    :key="invoice.id"
    class="group item"
    @click="() => onInvoiceClick(invoice)"
  >
    <span class="title">
      {{ invoice.title }}
      <span class="cost">
        {{ invoice.invoiceNumber }}
      </span>
    </span>
    <span class="type text-xs">
      <TimeDisplay
        :time="invoice.createdAt"
        :show-time-ago="false"
        :show-date-with-time="false"
      />
    </span>
    <span class="badge">{{ $t("global.invoice.title") }}</span>
  </div>
</template>
<script>
import { currencyToLocale } from "@/utils/utility_methods.js";
import { TimeDisplay } from "@/components/shared/index";
export default {
  components: {
    TimeDisplay,
  },
  props: {
    items: { type: Array, required: true },
    handleResultClick: { type: Function, required: true },
    numberOfRecords: { type: Number, default: 10 },
  },
  computed: {
    itemsArray() {
      return this.items.slice(0, this.numberOfRecords);
    },
  },
  methods: {
    onInvoiceClick(item) {
      this.handleResultClick();
      this.$router.push({ name: "InvoiceDetail", params: { id: item.id } });
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.item {
  @apply py-2 hover:bg-gray-100 transition ease-in-out duration-150 flex truncate items-center px-4 justify-between cursor-pointer;
}

.title {
  @apply text-sm font-semibold text-gray-700 truncate group-hover:text-primary;
}

.type {
  @apply text-gray-500 text-xs pl-2;
}

.cost {
  @apply block text-gray-600 text-xs font-normal;
}

.badge {
  @apply bg-primary px-2 py-1 text-xs text-white rounded hidden;
}
</style>
