import Dialog from "@/components/shared/Dialog";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogBodyText: "",
    };
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
  },
};
