<template>
  <div class="rounded-lg px-4 md:px-6 lg:px-8 py-2">
    <img
      :src="supplierImage(supplier)"
      :alt="supplier.name"
      class="w-full rounded-t-lg object-scale-down h-40"
    />
  </div>
  <div class="flex-1 p-4 space-y-4 flex flex-col place-content-between">
    <div>
      <h3 class="text-base font-medium text-gray-900">
        {{ supplier.name }}
      </h3>
    </div>
    <p class="text-sm text-gray-500 mb-2">{{ supplierDescription }}</p>
    <Button
      :on-click-handler="fetchSupplierRedirectUrl"
      :color="'dark-gray'"
      :loading="isLoading"
      :disabled="disabled"
    >
      {{ $t("catalogue.suppliers.visit") }} {{ supplier.name }}
    </Button>
  </div>
</template>

<script>
import Button from "@/components/shared/Button";
import { mapActions, mapState } from "vuex";
import SupplierPlaceholderImage from "@/assets/images/catalogue-item-image-placeholder.png";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_SUPPLIER_URL
} from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";

export default {
  components: {
    Button
  },
  props: {
    supplier: {
      type: Object,
      required: true
    },
    companySupplier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      disabled: false
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    userLanguage() {
      if (this.user.language) {
        return this.user.language.toLowerCase();
      }
      return "en";
    },

    supplierDescription() {
      return this.descriptionString[this.userLanguage];
    },

    descriptionString() {
      try {
        const parsedJSON = JSON.parse(this.supplier.description);
        return parsedJSON;
      } catch (error) {
        return { en: "[DESCRIPTION MISSING]", de: "[BESCHREIBUNG FEHLT]" };
      }
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getSupplierUrl: GET_SUPPLIER_URL
    }),
    supplierImage(supplier) {
      return supplier.logo || SupplierPlaceholderImage;
    },
    fetchSupplierRedirectUrl() {
      this.isLoading = true;
      this.getSupplierUrl({ id: this.companySupplier.id })
        .then((data) => {
          this.disabled = true;
          window.location.href = data.url;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
