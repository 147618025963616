<template>
  <apexchart
    type="radialBar"
    height="180"
    :options="chartOptions"
    :series="gaugeValue"
  ></apexchart>
</template>

<script>
export default {
  props: {
    goal: {
      type: String,
      default: "0",
    },
    valueTotal: {
      type: String,
      default: "0",
    },
    valueThisMonth: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {};
  },
  computed: {
    gaugeValue() {
      const value = Math.round(
        (parseFloat(this.valueTotal) / parseFloat(this.goal)) * 100
      );
      if (value > 0) return [value];
      return [];
    },
    chartOptions() {
      return {
        chart: {
          height: 120,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            offsetX: 60,
            offsetY: -20,
            hollow: {
              size: "30%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: "16px",
                fontWeight: "bold",
                color: "#9e4770",
                offsetY: 4,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          colors: ["#4CBAAE"],
          type: "horizontal",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 75, 100],
          },
        },
      };
    },
  },
};
</script>

<style></style>
