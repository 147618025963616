//https://github.com/kazupon/vue-i18n/issues/444#issuecomment-430853066
export default {
  methods: {
    t(key, fallbackStr) {
      // use `$te` and `$t
      return this.$t && this.$te
        ? this.$te(key)
          ? this.$t(key)
          : fallbackStr
        : fallbackStr
        ? fallbackStr
        : key;
    },
  },
};
