export default {
  purchaseOrders: [],
  purchaseOrdersListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 10,
      totalPages: "",
      totalRecords: "",
      search: "",
    },
    advancedFilters: {},
  },
  actionRequiredPurchaseOrders: { results: [] },
  purchaseOrdersListParams: {},
  supplierOrders: [],
  supplierListParams: {},
};
