<template>
  <div class="flex items-center w-full">
    <div class="flex w-full text-sm">
      <HivebuyOverlay
        ref="inlineSupplierSelect"
        :title="$t('global.supplier')"
        :subtitle="$t('companyManagement.companySupplier.heading')"
        :disabled-message="disabledMessage"
        :error-text="error"
        narrow
      >
        <template #header>
          <div v-if="companySupplier?.skipSupplier" class="text-sm font-normal">
            {{ $t("purchaseRequest.skip") }}
          </div>
          <SupplierName
            v-else-if="companySupplier?.companySupplier"
            :supplier="companySupplier.companySupplier"
          />
          <span v-else-if="companySupplier?.unlistedSupplier">
            <TruncateText :text="companySupplier?.unlistedSupplier" />
          </span>
          <span v-else>{{
            $t("global.prepositions.select", { object: $t("global.supplier") })
          }}</span>
        </template>
        <template #subtitle>
          <div v-if="companySupplier?.skipSupplier" class="text-sm"></div>
          <SupplierName
            v-else-if="companySupplier?.companySupplier"
            :supplier="companySupplier.companySupplier"
            :show-supplier-number="true"
          />
          <span v-else-if="companySupplier?.unlistedSupplier">
            {{ $t("global.unlistedSupplier") }}
          </span>
          <span v-else></span>
        </template>

        <template #icon>
          <SupplierNewSVG class="h-[32px] w-[32px]" />
        </template>
        <template #panelHeader>
          {{
            $t("global.prepositions.select", {
              object: $t("global.supplier"),
            })
          }}
        </template>
        <template #panelContent>
          <SupplierSelection
            :save-to-store="false"
            :show-skip="true"
            :save-supplier="saveSupplier"
            :show-inline-unlisted-supplier="true"
            :supplier="companySupplier"
            :sort-by-preferred="true"
            :compact="true"
            :selected-supplier="companySupplier?.companySupplier"
            :unlisted-supplier-object="{
              name: companySupplier?.unlistedSupplier,
              reason: companySupplier?.unlistedSupplierReason,
            }"
            :category="category"
          />
        </template>
        <template #panelFooter>
          {{ $t("purchaseRequest.selectCompanySupplierSubtext") }}
        </template>
      </HivebuyOverlay>
    </div>
  </div>
</template>

<script>
import SupplierSelection from "@/components/PurchaseRequest/FreeForm/Supplier.vue";
import { mapGetters } from "vuex";
import SupplierNewSVG from "@/assets/images/SupplierNewSVG.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { HivebuyOverlay } from "design-buy";
import SupplierName from "@/components/shared/SupplierName";
export default {
  name: "InlineSupplierSelect",
  components: {
    HivebuyOverlay,
    SupplierSelection,
    SupplierNewSVG,
    SupplierName,
  },
  props: {
    modelValue: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    disabledMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["supplierName"]),
    companySupplier: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.editMode = false;
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
  },
  methods: {
    supplierDetailsObject(supplier) {
      if (supplier.companySupplier)
        return this.supplierName(supplier.companySupplier);
      if (supplier.unlistedSupplier)
        return `${supplier.unlistedSupplier} (Unlisted)`;
      return "-";
    },
    saveSupplier(value, unlistedSupplier = false) {
      this.companySupplier = {
        skip: value.type == "skip",
        unlistedSupplier,
        details: unlistedSupplier ? value : value.details,
      };
      this.$refs.inlineSupplierSelect.toggle();
    },
  },
};
</script>
<style>
.p-link:focus {
  @apply shadow-none !important;
}
</style>
