<template>
  <Button
    :on-click-handler="toggleShow"
    :disabled="disabled"
    :color="'primary'"
    :size-class="'sm'"
  >
    {{ buttonText }}
  </Button>
  <UploadSection
    v-model="show"
    field="img"
    :width="200"
    :height="200"
    img-format="png"
    :lang-ext="customSettings()"
    @crop-success="cropSuccess"
  >
  </UploadSection>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import Button from "@/components/shared/Button.vue";
export default {
  components: {
    UploadSection: myUpload,
    Button,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
    saveImageData: {
      type: Function,
      required: true,
    },
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      imgDataUrl: "",
    };
  },
  methods: {
    customSettings() {
      return {
        hint: "Click or drag the file here to upload",
        loading: "Uploading…",
        noSupported: "Browser is not supported, please use a modern browser.",
        success: "Upload success",
        fail: "Upload failed",
        preview: "Preview",
        btn: {
          off: "Cancel",
          close: "Close",
          back: "Back",
          save: "Save",
        },
        error: {
          onlyImg: "Image only",
          outOfSize: "Image exceeds size limit: ",
          lowestPx: "Image's size is too low. Expected at least: ",
        },
      };
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.saveImageData(imgDataUrl);
    },
  },
};
</script>
