<template>
  <div class="custom-card no-shadow">
    <div class="custom-card-header">
      <div class="card-title">
        {{ $t("contracts.contractForm.licenseDetails") }}
      </div>
    </div>
    <div class="card-content">
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.licenseAmount") }}
            </label>
          </div>

          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>
                {{ $t("contracts.contractForm.licenseAmount") }}
              </template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.licenseAmount1") }}
                  </p>
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.licenseAmount2") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>

        <Input
          :value="licenseDetails.licenseAmount"
          :error-text="errorMessage(v.contract?.licenseDetails?.licenseAmount)"
          :on-value-change="
            (event) => (licenseDetails.licenseAmount = event.target.value)
          "
          type="number"
          :placeholder="$t('contracts.contractForm.licenseAmount')"
          :name="'licenseAmount'"
        />
      </div>
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.freeLicenseAmount") }}
            </label>
          </div>
          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>
                {{ $t("contracts.contractForm.freeLicenseAmount") }}
              </template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{
                      $t("contracts.contractForm.tooltip.freeLicenseAmount1")
                    }}
                  </p>
                  <p class="tooltip-subtext">
                    {{
                      $t("contracts.contractForm.tooltip.freeLicenseAmount2")
                    }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <Input
          :value="licenseDetails.freeLicenseAmount"
          :on-value-change="
            (event) => (licenseDetails.freeLicenseAmount = event.target.value)
          "
          type="number"
          :placeholder="$t('contracts.contractForm.freeLicenseAmount')"
          :name="'freeLicenseAmount'"
        />
      </div>
      <div class="cell">
        <PriceAndVAT
          v-model="price"
          :is-mandatory="!!v.contract?.licenseDetails?.grossAmount"
          :error-text="errorMessage(v.contract?.licenseDetails?.grossAmount)"
        />
      </div>
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.approvalNeeded") }}
            </label>
          </div>
          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>
                {{ $t("contracts.contractForm.approvalNeeded") }}
              </template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.approvalNeeded") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <SwitchCheckbox
          v-model="licenseDetails.approvalNeeded"
          :class="'flex-col !items-start gap-y-2'"
        />
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.replaceResult") }}
            </label>
          </div>

          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>
                {{ $t("contracts.contractForm.replaceResult") }}
              </template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.replaceResult") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <SwitchCheckbox
          v-model="licenseDetails.replaceResult"
          :class="'flex-col !items-start gap-y-2'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Input,
  PriceAndVAT,
  SwitchCheckbox,
} from "@/components/shared";
import { mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { CURRENCY } from "@/utils/constants";
import { errorMixin } from "@/components/mixins";

export default {
  name: "LicenseDetails",
  components: {
    Input,
    SwitchCheckbox,
    PriceAndVAT,
  },
  mixins: [errorMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      price: null,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    licenseDetails: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    price: {
      deep: true,
      handler(newVal) {
        if (!newVal) return;
        const { netAmount, grossAmount, currency, vat } = newVal;
        this.licenseDetails = {
          ...this.licenseDetails,
          grossAmount,
          netAmount,
          vat,
          grossAmountCurrency: currency,
          netAmountCurrency: currency,
        };
      },
    },
  },
  created() {
    this.price = this.defaultPricingDetails();
  },
  methods: {
    defaultPricingDetails() {
      if (!parseFloat(this.licenseDetails?.netAmount)) return null;
      const defaultCurrency = this.company?.currency
        ? CURRENCY.find((currency) => currency.name === this.company.currency)
        : CURRENCY[0];
      return {
        inputPrice: this.licenseDetails.netAmount,
        vat: this.licenseDetails.vat || defaultCurrency.vatRates[0].value,
        currency:
          this.licenseDetails.netAmountCurrency || defaultCurrency.value,
        grossAmount: this.licenseDetails.grossAmount,
        netAmount: this.licenseDetails.netAmount,
        netGross: "net",
      };
    },
    errorMessage(validationField) {
      return validationField ? this.error(validationField) : "";
    },
  },
};
</script>

<style scoped>
.page-content .left-content .card-content {
  @apply w-full grid gap-4 grid-cols-2;
}

.tooltip-header {
  @apply text-sm font-medium text-gray-900;
}
.tooltip-subtext {
  @apply mt-1 text-sm text-gray-500 whitespace-normal;
}
</style>
