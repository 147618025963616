import { timeDifference } from "@/utils/timeHelper.js";
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    timeAgo(createdAt) {
      return timeDifference(createdAt);
    },
  },
};
