<template>
  <Modal :show-modal="showModal" :close-modal="onCloseModal">
    <template #icon>
      <CloudUploadIcon class="h-7 w-7 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">Import - {{ headerText }}</h3>
    </template>
    <template #body>
      <div v-if="finalized">
        <div class="mt-3 mb-3 transform">
          <SuccessSVG />
        </div>
        <div class="flex justify-center text-base">
          {{ headerText }} {{ $t("buttons.csvUpload.uploadSuccessful") }}
        </div>
      </div>
      <div v-else>
        <div v-if="!fileUploaded" class="mb-2 text-base">
          <i18n-t keypath="buttons.csvUpload.subtext" tag="p">
            <template #type>
              <span class="font-bold text-primary">
                {{ $t(`buttons.csvUpload.${type}`) }}
              </span>
            </template>
          </i18n-t>
        </div>
        <UploadArea
          v-if="!fileUploaded && !loading"
          type="csv"
          @list-update="onFileUpload"
        />
        <div
          v-if="fileInvalid"
          class="text-base text-hivebuy-red font-medium mt-3"
        >
          {{ $t("buttons.csvUpload.fileInvalid") }}
          <div class="flex justify-center mt-2">
            <Button
              type="button"
              :on-click-handler="requestDownload"
              :loading="loading"
            >
              Export CSV
            </Button>
          </div>
        </div>
        <div
          v-if="loading"
          class="py-4 flex flex-col justify-center items-center"
        >
          <HivebuyLoader class="h-14" />
          <span class="text-sm text-gray-400 animate-pulse">{{
            $t("buttons.csvUpload.loading")
          }}</span>
        </div>
        <div v-if="fileUploaded">
          <span class="text-base">{{
            $t("buttons.csvUpload.fileAnalyzed")
          }}</span>
          <div class="grid grid-cols-1 gap-y-2 mt-4">
            <div class="text-base font-medium text-gray-900">
              <i18n-t keypath="buttons.csvUpload.adding" tag="p">
                <template #count>
                  <span class="mx-1 text-hivebuy-green text-lg">{{
                    numberOfRecordsToBeAdded
                  }}</span>
                </template>
                <template #type>
                  {{ $t(`buttons.csvUpload.${type}`) }}
                </template>
              </i18n-t>
            </div>
            <div class="text-base font-medium text-gray-900">
              <i18n-t keypath="buttons.csvUpload.updating" tag="p">
                <template #count>
                  <span class="mx-1 text-primary text-lg">{{
                    numberOfRecordsToBeUpdated
                  }}</span>
                </template>
                <template #type>
                  {{ $t(`buttons.csvUpload.${type}`) }}
                </template>
              </i18n-t>
            </div>
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                <i18n-t keypath="buttons.csvUpload.deleting" tag="p">
                  <template #count>
                    <span class="mx-1 text-hivebuy-red text-lg">{{
                      objectsToDelete.length
                    }}</span>
                  </template>
                  <template #type>
                    {{ $t(`buttons.csvUpload.${type}`) }}
                  </template>
                </i18n-t>
              </legend>
              <p v-if="deletableObjects.length" class="text-xs">
                <i18n-t keypath="buttons.csvUpload.deleteSubtext" tag="p">
                  <template #type>
                    <span class="font-bold text-primary">
                      {{ $t(`buttons.csvUpload.${type}`) }}
                    </span>
                  </template>
                </i18n-t>
                <SwitchCheckbox
                  v-model="deleteAll"
                  class="inline-flex mb-2 mr-0 mt-2 text-sm"
                  :label="
                    $t('buttons.csvUpload.deleteAll', {
                      type: headerText,
                    })
                  "
                />
              </p>
              <div
                v-if="deletableObjects.length"
                class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200 text-sm overflow-y-auto pr-5 deletion-section"
              >
                <div
                  v-for="object in deletableObjects"
                  :key="object.id"
                  class="relative flex items-start py-2.5"
                >
                  <div class="min-w-0 flex-1 text-sm">
                    <label class="font-medium text-gray-700 select-none">{{
                      object.name
                    }}</label>
                  </div>
                  <div class="ml-3 flex items-center h-5">
                    <input
                      :id="object.id"
                      v-model="objectsToDelete"
                      :value="object.id"
                      type="checkbox"
                      class="focus:ring-hivebuy-red h-4 w-4 text-hivebuy-red border-gray-300 rounded"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        v-if="!finalized"
        type="button"
        :on-click-handler="onCloseModal"
        full
        :color="'gray'"
        :loading="loading"
      >
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        v-if="!finalized"
        type="button"
        :on-click-handler="onFileUpload"
        :disabled="!fileUploaded"
        :loading="loading"
      >
        {{ $t("buttons.confirm") }}
      </Button>
      <Button
        v-else
        class="col-span-full"
        type="button"
        :on-click-handler="onCloseModal"
        full
        :color="'gray'"
        :loading="loading"
      >
        {{ $t("buttons.closeWindow") }}
      </Button>
    </template>
  </Modal>
</template>
<script>
import { Modal, Button, SwitchCheckbox } from "@/components/shared";
import { CloudUploadIcon } from "@heroicons/vue/solid";
import { UploadArea, HivebuyLoader } from "@/components/shared/index";
import SuccessSVG from "@/assets/images/SuccessCheckmark.vue";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPLOAD_CSV,
  FINALIZE_CSV,
} from "@/store/CompanyManagement/types";
import { DOWNLOAD_MODULE } from "@/store/Downloads/types.js";
import { mapState } from "vuex";
export default {
  components: {
    Modal,
    Button,
    CloudUploadIcon,
    UploadArea,
    HivebuyLoader,
    SuccessSVG,
    SwitchCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    onFinalized: {
      type: Function,
      default: () => {},
    },
    onDownloadRequest: {
      type: Function,
      default: () => {},
    },
    extraData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      deleteAll: false,
      fileUploaded: false,
      objectsToDelete: [],
      deletableObjects: [],
      formattedDeletableObjects: [],
      loading: false,
      fileInvalid: false,
      numberOfRecordsToBeAdded: 0,
      numberOfRecordsToBeUpdated: 0,
      uploadedFileFormData: null,
      finalized: false,
    };
  },
  computed: {
    ...mapState(DOWNLOAD_MODULE, ["uploads"]),
    headerText() {
      return this.$t(`buttons.csvUpload.${this.type}`);
    },
  },
  watch: {
    uploads: {
      handler(newValue) {
        Object.keys(newValue).forEach((key) => {
          if (!newValue[key].inProgress) {
            this.showNotification(
              this.$t("toasts.uploadSuccess", {
                name: this.$t(`buttons.csvUpload.${this.type}`),
              })
            );
          }
        });
      },
      deep: true,
    },
    deleteAll: {
      handler(newValue) {
        if (newValue) {
          this.objectsToDelete = this.deletableObjects.map((object) => {
            return object.id;
          });
        } else if (
          this.deletableObjects.length == this.objectsToDelete.length
        ) {
          this.objectsToDelete = [];
        }
      },
    },
    objectsToDelete: {
      handler(newValue) {
        if(newValue.length == 0) return
        this.deleteAll = newValue.length == this.deletableObjects.length;
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      uploadCSV: UPLOAD_CSV,
      finalizeCSV: FINALIZE_CSV,
    }),
    onFileUpload(file) {
      if (this.type) {
        if (!this.fileUploaded) {
          this.uploadedFileFormData = new FormData();
          this.uploadedFileFormData.append("file", file[0]);
          this.uploadedFileFormData.append("modelName", this.type);
          Object.keys(this.extraData).forEach((key) => {
            this.uploadedFileFormData.append(key, this.extraData[key]);
          });
        }
        this.loading = true;
        this.fileInvalid = false;
        if (this.fileUploaded) {
          this.uploadedFileFormData.append(
            "ids",
            this.formatObjectsToBeDeleted()
          );
          this.finalizeCSV(this.uploadedFileFormData)
            .then(() => {
              this.finalized = true;
              this.onSuccess();
            })
            .catch(() => {
              this.fileInvalid = true;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.uploadCSV(this.uploadedFileFormData)
            .then((response) => {
              this.fileUploaded = true;
              const {
                missingRecords,
                numberOfRecordsToBeAdded,
                numberOfRecordsToBeUpdated,
              } = response.data;
              this.deletableObjects = missingRecords;
              this.numberOfRecordsToBeAdded = numberOfRecordsToBeAdded;
              this.numberOfRecordsToBeUpdated = numberOfRecordsToBeUpdated;
            })
            .catch(() => {
              this.fileInvalid = true;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
    formatObjectsToBeDeleted() {
      if (this.objectsToDelete.length == 1) {
        return this.objectsToDelete[0];
      }
      const result = this.objectsToDelete.toString();
      return result;
    },
    onCloseModal() {
      this.closeModal();
      this.resetValues();
    },
    onSuccess() {
      this.onFinalized();
      setTimeout(() => {
        this.onCloseModal();
      }, 2500);
    },
    resetValues() {
      setTimeout(() => {
        this.fileUploaded = false;
        this.objectsToDelete = [];
        this.deletableObjects = [];
        this.fileInvalid = false;
        this.formattedDeletableObjects = [];
        this.finalized = false;
      }, 500);
    },
    requestDownload() {
      this.onDownloadRequest();
      this.onCloseModal();
    },
  },
};
</script>

<style>
.deletion-section {
  max-height: 40vh;
  overflow-y: scroll;
}
</style>
