<template>
  <div class="relative">
    <div class="text-right">
      <Button
        class="filters-btn"
        :on-click-handler="toggleFilters"
        :color="filterButtonColor"
      >
        <AdjustmentsIcon class="text-primary hover:text-primarydark w-6 h-6" />

        <div v-if="appliedFiltersCount" class="counter-badge">
          {{ appliedFiltersCount }}
        </div>
      </Button>
    </div>
    <CollapseTransition class="absolute right-0">
      <div v-if="showFilters" class="filter-holder z-50 w-[500px]">
        <div class="flex-grow z-50">
          <div class="grid lg:grid-cols-6 gap-4">
            <div class="col-span-12 lg:col-span-3">
              <div class="label">
                {{ $t("global.legalEntity") }}
              </div>
              <div>
                <Multiselect
                  v-model="filters.legalEntity"
                  :searchable="true"
                  :options="legalTendencyList"
                  :placeholder="$t('global.legalEntity')"
                  value-prop="id"
                  label="name"
                  track-by="name"
                  :can-clear="false"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-3">
              <div class="label">
                {{ $t("global.supplier") }}
              </div>
              <div>
                <SupplierSelect
                  v-model="filters.companySupplier"
                  :label="'name'"
                  :placeholder="$t('global.supplier')"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-3">
              <div>
                <AmountRange
                  :handle-amount-range="changeAmountRange"
                  :amount-filter="filters.amount"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-3">
              <div class="label">
                {{ $t("global.supplierNumber") }}
              </div>
              <div>
                <Input
                  :value="filters.supplierNumber"
                  name="supplierNumber"
                  :placeholder="$t('global.supplierNumber')"
                  :on-value-change="onAttributeChange"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end ml-2 mt-2">
            <Button :on-click-handler="onApplyFilters" class="mr-2">
              {{ $t("button.apply") }}
            </Button>
            <Button :on-click-handler="onResetAllFilters" :color="'gray'">
              {{ $t("button.resetAll") }}
            </Button>
          </div>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { AdjustmentsIcon } from "@heroicons/vue/outline";
import { mapGetters, mapState } from "vuex";
import { Input } from "@/components/shared";
import AmountRange from "@/components/InvoiceApproval/AmountRange";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import Button from "@/components/shared/Button.vue";
import Multiselect from "@vueform/multiselect";
import { SupplierSelect } from "@/components/shared";
import { INVOICE_MODULE } from "@/store/Invoice/types";
import {
  removeKeysFromObject,
  removeKeysInObjWithNoValue,
} from "@/utils/utility_methods";
import { invoiceFilterMixin } from "@/components/mixins/index.js";
export default {
  components: {
    CollapseTransition,
    AdjustmentsIcon,
    Input,
    AmountRange,
    Button,
    Multiselect,
    SupplierSelect,
  },
  mixins: [invoiceFilterMixin],
  data() {
    return {
      showFilters: false,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
    ...mapState(INVOICE_MODULE, {
      advancedFilters: (state) => state.invoiceListFilters.advancedFilters,
    }),
    filterButtonColor() {
      return this.showFilters ? "danger" : "primary";
    },
    appliedFiltersCount() {
      let filters = removeKeysFromObject(this.filters, ["filter"]);
      filters = removeKeysInObjWithNoValue(filters);
      return Object.keys(filters).length;
    },
  },
  watch: {
    advancedFilters: {
      handler(newVal) {
        this.filters = { ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onAttributeChange({ target: { name, value } }) {
      this.filters[name] = value;
    },
    changeAmountRange(event) {
      if (
        event.start &&
        event.end &&
        parseInt(event.start) > parseInt(event.end)
      ) {
        this.showNotification(
          this.$t("invoice.filter.amountMismatch"),
          "error"
        );
        return;
      } else this.filters.amount = event;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    onApplyFilters() {
      const updatedFilters = Object.assign(
        {},
        this.advancedFilters,
        this.filters
      );
      this.updateFilters("advancedFilters", updatedFilters);
      this.toggleFilters();
    },
    onResetAllFilters() {
      this.filters = { filter: "all" };
      this.updateFilters("advancedFilters", this.filters);
      this.toggleFilters();
    },
  },
};
</script>

<style scoped>
.filter-holder {
  @apply flex items-end justify-end bg-white p-6 rounded border border-gray-200 shadow-2xl;
}

.filters-btn {
  @apply bg-white border-none shadow-none !important;
}
</style>
