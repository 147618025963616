import { post, patch, get, deleteObject, cachedGet } from "../api";
import {DEPARTMENTS_API_URL} from "@/utils/services/apiUrls.js";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types.js";
import {formattedURL} from "@/utils/utility_methods";
import store from "@/store";
import {COMPANY_MANAGEMENT_MODULE} from "@/store/CompanyManagement/types.js";

export const getDepartment = (deptId, commit, resolve, reject) => {
  if (!deptId) return;

  const departmentGetter = store.getters[`${COMPANY_MANAGEMENT_MODULE}/departmentDetails`];
  const response = departmentGetter(deptId, true);

  if (response) {
    return resolve(response);
  }

  cachedGet(`${DEPARTMENTS_API_URL}${deptId}/`)
    .then((response) => {
      commit(CompanyManagementTypes.SET_DEPARTMENT, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const addDepartment = (payload, commit, resolve, reject) => {
  post(DEPARTMENTS_API_URL, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_DEPARTMENT, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateDepartment = (deptId, payload, commit, resolve, reject) => {
  if (!deptId) return;

  patch(`${DEPARTMENTS_API_URL}${deptId}/`, payload)
    .then((response) => {
      commit(CompanyManagementTypes.SET_DEPARTMENT, response.data);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDepartments = (commit, resolve, reject, paginationParams) => {
  get(formattedURL(DEPARTMENTS_API_URL, paginationParams))
    .then((response) => {
      commit(CompanyManagementTypes.SET_DEPARTMENTS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUserDepartments = (commit, resolve, reject) => {
  get(`${DEPARTMENTS_API_URL}?user_departments=true`)
    .then((response) => {
      const payload = { results: response.data };
      commit(CompanyManagementTypes.SET_DEPARTMENTS, payload);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteDepartment = (departmentId, commit, resolve, reject) => {
  if (!departmentId) return;

  deleteObject(`${DEPARTMENTS_API_URL}${departmentId}/`)
    .then((response) => {
      commit(CompanyManagementTypes.REMOVE_DEPARTMENT, departmentId);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
