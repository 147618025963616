import * as Types from "./types.js";
import {
  setItemInLocalStorage,
  getItemFromLocalStorage,
  updateOrCreate,
} from "@/utils/utility_methods.js";
const paginationState = (payload) => {
  const object = Object.assign({}, payload);
  object.results = object.results.map((element) => element.id);
  return object;
};
const setDataArray = (array, payload, actionFunction) => {
  const object = payload?.object?.purchaseRequest || payload;
  const index = array.findIndex((object) => object == object.id);
  if (actionFunction(object) && index == -1) {
    array = [object.id, ...array];
  } else {
    array.splice(index, 1);
  }
};
const actionRequired = (request) => {
  if (request.itemLevelApprover) return request.actionRequired;
  return request.items.some((item) => item.actionRequired);
};
export default {
  [Types.SET_SELECTED_SIDEBAR]: (state, payload) => {
    state.selectedSideBar = payload;
  },
  [Types.SHOW_NEW_VERSION_ALERT]: (state, payload) => {
    if (!payload) return;
    const lastVersions = getItemFromLocalStorage("versions", null);

    const prevLast = lastVersions ? lastVersions.previous : null;
    const currentLast = lastVersions ? lastVersions.current : null;
    if (prevLast == payload || currentLast == payload) return;

    const versions = {
      previous: currentLast,
      current: payload,
    };
    setItemInLocalStorage("versions", versions);
    if (prevLast == null) return;

    state.showNewVersionAlert = true;
  },
  [Types.DISABLE_NEW_VERSION_ALERT]: (state) => {
    state.showNewVersionAlert = false;
  },
  [Types.SET_PURCHASE_REQUESTS_ANALYTICS]: (state, payload) => {
    state.analytics.purchaseRequests = payload;
  },
  [Types.SET_DEPARTMENT_ANALYTICS]: (state, payload) => {
    state.analytics.departments = payload;
  },
  [Types.SET_DEPARTMENT_BUDGET_ANALYTICS]: (state, payload) => {
    state.analytics.budgets.departments = payload;
  },
  [Types.SET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS]: (state, payload) => {
    const departmentToUpdate = state.analytics.budgets.departments.findIndex(
      (element) => element.departmentId === payload.departmentId
    );
    if (departmentToUpdate >= 0) {
      state.analytics.budgets.departments[departmentToUpdate] = payload;
    } else {
      state.analytics.budgets.departments.push(payload);
    }
  },
  [Types.SET_DETAILED_ANALYTICS]: (state, payload) => {
    state.detailedAnalytics.purchaseRequests = payload;
  },
  [Types.SET_ANALYTICS_STATISTICS]: (state, payload) => {
    state.detailedAnalytics.statistics = payload;
  },
  [Types.SET_MY_PURCHASE_REQUESTS]: (state, payload) => {
    state.myPurchaseRequests = paginationState(payload);
  },
  [Types.SET_ACTION_REQUIRED_PURCHASE_REQUESTS]: (state, payload) => {
    state.actionRequiredPurchaseRequests = paginationState(payload);
  },

  [Types.SET_ACTION_REQUIRED_PURCHASE_REQUEST]: (state, payload) => {
    setDataArray(
      state.actionRequiredPurchaseRequests.results,
      payload,
      actionRequired
    );
  },
  [Types.SET_NEWS]: (state, payload) => {
    state.news = [...payload];
  },

  [Types.SAVE_NEWS_DATA]: (state, payload) => {
    state.news = updateOrCreate(state.news, payload);
  },

  [Types.REMOVE_NEWS]: (state, newsId) => {
    state.news = state.news.filter((news) => news.id !== newsId);
  },

  [Types.SET_USER_RELEVANT_NEWS]: (state, payload) => {
    state.userRelevantNews = [...payload];
  },

  [Types.SET_USER_RELEVANT_NEWS_DATA]: (state, payload) => {
    state.userRelevantNews = updateOrCreate(state.userRelevantNews, payload);
  },
};
