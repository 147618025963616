<template>
  <!-- members start -->
  <section>
    <div class="custom-card no-shadow">
      <div class="flex w-2/3">
        <div class="w-1/3">
          <p class="title">{{ $t("proposals.members.opened") }}</p>
          <UserWithAvatar
            :user="proposal.createdBy"
            :show-tooltip="true"
            :avatar-dimension="10"
            class="mt-3"
          />
        </div>
        <div class="w-2/3">
          <div class="flex justify-between">
            <p class="title mr-2">
              {{ $t("proposals.members.title") }}
            </p>
          </div>
          <div class="mt-3">
            <div v-if="!isEditable" class="members-list">
              <div class="flex flex-row">
                <div v-if="proposal.members.length" class="flex flex-col">
                  <div
                    v-for="row in memberRows"
                    :key="row"
                    class="flex flex-row"
                  >
                    <div
                      v-for="(member, memberIndex) in membersPerRow(row)"
                      :key="memberIndex"
                      class="-ml-2"
                    >
                      <UserWithAvatar
                        :user="member"
                        :show-delete="isStatusNotFinished && !loading"
                        :on-delete="onRemove"
                        :show-tooltip="true"
                        :avatar-dimension="10"
                        :department-name="proposal.title"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="showEditMemberIcon && !loading" class="add-member">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="w-6"
                    data-v-a454d12a=""
                    data-v-5784ed69-s=""
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <MemberSelectorPopover
                    :submit-btn-text="'Add Member/s'"
                    :users="memberUsers"
                    :on-submit="onUpdate"
                    :popover-classes="['-translate-x-1/2 left-1/2']"
                    :header-text="$t('proposals.addProposalModal.headerText')"
                  />
                </div>
                <div v-if="loading" class="!text-primary flex items-top ml-2">
                  <DashboardLoader height="10" width="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2">
        <div class="flex place-items-center w-full">
          <p class="description title mr-2">
            {{ $t("proposals.members.description") }}
          </p>

          <PencilAltIcon
            v-if="showEditDescriptionIcon"
            class="w-4 h-4 cursor-pointer text-secondary hover:text-hivebuy-green"
            @click="() => (isDescriptionEditable = !isDescriptionEditable)"
          />

          <XCircleIcon
            v-else-if="isDescriptionEditable"
            class="w-5 h-5 cursor-pointer text-hivebuy-red"
            @click="onCancelDescription"
          />
        </div>
        <template v-if="!isDescriptionEditable">
          <p class="text-gray-500 text-sm mt-2">{{ proposal.description }}</p>
        </template>
        <div v-else class="mt-2">
          <TextArea
            id="description"
            name="description"
            class="w-full"
            :placeholder="
              $t('proposals.suggestions.suggestionForm.descriptionPlaceholder')
            "
            :value="proposal.description"
            :rows="3"
            :on-value-change="onDescChange"
          />
          <div class="mt-1 flex flex-row gap-x-1">
            <Button
              class="mt-2"
              :on-click-handler="onUpdateDescription"
              :size-class="'sm'"
              :disabled="!showActions"
            >
              {{ $t("button.update") }}
            </Button>
            <Button
              class="mt-2"
              :size-class="'sm'"
              :color="'gray'"
              :on-click-handler="onCancelDescription"
            >
              {{ $t("button.cancel") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- members end -->

  <section class="status">
    <section class="custom-card no-shadow">
      <section class="flex items-center justify-between w-full">
        <div class="flex items-center left-content">
          <p class="title">{{ $t("proposals.members.status") }}:</p>
          <div class="mr-8 ml-2">
            <StatusTag :status="proposal.status" type="proposal" size="small" />
          </div>
          <TooltipPopover :tooltip-class="'text-gray-700'">
            <template #header>{{ $t("proposals.members.statusExp") }}</template>
            <template #body>
              <div
                class="grid grid-cols-2 border-b border-gray-100 items-center align-middle py-2 normal-case"
              >
                <p :class="'status-label'" class="bg-gray-300 w-12">
                  {{ $t("button.draft") }}
                </p>
                <p class="text-sm text-gray-500 inline-flex py-3.5">
                  {{ $t("proposals.members.draftExp") }}
                </p>
                <p :class="`status-label `" class="bg-hivebuy-yellow w-12">
                  {{ $t("button.open") }}
                </p>
                <p class="text-sm text-gray-500 inline-flex py-3.5">
                  {{ $t("proposals.members.openExp") }}
                </p>
                <p
                  :class="`status-label `"
                  class="bg-hivebuy-green w-20 items-center"
                >
                  {{ $t("proposals.members.completed") }}
                </p>
                <p class="text-sm text-gray-500 inline-flex py-3.5">
                  {{ $t("proposals.members.completedExp") }}
                </p>
                <p :class="`status-label`" class="bg-hivebuy-red w-16">
                  {{ $t("button.close") }}
                </p>
                <p class="text-sm text-gray-500 inline-flex py-3.5">
                  {{ $t("proposals.members.closeExp") }}
                </p>
              </div>
            </template>
          </TooltipPopover>

          <div v-if="isUserCreator">
            <p class="text-sm text-gray-500">
              {{ $t("proposals.members.changeStatus") }}
            </p>
          </div>
        </div>

        <div class="buttons-holder">
          <div v-if="isUserCreator" class="flex space-x-4 justify-center">
            <Button
              v-if="proposal.status != 'OP' && proposal.status != 'CO'"
              :on-click-handler="toggleModal"
              :class="'btn-success'"
              :loading="loading"
              size-class="sm"
            >
              {{ $t("button.open") }}
            </Button>
            <Button
              v-if="proposal.status != 'DR' && proposal.status != 'CO'"
              :on-click-handler="setStatusToDraft"
              :class="'btn-gray'"
              :loading="loading"
              size-class="sm"
            >
              {{ $t("button.draft") }}
            </Button>
            <Button
              v-if="proposal.status != 'CL' && proposal.status != 'CO'"
              :on-click-handler="toggleModalCL"
              :class="'btn-danger-outline'"
              :loading="loading"
              size-class="sm"
            >
              {{ $t("button.close") }}
            </Button>
            <Button
              v-if="proposal.status == 'CO'"
              :on-click-handler="onPurchaseRequestClick"
              :loading="loading"
              size-class="sm"
            >
              {{ $t("button.linkToPuR") }}
            </Button>
          </div>
        </div>
      </section>
    </section>
  </section>
  <ConfirmDialog
    :text="$t('proposals.members.infoDia')"
    :show-modal="showDeleteConfirmModal"
    :close-modal="toggleModal"
    :title="$t('proposals.members.infoTitle')"
    :confirm-modal="setStatusToOpen"
    info
  />
  <ConfirmDialog
    :text="$t('proposals.members.dangerDia')"
    :show-modal="showDeleteConfirmModalCL"
    :close-modal="toggleModalCL"
    :title="$t('proposals.members.dangerTitle')"
    :confirm-modal="setStatusToClosed"
    danger
  />
</template>

<script>
import {
  TextArea,
  UserWithAvatar,
  ConfirmDialog,
  TooltipPopover,
  Button,
  DashboardLoader,
} from "@/components/shared/index";
import { PencilAltIcon, XCircleIcon } from "@heroicons/vue/solid";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import MemberSelectorPopover from "@/components/CompanyManagement/Departments/ListingTable/MemberSelectorPopover";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_USERS,
} from "@/store/CompanyManagement/types";
import {
  PROPOSAL_MODULE,
  UPDATE_PROPOSAL,
  UPDATE_PROPOSAL_MEMBERS,
  UPDATE_PROPOSAL_STATUS,
} from "@/store/Proposal/types";

import { proposalMixin } from "@/components/mixins/index.js";
import StatusTag from "@/components/shared/StatusTag";

export default {
  name: "Members",
  components: {
    Button,
    UserWithAvatar,
    PencilAltIcon,
    MemberSelectorPopover,
    XCircleIcon,
    TextArea,
    TooltipPopover,
    ConfirmDialog,
    DashboardLoader,
    StatusTag,
  },
  mixins: [proposalMixin],
  props: {
    proposal: {
      type: Object,
      required: true,
    },
    fetchProposal: {
      type: Function,
      required: true,
    },
    updateProposalParent: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      selectedMembers: this.proposal.members,
      isStatusEditable: false,
      statusToUpdate: "",
      isDescriptionEditable: false,
      descToUpdate: "",
      suggestionToDelete: null,
      showDeleteConfirmModal: false,
      showDeleteConfirmModalCL: false,
      maxMemberInRow: 8,
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
    showEditMemberIcon() {
      return !this.isEditable && this.showActions;
    },
    showEditDescriptionIcon() {
      return !this.isDescriptionEditable && this.showActions;
    },
    isUserCreator() {
      if (this.user.id == this.proposal.createdBy.id) {
        return true;
      } else return false;
    },
    memberRows() {
      const rows = this.proposal.members.length / this.maxMemberInRow;
      return Math.ceil(rows);
    },
    memberUsers() {
      const filterUsers = [this.proposal.createdBy.id];
      this.proposal.members.forEach((member) => {
        const memberId = typeof member == "string" ? member : member.id;
        filterUsers.push(memberId);
      });
      return this.usersList().filter((user) => !filterUsers.includes(user.id));
    },

    isStatusNotFinished() {
      return this.proposal.status != "CL" && this.proposal.status != "CO";
    },
  },
  created() {
    !this.usersList().length && this.getUsers();
  },
  methods: {
    membersPerRow(index) {
      const startingIndex = (index - 1) * this.maxMemberInRow;
      const endingIndex = this.maxMemberInRow * index;
      return this.proposal.members.slice(startingIndex, endingIndex);
    },
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUsers: GET_USERS,
    }),
    ...mapActions(PROPOSAL_MODULE, {
      updateProposalMembers: UPDATE_PROPOSAL_MEMBERS,
      updateProposal: UPDATE_PROPOSAL,
    }),
    ...mapMutations(PROPOSAL_MODULE, {
      updateProposalStatus: UPDATE_PROPOSAL_STATUS,
    }),
    onCancel() {
      this.isEditable = false;
      this.selectedMembers = this.proposal.members;
    },
    toggleModal() {
      this.showDeleteConfirmModal = !this.showDeleteConfirmModal;
    },
    toggleModalCL() {
      this.showDeleteConfirmModalCL = !this.showDeleteConfirmModalCL;
    },
    onCancelStatus() {
      this.statusToUpdate = "";
      this.isStatusEditable = false;
    },
    setStatusToOpen() {
      this.statusToUpdate = "OP";
      this.onUpdateStatus();
      this.toggleModal();
    },
    setStatusToDraft() {
      this.statusToUpdate = "DR";
      this.onUpdateStatus();
    },
    setStatusToClosed() {
      this.statusToUpdate = "CL";
      this.onUpdateStatus();
      this.toggleModalCL();
    },
    findChosenSuggestion() {
      let chosenPR = null;
      chosenPR = this.proposal.suggestions.find(
        (suggestion) => suggestion.purchaseRequest.status != "D"
      );
      return chosenPR.purchaseRequest.id;
    },
    onPurchaseRequestClick() {
      this.$router.push({
        name: "RequestDetail",
        params: { id: this.findChosenSuggestion() },
      });
    },
    onCancelDescription() {
      this.descToUpdate = "";
      this.isDescriptionEditable = false;
    },
    onUpdate(members) {
      this.loading = true;
      this.updateProposalMembers({
        proposalId: this.proposal.id,
        payload: {
          id: this.proposal.id,
          membersToAdd: members,
        },
      })
        .then((response) => {
          this.isEditable = false;
          this.showNotification(
            this.$t("proposals.members.success"),
            "success"
          );
          this.updateProposalParent(response);
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRemove(member) {
      this.loading = true;
      this.updateProposalMembers({
        proposalId: this.proposal.id,
        payload: {
          id: this.proposal.id,
          membersToRemove: [member.id],
        },
      })
        .then((response) => {
          this.isEditable = false;
          this.showNotification(
            this.$t("proposals.members.removed"),
            "success"
          );
          this.updateProposalStatus({
            id: response.id,
            members: response.members,
          });

          this.loading = false;
          this.updateProposalParent(response);
        })
        .catch((error) => {
          this.showErrorMessage(error);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onUpdateStatus() {
      this.updateProposalData(
        {
          proposalId: this.proposal.id,
          payload: { status: this.statusToUpdate },
        },
        this.onCancelStatus
      );
    },
    updateProposalData(payload, functionToCall) {
      this.updateProposal(payload)
        .then((response) => {
          this.showNotification("Proposal has been updated successfully");
          this.updateProposalStatus({
            id: response.id,
            status: response.status,
          });
          this.updateProposalParent(response);
          functionToCall();
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onUpdateDescription() {
      if (!this.descToUpdate) this.descToUpdate = this.proposal.description;
      this.updateProposalData(
        {
          proposalId: this.proposal.id,
          payload: { description: this.descToUpdate },
        },
        this.onCancelDescription
      );
    },
    onDescChange(event) {
      this.descToUpdate = event.target.value;
    },
  },
};
</script>

<style scoped>
.members-list {
  @apply flex pl-2 flex-col flex-1;
}

.members-holder {
  @apply flex  flex-row;
}

.custom-card {
  @apply flex flex-col md:flex-row md:space-x-10 mb-4;
}

.status-label {
  @apply px-2 py-1 inline-flex text-xs font-semibold rounded-full text-white ml-2;
}

.title {
  @apply text-sm text-gray-500;
}

.add-member {
  @apply flex items-center justify-center text-primary border border-gray-200 rounded-full ml-2 w-10 h-10 cursor-pointer hover:bg-primary hover:text-white border-primary;
}

.content-row {
  @apply mb-4 pb-4 flex  border-b border-gray-200 items-center last:border-b-0;
}

.popup-label {
  @apply w-[100px];
}

.popup-content {
  @apply text-sm text-gray-500 flex-1;
}
</style>
