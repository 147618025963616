<template>
  <Modal
    :show-modal="showModal"
    :close-modal="onModalClose"
    :footer-classes="['flex items-center w-full justify-between px-4 ']"
    :size-classes="['!max-w-[40rem] w-full']"
  >
    <template #icon>
      <CloudUploadIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <div class="flex items-center justify-center">
        <span class="mr-2">{{ $t("purchaseOrder.datevModal.title") }}</span>
        <img
          src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/DATEV_Datenservice_Signet_ohne_Kachel_RGB.svg"
          class="h-14 w-14"
        />
      </div>
    </template>
    <template #body>
      <div>
        <span class="text-base font-medium">
          {{ $t("purchaseOrder.datevModal.header") }}
        </span>
        <p v-if="notAllowedList?.length" class="py-2 text-hivebuy-red">
          {{ $t("purchaseOrder.datevModal.ignoredOrders") }}
        </p>
        <dl
          class="divide-y divide-gray-200 border border-gray-200 rounded-lg mt-4"
        >
          <div
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3 text-primary"
          >
            <dt>{{ $t("global.title") }}</dt>
            <dt class="justify-self-end flex">
              {{
                $t(
                  "companyManagement.integrations.datev.fallbackModal.collectiveCreditor"
                )
              }}
            </dt>
            <dt v-if="!isInvoice" class="justify-self-end flex">ID</dt>
          </div>
          <div
            v-for="object in allowedList"
            :key="object.id"
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3"
            :class="
              hasInvoiceError(object.id) ? 'text-hivebuy-red' : 'text-gray-500'
            "
          >
            <dt class="text-sm font-medium">
              <span> {{ object.title }}</span>
              <p
                v-if="hasInvoiceError(object.id)"
                class="text-gray-500 text-xs"
              >
                {{
                  $t(
                    `purchaseOrder.datevModal.errorCodes.${
                      hasInvoiceError(object.id)?.code
                    }`
                  )
                }}
              </p>
            </dt>
            <dd
              class="mt-1 flex text-sm text-gray-900 sm:mt-0 justify-self-center"
            >
              <span v-if="!hasInvoiceError(object.id)">
                <SwitchCheckbox v-model="object.collective" />
              </span>
              <ExclamationIcon v-else class="h-5 w-5 text-hivebuy-red mr-4" />
            </dd>
            <dd
              v-if="!isInvoice"
              class="mt-1 flex text-sm text-gray-900 sm:mt-0 justify-self-end"
            >
              <span class="flex-grow">
                {{ object.externalId }}
              </span>
            </dd>
          </div>
          <div
            v-for="object in notAllowedList"
            :key="object.id"
            class="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3 px-3 text-gray-500"
          >
            <dt class="text-sm font-medium">
              <span> {{ object.title }}</span>
            </dt>
            <dd
              class="mt-1 flex text-sm text-gray-900 sm:mt-0 justify-self-end"
            >
              <p v-if="errorReason(object)" class="text-hivebuy-red">
                {{ $t(`purchaseOrder.datevModal.${object.reason}`) }}
              </p>
            </dd>
            <dd
              v-if="!isInvoice"
              class="mt-1 flex text-sm text-gray-900 sm:mt-0 justify-self-end"
            >
              <span
                :class="errorReason(object) ? 'text-hivebuy-red' : ''"
                class="flex-grow"
              >
                {{ object.externalId }}
              </span>
            </dd>
          </div>
        </dl>
        <div v-if="connectedClients.length > 1" class="pt-6 pb-2">
          <HivebuySelect
            v-model="clientToSendTo"
            :label="$t('purchaseOrder.datevModal.clientsLabel')"
            :filter="false"
            :options="connectedClients"
            :clearable="false"
            data-key="id"
            :option-value="'id'"
            :option-label="'name'"
          />
        </div>
        <div class="mt-4 flex items-center">
          <CloudUploadIcon class="h-6 w-6 text-hivebuy-green mr-4" />
          {{
            $t("purchaseOrder.datevModal.toBeSent", {
              count: allowedList?.length || 0,
            })
          }}
        </div>
      </div>
      <FieldError v-if="apiError" :text="apiError" />
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose">
        {{ $t("buttons.close") }}
      </Button>
      <Button
        :on-click-handler="sendData"
        :loading="loading"
        :disabled="!allowedList?.length"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import {
  Modal,
  Button,
  FieldError,
  SwitchCheckbox,
} from "@/components/shared/index";
import { HivebuySelect } from "design-buy";
import { CloudUploadIcon, ExclamationIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPLOAD_PURCHASE_ORDER_INVOICES_TO_DATEV,
  GET_DATEV_STATUS,
} from "@/store/CompanyManagement/types";

export default {
  components: {
    Modal,
    CloudUploadIcon,
    Button,
    SwitchCheckbox,
    FieldError,
    ExclamationIcon,
    HivebuySelect,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    objects: {
      type: Array,
      required: true,
    },
    source: {
      type: String,
      default: "purchaseOrder",
    },
  },
  data() {
    return {
      loading: false,
      apiError: null,
      errors: [],
      idsSendInProgress: [],
      allowedList: [],
      notAllowedList: [],
      clients: [],
      clientToSendTo: null,
    };
  },
  computed: {
    isInvoice() {
      return this.source === "invoice";
    },

    connectedClients() {
      return this.clients.filter((client) => client.connected);
    },
  },
  updated() {
    const validList = [];
    const errorList = [];
    if (this.objects) {
      this.objects.forEach((object) => {
        if (this.errorReason(object)) {
          errorList.push(
            Object.assign({}, object, { reason: this.errorReason(object) })
          );
        } else {
          validList.push(object);
        }
      });
    }
    this.allowedList = [...new Set(validList)];
    this.notAllowedList = [...new Set(errorList)];
    if (!this.clients.length) {
      this.getDatevStatus().then((integration) => {
        this.clients = integration.datevIntegration.clients;
        this.clientToSendTo = this.connectedClients[0]?.id;
      });
    }
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      uploadPurchaseOrderInvoicesToDatev:
        UPLOAD_PURCHASE_ORDER_INVOICES_TO_DATEV,
    }),
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getDatevStatus: GET_DATEV_STATUS,
    }),
    errorReason(obj) {
      if (obj.datevJob?.status >= 15 && obj.datevJob.status <= 20)
        return "alreadySent";

      if (this.isInvoice) {
        if (obj.status != "A") return "notApproved";
      } else {
        if (obj.status != "CO") return "statusNotComplete";
      }
    },

    isInvoiceSent() {},

    hasInvoiceError(id) {
      const attr = this.isInvoice ? "invoiceId" : "purchaseOrderId";

      return this.errors.find((error) => error[attr] === id);
    },

    sendData() {
      this.loading = true;
      const records = this.allowedList.map((record) => {
        return {
          id: record.id,
          collective: record.collective,
          client_id: this.clientToSendTo,
        };
      });
      let payload = {};
      if (this.isInvoice) {
        payload = {
          invoices: records,
        };
      } else {
        payload = {
          purchaseOrders: records,
        };
      }

      this.uploadPurchaseOrderInvoicesToDatev(payload)
        .then((response) => {
          this.showNotification(
            this.$t("purchaseOrder.datevModal.uploadSuccess")
          );
          this.errors = response.errors;
          this.idsSendInProgress = response.idsSendInProgress;
          this.closeModalAfterUploading();
        })
        .catch((error) => {
          const errorMessage =
            error.details?.response?.data?.details?.datevError?.message;
          if (errorMessage) {
            this.apiError =
              "This purchase order was already uploaded to DATEV and cannot be uploaded anymore.";
          }
        })
        .finally(() => (this.loading = false));
    },
    closeModalAfterUploading() {
      if (!this.errors.length) {
        this.onModalClose();
      }
    },
    onModalClose() {
      this.closeModal();
      this.errors = [];
      this.allowedList = [];
      this.notAllowedList = [];
      this.idsSendInProgress = [];
      this.apiError = null;
    },
  },
};
</script>
