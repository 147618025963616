export default {
  myPurchaseRequests: { results: [] },
  actionRequiredPurchaseRequests: { results: [] },
  selectedSideBar: "",
  showNewVersionAlert: false,
  analytics: {
    purchaseRequests: { purchaseRequests: [] },
    departments: [],
    budgets: {
      departments: [],
    },
  },
  detailedAnalytics: {
    purchaseRequests: [],
    statistics: {},
  },
  news: [],
  userRelevantNews: [],
};
