<template>
  <CardSection
    :loading="loading"
    :records="actionRequiredPurchaseRequests"
    :title="$t('dashboard.headerMyActions')"
    :total-records-count="totalRecords"
  >
    <template #col-tickets-content>
      <ul v-auto-animate>
        <li
          v-for="purchaseRequest in actionRequiredPurchaseRequests"
          :key="purchaseRequest.id"
        >
          <div class="ticket purchase-request">
            <router-link
              :to="{
                name: 'RequestDetail',
                params: { id: purchaseRequest.id },
              }"
            >
              <div class="ticket-header group cursor-pointer">
                <div class="ticket-header-left">
                  <div class="ticket-title">
                    {{ purchaseRequest.title }}
                  </div>
                  <div class="ticket-meta">
                    <span>
                      {{ purchaseRequest.externalId }}
                    </span>
                    <span>-</span>
                    <span>
                      {{ formattedDate(purchaseRequest.requestedAt) }}
                    </span>
                  </div>
                </div>

                <div
                  class="ticket-header-right text-primary transition duration-300 group-hover:border-primary group-hover:bg-primary group-hover:text-white"
                >
                  <ChevronRightIcon class="w-4 h-4" />
                </div>
              </div>
            </router-link>
            <div class="ticket-content">
              <ul class="content-listing">
                <li>
                  <div class="lab">
                    {{ $t("global.category") }}
                  </div>
                  <div class="val truncate">
                    <Category :purchase-request="purchaseRequest" squashed />
                  </div>
                </li>
                <li>
                  <div class="lab">
                    {{ $t("global.supplier") }}
                  </div>
                  <div class="val">
                    <SupplierName
                      :purchase-request="purchaseRequest"
                      size="small"
                      disable-supplier-badge-tooltip
                    />
                  </div>
                </li>
                <li>
                  <div class="lab truncate">{{ $t("global.requestedBy") }}</div>
                  <div class="val truncate">
                    <UserWithAvatar
                      :user="purchaseRequest.requestedBy"
                      :avatar-dimension="6"
                      :show-name="true"
                      :text-classes="['text-xs font-semibold text-gray-700']"
                      :show-tooltip="true"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="ticket-footer">
              <div class="ticket-footer-left">
                <i18n-n
                  :value="parseFloat(purchaseRequest.totalAmount)"
                  format="currency"
                  :locale="currencyToLocale(company.currency)"
                />
              </div>
              <div class="ticket-footer-right">
                <router-link
                  class="card-footer-right"
                  :to="{
                    name: 'RequestDetail',
                    params: { id: purchaseRequest.id },
                  }"
                >
                  <div class="button respond-to group">
                    {{ $t("dashboard.myActionsCard.respondButton") }}
                    <ChevronRightIcon
                      class="w-3.5 h-3.5 ml-2 text-primary opacity-20 -mr-3 group-hover:opacity-100"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </CardSection>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { mapActions, mapState, mapGetters } from "vuex";

import {
  DASHBOARD_MODULE,
  GET_ACTION_REQUIRED_PURCHASE_REQUESTS,
} from "@/store/Dashboard/types";
import { Category, SupplierName, UserWithAvatar } from "@/components/shared";
import { currencyToLocale } from "@/utils/utility_methods";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import CardSection from "@/components/Dashboard/AdvanceUserDashboard/CardSection";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
export default {
  name: "ActionablePurchaseRequests",
  components: {
    CardSection,
    ChevronRightIcon,
    UserWithAvatar,
    Category,
    SupplierName,
  },
  data() {
    return {
      loading: false,
      hoverOnCard: false,
      pageSize: 8,
    };
  },
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    ...mapState(DASHBOARD_MODULE, {
      requests: (state) => state.actionRequiredPurchaseRequests.results,
      totalRecords: (state) =>
        state.actionRequiredPurchaseRequests.totalRecords,
    }),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    actionRequiredPurchaseRequests() {
      return this.requests.map((request) => {
        return this.requestDetails(request);
      });
    },
    currentResults() {
      return this.actionRequiredPurchaseRequests?.length;
    },
  },
  watch: {
    currentResults: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (newVal < this.totalRecords) {
            this.fetchActionRequiredPurchaseRequests(false);
          }
        }
      },
    },
  },
  mounted() {
    if (!this?.actionRequiredPurchaseRequests?.length || !this?.totalRecords) {
      this.fetchActionRequiredPurchaseRequests();
    }
  },
  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      getActionRequiredPurchaseRequests: GET_ACTION_REQUIRED_PURCHASE_REQUESTS,
    }),
    fetchActionRequiredPurchaseRequests(loading = true) {
      this.loading = loading;
      this.getActionRequiredPurchaseRequests(this.paginationParams())
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
    paginationParams() {
      return {
        pageSize: this.pageSize,
      };
    },
    currencyToLocale,
  },
};
</script>

<style scoped>
.ticket.purchase-request .ticket-header::before {
  @apply bg-hivebuy-yellow;
}

.ticket.purchase-request .ticket-footer-right .button {
  @apply rounded-full py-1 px-4 justify-center text-primary font-semibold text-xs flex items-center cursor-pointer border border-primary/20 hover:border-primary bg-white;
}

.purchase-request .ticket-content .content-listing li {
  @apply border-b border-gray-200 px-2 py-2 flex items-center text-xs text-gray-600 gap-x-2 md:gap-x-4;
}

.purchase-request .ticket-content .content-listing li .val {
  @apply font-semibold line-clamp-1;
}
</style>
