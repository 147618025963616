<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden z-[100]"
      @close="closeModalHandler"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-600"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-600"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <form
                class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-4 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          {{
                            companySupplier.name ||
                            $t(
                              "companyManagement.companySupplier.addModal.headingAdd"
                            )
                          }}
                        </DialogTitle>
                        <p v-if="isEdit" class="text-sm text-gray-500">
                          {{
                            $t(
                              "companyManagement.companySupplier.addModal.headingEditSubtext"
                            )
                          }}
                          {{ companySupplier.name }}.
                        </p>
                        <p v-else class="text-sm text-gray-500">
                          {{
                            $t(
                              "companyManagement.companySupplier.addModal.headingSubtext"
                            )
                          }}
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button
                          type="button"
                          class="text-gray-400 hover:text-gray-500 outline-none"
                          @click="closeModalHandler"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div
                    class="md:grid md:grid-cols-2 gap-4 space-y-1 sm:divide-y sm:divide-gray-200"
                  >
                    <div
                      class="col-span-2 md:grid md:grid-cols-2 gap-x-4 gap-y-2 px-4 sm:px-7 sm:py-2"
                    >
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="name"
                          :error-text="error(v$.companySupplier.name)"
                          :value="
                            companySupplier.name ||
                            companySupplier?.supplier?.name
                          "
                          name="name"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.placeholderName'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.name'
                            )
                          "
                          :disabled="!!companySupplier.supplier"
                          :on-value-change="onAttributeChange"
                          is-mandatory
                        />
                      </div>

                      <div
                        v-if="!companySupplier.supplier"
                        class="mb-6 md:mb-0"
                      >
                        <Input
                          id="displayName"
                          :value="companySupplier.displayName"
                          name="displayName"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.placeholderDisplayName'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.displayName'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>

                      <div
                        v-if="!companySupplier.supplier"
                        class="mb-6 md:mb-0"
                      >
                        <Input
                          id="website"
                          :value="companySupplier.website"
                          name="website"
                          placeholder="www.example.com"
                          type="url"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.website'
                            )
                          "
                          :is-link="true"
                          :on-value-change="onAttributeChange"
                          :classes="'rounded-r-md'"
                          :error-text="error(v$.companySupplier.website)"
                        />
                      </div>
                      <div
                        v-if="!companySupplier.supplier"
                        class="mb-6 md:mb-0"
                      >
                        <Input
                          id="contactName"
                          :value="companySupplier.contactName"
                          name="contactName"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.placeholderContactName'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.contactName'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div
                        v-if="!companySupplier.supplier"
                        class="mb-6 md:mb-0"
                      >
                        <Input
                          id="contactEmail"
                          :error-text="error(v$.companySupplier.contactEmail)"
                          :value="companySupplier.contactEmail"
                          name="contactEmail"
                          placeholder="test@hivebuy.de"
                          type="email"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.contactEmail'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div
                        v-if="!companySupplier.supplier"
                        class="mb-6 md:mb-0"
                      >
                        <Input
                          id="contactPhone"
                          :value="companySupplier.contactPhone"
                          name="contactPhone"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.placeholderContactPhone'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.contactPhone'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="supplierNumber"
                          :value="companySupplier.supplierNumber"
                          name="supplierNumber"
                          placeholder="e.g. 70004"
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.supplierNumber'
                            )
                          "
                          :on-value-change="onAttributeChange"
                          :classes="'rounded-r-md'"
                        />
                      </div>
                      <div class="mb-6 md:mb-0">
                        <Input
                          id="externalSupplierNumber"
                          :value="companySupplier.externalSupplierNumber"
                          name="externalSupplierNumber"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.externalSupplierNumber'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.externalSupplierNumber'
                            )
                          "
                          :on-value-change="onAttributeChange"
                          :classes="'rounded-r-md'"
                        />
                      </div>
                    </div>
                    <div
                      v-if="!companySupplier.supplier"
                      class="col-span-2 px-4 sm:px-7 sm:py-2 space-y-4 mb-6 md:mb-0"
                    >
                      <div class="mb-4 pt-2">
                        <div class="label !flex">
                          <i18n-t
                            keypath="companyManagement.companySupplier.addModal.categories"
                            tag="p"
                          >
                            <template #permission>
                              <span class="font-bold text-primary">
                                {{
                                  $t(
                                    `companyManagement.companySupplier.addModal.${permissionType}`
                                  )
                                }}
                              </span>
                            </template>
                          </i18n-t>
                          <span
                            v-if="!companySupplier.assignedCategoriesNotAllowed"
                            class="text-hivebuy-red ml-1"
                          >
                            *
                          </span>
                        </div>
                        <div class="mt-1">
                          <Multiselect
                            v-model="companySupplier.categories"
                            :mode="'tags'"
                            :searchable="true"
                            :options="categoriesList"
                            :placeholder="
                              $t(
                                'companyManagement.companySupplier.addModal.placeholderCategories'
                              )
                            "
                            :value-prop="'id'"
                            :label="'name'"
                            :track-by="'name'"
                            :class="{
                              'border-red-400':
                                v$.companySupplier.categories?.$error,
                            }"
                          >
                          </Multiselect>

                          <FieldError
                            v-if="v$.companySupplier.categories?.$error"
                            :text="'Please select category'"
                          />
                        </div>
                      </div>
                      <div class="mt-2">
                        <SwitchCheckbox
                          v-model="companySupplier.assignedCategoriesNotAllowed"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.selectAllCategories'
                            )
                          "
                        />
                        <div class="py-2 text-xs text-primary">
                          {{
                            $t(
                              "companyManagement.companySupplier.addModal.selectAllCategoriesText"
                            )
                          }}
                        </div>
                      </div>
                      <div class="border border-b-gray-100"></div>
                      <div class="mb-6 md:mb-0">
                        <DateRangeCalendar
                          v-model="companySupplier.validDate"
                          :start-date-tooltip-text="
                            $t(
                              'companyManagement.companySupplier.addModal.startDateTooltipText'
                            )
                          "
                          :end-date-tooltip-text="
                            $t(
                              'companyManagement.companySupplier.addModal.endDateTooltipText'
                            )
                          "
                        />
                      </div>
                      <div
                        v-if="!companySupplier.supplier"
                        class="mt-6 mb-6 md:mb-0"
                      >
                        <label
                          class="block text-sm font-medium text-gray-700 my-2"
                        >
                          {{
                            $t(
                              "companyManagement.companySupplier.addModal.responsibleUser"
                            )
                          }}
                          <span class="text-red-600">*</span>
                        </label>
                        <div class="mt-1">
                          <MemberSelector
                            :value="companySupplier.responsibleUser"
                            :on-value-change="setResponsibleUser"
                            :members-list="activeMembers"
                          />

                          <FieldError
                            v-if="v$.companySupplier.responsibleUser.$error"
                            :text="
                              $t(
                                'companyManagement.companySupplier.addModal.errorResponsibleUser'
                              )
                            "
                          />
                        </div>
                      </div>
                      <div
                        v-if="!companySupplier.supplier"
                        class="mt-6 mb-6 md:mb-0"
                      >
                        <div class="mt-1">
                          <SwitchCheckbox
                            v-model="companySupplier.addToWorkflow"
                            :label="
                              $t(
                                'companyManagement.companySupplier.addModal.addUserToWorkflow'
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div :class="['col-span-2 px-4 pt-4 sm:px-7']">
                      <div
                        :class="[
                          'collapsible-section',
                          showAddress ? 'rounded-b-none' : 'rounded',
                          { 'border-hivebuy-red': addressAndPaymentError },
                        ]"
                      >
                        <ChevronDownIcon
                          class="chevron"
                          :class="showAddress ? 'chevron-open' : ''"
                        />
                        <span @click="showAddress = !showAddress">{{
                          $t(
                            "companyManagement.companySupplier.addModal.addressAndPayment"
                          )
                        }}</span>
                        <ChevronDownIcon
                          class="chevron"
                          :class="showAddress ? 'chevron-open' : ''"
                        />
                      </div>
                      <div v-if="showAddress">
                        <div
                          :class="[
                            'py-2 bg-gray-50 p-2 rounded rounded-t-none border border-t-0 border-gray-200',
                            { 'border-hivebuy-red': addressAndPaymentError },
                          ]"
                        >
                          <div
                            class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4"
                          >
                            <Input
                              id="street"
                              :value="companySupplier.street"
                              name="street"
                              :placeholder="
                                $t('companyManagement.profile.address.street')
                              "
                              type="text"
                              :label="
                                $t('companyManagement.profile.address.street')
                              "
                              :on-value-change="onAttributeChange"
                            />
                            <Input
                              id="houseNumber"
                              :value="companySupplier.houseNumber"
                              name="houseNumber"
                              :placeholder="
                                $t('companyManagement.profile.address.number')
                              "
                              type="text"
                              :label="
                                $t('companyManagement.profile.address.number')
                              "
                              :on-value-change="onAttributeChange"
                              :error-text="
                                error(v$.companySupplier.houseNumber)
                              "
                            />
                            <Input
                              id="postal"
                              :value="companySupplier.postal"
                              name="postal"
                              :placeholder="
                                $t('companyManagement.profile.address.postal')
                              "
                              type="text"
                              :label="
                                $t('companyManagement.profile.address.postal')
                              "
                              :on-value-change="onAttributeChange"
                            />
                            <Input
                              id="city"
                              :value="companySupplier.city"
                              name="city"
                              :placeholder="
                                $t('companyManagement.profile.address.city')
                              "
                              type="text"
                              :label="
                                $t('companyManagement.profile.address.city')
                              "
                              :on-value-change="onAttributeChange"
                            />

                            <Input
                              id="country"
                              :value="companySupplier.country"
                              name="country"
                              :placeholder="
                                $t('companyManagement.profile.address.country')
                              "
                              type="text"
                              :label="
                                $t('companyManagement.profile.address.country')
                              "
                              :on-value-change="onAttributeChange"
                            />
                          </div>
                          <div class="mt-4 gap-y-2 gap-x-4 grid grid-cols-1">
                            <Input
                              id="taxNumber"
                              :value="companySupplier.taxNumber"
                              name="taxNumber"
                              :placeholder="$t('global.pricing.vatNumber')"
                              type="text"
                              :label="$t('global.pricing.vatNumber')"
                              :on-value-change="onAttributeChange"
                            />
                            <Input
                              id="iban"
                              :value="companySupplier.iban"
                              name="iban"
                              :placeholder="
                                $t(
                                  'companyManagement.companySupplier.addModal.iban'
                                )
                              "
                              type="text"
                              :label="
                                $t(
                                  'companyManagement.companySupplier.addModal.iban'
                                )
                              "
                              :on-value-change="onAttributeChange"
                              :error-text="error(v$.companySupplier.iban)"
                            />
                            <Input
                              id="bic"
                              :value="companySupplier.bic"
                              name="bic"
                              :placeholder="
                                $t(
                                  'companyManagement.companySupplier.addModal.bic'
                                )
                              "
                              type="text"
                              :label="
                                $t(
                                  'companyManagement.companySupplier.addModal.bic'
                                )
                              "
                              :on-value-change="onAttributeChange"
                              :error-text="error(v$.companySupplier.bic)"
                            />
                            <Input
                              id="paymentTerms"
                              :value="companySupplier.paymentTerms"
                              name="paymentTerms"
                              :placeholder="$t('global.pricing.paymentTerms')"
                              type="text"
                              :label="$t('global.pricing.paymentTerms')"
                              :on-value-change="onAttributeChange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="isSupplierBudgetEditable"
                      :class="['col-span-2 px-4 pt-4 sm:px-7']"
                    >
                      <div
                        :class="[
                          'collapsible-section',
                          {
                            'rounded-b-none': showSupplierBudgetInfo,
                            rounded: !showSupplierBudgetInfo,
                          },
                        ]"
                      >
                        <ChevronDownIcon
                          class="chevron"
                          :class="[
                            'chevron',
                            { 'chevron-open': showSupplierBudgetInfo },
                          ]"
                        />
                        <span
                          @click="
                            showSupplierBudgetInfo = !showSupplierBudgetInfo
                          "
                          >{{
                            $t(
                              "companyManagement.companySupplier.addModal.supplierBudgetInformation"
                            )
                          }}</span
                        >
                        <ChevronDownIcon
                          :class="[
                            'chevron',
                            { 'chevron-open': showSupplierBudgetInfo },
                          ]"
                        />
                      </div>
                      <div v-if="showSupplierBudgetInfo">
                        <div
                          class="py-2 bg-gray-50 p-2 rounded rounded-t-none border border-t-0 border-gray-200"
                        >
                          <div
                            class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4"
                          >
                            <CurrencyInput
                              v-model="supplierBudgetAmount"
                              :label="$t('global.pricing.amount')"
                              :placeholder="$t('global.pricing.amount')"
                              :currency-value="
                                companySupplier.budget.amountCurrency
                              "
                            />
                            <Input
                              id="notificationAmount"
                              :value="companySupplier.notificationAmount"
                              name="notificationAmount"
                              type="number"
                              :placeholder="
                                $t(
                                  'companyManagement.companySupplier.addModal.supplierBudgetInfo.notificationAmount'
                                )
                              "
                              :label="
                                $t(
                                  'companyManagement.companySupplier.addModal.supplierBudgetInfo.notificationAmount'
                                )
                              "
                              :on-value-change="onAttributeChange"
                              :error-text="
                                v$.companySupplier.notificationAmount
                                  ?.$errors[0]?.$message
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <SupplierBudget
                        v-if="showSupplierBudget"
                        :supplier="companySupplier.id"
                      />
                    </div>

                    <div
                      v-if="showLegalEntityConfiguration"
                      class="col-span-2 px-4 pt-4 sm:px-7"
                    >
                      <div v-if="isApiLoading('companySupplierLegalEntities')">
                        <HivebuyLoader class="w-10 h-10" />
                      </div>
                      <LegalEntityInfo
                        v-else
                        v-model="companySupplier.companySupplierLegalEntities"
                        :active-members="activeMembers"
                      />
                    </div>

                    <div
                      class="col-span-2 px-4 grid grid-cols-2 gap-x-4 pt-4 sm:px-7"
                    >
                      <div v-if="!companySupplier.supplier">
                        <SwitchCheckbox
                          v-model="preferredCompanySupplier"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.active'
                            )
                          "
                        />
                      </div>
                      <div class="">
                        <SwitchCheckbox
                          v-model="companySupplier.includeLegalEntityInOrder"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.includeLegalEntityInOrder'
                            )
                          "
                        />
                      </div>
                    </div>
                    <div
                      v-if="!companySupplier.supplier"
                      class="mb-6 md:mb-0 col-span-2 sm:px-7 sm:py-4 px-4"
                    >
                      <TextArea
                        id="notes"
                        :value="companySupplier.notes"
                        name="notes"
                        :placeholder="
                          $t(
                            'companyManagement.companySupplier.addModal.placeholderNotes'
                          )
                        "
                        :rows="3"
                        :label="
                          $t('companyManagement.companySupplier.addModal.notes')
                        "
                        :on-value-change="onAttributeChange"
                      />
                    </div>
                    <div
                      v-if="!companySupplier.supplier"
                      class="mb-6 md:mb-0 col-span-2 sm:px-7 sm:py-4 px-4"
                    >
                      <div class="relative">
                        <SwitchGroup
                          as="div"
                          class="flex items-center justify-between mb-4"
                        >
                          <span class="flex-grow flex flex-col">
                            <SwitchLabel
                              as="span"
                              class="text-sm font-medium text-gray-900"
                              passive
                              >{{
                                $t(
                                  "companyManagement.companySupplier.addModal.enableEmail"
                                )
                              }}
                            </SwitchLabel>
                            <SwitchDescription
                              as="span"
                              class="text-sm text-gray-500 pr-2"
                              >{{
                                $t(
                                  "companyManagement.companySupplier.addModal.enableEmailInfoText"
                                )
                              }}</SwitchDescription
                            >
                          </span>
                          <Switch
                            v-model="companySupplier.orderViaEmail"
                            :class="[
                              companySupplier.orderViaEmail
                                ? 'bg-teal-500'
                                : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ',
                            ]"
                          >
                            <span
                              aria-hidden="true"
                              :class="[
                                companySupplier.orderViaEmail
                                  ? 'translate-x-5'
                                  : 'translate-x-0',
                                'pointer-events-none flex items-center justify-center h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                              ]"
                            >
                              <span v-if="!companySupplier.orderViaEmail">
                                <XIcon class="text-gray-500 w-3" />
                              </span>
                              <span v-else>
                                <CheckIcon class="text-teal-500 w-3" /> </span
                            ></span>
                          </Switch>
                        </SwitchGroup>
                      </div>
                      <div
                        v-if="companySupplier.orderViaEmail"
                        class="grid grid-cols-2 gap-x-4 gap-y-4"
                      >
                        <Input
                          id="orderEmail"
                          :value="companySupplier.orderEmail"
                          :error-text="
                            genericError(v$.companySupplier.orderEmail)
                          "
                          name="orderEmail"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.orderEmail'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.orderEmail'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                        <Input
                          id="replyToEmail"
                          :value="companySupplier.replyToEmail"
                          name="replyToEmail"
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.replyToEmail'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.replyToEmail'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                        <Input
                          id="customerNumber"
                          :value="companySupplier.customerNumber"
                          name="customerNumber"
                          :error-text="
                            genericError(v$.companySupplier.customerNumber)
                          "
                          :placeholder="
                            $t(
                              'companyManagement.companySupplier.addModal.orderIdentifier'
                            )
                          "
                          type="text"
                          :label="
                            $t(
                              'companyManagement.companySupplier.addModal.orderIdentifier'
                            )
                          "
                          :on-value-change="onAttributeChange"
                        />
                        <DropDown
                          :value="companySupplier.language"
                          :on-change="onLanguageChange"
                          :label="$t('profile.language')"
                          class="mt-1"
                        />
                        <div class="col-span-2">
                          <TextArea
                            id="orderNote"
                            :value="companySupplier.orderNote"
                            name="orderNote"
                            :placeholder="
                              $t(
                                'companyManagement.companySupplier.addModal.orderPlaceholderNotes'
                              )
                            "
                            type="text"
                            :rows="3"
                            :label="
                              $t(
                                'companyManagement.companySupplier.addModal.orderPlaceholderNotes'
                              )
                            "
                            :on-value-change="onAttributeChange"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        companySupplier?.supplier && !companySupplier.active
                      "
                      class="col-span-2 px-4 pt-4 sm:px-7"
                    >
                      <div
                        class="border border-hivebuy-red rounded bg-gray-50 p-1 sm:p-2 text-sm text-hivebuy-red"
                      >
                        {{
                          $t(
                            "companyManagement.companySupplier.addModal.integratedSupplierNotActive",
                            { name: companySupplier?.name }
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="v$.$error"
                  class="py-2 flex justify-center text-sm font-medium text-hivebuy-red"
                >
                  {{ $t("global.errorMessages.genericFieldError") }}
                </div>
                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6"
                >
                  <div class="space-x-3 flex justify-end">
                    <Button
                      :on-click-handler="onModalClose"
                      :color="'gray'"
                      :disabled="loading"
                    >
                      {{
                        $t(
                          "companyManagement.companySupplier.addModal.cancelButton"
                        )
                      }}
                    </Button>
                    <Button :on-click-handler="onSave" :loading="loading">
                      {{
                        isEdit && !isSourceContract
                          ? $t(
                              "companyManagement.companySupplier.addModal.editButton"
                            )
                          : $t(
                              "companyManagement.companySupplier.addModal.addButton"
                            )
                      }}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import useValidate from "@vuelidate/core";
import { errorMixin } from "@/components/mixins/index.js";
import {
  deepClone,
  formatDateInHyphenFormat,
  isObjectEmpty,
  isEmptyString,
} from "@/utils/utility_methods";
import { email, requiredIf, between, helpers } from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  SAVE_COMPANY_SUPPLIER,
  FETCH_COMPANY_SUPPLIER,
  UPDATE_BUDGET,
} from "@/store/CompanyManagement/types";
import Multiselect from "@vueform/multiselect";
import { localeMixin } from "@/components/mixins/index.js";
import {
  DateRangeCalendar,
  TextArea,
  Input,
  Button,
  FieldError,
  MemberSelector,
  SwitchCheckbox,
  HivebuyLoader,
  CurrencyInput,
} from "@/components/shared/index";
import { XIcon, CheckIcon, ChevronDownIcon } from "@heroicons/vue/outline";
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import LegalEntityInfo from "@/components/CompanyManagement/CompanySuppliers/LegalEntityInfo.vue";
import SupplierBudget from "@/components/CompanyManagement/CompanySuppliers/SupplierBudget.vue";

export default {
  name: "CompanySupplierForm",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    DateRangeCalendar,
    Multiselect,
    FieldError,
    Button,
    SwitchCheckbox,
    Input,
    MemberSelector,
    TextArea,
    XIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    CheckIcon,
    ChevronDownIcon,
    LegalEntityInfo,
    HivebuyLoader,
    CurrencyInput,
    SupplierBudget,
  },
  mixins: [errorMixin, localeMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModalHandler: {
      type: Function,
      required: true,
    },
    companySupplierToEdit: {
      type: Object,
      default: null,
    },
    isSourceContract: {
      type: Boolean,
      default: false,
    },
    onCompanySupplierCreate: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      v$: useValidate(),
      showMoreFields: false,
      companySupplier: this.defaultValues(),
      showAddress: false,
      loading: false,
      showSupplierBudgetInfo: false,
      supplierBudgetAmount: 0,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["users", "globalPolicies"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoriesList",
      "usersList",
      "getGlobalPolicy",
    ]),
    addressAndPaymentError() {
      const errorFields = ["houseNumber", "iban", "bic"];
      return errorFields.some((field) =>
        this.error(this.v$.companySupplier[field])
      );
    },
    activeMembers() {
      return this.usersList().filter((user) => user.id);
    },
    isEdit() {
      return !isObjectEmpty(this.companySupplierToEdit);
    },
    preferredCompanySupplier: {
      get() {
        return this.companySupplier.isPreferred;
      },
      set(value) {
        this.companySupplier.isPreferred = value;
      },
    },
    showLegalEntityConfiguration() {
      return this.isEdit;
    },
    isSupplierBudgetEditable() {
      return this.isEdit && this.isFeatureAllowed("supplierBudget");
    },
    showSupplierBudget() {
      const { budget } = this.companySupplier;
      return (
        this.isSupplierBudgetEditable &&
        !isObjectEmpty(budget) &&
        parseFloat(budget.amount) > 0
      );
    },
    permissionType() {
      return this.companySupplier.assignedCategoriesNotAllowed
        ? "categoriesDenyAccess"
        : "categoriesAllowAccess";
    },
  },
  watch: {
    companySupplierToEdit: {
      handler(newVal, oldVal) {
        if (!isObjectEmpty(newVal) && newVal != oldVal) {
          if (
            this.showLegalEntityConfiguration &&
            !newVal.companySupplierLegalEntities.length
          )
            this.fetchCompanySupplierLegalEntities();

          this.companySupplier = deepClone(newVal);

          if (newVal.categories.length) {
            this.companySupplier.categories = newVal.categories.map(
              (category) => category?.id || category
            );
          }
          if (newVal?.supplier) {
            this.companySupplier.name = newVal.name;
          }
          if (newVal.responsibleUser) {
            this.companySupplier.responsibleUser =
              newVal.responsibleUser.id || newVal.responsibleUser;
          }

          this.companySupplier.validDate = {
            ...this.companySupplier.validDate,
            ...{
              start: this.companySupplier.validFrom,
              end: this.companySupplier.validTo,
            },
          };

          this.supplierBudgetAmount = newVal.budget?.amount;
        } else {
          this.companySupplier = { validDate: {} };
        }
      },
    },
    showModal: {
      handler(newVal) {
        if (newVal && !this.isEdit) {
          this.companySupplier.addToWorkflow =
            this.getGlobalPolicy("RESPONSIBLE_USER")?.isActive || false;
        }
      },
    },
  },
  methods: {
    isEmptyString,
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      saveCompanySupplier: SAVE_COMPANY_SUPPLIER,
      fetchCompanySupplier: FETCH_COMPANY_SUPPLIER,
      updateBudget: UPDATE_BUDGET,
    }),
    onLanguageChange(val) {
      this.companySupplier.language = val;
    },
    fetchCompanySupplierLegalEntities() {
      this.setApiLoading({ key: "companySupplierLegalEntities", value: true });
      this.fetchCompanySupplier(this.companySupplierToEdit.id, true)
        .then((data) => {
          this.companySupplier.companySupplierLegalEntities =
            data.companySupplierLegalEntities;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() =>
          this.setApiLoading({
            key: "companySupplierLegalEntities",
            value: false,
          })
        );
    },
    defaultValues() {
      const currentDate = new Date();
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() + 24);

      return {
        name: "",
        displayName: "",
        active: false,
        validDate: {
          start: formatDateInHyphenFormat(currentDate),
          end: formatDateInHyphenFormat(endDate),
        },
        supplierNumber: null,
        website: "",
        responsibleUser: "",
        notes: "",
        categories: [],
        orderViaEmail: false,
        includeLegalEntityInOrder: false,
        addToWorkflow: false,
        notificationAmount: 0,
        assignedCategoriesNotAllowed: false,
      };
    },
    onAttributeChange(event) {
      let { value } = event.target;
      const { name } = event.target;

      if (name === "website") {
        value = this.handleLinkValue(value);
      }
      this.companySupplier[name] = value;
    },
    handleLinkValue(value) {
      if (value == "") {
        return null;
      }
      if (!value.includes("http://") && !value.includes("https://")) {
        value = "http://" + value.replace(/\s/g, "");
      }
      return value;
    },
    onModalClose() {
      this.closeModalHandler();
      this.v$.$reset();
      if (!this.isEdit) this.companySupplier = this.defaultValues();
    },
    async onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const { start, end } = this.companySupplier.validDate;
        let payload = Object.assign({}, this.companySupplier, {
          displayName:
            this.companySupplier.displayName || this.companySupplier.name,
        });

        if (start && end) {
          payload = Object.assign({}, payload, {
            validFrom: formatDateInHyphenFormat(start),
            validTo: formatDateInHyphenFormat(end),
          });
        }
        if (payload.supplier) delete payload.supplier;
        this.loading = true;

        try {
          if (
            this.isSupplierBudgetEditable &&
            this.companySupplierToEdit.budget.amount !=
              this.supplierBudgetAmount
          ) {
            const payload = {
              id: this.companySupplierToEdit.budget.id,
              amount: this.supplierBudgetAmount,
            };
            await this.updateBudget(payload);
          }
          const supplierResponseData = await this.saveCompanySupplier(payload);
          if (this.isSourceContract) {
            this.onCompanySupplierCreate(supplierResponseData);
          }
          this.onModalClose();
          this.companySupplier = this.defaultValues();
          this.showNotification(
            this.isEdit
              ? this.$t("toasts.genericSuccess")
              : this.$t("toasts.genericCreationSuccess"),
            "success"
          );
        } catch (error) {
          this.showErrorMessage(error);
        } finally {
          this.loading = false;
        }
      }
    },
    setResponsibleUser(user) {
      this.companySupplier.responsibleUser = user || "";
    },
  },

  validations() {
    const urlRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})([/\w .-]*)*\/?$/;

    return {
      companySupplier: {
        name: { required: requiredIf(() => !this.companySupplier?.supplier) },
        website: {
          urlOrEmpty: (value) => isEmptyString(value) || urlRegex.test(value),
        },
        contactEmail: { email },
        orderEmail: {
          email,
          required: requiredIf(() => {
            return (
              this.companySupplier.orderViaEmail &&
              !this.companySupplier.supplier
            );
          }),
        },
        customerNumber: {
          required: requiredIf(() => {
            return (
              this.companySupplier.orderViaEmail &&
              !this.companySupplier.supplier
            );
          }),
        },
        responsibleUser: {
          required: requiredIf(() => {
            return (
              this.companySupplier.orderViaEmail &&
              !this.companySupplier.supplier
            );
          }),
        },
        categories: {
          required: requiredIf(
            () =>
              !this.companySupplier?.supplier &&
              !this.companySupplier.assignedCategoriesNotAllowed
          ),
        },
        houseNumber: {
          required: requiredIf(() => this.companySupplier.street),
        },
        iban: {
          required: requiredIf(() => this.companySupplier.bic),
        },
        bic: {
          required: requiredIf(() => this.companySupplier.iban),
        },
        notificationAmount: {
          between: helpers.withMessage(
            this.$t("errors.between", { min: 0, max: 100 }),
            between(0, 100)
          ),
        },
      },
    };
  },
};
</script>
<style scoped>
:deep(.chevron) {
  @apply h-4 w-4 transition-all;
}

:deep(.chevron-open) {
  @apply rotate-180;
}

.collapsible-section {
  @apply select-none text-sm font-medium flex justify-center items-center gap-x-2 cursor-pointer hover:underline bg-gray-50 py-2 border-gray-200 rounded border;
}

:deep(.multiselect-tag) {
  @apply bg-gray-300 text-gray-600;
}
</style>
