import { mapActions } from "vuex";
import { SET_PO_STATUS_PENDING, INVOICE_MODULE } from "@/store/Invoice/types";

export default {
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      markPoPending: SET_PO_STATUS_PENDING,
    }),
    async markPending(payload, callback = () => {}) {
      try {
        this.loading = true;
        const response = await this.markPoPending(payload);
        callback();
        this.showNotification("Sent For approval");
        return response;
      } catch {
        this.showNotification("Error updating the IPo", "error");
      } finally {
        this.loading = false;
      }
    },
  },
};
