<template>
  <div v-if="isItemLevelApprovalAllowed && product.source?.object">
    <span v-if="displaySupplierName">{{
      product.source.object.displayName
    }}</span>
  </div>
  <span v-else>
    <PencilAltIcon
      class="h-5 w-5 text-gray-400 hover:text-hivebuy_turquoise cursor-pointer inline"
      @click="toggleModal"
    />
    <CategorySupplierEdit
      :open="showModal"
      :on-modal-close="toggleModal"
      :selected-option="'supplier'"
      :save-details-attr="savePrDetail"
      :selected-category="category"
      :selected-supplier="supplier"
      :show-skip="false"
    />
  </span>
</template>

<script>
import CategorySupplierEdit from "@/components/PurchaseRequest/Form/CategorySupplierEdit.vue";
import {
  PURCHASE_REQUEST_MODULE,
  EDIT_PURCHASE_REQUEST,
  UPDATE_REQUEST_ITEM,
} from "@/store/PurchaseRequest/types.js";
import { mapActions } from "vuex";
import { PencilAltIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CategorySupplierEdit,
    PencilAltIcon,
  },
  props: {
    purchaseRequest: {
      type: Object,
      default: () => {},
    },
    product: {
      type: Object,
      default: null,
    },
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
    displaySupplierName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    category() {
      return this.isItemLevelApprovalAllowed && this.product
        ? this.product.category
        : this.purchaseRequest.category;
    },
    supplier() {
      const object = this.product || this.purchaseRequest;
      return object.source?.object
        ? object.source.object.name
        : object.companySupplier;
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      editRequest: EDIT_PURCHASE_REQUEST,
      editItem: UPDATE_REQUEST_ITEM,
    }),
    toggleModal() {
      this.showModal = !this.showModal;
    },
    supplierPayload(value) {
      const { type, details } = value;
      if (type === "unlisted") {
        return {
          unlistedSupplier: details.name,
          unlistedSupplierReason: details.reason,
          companySupplier: null,
        };
      } else {
        return {
          unlistedSupplier: null,
          unlistedSupplierReason: "",
          companySupplier: details,
        };
      }
    },

    payload(value) {
      if (this.isItemLevelApprovalAllowed) {
        return {
          payload: { id: this.product.id, ...this.supplierPayload(value) },
          id: this.product.id,
          purchaseRequestId: this.purchaseRequest.id,
        };
      }

      const payload = {
        items: this.purchaseRequest.items.map((item) => ({
          id: item.id,
          ...this.supplierPayload(value),
        })),
      };
      return {
        payload,
        purchaseRequestId: this.purchaseRequest.id,
      };
    },
    getActionAndPayload(value) {
      return {
        action: this.isItemLevelApprovalAllowed
          ? this.editItem
          : this.editRequest,
        payload: this.payload(value),
      };
    },
    savePrDetail(_, value) {
      this.setApiLoading({ key: "productSupplier", value: true });
      const { action, payload } = this.getActionAndPayload(value);
      action(payload)
        .then(() => {
          this.showNotification(this.$t("toasts.genericSuccess"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "productSupplier", value: false });
        });
    },
  },
};
</script>
