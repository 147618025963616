import { get } from "@/utils/services/api";
import { CUSTOM_FIELD_URL } from "@/utils/services/apiUrls";
import * as CompanyManagementTypes from "@/store/CompanyManagement/types";

export const getCustomFields = (commit, resolve, reject) => {
  get(CUSTOM_FIELD_URL)
    .then((response) => {
      commit(CompanyManagementTypes.SET_CUSTOM_FIELDS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
