<template>
  <Tip
    :trigger="'click'"
    :interactive="true"
    :show-footer="true"
    :save-action="confirmModal"
  >
    <template #placeholder> <slot name="placeholder"> </slot></template>
    <template #content>
      <div class="sm:flex sm:justify-center py-2 max-w-56">
        <div class="text-center">
          <div>
            <p class="text-center text-sm text-gray-600 font-normal">
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </Tip>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: String,
      default: null,
      required: true,
    },
    confirmModal: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
