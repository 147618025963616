<template>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12 custom-card no-shadow">
      <div class="section-title">
        {{ $t("purchaseRequest.summary.comment.commentTitle") }}
      </div>
      <ul v-if="comments?.length" v-auto-animate>
        <li v-for="comment in comments" :key="comment.id" class="mb-2">
          <div class="flex px-3 py-2 rounded border border-gray-100 bg-gray-50">
            <div class="flex-shrink-0 mr-2">
              <UserWithAvatar
                :user="comment.commentedBy"
                :show-tooltip="false"
              />
            </div>
            <div class="text-gray-500 font-medium text-sm whitespace-pre-line">
              <div class="text-gray-900 font-semibold mb-2">
                <UserWithAvatar :user="comment.commentedBy" show-name-only />
              </div>

              <component
                :is="{ template: formattedComment(comment.comment) }"
              />
              <div
                v-if="
                  user.id == comment.commentedBy &&
                  timeDifferenceInMinutes(comment.createdAt) <= editThreshold
                "
                class="mt-2"
              >
                <button
                  class="focus:outline-none hover:text-success hover:font-semibold"
                  @click="() => setEditComment(comment)"
                >
                  {{ $t("purchaseRequest.summary.comment.commentEdit") }}
                </button>
                <button
                  class="focus:outline-none hover:text-red-500 hover:font-semibold ml-2"
                  @click="() => setDeleteComment(comment)"
                >
                  {{ $t("purchaseRequest.summary.comment.commentDelete") }}
                </button>
              </div>
            </div>
            <div class="flex-shrink-0 text-gray-500 text-xs ml-auto">
              <TimeDisplay :time="comment.createdAt" />
            </div>
          </div>
        </li>
      </ul>
      <div class="flex items-start space-x-4">
        <div class="flex-shrink-0">
          <UserWithAvatar :user="user" :show-tooltip="false" />
        </div>
        <div class="min-w-0 flex-1">
          <form action="#" class="relative bold">
            <div
              class="border border-gray-200 rounded-lg overflow-hidden focus-within:border-primary focus-within:ring-1 focus-within:ring-primary px-3"
            >
              <label for="comment" class="sr-only">Add your comment</label>
              <Mentionable :keys="['@']" :items="membersToTag" offset="6">
                <textarea
                  id="comment"
                  v-model="newComment"
                  name="comment"
                  rows="3"
                  :placeholder="
                    $t('purchaseRequest.summary.comment.leaveComment')
                  "
                  class="block w-full py-3 border-0 focus-within:border-primary resize-none focus:ring-0 sm:text-sm"
                />
                <template #item="{ item }">
                  <UserWithAvatar
                    :user="item.id"
                    avatar-dimension="6"
                    show-name
                    :text-classes="['text-xs text-gray-600 font-medium']"
                  />
                </template>
                <template #no-result>
                  <div class="text-xs px-2 py-1">No entry</div>
                </template>
              </Mentionable>

              <!-- Spacer element to match the height of the toolbar -->
              <div class="py-2" aria-hidden="true">
                <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                <div class="py-px">
                  <div class="h-9" />
                </div>
              </div>
            </div>

            <div
              class="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between"
            >
              <div class="flex items-center space-x-5"></div>
              <div class="flex-shrink-0">
                <Button
                  type="button"
                  :on-click-handler="addNewComment"
                  :loading="loading"
                  :disabled="!newComment"
                  size-class="sm"
                  >{{ $t("purchaseRequest.summary.comment.saveComment") }}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ConfirmDialog
        v-if="commentToDelete"
        :text="$t('purchaseRequest.summary.comment.deleteCommentQuestion')"
        :show-modal="showDeleteConfirmModal"
        :close-modal="
          () => {
            showDeleteConfirmModal = false;
          }
        "
        :title="$t('purchaseRequest.summary.comment.deleteComment')"
        :item-to-confirm="commentToDelete.comment"
        :confirm-modal="onDeleteConfirm"
      />
      <UpdateComment
        v-if="commentToEdit"
        :comment="commentToEdit.comment"
        :save-method="saveComment"
        :open="showEditCommentModal"
        :toggle-function="toggleUpdateModal"
        :members-to-tag="membersToTag"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  ADD_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT,
} from "@/store/PurchaseRequest/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import UpdateComment from "@/components/PurchaseRequest/SummarySections/UpdateComment.vue";
import {
  ConfirmDialog,
  Button,
  UserWithAvatar,
  TimeDisplay,
  UserPopup,
} from "@/components/shared/index";
import { timeDifference } from "@/utils/timeHelper.js";

import { Mentionable } from "vue-mention";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import "floating-vue/dist/style.css";
import twitter from "twitter-text";

export default {
  components: {
    UpdateComment,
    ConfirmDialog,
    Button,
    UserWithAvatar,
    TimeDisplay,
    Mentionable,
    UserPopup,
  },

  props: {
    prId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEditCommentModal: false,
      commentToEdit: null,
      showDeleteConfirmModal: false,
      commentToDelete: null,
      newComment: "",
      editThreshold: 5,
      loading: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["openCurrentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    comments() {
      const request = this.requestDetails(this.openCurrentRequest);
      if (!request) return [];

      return request.comments;
    },
    membersToTag() {
      return this.usersList()
        .filter((member) => member.username) // remove entries which don't have username
        .map((member) => {
          return {
            value: member.username,
            label: `${member.firstName} ${member.lastName}`,
            id: member.id,
          };
        });
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      addComment: ADD_COMMENT,
      deleteComment: DELETE_COMMENT,
      updateComment: UPDATE_COMMENT,
    }),
    timeAgo(createdAt) {
      return timeDifference(createdAt);
    },
    setEditComment(comment) {
      this.commentToEdit = comment;
      this.toggleUpdateModal();
    },
    setDeleteComment(comment) {
      this.commentToDelete = comment;
      this.showDeleteConfirmModal = true;
    },
    saveComment(text) {
      const payload = {
        prId: this.prId,
        commentId: this.commentToEdit.id,
        comment: text,
        mentions: this.taggedUsersList(text),
      };
      this.updateComment(payload)
        .then(() => {
          this.newComment = "";
          this.commentToEdit = null;
          this.showNotification("Comment Updated Successfully");
        })
        .catch((error) => this.showErrorMessage(error));
    },
    toggleUpdateModal() {
      this.showEditCommentModal = !this.showEditCommentModal;
    },
    onDeleteConfirm() {
      const payload = {
        prId: this.prId,
        commentId: this.commentToDelete.id,
      };
      this.deleteComment(payload)
        .then(() => {
          this.commentToDelete = null;
          this.showNotification("Comment Deleted Successfully");
          this.showEditCommentModal = false;
        })
        .catch((error) => this.showErrorMessage(error));
    },
    addNewComment() {
      const commentPayload = {
        commentedBy: this.user.id,
        comment: this.newComment,
        mentions: this.taggedUsersList(this.newComment),
      };
      this.loading = true;
      this.addComment({ prId: this.prId, comment: commentPayload })
        .then(() => {
          this.newComment = "";
          this.showNotification("Comment Added Successfully");
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
    timeDifferenceInMinutes(createdAt) {
      const now = new Date();
      const date = Date.parse(createdAt);
      let diff = (date - now) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    },
    taggedUsersList(text) {
      let usernames = twitter.extractMentions(text);
      let mentionedUsers = [];

      if (!usernames?.length) return mentionedUsers;

      usernames = [...new Set(usernames)]; // to remove duplicate/multiple usernames
      mentionedUsers = this.usersList().filter((member) =>
        usernames.includes(member.username)
      );
      return mentionedUsers.map((user) => user.id);
    },
    formattedComment(comment) {
      const memberList = this.usersList();
      const preUpdate = comment.replace(/<([^>]+)>|(?:\r\n|\r|\n)/g, "<br>");
      const updatedComment = preUpdate.replace(
        /\B@[a-z0-9_-]+/gi,
        function (matched) {
          const username = matched.split("@")[1];
          const user = memberList.find(
            (member) => member.username === username
          );
          if (user) {
            return `<div class='font-bold text-primary cursor-pointer inline-flex'><UserPopup text="${
              user.name || matched
            }" user="${user.id}" /></div>`;
          }
          return `${matched}`;
        }
      );
      return updatedComment;
    },
  },
};
</script>

<style>
.mention-item {
  @apply py-1 px-2 cursor-pointer;
}
.mention-selected {
  @apply bg-gray-100;
}
</style>
