// Modules
export const DASHBOARD_MODULE = "DASHBOARD_MODULE";

// Actions
export const GET_DEPARTMENT_ANALYTICS = "GET_DEPARTMENT_ANALYTICS";
export const GET_DEPARTMENT_BUDGET_ANALYTICS =
  "GET_DEPARTMENT_BUDGET_ANALYTICS";
export const GET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS =
  "GET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS";
export const GET_PURCHASE_REQUESTS_ANALYTICS =
  "GET_PURCHASE_REQUESTS_ANALYTICS";
export const GET_PURCHASE_REQUESTS = "GET_PURCHASE_REQUESTS";

export const SAVE_SELF_SIGNUP_COMPANY_INFO = "SAVE_SELF_SIGNUP_COMPANY_INFO";
export const GET_DETAILED_ANALYTICS = "GET_DETAILED_ANALYTICS";
export const GET_ANALYTICS_STATISTICS = "GET_ANALYTICS_STATISTICS";

export const GET_NEWS = "GET_NEWS";
export const SAVE_NEWS = "SAVE_NEWS";

export const DELETE_NEWS = "DELETE_NEWS";

export const GET_USER_RELEVANT_NEWS = "GET_USER_RELEVANT_NEWS";

export const MARK_NEWS_AS_READ = "MARK_NEWS_AS_READ";

//Mutations
export const SET_PURCHASE_REQUESTS_ANALYTICS =
  "SET_PURCHASE_REQUESTS_ANALYTICS";
export const SET_DEPARTMENT_ANALYTICS = "SET_DEPARTMENT_ANALYTICS";
export const SET_DEPARTMENT_BUDGET_ANALYTICS =
  "SET_DEPARTMENT_BUDGET_ANALYTICS";
export const SET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS =
  "SET_SINGLE_DEPARTMENT_BUDGET_ANALYTICS";
export const SET_SELECTED_SIDEBAR = "SET_SELECTED_SIDEBAR";
export const SET_PURCHASE_REQUESTS = "SET_PURCHASE_REQUESTS";

export const SHOW_NEW_VERSION_ALERT = "SHOW_NEW_VERSION_ALERT";
export const DISABLE_NEW_VERSION_ALERT = "DISABLE_NEW_VERSION_ALERT";
export const GET_SEARCH_DATA = "GET_SEARCH_DATA";
export const SET_DETAILED_ANALYTICS = "SET_DETAILED_ANALYTICS";
export const SET_ANALYTICS_STATISTICS = "SET_ANALYTICS_STATISTICS";
export const SET_MY_PURCHASE_REQUESTS = "SET_MY_PURCHASE_REQUESTS";
export const GET_MY_PURCHASE_REQUESTS = "GET_MY_PURCHASE_REQUESTS";
export const GET_ACTION_REQUIRED_PURCHASE_REQUESTS =
  "GET_ACTION_REQUIRED_PURCHASE_REQUESTS";
export const SET_ACTION_REQUIRED_PURCHASE_REQUESTS =
  "SET_ACTION_REQUIRED_PURCHASE_REQUESTS";
export const SET_ACTION_REQUIRED_PURCHASE_REQUEST =
  "SET_ACTION_REQUIRED_PURCHASE_REQUEST";

export const SET_NEWS = "SET_NEWS";
export const SAVE_NEWS_DATA = "SAVE_NEWS_DATA";
export const REMOVE_NEWS = "REMOVE_NEWS";

export const SET_USER_RELEVANT_NEWS = "SET_USER_RELEVANT_NEWS";
export const SET_USER_RELEVANT_NEWS_DATA = "SET_USER_RELEVANT_NEWS_DATA";
