<template>
  <div class="w-full min-h-[600px] flex items-center justify-center">
    <div class="w-36 h-36"><HivebuyLoader /></div>
  </div>
</template>

<script>
import HivebuyLoader from "@/components/shared/HivebuyLoader";
import fusionAuth from "@/utils/services/auth";

export default {
  name: "SelfSignup",
  components: { HivebuyLoader },
  mounted() {
    fusionAuth.redirectToSignupUrl();
  },
};
</script>
