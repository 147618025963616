<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <PencilAltIcon
        v-if="isEdit"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <PlusIcon v-else class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      {{ $tc("companyManagement.profile.paymentMethods", 1) }}
    </template>
    <template #body>
      <div class="mt-2">
        <div class="text-sm text-gray-500">
          {{ $t("companyManagement.profile.paymentMethodsModal.text") }}
          <li class="mt-2">
            {{ $t("companyManagement.profile.paymentMethodsModal.example1") }}
          </li>
          <li>
            {{ $t("companyManagement.profile.paymentMethodsModal.example2") }}
          </li>
          <li>
            {{ $t("companyManagement.profile.paymentMethodsModal.example3") }}
          </li>
          <p class="font-medium my-2 mb-6">
            {{ $t("companyManagement.profile.paymentMethodsModal.disclaimer") }}
          </p>
        </div>
        <Input
          :value="paymentMethod.title"
          :name="'title'"
          :placeholder="$t('global.title')"
          :label="$t('global.title')"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :error-text="error(v$.paymentMethod.title)"
        />
        <div class="mt-6">
          <label for="notes" class="block text-sm font-medium text-gray-700">
            {{ $t("global.description") }}
          </label>
          <textarea
            id="notes"
            v-model="paymentMethod.description"
            name="description"
            rows="5"
            class="mt-1 shadow-sm block w-full focus:ring-teal-500 focus:border-teal-400 sm:text-sm border-gray-300 rounded-md"
            :placeholder="
              $t(
                'companyManagement.profile.paymentMethodsModal.placeholderTextarea'
              )
            "
          />
        </div>
        <div class="mt-6 flex">
          <SwitchCheckbox
            v-model="paymentMethod.isDefault"
            :label="$t('global.setDefault')"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button :on-click-handler="onSave">
        {{ isEdit ? $t("buttons.update") : $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { PlusIcon, PencilAltIcon } from "@heroicons/vue/outline";
import { Button, Modal, Input } from "@/components/shared/index";
import { errorMixin } from "@/components/mixins/index.js";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { isObjectEmpty } from "@/utils/utility_methods";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";

export default {
  components: {
    Modal,
    PlusIcon,
    Input,
    SwitchCheckbox,
    Button,
    PencilAltIcon,
  },
  mixins: [errorMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    savePaymentMethod: {
      type: Function,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    paymentMethodToEdit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      paymentMethod: this.paymentMethodInitialState(),
      v$: useValidate(),
    };
  },
  watch: {
    paymentMethodToEdit: {
      handler(newVal, oldVal) {
        if (this.isEdit && !isObjectEmpty(newVal) && newVal != oldVal) {
          this.paymentMethod = Object.assign({}, newVal);
        }
      },
    },
  },
  methods: {
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.savePaymentMethod(this.paymentMethod);
      }
    },
    onModalClose() {
      this.closeModal();
      this.resetValues();
    },
    resetValues() {
      setTimeout(() => {
        this.paymentMethod = this.paymentMethodInitialState();
        this.v$.$reset();
      }, 500);
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.paymentMethod[name] = value;
    },
    paymentMethodInitialState() {
      return {
        title: "",
        description: "",
        isDefault: false,
      };
    },
  },
  validations() {
    return {
      paymentMethod: {
        title: { required },
      },
    };
  },
};
</script>
