<template>
  <div class="flex min-w-0 gap-x-4 items-center truncate-min">
    <div class="min-w-0 flex-auto truncate-min">
      <div v-if="requestedFor.type == 'user'">
        <EntityDisplay
          v-if="valuesObject.user"
          :entity="{ id: valuesObject.user, type: 'user' }"
          :read-only="true"
          :show-name="true"
        >
          <template #userDetails>
            <EntityDisplay
              v-if="valuesObject.department"
              :entity="{ id: valuesObject.department, type: 'department' }"
              :read-only="true"
              :show-name="true"
              :show-department-as-subheader="true"
              class="text-sm !important"
            />
          </template>
        </EntityDisplay>
      </div>
      <div v-if="requestedFor.type == 'project'" class="truncate-min">
        <EntityDisplay
          v-if="valuesObject.project"
          :entity="{ id: valuesObject.project, type: 'project' }"
          :read-only="true"
          :show-name="true"
          show-cost-center
        >
        </EntityDisplay>
      </div>
      <div v-if="requestedFor.type == 'department'" class="truncate-min">
        <EntityDisplay
          v-if="valuesObject.department"
          :entity="{ id: valuesObject.department, type: 'department' }"
          :read-only="true"
          :show-name="true"
        >
          <template #departmentDetails>
            <EntityDisplay
              v-if="valuesObject.department"
              :entity="{ id: valuesObject.department, type: 'department' }"
              :read-only="true"
              :show-name="true"
              show-cost-center
              class="text-sm !important"
            />
          </template>
        </EntityDisplay>
      </div>
    </div>
  </div>
</template>

<script>
import EntityDisplay from "@/components/shared/EntityDisplay.vue";

export default {
  components: {
    EntityDisplay,
  },
  props: {
    requestedFor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valuesObject: {},
    };
  },
  watch: {
    requestedFor: {
      handler(val) {
        this.assignObjectValues(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    assignObjectValues(requestedFor) {
      let value = { ...requestedFor };

      if (
        requestedFor.id &&
        (!requestedFor.type || requestedFor.type === "user")
      ) {
        value = {
          ...requestedFor,
          user: requestedFor.id,
          type: "user",
        };
      }

      this.valuesObject = value;
    },
  },
};
</script>
