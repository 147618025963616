<template>
  <div
    class="grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-0 w-full divide-y md:divide-y-0 divide-gray-300 py-3"
  >
    <div class="truncate-min px-3 w-full md:border-r md:border-gray-300">
      <RequestedFor
        v-model="requestedFor"
        :v="v"
        :error="requestedForErrorMessage()"
        :edit-allowed="editAllowed"
      />
    </div>
    <div class="px-3 w-full md:border-r md:border-gray-300">
      <h3 class="text-sm font-medium leading-6 text-primary text-center">
        {{ $t("global.category") }}
      </h3>
      <div>
        <InlineCategorySelect
          v-model="category"
          :error="errorMessage('category')"
          :disabled-message="disabledMessageCategory"
        />
      </div>
    </div>
    <div class="px-3 w-full">
      <h3 class="text-sm font-medium leading-6 text-primary text-center">
        {{ $t("global.supplier") }}
      </h3>
      <div>
        <InlineSupplierSelect
          v-model="supplier"
          :category="modelValue.category"
          :error="errorMessage('companySupplier')"
          :disabled-message="disabledMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  InlineCategorySelect,
  InlineSupplierSelect,
  RequestedFor,
} from "@/components/shared/index";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import {
  PURCHASE_REQUEST_MODULE,
  GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
} from "@/store/PurchaseRequest/types";
import { mapActions, mapState } from "vuex";
import { isHashEqual } from "@/utils/utility_methods";
import { newRequestMixin } from "@/components/mixins/index.js";
export default {
  name: "RequestHeader",
  components: {
    InlineCategorySelect,
    InlineSupplierSelect,
    RequestedFor,
  },
  mixins: [newRequestErrorMixin, newRequestMixin],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    editAllowed: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "defaultValuesChange"],

  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),

    disabledMessage() {
      const { isCatalogueItem, isIntegratedSupplierItem } = this.modelValue;
      if (isIntegratedSupplierItem)
        return this.$t("purchaseRequest.errors.disabledField.integrated");
      if (isCatalogueItem)
        return this.$t("purchaseRequest.errors.disabledField.catalogue");
      return "";
    },
    disabledMessageCategory() {
      const { isCatalogueItem } = this.modelValue;
      if (isCatalogueItem)
        return this.$t("purchaseRequest.errors.disabledField.catalogue");
      return "";
    },
    requestedFor: {
      get() {
        return {
          requestedForId: this.modelValue.requestedForId,
          department: this.modelValue.department,
          project: this.modelValue.project,
          requestedForType: this.requestedForType(
            this.modelValue.requestedForType
          ),
        };
      },
      set(value) {
        if (this.newRequest.sameRecipient) {
          this.copyValue(value);
          return;
        }
        this.emitRequestedForValue(Object.assign({}, this.modelValue, value));
      },
    },
    category: {
      get() {
        return this.modelValue.category || null;
      },
      set(category) {
        const value = {
          ...this.modelValue,
          category,
        };
        if (!this.modelValue.isIntegratedSupplierItem) {
          value.companySupplier = null;
        }
        this.$emit("update:modelValue", value);
      },
    },
    supplier: {
      get() {
        const {
          companySupplier,
          unlistedSupplier,
          unlistedSupplierReason,
          skipSupplier,
        } = this.modelValue;
        if (!companySupplier && !unlistedSupplier && !skipSupplier) {
          return null;
        }
        return {
          companySupplier,
          unlistedSupplier,
          unlistedSupplierReason,
          skipSupplier,
        };
      },
      set(companySupplier) {
        const object = {};
        if (companySupplier?.unlistedSupplier) {
          object.companySupplier = null;
          object.unlistedSupplier = companySupplier.details.name;
          object.unlistedSupplierReason = companySupplier.details.reason;
          object.skipSupplier = false;
        } else if (companySupplier?.skip) {
          object.companySupplier = null;
          object.unlistedSupplier = null;
          object.unlistedSupplierReason = "";
          object.skipSupplier = true;
        } else {
          object.companySupplier = companySupplier?.details;
          object.unlistedSupplier = null;
          object.unlistedSupplierReason = "";
          object.skipSupplier = false;
        }
        this.$emit("update:modelValue", {
          ...this.modelValue,
          ...object,
        });
      },
    },
  },
  created() {
    if (this.modelValue.requestedForId && !this.modelValue.paymentMethod) {
      const value = this.transformRequestedFor(this.modelValue);
      this.defaultValuesApi(value);
    }
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getDefaultValuesForRequestedFor: GET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
    }),

    copyValue(value) {
      Object.keys(value).forEach((key) => {
        this.copyValueToAllItems(key, value[key]);
      });

      this.emitRequestedForValue(Object.assign({}, this.modelValue, value));
    },
    emitRequestedForValue(value) {
      const oldRequestedFor = this.transformRequestedFor(this.modelValue);
      if (isHashEqual(value, oldRequestedFor)) return;
      this.$emit("update:modelValue", value);
      this.defaultValuesApi(value);
    },
    defaultValuesApi(value) {
      if (
        !value.requestedForId ||
        this.requestedForValues?.[value.requestedForId]
      )
        return;

      const updatedRequestedFor = {
        id: value.requestedForId,
        type: value.requestedForType,
        department: value.department,
        project: value.project,
      };

      this.getDefaultValuesForRequestedFor(updatedRequestedFor).then(
        (response) => this.$emit("defaultValuesChange", response)
      );
    },
    requestedForErrorMessage() {
      const fields = ["requestedForId", "department", "project"];
      const hasError = fields.some((field) => this.errorMessage(field));

      return hasError
        ? this.$t("errors.required", { entity: "requestedFor" })
        : "";
    },
    transformRequestedFor(value) {
      return {
        requestedForId: value.requestedForId,
        department: value.department,
        project: value.project,
        requestedForType: value.requestedForType,
      };
    },
  },
};
</script>
