<template>
  <Popper
    :hover="hover"
    :placement="placement"
    :arrow="arrow"
    :open-delay="openDelay"
    :disabled="disabled"
    :show="show"
  >
    <slot />
    <template #content>
      <div class="text-sm">
        <slot name="content"></slot>
      </div>
    </template>
  </Popper>
</template>

<script>
export default {
  props: {
    placement: { type: String, default: "top" },
    openDelay: { type: [String, Number], default: "200" },
    hover: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    show: { type: Boolean, default: null },
    arrow: { type: Boolean, default: true },
  },
};
</script>
<style>
:root {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #dad4d4;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 4px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
