<template>
  <div v-if="infoTooltip">
    <InformationPopover class="ml-2 translate-y-0.5 relative z-50" alt-color>
      <template #header>
        {{ $t("companyManagement.users.table.rolesTooltip.header") }}
      </template>
      <template #body>
        <div
          v-for="role in roles"
          :key="role"
          class="grid grid-cols-3 border-b border-gray-100 items-center align-middle py-2 normal-case w-[400px]"
        >
          <p class="text-sm font-medium text-gray-900 text-left col-span-1">
            <Role
              :class-name="roleStyle(role)"
              :role-name="roleName(role)"
              :role="role"
              class="text-white"
            />
          </p>
          <p class="text-sm text-gray-500 inline-flex py-0.5 col-span-2">
            {{ $t(`companyManagement.users.table.rolesTooltip.${role}`) }}
          </p>
        </div>
      </template>
    </InformationPopover>
  </div>
  <div v-else class="grid grid-cols-3 gap-1 min-w-0">
    <Role
      v-for="(role, groupIndex) in roles"
      :key="groupIndex"
      :class-name="`${roleStyle(role)} flex justify-center`"
      :role="role"
    />
  </div>
</template>

<script>
import Role from "@/components/CompanyManagement/Users/Role.vue";
import { USER_ROLES_MAPPING } from "@/utils/constants";
import InformationPopover from "@/components/shared/InformationPopover.vue";
import userMixin from "@/components/mixins/userMixin";

export default {
  name: "UserRoles",
  components: { Role, InformationPopover },
  mixins: [userMixin],
  props: {
    displayAllRoles: {
      type: Boolean,
      default: false,
    },
    userRoles: {
      type: Array,
      default: () => [],
    },
    infoTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    roles() {
      return this.displayAllRoles
        ? Object.keys(USER_ROLES_MAPPING)
        : this.userRoles.length
        ? this.userRoles
        : ["B"];
    },
  },
};
</script>
