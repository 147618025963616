<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Modal :show-modal="open" :close-modal="toggleFunction">
    <template #icon>
      <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-2xl font-medium text-gray-500">
        {{ $t("workflow.editor.workflowTitleSaveTitle") }}
      </h3>
    </template>
    <template #body>
      <div class="mt-2">
        <Input
          :value="suggestion"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
        />
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="toggleFunction" :color="'gray'">
        {{ $t("shared.cancelButton") }}
      </Button>
      <Button :on-click-handler="confirmSave">
        {{ $t("shared.saveButton") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import { Button, Modal, Input } from "@/components/shared/index";

export default {
  components: {
    Modal,
    CheckIcon,
    Input,
    Button,
  },
  props: {
    suggestion: {
      type: String,
      default: "",
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    toggleFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      title: this.suggestion,
    };
  },
  watch: {
    suggestion: {
      handler(newVal) {
        this.title = newVal;
      },
    },
  },
  methods: {
    confirmSave() {
      this.toggleFunction();
      this.saveMethod(this.title);
    },
    onAttributeChange(event) {
      this.title = event.target.value;
    },
  },
};
</script>
