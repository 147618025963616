import { INVOICE_MODULE, GET_INVOICE } from "@/store/Invoice/types";
import { mapActions } from "vuex";
import { formatDateTime } from "@/utils/utility_methods.js";

export default {
  data() {
    return {
      invoiceDetails: {},
      loading: false,
    };
  },
  computed: {
    language() {
      if (this.$i18n) {
        return this.$i18n.locale;
      } else {
        return "en";
      }
    },
    auditTrailFile() {
      if (this.invoiceDetails.mergedInvoice?.length) {
        return this.invoiceDetails.mergedInvoice[0].file;
      }
      return null;
    },
    isInvoiceLoading() {
      return this.loading || !this.invoiceDetails.id;
    },
  },
  async created() {
    this.loading = true;
    const invoiceId = this.$route.params.id || this.invoiceId;
    try {
      this.invoiceDetails = await this.getInvoice(invoiceId);
    } catch (error) {
      this.showErrorMessage(error);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(INVOICE_MODULE, {
      getInvoice: GET_INVOICE,
    }),
    formatDateTime,
  },
};
