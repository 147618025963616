import { mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { PROPOSAL_STATUSES_MAPPING } from "@/utils/constants";

export default {
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    showActions() {
      return (
        this.isUserBoardCreator &&
        !this.isBoardStatusCompleted &&
        !this.isBoardStatusClosed
      );
    },
    isUserBoardCreator() {
      return this.user.id === this.proposal.createdBy.id;
    },
    isBoardStatusCompleted() {
      return this.proposal.status === PROPOSAL_STATUSES_MAPPING.completed;
    },
    isBoardStatusClosed() {
      return this.proposal.status === PROPOSAL_STATUSES_MAPPING.closed;
    },
    isBoardStatusOpen() {
      return this.proposal.status === PROPOSAL_STATUSES_MAPPING.open;
    },
    isBoardStatusDraft() {
      return this.proposal.status === PROPOSAL_STATUSES_MAPPING.draft;
    },
    isBoardExpired() {
      return this.isBoardStatusCompleted || this.isBoardStatusClosed;
    },
  },
};
