import {
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
} from "@heroicons/vue/solid";
export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
    ShieldExclamationIcon,
  },
  methods: {
    showNotification(
      text = null,
      type = "success",
      notificationTitle = null,
      icon = null
    ) {
      const notificationObject = {
        success: {
          title: this.$t("toasts.headerSuccess"),
          icon: CheckCircleIcon,
        },
        error: { title: this.$t("toasts.headerError"), icon: XCircleIcon },
        info: {
          title: this.$t("toasts.headerInfo"),
          icon: InformationCircleIcon,
        },
        warning: {
          title: this.$t("toasts.headerWarning"),
          icon: ShieldExclamationIcon,
        },
      };
      if (notificationObject[type]) {
        notificationObject[type].text = text;
        if (notificationTitle)
          notificationObject[type].title = notificationTitle;
        if (icon) notificationObject[type].icon = icon;
      }
      this.$notify(
        Object.assign({}, { group: "hivebuy", type }, notificationObject[type]),
        4000
      );
    },
    showErrorMessage(errorResponse = null) {
      let error = errorResponse?.details?.response?.data;
      if (error?.type && error?.errors) {
        const errorMessages = error.errors.map((errorItem) => {
          const translationKey = `global.errors.${errorItem.code}`;
          const translation = this.$t(translationKey);

          return translation !== translationKey
            ? translation
            : errorItem.detail;
        });
        error = errorMessages.length > 1 ? errorMessages : errorMessages[0];
        this.showNotification(error, "error");
      } else {
        console.log("Error: ", error);
        this.showNotification(this.$t("errors.genericBackendError"), "error");
      }
    },
  },
};
