<template>
  <div class="profile">
    <!--header-->
    <div class="profile__header">
      <div class="profile__header__left">
        <h3 class="card-title">
          {{ $t("companyManagement.profile.companyProfile") }}
        </h3>
        <span class="sub-title">
          This information will be displayed publicly so be careful what you
          share.
        </span>
      </div>
      <div class="profile__header__right">
        <div v-if="editMode">
          <Button
            :on-click-handler="resetValues"
            :color="'gray'"
            class="button-small"
          >
            {{ $t("companyManagement.profile.buttons.cancel") }}
          </Button>
          <Button :on-click-handler="onSave" class="button-small">
            {{ $t("companyManagement.profile.buttons.save") }}
          </Button>
        </div>
        <div v-else>
          <Button
            :on-click-handler="toggleEditMode"
            :color="'primary'"
            class="button-small"
          >
            {{ $t("companyManagement.profile.buttons.edit") }}
          </Button>
        </div>
      </div>
    </div>

    <!--header end-->

    <div class="flex w-full flex-col">
      <div class="content-wrapper">
        <div class="content-row">
          <div class="content-row__content">
            <Input
              id="company_name"
              :name="'company_name'"
              :on-value-change="
                (event) => (companyProfile.name = event.target.value)
              "
              :value="companyProfile.name"
              :type="'text'"
              :placeholder="$t('companyManagement.profile.companyName')"
              :label="$t('companyManagement.profile.companyName')"
              :disabled="!editMode"
              :error-text="error(v$.companyProfile.name)"
            />
          </div>
        </div>

        <div class="content-row">
          <div class="content-row__label">
            {{ $t("companyManagement.profile.legalForm") }}
          </div>
          <div class="content-row__content">
            <Multiselect
              v-model="companyProfile.legalForm"
              :mode="'single'"
              :options="legalForms"
              :placeholder="$t('companyManagement.profile.legalForm')"
              :value-prop="'name'"
              :label="'name'"
              :can-deselect="false"
              :can-clear="false"
              :disabled="!editMode"
            />
          </div>
        </div>

        <div class="content-row">
          <div class="content-row__content">
            <Input
              id="company_website"
              :name="'company_website'"
              :on-value-change="
                (event) => (companyProfile.domain = event.target.value)
              "
              :value="companyProfile.domain"
              :type="'text'"
              :placeholder="'www.example.com'"
              :label="$t('companyManagement.profile.website')"
              :disabled="!editMode"
              :is-link="true"
            />
          </div>
        </div>

        <div class="content-row">
          <div class="content-row__label">
            {{ $t("companyManagement.profile.companyLogo") }}
          </div>
          <div class="content-row__content">
            <div class="flex justify-between items-center">
              <div
                class="company-logo"
                :class="{ 'bg-gray-100 ': !companyProfile.logo }"
              >
                <img
                  :src="companyLogo"
                  alt="company-logo-placeholder"
                  class="h-full w-full text-gray-300"
                />
              </div>
              <div class="flex space-x-4 items-center">
                <p
                  v-if="editMode"
                  class="remove-logo cursor-pointer"
                  @click="removeLogo"
                >
                  {{ $t("companyManagement.profile.removeLogo") }}
                </p>
                <ImageUploader
                  v-if="editMode"
                  :button-text="$t('companyManagement.profile.buttons.change')"
                  :save-image-data="saveImageData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Input, ImageUploader } from "@/components/shared/index";
import { required } from "@vuelidate/validators";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const legalForms = require("@/assets/data/legalforms.json");
import Multiselect from "@vueform/multiselect";
import { companyProfileMixin } from "@/components/mixins/index.js";

export default {
  components: {
    Button,
    Input,
    ImageUploader,
    Multiselect,
  },
  mixins: [companyProfileMixin],
  data() {
    return {
      companyProfile: this.initializeCompanyProfile(),
    };
  },
  computed: {
    companyLogo() {
      return (
        this.companyProfile.logo ||
        require("@/assets/images/company-logo-placeholder.svg")
      );
    },
    legalForms() {
      return legalForms;
    },
  },
  methods: {
    initializeCompanyProfile() {
      const { name, legalForm, domain, id, logo } = this.company;
      return Object.assign({}, { name, legalForm, domain, id, logo });
    },
    resetValues() {
      this.companyProfile = this.initializeCompanyProfile();
      this.toggleEditMode();
    },
    saveImageData(data) {
      this.companyProfile.logo = data;
    },
    removeLogo() {
      this.companyProfile.logo = null;
    },
    onSave() {
      this.v$.$validate();

      if (!this.v$.$error) {
        const { name, legalForm, domain, id, logo } = this.companyProfile;
        const payload = { payload: { name, legalForm, domain, id, logo } };
        this.updateCompanyData(payload, "Company has been updated");
      }
    },
  },
  validations() {
    return {
      companyProfile: {
        name: { required },
      },
    };
  },
};
</script>

<style scoped>
.logo-holder-profile {
  @apply flex flex-col mr-4 items-center;
}
.company-logo {
  @apply flex space-x-4;
}
.company-logo img {
  @apply h-[36px] w-auto object-contain;
}
.remove-logo {
  @apply text-sm text-red-500;
}

.button-small {
  @apply border-0 py-1 px-2 bg-transparent text-sm text-gray-800 hover:text-white  hover:bg-primary ml-3;
}

.profile {
  @apply mb-12;
}

.profile__header {
  @apply flex justify-between border-b border-gray-200 pb-4;
}

.profile__header__left .card-title {
  @apply font-bold text-primary mb-1 text-sm;
}
.profile__header__left .sub-title {
  @apply text-sm text-gray-500;
}

.content-wrapper {
  @apply flex flex-col w-full;
}

.content-row {
  @apply flex border-b border-gray-200 py-4 border-collapse items-center w-full;
}

.content-row__label {
  @apply w-1/5 text-sm font-semibold text-gray-500;
}
.content-row__content {
  @apply text-sm font-semibold text-gray-800 flex-1;
}
.content-row__content .input-holder {
  @apply flex items-center;
}
:deep(.content-row__content .input-holder .label) {
  @apply !w-1/5 text-sm font-semibold text-gray-500;
}
:deep(.content-row__content .input-holder > .relative) {
  @apply flex-1;
}
</style>
