<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden z-20"
      @close="toggleAction"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div
          class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
        >
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="pointer-events-auto w-screen max-w-lg">
              <div
                class="flex h-full mt-14 flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div
                  class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4"
                >
                  <button
                    type="button"
                    class="rounded-md text-gray-300 hover:text-white focus:outline-none"
                    @click="toggleAction"
                  >
                    <span class="sr-only">Close panel</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="relative flex-1 px-4 sm:px-6">
                  <div class="mb-4">
                    <h2
                      class="text-lg font-semibold text-gray-700 flex items-center"
                    >
                      {{ $t("global.purchaseOrderShort") }}
                      {{ invoicePo.purchaseOrder?.purchaseRequest?.externalId }}
                      <StatusTag
                        v-if="invoicePo.invoice.status"
                        type="invoice"
                        :status="invoicePo.invoice.status"
                        class="ml-2"
                      />
                    </h2>
                    <p class="text-sm text-gray-500">
                      {{ $t("global.decisions.requested") }}:
                      {{ formattedDate(poObject.purchaseRequest.requestedAt) }}
                      / {{ $t("global.decisions.approved") }}:
                      {{ formattedDate(poObject.purchaseRequest.decidedAt) }}
                    </p>
                  </div>
                  <div v-if="poObject.purchaseRequest">
                    <dl
                      class="mt-2 divide-y divide-gray-200 border-gray-200 px-4 rounded bg-gray-50"
                    >
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.requestedBy") }}
                        </dt>
                        <dd class="text-gray-900">
                          <UserWithAvatar
                            :user="poObject.purchaseRequest.requestedBy"
                            show-name
                            :show-avatar="false"
                          />
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.supplier") }}
                        </dt>
                        <dd class="text-gray-900">
                          <SupplierName
                            :purchase-request="poObject.purchaseRequest"
                          />
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.category") }}
                        </dt>
                        <dd class="text-gray-900">
                          <Category
                            :purchase-request="poObject.purchaseRequest"
                            squashed
                          />
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.decisions.ordered") }}
                        </dt>
                        <dd class="text-gray-900">
                          {{ orderedAt }}
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.decisions.delivered") }}
                        </dt>
                        <dd class="text-gray-900">
                          {{ deliveredAt }}
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.pricing.net") }}
                        </dt>
                        <dd
                          class="text-xs text-primary cursor-pointer select-none"
                          @click="showPriceDetails = !showPriceDetails"
                        >
                          {{
                            showPriceDetails ? "Hide details" : "Show Details"
                          }}
                        </dd>
                        <dd class="text-gray-900">
                          <i18n-n
                            :value="parseFloat(poObject.netAmount)"
                            format="currency"
                            :locale="
                              currencyToLocale(poObject.netAmountCurrency)
                            "
                          />
                        </dd>
                      </div>
                    </dl>
                    <dl
                      v-if="showPriceDetails"
                      class="border border-gray-200 py-6 space-y-6 px-4 rounded-md bg-gray-50 mt-1"
                    >
                      <div class="flex items-center justify-between">
                        <dt class="text-sm">
                          {{ $t("global.pricing.net") }}
                        </dt>
                        <dd class="text-sm font-medium text-gray-900">
                          <i18n-n
                            :value="parseFloat(poObject.netAmount)"
                            format="currency"
                            :locale="
                              currencyToLocale(poObject.netAmountCurrency)
                            "
                          />
                        </dd>
                      </div>
                      <div class="flex items-center justify-between">
                        <dt class="text-sm">
                          {{ $t("global.pricing.tax") }}
                        </dt>
                        <dd class="text-sm font-medium text-gray-900">
                          <i18n-n
                            :value="parseFloat(poObject.tax)"
                            format="currency"
                            :locale="currencyToLocale(poObject.taxCurrency)"
                          />
                        </dd>
                      </div>
                      <div
                        class="flex items-center justify-between border-t border-gray-200 pt-6"
                      >
                        <dt class="text-base font-medium">
                          {{ $t("global.pricing.gross") }}
                        </dt>
                        <dd class="text-base font-medium text-gray-900">
                          <i18n-n
                            :value="parseFloat(poObject.grossAmount)"
                            format="currency"
                            :locale="
                              currencyToLocale(poObject.grossAmountCurrency)
                            "
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <h2
                    class="mt-6 text-lg font-semibold text-gray-700 flex items-center"
                  >
                    {{ $t("global.invoice.title") }}
                  </h2>
                  <p class="text-sm text-gray-500">
                    {{ invoiceObject.invoiceNumber }}
                  </p>
                  <div v-if="invoiceObject">
                    <dl
                      class="mt-2 divide-y divide-gray-200 border-gray-200 px-4 rounded bg-hivebuy-lilac/50"
                    >
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.invoice.title") }}
                          {{ $t("global.pricing.net") }}
                        </dt>
                        <dd
                          :class="
                            errorMessage('totalNetAmount').length
                              ? 'text-red-500 '
                              : 'text-gray-900'
                          "
                        >
                          <i18n-n
                            :value="parseFloat(invoiceObject.totalNetAmount)"
                            format="currency"
                            :locale="
                              currencyToLocale(
                                invoiceObject.totalNetAmountCurrency
                              )
                            "
                          />
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.invoice.title") }}
                          {{ $t("global.pricing.tax") }}
                        </dt>
                        <dd
                          :class="
                            errorMessage('tax').length
                              ? 'text-red-500 '
                              : 'text-gray-900'
                          "
                        >
                          <i18n-n
                            :value="parseFloat(invoiceObject.tax)"
                            format="currency"
                            :locale="
                              currencyToLocale(
                                invoiceObject.totalNetAmountCurrency
                              )
                            "
                          />
                        </dd>
                      </div>
                      <div
                        class="flex justify-between py-3 text-sm font-medium"
                      >
                        <dt class="text-gray-500">
                          {{ $t("global.invoice.title") }}
                          {{ $t("global.pricing.gross") }}
                        </dt>
                        <dd
                          :class="
                            errorMessage('totalGrossAmount').length
                              ? 'text-red-500 '
                              : 'text-gray-900'
                          "
                        >
                          <i18n-n
                            :value="parseFloat(invoiceObject.totalGrossAmount)"
                            format="currency"
                            :locale="
                              currencyToLocale(
                                invoiceObject.totalNetAmountCurrency
                              )
                            "
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <div class="grid text-sm relative">
                    <FieldError
                      v-if="invoiceAmountError"
                      :text="$t('invoice.errors.amountIsZeroORBlank')"
                    />
                    <div v-if="invoiceObject?.collective">
                      <h2 class="text-base font-medium text-gray-600 mt-6">
                        Assigning the values to Invoice for this purchase order
                      </h2>
                      <p class="text-sm text-gray-500 mb-4">
                        Filling this out means that the invoice is a collective
                        bill and it will be matched with more than one purchase
                        order
                      </p>
                      <!-- <DecisionComments :purchase-orders="[invoicePo]" /> -->

                      <div class="grid grid-cols-2 gap-2 mb-2">
                        <CurrencyInput
                          id="totalNetAmount"
                          v-model.lazy="invoice.totalNetAmount"
                          label="Total Net Amount"
                          :error-text="errorMessage('totalNetAmount')"
                          placeholder="Total Net Amount"
                          is-mandatory
                        />
                        <CurrencyInput
                          id="totalGrossAmount"
                          v-model.lazy="invoice.totalGrossAmount"
                          :error-text="errorMessage('totalGrossAmount')"
                          placeholder="Gross price"
                          label="Gross price"
                          is-mandatory
                        />
                      </div>

                      <div class="grid grid-cols-2 gap-2 mb-4">
                        <CurrencyInput
                          id="tax"
                          v-model.lazy="invoice.tax"
                          :error-text="errorMessage('tax')"
                          placeholder="Tax"
                          label="Tax"
                          is-mandatory
                        />
                        <div
                          class="mb-2 flex items-center justify-items-center justify-center"
                        >
                          <div v-if="!showCurrency">
                            Currency: {{ invoice.totalNetAmountCurrency }}
                            <span
                              class="ml-2 text-xs text-primary cursor-pointer hover:underline"
                              @click="showCurrency = !showCurrency"
                              >(change)</span
                            >
                          </div>
                          <div v-if="showCurrency">
                            <CurrencySelect
                              v-model="invoice.totalNetAmountCurrency"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="grid grid-cols-1 gap-2 mb-4 mt-4">
                      <TextArea
                        id="notes"
                        name="notes"
                        placeholder="Notes"
                        label="Notes"
                        :value="invoice.notes"
                        :rows="3"
                        :on-value-change="onAttributeChange"
                      />
                    </div>
                    <div class="flex justify-between mb-4 md:mt-3">
                      <Button
                        :color="'gray'"
                        :disabled="loading"
                        :on-click-handler="toggleAction"
                      >
                        {{ $t("buttons.cancel") }}
                      </Button>
                      <div class="flex items-center gap-x-2">
                        <Button
                          :loading="loading"
                          :disabled="invoiceAmountError"
                          :on-click-handler="
                            () => {
                              saveDetails(false);
                            }
                          "
                        >
                          {{ $t("buttons.save") }}
                        </Button>
                        <div class="relative">
                          <ConfirmTip
                            :text="
                              $t('invoice.confirmation.saveAndSendApproval')
                            "
                            :confirm-modal="
                              () => {
                                saveDetails(true);
                              }
                            "
                          >
                            <template #placeholder>
                              <Button
                                :loading="loading"
                                :disabled="invoiceAmountError"
                                :on-click-handler="() => {}"
                              >
                                {{ $t("global.decisions.sendForApproval") }}
                              </Button>
                            </template>
                          </ConfirmTip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { required, between, helpers, requiredIf } from "@vuelidate/validators";
import { TextArea, Button, ConfirmTip } from "@/components/shared";
import useValidate from "@vuelidate/core";
import {
  UserWithAvatar,
  CurrencyInput,
  SupplierName,
  Category,
  FieldError,
} from "@/components/shared/index";
import poStatusMixin from "@/components/mixins/poStatusMixin.js";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import { currencyToLocale } from "@/utils/utility_methods.js";
import { mapGetters } from "vuex";
import { INVOICE_MODULE } from "@/store/Invoice/types";
import { DecisionComments } from "@/components/InvoiceApproval/index.js";
import CurrencySelect from "@/components/shared/CurrencySelect/index.vue";
import StatusTag from "@/components/shared/StatusTag";
const isTaxAmountCorrect = (invoice) => () => {
  const deviation = Math.abs(
    parseFloat(invoice.totalGrossAmount) -
      (parseFloat(invoice.totalNetAmount) + parseFloat(invoice.tax))
  );
  return deviation <= 0.03;
};
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    TextArea,
    Button,
    CurrencyInput,
    UserWithAvatar,
    SupplierName,
    Category,
    // eslint-disable-next-line vue/no-unused-components
    DecisionComments,
    StatusTag,
    ConfirmTip,
    FieldError,
    CurrencySelect,
  },
  mixins: [poStatusMixin],
  inject: ["reloadInvoicesPage"],
  props: {
    open: { type: Boolean, default: false },
    toggleAction: { type: Function, default: () => {} },
    invoiceId: { type: String, required: true },
    invoicePo: { type: Object, required: true },
    saveAction: { type: Function, default: () => {} },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      invoice: {},
      v$: useValidate(),
      showCurrency: false,
      showPriceDetails: false,
      useInvoiceValuesForPO: true,
      orderedAt: null,
      deliveredAt: null,
    };
  },
  computed: {
    ...mapGetters(INVOICE_MODULE, ["invoiceWithId", "invoicePOWithId"]),
    invoiceObject() {
      return this.invoiceWithId(this.invoiceId);
    },
    poObject() {
      return this.invoicePo.purchaseOrder;
    },
    featureAllowed() {
      return this.isFeatureAllowed("invoiceApproval");
    },
    invoiceAmountError() {
      return !!(
        this.errorMessage("totalNetAmount").length ||
        this.errorMessage("totalGrossAmount").length ||
        this.errorMessage("tax").length
      );
    },
  },
  watch: {
    invoicePo: {
      handler() {
        this.invoice =
          this.invoicePOWithId(this.invoicePo.id) ||
          this.invoiceWithId(this.invoiceId) ||
          {};
        this.orderedAndDeliveredAt();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    currencyToLocale,
    async orderedAndDeliveredAt() {
      if (!this.poObject) return;

      const poStatusObject = await this.posSimpleStatus(this.poObject);
      const objectsToExtract = ["ordered", "delivered"];
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      objectsToExtract.forEach((item) => {
        const value = poStatusObject && poStatusObject[item];
        that[`${item}At`] = value
          ? that.formattedDate(poStatusObject[`${item}At`])
          : "-";
      });
    },
    onCurrencyChange(value) {
      this.invoice.totalGrossAmountCurrency = value;
      this.invoice.taxCurrency = value;
      this.invoice.shippingCurrency = value;
      this.invoice.totalAmountCurrency = value;
    },
    errorMessage(attribute) {
      return this.parseError(this.v$, attribute);
    },
    onAttributeChange(event) {
      const { name, value } = event.target ? event.target : event;
      this.invoice[name] = value;
    },
    parseError(validationObject, attribute) {
      if (!validationObject.invoice[attribute]) return "";

      return validationObject.invoice[attribute].$error
        ? validationObject.$errors.find(
            (error) => error.$property === attribute
          ).$message
        : "";
    },
    payload() {
      return {
        purchaseOrder: this.poObject.id || this.poObject,
        invoice: this.invoiceObject.id,
        totalNetAmount: this.invoice.totalNetAmount,
        totalNetAmountCurrency: this.invoice.totalNetAmountCurrency,
        totalGrossAmount: this.invoice.totalGrossAmount,
        totalGrossAmountCurrency: this.invoice.totalNetAmountCurrency,
        tax: parseFloat(this.invoice.tax).toFixed(this.backendDecimalPoint),
        notes: this.invoice.notes,
        taxCurrency: this.invoice.totalNetAmountCurrency,
        shipping: this.invoice.shipping,
        shippingCurrency: this.invoice.totalNetAmountCurrency,
        totalAmount: this.invoice.totalGrossAmount,
        totalAmountCurrency: this.invoice.totalNetAmountCurrency,
      };
    },
    saveDetails(finish = false) {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveAction(this.payload(), finish, () => {
          this.invoice = {};
        });

        this.v$.$reset();
      }
    },
    currencyInputOptions() {
      return {
        currency: "EUR",
        locale: "de-DE",
      };
    },
  },
  validations() {
    return {
      invoice: {
        totalNetAmount: {
          required: helpers.withMessage(
            "Value missing",
            requiredIf(() => this.featureAllowed)
          ),
          between: helpers.withMessage("Value missing", between(1, 100000000)),
          grossAmount: helpers.withMessage(
            this.$t("invoice.errors.netLessThanGross"),
            () => {
              return (
                parseFloat(this.invoice.totalNetAmount) <=
                parseFloat(this.invoice.totalGrossAmount)
              );
            }
          ),
        },
        totalGrossAmount: {
          required: helpers.withMessage(
            "Value missing",
            requiredIf(() => this.featureAllowed)
          ),
          taxAmount: helpers.withMessage(
            this.$t("invoice.errors.taxMismatch"),
            isTaxAmountCorrect(this.invoice)
          ),
          between: helpers.withMessage("Value missing", between(1, 100000000)),
        },
        tax: { required, between: between(0, 100000000) },
      },
    };
  },
};
</script>
