<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="21"
    viewBox="0 0 29 21"
    class="fill-current"
    stroke="currentColor"
  >
    <path
      id="deliver"
      d="M23.06,21a4.016,4.016,0,0,1-.8-.08,3.964,3.964,0,0,1-1.417-.592A4,4,0,0,1,19.4,18.6a3.969,3.969,0,0,1-.237-.74,4.013,4.013,0,0,1-.089-.8H11.131a4.013,4.013,0,0,1-.089.8,3.968,3.968,0,0,1-.607,1.409,4,4,0,0,1-1.749,1.426,3.966,3.966,0,0,1-.744.229,4.025,4.025,0,0,1-1.6,0,3.964,3.964,0,0,1-1.417-.592A4,4,0,0,1,3.484,18.6a3.969,3.969,0,0,1-.237-.74,4.013,4.013,0,0,1-.089-.8H0V0H19.887V2.1h4.884L29,10.608l-.013.006H29v6.447H27.047a4.013,4.013,0,0,1-.089.8,3.968,3.968,0,0,1-.607,1.409A4,4,0,0,1,24.6,20.691a3.966,3.966,0,0,1-.744.229A4.016,4.016,0,0,1,23.06,21Zm0-5.7a1.709,1.709,0,1,0,1.709,1.709A1.711,1.711,0,0,0,23.06,15.3Zm-15.916,0a1.709,1.709,0,1,0,1.709,1.709A1.711,1.711,0,0,0,7.144,15.3Zm-.6-10L4.655,7.2,9.228,11.77l6.626-6.628L13.966,3.254,9.228,7.994,6.542,5.307Zm13.345-.765v6.072h6.39L23.258,4.542Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DeliverSVG",
};
</script>
