<template>
  <div class="grid grid-cols-12 gap-4 mt-4">
    <div class="col-span-12 custom-card no-shadow">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 id="duplicate-request" class="section-title">
            {{ $t(`purchaseRequest.summary.${section}.title`) }}
          </h3>
          <p class="max-w-xl text-sm text-gray-500">
            {{ $t(`purchaseRequest.summary.${section}.desc`) }}
          </p>
        </div>
        <div
          class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center"
        >
          <ConfirmDialog
            :text="$t(`purchaseRequest.summary.${section}.confirmDialog.text`)"
            :show-modal="showConfirmModal"
            :close-modal="() => toggleConfirmModal(false)"
            :title="
              $t(`purchaseRequest.summary.${section}.confirmDialog.title`)
            "
            :item-to-confirm="purchaseRequest.title"
            :confirm-modal="onDuplicateRequest"
          />
          <Button :on-click-handler="() => toggleConfirmModal(edit)">
            {{ $t(`purchaseRequest.summary.${section}.btnText`) }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, ConfirmDialog } from "@/components/shared/index";

export default {
  components: { Button, ConfirmDialog },

  props: {
    duplicateRequest: { type: Function, required: true },
    purchaseRequest: { type: Object, required: true },
  },

  data() {
    return {
      showConfirmModal: false,
      edit: false,
    };
  },
  computed: {
    section() {
      let section = "duplicateRequestSection";
      if (this.edit) section = "editRequestSection";

      return section;
    },
  },
  methods: {
    toggleConfirmModal(edit) {
      this.edit = edit;
      this.showConfirmModal = !this.showConfirmModal;
    },
    onDuplicateRequest() {
      this.duplicateRequest(this.edit);
      this.toggleConfirmModal();
    },
  },
};
</script>
