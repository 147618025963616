<template>
  <ul class="address-list">
    <li v-for="address in addressList" :key="address.id" class="content-row">
      <div class="content flex flex-1 flex-col">
        <div class="flex items-center">
          <h3 class="address-title">
            {{ address.name || company.name }}
          </h3>
          <span v-if="address.defaultDelivery" class="default-badge">
            {{ $t("profile.defaultDeliveryAddress") }}
          </span>
          <span v-if="address.defaultInvoice" class="default-badge">
            {{ $t("profile.defaultInvoiceAddress") }}
          </span>
        </div>

        <div class="content-row__content">
          <p>{{ address.street }} {{ address.houseNumber }}</p>
          <p>{{ address.addressSupplement }}</p>
          <p>{{ address.postalCode }} {{ address.city }}</p>
          <p>{{ address.country }}</p>
        </div>
      </div>
      <div class="flex items-center">
        <a
          class="delete-button"
          @click="
            () => {
              setDelete(address);
            }
          "
        >
          <span> <TrashIcon class="h-5 w-5" aria-hidden="true" /></span>
        </a>
        <a class="edit-button" @click="onEditAddress(address)">
          <span><PencilIcon class="h-5 w-5" aria-hidden="true" /></span>
        </a>
      </div>
    </li>
  </ul>
  <div>
    <EditAddressModal
      :address-to-edit="addressToEdit"
      :show-modal="showEditAddressModal"
      :close-modal="onEditAddressModalClose"
    />
    <ConfirmDialog
      v-if="addressToDelete.id"
      :text="'Are you sure you want to delete this?'"
      :show-modal="showDeleteAddressModal"
      :close-modal="onDeleteModalClose"
      title="Delete address?"
      :item-to-confirm="addressToDelete.name"
      :confirm-modal="confirmDelete"
    />
  </div>
</template>

<script>
import { PencilIcon, TrashIcon } from "@heroicons/vue/solid";
import { mapActions, mapState, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  ADD_COMPANY_ADDRESS,
  GET_COMPANY_ADDRESSES,
  DELETE_COMPANY_ADDRESS,
  UPDATE_COMPANY_ADDRESS,
} from "@/store/CompanyManagement/types";

import { ConfirmDialog } from "@/components/shared/index";
import EditAddressModal from "@/components/CompanyManagement/Profile/CompanyAddresses/EditAddressModal";

export default {
  components: {
    PencilIcon,
    TrashIcon,
    ConfirmDialog,
    EditAddressModal,
  },

  data() {
    return {
      showAddAddressModal: false,
      showEditAddressModal: false,
      showDeleteAddressModal: false,
      addressToEdit: {},
      addressToDelete: {},
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companyAddresses"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["addressList"]),
  },
  mounted() {
    this.fetchCompanyAddresses();
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      addCompanyAddress: ADD_COMPANY_ADDRESS,
      getCompanyAddresses: GET_COMPANY_ADDRESSES,
      deleteCompanyAddress: DELETE_COMPANY_ADDRESS,
      updateCompanyAddress: UPDATE_COMPANY_ADDRESS,
    }),
    setDelete(address) {
      if (this.companyAddresses.length < 2) {
        this.showNotification("There has to be at least 1 address", "error");
      } else {
        this.addressToDelete = address;
        this.showDeleteAddressModal = true;
      }
    },
    confirmDelete() {
      this.deleteCompanyAddress(this.addressToDelete.id)
        .then(() => {
          this.getCompanyAddresses();
          this.addressToDelete = {};
          this.showNotification("Address deleted successfully");
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onDeleteModalClose() {
      this.showDeleteAddressModal = false;
    },
    onEditAddress(address) {
      this.showEditAddressModal = true;
      this.addressToEdit = address;
    },
    onEditAddressModalClose() {
      this.addressToEdit = {};
      this.showEditAddressModal = false;
    },
    fetchCompanyAddresses() {
      if (this.companyAddresses.length) return;
      this.getCompanyAddresses();
    },
  },
};
</script>

<style scoped>
.content-row {
  @apply flex border-b border-gray-200 py-4 justify-between  last:border-b-0;
}

.content-row__label {
  @apply text-sm text-gray-800 mb-2;
}

.address-title {
  @apply font-semibold text-gray-800 text-base mb-1;
}

.default-badge {
  @apply inline-block px-2 py-0.5 text-hivebuy-green text-xs bg-hivebuy-green bg-opacity-20 rounded-full ml-2;
}

.content-row__content {
  @apply text-sm text-gray-500 flex-1;
}

.edit-button {
  @apply h-8 flex items-center justify-center rounded-full bg-hivebuy-green text-white px-4 cursor-pointer hover:contrast-125;
}

.delete-button {
  @apply h-8 flex items-center justify-center rounded-full border border-hivebuy-red text-hivebuy-red mr-2 px-4 cursor-pointer;
}
</style>
