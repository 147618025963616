import VueApexCharts from "vue3-apexcharts";
import VueClipboard from "vue3-clipboard";
import VueSweetalert2 from "vue-sweetalert2";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import Popper from "vue3-popper";
import FileInput from "@/components/shared/UploadArea.vue";
import StatusTag from "@/components/shared/StatusTag.vue";
import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/border.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/shift-toward.css";
import { UserPopup, Tip } from "@/components/shared/index";
import Notifications from "vue3-vt-notifications";
import vClickOutside from "click-outside-vue3";
import PrimeVue from "primevue/config";
import Vidle from "v-idle-3";
import TruncateText from "@/components/shared/TruncateText.vue";
import RequestFileFields from "@/components/PurchaseRequest/RequestFileFields.vue";
import ConfirmationService from "primevue/confirmationservice";
export const defineGlobalComponents = (instance) => {
  instance
    .use(Vidle)
    .use(PrimeVue)
    .use(Notifications)
    .use(vClickOutside)
    .use(VueApexCharts)
    .use(VueSweetalert2)
    .use(autoAnimatePlugin)
    .use(ConfirmationService)
    .use(VueClipboard, { autoSetContainer: true })
    .use(VueTippy, {
      directive: "tippy",
      component: "tippy",
      componentSingleton: "tippy-singleton",
      defaultProps: {
        placement: "top",
        allowHTML: true,
        interactive: true,
        animation: "scale",
      },
    })
    .component("Popper", Popper)
    .component("FileInput", FileInput)
    .component("StatusTag", StatusTag)
    .component("UserPopup", UserPopup)
    .component("Tip", Tip)
    .component("TruncateText", TruncateText)
    .component("RequestFileFields", RequestFileFields);
};
