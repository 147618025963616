<template>
  <div class="flex items-center gap-x-1">
    <Processing v-if="fileUploading()" />
    <div v-for="model in modelsStatus" :key="model">
      <Button
        v-if="model.value || model.downloading"
        :on-click-handler="() => fetchFile(model.model)"
        size-class="sm"
        :loading="model.downloading"
        :color="model.error ? 'danger' : 'primary'"
      >
        <template #icon>
          <DownloadIcon />
        </template>
        <span v-if="model.error">
          {{
            $t(`buttons.csvUpload.error`, {
              type: $t(`buttons.csvExport.${model.model}`),
            })
          }}
        </span>
        <span v-else>
          {{ $t(`buttons.csvExport.${model.model}`) }}
        </span>
      </Button>
    </div>
  </div>
</template>
<script>
import { Button, Processing } from "@/components/shared/";
import downloadMixin from "@/components/mixins/downloadMixin.js";
import { DownloadIcon } from "@heroicons/vue/solid";
export default {
  components: { Button, DownloadIcon, Processing },
  mixins: [downloadMixin],
  props: {
    models: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    modelsStatus() {
      return this.exportStatusesForArray(this.models);
    },
  },
};
</script>
