<template>
  <Panel
    ref="panel"
    unstyled
    toggleable
    :collapsed="true"
    :pt="{
      header: 'hb-panel-header',
      root: ['hb-panel-root', error ? 'error' : ''],
      content: 'hb-panel-content',
      toggler: 'hb-panel-toggler',
    }"
  >
    <template #togglericon="{ collapsed }">
      <ChevronDownIcon
        class="text-primary h-6 w-6 transition-transform"
        :class="collapsed ? '' : 'rotate-180'"
      />
    </template>
    <template #header>
      <div
        className="self-stretch py-1 justify-start items-center gap-2.5 inline-flex cursor-pointer w-full"
        @click="toggle"
      >
        <div
          className="grow shrink basis-0 text-[#9e4770] text-[12.80px] font-semibold"
        >
          {{ $t("purchaseRequest.newRequest.advancedInformation") }}
        </div>
      </div>
      <ExclamationIcon v-if="error" class="h-5 w-5 text-hivebuy-red" />
    </template>
    <div class="w-full flex flex-col gap-y-4 py-4">
      <RequestFiles
        :source-object="item"
        source="request"
        file-key="files"
        :delete-file="deleteFile"
        :allow-delete="true"
        :save-files="saveFiles"
      />
      <template v-if="isFeatureAllowed('customFields') && customFields.length">
        <div class="py-2 md:py-4 lg:py-6 border border-gray-300 rounded px-4">
          <CustomFieldForm
            v-model="item.customFields"
            :validation-object="v?.item?.customFields"
          />
        </div>
      </template>
      <RecurrenceInterval
        v-model="item.recurrence"
        :error="errorMessage('recurrence')"
      />
      <AdvanceOptionsForm
        v-if="!item.isIntegratedSupplierItem"
        v-model="item"
        :v="v"
        :show-address="false"
      />
    </div>
  </Panel>
</template>

<script>
import RecurrenceInterval from "@/components/shared/RecurrenceInput.vue";
import AdvanceOptionsForm from "@/components/PurchaseRequest/Form/AdvanceOptionsForm.vue";
import CustomFieldForm from "@/components/CustomFields/CustomFieldForm.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapState } from "vuex";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import RequestFiles from "@/components/PurchaseRequest/SummarySections/RequestFiles.vue";
import Panel from "primevue/panel";
import { ChevronDownIcon, ExclamationIcon } from "@heroicons/vue/solid";
export default {
  name: "AdvanceInfo",
  components: {
    RequestFiles,
    AdvanceOptionsForm,
    CustomFieldForm,
    RecurrenceInterval,
    Panel,
    ChevronDownIcon,
    ExclamationIcon,
  },
  mixins: [newRequestErrorMixin],
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    v: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    isCatalogueItem() {
      return (
        this.requestedForType(this.item.sourceType) ===
        CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM
      );
    },
    isIntegratedSupplier() {
      return (
        this.requestedForType(this.item.sourceType) ===
        CATALOGUE_ITEM_SOURCE.SUPPLIER
      );
    },
  },
  methods: {
    async saveFiles(newFiles, callback) {
      const files = this.item.files || [];
      this.item.files = [...files, newFiles];
      callback();
    },
    deleteFile(index) {
      this.item.files.splice(index, 1);
    },
    saveRequestAttributes(value) {
      this.$emit("update:modelValue", value);
    },
    togglePanel(value) {
      this.$refs.panel.d_collapsed = value;
    },
    toggle() {
      this.$refs.panel.toggle();
    },
  },
};
</script>
