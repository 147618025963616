<template>
  <div class="relative w-full">
    <span class="flex justify-center text-base font-normal text-primarydark"
      >{{ $t("global.catalogue") }} {{ $t("global.description") }}</span
    >
    <div
      id="description"
      ref="description"
      :class="[
        'bg-white rounded border border-gray-200 overflow-hidden transition-all duration-300 ease-in-out',
      ]"
      :style="{ maxHeight: isExpanded ? contentHeight : '7rem' }"
    >
      <div ref="innerContent" :class="isTruncated || isExpanded ? 'pb-6' : ''">
        <slot name="description">
          <p class="text-gray-700">No description provided</p>
        </slot>
      </div>

      <div
        v-if="isTruncated || isExpanded"
        :class="['inset-x-0 bottom-0 absolute']"
      >
        <div
          class="flex items-center justify-center bg-gray-300/10 backdrop-blur-md py-1"
        >
          <div
            class="text-hivebuy_plum cursor-pointer text-sm flex items-center py-0.5 px-2 rounded-lg border border-gray-300 bg-white"
            @click="toggleExpand"
          >
            <ChevronDownIcon
              :class="['h-5 w-5 transition-transform', iconClasses]"
            />
            {{ text }}
            <ChevronDownIcon
              :class="['h-5 w-5 transition-transform', iconClasses]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      isExpanded: false,
      isTruncated: false,
      contentHeight: "0px",
    };
  },
  computed: {
    text() {
      return this.isExpanded
        ? this.$t("catalogue.catalogueItemForm.showLess")
        : this.$t("catalogue.catalogueItemForm.showMore");
    },
    iconClasses() {
      return this.isExpanded ? "transform rotate-180" : "";
    },
  },
  mounted() {
    this.checkIfTruncated();
    window.addEventListener("resize", this.checkIfTruncated);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfTruncated);
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.updateContentHeight();
    },

    checkIfTruncated() {
      this.$nextTick(() => {
        const contentEl = this.$refs.description;
        const innerContentEl = this.$refs.innerContent;
        this.isTruncated = innerContentEl.scrollHeight > contentEl.clientHeight;
        this.updateContentHeight();
      });
    },
    updateContentHeight() {
      this.$nextTick(() => {
        const innerContentEl = this.$refs.innerContent;
        this.contentHeight = this.isExpanded
          ? `${innerContentEl.scrollHeight}px`
          : "7rem";
      });
    },
  },
};
</script>
<style>
#description h1 {
  @apply text-lg font-semibold my-2;
}

#description a {
  @apply font-bold text-primary;
}

#description a:hover {
  @apply underline;
}
</style>
