<template>
  <div class="h-full flex">
    <div class="custom-card no-shadow flex flex-col justify-between">
      <div class="flex items-center justify-between">
        <h3 class="section-title text-base">
          <div class="flex items-center">
            <div class="flex h-12 w-12 items-center ml-2">
              <ZapierSVG class="h-8 w-8 mb-1" />
            </div>
            {{ $t("companyManagement.integrations.zapierTitle") }}
          </div>
        </h3>
      </div>
      <div class="flex flex-col justify-between h-full gap-y-3">
        <div class="border border-gray-200 bg-gray-50 p-4 rounded-md h-full">
          <div class="flex flex-col justify-content h-full">
            <div class="text-gray-600 object-bottom">
              {{ $t("companyManagement.integrations.zapierText") }}
            </div>
          </div>
        </div>
        <div
          v-if="showZapierData"
          class="grid grid-cols-1 gap-y-1 text-sm font-normal rounded-md p-2 border border-primary"
        >
          <div class="flex justify-between mb-4">
            <Button :on-click-handler="closeZapierData" :color="'gray'">
              {{ $t("button.cancel") }}
            </Button>
            <Button :on-click-handler="addToZapier">
              <template #icon>
                <ExternalLinkIcon />
              </template>

              {{ $t("companyManagement.integrations.zapier.add") }}
            </Button>
          </div>
          <div>
            <label for="app-id" class="font-bold"
              >{{ $t("companyManagement.integrations.zapier.id") }}
              <span class="text-xs font-light">{{
                $t("button.clickToCopy")
              }}</span>
            </label>
            <div class="truncate">
              <HivebuyTooltip :open-delay="50">
                <div
                  id="app-id"
                  v-clipboard:copy="zapierData.appId"
                  v-clipboard:success="onCopy"
                  name="AppID"
                  class="cursor-pointer hover:text-primary"
                >
                  {{ zapierData.appId }}
                </div>
                <template #content>
                  <div class="p-2">
                    <div>
                      {{ $t("button.clickToCopy") }}
                      {{ $t("companyManagement.integrations.zapier.id") }}
                    </div>
                  </div>
                </template>
              </HivebuyTooltip>
            </div>
          </div>
          <div>
            <label for="app-id" class="font-bold"
              >{{ $t("companyManagement.integrations.zapier.iK") }}
              <span class="text-xs font-light">{{
                $t("button.clickToCopy")
              }}</span>
            </label>
            <div class="truncate">
              <HivebuyTooltip :open-delay="50">
                <div
                  id="integration-key"
                  v-clipboard:copy="zapierData.integrationKey"
                  v-clipboard:success="onCopy"
                  name="IntegrationKey"
                  class="cursor-pointer hover:text-primary"
                >
                  {{ zapierData.integrationKey }}
                </div>
                <template #content>
                  <div class="p-2">
                    <div>
                      {{ $t("button.clickToCopy") }}
                      {{ $t("companyManagement.integrations.zapier.iK") }}
                    </div>
                  </div>
                </template>
              </HivebuyTooltip>
            </div>
          </div>
        </div>
        <div v-else>
          <Button
            :on-click-handler="getZapierData"
            :loading="loading"
            class="relative flex border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-white disabled buttonHelper"
          >
            {{ $t("button.showData") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_SVIX_LOGIN_DATA,
} from "@/store/CompanyManagement/types";
import ZapierSVG from "@/assets/images/ZapierSVG";
import { ExternalLinkIcon } from "@heroicons/vue/outline";
import { Button, HivebuyTooltip } from "@/components/shared";

export default {
  name: "Zapier",
  components: {
    ZapierSVG,
    Button,
    HivebuyTooltip,
    ExternalLinkIcon,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showZapierData: false,
      zapierData: {},
      appIdCopied: false,
      integrationIdCopied: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getSvixLoginData: GET_SVIX_LOGIN_DATA,
    }),
    getZapierData() {
      this.loading = true;
      this.getSvixLoginData(this.integration.id)
        .then((response) => {
          this.zapierData = response.data;
          this.showZapierData = true;
        })
        .catch(() => {
          this.showNotification(
            "Error while getting Zapier data - please try again later or contact our support at support@hivebuy.de",
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCopy() {
      this.showNotification(
        `${this.$t("companyManagement.integrations.zapier.copySuccess")}`
      );
    },
    addToZapier() {
      window.open(this.zapierData.zapierInviteURL, "_blank").focus();
    },
    closeZapierData() {
      this.showZapierData = false;
    },
  },
};
</script>

<style scoped>
.buttonHelper {
  width: 135px;
  height: 37px;
}
</style>
