export default {
  data() {
    return {
      sortOrder: 1,
      sortBy: "",
    };
  },
  methods: {
    tableSortClassMapping(position) {
      return {
        0: "icon-sort",
        1: "icon-sort icon-sort-up",
        2: "icon-sort icon-sort-down",
      }[position];
    },
    setSortValues(fieldName) {
      this.sortBy = fieldName;
      const prevPosition = this.sortOrder;
      this.sortOrder = prevPosition !== 1 ? 1 : 2;

      // TODO: Need to remove this check when we have applies pagination on every table component which has sorting
      if (this.updatePaginationParams) {
        const orderingValue =
          this.sortOrder == 2 ? `-${this.sortBy}` : this.sortBy;
        this.updatePaginationParams("ordering", orderingValue);
      }
    },
    setSortOrder() {
      const prevPosition = this.sortOrder;
      this.sortOrder = prevPosition !== 1 ? 1 : 2;
    },
    getSortClass() {
      return this.tableSortClassMapping(this.sortOrder);
    },
    getSortedData(data) {
      if (this.sortBy) {
        data = data.sort((a, b) => {
          const sortFieldVal1 = a[this.sortBy];
          const sortFieldVal2 = b[this.sortBy];
          if (sortFieldVal1?.toLowerCase() > sortFieldVal2?.toLowerCase()) {
            return this.sortOrder === 1 ? 1 : -1;
          }

          if (sortFieldVal1?.toLowerCase() < sortFieldVal2?.toLowerCase()) {
            return this.sortOrder === 1 ? -1 : 1;
          }

          return 0;
        });
      }
      return data;
    },
  },
};
