<template>
  <div class="custom-card no-shadow flex mt-4">
    <div class="category flex mr-12 text-base">
      <p class="mr-2">
        {{
          $t(`global.prepositions.selected`, {
            object: $t("global.category"),
          })
        }}
      </p>
      <div @click="() => onModalOpen('category')">
        <HivebuyTooltip :open-delay="50" :disabled="!showEdit">
          <span
            class="text-primary font-semibold"
            :class="showEdit ? 'cursor-pointer hover:underline' : ''"
          >
            <Category v-if="category" :category="category" />
            <div v-else>
              {{
                $t(`global.prepositions.selected`, {
                  object: $t("global.category"),
                })
              }}
            </div>
          </span>
          <template #content>
            <div class="p-2">Edit category</div>
          </template>
        </HivebuyTooltip>
      </div>
    </div>

    <div class="vendor flex mr-8 text-base">
      <p class="mr-2">
        {{
          $t(`global.prepositions.selected`, {
            object: $t("global.supplier"),
          })
        }}
      </p>

      <div class="flex items-center">
        <HivebuyTooltip :open-delay="50">
          <span
            class="font-semibold text-primary"
            :class="showEdit ? 'cursor-pointer hover:underline' : ''"
            @click="() => onModalOpen('supplier')"
          >
            <SupplierName v-if="supplier" :supplier="supplier" />
            <UnlistedSupplierBadge
              v-else-if="['unlisted', 'skip'].includes(itemSupplier?.type)"
              :unlisted-supplier="unlistedSupplierName"
              :skipped="itemSupplier.type == 'skip'"
              class="ml-2"
            />
            <div v-else>
              {{
                $t(`global.prepositions.selected`, {
                  object: $t("global.supplier"),
                })
              }}
            </div>
          </span>
          <template #content>
            <div class="p-2">Edit supplier</div>
          </template>
        </HivebuyTooltip>
      </div>
    </div>
  </div>
  <CategorySupplierEdit
    :open="showModal"
    :on-modal-close="onModalClose"
    :selected-option="selectedOption"
    :save-details-attr="saveDetailsAttr"
    :selected-category="category"
    :selected-supplier="supplier"
    :show-skip="!invoiceForm"
  />
</template>
<script>
import {
  UnlistedSupplierBadge,
  Category,
  SupplierName,
} from "@/components/shared";
import CategorySupplierEdit from "@/components/PurchaseRequest/Form/CategorySupplierEdit.vue";
import { HivebuyTooltip } from "@/components/shared/index";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types.js";
import { mapState } from "vuex";

export default {
  components: {
    UnlistedSupplierBadge,
    CategorySupplierEdit,
    HivebuyTooltip,
    Category,
    SupplierName,
  },
  props: {
    invoiceForm: { type: Boolean, default: false },
    showEdit: { type: Boolean, default: true },
    saveDetailsAttr: { type: Function, default: () => {} },
    category: {
      type: String,
      default: null,
    },
    supplier: {
      type: [String, Object],
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      selectedOption: null,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    itemSupplier() {
      return this.supplier || this.currentRequest.supplier;
    },
    unlistedSupplierName() {
      return this.itemSupplier?.details?.name || "";
    },
  },
  methods: {
    onModalClose() {
      this.showModal = false;
      setTimeout(() => {
        this.selectedOption = null;
      }, 300);
    },
    onModalOpen(selectedOption) {
      if (!this.showEdit && !this.invoiceForm) return;
      this.showModal = true;
      this.selectedOption = selectedOption;
    },
  },
};
</script>
