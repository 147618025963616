<template>
  <HivebuyLoader v-if="loading" class="h-36" />
  <div v-else id="product-wizard" class="product-wizard">
    <div class="wizard-header">
      <Stepper />
      <div class="reset-all">
        <div class="absolute top-8 right-6">
          <StartOver />
        </div>
      </div>
    </div>
    <div class="wizard-content">
      <div v-auto-animate class="relative flex h-[calc(100%-10px)] w-full">
        <CategorySelection
          v-if="currentStep().category"
          class="section w-full"
          :selected-category="currentRequest.category"
        />
        <SupplierSelection
          v-else-if="currentStep().supplier"
          :selected-supplier="currentRequest.supplier?.details"
        />
        <ItemForm v-else-if="currentStep().item" />
        <Cart v-else-if="currentStep().summary" class="section w-full" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  CategorySelection,
  SupplierSelection,
  Stepper,
  ItemForm,
  Cart,
} from "@/components/PurchaseRequest/FreeForm/index.js";
import { mapGetters, mapState, mapMutations } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  MARK_ITEM_AS_ACTIONABLE,
} from "@/store/PurchaseRequest/types";
import { StartOver } from "@/components/PurchaseRequest";
import {
  copyPurchaseRequestDataMixin,
  GetPurchaseRequestMixin,
  prPermissionMixin,
} from "@/components/mixins/index";
import { HivebuyLoader } from "@/components/shared/index";
export default {
  components: {
    CategorySelection,
    SupplierSelection,
    Stepper,
    StartOver,
    ItemForm,
    Cart,
    HivebuyLoader,
  },
  mixins: [
    prPermissionMixin,
    copyPurchaseRequestDataMixin,
    GetPurchaseRequestMixin,
  ],
  data() {
    return {
      purchaseRequest: null,
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["currentStep"]),
    isEditRoute() {
      return this.$route.name == "PurchaseRequestEdit";
    },
    loading() {
      const apis = [
        "members",
        "categories",
        "companySuppliers",
        "companyAddresses",
        "departments",
        "paymentMethods",
      ];
      return apis.some((api) => this.isApiLoading(api));
    },
  },
  async mounted() {
    this.isEditRoute && (await this.setData());
    this.setItemActionable({ position: 0, isPreview: false });
  },

  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setItemActionable: MARK_ITEM_AS_ACTIONABLE,
    }),
    async setData() {
      this.purchaseRequest = await this.getRequestData(this.$route.params.id);
      this.validateEdit();
      this.setStoreState();
    },
    validateEdit() {
      if (this.editableRequest(this.purchaseRequest)) return true;
      this.$router.push({ name: "Dashboard" });
      this.showNotification(this.$t("purchaseRequest.errors.notAllowed"));
    },
    setStoreState() {
      this.setNewPurchaseRequest({
        payload: this.duplicatePurchaseRequestPayload(true),
        edit: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  opacity: 1;
  transition: all 0.8s ease;
}
.section.hide {
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 0px;
  width: 0px;
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
  position: absolute;
  width: 100%;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.product-wizard {
  @apply flex flex-col bg-gray-50 border border-gray-200 shadow-md rounded-md overflow-hidden h-full  relative;
}

.loader-holder {
  @apply text-primary flex ml-2 flex-col w-[98%] h-full justify-center items-center;
}

.wizard-header {
  @apply w-[65%] m-auto;
}

.wizard-content {
  @apply bg-white border-t border-x-gray-200 p-8 rounded-t-3xl flex-1 overflow-auto;
}
</style>
