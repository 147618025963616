<template>
  <div>
    <label class="block text-sm text-gray-700 mb-2 pt-1 capitalize label">
      {{ label ? label : name }}
      <span v-if="isMandatory" class="text-red-600">*</span>
    </label>
    <div v-if="!countryList">
      <select
        :name="name"
        class="block w-full px-4 border border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-base"
        @change="onChange"
      >
        <option v-for="option in options" :key="option">{{ option }}</option>
      </select>
    </div>
    <div v-else>
      <select
        :name="name"
        class="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        @change="onChange"
      >
        <option v-for="option in options" :key="option" :value="option.abbr">
          {{ option.name_en }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    mode: {
      type: String,
      default: "single",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    countryList: {
      type: Boolean,
      default: false,
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
