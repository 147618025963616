<template>
  <aside
    :class="[
      'sidebar',
      { 'perm-open': isSidebarPermanentlyOpen, open: isSidebarTemporaryOpen },
    ]"
    @mouseover="setSidebarStatus(true)"
    @mouseleave="setSidebarStatus(false)"
  >
    <NewPRButton v-if="!isUserOnlyAuditor" class="mt-2 z-30" />
    <div class="nav-holder">
      <Navigation
        :is-sidebar-permanently-open="isSidebarPermanentlyOpen"
        :is-sidebar-temporary-open="isSidebarTemporaryOpen"
        :toggle-side-bar-collapsed="toggleSideBarCollapsed"
      />
      <div class="px-2">
        <Timer
          v-if="trialMode"
          :demo-mode-until="demoModeUntil"
          :is-sidebar-open="isSidebarTemporaryOpen || isSidebarPermanentlyOpen"
        />
      </div>
    </div>
  </aside>
</template>
<script>
import Navigation from "./Sidebar/Navigation.vue";
import Timer from "@/components/Home/Timer.vue";
import NewPRButton from "@/components/shared/NewPRButton.vue";
export default {
  components: {
    Navigation,
    Timer,
    NewPRButton,
  },
  props: {
    sidebarOpen: {
      type: Function,
      required: true,
    },

    isSidebarPermanentlyOpen: {
      type: Boolean,
      default: false,
    },
    isSidebarTemporaryOpen: {
      type: Boolean,
      default: false,
    },
    trialMode: {
      type: Boolean,
      default: false,
    },
    demoModeUntil: { type: String, default: null },
    toggleSideBarCollapsed: { type: Function, required: true },
  },
  data() {
    return {
      sidebar: false,
    };
  },

  methods: {
    setSidebarStatus(value) {
      if (!this.isSidebarPermanentlyOpen) this.sidebarOpen(value);
    },
  },
};
</script>

<style scoped>
.sidebar {
  @apply bg-primary transition-all flex flex-col duration-500 ease-in-out pt-[60px] overflow-x-hidden overflow-y-auto w-[88px] fixed top-0 lg:left-0 z-50 left-[-88px];
  @apply hover:rounded-r-xl;
}

.sidebar.open {
  @apply left-0 w-[300px] rounded-r-xl lg:relative;
}

.sidebar.perm-open {
  @apply left-0 w-[300px] rounded-r-xl lg:relative;
}

.nav-holder {
  @apply overflow-y-auto overflow-x-hidden flex flex-col py-4 pb-[6rem] sm:pb-4;
  height: calc(100vh - 60px);
}
</style>
