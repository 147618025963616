import { snakeToCamel } from "@/utils/utility_methods.js";
const reverseString = (url) => {
  return url.split("").reverse().join("");
};
export const keyFromUrl = (url) => {
  const reversedUrl = reverseString(url).slice(1);
  const firstIndex =
    reversedUrl.indexOf("/") >= 0
      ? reversedUrl.indexOf("/")
      : reversedUrl.length;
  const key = reversedUrl.substring(0, firstIndex);
  return snakeToCamel(reverseString(key));
};
