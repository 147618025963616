<template>
  <span>{{ dotsText }}</span>
</template>

<script>
export default {
  name: "Dots",

  data() {
    return {
      dots: 1,
    };
  },
  computed: {
    dotsText() {
      return ".".repeat(this.dots);
    },
  },
  mounted() {
    this.startDotAnimation();
  },
  methods: {
    startDotAnimation() {
      setInterval(() => {
        this.dots = (this.dots + 1) % 4;
      }, 500);
    },
  },
};
</script>
