<template>
  <div class="popover-holder">
    <Popper :placement="placement" class="popper-z-index">
      <slot>
        <button class="focus:outline-none question-mark">
          <div
            class="h-5 w-5 cursor-pointer"
            :class="
              altColor
                ? 'text-gray-200 hover:text-gray-300'
                : 'text-gray-400 hover:text-gray-500'
            "
          >
            <QuestionMarkCircleIcon :class="iconClass" />
          </div>
        </button>
      </slot>
      <template #content="{ close }">
        <div class="popover max-w-lg">
          <div class="popover-header text-primary font-semibold">
            <slot name="header" />
            <div class="popover-close">
              <button type="button" @click="close">
                <span class="sr-only">{{ $t("buttons.close") }}</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div class="popover-body">
            <slot name="body" />
          </div>
          <div class="popover-footer">
            <slot name="footer" />
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import { QuestionMarkCircleIcon, XIcon } from "@heroicons/vue/solid";

export default {
  components: { QuestionMarkCircleIcon, XIcon },
  props: {
    altColor: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: null,
    },
    placement: {
      type: String,
      default: "bottom",
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.popper-z-index .popper) {
  z-index: 100;
}
</style>
