<template>
  <div v-if="noDisplay">
    <div class="label">{{ labelText }}</div>
    <Multiselect
      :ref="refString"
      v-model="selectorValue"
      class="w-full bg-white"
      :mode="mode"
      :track-by="['name', 'street', 'city']"
      :value-prop="'id'"
      :object="objectType"
      :options="optionsList"
      :placeholder="$t('global.dropdown.pleaseSelect')"
      :can-clear="canClear"
      :searchable="true"
      :can-deselect="canDeselect"
    >
      <template #tag="{ option, handleTagRemove, disabled }">
        <div
          :class="[
            'multiselect-tag tag-section',
            { 'is-disabled': disabled },
            option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
          ]"
        >
          <span v-if="addDefault" class="multiselect-tag-remove">
            <UndefaultIcon
              v-if="option.isDefault"
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
            <DefaultIcon
              v-else
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
          </span>
          {{ option.name || company.name }}
          <span
            v-if="!disabled"
            class="multiselect-tag-remove"
            @mousedown.prevent="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon" />
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="gap-x-4 w-4/5 grid grid-cols-2">
          <div class="text-base font-medium">
            {{ option.name || company.name }}
            <p v-if="option.isDefault" class="text-xs font-medium">(Default)</p>
          </div>
          <div class="text-xs">
            <p>{{ option.street }} {{ option.houseNumber }}</p>
            <p>{{ option.addressSupplement }}</p>
            <p>{{ option.postalCode }} {{ option.city }}</p>
            <p>{{ option.country }}</p>
          </div>
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label font-normal text-sm truncate">
          {{ value.street }} - {{ value.city }}
        </div>
      </template>
    </Multiselect>
  </div>
  <div v-if="!noDisplay && isAddress" class="section bg-white">
    <dt v-if="showTitle" class="section-title">
      {{ labelText }}
    </dt>
    <dt>
      <HivebuyInlineEdit ref="address" :allow-edit="isEdit && !loading">
        <template #header>
          <span v-if="loading"></span>
          <span v-else class="block">
            {{ addressDetails?.[0]?.name }}
          </span>
        </template>
        <template #subtitle>
          <HivebuyLoader v-if="loading" class="h-12 w-12" />

          <div v-else-if="isAddress" class="section">
            <dd v-if="displayObjects?.length > 1" class="flex ml-1">
              <p>
                {{
                  isInvoiceAddress
                    ? $t("tooltip.squashedText.invoiceAddress", {
                        count: displayObjects.length,
                      })
                    : $t("tooltip.squashedText.deliveryAddress", {
                        count: displayObjects.length,
                      })
                }}
              </p>
              <Tip help theme="tooltip">
                <template #header>
                  <div class="flex items-center truncate">
                    {{ labelText }}
                  </div>
                </template>
                <template #content>
                  <div class="card">
                    <p
                      v-for="(address, index) in addressDetails"
                      :key="index"
                      class="tooltip-subtext px-4 py-2"
                    >
                      <span class="block">
                        {{ address?.street }}
                        {{ address?.houseNumber }}
                      </span>
                      <span class="block">
                        {{ address?.postalCode }}
                        {{ address?.city }}
                      </span>
                      <span class="block">
                        {{ address?.country }}
                      </span>
                    </p>
                  </div>
                </template>
              </Tip>
            </dd>
            <dd
              v-else-if="addressDetails[0]"
              class="text-gray-500"
              :class="small ? 'text-xs' : 'text-sm'"
            >
              <span class="block">
                {{ addressDetails[0].street }}
                {{ addressDetails[0].houseNumber }}
              </span>
              <span class="block">
                {{ addressDetails[0].postalCode }}
                {{ addressDetails[0].city }}
              </span>
              <span class="block">
                {{ addressDetails[0].country }}
              </span>
            </dd>
            <dd v-else>-</dd>
          </div>
        </template>
        <template #editSection>
          <div class="w-full">
            <Multiselect
              :ref="refString"
              v-model="selectorValue"
              class="w-full bg-white"
              :mode="mode"
              :track-by="['name', 'street', 'city']"
              :value-prop="'id'"
              :object="objectType"
              :options="optionsList"
              :placeholder="$t('global.dropdown.pleaseSelect')"
              :can-clear="canClear"
              :searchable="true"
              :can-deselect="canDeselect"
            >
              <template #tag="{ option, handleTagRemove, disabled }">
                <div
                  :class="[
                    'multiselect-tag tag-section',
                    { 'is-disabled': disabled },
                    option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
                  ]"
                >
                  <span v-if="addDefault" class="multiselect-tag-remove">
                    <UndefaultIcon
                      v-if="option.isDefault"
                      class="w-4 h-4 text-white flex-none"
                      @mousedown.prevent="onMarkAsDefault(option)"
                    />
                    <DefaultIcon
                      v-else
                      class="w-4 h-4 text-white flex-none"
                      @mousedown.prevent="onMarkAsDefault(option)"
                    />
                  </span>
                  {{ option.name || company.name }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon" />
                  </span>
                </div>
              </template>
              <template #option="{ option }">
                <div class="gap-x-4 w-4/5 grid grid-cols-2">
                  <div class="text-base font-medium">
                    {{ option.name || company.name }}
                    <p v-if="option.isDefault" class="text-xs font-medium">
                      (Default)
                    </p>
                  </div>
                  <div class="text-xs">
                    <p>{{ option.street }} {{ option.houseNumber }}</p>
                    <p>{{ option.addressSupplement }}</p>
                    <p>{{ option.postalCode }} {{ option.city }}</p>
                    <p>{{ option.country }}</p>
                  </div>
                </div>
              </template>
              <template #singlelabel="{ value }">
                <div
                  class="multiselect-single-label font-normal text-sm truncate"
                >
                  {{ value.street }} - {{ value.city }}
                </div>
              </template>
            </Multiselect>
            <div
              v-if="showSaveButtons"
              class="flex items-center justify-end gap-x-1 mt-1"
            >
              <CheckCircleIcon class="confirm_icon" @click.stop="onConfirm" />
              <XCircleIcon class="cancel_icon" @click.stop="onCancel" />
            </div>
          </div>
          <div v-if="isEdit" class="flex items-center my-2">
            <HivebuyTooltip v-if="addDefault" :open-delay="50">
              <QuestionMarkCircleIcon class="w-4 h-4 text-primary" />
              <template #content>
                <div class="p-2">
                  <i18n-t
                    keypath="legalTendency.legalTendencyModal.tooltipDefault"
                    tag="span"
                    class="text-base"
                  >
                    <template #icon>
                      <span class="inline-block">
                        <DefaultIcon class="w-4 h-4 text-primary" />
                      </span>
                    </template>
                    <template #object>
                      {{ labelText }}
                    </template>
                  </i18n-t>
                </div>
              </template>
            </HivebuyTooltip>
            <FieldError v-if="isInvalid" :text="errorMessage" />
          </div>
        </template>
      </HivebuyInlineEdit>
    </dt>
  </div>
</template>

<script>
import {
  COMPANY_MANAGEMENT_MODULE,
  FETCH_COMPANY_ADDRESS,
} from "@/store/CompanyManagement/types";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import FieldError from "@/components/shared/FieldError.vue";
import {
  BookmarkIcon as UndefaultIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
import { BookmarkIcon as DefaultIcon } from "@heroicons/vue/outline";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import { deepClone, isObject } from "@/utils/utility_methods";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { HivebuyInlineEdit } from "design-buy";
import HivebuyLoader from "@/components/shared/HivebuyLoader.vue";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/solid";
export default {
  components: {
    Multiselect,
    FieldError,
    DefaultIcon,
    UndefaultIcon,
    HivebuyTooltip,
    QuestionMarkCircleIcon,
    HivebuyInlineEdit,
    HivebuyLoader,
    CheckCircleIcon,
    XCircleIcon,
  },
  inject: ["index"],
  props: {
    modelValue: {
      type: [Object, String],
      default: "",
    },
    noDisplay: {
      type: Boolean,
      default: false,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
    addressId: {
      type: String,
      default: "",
    },
    objectType: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: true,
    },
    isInvoiceAddress: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "",
    },
    requestedForId: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    addDefault: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showSaveButtons: {
      type: Boolean,
      default: false,
    },
    objectFilterKey: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      tempValue: null,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company", "companyAddresses"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "getCompanyAddressObject",
      "addressList",
    ]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),
    displayObjects() {
      if (this.list.length) return this.list;
      if (this.isMultiSelect) return this.modelValue;

      return [this.addressId || this.modelValue];
    },
    isMultiSelect() {
      return this.mode !== "single";
    },
    filterKey() {
      if (this.objectFilterKey) return this.objectFilterKey;
      return this.isInvoiceAddress ? "invoiceAddress" : "deliveryAddress";
    },
    refString() {
      const str = this.isInvoiceAddress ? "invoiceAddress" : "companyAddress";
      return `${str}-${this.index}`;
    },
    selectorValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (!this.modelValue) {
          this.emitModelValue(value);
          return;
        }

        this.tempValue = value;
        if (this.showSaveButtons) return;
        if (!this.noDisplay) this.$refs.address.toggle();
        this.emitModelValue(value);
      },
    },
    optionsList() {
      let list = [];
      const defaultValuesList = this.isInvoiceAddress
        ? this.requestedForValues[this.requestedForId]?.defaultValues
            ?.invoiceAddresses
        : this.requestedForValues[this.requestedForId]?.defaultValues
            ?.deliveryAddresses;

      if (defaultValuesList?.length) {
        list = this.addressList.filter((address) =>
          defaultValuesList.includes(address.id)
        );
      } else {
        list = this.addDefault
          ? this.addressList.map((item) => {
              return { ...item, isDefault: false };
            })
          : this.addressList;
      }

      return deepClone(list);
    },
    isAddress() {
      return this.addressList?.length;
    },

    addressDetails() {
      return this.displayObjects
        .map((address) => {
          const id = isObject(address) ? address[this.filterKey] : address;
          return this.getDetails(id);
        })
        .filter((list) => list);
    },
    errorMessage() {
      if (this.errorText) return this.errorText;
      return this.$t(
        "catalogue.myCart.purchaseRequestInfoModal.deliveryAddressError"
      );
    },
    labelText() {
      if (this.label) return this.label;
      if (this.isInvoiceAddress) {
        return this.$tc("global.invoiceAddress", this.list?.length);
      }
      return this.$tc("global.deliveryAddress", this.list?.length);
    },
  },
  watch: {
    requestedForId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          this.requestedForValues[newVal] &&
          ((oldVal && newVal !== oldVal) || !this.selectorValue)
        ) {
          this.setAddress(this.requestedForValues[newVal].defaultValues);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchAddress: FETCH_COMPANY_ADDRESS,
    }),
    getDetails(addressIdValue) {
      const address = this.getCompanyAddressObject(addressIdValue);
      if (addressIdValue && !address) this.fetchAddress(addressIdValue);
      return address;
    },
    emitModelValue(value) {
      this.$emit("update:modelValue", value);
      this.$emit("change", value);
    },
    onConfirm() {
      if (this.showSaveButtons) this.$refs.address.toggle();
      if (!this.tempValue) {
        this.showNotification("value not changed", "warning");
        return;
      }
      this.emitModelValue(this.tempValue);
    },
    onCancel() {
      this.$refs[this.refString].select(this.selectorValue);

      if (this.showSaveButtons) this.$refs.address.toggle();
    },

    setAddress(data) {
      const key = this.isInvoiceAddress ? "invoiceAddress" : "deliveryAddress";
      this.selectorValue = this.objectType
        ? this.getCompanyAddressObject(data[key])
        : data[key];
    },
    onMarkAsDefault(option) {
      this.selectorValue.forEach((item) => {
        if (item.id === option.id) {
          item.isDefault = !item.isDefault;
          const message = item.isDefault
            ? `${item.name} ${this.$t("home.markAsDefault")}`
            : `${item.name} ${this.$t("home.markAsUnDefault")}`;

          this.showNotification(message);
        } else {
          item.isDefault = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.section-title {
  @apply text-base mb-1 px-2;
}

.custom-card {
  @apply p-0;
}

.section {
  @apply flex-1 last:border-r-0;
}

.multiselect-tag.tag-section i:before {
  @apply text-white rounded-full;
}
.confirm_icon {
  @apply h-5 w-5 cursor-pointer text-hivebuy-green hover:ring hover:ring-hivebuy-green rounded-full;
}

.cancel_icon {
  @apply h-5 w-5 cursor-pointer text-hivebuy-red hover:ring hover:ring-hivebuy-red rounded-full;
}
</style>
