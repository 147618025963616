<template>
  <div
    v-if="paginationParams.page < paginationParams.totalPages"
    id="load-more-section"
  >
    <LoadMoreSection
      v-if="paginationParams.page < paginationParams.totalPages"
      :load-more-btn-loading="isApiLoading('invoiceList')"
      :load-more="loadMore"
    />
  </div>
</template>
<script>
import { INVOICE_MODULE } from "@/store/Invoice/types";
import LoadMoreSection from "@/components/shared/LoadMoreSection.vue";
import { mapState } from "vuex";
import loadMorePaginationMixin from "@/components/mixins/loadMorePaginationMixin.js";
import invoiceFilterMixin from "@/components/mixins/invoiceFilterMixin.js";
export default {
  components: {
    LoadMoreSection,
  },
  mixins: [loadMorePaginationMixin, invoiceFilterMixin],
  computed: {
    ...mapState(INVOICE_MODULE, {
      paginationParams: (state) => state.invoiceListFilters.paginationParams,
    }),
  },
};
</script>
