<template>
  <Role
    v-for="(role, index) in roles"
    :key="index"
    :class-name="roleStyle(role)"
    :role-name="roleName(role)"
    :role="role"
  />
</template>
<script>
import { USER_ROLES_MAPPING, USER_BADGE_STYLE } from "@/utils/constants";
import Role from "@/components/CompanyManagement/Users/Role";
export default {
  components: { Role },
  props: {
    roles: {
      type: Array,
      required: true,
    },
  },
  methods: {
    roleName(role) {
      return USER_ROLES_MAPPING[role];
    },

    roleStyle(role) {
      return USER_BADGE_STYLE[role];
    },
  },
};
</script>
