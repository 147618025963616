<template>
  <div
    v-if="isApiLoading('exchangeRates')"
    class="rounded border border-gray-200 bg-white"
  >
    <HivebuyLoader class="h-36 w-36" />
  </div>
  <div v-else class="rounded border border-gray-200 bg-white">
    <h3 class="sr-only">{{ $t("global.pricing.price") }}</h3>
    <dl class="space-y-4 border-gray-200 px-4 py-6 sm:px-6">
      <div
        class="flex items-center justify-between border-b border-gray-200 pb-4"
      >
        <dt class="text-lg font-medium">{{ $t("global.pricing.total") }}</dt>
        <dd class="text-lg font-medium text-gray-900">
          <i18n-n
            :value="parseFloat(totalGross)"
            format="currency"
            :locale="currencyLocale()"
          />
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-base">{{ $t("global.pricing.tax") }}</dt>
        <dd class="text-base font-medium text-gray-900">
          <i18n-n
            :value="parseFloat(totalTax)"
            format="currency"
            :locale="currencyLocale()"
          />
        </dd>
      </div>
      <div class="flex items-center justify-between">
        <dt class="text-base">{{ $t("global.pricing.net") }}</dt>
        <dd class="text-base font-medium text-gray-900">
          <i18n-n
            :value="parseFloat(totalNet)"
            format="currency"
            :locale="currencyLocale()"
          />
        </dd>
      </div>
    </dl>
    <div
      class="border-t border-gray-200 px-4 pb-6 pt-4 sm:px-6 max-h-[200px] overflow-y-scroll product-container"
    >
      <table class="price-table">
        <thead>
          <tr class="font-light text-gray-500">
            <td>{{ $t("global.name") }}</td>
            <td>{{ $t("global.pricing.quantity") }}</td>
            <td>{{ $t("global.pricing.net") }}</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item"
            @click="scrollToElement(index)"
          >
            <td class="flex items-center">
              <TruncateText :tooltip="false" :text="item.name" />
              <Tip
                v-if="showCurrencyHelp(item)"
                help
                theme="tooltip"
                placement="right"
              >
                <template #header>{{ $t("global.pricing.currency") }}</template>
                <template #content>
                  <div class="tooltip-subtext">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <InformationCircleIcon
                          class="h-5 w-5 primary"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <i18n-t
                          keypath="purchaseRequest.summary.totalPrice.difCurrencyItem"
                          tag="p"
                        >
                          <template #currency>
                            <span class="font-bold text-primary">
                              {{ itemCurrency(item) }}
                            </span>
                          </template>
                          <template #rate>
                            <span class="font-bold text-primary">
                              {{ exchangeRates[itemCurrency(item)] }}
                            </span>
                          </template>
                        </i18n-t>
                      </div>
                    </div>
                  </div>
                </template>
              </Tip>
            </td>
            <td>
              <i18n-n
                :value="parseFloat(item.quantity)"
                format="decimal"
                :locale="'de-DE'"
              />
            </td>
            <td>
              <i18n-n
                v-if="showCurrencyHelp(item)"
                :value="itemAmount(item)"
                format="currency"
                :locale="currencyLocale(company.currency)"
              />
              <i18n-n
                v-else
                :value="parseFloat(item.netAmount * item.quantity || 0)"
                format="currency"
                :locale="
                  currencyLocale(item.currency || item.netAmountCurrency)
                "
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { HivebuyLoader } from "@/components/shared";
import { mapActions, mapState, mapMutations } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  SET_EXCHANGE_RATE,
} from "@/store/CompanyManagement/types";
import {
  PURCHASE_REQUEST_MODULE,
  GET_EXCHANGE_RATE,
} from "@/store/PurchaseRequest/types";
import { isObjectEmpty, currencyToLocale } from "@/utils/utility_methods.js";
import { InformationCircleIcon } from "@heroicons/vue/outline";
export default {
  name: "TotalPrice",
  components: {
    HivebuyLoader,
    InformationCircleIcon,
  },

  data() {
    return {
      currency: "EUR",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company", "exchangeRates"]),

    ...mapState(PURCHASE_REQUEST_MODULE, {
      items: (state) => state.newRequest.items,
    }),
    totalNet() {
      return this.amountTotal();
    },
    totalGross() {
      return this.amountTotal("grossAmount");
    },
    totalTax() {
      return this.amountTotal("tax");
    },
    multipleCurrencies() {
      return new Set(this.items.map((item) => item.netAmountCurrency)).size > 1;
    },
  },

  watch: {
    items: {
      handler(val) {
        if (!val.length) return;

        const currencies = [
          ...new Set(val.map((item) => this.itemCurrency(item))),
        ];
        if (isObjectEmpty(this.exchangeRates)) this.getRates();
        if (currencies.length === 1) {
          this.currency = currencies[0];
        } else this.currency = this.company.currency;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getExchangeRate: GET_EXCHANGE_RATE,
    }),
    ...mapMutations(COMPANY_MANAGEMENT_MODULE, {
      setExchangeRates: SET_EXCHANGE_RATE,
    }),
    itemCurrency(item) {
      return item.currency || item.netAmountCurrency;
    },
    showCurrencyHelp(item) {
      return (
        this.multipleCurrencies &&
        item.netAmountCurrency != this.company.currency
      );
    },
    currencyLocale(currency = null) {
      const expectedCurrency =
        currency || this.currency || this.company.currency;
      return currencyToLocale(expectedCurrency);
    },
    itemAmount(item, attr = "netAmount") {
      return (item[attr] * item.quantity) / this.currencyFactor(item);
    },
    amountTotal(attr = "netAmount") {
      const amount = this.items
        .filter((item) => item.quantity && item[attr])
        .reduce((acc, item) => acc + this.itemAmount(item, attr), 0)
        .toFixed(2);
      return amount || 0;
    },
    currencyFactor(item) {
      const currency = item.currency || item.netAmountCurrency;
      if (currency === this.currency) return 1;
      return parseFloat(this.exchangeRates[currency] || 1);
    },
    getRates() {
      if (this.isApiLoading("exchangeRates")) return;

      this.setApiLoading({ key: "exchangeRates", value: true });
      this.getExchangeRate()
        .then((response) => {
          this.setExchangeRates(response);
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.setApiLoading({ key: "exchangeRates", value: false });
        });
    },
    scrollToElement(position) {
      const element = document.getElementById(position);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },

    scrollIntoViewWithOffset(position) {
      const offset = 45;
      const element = document.getElementById(position);
      const top =
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
      window.scrollTo({
        behavior: "smooth",
        top: top,
      });
    },
  },
};
</script>

<style scoped>
.single-item-name {
  @apply min-w-0 pr-4;
}

.price-table {
  table-layout: fixed;
  @apply w-full;
}

.price-table tr {
  @apply text-sm;
}

.price-table thead tr td {
  @apply pb-2;
}
.price-table tbody tr:hover {
  @apply text-primary cursor-pointer ring-1 ring-gray-200 bg-gray-50 rounded ring-offset-2;
}

.price-table td {
}

.price-table tbody td:first-child {
  @apply min-w-0 font-medium;
}

.price-table td:nth-child(2) {
  width: 20%;
  @apply text-center px-2;
}

.price-table td:last-child {
  width: 35%;
  @apply text-right;
}

.product-container {
  scrollbar-gutter: stable;
}

.product-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.product-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  border: 1px solid transparent;
}
</style>
