<template>
  <span
    :class="[
      'ml-1 inline-flex md:group-hover:opacity-100 col-span-1 group-hover:text-primary align-middle content-center',
      { 'md:opacity-0': !isGlobalPolicy },
    ]"
  >
    <PencilAltIcon
      v-if="allowActions"
      class="w-5 h-5 text-gray-300 mr-2 cursor-pointer hover:text-primary"
      @click="() => onEdit(type, category)"
    />
    <TrashIcon
      v-if="allowActions && !isGlobalPolicy"
      class="w-5 h-5 text-gray-300 cursor-pointer hover:text-hivebuy-red"
      @click="() => onDelete(type, category)"
    />
  </span>
</template>
<script>
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";

export default {
  name: "EditorActions",
  components: { PencilAltIcon, TrashIcon },
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
    category: {
      type: [String, Number],
      required: true,
    },
    isGlobalPolicy: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
