<template>
  <div class="form-header mb-4">
    <h3 class="page-title">
      {{ $t("purchaseRequest.detail.header") }}
    </h3>
    <p class="text-sm text-gray-500 -mt-3">
      {{ $t("purchaseRequest.detail.headerSubtext") }}
    </p>
    <CategorySupplierSection
      :supplier="product.supplier?.details"
      :category="product.category || currentRequest.category"
      :save-details-attr="saveDetailsAttr"
      :show-edit="allowEdit"
    />
  </div>
</template>
<script>
import CategorySupplierSection from "@/components/shared/CategorySupplierSection.vue";
import { mapState } from "vuex";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { requestTypeMixin } from "@/components/mixins";
export default {
  components: {
    CategorySupplierSection,
  },
  mixins: [requestTypeMixin],
  props: {
    saveDetailsAttr: { type: Function, default: () => {} },
    product: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    allowEdit() {
      return (
        this.isFeatureAllowed("itemLevelApproval") ||
        this.canUpdateCategoryAndSupplier
      );
    },
    isFirstItem() {
      return this.product.position === 1;
    },
    canUpdateCategoryAndSupplier() {
      if (this.isFirstItem) {
        return ( !this.isSupplierRequest(this.currentRequest) && !this.currentRequest?.approvals?.length );
      }
      return false;
    },
  },
};
</script>
>
