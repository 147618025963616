<template>
  <div class="mb-2 md:mb-4">
    <Input
      id="search"
      :value="search"
      name="search"
      :placeholder="$t('general.search')"
      show-icon
      type="search"
      :on-key-up="onAttributeChange"
      :classes="'border-0 focus:border-0 focus:ring-0'"
    />
  </div>
  <div v-if="!loading" v-auto-animate class="grid grid-cols-12 gap-4">
    <div
      v-for="department in filteredDepartments"
      :key="department"
      class="col-span-12 md:col-span-6 2xl:col-span-4"
    >
      <DepartmentBudget :department="department" :with-details="true" />
    </div>
  </div>
  <div v-else class="flex">
    <div class="custom-card flex justify-center items-center h-52">
      {{ $t("general.prepData") }}<SpinningLoaderSVG class="ml-4" />
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import SpinningLoaderSVG from "@/assets/images/SpinningLoaderSVG.vue";
import DepartmentBudget from "@/components/Analytics/Departments/DepartmentBudget.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  DASHBOARD_MODULE,
  GET_DEPARTMENT_BUDGET_ANALYTICS,
} from "@/store/Dashboard/types";
import { mapActions, mapState, mapGetters } from "vuex";
import { Input } from "@/components/shared/index";
import { formatDateInHyphenFormat } from "@/utils/utility_methods";
export default {
  components: {
    DepartmentBudget,
    SpinningLoaderSVG,
    Input,
  },
  data() {
    return {
      loading: false,
      search: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapState(DASHBOARD_MODULE, ["analytics"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList"]),
    filteredDepartments() {
      return this.analytics.budgets.departments.filter((dep) => {
        return dep.departmentName
          .toLowerCase()
          .includes(this.search?.toLowerCase());
      });
    },
  },
  mounted() {
    let loadData = false;
    if (
      this.analytics.budgets.departments &&
      this.analytics.budgets.departments.length < 1
    ) {
      loadData = true;
    }
    if (this.departmentsList()?.length && this.analytics.budgets.departments) {
      if (
        this.analytics.budgets.departments.length <
        this.departmentsList().length
      ) {
        loadData = true;
      }
    }
    if (loadData) {
      this.loading = true;
      const params = {
        validFrom: formatDateInHyphenFormat(this.company.budgetValidFrom),
        validTo: formatDateInHyphenFormat(this.company.budgetValidTo),
      };
      this.getDepartmentsBudgetAnalytics(params)
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    }
  },

  methods: {
    ...mapActions(DASHBOARD_MODULE, {
      getDepartmentsBudgetAnalytics: GET_DEPARTMENT_BUDGET_ANALYTICS,
    }),
    onAttributeChange(event) {
      const { value } = event.target;
      this.search = value;
    },
  },
};
</script>

<style></style>
