<template>
  <i18n-n :value="parseFloat(value)" format="currency" :locale="locale" />
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    locale: { type: String, required: true },
  },
};
</script>
