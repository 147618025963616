<template>
  <Modal :show-modal="showModal" :close-modal="closeModal" :show-icon="false">
    <template #header>
      {{
        $t("approvalWorkflow.globalPolicies.addApprover.approverModal.title")
      }}
    </template>
    <template #body>
      <ApprovalForm
        :save-value="saveApprover"
        :departments="departments"
        :users="usersList()"
        :workflow="workflow"
        :edit-value="editApprovalValue"
        :close-modal="closeModal"
        :is-global-policy="isGlobalPolicy"
      />
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { ApprovalForm } from "@/components/CompanyManagement/ApprovalWorkflow";
import { Modal } from "@/components/shared/index";

export default {
  name: "ApproversModal",
  components: {
    ApprovalForm,
    Modal,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    saveApprover: {
      type: Function,
      required: true,
    },
    workflow: {
      type: Object,
      required: true,
    },
    editApprovalValue: {
      type: Number,
      default: null,
    },
    isGlobalPolicy: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
  },
};
</script>
