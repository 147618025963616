<template>
  <ul class="custom-card no-shadow text-base">
    <div v-if="readOnly">
      <li class="list-data py-0.5 items-center justify-between">
        <div v-if="purchaseRequest.title" class="flex">
          <div class="list-title">{{ $t("global.title") }}</div>
          <div class="list-value">
            {{ purchaseRequest.title }}
          </div>
        </div>
        <div v-if="purchaseRequest?.requestedAt">
          <div class="flex text-sm font-medium items-center content-center">
            <CalendarIcon class="h-4 w-4 mr-2 text-hivebuy_plum" />
            {{ formattedDate(purchaseRequest?.requestedAt) }} -
            {{ formattedTime(purchaseRequest?.requestedAt) }}
          </div>
        </div>
      </li>
      <li
        v-if="purchaseRequest?.purchaseOrder?.clientExternalId"
        class="list-data"
      >
        <div class="list-title">
          {{ $t("global.clientExternalId") }}
        </div>

        <div class="list-value">
          {{ purchaseRequest?.purchaseOrder?.clientExternalId }}
        </div>
      </li>

      <li class="list-data">
        <div class="list-title">
          {{ $t("purchaseRequest.summary.requestedBy") }}
        </div>

        <div class="list-value">
          <UserWithAvatar :user="requestedBy" :avatar-dimension="7" show-name />
        </div>
      </li>
    </div>
    <div class="sm:flex flex-col sm:flex-row justify-between">
      <li class="list-data !mb-0">
        <div class="list-title">
          {{ $t("purchaseRequest.summary.category") }}
        </div>

        <div class="list-value">
          <Category
            :purchase-request="purchaseRequest"
            :with-tooltip="true"
            :squashed="true"
          />
        </div>
      </li>
      <li class="list-data !mb-0">
        <div class="list-title">
          {{ $t("purchaseRequest.summary.companySupplier") }}
        </div>

        <div class="list-value">
          <div
            v-if="!isApiLoading('productSupplier')"
            class="flex items-center"
          >
            <UnlistedSupplierBadge
              v-if="purchaseRequest.unlistedSupplier"
              :company-supplier="purchaseRequest.companySupplier"
              :unlisted-supplier-reason="purchaseRequest.unlistedSupplierReason"
              :unlisted-supplier="purchaseRequest.unlistedSupplier"
              class="ml-2"
            />
            <SupplierName v-else :purchase-request="purchaseRequest" />
            <EditSupplier
              v-if="isAllowedToEditSupplier"
              :purchase-request="purchaseRequest"
              :is-item-level-approval-allowed="isItemLevelApprovalAllowed"
            />
          </div>
          <HivebuyLoader v-else class="h-6 w-6" />
        </div>
      </li>
    </div>

    <li v-if="purchaseRequest.description" class="description">
      <div class="description-title">
        {{ $t("purchaseRequest.summary.description") }}:
      </div>

      <div class="description-value">
        {{ purchaseRequest.description }}
      </div>
    </li>
  </ul>
</template>
<script>
import {
  UserWithAvatar,
  UnlistedSupplierBadge,
} from "@/components/shared/index";
import { formattedTime } from "@/utils/utility_methods";
import { CalendarIcon } from "@heroicons/vue/outline";
import {
  requestTypeMixin,
  supplierHelperMixin,
} from "@/components/mixins/index.js";
import { Category, SupplierName, HivebuyLoader } from "@/components/shared";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapState } from "vuex";
import EditSupplier from "@/components/PurchaseRequest/SummarySections/EditSupplier.vue";
export default {
  components: {
    UserWithAvatar,
    UnlistedSupplierBadge,
    CalendarIcon,
    Category,
    SupplierName,
    HivebuyLoader,
    EditSupplier,
  },
  mixins: [supplierHelperMixin, requestTypeMixin],
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    purchaseRequest: {
      type: Object,
      required: false,
      default: () => {},
    },
    isItemLevelApprovalAllowed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    requestedBy() {
      const { requestedBy } = this.purchaseRequest;
      return requestedBy?.id || requestedBy;
    },
    isAllowedToEditSupplier() {
      return (
        this.user.roles.includes("BU") &&
        this.purchaseRequest?.purchaseOrder &&
        !this.isItemLevelApprovalAllowed &&
        !this.isAutoProcessingNila
      );
    },
    isAutoProcessingNila() {
      return this.purchaseRequest?.items.some((item) => item.autoProcessing);
    },
  },
  methods: {
    formattedTime,
  },
};
</script>

<style scoped>
.list-data {
  @apply flex items-center mb-3;
}
.list-data .list-title {
  @apply mr-4 font-light text-gray-500;
}
.list-data .list-value {
  @apply text-primary font-semibold;
}

.description {
  @apply border-gray-200 mt-4 pt-4 border-t flex;
}

.description .description-title {
  @apply mr-4 text-gray-500;
}
.description .description-value {
  @apply text-gray-700 flex-1;
}
</style>
