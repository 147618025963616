<template>
  <Modal
    :show-modal="showModal"
    :show-icon="false"
    :close-modal="closing"
    prevent-click-outside
  >
    <template #header>
      <div v-if="loading" class="animate-pulse">{{ $t("global.loading") }}</div>
      <div v-else>
        <span v-if="orderDetails?.purchaseOrder?.clientExternalId">
          {{ orderDetails?.purchaseOrder?.clientExternalId }} /
        </span>
        {{ orderDetails?.purchaseOrder?.externalId }}
        <p class="text-gray-500 text-sm font-light">
          <TimeDisplay
            :time="orderDetails.sentAt"
            :show-time-ago="false"
            :show-date-with-time="true"
          />
        </p>
      </div>
    </template>
    <template #body>
      <div>
        <dl
          class="-my-3 divide-y divide-gray-100 py-4 text-sm leading-6"
          :class="loading ? 'animate-pulse' : ''"
        >
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500 flex-shrink-0">
              {{ $t("global.purchaseOrder") }}
            </dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700">
              <span v-if="orderDetails?.purchaseOrder?.clientExternalId">
                {{ orderDetails?.purchaseOrder?.clientExternalId }} /
              </span>
              {{ orderDetails?.purchaseOrder?.externalId }}
            </dd>
          </div>
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500">{{ $t("global.name") }}</dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700 min-w-0">
              <TruncateText :text="orderDetails?.purchaseOrder?.title" />
            </dd>
          </div>
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500">{{ $t("global.dates.sentAt") }}</dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="flex items-start gap-x-2">
              <div class="font-medium text-gray-700">
                <TimeDisplay
                  :time="orderDetails.sentAt"
                  :show-time-ago="false"
                  :show-date-with-time="true"
                />
              </div>
            </dd>
          </div>
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500 shrink-0">
              {{ $t("supplierOrderList.sentTo") }}
            </dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="min-w-0 font-medium text-gray-700">
              <TruncateText :text="orderDetails.sentTo" />
            </dd>
          </div>
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500">{{ $t("global.requestedFor") }}</dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700">
              <RequestedForDisplay
                :product="requestedFor"
                :avatar-dimension="6"
                show-name
              />
            </dd>
          </div>
          <div class="flex justify-between gap-x-8 py-2">
            <dt class="text-gray-500">
              {{ $t("global.pricing.price") }} {{ $t("global.pricing.gross") }}
            </dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700">
              <i18n-n
                :value="totalPrice"
                format="currency"
                :locale="currencyToLocale(currency)"
              />
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-2">
            <dt class="text-gray-500">{{ $t("global.deliveryAddress") }}</dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700">
              <div v-if="requestedFor == 'multiple'">Multiple</div>
              <RequestAddress
                :show-title="false"
                :is-invoice-address="false"
                :address-id="address.delivery"
                :small="false"
              />
            </dd>
          </div>
          <div class="flex justify-between gap-x-4 py-2">
            <dt class="text-gray-500">{{ $t("global.invoiceAddress") }}</dt>
            <dd v-if="loading" class="font-medium text-gray-500 rounded px-2">
              {{ $t("global.loading") }}
            </dd>
            <dd v-else class="font-medium text-gray-700">
              <RequestAddress
                :show-title="false"
                :is-invoice-address="true"
                :address-id="address.invoice"
                :small="false"
              />
            </dd>
          </div>
          <div class="py-2 flex justify-between">
            <div
              class="rounded border border-gray-200 bg-gray-50 px-2 py-0.5 cursor-pointer hover:text-primary hover:border-primary"
              :class="showPayload ? 'text-primary border-primary' : ''"
              @click="togglePayload"
            >
              Payload
            </div>
            <div
              class="rounded border border-gray-200 bg-gray-50 px-2 py-0.5 cursor-pointer hover:text-primary hover:border-primary"
              :class="showResponse ? 'text-primary border-primary' : ''"
              @click="toggleResponse"
            >
              Response
            </div>
          </div>
          <div v-if="showPayload || showResponse" class="xml-section">
            <div
              v-if="loading"
              class="animate-pulse flex h-full items-center justify-center"
            >
              <div>{{ $t("global.loading") }}</div>
            </div>
            <div v-else class="select-all">
              <span v-if="showPayload">{{ orderDetails.payload }}</span>
              <span v-if="showResponse">{{
                checkEmail(orderDetails.sentTo)
                  ? translatedEmailResponse
                  : orderDetails.response
              }}</span>
            </div>
          </div>
        </dl>
      </div>
    </template>
    <template #footer>
      <div class="col-span-full flex justify-center">
        <Button :on-click-handler="closing" :color="'gray'">
          {{ $t("buttons.close") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import { currencyToLocale, checkEmail } from "@/utils/utility_methods";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import {
  GET_SUPPLIER_ORDER,
  PURCHASE_ORDER_MODULE,
} from "@/store/PurchaseOrder/types";
import { Modal, Button } from "@/components/shared/index";
import { TimeDisplay } from "@/components/shared/index";
import { RequestAddress } from "@/components/shared/index";
export default {
  name: "OrderListQuickView",
  components: {
    Modal,
    TimeDisplay,
    Button,
    RequestAddress,
    RequestedForDisplay,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    isEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      orderDetails: {},
      showPayload: false,
      showResponse: false,
    };
  },
  computed: {
    address() {
      const addresses =
        this.orderDetails?.items?.length > 0
          ? this.orderDetails?.items[0]
          : null;
      if (!addresses) return {};
      return {
        delivery: addresses.companyAddress,
        invoice: addresses.invoiceAddress,
      };
    },
    translatedEmailResponse() {
      return this.orderDetails.response == "1" ? "OK" : "ERROR";
    },
    totalPrice() {
      if (!this.orderDetails?.items?.length) return 0;
      return this.orderDetails.items.reduce((sum, item) => {
        return sum + parseFloat(item.grossAmount);
      }, 0);
    },

    currency() {
      return this.orderDetails?.items?.length > 0
        ? this.orderDetails.items[0].grossAmountCurrency
        : "EUR";
    },
    requestedFor() {
      if (!this.orderDetails?.items?.length) return null;
      if (!this.orderDetails.items[0].requestedFor) return null;

      const firstObject = this.orderDetails.items[0];
      const allMatch = this.orderDetails.items.every(
        (item) => item.requestedFor.id === firstObject.requestedFor.id
      );

      return allMatch ? firstObject : "multiple";
    },
  },
  updated() {
    if (this.order && this.showModal) {
      this.fetchDetail();
    }
  },
  methods: {
    checkEmail,
    currencyToLocale,
    ...mapActions(PURCHASE_ORDER_MODULE, {
      getSupplierOrder: GET_SUPPLIER_ORDER,
    }),
    togglePayload() {
      this.showPayload = !this.showPayload;
      this.showResponse = false;
    },
    toggleResponse() {
      this.showResponse = !this.showResponse;
      this.showPayload = false;
    },
    fetchDetail() {
      this.loading = true;
      this.orderDetails = {};
      this.getSupplierOrder(this.order.id)
        .then((result) => {
          this.orderDetails = result;
        })
        .catch(() => {
          // Something
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closing() {
      this.loading = false;
      this.showPayload = false;
      this.showResponse = false;
      this.closeModal();
    },
  },
};
</script>
<style scoped>
.xml-section {
  @apply py-2 px-4 border border-gray-200 rounded bg-gray-50 whitespace-pre-line;
  max-height: 100px;
  min-height: 100px;
  overflow-y: scroll;
}
</style>
