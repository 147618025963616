import { mappedPrData } from "@/utils/helpers/modelHelpers/purchaseRequest/requestDataMapper";

export const assignSupplierDetails = (item) => {
  const updatedItem = Object.assign({}, item);
  if (item.companySupplier || item.unlistedSupplier) return updatedItem;

  const supplier = updatedItem.supplier;
  if (supplier.type === "companySupplier") {
    updatedItem.companySupplier = supplier.details;
  } else if (supplier.type === "unlisted") {
    const { name, reason } = supplier.details;
    updatedItem.unlistedSupplier = name;
    updatedItem.unlistedSupplierReason = reason;
  } else if (supplier.type === "skip") {
    updatedItem.companySupplier = null;
  }

  return updatedItem;
};

const extraKeys = [
  "currentPage",
  "editItemPosition",
  "itemLevelApproval",
  "supplier",
  "category",
  "companySupplier",
  "status",
];

export const purchaseRequestPayload = (details, edit = false) => {
  const payload = Object.assign({}, details);
  extraKeys.forEach((key) => delete payload[key]);
  return mappedPrData(payload, edit);
};
