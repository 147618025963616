<template>
  <Modal :show-modal="showModal" :close-modal="onModalClose">
    <template #icon>
      <UserGroupIcon class="h-6 w-6 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="modal-heading">Edit Department</h3>
    </template>
    <template #body>
      <div>
        <Input
          id="department_name"
          :error-text="error(v$.department.name, 'department name')"
          name="name"
          :value="department.name"
          type="text"
          :placeholder="
            $t(
              'companyManagement.departments.addDepartmentModal.namePlaceholder'
            )
          "
          label="Name of department"
          :on-value-change="onAttributeChange"
        />
      </div>

      <div class="mt-6">
        <Input
          id="cost_center"
          name="costCenter"
          :value="department.costCenter"
          type="text"
          :placeholder="
            $t(
              'companyManagement.departments.addDepartmentModal.costCenterPlaceholder'
            )
          "
          :label="
            $t('companyManagement.departments.addDepartmentModal.costCenter')
          "
          :on-value-change="onAttributeChange"
        />
      </div>
      <div v-if="isFeatureAllowed('legalEntity')" class="mt-10">
        <p class="block text-sm font-medium text-gray-700 my-1">
          {{ $t("companyManagement.users.addUserModal.legalTendency") }}
        </p>
        <div>
          <Multiselect
            v-model="department.legalEntity"
            :searchable="true"
            :options="legalTendencyList"
            :placeholder="
              $t('companyManagement.users.addUserModal.legalTendency')
            "
            :value-prop="'id'"
            :label="'name'"
            :track-by="'name'"
            :can-clear="true"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">Cancel</Button>
      <Button :on-click-handler="onUpdate"> Save </Button>
    </template>
  </Modal>
</template>

<script>
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { deepClone, isObjectEmpty } from "@/utils/utility_methods";

import { mapActions, mapState, mapGetters } from "vuex";
import {
  COMPANY_MANAGEMENT_MODULE,
  UPDATE_DEPARTMENT,
} from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import { errorMixin } from "@/components/mixins/index.js";
import { Button, Modal, Input } from "@/components/shared/index";
import { UserGroupIcon } from "@heroicons/vue/solid";
import Multiselect from "@vueform/multiselect";

export default {
  name: "EditDepartmentModal",
  components: {
    Modal,
    UserGroupIcon,
    Button,
    Input,
    Multiselect,
  },
  mixins: [errorMixin],
  props: {
    departmentToEdit: {
      type: Object,
      default: () => {},
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      department: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["legalTendencyList"]),
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
  },
  watch: {
    departmentToEdit: {
      handler(newVal, oldVal) {
        if (!isObjectEmpty(newVal) && newVal != oldVal) {
          this.department = deepClone(newVal);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      updateDepartment: UPDATE_DEPARTMENT,
    }),
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.department[name] = value;
    },
    onUpdate() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const { id, name, costCenter, legalEntity } = this.department;

        const payload = {
          departmentId: id,
          payload: this.isFeatureAllowed("legalEntity")
            ? { name, costCenter, legalEntity }
            : { name, costCenter },
        };

        this.updateDepartment(payload)
          .then(() => this.showNotification(this.$t("toasts.genericSuccess")))
          .catch((error) => this.showErrorMessage(error));
        this.onModalClose();
      }
    },
    onModalClose() {
      this.closeModal();
    },
  },
  validations() {
    return {
      department: {
        name: {
          required,
        },
      },
    };
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style></style>
