<template>
  <section class="invoice-decision">
    <section v-if="!loading && invoice" class="page-header">
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <h3
            class="page-title !mb-0 flex hover:text-hivebuy_plum cursor-pointer"
            @click="
              () => {
                $router.back();
              }
            "
          >
            <ChevronLeftIcon v-if="historyExist" class="h-5 w-5 mt-1" />{{
              title
            }}
          </h3>
          <span
            v-if="!isObjectEmpty(purchaseRequest)"
            v-tippy="{
              content: 'Go to order',
              delay: [300, 100],
              theme: 'time',
            }"
            class="ml-2 text-sm group flex items-center cursor-pointer hover:text-hivebuy_plum"
            @click="viewPO"
          >
            {{ company?.prefix }}-{{
              purchaseRequest ? purchaseRequest.externalId : ""
            }}
          </span>
        </div>
      </div>
    </section>
    <div v-if="loading && isObjectEmpty(invoice)" class="loader">
      <HivebuyLoader class="h-36 w-36" />
    </div>
    <div v-else>
      <div class="custom-card w-full no-shadow">
        <ComparisonTable
          :invoice="invoice"
          :invoice-purchase-order="invoicePurchaseOrder"
          :purchase-order="purchaseOrder"
          :purchase-request="purchaseRequest"
          :update-status="updateStatus"
        />
      </div>
      <div
        v-if="!isObjectEmpty(purchaseOrder)"
        class="custom-card po-info no-shadow mt-4 mb-4"
      >
        <dl class="wrapper">
          <div class="sm:col-span-1">
            <dt class="label">{{ $t("global.requestedBy") }}</dt>
            <dd class="content">
              <UserWithAvatar
                :user="purchaseRequest?.requestedBy"
                :avatar-dimension="8"
                show-name
              />
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="label">{{ $t("global.requestedFor") }}</dt>
            <dd class="content">
              <RequestedForDisplay
                v-if="
                  purchaseRequest?.items?.length &&
                  purchaseRequest.items[0].requestedFor
                "
                :product="purchaseRequest.items[0]"
                :read-only="true"
                :avatar-dimension="8"
                :text-classes="['text-sm font-semibold text-gray-700']"
                :icon-classes="['text-yellow-500 w-6 h-6']"
                show-name
              />
            </dd>
          </div>
          <div v-if="!isObjectEmpty(purchaseRequest)" class="sm:col-span-1">
            <dt class="label">{{ $t("global.category") }}</dt>
            <dd class="content">
              <Category :purchase-request="purchaseRequest" />
            </dd>
          </div>
          <div v-if="!isObjectEmpty(purchaseRequest)" class="sm:col-span-1">
            <dt class="label">{{ $t("global.supplier") }}</dt>
            <dd class="content">
              <UnlistedSupplierBadge
                v-if="purchaseRequest?.unlistedSupplier"
                :company-supplier="purchaseRequest?.companySupplier"
                :unlisted-supplier-reason="
                  purchaseRequest?.unlistedSupplierReason
                "
                :unlisted-supplier="purchaseRequest.unlistedSupplier"
                class="ml-2"
              />
              <SupplierName v-else :purchase-request="purchaseRequest" />
            </dd>
          </div>
        </dl>
        <div class="text-sm text-gray-600 mt-4">
          <div>
            <div class="grid grid-cols-12 gap-x-8">
              <h2 class="sr-only">Request Summary</h2>
              <div class="content-box">
                <table class="divide-y divide-gray-200 text-sm w-full">
                  <thead>
                    <tr>
                      <th class="text-left text-gray-600 px-4 py-2">Name</th>
                      <th class="text-left text-gray-600 px-4 py-2">
                        Net Price
                      </th>
                      <th class="text-left text-gray-600 px-4 py-2">
                        Gross Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in purchaseRequest?.items"
                      :key="item.id"
                      :class="[
                        'py-4',
                        {
                          'border-b':
                            index != purchaseRequest?.items.length - 1,
                        },
                      ]"
                    >
                      <td class="text-gray-600 px-4 py-2">{{ item.name }}</td>
                      <td class="font-medium text-gray-900 px-4 py-2">
                        <i18n-n
                          v-if="item.netAmount"
                          :value="parseFloat(item.netAmount)"
                          format="currency"
                          class="currency"
                          :locale="currencyToLocale(item.netAmountCurrency)"
                        />
                      </td>
                      <td class="font-medium text-gray-900 px-4 py-2">
                        <i18n-n
                          v-if="item.grossAmount"
                          :value="parseFloat(item.grossAmount)"
                          format="currency"
                          class="currency"
                          :locale="currencyToLocale(item.grossAmountCurrency)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="
                  !isObjectEmpty(purchaseRequest) &&
                  !purchaseRequest?.isItemLevelApprover
                "
                class="sm:rounded-lg lg:gap-x-8 col-span-12 lg:col-span-6"
              >
                <Timeline :object="purchaseRequest" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  currencyToLocale,
  scrollToError,
  isObjectEmpty,
} from "@/utils/utility_methods.js";
import { Timeline } from "@/components/PurchaseRequest";
import {
  supplierHelperMixin,
  TimelineMixin,
} from "@/components/mixins/index.js";
import RequestedForDisplay from "@/components/PurchaseRequest/SummarySections/requestedForDisplay.vue";
import {
  UnlistedSupplierBadge,
  UserWithAvatar,
  HivebuyLoader,
  Category,
  SupplierName,
} from "@/components/shared/index";
import { INVOICE_MODULE, GET_INVOICE } from "@/store/Invoice/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapActions, mapState } from "vuex";
import { ComparisonTable } from "@/components/InvoiceApproval/index.js";
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import invoiceDecisionMixin from "@/components/mixins/invoiceDecisionMixin.js";
export default {
  components: {
    ChevronLeftIcon,
    UserWithAvatar,
    ComparisonTable,
    HivebuyLoader,
    UnlistedSupplierBadge,
    RequestedForDisplay,
    Timeline,
    Category,
    SupplierName,
  },
  mixins: [supplierHelperMixin, TimelineMixin, invoiceDecisionMixin],
  data() {
    return {
      loading: true,
      updatedTimeline: null,
      showPoDetails: false,
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    historyExist() {
      return window.history.state.back !== null;
    },

    title() {
      return this.invoice ? this.invoice.title : "N/A";
    },
  },
  async mounted() {
    await this.getInvoiceDetails();
  },

  methods: {
    scrollToError,
    currencyToLocale,
    isObjectEmpty,
    ...mapActions(INVOICE_MODULE, {
      getInvoice: GET_INVOICE,
    }),
    async getInvoiceDetails() {
      try {
        await this.getInvoice(this.invoiceId);
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },

    updateStatus(newTimeline) {
      const poId = this.$route.query.poId;
      if (!this.invoice || !this.invoice.invoicePurchaseOrders || !poId) return;

      const index = this.invoice.invoicePurchaseOrders.findIndex(
        (po) => po.purchaseOrder.id == poId
      );
      this.invoice.invoicePurchaseOrders[index] =
        newTimeline.invoicePurchaseOrder;
      this.invoice.details = newTimeline.invoicePurchaseOrder;
    },
    getNewApprovalTimeline() {
      if (this.updatedTimeline) {
        return this.updatedTimeline;
      } else {
        return this.purchaseOrder.approvals;
      }
    },

    viewPO() {
      this.$router.push({
        name: "RequestDetail",
        params: { id: this.purchaseRequest?.id },
      });
    },
  },
};
</script>

<style scoped>
.invoice-decision {
  @apply flex flex-col h-full;
}

.wrapper {
  @apply grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-6;
}

.label {
  @apply text-gray-400;
}

.content {
  @apply text-sm text-gray-700 font-medium;
}

.content-box {
  @apply border border-gray-200 bg-gray-50 py-3 px-3 sm:rounded-lg col-span-12 lg:col-span-6;
}

.loader {
  @apply flex-1 w-full  flex items-center justify-center;
}

.custom-card {
  @apply flex-1 !overflow-auto;
}
</style>
