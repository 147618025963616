<template>
  <div v-if="loading" class="animate-pulse">
    <div class="relative overflow-hidden pt-2 pb-2 px-4 sm:px-6 sm:pt-6">
      <dt class="col-span-5 bg-gray-100 rounded-md h-4 mb-2" />
      <dd class="col-span-5 bg-gray-100 rounded-md h-4 mb-2" />
      <dd class="col-span-5 bg-gray-100 rounded-md h-4 mb-2" />
    </div>
  </div>
  <div
    v-else
    class="relative overflow-hidden py-4 px-4 sm:px-6 sm:py-6 rounded border border-gray-200"
  >
    <dt>
      <div class="absolute rounded-md bg-primary p-3">
        <component :is="icon" class="h-7 w-7 text-white" aria-hidden="true" />
      </div>
      <p class="ml-16 truncate text-base font-medium text-gray-500">
        {{ chartName }}
      </p>
    </dt>
    <dd class="ml-16 flex items-baseline">
      <p class="text-xl font-semibold text-gray-900">{{ topStatsName }}</p>
    </dd>
    <dd class="ml-16 flex items-center justify-between text-primary">
      <div class="flex">
        <span
          >{{ statistic.count }}
          {{ $tc("global.purchaseRequest", statistic.count) }} //
          {{ totalAmount }}</span
        >
      </div>
    </dd>
  </div>
</template>

<script>
import { currencyLocaleMixin } from "@/components/mixins";
import { GlobeAltIcon, TagIcon } from "@heroicons/vue/outline";

export default {
  name: "TopChart",

  mixins: [currencyLocaleMixin],

  props: {
    loading: { type: Boolean, required: true },
    chartName: { type: String, required: true },
    statistic: {
      type: Object,
      required: true,
    },
    isSupplier: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalAmount() {
      return this.valueInCurrency(this.statistic.totalNetAmount);
    },
    topStatsName() {
      if (this.isSupplier) {
        return this.statistic?.name ? this.statistic.name : "No Supplier";
      } else {
        return this.statistic ? this.statistic.name : "";
      }
    },
    icon() {
      return this.isSupplier ? GlobeAltIcon : TagIcon;
    },
  },
};
</script>
