<template>
  <RequestAddress
    v-model="companyAddress"
    :is-edit="isEditAllowed"
    :requested-for-id="modelValue.requestedForId"
    :is-invalid="!!errorMessage('companyAddress').length"
    :error-text="errorMessage('companyAddress')"
    :can-clear="false"
    :can-deselect="false"
  />
  <RequestAddress
    v-model="invoiceAddress"
    :is-edit="isEditAllowed"
    :label="$t('purchaseRequest.detail.invoiceAddress')"
    is-invoice-address
    :requested-for-id="modelValue.requestedForId"
    :is-invalid="!!errorMessage('invoiceAddress').length"
    :error-text="errorMessage('invoiceAddress')"
    :can-clear="false"
    :can-deselect="false"
  />
</template>
<script>
import RequestAddress from "@/components/shared/RequestAddress.vue";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
import { newRequestMixin } from "@/components/mixins/index.js";

export default {
  components: {
    RequestAddress,
  },
  mixins: [newRequestErrorMixin, newRequestMixin],
  inject: ["index"],
  props: {
    defaultProduct: { type: Object, default: null },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    isEditAllowed() {
      if (!this.newRequest.sameRecipient) return true;
      return this.newRequest.sameRecipient && this.index == 0;
    },

    companyAddress: {
      get() {
        return this.modelValue.companyAddress;
      },
      set(value) {
        if (this.newRequest.sameRecipient) {
          this.copyValueToAllItems("companyAddress", value);
          return;
        }
        this.$emit("update:modelValue", {
          ...this.modelValue,
          companyAddress: value,
        });
      },
    },
    invoiceAddress: {
      get() {
        return this.modelValue.invoiceAddress;
      },
      set(value) {
        if (this.newRequest.sameRecipient) {
          this.copyValueToAllItems("invoiceAddress", value);
          return;
        }
        this.$emit("update:modelValue", {
          ...this.modelValue,
          invoiceAddress: value,
        });
      },
    },
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
