<template>
  <Popper :hover="true" :placement="placement">
    <slot />
    <template #content>
      <div
        class="rounded p-2 font-light text-xs text-black z-50 whitespace-pre"
      >
        <p>{{ description }} {{ text }}</p>
        <p>{{ description2 }} {{ text2 }}</p>
      </div>
    </template>
  </Popper>
</template>

<script>
export default {
  props: {
    placement: { type: String, default: "top" },
    text: {
      type: String,
      required: false,
      default: "",
    },
    text2: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    description2: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
