<template>
  <Modal :show-modal="showModal" :show-icon="false" :close-modal="closeModal">
    <template #header>
      <div class="flex flex-col items-start">
        <h3 class="text-lg text-primary font-semibold">
          {{
            isPurchaseOrder || type === "order"
              ? $t("global.purchaseOrder")
              : $t("global.purchaseRequest")
          }}
          - {{ purchaseRequest?.externalId }}
        </h3>
        <p class="text-sm text-gray-700">
          {{ formattedDate(purchaseRequest?.requestedAt) }} -
          {{ formattedTime(purchaseRequest?.requestedAt) }}
        </p>
      </div>
    </template>
    <template #body>
      <div>
        <ul class="border border-gray-200 bg-gray-50 text-gray-700 rounded p-4">
          <li class="list-item">
            <p>ID</p>
            <p>{{ purchaseRequest?.externalId }}</p>
          </li>
          <li class="list-item">
            <p>{{ $t("purchaseRequest.summary.title") }}</p>
            <p>
              {{ purchaseRequest?.title || "N/A" }}
            </p>
          </li>

          <li class="list-item">
            <p>{{ $t("purchaseRequest.summary.category") }}</p>
            <p><Category :purchase-request="purchaseRequest" squashed /></p>
          </li>
          <li class="list-item">
            <p>{{ $t("purchaseRequest.summary.companySupplier") }}</p>
            <p>
              <SupplierName :purchase-request="purchaseRequest" />
            </p>
          </li>
          <li class="list-item">
            <p>Status</p>
            <StatusTag
              :status="requestStatus(purchaseObject)"
              :type="
                purchaseRequest?.purchaseOrder || purchaseOrder
                  ? 'order'
                  : 'request'
              "
              :size="'small'"
            />
          </li>
          <li class="list-item !border-b-0">
            <p>{{ $t("purchaseRequest.summary.requestedBy") }}</p>
            <p>
              <UserWithAvatar
                :user="purchaseRequest?.requestedBy"
                :avatar-dimension="7"
                show-name
                :text-classes="['text-sm font-medium text-primary']"
              />
            </p>
          </li>
          <div class="relative mt-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-start">
              <span class="pr-2 bg-gray-50 text-base">
                {{ $t("purchaseRequest.summary.productsInRequest") }}
              </span>
            </div>
          </div>
          <div
            v-for="item in purchaseItems"
            :key="item.id"
            class="w-full text-sm grid py-2 grid-cols-3"
          >
            <div
              class="truncate col-span-2 text-primary"
              :class="isItemRejected(item) ? 'line-through' : ''"
            >
              {{ item?.name }}
            </div>
            <div
              class="justify-self-end"
              :class="isItemRejected(item) ? 'line-through' : ''"
            >
              <i18n-n
                :value="parseFloat(item?.netAmount)"
                format="currency"
                :locale="currencyToLocale(item?.netAmountCurrency)"
              />
            </div>
          </div>
          <li
            class="flex items-center justify-between text-base border-top border-t border-gray-300 pt-2 font-semibold"
          >
            <p>{{ $t("purchaseRequest.summary.totalPrice.netTotal") }}</p>
            <p>
              <i18n-n
                :value="parseFloat(purchaseObject?.netAmount)"
                format="currency"
                :locale="currencyToLocale(purchaseObject?.netAmountCurrency)"
              />
            </p>
          </li>
          <li
            class="flex items-center justify-between text-sm pt-2 font-normal"
          >
            <p>{{ $t("purchaseRequest.summary.totalPrice.tax") }}</p>
            <p>
              <i18n-n
                :value="parseFloat(purchaseObject?.tax)"
                format="currency"
                :locale="currencyToLocale(purchaseObject?.totalAmountCurrency)"
              />
            </p>
          </li>
          <li
            class="flex items-center justify-between text-base pt-2 font-semibold"
          >
            <p>{{ $t("purchaseRequest.summary.totalPrice.orderTotal") }}</p>
            <p>
              <i18n-n
                :value="parseFloat(purchaseObject?.totalAmount)"
                format="currency"
                :locale="currencyToLocale(purchaseObject?.totalAmountCurrency)"
              />
            </p>
          </li>
        </ul>
      </div>
      <div
        v-if="purchaseOrder?.datevJob"
        class="mt-2 p-2 border border-gray-200 rounded flex space-x-4"
      >
        <div class="self-center">
          <img
            src="https://hivebuy-prod-core-static.s3.eu-central-1.amazonaws.com/DATEV_Datenservice_Signet_ohne_Kachel_RGB.svg"
            class="h-28 w-28"
          />
        </div>
        <div class="self-center">
          <p>
            Status:
            <span
              :class="[datevStatus.color, datevStatus.textColor]"
              class="px-3 py-1 border border-gray-100 rounded"
              >{{ datevStatus.text }}</span
            >
          </p>
          <p v-if="purchaseOrder.datevJob.completedAt" class="mt-2">
            {{ $t("purchaseOrder.datevStatuses.jobCompletedAt")
            }}{{ formattedDate(purchaseOrder.datevJob.completedAt) }} -
            {{ formattedTime(purchaseOrder.datevJob.completedAt) }}
          </p>
          <div
            v-if="purchaseOrder.datevJob.warnings?.length"
            class="mt-2 border-l-4 border-l-hivebuy-red pl-2"
          >
            <p
              v-for="warning in purchaseOrder.datevJob.warnings"
              :key="warning"
            >
              {{ warning }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <Button :on-click-handler="closeModal" :color="'gray'">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        type="button"
        :on-click-handler="() => navigateToDetailPage(purchaseRequest)"
      >
        {{ $t("purchaseRequest.goToDetails") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import {
  supplierHelperMixin,
  requestTypeMixin,
  userMixin
} from "@/components/mixins/index.js";
import {
  Button,
  Modal,
  Category,
  SupplierName
} from "@/components/shared/index";
import {
  formattedTime,
  requestStatus,
  currencyToLocale
} from "@/utils/utility_methods";
import { UserWithAvatar } from "@/components/shared/index";
import StatusTag from "@/components/shared/StatusTag";
import { DATEV_STATUS } from "@/utils/constants.js";

export default {
  components: {
    SupplierName,
    Button,
    Modal,
    UserWithAvatar,
    Category,
    StatusTag
  },
  mixins: [userMixin, supplierHelperMixin, requestTypeMixin],
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    closeModal: {
      type: Function,
      required: true
    },
    purchaseRequest: {
      type: Object,
      default: () => {}
    },
    navigateToDetailPage: {
      type: Function,
      required: true
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false
    },
    purchaseOrder: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "request"
    }
  },
  computed: {
    purchaseObject() {
      let result = Object.assign({}, this.purchaseRequest);

      if (this.type === "order") {
        result = Object.assign({}, this.purchaseOrder);
      } else {
        result = {
          ...result,
          netAmount: result.totalNetAmount,
          netAmountCurrency: result.totalNetAmountCurrency
        };
      }

      return result;
    },
    purchaseItems() {
      return this.type === "order"
        ? this.purchaseObject?.purchaseRequest?.items
        : this.purchaseObject?.items;
    },

    datevStatus() {
      const status = this.purchaseOrder?.datevJob?.status;
      switch (status) {
        case DATEV_STATUS.INITIALIZED:
          return {
            color: "bg-hivebuy-yellow",
            textColor: "text-gray-600",
            text: this.$t("purchaseOrder.datevStatuses.initialized")
          };
        case DATEV_STATUS.JOB_CREATED:
          return {
            color: "bg-hivebuy-yellow",
            textColor: "text-gray-600",
            text: this.$t("purchaseOrder.datevStatuses.initialized")
          };
        case DATEV_STATUS.FILES_UPLOADED:
          return {
            color: "bg-hivebuy-yellow",
            textColor: "text-gray-600",
            text: this.$t("purchaseOrder.datevStatuses.uploaded")
          };
        case DATEV_STATUS.JOB_SUCCESSFUL:
          return {
            color: "bg-hivebuy-green",
            textColor: "text-white",
            text: this.$t("purchaseOrder.datevStatuses.finalized")
          };
        case DATEV_STATUS.JOB_PARTIALLY_SUCCESSFUL:
          return {
            color: "bg-orange-400",
            textColor: "text-white",
            text: this.$t("purchaseOrder.datevStatuses.finalized")
          };
        case DATEV_STATUS.JOB_FAILED:
          return {
            color: "bg-hivebuy-red",
            textColor: "text-white",
            text: this.$t("purchaseOrder.datevStatuses.failed")
          };
        case DATEV_STATUS.UNKNOWN_ERROR:
          return {
            color: "bg-hivebuy-red",
            textColor: "text-white",
            text: this.$t("purchaseOrder.datevStatuses.failed")
          };
        case DATEV_STATUS.INTERNAL_ERROR:
          return {
            color: "bg-hivebuy-red",
            textColor: "text-white",
            text: this.$t("purchaseOrder.datevStatuses.failed")
          };
        default:
          return "Error";
      }
    }
  },
  created() {
    this.DATEV_STATUS = DATEV_STATUS;
  },
  methods: {
    formattedTime,
    currencyToLocale,
    requestStatus,
    isItemRejected(item) {
      return item.status === "R";
    }
  }
};
</script>

<style scoped>
.list-item {
  @apply flex items-center justify-between text-base border-b border-gray-200 py-3 last:border-0;
}

.list-item p:nth-child(2) {
  @apply text-primary;
}
</style>
