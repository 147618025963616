<template>
  <Modal
    :show-modal="showModal"
    :show-icon="false"
    :size-classes="['sm:max-w-3xl']"
    :close-modal="onClose"
  >
    <template #header>
      <h3 class="section-heading">{{ $t("purchaseOrder.manageTable") }}</h3>
      <p class="mb-4 text-sm text-gray-700 font-normal">
        {{ $t("purchaseOrder.manageTableSubtext") }}
      </p>
    </template>
    <template #body>
      <div class="bg-white py-4 px-8">
        <ul class="grid grid-cols-2 gap-2 gap-x-12">
          <li
            v-for="(column, index) in tableColumns"
            :key="column.field"
            class="mb-2"
          >
            <div>
              <SwitchGroup
                as="div"
                :class="`flex items-center justify-between`"
              >
                <SwitchLabel as="span" class="mr-3">
                  <span class="text-sm font-medium text-gray-700">
                    {{ column.header }}
                  </span>
                </SwitchLabel>
                <Switch
                  v-model="tableColumns[index].visible"
                  :class="[
                    tableColumns[index].visible
                      ? 'switch-active'
                      : 'switch-inactive',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                  ]"
                >
                  <span class="sr-only">Show {{ column }} in Table View</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      tableColumns[index].visible
                        ? 'translate-x-5'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  />
                </Switch>
              </SwitchGroup>
            </div>
          </li>
        </ul>
        <SwitchCheckbox
          :value="selectAll"
          :label="$t('purchaseOrder.selectAll')"
          :classes="'sm:ml-auto ml-0 mt-5 cursor'"
          :on-click-handler="onSelectAllClick"
        />
      </div>

      <FieldError v-if="errorText" :text="errorText" />
    </template>
  </Modal>
</template>

<script>
import { Modal, SwitchCheckbox, FieldError } from "@/components/shared/index";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { mapActions } from "vuex";
import { isHashEqual } from "@/utils/utility_methods";
import PreferenceService from "@/utils/services/userPreference";
import { AUTH_MODULE, UPDATE_ME } from "@/store/Auth/types";

export default {
  components: {
    FieldError,
    Modal,
    Switch,
    SwitchGroup,
    SwitchLabel,
    SwitchCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    tableName: { type: String, default: "purchaseOrderAccountingView" },
  },
  data() {
    return {
      tableColumns: [],
      errorText: "",
    };
  },
  computed: {
    selectAll() {
      return !this.tableColumns.every((col) => col.visible);
    },
  },
  watch: {
    tableColumns: {
      deep: true,
      handler(newVal, oldVal) {
        if (isHashEqual(newVal, oldVal)) return;

        const lessThanMin =
          newVal.filter((column) => column.visible).length < 3;
        if (lessThanMin) {
          this.errorText = this.$t("purchaseOrder.table.error");
          this.tableColumns = oldVal;
        } else {
          this.errorText = "";
          this.tableColumns = newVal;
        }
      },
    },
    columns: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.tableColumns = newVal.filter((col) => col.field != "id");
      },
    },
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    onClose() {
      this.toggleModal();

      const payload = PreferenceService.getTablePreferenceUpdatePayload(
        this.tableName,
        this.columns
      );

      this.updateMe({ payload });
    },
    onSelectAllClick() {
      const cols = [...this.columns];

      cols.forEach((col, index) => {
        if (!this.selectAll) {
          if (index > 3) col.visible = this.selectAll;
        } else col.visible = this.selectAll;
      });

      this.tableColumns = cols.filter((col) => col.field != "id");
    },
  },
};
</script>
<style>
.switch-active {
  @apply bg-primary !important;
}

.switch-inactive {
  @apply bg-gray-200 !important;
}
</style>
