<template>
  <section class="page-title">{{ $t("shoppingList.title") }}</section>
  <p class="text-sm text-gray-500 mb-3 md:mb-5">
    {{ $t("shoppingList.subText") }}
  </p>
  <section v-if="!isApiLoading('component')" id="new-shopping-list">
    <section class="page-content">
      <div v-if="currentList.length" class="products-holder">
        <ShoppingListProduct
          v-for="(product, index) in currentList"
          :key="`${product.name}-${index}`"
          ref="product"
          :class="[
            'mt-1',
            { 'product-error': invalidProducts.includes(index) },
          ]"
          :index="index"
          :open="index == currentList.length - 1"
          :manage-validation="manageValidation"
        />
      </div>
      <div
        class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-4 text-center hover:border-gray-400 text-hivebuy-grey hover:text-primary bg-gray-50 hover:bg-white cursor-pointer"
        @click="addNewProduct"
      >
        <PlusCircleIcon class="h-8 w-8 mx-auto" />
        <span class="mt-2 block text-sm font-medium">{{
          $t("shoppingList.buttons.addProduct")
        }}</span>
      </div>
    </section>

    <section class="page-footer">
      <div class="buttons-holder">
        <Button
          class="btn btn-primary-outline"
          :on-click-handler="toggleDeleteConfirm"
          >{{ $t("buttons.cancel") }}</Button
        >
        <Button class="btn" :on-click-handler="initiateSave">{{
          $t("buttons.save")
        }}</Button>
      </div>
    </section>
    <ListInfo
      :save-method="saveList"
      :open="showTitleForm"
      :toggle-function="toggleTitleForm"
      :suggestion="currentList[0]?.name"
    />
    <ConfirmDialog
      :text="$t('shoppingList.cancelConfirm')"
      :show-modal="showConfirmationModal"
      :close-modal="toggleDeleteConfirm"
      :title="$t('shoppingList.modalTitle')"
      item-to-confirm=""
      :confirm-modal="clearShoppingList"
    />
  </section>
  <div v-else class="flex items-center justify-center mt-10">
    <div class="w-36 h-36">
      <HivebuyLoader />
    </div>
  </div>
</template>

<script>
import { PlusCircleIcon } from "@heroicons/vue/outline";
import {
  ShoppingListProduct,
  ListInfo,
} from "@/components/ShoppingList/index.js";
import {
  Button,
  ConfirmDialog,
  HivebuyLoader,
} from "@/components/shared/index.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import {
  SHOPPING_LIST_MODULE,
  SAVE_PRODUCT,
  SAVE_SHOPPING_LIST,
  RESET_SHOPPING_LIST,
} from "@/store/ShoppingList/types.js";

import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  name: "NewShoppingList",
  components: {
    ShoppingListProduct,
    Button,
    ListInfo,
    ConfirmDialog,
    PlusCircleIcon,
    HivebuyLoader,
  },

  data() {
    return {
      showTitleForm: false,
      showConfirmationModal: false,
      invalidProducts: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(SHOPPING_LIST_MODULE, ["currentList"]),
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companyAddresses"]),
    defaultProduct() {
      return {
        data: {
          name: null,
          quantity: 1,
          requestedFor: {
            department: this.user.departments[0]?.id || null,
            id: this.user.id,
            type: "user",
          },
        },
      };
    },
  },
  mounted() {
    if (!this.currentList.length) this.addNewProduct();
  },
  methods: {
    ...mapMutations(SHOPPING_LIST_MODULE, {
      saveProduct: SAVE_PRODUCT,
      resetShoppingList: RESET_SHOPPING_LIST,
    }),
    ...mapActions(SHOPPING_LIST_MODULE, {
      saveShoppingList: SAVE_SHOPPING_LIST,
    }),
    addNewProduct() {
      this.saveProduct(this.defaultProduct);
    },
    initiateSave() {
      Promise.all(this.validateProducts()).then(() => {
        if (this.invalidProducts.length) {
          this.showNotification("Information is not complete", "error");
        } else {
          this.toggleTitleForm();
        }
      });
    },
    payload(title, description) {
      return {
        title,
        description,
        items: this.currentList.map((item) => {
          return {
            ...item,
            invoiceAddress: item.invoiceAddress.id,
            deliveryAddress: item.deliveryAddress.id,
          };
        }),
      };
    },
    toggleDeleteConfirm() {
      this.showConfirmationModal = !this.showConfirmationModal;
    },
    toggleTitleForm() {
      this.showTitleForm = !this.showTitleForm;
    },
    clearShoppingList() {
      this.resetShoppingList();
      this.$router.back();
    },
    manageValidation(index, valid = true) {
      if (!this.invalidProducts.includes(index) && !valid) {
        this.invalidProducts.push(index);
      }
      if (!valid) return;

      const itemIndex = this.invalidProducts.indexOf(index);
      if (itemIndex < 0) return;
      this.invalidProducts.splice(itemIndex, 1);
    },
    validateProducts() {
      const promises = [];
      this.$refs.product.forEach((product) => {
        promises.push(product.validateProduct(this.invalidProducts));
      });
      return promises;
    },
    saveList(title, description) {
      this.loading = true;
      this.saveShoppingList(this.payload(title, description))
        .then(() => {
          this.resetShoppingList();
          this.$router.push({ name: "ShoppingList" });
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
#new-shopping-list {
  @apply flex flex-col;
}
#new-shopping-list .page-content {
  @apply flex-1 flex flex-col items-baseline gap-y-2;
}

#new-shopping-list .page-footer {
  @apply flex mt-4;
}
#new-shopping-list .page-footer .buttons-holder {
  @apply flex justify-between w-full;
}

.products-holder {
  @apply w-full flex flex-col gap-y-2;
}

/* Product Collape */

.add-product {
  @apply items-center mt-2 flex gap-x-0.5 text-primary text-sm font-semibold;
}
.product-error {
  @apply border-2 border-rose-600;
}

/* Tabs */
</style>
