<template>
  <div>
    <div v-if="!selectedCategory">
      <h3 class="text-sm leading-6 font-normal text-gray-600">
        {{ $t("workflow.editor.requiredApprovalsDescription") }}
      </h3>
      <SelectOptions
        :options="requiredApprovalTypes"
        :on-select="(value) => onApprovalSelect(value)"
      />
    </div>
    <div v-if="selectedCategory == 'role'">
      <div class="sm:col-span-3 mt-10">
        <Multiselect
          id="role"
          v-model="value"
          mode="single"
          :options="roles"
          :placeholder="$t('workflow.editor.chooseRole')"
          :value-prop="'value'"
        >
          <template #singlelabel="{ value: role }">
            <div class="multiselect-single-label">
              <Role :role="role.value" :badge="false" /></div
          ></template>
          <template #option="{ option }">
            <Role :role="option.value" :badge="false"
          /></template>
        </Multiselect>
      </div>
    </div>
    <div v-if="isDepartmentOrOriginalRequester">
      <div class="sm:col-span-3">
        <p>
          <span>
            {{ $t(`workflow.editor.helperText.${selectedCategory}`) }}
          </span>
        </p>

        <SwitchCheckbox
          v-if="!onlyShowSummary"
          v-model="approverOfDepartment"
          class="my-8 inline-flex"
          :label="$t(`workflow.editor.switch.${selectedCategory}`)"
          state-icons
        >
        </SwitchCheckbox>
        <div v-if="!onlyShowSummary" class="ml-4 inline items-center">
          {{ $t(`workflow.editor.help`) }}
          <InformationPopover class="ml-2 inline-flex">
            <template #header>
              <div class="text-primary">
                {{ $t(`workflow.editor.switch.${selectedCategory}`) }}
              </div>
            </template>
            <template #body>
              {{ $t(`workflow.editor.switchTooltip.${selectedCategory}`) }}
            </template>
          </InformationPopover>
        </div>
        <DepartmentSelect
          v-if="!approverOfDepartment && !onlyShowSummary"
          id="approverOfDepartment"
          v-model="value"
          class="mt-6"
          :label="'name'"
          :object="true"
          :placeholder="$t('workflow.editor.chooseDepartment')"
        />
      </div>
    </div>
    <div v-if="isBudgetOwnerOfDepartment">
      <div class="sm:col-span-3">
        <p>
          <span>
            {{ $t(`workflow.editor.helperText.${selectedCategory}`) }}
          </span>
        </p>

        <SwitchCheckbox
          v-if="!onlyShowSummary"
          v-model="budgetOwnerDepartmentApprover"
          class="my-8 inline-flex"
          :label="$t(`workflow.editor.switch.${selectedCategory}`)"
          state-icons
        >
        </SwitchCheckbox>
        <div v-if="!onlyShowSummary" class="ml-4 inline items-center">
          {{ $t(`workflow.editor.help`) }}
          <InformationPopover class="ml-2 inline-flex">
            <template #header>
              <div class="text-primary">
                {{ $t(`workflow.editor.switch.${selectedCategory}`) }}
              </div>
            </template>
            <template #body>
              {{ $t(`workflow.editor.switchTooltip.${selectedCategory}`) }}
            </template>
          </InformationPopover>
        </div>
        <DepartmentSelect
          v-if="!budgetOwnerDepartmentApprover && !onlyShowSummary"
          id="budgetOwnerOfDepartment"
          v-model="value"
          class="mt-6"
          :label="'name'"
          :object="true"
          :placeholder="$t('workflow.editor.chooseDepartment')"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'user'">
      <div class="sm:col-span-3 mt-10">
        <MemberSelector
          id="user-selector"
          :value="value"
          :on-value-change="
            (response) => {
              value = response;
            }
          "
          :object="true"
          :members-list="users"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'approverOfLegalEntity'">
      <div class="sm:col-span-3 mt-10">
        <Multiselect
          v-model="value"
          :searchable="true"
          :options="legalTendencies"
          :placeholder="$t('workflow.editor.chooseLegalTendency')"
          :label="'name'"
          :value-prop="'id'"
          :object="true"
          class="mt-6"
        />
      </div>
    </div>
    <div
      v-if="selectedCategory == 'responsibleUser'"
      class="workflowItem text-center"
    >
      {{ $t("workflow.editor.responsibleUser") }}
    </div>
    <EditorButtons
      :reset="
        () => {
          selectedCategory = null;
        }
      "
      :save="onSave"
      :cancel="closeModal"
      :show-reset="selectedCategory != null"
      :show-save="isValidSubmission"
      :is-global-policy="isGlobalPolicy"
    />
  </div>
</template>
<script>
import { isObjectEmpty } from "@/utils/utility_methods";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const workflowJson = require("@/assets/data/workflow.json");
const { requiredApprovalTypes } = workflowJson;
import { workflowMixin } from "@/components/mixins/index.js";
import { USER_ROLES_MAPPING } from "@/utils/constants";
import Role from "@/components/CompanyManagement/Users/Role.vue";
import {
  MemberSelector,
  SwitchCheckbox,
  InformationPopover,
} from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters } from "vuex";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";
export default {
  components: {
    DepartmentSelect,
    MemberSelector,
    SwitchCheckbox,
    InformationPopover,
    Role,
  },
  mixins: [workflowMixin],
  props: {
    isGlobalPolicy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredApprovalTypes: this.filteredApprovalTypes(),
      roles: USER_ROLES_MAPPING,
      selectedCategory: null,
      value: null,
      approverOfDepartment: false,
      budgetOwnerDepartmentApprover: false,
      originalRequester: false,
      requestedFor: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList"]),
    isValidSubmission() {
      return this.value != null && !isObjectEmpty(this.selectedCategory);
    },
    isDepartmentOrOriginalRequester() {
      return [
        "approverOfDepartment",
        "departmentApprover",
        "originalRequester",
        "requestedFor",
      ].includes(this.selectedCategory);
    },
    isOriginalRequester() {
      return ["originalRequester"].includes(this.selectedCategory);
    },
    onlyShowSummary() {
      return ["originalRequester", "requestedFor"].includes(
        this.selectedCategory
      );
    },
    isBudgetOwnerOfDepartment() {
      return ["budgetOwnerOfDepartment"].includes(this.selectedCategory);
    },
  },
  watch: {
    approverOfDepartment: {
      handler(newVal) {
        this.value = newVal ? newVal : null;
      },
    },
    budgetOwnerDepartmentApprover: {
      handler(newVal) {
        this.value = newVal ? newVal : null;
      },
    },
    selectedCategory: {
      handler(newVal) {
        if (newVal == "approverOfDepartment" && this.editValue == null) {
          this.value = this.approverOfDepartment;
        }
        if (
          newVal == "originalRequester" ||
          newVal == "requestedFor" ||
          newVal == "responsibleUser"
        ) {
          this.value = true;
        }
      },
    },
  },
  created() {
    if (this.editValue != null) {
      const object = this.workflow.requiredApprovalTypes.find(
        (approvalType, index) =>
          approvalType.id == this.editValue || index === this.editValue
      );
      const keys = Object.keys(object).filter(
        (x) => !["id", "order"].includes(x)
      );
      this.selectedCategory = keys[keys.length - 1];
      if (this.selectedCategory == "approverOfDepartment") {
        this.approverOfDepartment = null;
      }
      if (this.selectedCategory == "budgetOwnerDepartmentApprover") {
        this.budgetOwnerDepartmentApprover = null;
      }
      this.value = object[this.selectedCategory];
    }
  },

  methods: {
    filteredApprovalTypes() {
      const notAllowedTypes = [];
      if (!this.isFeatureAllowed("legalEntity"))
        notAllowedTypes.push("approverOfLegalEntity");
      if (this.$route.query.flowType != "invoice_purchase_order")
        notAllowedTypes.push("originalRequester");
      if (this.$route.query.flowType != "invoice_purchase_order")
        notAllowedTypes.push("requestedFor");

      return requiredApprovalTypes.filter(
        (approvalType) => !notAllowedTypes.includes(approvalType.value)
      );
    },
    onSave() {
      if (this.isValidSubmission) {
        this.saveValue(
          "requiredApprovalTypes",
          this.selectedCategory,
          this.value,
          this.editValue
        );
        this.closeModal();
      } else {
        alert("value required");
      }
    },
    onApprovalSelect(val) {
      this.selectedCategory = val;
      if (this.editValue) {
        this.value = null;
      }
    },
  },
};
</script>
