<template>
  <div>
    <div class="mt-5 sm:mt-6 text-center">
      <Button v-if="showReset" :color="'gray'" :on-click-handler="reset">
        {{
          isGlobalPolicy
            ? $t("workflow.editor.chooseAnotherOption")
            : $t("workflow.editor.goBack")
        }}
      </Button>
    </div>
    <div class="mt-6 grid sm:grid-cols-2 gap-3">
      <Button :color="'gray'" :on-click-handler="cancel">{{
        $t("workflow.editor.cancel")
      }}</Button>
      <Button
        v-focus
        :disabled="!showSave"
        :color="'primary'"
        :on-click-handler="save"
      >
        {{ $t("workflow.editor.saveCondition") }}
      </Button>
    </div>
  </div>
</template>
<script>
import Button from "@/components/shared/Button.vue";

export default {
  components: { Button },
  props: {
    reset: { type: Function, required: true },
    save: { type: Function, required: true },
    cancel: { type: Function, required: true },
    showReset: { type: Boolean, default: false },
    showSave: { type: Boolean, default: false },
    isGlobalPolicy: { type: Boolean, default: false },
  },
};
</script>
